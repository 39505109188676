import React, {useState, useEffect} from 'react';

import SecurityAnnouncement from '@assets/icons/security-announcement-icon.svg';
import { TreeSelect } from 'antd';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import {
	getImportTasksSampleAdmin,
	getImportTasksSampleClient,
	getImportTaskSampleManager,
	getUpdateTasksSampleAdmin,
	getUpdateTasksSampleManager
} from 'FielderUtils/session/Session';
import { listToTree, getTaskTypeList, hasTaskTypeFunctionality } from 'FielderUtils/taskType/taskType';
import { Row, Col, Navbar, Nav } from 'react-bootstrap';
import { Typography } from '@mui/material';
import { getGeocode } from 'use-places-autocomplete';
import { useJsApiLoader } from '@react-google-maps/api';
// Custom components
import LoadingConst from '../../Loading/LoadingConst';
import Popup from '../../Popup';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import {
  resourceAllocationMyOperationRequest,
  resourceIsochronousMyOperationRequest
} from '@core/api/resourceAllocation/resourceAllocation.service';
import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import '../MyOperation.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectAccount } from '@core/store/modules/session/sessionSelectors';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { doFilterTemplateRequest } from '@core/api/autoAssign/template.service';
import { AUTO_ASSIGN_TYPES } from '../../../pages/AutoAssignTemplate/AutoAssignTemplate.utils';
import { mapConfig } from '@core/utils/map/googleMaps.config';

let myoperation = require('Object/request/myoperation/resource_allocation');
const IMPORT_NAME_LOADING ="IMPORT_LOADING"
const ImportTaskPopup = (props) => {
  const { isLoaded } = useJsApiLoader(mapConfig);
	const lan = useLanguage();
	const dispatch = useDispatch();
	const account = useSelector(selectAccount);
	const user = useSelector(selectUser);
	const taskTypeFunctionality = hasTaskTypeFunctionality(account.funcionalityPurchases);
	const getImportTasksSample = () => {
		if (RoleUtils.isUserClient(user)) {
			return getImportTasksSampleClient(user.language);
		}
		if (RoleUtils.isUserManager(user)) {
			return getImportTaskSampleManager(user.language);
		}
		return getImportTasksSampleAdmin(user.language);
	}
	let linkImport = getImportTasksSample();
	const getUpdateTasksSample = () => {
		if (RoleUtils.isUserManager(user)) {
			return getUpdateTasksSampleManager(user.language);
		} 
		return getUpdateTasksSampleAdmin(user.language);
	}
	let updateImport = getUpdateTasksSample();
	const [tab, setTab] = useState('import');
	const [taskType, setTaskType] = useState(1);
	const [taskTypes, setTaskTypes] = useState([
			{
				id: 1,
				name: lan.pickup,
				children: [],
				title: lan.pickup,
				value: 1,
				key: 1,
			},
			{
				id: 2,
				name: lan.workforce,
				children: [],
				title: lan.workforce,
				value: 2,
				key: 2,
			},
			{
				id: 3,
				name: lan.delivery,
				children: [],
				title: lan.delivery,
				value: 3,
				key: 3,
			}
	]);
	useEffect(() => {
		getTaskTypes()
	}, []);
	const getTaskTypes = async () => {
		if (taskTypeFunctionality) {
			const { formatTypes } = await getTaskTypeList(true, { lan:lan });
			const tree = listToTree(formatTypes);
			setTaskTypes(tree);
		}
	};
	const uploadFile = (file, isAutoAssignFile, totals) => {
		const data =
      tab === 'import' ? isAutoAssignFile ? myoperation.importAutoAssignTasks() : myoperation.importTasks()
        : tab === 'update' ? myoperation.updateTasks() : myoperation.updateForms();
		data.newTaskInfo = {
			idTaskType: taskType,
			importTasksBytes: file,
			idAccount: user ? user.idAccount : 0,
			userToNotify: {
				language: user ? user.language : 'en_US',
				name: user ? user.name : '',
				lastName: user ? user.lastName : '',
				email:  user ? user.email : '',
			},
      totalImportTaskRecords: totals
		};
		const onSuccess = (response) => {
			dispatch(loadingStop(IMPORT_NAME_LOADING));
			props.onClose({ statusCode: 200, response });
		}
		const onError = (error) => console.log(error)
		const loadingConfig = { dispatch }
    if (isAutoAssignFile)
      resourceIsochronousMyOperationRequest(data, onSuccess, onError, loadingConfig).then(r => console.log(r));
    else
      resourceAllocationMyOperationRequest(data, onSuccess, onError, loadingConfig).then(r => console.log(r));
	};
  const processTasksFile = async (event) => {
    const file = event.target.files[0];
    dispatch(loadingStart({
      name: IMPORT_NAME_LOADING,
      config: {
        icon: LoadingConst.IMPORT,
        text: tab === 'import' ? lan.importMessage : tab === 'update' ? lan.updateMessage : lan.updateMessage,
        progress: 100,
      }
    }));
    if (file.type === "text/csv") {
      try {
        const {
          normalTaskFile,
          autoAssignTaskFile,
          totalRecords,
          totalNormalTasks,
          totalAutoAssignTasks
        } = await processCSVFile(file);
        let uploadPromises = [];
        console.log("Total Records:", totalRecords);
        console.log("Total Normal Tasks:", totalNormalTasks);
        console.log("Total Auto-Assign Tasks:", totalAutoAssignTasks);
        if (normalTaskFile) {
          uploadPromises.push(new Promise((resolve) => {
            getBase64(normalTaskFile, (data) => {
              console.log("Uploading Normal Task File:", data);
              if (data) {
                uploadFile(data.split(',')[1], false,
                  {totalRecords: totalRecords, totalNormalTasks: 0, totalAutoAssignTasks: totalAutoAssignTasks});
              } else dispatch(loadingStop(IMPORT_NAME_LOADING));
              resolve();
            });
          }));
        }
        if (autoAssignTaskFile) {
          uploadPromises.push(new Promise((resolve) => {
            getBase64(autoAssignTaskFile, (data) => {
              console.log("Uploading Auto-Assign Task File:", data);
              if (data) {
                uploadFile(data.split(',')[1], true,
                  {totalRecords: totalRecords, totalNormalTasks: totalNormalTasks, totalAutoAssignTasks: 0});
              } else dispatch(loadingStop(IMPORT_NAME_LOADING));
              resolve();
            });
          }));
        }
        await Promise.allSettled(uploadPromises);
        dispatch(loadingStop(IMPORT_NAME_LOADING));
      } catch (error) {
        console.error("Error processing CSV file:", error);
        dispatch(loadingStop(IMPORT_NAME_LOADING));
      }
    } else {
      dispatch(loadingStop(IMPORT_NAME_LOADING));
      console.error("Invalid file type. Please upload a CSV file.");
    }
  };
  const processCSVFile = async (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = async function (e) {
        try {
          const csvContent = e.target.result;
          console.log("CSV Data:", csvContent);
          const rows = csvContent.split("\n").map(row => row.trim()).filter(row => row !== "");
          if (rows.length < 2) {
            console.warn("CSV file is empty or only contains headers.");
            return resolve({ normalTaskFile: null, autoAssignTaskFile: null });
          }
          const headers = rows.shift().split(",").map(header => header.trim());
          headers.push("originalRowIndex");
          console.log("Headers:", headers);
          let normalTaskRecords = [];
          let autoAssignTaskRecords = [];
          // Use async processing for records
          await Promise.all(rows.map(async (row, index) => {
            const originalRowIndex = index + 1;
            const values = row.split(",").map(value => value.trim());
            let locationBranchAddress = values[9] || ""; // Get address value
            if (locationBranchAddress.startsWith('"') && !locationBranchAddress.endsWith('"')) {
              let i = 10;
              while (i < values.length && !values[i].endsWith('"')) {
                locationBranchAddress += `, ${values[i]}`;
                i++;
              }
              if (i < values.length) {
                locationBranchAddress += `, ${values[i]}`;
              }
              locationBranchAddress = locationBranchAddress.replace(/^"|"$/g, "").trim();
              values.splice(10, i - 10 + 1);
            }
            values[9] = locationBranchAddress;
            let record = {
              priority: values[2] || "",
              startRangeDateTime: values[5] || "",
              endRangeDateTime: values[6] || "",
              latitude: values[7] || "",
              longitude: values[8] || "",
              addressLocation: values[9] || "",
              personalizedIdBranch: values[12] || "",
              branchName: values[13] || "",
              idCompany: values[20] || "",
              agentUserName: values[22] || ""
            };
            const rowWithIndex = `${row},${originalRowIndex}`;
            const isAutoAssignTask = (
              record.priority &&
              (!record.startRangeDateTime || !record.endRangeDateTime) &&
              !record.agentUserName &&
              (
                record.personalizedIdBranch ||
                record.branchName ||
                record.idCompany ||
                record.addressLocation ||
                (record.latitude && record.longitude)
              )
            );
            if (isAutoAssignTask) {
              console.log("Auto-Assign Task Record:", record);
              //const location = { lat: parseFloat(record.latitude), lng: parseFloat(record.longitude) };
              const location = await (record?.latitude && record?.longitude)
                ? { lat: parseFloat(record.latitude) ?? 0, lng: parseFloat(record.longitude) ?? 0 }
                : record.addressLocation ? await generateAddressLocation(record.addressLocation) : { lat: 0, lng: 0 };
              const branchFields = {
                idCustom: record.personalizedIdBranch.toLowerCase(),
                name: record.branchName.toLowerCase()
              };
              const [branchExists, companyExists, taskTypeExists, coverageExist] = await Promise.all([
                callAutoAssignTemplates(AUTO_ASSIGN_TYPES.branch, branchFields, location),
                callAutoAssignTemplates(AUTO_ASSIGN_TYPES.company, record.idCompany, location),
                callAutoAssignTemplates(AUTO_ASSIGN_TYPES.taskType, null, location),
                callAutoAssignTemplates(AUTO_ASSIGN_TYPES.coverage, null, location)
              ]);
              if (branchExists || companyExists || taskTypeExists || coverageExist) {
                autoAssignTaskRecords.push(rowWithIndex);
              } else normalTaskRecords.push(rowWithIndex );
            } else {
              console.log("Normal Task Record:", record);
              normalTaskRecords.push(rowWithIndex);
            }
          }));
          let normalTaskFile = null;
          let autoAssignTaskFile = null;
          if (normalTaskRecords.length > 0) {
            const normalTaskCSV = [headers, ...normalTaskRecords].join("\n");
            const normalTaskBlob = new Blob([normalTaskCSV], { type: "text/csv" });
            normalTaskFile = new File([normalTaskBlob], `${file.name.replace(".csv", "_normal.csv")}`, { type: "text/csv" });
          }
          if (autoAssignTaskRecords.length > 0) {
            const autoAssignTaskCSV = [headers, ...autoAssignTaskRecords].join("\n");
            const autoAssignTaskBlob = new Blob([autoAssignTaskCSV], { type: "text/csv" });
            autoAssignTaskFile = new File([autoAssignTaskBlob], `${file.name.replace(".csv", "_auto_assign.csv")}`, { type: "text/csv" });
          }
          resolve({
            normalTaskFile,
            autoAssignTaskFile,
            totalRecords: rows.length,
            totalNormalTasks: normalTaskRecords.length || 0,
            totalAutoAssignTasks: autoAssignTaskRecords.length || 0
          });
        } catch (error) {
          console.error("Error processing CSV:", error);
          reject(error);
        }
      };
      reader.onerror = function () {
        reject(new Error("Error reading CSV file."));
      };
      reader.readAsText(file);
    });
  };
  const callAutoAssignTemplates = async (type, id, location) => {
    console.log("callAutoAssignTemplates", type, id, location);
    let body = { filter: "", filterOrigin: type };
    return doFilterTemplateRequest(body).then((response) => {
      if (response.success === true) {
        return getAutoAssignTemplates(response, type, id, location, taskType);
      } else return false;
    });
  };
  const getAutoAssignTemplates = ({ tableTemplates }, type, id, taskLocation, selectedTaskType) => {
    if (tableTemplates.length === 0) return false;
    const logInfo = (typeString) => {
      console.log(`${typeString} - taskLocation: `, taskLocation);
      console.log(`${typeString} - selectedTaskType: `, selectedTaskType);
    };
    const isochronousMatch = (isochronousItem) => {
      if (isochronousItem.radius > 0 && isochronousItem.center) {
        const distance = calculateDistance(taskLocation.lat, taskLocation.lng, isochronousItem.center.lat, isochronousItem.center.lng);
        return distance <= isochronousItem.radius;
      } else if (isochronousItem.vertices && isochronousItem.vertices.length > 0) {
        return isPointInPolygon({ lat: taskLocation.lat, lng: taskLocation.lng }, isochronousItem.vertices);
      }
      return false;
    };
    if (type === AUTO_ASSIGN_TYPES.company) {
      logInfo('AUTO_ASSIGN_TYPES.company');
      return tableTemplates.some((template) => {
        const idFound = template.originTypeIds.includes(id);
        if (taskLocation && (taskLocation.lat !== 0 || taskLocation.lng !== 0)) {
          const isochronousFound = template.isochronous.some(isochronousMatch);
          if (!isochronousFound) return false;
          if (idFound && isochronousFound) return template.autoAssignTemplate.taskTypes.includes(selectedTaskType);
        }
        if (idFound) return template.autoAssignTemplate.taskTypes.includes(selectedTaskType);
        return false;
      });
    } else if (type === AUTO_ASSIGN_TYPES.branch) {
      logInfo('AUTO_ASSIGN_TYPES.branch');
      return tableTemplates.some((template) => {
        const idFound = template.branches.some(branch =>
          branch.idCustom.toLowerCase() === id.idCustom &&
          branch.name.toLowerCase() === id.name
        );
        if (taskLocation && (taskLocation.lat !== 0 || taskLocation.lng !== 0)) {
          const isochronousFound = template.isochronous.some(isochronousMatch);
          if (!isochronousFound) return false;
          if (idFound && isochronousFound) return template.autoAssignTemplate.taskTypes.includes(selectedTaskType);
        }
        if (idFound) return template.autoAssignTemplate.taskTypes.includes(selectedTaskType);
        return false;
      });
    }
    else if (type === AUTO_ASSIGN_TYPES.coverage && taskLocation && (taskLocation.lat !== 0 || taskLocation.lng !== 0)) {
      logInfo('AUTO_ASSIGN_TYPES.coverage');
      return tableTemplates.some((template) => {
        const isochronousFound = template.isochronous.some(isochronousMatch);
        if (!isochronousFound) return false;
        if (isochronousFound) return template.autoAssignTemplate.taskTypes.includes(selectedTaskType);
        return false;
      });
    } else if (type === AUTO_ASSIGN_TYPES.taskType) {
      logInfo('AUTO_ASSIGN_TYPES.taskType');
      return tableTemplates.some((template) => {
        if (taskLocation && (taskLocation.lat !== 0 || taskLocation.lng !== 0)) {
          const isochronousFound = template.isochronous.some(isochronousMatch);
          if (!isochronousFound) return false;
          if (isochronousFound) return template.autoAssignTemplate.taskTypes.includes(selectedTaskType);
        }
        return template.autoAssignTemplate.taskTypes.includes(selectedTaskType);
      });
    }
    return false;
  };
  const generateAddressLocation = async (address) => {
    console.log("Generating address location:", address);
    if (!address) return { lat: 0, lng: 0 };
    if (!isLoaded) {
      console.error("Google Maps API not loaded yet.");
      return { lat: 0, lng: 0 };
    }
    try {
      const results = await getGeocode({ address });
      console.log("Geocode Results:", results);
      if (results.length > 0) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        return { lat, lng };
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
    return { lat: 0, lng: 0 };
  };
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance * 1000;
  };
  const isPointInPolygon = (point, polygon) => {
    let x = point.lat, y = point.lng;
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi = polygon[i].lat, yi = polygon[i].lng;
      let xj = polygon[j].lat, yj = polygon[j].lng;
      let intersect = ((yi > y) !== (yj > y)) &&
        (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  };
	const getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result);
		};
		reader.onerror = function (error) {
			dispatch(loadingStop(IMPORT_NAME_LOADING));
		};
	};
	const changeTab = (tabAux) => setTab(tabAux);
	const isActive = (tabAux) => (tab === tabAux ? 'td-active' : '');
	const handleOpenFormClick = () => {
		window.open(ENDPOINTS.forms, '_blank');
	};
	const ImportButtonTasksComponent = () => {
		return (
		  <Row className='d-flex justify-content-center'>
			<div className='import-tasks' id="import-tasks">
			  <label htmlFor='file-input' className='image-pointer'>
				<span className='Fielder-button mx-auto'>{tab === 'import' ? lan.import : tab === 'update' ? lan.updateTasks : lan.updateForms}</span>
			  </label>
			  <input
          type='file' id='file-input'
          onChange={(event) => {
            processTasksFile(event).catch((error) => console.error("File processing failed:", error));
          }}
          accept='.csv' />
			</div>
		  </Row>
		);
	  };
	return (
		<Popup width={'lg'} icon='excel-logo-icon.png' show closeButton onClose={props.onClose}>
			<Navbar className='td-navbar mb-3' style={{ zIndex: 9999, backgroundColor: 'white' }}>
				<Nav.Item
					id='nav-import-task'
					className={'td-nav-item text-center ' + isActive('import')}
					style={{ width: '50%' }}
				>
					<Nav.Link className='td-nav-title' onClick={() => changeTab('import')}>
						{lan.importTask}
					</Nav.Link>
				</Nav.Item>
				{!RoleUtils.isUserClient(user) && (
					<>
					<Nav.Item
						id='nav-update-task'
						className={'td-nav-item text-center ' + isActive('update')}
						style={{ width: '50%' }}
					>
						<Nav.Link className='td-nav-title' onClick={() => changeTab('update')}>
							{lan.updateTasks}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item
						id='nav-update-task'
						className={'td-nav-item text-center ' + isActive('updateForm')}
						style={{ width: '100%' }}
					>
						<Nav.Link className='td-nav-title' onClick={() => changeTab('updateForm')}>
							{lan.updateTaskForms}
						</Nav.Link>
							</Nav.Item>
					</>
				)}
				
			</Navbar>
			
			{tab === 'import' && (
				<div>
					<Row className='mb-1' style={{ color: '#DC5C87' }}>
						<Col className='mx-3'>
							<Typography fontSize="11px">{lan.maxImportMessage}</Typography>
						</Col>
						<Col className='mx-3'>
							<a href={linkImport}>
								<span className='myoperation-link'>
									<u>{lan.downloadTaskTemplate}</u>
								</span>
							</a>
						</Col>
					</Row>
					
				</div>
			)}
			{tab === 'update' && (
				<div>
					<Row className='mb-1' style={{ color: '#DC5C87' }}>
						<Col className='mx-3'>
							<Typography fontSize="11px">{lan.maxUpdateMessage}</Typography>
						</Col>
						<Col className='mx-3'>
							<a href={updateImport}>
								<span className='myoperation-link'>
									<u>{lan.downloadUpdateTaskTemplate}</u>
								</span>
							</a>	
						</Col>	
					</Row>			
				</div>
			)}
			{tab === 'updateForm' && (
				<div>
					<Row className='mb-1' style={{ color: '#DC5C87' }}>
					
						<Col className='mx-3'>
							{RoleUtils.isUserManager(user) ? 
								<Row >
									<Col className='mx-3'>
										<Typography fontSize="11px" style={{ display: 'inline-block' }} >{lan.downloadFormTemplateManagerMessage1}</Typography>
									</Col>
								</Row>
								:
								<Row >
									<Col className='mx-3'>
										<Typography fontSize="11px" style={{ display: 'inline-block' }} >{lan.downloadFormTemplateMessage1}</Typography>
									
										<Typography className='ml-2 mr-2' fontSize="11px" style={{ display: 'inline-block', color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} onClick={handleOpenFormClick} >{lan.downloadFormTemplateMessage11}</Typography>
								
										<Typography fontSize="11px" style={{ display: 'inline-block' }}>{lan.downloadFormTemplateMessage12}</Typography>
									</Col>
								</Row>
							}
							<Row>
								<Col className='mx-3'>
									<Typography fontSize="11px">{RoleUtils.isUserManager(user) ? lan.downloadFormTemplateManagerMessage2 :lan.downloadFormTemplateMessage2}</Typography>
								</Col>
							</Row>
							<Row>
								<Col className='mx-3'>
									<Typography fontSize="11px">{RoleUtils.isUserManager(user) ? lan.downloadFormTemplateManagerMessage3  : lan.downloadFormTemplateMessage3}</Typography>
								</Col>
							</Row>
							<Row>
								<Col className='mx-3'>
									<Typography fontSize="11px">{RoleUtils.isUserManager(user) ? lan.downloadFormTemplateManagerMessage4  : lan.downloadFormTemplateMessage4}</Typography>
								</Col>
							</Row>
						</Col>
					</Row>
					
				</div>
			)}

			{(tab === 'import' || tab === 'update') && (
				<div>
					<Row className='mt-2'>
						<Col className='mx-3' sm={6}>
							<TreeSelect
								className='full-width'
								id='taskType-red-input'
								treeData={taskTypes}
								dropdownStyle={{ maxHeight: '400px', overflow: 'auto' }}
								onChange={(e) => setTaskType(e)}
								placeholder={lan.taskType}
								defaultValue={1}
							/>
						</Col>
					</Row>

					<Row className='mt-2 mx-1'>
						<Col sm={'auto'} className='no-padding mt-1 d-flex justify-content-center'>
							<img className='mt-2 ml-3' src={SecurityAnnouncement} alt='' />
						</Col>
						<Col>
							<Row className='mt-2 ml-1'>
								<span className='small-popup-label '>{lan.rememberCsvFile}</span>
							</Row>
							<Row className='ml-1'>
								<span className='small-popup-label '>{lan.useLastCsvTemplate}</span>
							</Row>
						</Col>
					</Row>
				</div>
			)}

			<ImportButtonTasksComponent />
	
		</Popup>
	);
}

export default ImportTaskPopup;
