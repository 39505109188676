const BRANCH_NAME = 'branch';
const COMPANY_NAME = 'company';

//TaskType
const TASK_TYPE_VARIABLE_LIMIT = 5;
const TASK_REMOTE_TYPE_ID = 100000;
const TASK_BY_MAIL = 100001;

/* GROUP OF ACTIVITIES STATUS BY CODE */
const ACTIVITY_CODE_UNKNOWN = [ 6, 20 ];
const ACTIVITY_CODE_TASK = [ 0, 1, 7, 10, 14, 32, 39, 54, 55, 65, 66 ];
const ACTIVITY_CODE_MODULE = [ 33, 34, 35, 80, 81 ];
const ACTIVITY_CODE_AGENT = [ 2, 3, 4, 5, 24, 40, 51, 52, 53 ];
const ACTIVITY_CODE_PARTNER = [ 11, 12, 13, 26, 31, 48 ];
const ACTIVITY_CODE_ROUTE = [ 9, 15, 16, 17, 18, 19, 21, 22, 23, 25, 37, 38, 56, 57, 58, 59 ];
const ACTIVITY_CODE_TASK_GROUP = [ 27, 60, 61, 62, 63, 64 ];
const ACTIVITY_CODE_AUTO_ASSIGN = [ 28, 29, 30, 36, 42, 41, 43, 44, 45, 46, 47, 49, 50, 66, 67 ];
/* ACTIVITY STATUS BY CODE */
const ACTIVITY_STATUS_SUCCESS = [ 2, 4, 53, 81 ];
const ACTIVITY_STATUS_CREATE = [ 0, 65, 66 ];
const ACTIVITY_STATUS_ASSIGN = [ 1, 14, 23, 25, 27, 32, 33, 34, 35, 48, 62, 63 ];
const ACTIVITY_STATUS_CANCEL = [ 7, 13, 39, 60 ];
const ACTIVITY_STATUS_EDIT = [ 3, 10, 37, 38, 55, 56, 57, 59, 61, 64 ];
const ACTIVITY_STATUS_ERROR = [ 80, 5, 31, 21, 22, 58, 28, 29, 30, 36, 42, 43, 44, 45, 46, 47, 49, 66, 41, 50, 67 ];
const ACTIVITY_STATUS_INVITE = [ 11, 12, 26 ];
const ACTIVITY_STATUS_PROGRESS = [ 54, 24, 40, 51, 52, 9, 15, 16, 17, 18, 19 ];
const ACTIVITY_STATUS_UNKNOWN = [ 6, 20 ];
/* ALL ACTIVITY CODES */
const ACTIVITY_CODES = [
	0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 
	17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33, 
	34, 35, 37, 38, 39, 40, 48, 51, 52, 53, 54, 55, 56, 57, 
	58, 59, 60, 61, 62, 63, 64, 65, 66, 80, 81, 28, 29, 30, 36, 42, 41, 43, 44, 45, 46, 47, 49, 50, 66, 67 ];
/* STATUS IMAGE ACTIVITY */
const IMG_CLIENT_USER = [ 2, 4 ];
const IMG_TECHNICIAN = [ 81, 33, 34, 35, 80 ];
const IMG_AUTO_ASSIGN = [ 28, 29, 30, 36, 42, 41, 43, 44, 45, 46, 47, 49, 50, 66, 67  ];
const IMG_CLIENT_OR_TECHNICIAN = [ 0, 1, 3, 5, 6, 7, 9, 10, 11, 12, 13,
	14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
	31, 32, 37, 38, 39, 40, 48, 51, 52, 53, 54, 55, 56, 57,
	58, 59, 60, 61, 62, 63, 64, 65];
export default {
	BRANCH_NAME,
	COMPANY_NAME,
	TASK_TYPE_VARIABLE_LIMIT,
	TASK_REMOTE_TYPE_ID,
	TASK_BY_MAIL,
	ACTIVITY_CODES,
	ACTIVITY_CODE_UNKNOWN,
	ACTIVITY_CODE_TASK,
	ACTIVITY_CODE_MODULE,
	ACTIVITY_CODE_AGENT,
	ACTIVITY_CODE_PARTNER,
	ACTIVITY_CODE_ROUTE,
	ACTIVITY_CODE_TASK_GROUP,
	ACTIVITY_CODE_AUTO_ASSIGN,
	ACTIVITY_STATUS_SUCCESS,
	ACTIVITY_STATUS_CREATE,
	ACTIVITY_STATUS_ASSIGN,
	ACTIVITY_STATUS_CANCEL,
	ACTIVITY_STATUS_EDIT,
	ACTIVITY_STATUS_ERROR,
	ACTIVITY_STATUS_INVITE,
	ACTIVITY_STATUS_PROGRESS,
	ACTIVITY_STATUS_UNKNOWN,
	IMG_CLIENT_USER,
	IMG_TECHNICIAN,
	IMG_AUTO_ASSIGN,
	IMG_CLIENT_OR_TECHNICIAN,
};
