import TaskGroupStatus from 'FielderUtils/status/groupTaskStatus';
import TaskStatus from 'FielderUtils/status/taskStatus';

import AdvancedFilterUtilColum from './AdvancedFilterUtilColum';
import AdvancedFilterUtilOperator from './AdvancedFilterUtilOperator';
import { forEach } from 'lodash';

const PRIORITYHIGH = 1;
const PRIORITYMEDIUM = 2;
const PRIORITYLOW = 3;

export const columnDataAvancedFilter = (lan) => [
	{
		label: "idTask",
		value: AdvancedFilterUtilColum.ID_TASK,
		disabled: false,
	},

	{
		label: "folio",
		value: AdvancedFilterUtilColum.FOLIO,
		disabled: false,
	},
	{
		label: "caseNumber",
		value: AdvancedFilterUtilColum.CASE_NUMBER,
		disabled: false,
	},
	{
		label: "priority",
		value: AdvancedFilterUtilColum.PRIORITY,
		disabled: false,
	},
	{
		label: "typeOfTask",
		value: AdvancedFilterUtilColum.TYPE_OF_TASK,
		disabled: false,
	},
	{
		label: "status",
		value: AdvancedFilterUtilColum.STATUS,
		disabled: false,
	},
	{
		label: "idParentTask",
		value: AdvancedFilterUtilColum.ID_PARENT_TASK,
		disabled: false,
	},
	{
		label: "partner",
		value: AdvancedFilterUtilColum.PARTNER,
		disabled: false,
	},
	{
		label: "user",
		value: AdvancedFilterUtilColum.NAME,
		disabled: false,
	},
	{
		label: "idBranch",
		value: AdvancedFilterUtilColum.BRANCH_ID,
		disabled: false,
	},
	{
		label: "branchName",
		value: AdvancedFilterUtilColum.BRANCH_NAME,
		disabled: false,
	},
	{
		label: "company",
		value: AdvancedFilterUtilColum.COMPANY,
		disabled: false,
	},
	{
		label: "country",
		value: AdvancedFilterUtilColum.COUNTRY,
		disabled: false,
	},
	{
		label: "directionClient",
		value: AdvancedFilterUtilColum.DIRECTION,
		disabled: false,
	},
	{
		label: "agent",
		value: AdvancedFilterUtilColum.AGENT,
		disabled: false,
	},
	{
		label: "group",
		value: AdvancedFilterUtilColum.GROUP,
		disabled: false,
	},
	{
		label: "statusGroup",
		value: AdvancedFilterUtilColum.STATUS_GROUP,
		disabled: false,
	},
	{
		label: "score",
		value: AdvancedFilterUtilColum.SCORE,
		disabled: false,
	},
];



export const getPrioritiesAvancedFilter = (getPrioritiesAvancedFilter, lan) => { 
	const priorities = [];
	forEach(getPrioritiesAvancedFilter, (priority) => {

		let label = priority.name;
		if (priority.id === 1 || priority.id === 2 || priority.id === 3) {
			label = lan['severity_'+priority.id]
		}
		priorities.push({
			label: label,
			value: priority.id,
			disabled: false,
		});
	});
	return priorities;

	
}



export const taskGroupStatusDataAvancedFilter = (lan) => [
	{
		label: "CREATED",
		value: TaskGroupStatus.PAUSED,
		disabled: false,
	},
	{
		label: "ON_WAY",
		value: TaskGroupStatus.ON_WAY,
		disabled: false,
	},
	{
		label: "ARRIVE",
		value: TaskGroupStatus.ARRIVE,
		disabled: false,
	},
	{
		label: "EXECUTING",
		value: TaskGroupStatus.EXECUTING,
		disabled: false,
	},
	{
		label: "PAUSE",
		value: TaskGroupStatus.PAUSE,
		disabled: false,
	},
	{
		label: "FINISH",
		value: TaskGroupStatus.FINISH,
		disabled: false,
	},
	{
		label: "CANCELED",
		value: TaskGroupStatus.CANCELED,
		disabled: false,
	},
];

export const taskStatusDataAvancedFilter = (lan) => [
	{
		label: "taskStatus_0",
		value: TaskStatus.MANAGING,
		disabled: false,
	},
	{
		label: "taskStatus_1",
		value: TaskStatus.CANCEL,
		disabled: false,
	},
	{
		label: "taskStatus_2",
		value: TaskStatus.FINISH,
		disabled: false,
	},
	{
		label: "taskStatus_3",
		value: TaskStatus.WAITING_FOR_AGENT,
		disabled: false,
	},
	{
		label: "taskStatus_4",
		value: TaskStatus.IN_PROGRESS,
		disabled: false,
	},
	{
		label: "taskStatus_5",
		value: TaskStatus.EXECUTING,
		disabled: false,
	},
	{
		label: "taskStatus_6",
		value: TaskStatus.START_ROUTE,
		disabled: false,
	},
	{
		label: "taskStatus_7",
		value: TaskStatus.CANCEL_BY_PARTNER,
		disabled: false,
	},
	{
		label: "taskStatus_8",
		value: TaskStatus.ASSIGNED_TO_PARTNER,
		disabled: false,
	},
	{
		label: "taskStatus_9",
		value: TaskStatus.ASSIGNED_TO_TEAM,
		disabled: false,
	},
	{
		label: "taskStatus_10",
		value: TaskStatus.ARRIVED,
		disabled: false,
	},
	{
		label: "taskStatus_11",
		value: TaskStatus.DELETED,
		disabled: false,
	},
	{
		label: "taskStatus_15",
		value: TaskStatus.PAUSED,
		disabled: false,
	},
];

export const operationDataAvancedFilter = (lan) => [
	{
		label: "contain",
	},
];

export const operatorDataAvancedFilter = (lan) => [
	{
		label: "andCapitalLetter",
		value: AdvancedFilterUtilOperator.AND,
	},
];

export const operatorCategoryDataAvancedFilter = (lan) => [
	{
		label: `orCapitalLetter`,
		value: AdvancedFilterUtilOperator.OR,
	},
	{
		label: `andCapitalLetter`,
		value: AdvancedFilterUtilOperator.AND_CATEGORY,
	},
];
