import React, { useRef } from 'react';

import AddIcon from '@assets/icons/red-add-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { Row, Col, Image, Form, Container } from 'react-bootstrap';
import Select from 'react-select';
import DatePickerFielder from '../../DatePickerFielder/DatePickerFielder';
import { useLanguage } from 'Context/LanguageContext';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';

import Scrollbars from 'react-custom-scrollbars';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import TaskGroupStatusFormatter from '../../SmartTable/TaskGroupStatusFormatter';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import Lottie from 'react-lottie';
import lottie from '@assets/lotties/loadingFielder.json';

const GroupTaskInfo = (props) => {
	
	const lan = useLanguage();
	const user = useSelector(selectUser);

	const cbnoassign = useRef({});
	const cbagent = useRef({});
	const cbteam = useRef({});
	const cbpartner = useRef({});

	const CreateAgents = () =>
		props.selectedAgents.map((agent, i) => (
			<Col sm={'auto'} className='m-1' key={agent.id + '-tag'}>
					<Row className='tag-button my-auto'>
						<div className='mt-1'>
							<Container key={agent.id + '-tag-container'}>
								<span className='mr-3'>{agent.name + ' ' + agent.lastName} </span>
								<button
									key={'btn-close-' + i}
									id={'btn-close-' + i}
									className='close'
									aria-label='Close'
									onClick={() => props.removeAgent(i)}
									disabled={props.editOnly}
								>
									<span style={{ fontSize: '20px', margin:'0px', position:'relative', top:'-4px', right:'-6px'}} aria-hidden='true'>
										&times;
									</span>
								</button>
							</Container>
						</div>
					</Row>
				</Col>

		));

		return (
			<React.Fragment>
				{props.tab === 'infoTaskGroup' && (
					<React.Fragment>
						<Row className='mx-2'>
							<Col sm={6} className='mx-0'>
								<div className='mt-1'>
									<Row>
										<Col className='small-popup-label'>{lan.idCustomGroupTask}</Col>
									</Row>
									<Row>
										<div className='form-group col-5'>
											<Form.Control
												type='text'
												name='idCustom'
												id='idCustomGroup-red-input'
												value={props.idCustomGroup}
												onChange={(e) =>
													props.onTodoChange(e.target.value, 'idCustomGroupTask')
												}
												readOnly={props.editOnly}
											/>
										</div>
										<div className='col-1 pl-0'>
												<img
													src={redAlertIcon}
													alt=''
													id='idCustomGroup-alert-icon'
													className='CreateTask-dir-desc-fieldContainer-icon'
												></img>
										</div>
										<div className='col-6 pr-0 font-inputs'>
												<div className='row justify-content-center no-padding'>
													<TaskGroupStatusFormatter {...props} value={props.status} />
												</div>
										</div>
									</Row>
								</div>
								<div className='mt-3'>
									<Row>
										<Col className='small-popup-label'>{lan.executionDate}</Col>
									</Row>

									<DatePickerFielder
										id='executionDate-red-input'
										selected={props.dateSelected}
										handleDate={props.handleProgramDate}
										minDate={new Date()}
										timeFormat='HH:mm'
										minTime={props.minTime}
										maxTime={new Date().setHours(23, 59)}
										dateFormat='dd/MM/yyyy h:mm aa'
										timeIntervals={30}
										classNamePicker='MyOperation-eschedule form-control mt-1 text-left datePicker-zIndex'
										imgId='executionDate-alert-icon'
										classNameImg='CreateTask-dir-desc-fieldContainer-icon'
										readOnly={props.editOnly}
									/>
								</div>

								<div className='mt-3'>
									<Row>
										<Col className='small-popup-label'>{lan.description}</Col>
									</Row>
									<Row className='mr-1'>
										<div className='form-group col'>
											<textarea className='form-control' id='description-red-input' rows='3' cols='40'
												value={props.description} onChange={(e) => props.onTodoChange(e.target.value, 'description')}
												readOnly={props.editOnly} ></textarea>
										</div>
										<div className='col-auto pl-0 pt-2 mt-3'>
												<img
													src={redAlertIcon}
													alt=''
													id='description-alert-icon'
													className='CreateTask-dir-desc-fieldContainer-icon'
												></img>
										</div>
									</Row>
								</div>									
							</Col>

							<Col sm={6} className=' Fielder-cards-colum'>
								<div className='mt-1'>
									<Row>
										<Col className='small-popup-label'>{lan.name}</Col>
									</Row>
									<Row className='mr-1'>
										<div className='form-group col'>
											<Form.Control
												type='text'
												name='name'
												value={props.name}
												id='name-red-input'
												onChange={(e) => props.onTodoChange(e.target.value, 'name')}
												readOnly={props.editOnly}

											/>
										</div>
										<div className='col-auto pl-0'>
											<img
												src={redAlertIcon}
												alt=''
												id='name-alert-icon'
												className='CreateTask-dir-desc-fieldContainer-icon'
											></img>	
										</div>
									</Row>
								</div>
								
								<div className='mt-3'>
									<Row>
										<Col className='small-popup-label'>{lan.branch}</Col>
									</Row>
									<Row className='mt-2 mr-1'>
										<div className='col'>
											{props.showBranchSelect && (
												<AsyncSearch
													className='react-select__control full-width mt-1 mb-3'
													getOptions={props.getOptionsBranch}
													defaultValue={props.selectedBranch}
													onChange={(e) => props.setFieldBranch(e)}
													label={lan.placeHolderBranch}
													disabled={!props.isPartner ? props.editOnly : true } 
													lan={lan}
													id='branch-AsyncSearch'
													multiple={false}
													showInitialOptions
													getInitialOptions={() => props.getInitialOptionsBranch()}
												/>
											)}
										</div>
									</Row>						
								</div>
							</Col>
							
							<Scrollbars className='my-3' style={{ height: '300px' }}
								renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
								renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none !important' }} />}	
							>

							<Col sm={12} className='mx-0 track-horizontal'   style={{ overflowX: 'hidden !important' }}   >
								<Row className='mt-3'>
									{props.dontShowToManager && !props.isPartner && (
										<Col>
											<div className='card'>
												<Row className='mx-auto mt-2'>
													<input
														type='checkbox'
														className='form-check-input labelCheckbox d-none'
														id='cbnoassign'
														name='checkbox-no-assign'
														ref={cbnoassign}
														checked={props.showNoAssign}
														onClick={props.checkNoAssing}
														disabled={props.editOnly}
													/>

													<label
														htmlFor='cbnoassign'
														className='square-checkbox ml-2'
													></label>
													<div className='tag ml-4' style={{ textAlign: 'center' }}>
														{' '}
														{lan.doNotAssign}{' '}
													</div>
												</Row>
											</div>
										</Col>
									)}

									{ !props.isPartner && (
										<Col className={ props.dontShowToManager ? ''  : 'col-4'}>
											<div className='card'>
												<Row className='mx-auto mt-2'>
													<input
														type='checkbox'
														className='form-check-input labelCheckbox d-none'
														id='cbteam'
														name='cbteam'
														ref={cbteam}
														checked={props.showTeamSearch}
														onClick={props.checkTeams}
														disabled={props.editOnly}
													/>

													<label
														htmlFor='cbteam'
														className='square-checkbox ml-2'
													></label>
													<div className='tag ml-4' style={{ textAlign: 'center' }}>
														{' '}
														{lan.team}{' '}
													</div>
												</Row>
											</div>
										</Col>
									)}

									
									{((props.dontShowToManager && !props.isPartner) || props.isPartner) && (
										<Col className={ !props.isPartner ? ''  : 'col-4'}>
											<div className='card'>
												<Row className='mx-auto mt-2'>
													<input
														type='checkbox'
														className='form-check-input labelCheckbox d-none'
														id='cbpartner'
														name='cbpartner'
														ref={cbpartner}
														checked={props.showPartnerSearch}
														onClick={props.checkPartner}
														disabled={props.editOnly}
													/>
													<label
														htmlFor='cbpartner'
														className='square-checkbox ml-2'
													></label>
													<div className='tag ml-4' style={{ textAlign: 'center' }}>
														{' '}
														{lan.partners}{' '}
													</div>
												</Row>
											</div>
										</Col>
									)}
									{(props.dontShowToManager && !props.isPartner) && (
										<Col>
											<div className='card'>
												<Row className='mx-auto mt-2'>
													<input
														type='checkbox'
														className='form-check-input labelCheckbox d-none'
														id='cbagent'
														name='cbagent'
														ref={cbagent}
														checked={props.showAgentSearch}
														onClick={props.checkAgent}
														disabled={props.editOnly}
													/>
													<label
														htmlFor='cbagent'
														className='square-checkbox ml-2'
													></label>
													<div className='tag ml-4' style={{ textAlign: 'center' }}>
														{' '}
														{lan.agent}{' '}
													</div>
												</Row>
											</div>
										</Col>
									)}
								</Row>	
								
										
								<React.Fragment>
									{props.showNoAssign && (
										<Row className="mb-3" />		
									)}	
									

									{props.showTeamSearch && (
										<React.Fragment>
											<Row>
												<Col sm={12} className='mt-3 ml-1 mb-4'>
													<span className='title-padding text-title'>
														{lan.addTeam}
													</span>
												</Col>
												<Col key='ml-2 col-task-assing-to'>
													<AsyncSearch
														className='react-select__control full-width mt-1 mb-3'
														getOptions={props.getOptionsTeam}
														defaultValue={props.selectedTeam}
														onChange={(e) => props.setFieldTeam(e)}
														disabled={props.editOnly}
														label={lan.team}
														lan={lan}
														isDisabled={props.editOnly}
														showInitialOptions
														getInitialOptions={() => props.getInitialOptionsTeam()}
													/>
												</Col>
											</Row>
										</React.Fragment>

									)}
										
									{props.showPartnerSearch && (
										<React.Fragment>
											<Row>
												<Col sm={12} className='mt-3 ml-1 mb-4'>
													<span className='title-padding text-title'>
														{lan.addPartner}
													</span>
												</Col>
												<Col key='ml-2 col-task-assing-to'>
													<AsyncSearch
														className='react-select__control full-width mt-1 mb-3'
														getOptions={props.getOptionsPartner}
														onChange={(e) => props.handleOptionSelectedPartner(e)}
														defaultValue={props.selectedPartner}
														disabled={!props.isPartner ? props.editOnly : true }
														lan={lan}
														id='all-AsyncSearch'
														showInitialOptions
														getInitialOptions={() => props.getInitialOptionsPartner()}
													/>
												</Col>
											</Row>
										</React.Fragment>
									)}

									{(RoleUtils.isUserManager(user) ||  props.showAgentSearch) && (
										<React.Fragment>
											<Row>
												<Col sm={12} className='mt-3 ml-1 mb-4'>
													<span className='title-padding text-title'>
														{lan.addAgents}
													</span>
												</Col>

												{!props.activateLoadingManager ? (
													<>		
													<Col sm={10}>
														<Select
															className='react-select__control full-width mt-1 mb-3'
															options={props.agentsSelectList}
															onChange={(e) => props.setFieldAgent(e)}
															placeholder={props.lan.placeHolderClient}
															isDisabled={ props.editOnly}
														/>
													</Col>
													<Col sm={2} className='d-flex justify-content-end'>
														<Image
															className='ml-3'
															src={AddIcon}
															alt={lan.searchBy}
															onClick={() => {
																props.addAgent(props.agent);
															}}
														/>
													</Col>
													</>
													) : (
													<div className='col-auto mt-1 mr-2 no-padding ml-4'>
														<Lottie
															options={{
																loop: true,
																autoplay: true,
																animationData: lottie,
															}}
															height={'40px'}
															width={'40px'}
														/>
													</div>
													)}
											</Row>
											<Row className='ml-3 mb-3'>
												<CreateAgents />
											</Row>
										</React.Fragment>
									)}

									
									</React.Fragment>

									<div id='register-form-hint' className='Register-form-hint mt-3'>
										<p>*{lan.requiredFields}</p>
									</div>

									{props.showSubmitButton ? (
										<div className='mb-4'>
											<div className='col-sum-12 d-flex justify-content-center mt-4'>
												<button
													type='submit'
													className='btn btn-outline-primary btn-success create-client-modal-btnsave-size'
													onClick={props.handleDoActionUpdateTaskGroup}
												>
													{lan.add}
												</button>
											</div>
										</div>
									) : null}
						

								</Col>
							</Scrollbars>
						</Row>
					</React.Fragment>
				)}
			</React.Fragment>
		);
}

export default GroupTaskInfo;
