import React, { useEffect, useState } from 'react';

//Library
import ProfileImage from '@assets/icons/default-profile-icon.svg';
import {
	getBranchesByFilterAsyncSelectCreateTask,
	getBranchesInitialOptionsAsyncSelect,
} from 'FielderUtils/branch/branch';
import { getPartnersByFilterAsyncSelect, getPartnerInitialOptionsAsyncSelect } from 'FielderUtils/partner/partner';
import RoleUtils from 'FielderUtils/roles/roleUtils';

import { getIdToken } from 'FielderUtils/session/Session';
import TaskStatus from 'FielderUtils/status/taskStatus';
import { getTeamsByFilterAsyncSelect, getTeamsInitialOptionsAsyncSelect } from 'FielderUtils/team/team';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { Modal, Navbar, Nav, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { useLanguage } from 'Context/LanguageContext';

import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';
import TaskDetails from '../../TaskDetails';
import EditGroupTaskActivity from './EditGroupTaskActivity';
import EditGroupTaskTitle from './EditGroupTaskTitle';
import GenerateExportTaskPopup from './generate-export-task-popup';
import GroupTaskComments from './GroupTaskComments';
import GroupTaskInfo from './GroupTaskInfo';
import GroupTaskSectionTask from './GroupTaskSectionTask';
import { SuperAdmin, Admin, Manager } from 'FielderUtils/roles/roles';

import 'rc-time-picker/assets/index.css';
import '../MyOperation.css';
import 'FielderUtils/style.css';
import { getMyOperationAgentsRequest } from '@core/api/agents/agents.service';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import {
	editTaskGroupRequest,
	unlinkTaskFromTaskGroupRequest,
	getTaskGroupRequest,
} from '@core/api/task/task.group.service';
import { resourceAllocationMyOperationRequest } from '@core/api/resourceAllocation/resourceAllocation.service';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';

var moment = require('moment-timezone');

const EditGruopTask = (props) => {
	const fielderMsg = useFielderSnackBar();
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const dontShowToManager = RoleUtils.isUserManager(user) ? false : true;

	const [dateSelected, setDateSelected] = useState(new Date());
	const [minTime, setMinTime] = useState(new Date());

	const [idBranch, setIdBranch] = useState(null);
	const [idPartner, setIdPartner] = useState(null);
	const [idGroup, setIdGroup] = useState(null);
	const [idTeam, setIdTeam] = useState(null);

	const [showPartnerSelect, setShowPartnerSelect] = useState(false);
	const [showBranchSelect, setShowBranchSelect] = useState(false);

	const [showTeamSelect, setShowTeamSelect] = useState(false);
	const [showAgentSearch, setShowAgentSearch] = useState(false);
	const [showTeamSearch, setShowTeamSearch] = useState(false);
	const [showPartnerSearch, setShowPartnerSearch] = useState(false);
	const [showNoAssign, setShowNoAssign] = useState(false);

	const [showSubmitButton, setShowSubmitButton] = useState(false);

	const [selectedBranch, setSelectedBranch] = useState(null);
	const [activateAlert, setActivateAlert] = useState(null);
	const [selectedAgents, setSelectedAgents] = useState([]);
	const [editAgents, setEditAgents] = useState(false);
	const [editOnly, setEditOnly] = useState(true);

	const [managerName, setManagerName] = useState('');
	const [status, setStatus] = useState('');

	const [titleBranch, setTitleBranch] = useState(lan.detailTaskGroup);

	const [noImagesMessage, setNoImagesMessage] = useState(false);

	const [message, setMessage] = useState('');
	const [countNumberFinish, setCountNumberFinish] = useState(0);

	const [dateChanged, setDateChanged] = useState(false);
	const [tab, setTab] = useState('infoTaskGroup');

	const [idTaskDetail, setIdTaskDetail] = useState(null);
	const [showDetailTask, setShowDetailTask] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [showTooltipFinishTask, setShowTooltipFinishTask] = useState(false);
	const [comments, setComments] = useState([]);
	const [showExportTaskPopUp, setShowExportTaskPopUp] = useState(false);
	const [groupActivity, setGroupActivity] = useState({ activity: [] });
	const [opacityModal, setOpacityModal] = useState(1);

	const [idCustomGroup, setIdCustomGroup] = useState('');
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [agentsSelectList, setAgentsSelectList] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [selectedPartner, setSelectedPartner] = useState(null);
	const [showUnlinkTaskConfirmationDialog, setShowUnlinkTaskConfirmationDialog] = useState(false);
	const [agent, setAgent] = useState(null);

	const [taskSelected, setTaskSelected] = useState([]);
	const [loadingActive, setLoadingActive] = useState(false);
	const [filterToExport, setFilterToExport] = useState(null);
	const [filterFilterIdsTask,setFilterIdsTask] = useState(null);

	const [activateLoadingManager, setActivateLoadingManager] = useState(false);
	const [isPartner, setIsPartner] = useState(false);

	const getGroupTaskBody = () => {
		let body = {};
		if (props.idGroup && props.idGroup[0]) {
			body.idGroup = props.idGroup[0];
		} else if (props.tasksFromGroup) {
			body.idGroup = props.tasksFromGroup[0];
		} else if (props.customId) {
			body.idCustom = props.customId;
		}
		return body;
	}

	useEffect(() => {
		setLoadingActive(true);
		executeGetGroupTask();

		if (!RoleUtils.isUserManager(user)) {
			getMyOperationAgentsRequest(manageAgentList, null, null, 0);
		}
	}, []);

	const executeGetGroupTask = () => {
		const body = getGroupTaskBody();

		getTaskGroupRequest(body, manageGetTaskGroupDetailById, console.error);
	}

	useEffect(() => {
		if (RoleUtils.isUserManager(user)) {
			getMyOperationAgentsRequest(manageAgentList, null, null, idTeam);
		}
	}, [idTeam]);

	const manageAgentList = (res) => {
		let agentsAux = [];
		if (res && res.agents) {
			agentsAux = res.agents.map((element) => ({
				label: element.name + ' ' + element.lastName + ', ' + element.email,
				value: element,
			}));
		}
		setAgentsSelectList([...agentsAux]);
		setActivateLoadingManager(false);
	};

	const manageGetTaskGroupDetailById = ({ group }) => {

		if (!group) return;

		if (user && user.role === Manager.name && user.idTeams && !user.idTeams.includes(group.idTeam)) {
			props.onClose(false);
		} else if (user && (user.role == SuperAdmin.name || user.role == Admin.name) && user.idAccount != group.idAccount
			&& user.idAccount != group.idPartner) {
			props.onClose(false);
		}

		setShowNoAssign((!group.agents || group.agents.length <= 0) && !group.team && !group.partner);
		setSelectedAgents(group.agents ? group.agents : []);

		handleGroupTeamOption(group.team);
		handleGroupPartnerOption(group.partner);
		handleGroupBranchOption(group.branch);
		handleGroupActivityHistory(group.groupActivities);

		setIdGroup(group.id ? group.id : null);
		setIdBranch(group.idBranch ? group.idBranch : null);
		setIdTeam(group.idTeam ? group.idTeam : null);
		setIdPartner(group.idPartner ? group.idPartner : null);
		setIdCustomGroup(group.idCustomGroup ? group.idCustomGroup : '');
		setName(group.name ? group.name : '');
		setDescription(group.description ? group.description : '');

		setStatus(group.status ? group.status : '');
		setDateSelected(group.executionDateTime ? new Date(group.executionDateTime) : new Date());
		setComments(group.comments ? group.comments : []);

		setShowPartnerSelect(true);
		setShowBranchSelect(true);
		setShowTeamSelect(true);
		setShowTeamSearch(group.team ? true : false);
		setShowPartnerSearch(group.partner ? true : false);

		let isPartnerValidation = false;
		let showAgentSelect = false;

		if (group.idPartner && group.idPartner == user.idAccount) {
			isPartnerValidation = true;
			showAgentSelect = true;
		} else if (!RoleUtils.isUserManager(user)) {
			showAgentSelect = (group.idTeam > 0 || group.idPartner > 0) || (group.agents && group.agents.length <= 0)  ? false : true;
		} else {
			showAgentSelect = group.agents && group.agents.length > 0 ? true : false;
		}


		setIsPartner(isPartnerValidation);
		setShowAgentSearch(showAgentSelect);
		setLoadingActive(false);
	};

	const handleGroupTeamOption = (team) => {
		if (!team) return;
		
		let teamManager = '';
		if (team.users) {
			const users = team.users;
			for (let i = 0; i < users.length; i++) {
				teamManager = `(${users[i].name} ${users[i].lastName})`;
				break;
			}
		}
	
		const teamOption = {
			label: `[${team.id}] - ${team.name} ${teamManager}`,
			value: team,
		};

		setManagerName(teamManager);
		setSelectedTeam(teamOption);
	};

	const handleGroupPartnerOption = (partner) => {
		if (!partner) return;

		const partnerAccount = partner.account;
		const accountName = partnerAccount.name ? partnerAccount.name : '';
		const selectedPartner = {
			label: `[${partnerAccount.id}] - ${accountName} (${partnerAccount.user.name} ${partnerAccount.user.lastName})`,
			value: partnerAccount.id,
		};

		setSelectedPartner(selectedPartner);
	};

	const handleGroupBranchOption = (branch) => {
		if (!branch) return;

		const { id, idCustom, name, company } = branch;
		const selectedBranchAux = {
			label: `${idCustom} ${name} (${company.id} - ${company.name})`,
			value: id,
		};
		setSelectedBranch(selectedBranchAux);
	};

	const handleGroupActivityHistory = (activityHistory) => {
		if (!activityHistory) return;

		const activityRows = [];

		for (const activity of activityHistory) {
			let agent = '';
			let user = '';
			let userImage = ProfileImage;
			let activityDate = moment(activity.createdTime, 'x');

			if (activity.user !== null) {
				user = `${activity.user.name} ${activity.user.lastName}`;

				if (activity.user.profileImage) {
					userImage = activity.user.profileImage.scalePath;
				}
			} else if (activity.agent !== null) {
				agent = `${activity.agent.name} ${activity.agent.lastName}`;

				if (activity.agent.profileImage) {
					userImage = activity.agent.profileImage;
				}
			}

			activityRows.push({
				idActivity: activity.id,
				time: activityDate.format('hh:mm a'),
				date: activityDate.format('DD MMMM YYYY'),
				activity: lan[activity.typeAction],
				agent,
				user,
				userImage,
			});
		}

		setGroupActivity({ activity: activityRows });
	};

	const onTodoChange = (value, field) => {
		if (field === 'idCustomGroupTask') {
			setIdCustomGroup(value);
		} else if (field === 'name') {
			setName(value);
		} else if (field === 'description') {
			setDescription(value);
		}
	};

	const validateDateGroup = () => {
		var currentDate = new Date();

		if (!dateChanged || dateSelected >= currentDate) {
			document.getElementById('executionDate-alert-icon').style.display = 'none';
			document.getElementById('executionDate-red-input').style.borderColor = '#ced4da';
			setActivateAlert(false);
			return true;
		}
		document.getElementById('executionDate-alert-icon').style.display = 'inline';
		document.getElementById('executionDate-red-input').style.borderColor = '#c70101';
		setActivateAlert(true);
		return false;
	};

	const validateIdCustomGroup = (idCustomGroup) => {
		if (idCustomGroup !== '') {
			document.getElementById('idCustomGroup-alert-icon').style.display = 'none';
			document.getElementById('idCustomGroup-red-input').style.borderColor = '#ced4da';
			return true;
		}
		document.getElementById('idCustomGroup-alert-icon').style.display = 'inline';
		document.getElementById('idCustomGroup-red-input').style.borderColor = '#c70101';
		return false;
	};

	const validateName = (name) => {
		if (name !== '') {
			document.getElementById('name-alert-icon').style.display = 'none';
			document.getElementById('name-red-input').style.borderColor = '#ced4da';
			return true;
		}
		document.getElementById('name-alert-icon').style.display = 'inline';
		document.getElementById('name-red-input').style.borderColor = '#c70101';
		return false;
	};

	const validateDesciption = (description) => {
		if (description !== '') {
			document.getElementById('description-alert-icon').style.display = 'none';
			document.getElementById('description-red-input').style.borderColor = '#ced4da';
			return true;
		}
		document.getElementById('description-alert-icon').style.display = 'inline';
		document.getElementById('description-red-input').style.borderColor = '#c70101';
		return false;
	};

	const handleDoActionUpdateTaskGroup = () => {
		var isValid = true;
		if (!validateDateGroup()) {
			isValid = false;
		}

		if (
			idCustomGroup === '' ||
			name === '' ||
			description === '' ||
			((idTeam == null || idTeam == 0) && selectedAgents.length <= 0 && (idPartner == null || idPartner == 0))
		) {
			var isValidIdCustomGroup = validateIdCustomGroup(idCustomGroup);
			var isValidName = validateName(name);
			var isValidDescription = validateDesciption(description);

			if (isValidIdCustomGroup && isValidName && isValidDescription) {
				document.getElementById('register-form-hint').style.display = 'none';
			} else {
				isValid = false;
			}
		}
		if (isValid) {
			const timeSelected = dateSelected;
			const timeMilliseconds = timeSelected.getTime();
			const idAgents = getIdsAgents();
			const idAgentsOnly = idAgents.map((agent) => agent.idAgent);
			const body = {
				groupTask: {
					idCustomGroup: idCustomGroup,
					name: name,
					description: description,
					idBranch: idBranch,
					idTeam: idTeam,
					idPartner: idPartner,
					executionDateTime: timeMilliseconds,
					id: idGroup,
				},
				editAgents: editAgents,
				idAgents: idAgentsOnly,
			};

			const onSuccess = () => props.onClose(true);
			const onError = (error) => {
				let dontShowError = false;
				if (error.status == 504) {
					dontShowError = true;
				}
				props.onClose(dontShowError);
			}

			const loadingConfig = { dispatch, name: 'EDIT_GROUP' };
			editTaskGroupRequest(body, onSuccess, onError, loadingConfig);
		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
	};

	const addAgent = (agent) => {
		setEditAgents(true);
		let selectedAgentsAux = selectedAgents ? selectedAgents : [];
		if (agent && !validateHasAgent(selectedAgentsAux, agent)) {
			selectedAgentsAux.push(agent);
			setSelectedAgents([...selectedAgentsAux]);
		}
	};

	const removeAgent = (index) => {
		setEditAgents(true);

		let agents = selectedAgents;
		agents.splice(index, 1);

		setSelectedAgents([...agents]);
	};

	const getIdsAgents = () => {
		const agents = selectedAgents;
		let ids = [];

		for (var i = 0; i < agents.length; i++) {
			const agent = agents[i];
			ids.push({ idAgent: agent.idAgent });
		}
		return ids;
	};

	const handleProgramDate = (date) => {
		let minTimeAux = new Date();
		if (dateSelected !== date) {
			if (
				date.getDate() !== new Date().getDate() ||
				date.getMonth() !== new Date().getMonth() ||
				date.getFullYear() !== new Date().getFullYear()
			) {
				minTimeAux = new Date().setHours(0, 0);
			}

			setDateSelected(date);
			setMinTime(minTimeAux);
			setDateChanged(true);
		}
	};

	const validateHasAgent = (allAgents, agent) => {
		for (let i = 0; i < allAgents.length; i++) {
			const element = allAgents[i];
			if (agent.idAgent === element.idAgent) return true;
		}
		return false;
	};

	const handleEdit = (e) => {
		e.preventDefault();

		if (editOnly == false) {
			setTitleBranch(lan.detailTaskGroup);
		} else {
			setTitleBranch(lan.editTaskGroup);
		}
		setShowSubmitButton(!showSubmitButton);
		setEditOnly(!editOnly);
	};

	const downloadZipFile = (zip) => {
		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, idCustomGroup + '.zip');
			dispatch(loadingStop({ name: 'DOWNLOAD_ZIP' }));
		});
	};

	const getImageName = (url) => {
		let separeted = url.split('/');
		const lastIndex = separeted.length - 1;

		if (lastIndex > 0) {
			return separeted[lastIndex];
		}
		return '';
	};

	const getImagesFromGroup = async (images, index, zip) => {
		if (index < images.length) {
			const image = images[index];
			if (image && image.imagePath) {
				let imageLink = image.imagePath;
				let bucket = process.env.AWS_BUCKET;
				let link_split = imageLink.split('com/');
				let key = link_split[1];

				if (!key.includes(bucket)) {
					imageLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com/${key}`;
				} else {
					let auxKey = key.split(process.env.AWS_BUCKET);
					imageLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${auxKey[1]}`;
				}

				await new Promise((resolve, reject) => {
					JSZipUtils.getBinaryContent(imageLink, (err, data) => {
						zip.file(getImageName(imageLink) + '.jpeg', data, { binary: true });
						resolve();
					});
				});

				let newIndex = index + 1;
				getImagesFromGroup(images, newIndex, zip);
			} else {
				downloadZipFile(zip);
			}
		} else {
			downloadZipFile(zip);
		}
	};

	const wrapImages = (res) => {
		if (res && res.images && res.images.length > 0) {
			getImagesFromGroup(res.images, 0, new JSZip());
		} else {
			dispatch(loadingStop({ name: 'DOWNLOAD_ZIP' }));
			setOpacityModal(1);
			fielderMsg({message: lan.noGroupTaskImages,
				variant: 'warning', closeButton: true, duration: 5000});
		}
	};

	const handleDownloadImage = (e) => {
		e.preventDefault();

		dispatch(loadingStart({ name: 'DOWNLOAD_ZIP' }));
		const body = {
			type: 'GET_IMAGES_BY_GROUP',
			idGroup,
		};

		const onSuccess = wrapImages;

		resourceAllocationMyOperationRequest(body, onSuccess, null, null);
	};

	const changeTab = (tabAux) => setTab(tabAux);
	const isActive = (tabAux) => (tab === tabAux ? 'td-active' : '');

	const closeUnlinkConfirmationDialog = () => {
		setShowUnlinkTaskConfirmationDialog(false);
		setOpacityModal(1);
	};

	const unlinkTaskFromTaskGroupConfirm = () => {
		let idTasks = [];
		let validateStatus = true;
		for (let i = 0; i < taskSelected.length; i++) {
			if (taskSelected[i].status != TaskStatus.FINISH) {
				idTasks.push(taskSelected[i].idTask);
				validateStatus = false;
			}
		}

		if (idTasks.length <= 0 && validateStatus === true) {
			setShowTooltipFinishTask(true);
			setShowUnlinkTaskConfirmationDialog(false);
		} else {
			setShowTooltipFinishTask(false);

			const body = { tasks: idTasks, idGroupTask: idGroup };
			const loadingConfig = { dispatch };
			const onSuccess = () => props.closeUnlinkGroupPopup(true);
			const onError = () => {
				setShowTooltipFinishTask(true);
				setShowUnlinkTaskConfirmationDialog(false);
			};
			unlinkTaskFromTaskGroupRequest(body, onSuccess, onError, loadingConfig);
		}
	};

	const handleUnlinkTask = (e) => {
		e.preventDefault();

		if (taskSelected.length <= 0) {
			setShowTooltip(true);
			setOpacityModal(1);
		} else {
			setShowUnlinkTaskConfirmationDialog(true);
			setShowTooltip(false);
			setOpacityModal(0);
		}
	};

	const handleExportTasks = () => {
		setOpacityModal(0);
		setShowExportTaskPopUp(true);
	};

	const handleCloseGenerateTaskReportPopup = () => {
		setShowExportTaskPopUp(false);
		setOpacityModal(1);
	};

	const showTaskDetail = (idTask) => {
		setIdTaskDetail(idTask);
		setShowDetailTask(true);
	};

	const setFieldBranch = (event) => {
		if (event == null) return;
		setIdBranch(event.value.id);
	};

	const getInitialOptionsBranch = () => getBranchesInitialOptionsAsyncSelect(user.idAccount, user, getIdToken());
	const getInitialOptionsPartner = () => getPartnerInitialOptionsAsyncSelect(user.idAccount, user.idUser, getIdToken());
	const getInitialOptionsTeam = () => getTeamsInitialOptionsAsyncSelect(user.idAccount, user.idUser, getIdToken(), lan);
	const getOptionsBranch = (e) => getBranchesByFilterAsyncSelectCreateTask(e, user.idAccount, user, getIdToken());
	const getOptionsPartner = (e) => getPartnersByFilterAsyncSelect(e, user.idAccount, user.idUser, getIdToken());
	const getOptionsTeam = (e) => getTeamsByFilterAsyncSelect(e, user.idAccount, user.idUser, getIdToken(), lan);

	const handleOptionSelectedPartner = (event) => setIdPartner(event ? event.value : null);

	const setFieldTeam = (event) => {
		if (event && event.value && event.value.id != idTeam) {
			setActivateLoadingManager(true);
			setIdTeam(event.value.id);
			setSelectedAgents([]);
		}
	};

	const setFieldAgent = (event) => setAgent(event.value);

	const checkNoAssing = () => {
		document.getElementById('cbteam').checked = false;
		document.getElementById('cbpartner').checked = false;
		document.getElementById('cbagent').checked = false;

		setIdPartner(null);
		setSelectedPartner(null)
		setSelectedAgents([])
		setIdTeam(null)
		setEditAgents(true)
		setShowAgentSearch(false)
		setShowTeamSearch(false)
		setShowPartnerSearch(false)
		setShowNoAssign(true)
	};

	const checkTeams = () => {
		document.getElementById('cbnoassign').checked = false;
		document.getElementById('cbpartner').checked = false;
		document.getElementById('cbagent').checked = false;

		setIdPartner(null)
		setSelectedAgents([]);
		setEditAgents(true);
		setShowAgentSearch(false);
		setShowTeamSearch(true);
		setShowPartnerSearch(false);
		setSelectedPartner(null);
		setShowNoAssign(false);
	};

	const checkPartner = () => {
		document.getElementById('cbnoassign').checked = false;
		document.getElementById('cbteam').checked = false;
		document.getElementById('cbagent').checked = false;

		setSelectedAgents([]);
		setEditAgents(true);
		setIdTeam(null);
		setShowAgentSearch(false);
		setShowTeamSearch(false);
		setShowPartnerSearch(true);
		setSelectedTeam(null);
		setShowNoAssign(false);
	};

	const checkAgent = () => {
		document.getElementById('cbnoassign').checked = false;
		document.getElementById('cbteam').checked = false;
		document.getElementById('cbpartner').checked = false;

		setSelectedAgents([]);
		setIdPartner(null);
		setIdTeam(null);
		setShowAgentSearch(true);
		setShowTeamSearch(false);
		setShowPartnerSearch(false);
		setSelectedTeam(null);
		setSelectedPartner(null);
		setShowNoAssign(false);
	};

	return (
		<React.Fragment>
			{showUnlinkTaskConfirmationDialog && (
				<ConfirmationDialog
					{...props}
					lan={lan}
					cssModal='modal-shadow'
					handleAction={unlinkTaskFromTaskGroupConfirm}
					handleClose={closeUnlinkConfirmationDialog}
					message={lan.unlinkTaskQuestion}
				/>
			)}

			{noImagesMessage && (
				<ErrorMessage
					{...props}
					closeErrorMessage={() => {
						setNoImagesMessage(false);
						setOpacityModal(1);
					}}
					message={message}
					showErrorMessage={noImagesMessage}
				/>
			)}

			<Modal
				show
				size='xl'
				className='Fielder-modals-header-border Fielder-modals-content-border'
				onHide={props.onClose}
				style={{ opacity: opacityModal }}
			>
				<Modal.Header
					className='Fielder-modals-header-border no-padding-bottom Fielder-modals-content-border'
					closeButton
				>
					<Modal.Title className='full-width'>
						<EditGroupTaskTitle
							lan={lan}
							status={status}
							handleEdit={handleEdit}
							handleDownloadImage={handleDownloadImage}
							showExportTask={handleExportTasks}
							handleUnlinkTask={handleUnlinkTask}
							showTooltip={showTooltip}
							showTooltipFinishTask={showTooltipFinishTask}
							countNumberFinish={countNumberFinish}
							titleBranch={titleBranch}
							tab={tab}
							loadingActive={loadingActive}
						/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='Fielder-body' style={{ background: 'white' }}>
						<Row className='mx-3'>
							<Navbar className='td-navbar mb-3'>
								<Nav.Item id='nav-info' className={`td-nav-item text-center ${isActive('infoTaskGroup')}`}>
									<Nav.Link className='td-nav-title' onClick={() => changeTab('infoTaskGroup')}>
										{lan.information}
									</Nav.Link>
								</Nav.Item>

								<Nav.Item id='nav-act' className={`td-nav-item text-center ${isActive('taskInfo')}`}>
									<Nav.Link className='td-nav-title' onClick={() => changeTab('taskInfo')}>
										{lan.tasks}
									</Nav.Link>
								</Nav.Item>

								<Nav.Item id='nav-act' className={`td-nav-item text-center ${isActive('taskGroupActivity')}`}>
									<Nav.Link className='td-nav-title' onClick={() => changeTab('taskGroupActivity')}>
										{lan.activity}
									</Nav.Link>
								</Nav.Item>
							</Navbar>
						</Row>

						{tab === 'taskInfo' && (
							<GroupTaskSectionTask
								idGroup={idGroup}
								idGroupTask={props.tasksFromGroup}
								showTaskDetail={showTaskDetail}
								setCountNumberFinish={setCountNumberFinish}
								taskSelected={taskSelected}
								setTaskSelected={setTaskSelected}
								setLoadingActive={setLoadingActive}
								setFilterToExport={setFilterToExport}
								setFilterIdsTask={setFilterIdsTask}
							/>
						)}

							<GroupTaskInfo
								tab={tab}
								lan={lan}
								getOptionsBranch={getOptionsBranch}
								getInitialOptionsBranch={getInitialOptionsBranch}
								getOptionsTeam={getOptionsTeam}
								getInitialOptionsTeam={getInitialOptionsTeam}
								getOptionsPartner={getOptionsPartner}
								getInitialOptionsPartner={getInitialOptionsPartner}
								idCustomGroup={idCustomGroup}
								name={name}
								description={description}
								onTodoChange={onTodoChange}
								editOnly={editOnly}
								status={status}
								showPartnerSelect={showPartnerSelect}
								showBranchSelect={showBranchSelect}
								showTeamSelect={showTeamSelect}
								selectedBranch={selectedBranch}
								selectedTeam={selectedTeam}
								selectedPartner={selectedPartner}
								selectedAgents={selectedAgents}
								setFieldBranch={setFieldBranch}
								setFieldAgent={setFieldAgent}
								activateAlert={activateAlert}
								dateSelected={dateSelected}
								handleProgramDate={handleProgramDate}
								minTime={minTime}
								dontShowToManager={dontShowToManager}
								addAgent={addAgent}
								removeAgent={removeAgent}
								agentsSelectList={agentsSelectList}
								agent={agent}
								idPartner={idPartner}
								handleOptionSelectedPartner={handleOptionSelectedPartner}
								managerName={managerName}
								showSubmitButton={showSubmitButton}
								handleDoActionUpdateTaskGroup={handleDoActionUpdateTaskGroup}
								checkNoAssing={checkNoAssing}
								checkTeams={checkTeams}
								checkPartner={checkPartner}
								checkAgent={checkAgent}
								setFieldTeam={setFieldTeam}
								showAgentSearch={showAgentSearch}
								showTeamSearch={showTeamSearch}
								showPartnerSearch={showPartnerSearch}
								showNoAssign={showNoAssign}
								activateLoadingManager={activateLoadingManager}
								isPartner={isPartner}
							/>

						<GroupTaskComments lan={lan} comments={comments} />
						<EditGroupTaskActivity lan={lan} tab={tab} groupActivity={groupActivity} />

						{showDetailTask && (
							<TaskDetails
								{...props}
								onClose={() => {
									setIdTaskDetail(null);
									setShowDetailTask(false);
								}}
								onCloseModal={() => {
									setShowDetailTask(false)
								}}
								idTasks={[idTaskDetail]}
								canNotEdit={false}
								lan={lan}
							/>
						)}
					</div>
				</Modal.Body>
			</Modal>

			{showExportTaskPopUp && (
				<GenerateExportTaskPopup
					{...props}
					onClose={handleCloseGenerateTaskReportPopup}
					idGroup={idGroup}
					filter={filterToExport}
					lan={lan}
					filterFilterIdsTask = {filterFilterIdsTask}
				/>
			)}
		</React.Fragment>
	);
};

export default EditGruopTask;
