export function getAccountPreferences(component, session) {
	if (session) {
		let asset = false;
		let inventory = false;
		let form = false;
		let group = false;
		let autoassign = false;
		let skills = false;
		let personalization = false;
		let isochronous = false;
		let routeOpt = false;
		let apiPref = false;
		let resourceAlloc = false;
		let tag = false;
		let sms = false;
		let dynamicAllocation = false;
		let calendarDownload = false;
		let taskType = false;
		let advancedAnalytics = false;
		let mailIntegration = false;
		let routeExpenses = false;
		let roleManagement = false;
		let formUploadExcelTemplate = false;

		let allowManagerCreate = false;
		let allowAgentExecuteTask = false;
		let allowAgentCreateTask = false;
		let allowManagerAccessRoute = false;
		let allowManagerAccessResource = false;
		let allowManagerAccessIso = false;
		let allowManagerDownloadTask = false;
		let allowManagerToImportTask = false;
		let allowAdminAccessAnalytics = false;
		let allowManagerAccessAnalytics = false;
		let allowAccessClient = false;
		let allowClientToImport = false;
		let allowClientToExport = false;
		let allowManagerToModifyTasksStatus = false;
		let allowManagerToDownloadFormExcelTaskDetail = false;
		let allowManagerToEditFormTaskDetail = false;
		let allowManagerToGenerateAndDownloadInventories = false;
		let allowPartnerToEditBranch = false;
		let allowPartnerToGenerateForms = false;
		let allowManagerToGenerateAndDownloadAssets = false;
		let allowManagerToGenerateAndDownloadForms = false;
		let allowManagerToSeeTaskByMail = false;
		let allowManagerToGeneratePdf = false;
		let allowManagerToApproveForms = false;
		let allowManagerToGenerateAutoAssignTasks = false;
    	let allowAdminToModifyTasksStatus = false;

		if (session.account && session.account.preferences != null) {
			if (session.account.preferences.allowManagerToCreate === true) {
				allowManagerCreate = true;
			}
			if (session.account.preferences.allowAgentToAllwaysExecuteTasks === true) {
				allowAgentExecuteTask = true;
			}
			if (session.account.preferences.allowAgentToCreateTasks === true) {
				allowAgentCreateTask = true;
			}
			if (session.account.preferences.allowManagerAccessToRoute === true) {
				allowManagerAccessRoute = true;
			}
			if (session.account.preferences.allowManagerAccessToResource === true) {
				allowManagerAccessResource = true;
			}
			if (session.account.preferences.allowManagerAccessToIsochronous === true) {
				allowManagerAccessIso = true;
			}
			if (session.account.preferences.allowManagerAccessToDownloadTasks === true) {
				allowManagerDownloadTask = true;
			}
			if (session.account.preferences.allowManagerAccessToImportTasks === true) {
				allowManagerToImportTask = true;
			}
			if (session.account.preferences.allowAdminAccessToAnalytics === true) {
				allowAdminAccessAnalytics = true;
			}
			if (session.account.preferences.allowManagerAccessToAnalytics === true) {
				allowManagerAccessAnalytics = true;
			}
			if (session.account.preferences.allowAccessClient === true) {
				allowAccessClient = true;
			}
			if (session.account.preferences.allowClientToImport === true) {
				allowClientToImport = true;
			}
			if (session.account.preferences.allowClientToExport === true) {
				allowClientToExport = true;
			}
			if (session.account.preferences.allowManagerToModifyTasksStatus === true) {
				allowManagerToModifyTasksStatus = true;
			}

			if (session.account.preferences.allowManagerToDownloadFormExcelTaskDetail === true) {
				allowManagerToDownloadFormExcelTaskDetail = true;
			}

			if (session.account.preferences.allowManagerToEditFormTaskDetail === true) {
				allowManagerToEditFormTaskDetail = true;
			}

			if (session.account.preferences.allowManagerToGenerateAndDownloadInventories === true) {
				allowManagerToGenerateAndDownloadInventories = true;
			}

			if (session.account.preferences.allowPartnerToEditBranch === true) {
				allowPartnerToEditBranch = true;
			}

			if(session.account.preferences.allowPartnerToGenerateForms === true) {
				allowPartnerToGenerateForms = true;
			}
			
			if (session.account.preferences.allowManagerToGenerateAndDownloadAssets === true) {
				allowManagerToGenerateAndDownloadAssets = true;
			}

			if (session.account.preferences.allowManagerToGenerateAndDownloadForms === true) {
				allowManagerToGenerateAndDownloadForms = true;
			}
	
			if (session.account.preferences.allowManagerToSeeTaskByMail === true) {
				allowManagerToSeeTaskByMail = true;
			}
			
			if (session.account.preferences.allowManagerToGeneratePdf === true) {
				allowManagerToGeneratePdf = true;
			}

      		if (session.account.preferences.allowManagerToApproveForms === true) {
				allowManagerToApproveForms = true;
			}

      		if (session.account.preferences.allowManagerToGenerateAutoAssignTasks === true) {
				allowManagerToGenerateAutoAssignTasks = true;
			}

			if (session.account.preferences.allowAdminToModifyTasksStatus === true) {
				allowAdminToModifyTasksStatus = true;
			}
		}

		if (session.account && session.account.funcionalityPurchases.length > 0) {
			const functionalitiesPurchased = session.account.funcionalityPurchases;

			for (let i = 0; i < functionalitiesPurchased.length; i++) {
				if (functionalitiesPurchased[i].functionality.customCode === 'AUTO_ASSIGN') {
					autoassign = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'ASSETS') {
					asset = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'INVENTORY') {
					inventory = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'FORMS') {
					form = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'GROUPS') {
					group = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'RESOURCE_ALLOCATION') {
					resourceAlloc = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'PERSONALIZATION_MODULE') {
					personalization = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'ISOCHRONOUS_MODULE') {
					isochronous = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'ROUTE_OPTIMIZATION') {
					routeOpt = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'TAGS') {
					tag = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'FIELDER_API') {
					apiPref = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'SKILLS') {
					skills = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'SMS') {
					sms = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'TASK_TYPE') {
					taskType = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'ADVANCED_ANALYTICS') {
					advancedAnalytics = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'MAIL_INTEGRATION') {
					mailIntegration = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'DYNAMIC_ALLOCATION') {
					dynamicAllocation = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'CALENDAR_DOWNLOAD') {
					calendarDownload = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'ROUTE_EXPENSES') {
					routeExpenses = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'ROLE_MANAGEMENT') {
					roleManagement = true;
				} else if (functionalitiesPurchased[i].functionality.customCode === 'FORMS_UPLOAD_EXCEL_TEMPLATE') {
					formUploadExcelTemplate = true;
				}
			}
		}

		component.setState(
			{
				showAutoAssign: autoassign,
				showAsset: asset,
				showInventory: inventory,
				showForms: form,
				showRouteOpt: routeOpt,
				showResourceAlloc: resourceAlloc,
				showApiPref: apiPref,
				showSkills: skills,
				showIsochronous: isochronous,
				showPersonalization: personalization,
				showTags: tag,
				showGroups: group,
				showSms: sms,
				showDynamicAllocation: dynamicAllocation,
				showTaskType: taskType,
				showAdvancedAnalytics: advancedAnalytics,
				showMailIntegration: mailIntegration,
				showCalendarDownload: calendarDownload,
				showRouteExpenses: routeExpenses,
				showRoleManagement: roleManagement,
				showFormsUploadExcelTemplate: formUploadExcelTemplate,

				allowManagerCreate,
				allowAgentExecuteTask,
				allowAgentCreateTask,
				allowManagerAccessRoute,
				allowManagerAccessResource,
				allowManagerAccessIso,
				allowManagerDownloadTask,
				allowManagerToImportTask,
				allowAdminAccessAnalytics,
				allowManagerAccessAnalytics,
				allowAccessClient,
				allowClientToImport,
				allowClientToExport,
				allowManagerToModifyTasksStatus,
				allowManagerToDownloadFormExcelTaskDetail,
				allowManagerToEditFormTaskDetail,
				allowManagerToSeeTaskByMail,
				allowManagerToGeneratePdf,
       	 		allowManagerToApproveForms,
				allowManagerToGenerateAutoAssignTasks,
        		allowAdminToModifyTasksStatus,
				allowManagerToGenerateAndDownloadInventories,
				allowManagerToGenerateAndDownloadAssets,
				allowManagerToGenerateAndDownloadForms,
				allowPartnerToEditBranch,
				allowPartnerToGenerateForms,
				accountInfoUpdated: true,
				loading: false,
			},
			() => {}
		);
	} else {
		component.setState({ accountModules: 'Cannot get modules info', loading: false }, () => {});
	}
}

export const accountPreferences = (session) => {};
