export const ENDPOINTS = {
	root: '/',
	login: '/login',
	recoverPassword: '/recoverPassword',
  cognitoProvider: '/cognitoProvider',
	register: '/register',
  logoutSSOMobile: '/logoutSSOMobile',
	globalSearch: '/globalSearch',
	invoice: '/invoice',
	taskDetails: '/taskDetails',
	dashboard: '/dashboard',
	agents: '/agents',
	profile: '/profile',
	configuration: '/configuration',
	administrators: '/administrators',
	managers: '/managers',
	partners: '/partners',
	teams: '/teams',
	myOperation: '/myOperation',
	commandCenter: '/commandCenter',
	companies: '/companies',
	branches: '/branches',
	clients: '/clients',
	support: '/support',
	createTask: '/createTask',
	changePassword: '/changePassword',
	calendar: '/calendar',
	analytics: '/analytics',
	marketplace: '/marketplace',
	forms: '/forms',
	assetManagement: '/assetManagement',
	inventory: '/inventory',
	autoAssign: '/autoAssign',
	userApiPreferences: '/userApiPreferences',
	skills: '/skillsConfig',
	isochronous: '/isochronous',
	taskTypes: '/taskTypes',
	routeOptimization: '/routeOptimization',
	resourceAllocation: '/resourceAllocation',
	sms: '/sms',
	dynamicAllocation: '/dynamicAllocation',
	expenses: '/expenses',
	globalAccountDashboard: '/globalAccount/dashboard',
	globalAccountClientDashboard: '/globalAccount/client/dashboard',
	internalAdminDashboard: '/internalAdmin/dashboard',
	accounts: '/accounts',
	records: '/records',
	globalUser: '/globalUser',
	accountReview:'/accountReview',
	billingSummary: '/billing/summary',
	roleManagement: '/roleManagement',
	massiveDownload: '/taskFilesMassiveDownload',
	forbidden: '/forbidden',
	notFound: '/404',
};
