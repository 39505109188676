import {
	LightGreySubtitleColor,
	AquaMarine,
	PersianGreen,
} from '@core/theme/baseTheme';
import { FilledInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = styled(Typography)`
	font-family: 'Lato';
	font-size: 11px;
	color: ${LightGreySubtitleColor};
`;

export const LogInput = styled(FilledInput)(({ updated }) => ({
	'&.MuiFilledInput-root': {
		fontFamily: 'Lato',
		fontSize: '0.8rem',
		backgroundColor: `${updated ? `${AquaMarine}50` : '#8d909417'} !important`,
		color: `${updated ? `${PersianGreen}` : LightGreySubtitleColor} !important`,
		border: `none !important`,
		'&.Mui-disabled': {
			backgroundColor: `${updated ? `${AquaMarine}50` : '#8d909417'} !important`,
			color: `${updated ? `${PersianGreen}` : LightGreySubtitleColor} !important`,
			border: `none !important`,
		}

	},
	'&.MuiFilledInput-input.MuiInputBase-inputSizeSmall ': {
		paddingTop: '10px !important'
	},
	'&.MuiInputBase-input.MuiInputBase-inputSizeSmall ': {
		paddingTop: '10px !important'
	}

}));
