import React, { Component } from 'react';

import { sanitizeInput } from '@core/utils/dompurify/dompurify.js';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import searchIcon from '@assets/icons/search-icon.svg';
import ErrorCodes from 'FielderUtils/ErrorCodes';
import skillsConst from 'FielderUtils/roleManagementConst/skillsConst';
import { GOOGLE_API_KEY } from 'FielderUtils/session/Session';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Geocode from 'react-geocode';
import { formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber 
} from 'react-phone-number-input';
import { connect } from 'react-redux';
import Select from 'react-select';
import CreateSkillsCard from './CreateSkillsCard';
import Chips from '~/Chips/Chips.component';
import { FIELDER } from '@core/constants/base.constants';
import { isRoleValid } from '../../core/utils/roles/validateRoles';
import ErrorPopup from '../Autoassign/popups/ErrorPopup';
import RepeatedSkillPopup from '../Autoassign/popups/RepeatedSkillPopup';
import 'react-phone-number-input/style.css';
import './EditCreateDetailAgent.css';
import 'FielderUtils/style.css';
import VehicleTypeContainer from './VehicleTypeContainer';
import Location from '~/Location/Location';
import { createAgentRequest } from '@core/api/agents/agents.service';
import { getAllActionsSkillsRequest, getSkills } from '@core/api/skills/skills.service';
import { Avatar } from '@mui/material';
import defaultProfilePicture from '@assets/icons/topMenuThumbnailProfile-icon.png';
import TextField from '~/TextField/TextField.component';
import FielderButton from 'FielderComponents/FielderButton';
import { validateUsername, validateName, validateLastname, validateSecondEmail, validateEmail } from '@core/utils/validations/FieldValidations'
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import {FielderPhoneField} from '~/FielderElements/FielderElements.component';

const { txt: TXT_ID, btn: BTN_ID, dtpckr: DTPCKR_ID } = FIELDER.idElement;
let request = require('FielderRequestTypes');
let beginningTime;
let endTime;
let validTime;
let control = false;
Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();
class CreateDetailAgent extends Component {
	constructor(props) {
		super(props);
		let editImage = require('@assets/icons/edit-icon.svg');
		let exampleMap = require('@assets/icons/edit-icon.svg');
		this.state = {
			showModal: true,
			userInfo: {
				userName: '',
				password: '',
				name: '',
				lastName: '',
				email: '',
				phone: '',
				status: 0,
				companyName: '',
				licencePlate: '',
				vehicleType: '1',
				workScheduleFrom: '12:00',
				workScheduleTo: '12:00',
				location: {
					state: '',
					country: '',
					postalCode: '',
					timeZone: '',
					address: '',
					latitude: '',
					longitud: '',
				},
				skills: [],
			},
			countryIsoName: '',
			skillsToAdd: [],
			skills: [],
			skillsRendered: [],
			disabledInput: false,
			activateAlert: false,
			UserImage: null,
			editImageIcon: editImage,
			exampleMap: exampleMap,
			editAgentActive: false,
			countryPhone: '',
			userName: null,
			password: null,
			name: null,
			lastName: null,
			email: null,
			validateSameEmail: true,
			phone: null,
			status: null,
			companyName: null,
			showLicencePlate: null,
			vehicleType: 1,
			workScheduleFrom: null,
			workScheduleTo: null,
			skillsH: null,
			showSkills: false,
			countryCallingCode: this.props.lan.phoneNumberInput,
			popups: {
				showRepeatedPopup: false,
				showErrorPopup: false,
			},
			errors: {
				location: false,
			},
			showCreateSkll: false,
			canDeleteSkills: false,
			errorMobilePhone: ''
		};
	}
	componentDidMount = () => {
		if (this.props.skillsElegible) {
			this.getAccountSkills();
		}

		const info = this.props.session;
		let showSkills = false;
		if (info.account && info.account.funcionalityPurchases.length != 0) {
			var functionalitiesPurchased = info.account.funcionalityPurchases;

			for (let i = 0; i < functionalitiesPurchased.length; i++) {
				if (functionalitiesPurchased[i].functionality.customCode === 'SKILLS') {
					showSkills = true;
				}
			}
			let state = this.state;
			state.showSkills = showSkills;
			this.setState(state);
		}

		const canDeleteSkills =
			this.props.canDeleteSkill && isRoleValid(skillsConst.SKILLS, skillsConst.DELETE, this.props.session.roles);
		const canCreateSkills = isRoleValid(skillsConst.SKILLS, skillsConst.CREATE, this.props.session.roles);

		let state = this.state;
		state.canDeleteSkills = canDeleteSkills;
		state.canCreateSkills = canCreateSkills;
		this.setState(state);

		this.forceUpdate();
	};
	hidePopup = (name) => {
		let state = this.state;
		state.popups[name] = false;
		this.setState(state);
	};
	updateLocation = (newLocation, coordinates) => {
		let location = this.state.userInfo.location;

		if (coordinates.lat != location.latitude && coordinates.lng != location.longitud) {
			location.latitude = coordinates.lat;
			location.longitud = coordinates.lng;
			location.address = newLocation.address;
			location.postalCode = newLocation.postalCode;
			location.state = newLocation.state;
			location.timeZone = newLocation.timezone;
			location.country = newLocation.country;

			let state = this.state;
			state.userInfo.location = location;
			this.setState(state);
		}
	};
	handleChangeDirection = (event) => {
		let state = this.state;
		state.userInfo.location.address = event.target.value;
		this.setState(state);
	};
	handleEnabled = (e) => {
		e.preventDefault();
		this.setState({
			disabledInput: !this.state.disabledInput,
			editAgentActive: !this.state.editAgentActive,
		});
	};
	addTagSkills = (newTag) => {
		var tagList = this.state.userInfo.skills;
		var tagListRendered = this.state.skillsRendered;

		if (!tagList.includes(newTag.value)) {
			// Add new tag
			tagList.push(newTag.value);
			tagListRendered.push(newTag.label);

			// Reset input and send new type to state
			let state = this.state;
			state.userInfo.skills = tagList;
			state.skillsRendered = tagListRendered;
			this.setState(state);
		}
	};
	updateSkills = (event) => this.addTagSkills(event);
	removeTagSkills = (id) => {
		let state = this.state;
		var index = state.skillsRendered.indexOf(id);
		state.userInfo.skills.splice(index, 1);
		state.skillsRendered.splice(index, 1);
		this.setState(state);
	};
	getAccountSkills = () => {
		const onSuccess = ({ skills }) => {
			if (skills && skills.length > 0) {
				// Add the skills to the state
				const skillOptions = skills.map((skill) => {
					let title = skill.title
						.toLowerCase()
						.split(' ')
						.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
						.join(' ');

					return {
						label: title,
						value: skill.id,
					};
				});

				// Clean the skills array
				let state = this.state;
				state.skills = skillOptions;
				state.skillsToAdd = [];
				this.setState(state);
			}
		};

		const loadingConfig = {
			dispatch: this.props.dispatch,
		};
		getSkills(onSuccess, null, loadingConfig);
	};
	updateUserName = (event) => {
		let state = this.state;

		let userNameTrim = event.target.value;
		let userNameWithTrim = userNameTrim.replace(/\s/g, '');

		const sanitizedInput = sanitizeInput(userNameWithTrim);
		state.userInfo.userName = sanitizedInput;
		this.setState(state);
	};
	updateName = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.name = sanitizedInput;
		this.setState(state);
	};
	updateLastName = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.lastName =sanitizedInput;
		this.setState(state);
	};
	updateEmail = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.email = sanitizedInput;
		this.setState(state);
	};
	updateEmailConfirm = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.emailConfirm = sanitizedInput;
		this.setState(state);
	}

	updatePassword = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.password = sanitizedInput;
		this.setState(state);
	};
	updateCompanyName = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.companyName = sanitizedInput;
		this.setState(state);
	};
	updateVehicleType = (event) => {
		let state = this.state;
		state.userInfo.vehicleType = event.target.value;
		if (event.target.value === '6') {
			state.userInfo.licencePlate = '';
		}
		this.setState(state);
	};
	updateLicensePlate = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.licencePlate = sanitizedInput;
		this.setState(state);
	};
	validateCellPhone = (cellphone) => {
		if(cellphone.length > 0 && isValidPhoneNumber(cellphone)) {
			return true;
		} else {
			const state = this.state;
			state.errorMobilePhone = this.props.lan.messageErrorPhone
			return false; 
		}
	};
	validateSchedule = () => {
		var from = this.state.userInfo.workScheduleFrom;
		var to = this.state.userInfo.workScheduleTo;

		if (!from || !to) {
			document.getElementById('Agent-schedule-alert-icon').style.display = 'inline-block';
			return false;
		}

		validTime = moment(from, 'h:mm a').isBefore(moment(to, 'h:mm a'));

		if (!validTime) {
			document.getElementById('Agent-schedule-alert-icon').style.display = 'inline-block';
			return false;
		}

		document.getElementById('Agent-schedule-alert-icon').style.display = 'none';
		return true;
	};
	validateLicensePlate = () => {
		var name = this.state.userInfo.licencePlate;
		var nameRegex = new RegExp('^\\s+$');

		if (name !== null && !nameRegex.test(name) && name.length >= 1 && this.state.userInfo.vehicleType !== '6') {
			return true;
		} else if (this.state.userInfo.vehicleType === '6') {
			return true;
		}

		return false;
	};
	validateLocation = () => {
		let location = this.state.userInfo.location;
		return location.latitude !== 0 && location.longitud !== 0 && location.address !== '';
	};
	formatTime = () => {
		let fromTime = this.state.userInfo.workScheduleFrom;
		let toTime = this.state.userInfo.workScheduleTo;

		if (fromTime.charAt(0) == '0') {
			let state = this.state;
			state.userInfo.workScheduleFrom = fromTime.slice(1);
			this.setState(state);
		}

		if (toTime.charAt(0) == '0') {
			let state = this.state;
			state.userInfo.workScheduleTo = toTime.slice(1);
			this.setState(state);
		}
	};
	handleCreateAgent = () => {
		const secondEmail = document.getElementById(TXT_ID.emailConfirm).value;
		const isValidUserName = validateUsername(this.state.userInfo.userName);
		const isValidName = validateName(this.state.userInfo.name);
		const isValidLastName = validateLastname(this.state.userInfo.lastName);
		const isValidEmail = validateEmail(this.state.userInfo.email);
		const isValidSecondEmail = validateSecondEmail(this.state.userInfo.email, secondEmail);
		const isValidPhone = this.validateCellPhone(this.state.userInfo.phone);
		const isValidSchedule = this.validateSchedule();
		const isValidLicensePlate = this.validateLicensePlate();
		const isValidLocation = this.validateLocation();

		let state = this.state;
		state.userName = isValidUserName;
		state.name = isValidName;
		state.lastName = isValidLastName;
		state.email = isValidEmail;
		state.phone = isValidPhone;
		state.activateAlert = !isValidSchedule;
		state.showLicencePlate = isValidLicensePlate;
		state.validateSameEmail = isValidSecondEmail;
		state.errors.location = !isValidLocation;
		this.setState(state);

		if (
			isValidUserName &&
			isValidName &&
			isValidLastName &&
			isValidEmail &&
			isValidPhone &&
			isValidSchedule &&
			isValidLicensePlate &&
			isValidSecondEmail &&
			isValidLocation
		) {
			this.formatTime();
			document.getElementById('register-form-hint').style.display = 'none';
			this.createAgentAction();
		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
	};
	createAgentAction = () => {
		const body = {
			agent: this.state.userInfo,
			isoName: this.state.countryIsoName,
		};

		this.setState({ showModal: false });

		const loadingConfig = {
			name: 'CREATE_AGENT',
			dispatch: this.props.dispatch,
		};

		const onSuccess = () => this.props.handleCreateSuccess();
		const onError = ({ status }) => this.handleCreateError(status);
		createAgentRequest(body, onSuccess, onError, loadingConfig);
	};
	handleCreateError = (errorCode) => {
		let errorMessageText;
		switch (errorCode) {
			case ErrorCodes.DUPLICATED_USERNAME:
				errorMessageText = this.props.lan.agentDuplicatedUserName;
				break;
			case ErrorCodes.FAIL_DUPLICATE_COGNITO_USER:
				errorMessageText = this.props.lan.agentDuplicatedUserName;
				break;
			case ErrorCodes.USER_DOES_NOT_HAVE_PERMISSION:
				errorMessageText = this.props.lan.createAgentPermission;
				break;
			default:
				errorMessageText = this.props.lan.createUserError;
				break;
		}
		this.props.handleCreateError(errorMessageText);
	};
	InputInfo = (props) => {
		return (
			<div className='Agent-input-help'>
				<p>*{props.info}</p>
			</div>
		);
	};
	checkAlert = () => {
		if (endTime != null) {
			if (validTime === false && control === false) {
				control = true;
				this.setState({ activateAlert: !this.state.activateAlert });
			}

			if (validTime === true && control === true) {
				control = false;
				this.setState({ activateAlert: !this.state.activateAlert });
			}
		}
	};
	onChangeTimeOne = (value) => {
		if (value != undefined) {
			var timeFrom = new Date(value._d);
			beginningTime = timeFrom;
			this.validateTime();
		}
	};
	onChangeTimeTwo = (value) => {
		if (value != undefined) {
			var timeFromTo = new Date(value._d);
			endTime = timeFromTo;
			this.validateTime();
		}
	};
	validateTime = () => {
		if (beginningTime != null && endTime != null) {
			let initialTime = beginningTime;
			let finalTime = endTime;
			validTime = moment(initialTime, 'h:mm a').isBefore(moment(finalTime, 'h:mm a'));

			if (validTime == true) {
				let formatInitialTime = moment(initialTime, 'hh:mm a').format('HH:mm');
				let formatEndTime = moment(finalTime, 'hh:mm a').format('HH:mm');

				let state = this.state;
				state.userInfo.workScheduleFrom = formatInitialTime;
				state.userInfo.workScheduleTo = formatEndTime;
				state.activateAlert = false;
				this.setState(state);
			} else {
				let state = this.state;
				state.activateAlert = true;
				this.setState(state);
			}
		}
	};
	saveSkills = () => {
		const body = {
			requestType: request.getRequestDoAddAccountSkills().requestType,
			skillNames: this.state.skillsToAdd,
		};

		const onSuccess = this.getSkills;
		const onError = () => {
			let state = this.state;
			state.popups.showErrorPopup = true;
			this.setState(state);
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		getAllActionsSkillsRequest(body, onSuccess, onError, loadingConfig);
	};
	removeSkill = (index, skill) => {
		let skills = this.state[skill];
		skills.splice(index, 1);

		switch (skill) {
			case 'skillsToAdd':
				this.setState({ skillsToAdd: skills });
				break;
		}
	};
	addSkill = () => {
		let input = document.getElementById('input-add-skill');
		let newSkill = input.value;

		// Lists of skills
		var skillList = this.state.skillsToAdd;

		if (!skillList.includes(newSkill) && newSkill != '') {
			// Add new skill
			skillList.push(newSkill);

			// Reset input and send new type to state
			this.setState({ skillsToAdd: skillList });
		} else {
			// Show the repeated skill popup
			let state = this.state;
			state.popups.showRepeatedPopup = true;
			this.setState(state);
		}

		input.value = '';
	};
	render() {
		return (
			<>
			{this.state.popups.showRepeatedPopup && 
				<RepeatedSkillPopup hidePopup={this.hidePopup} {...this.props} />}
			{this.state.popups.showErrorPopup && 
				<ErrorPopup hidePopup={this.hidePopup} {...this.props} />}
			<div className='row mb-5'>
				<div className='col-12 col-md-6 px-4'>
					<div className='row mt-4'>
						<div className='col'>
							<Avatar src={this.state.UserImage || defaultProfilePicture} sx={{ height: '100%', width: '100%' }} />
						</div>
						<div className='col-8 my-auto'>
							<TextField
								id={TXT_ID.username}
								label={`${this.props.lan.userName}*`}
								value={this.state.userInfo.userName}
								onChange={this.updateUserName}
								error={this.state.userName === false}
								helperText={this.state.userName === false && this.props.lan.userNameFieldHelp}
							/>
						</div>
					</div>

					<div className='row my-3'>
						<div className='col-sm-12'>
							<TextField
								id={TXT_ID.name}
								value={this.state.userInfo.name}	
								onChange={this.updateName}
								label={`${this.props.lan.name}*`}
								error={this.state.name === false}
								helperText={this.state.name === false && this.props.lan.nameFieldHelp}
							/>
						</div>
					</div>

					<div className='row my-3'>
						<div className='col-sm-12'>
							<TextField
								id={TXT_ID.lastName}
								value={this.state.userInfo.lastName}
								onChange={this.updateLastName}
								label={`${this.props.lan.lastName}*`}
								error={this.state.lastName === false}
								helperText={this.state.lastName === false && this.props.lan.lastNameFieldHelp}
							/>
						</div>
					</div>

					<div className='row my-3'>
						<div className='col-sm-12'>
							<TextField
								id={TXT_ID.email}
								value={this.state.userInfo.email}
								onChange={this.updateEmail}
								label={`${this.props.lan.email}*`}
								error={this.state.email === false}
								helperText={this.state.email === false && this.props.lan.emailFieldHelp}
							/>
						</div>
					</div>
					<div className='row my-3'>
						<div className='col-sm-12'>
							<TextField
								id={TXT_ID.emailConfirm}
								value={this.state.emailConfirm}
								onChange={this.updateEmailConfirm}
								label={`${this.props.lan.confirmEmail}*`}
								error={this.state.validateSameEmail === false}
								helperText={this.state.validateSameEmail === false && this.props.lan.validateSameEmail}
							/>
						</div>
					</div>

					<div className='row my-3'>
						<div className='col-sm-12'>
							<TextField
								id={TXT_ID.companyName}
								value={this.state.userInfo.companyName}
								onChange={this.updateCompanyName}
								label={this.props.lan.company}
							/>
						</div>
					</div>

					<div className='row my-3'>
						<div className='col-sm-12'>
							<FielderPhoneField 
								id={TXT_ID.mobile}
								label={this.props.lan.mobilePhone}
								value={this.state.userInfo.phone}
								onChange={value => {
									const valueNumber = value || '';
									const formattedPhone = formatPhoneNumberIntl(valueNumber);
									let parsedNumber;
									try {
										parsedNumber = parsePhoneNumber(valueNumber);
									} catch (err) {
										console.warn('Failed to parse phone number:', err);
									}
									this.setState(prevState => ({
										...prevState,
										errorMobilePhone: '',
										userInfo: {
											...prevState.userInfo,
											phone: formattedPhone
										},
										countryIsoName: parsedNumber?.country,
										countryCallingCode: parsedNumber?.countryCallingCode
									}));
								}}
								lan={this.props.lan}
								isRequired={true}
								externalError={this.state.errorMobilePhone}
							/>
						</div>
					</div>

					<div className='row my-3'>
						<div className='col-sm-12 lato-font-label mb-1'>{`${this.props.lan.workingHours}*`}</div>
						<div className='col-sm-11'>
							<Row className='ml-2'>
								<Col sm={4}>
									<Datetime
										id={DTPCKR_ID.workingHoursFrom}
										inputProps={{
											placeholder: this.props.lan.from,
											readOnly: true,
										}}
										dateFormat={false}
										onChange={this.onChangeTimeOne}
									/>
								</Col>
								<Col sm='auto'>
									<p> &nbsp; - &nbsp; </p>
								</Col>
								<Col sm={4}>
									<Datetime
										id={DTPCKR_ID.workingHoursTo}
										inputProps={{
											placeholder: this.props.lan.to,
											readOnly: true,
										}}
										dateFormat={false}
										onChange={this.onChangeTimeTwo}
									/>
								</Col>
								<Col sm={1}>
									<img
										id='Agent-schedule-alert-icon'
										src={redAlertIcon}
										alt=''
										className='Agent-schedule-alert-icon'
									/>
								</Col>
							</Row>
						</div>

						{this.state.activateAlert && (
							<div className='col-sm-12 pl-4'>
								<this.InputInfo info={this.props.lan.createAgentWorkingHoursFieldHelp} />
							</div>
						)}
					</div>

					{this.state.showSkills && (
						<>
							<div className='col-sm-12 lato-font-label mb-1'>{this.props.lan.skills}</div>
							{this.state.skillsH === false && <this.InputInfo info='Selecciona al menos un skill para agente' />}
							<div className='row my-3'>
								<div className='col-sm-11 TemplateAA-body-row-search'>
									<Row>
										<Col sm='auto'>
											<img src={searchIcon} alt='' className='TemplateAA-search-img-edit' />
										</Col>
										<Col>
											<Select
												className='TemplateAA-body-row-skills-search'
												options={this.state.skills}
												onChange={this.updateSkills}
												placeholder={this.props.lan.search}
											/>
										</Col>
									</Row>
								</div>
								<div className='col-sm-1 mt-2'>
									<img
										src={redAlertIcon}
										alt=''
										id='Agent-skills-alert-icon'
										className='Agent-fieldContainer-icon'
									/>
								</div>
							</div>

							<div className='col-12 mt-2'>
								<Chips
									elements={this.state.skillsRendered}
									onDelete={this.state.disabledInput ? null : this.removeTagSkills}
								/>
							</div>
						</>
					)}

					{this.state.showSkills && this.state.canCreateSkills && (
						<div className='row mx-1'>
							<CreateSkillsCard
								canDelete={this.props.canDelete}
								isPopup={this.props.popup}
								disabled={this.state.disabledInput}
								getSkillsFromAccount={this.getAccountSkills}
							/>
						</div>
					)}
				</div>
				<div className='col-12 col-md-6 px-4'>
					<VehicleTypeContainer
						lan={this.props.lan}
						updateVehicleType={this.updateVehicleType}
						userInfo={this.state.userInfo}
						disabledInput={this.state.disabledInput}
					/>
					{this.state.userInfo.vehicleType !== '6' && (
						<div className='row my-3'>
							<div className='col-sm-12 font-inputs'>
								<TextField
									id={TXT_ID.licensePlate}
									label={`${this.props.lan.plate}*`}
									value={this.state.userInfo.licencePlate}	
									onChange={this.updateLicensePlate}
									error={this.state.showLicencePlate === false}
									helperText={
										this.state.showLicencePlate === false && this.props.lan.licensePlateFieldHelp
									}
								/>
							</div>
						</div>
					)}
					<div className='col-12'>
						<Location
							edit
							includeCoordinates
							height='200px'
							label={this.props.lan.createAgentStartingPointField}
							locationUpdate={this.updateLocation}
							error={this.state.errors.location}
						/>
					</div>

					<div id='register-form-hint' className='TemplateAA-form-hint mt-3'>
						<p>{`*${this.props.lan.requiredFields}`}</p>
					</div>

					<div className='row'>
						<div className='col-6 mx-auto'>
							<FielderButton id={BTN_ID.create} onClick={this.handleCreateAgent}>
								{this.props.lan.create}
							</FielderButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(CreateDetailAgent);
export function withSnackbar(Component) {
	return function WrappedComponent(props) {
		const fielderMsg = useFielderSnackBar();
		return <Component {...props} fielderMsg={fielderMsg} />;
	};
}
