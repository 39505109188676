import API_LINKS from 'FielderAPILinks';

/**
 *
 * @param {string} filter       - Filter to search the partners by
 * @param {number} idAccount    - Id of the account in use
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the partners found (returns empty if error)
 */
export const getAssetsByFilter = (filter, idAccount, idToken, ignoreAssetStatus) => {
	let body = {
		type: 'GET_ASSETS_BY_FILTER',
		idAccount: idAccount,
		filter: filter,
		ignoreStatus: ignoreAssetStatus ? ignoreAssetStatus : false,
	};

	return fetch(API_LINKS.functionalityTaskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res && res.success === true) {
				return res.assets;
			} else {
				console.error(res);
				return [];
			}
		});
};

/**
 *
 * @param {string} filter       - Filter to search the partner by
 * @param {number} idAccount    - Id of the account in use
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all partners by the filter send and sets the information and format for the asyncSelect component
 */
export const getAssetsByFilterAsyncSelect = async (
	filter,
	idAccount,
	idToken,
	ignoreAssetStatus,
	lan
) => {
	let assets = await getAssetsByFilter(filter, idAccount, idToken, ignoreAssetStatus);
	let assetList = [];

	assets.forEach((asset) => {
		let label = `${asset.id} - ${asset.name}`;
		let assetInfo = [];

		if (asset.category) {
			label += ` [${asset.category.name}]`;
		}

		if (asset.brand) {
			assetInfo.push(asset.brand + ' (' + lan.brand + ')');
		}
		if (asset.type) {
			assetInfo.push(asset.type + ' (' + lan.type + ')');
		}
		if (asset.model) {
			assetInfo.push(asset.model + ' (' + lan.model + ')');
		}
		if (asset.serialNumber) {
			assetInfo.push(asset.serialNumber + ' (' + lan.serialNumber + ')');
		}
		if (asset.contract) {
			assetInfo.push(asset.contract + ' (' + lan.contractNumber + ')');
		}

		label += assetInfo.join(' - ');
		assetList.push({
			label: label,
			value: asset,
		});
	});

	return assetList;
};
