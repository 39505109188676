const AdvancedFilterUtilColum = {
	ID_TASK: 0,
	ID_CUSTOM: 1,
	FOLIO: 2,
	PRIORITY: 3,
	TYPE_OF_TASK: 4,
	STATUS: 5,
	ID_PARENT_TASK: 6,
	PARTNER: 7,
	TAGS: 8,
	NAME: 9,
	BRANCH_ID: 10,
	BRANCH_NAME: 11,
	COMPANY: 12,
	COUNTRY: 13,
	DIRECTION: 14,
	AGENT: 15,
	GROUP: 16,
	STATUS_GROUP: 17,
	SCORE: 18,
	CASE_NUMBER:19
};

export default AdvancedFilterUtilColum;
