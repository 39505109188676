import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, TextField, Box, Typography, Grid, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FielderButton from 'FielderComponents/FielderButton';
import { styled } from '@mui/material/styles';
import { animateScroll as scroll } from 'react-scroll';
import withRouter from '~/withRouter/withRouter.component';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import LogoLogin from '@assets/icons/logo-login-main.svg';
import 'FielderUtils/style.css';
import './CognitoProvider.css';
import {
  setSelectedCognitoProvider,
  getSelectedCognitoProvider,
  removeSelectedCognitoProvider,
} from 'FielderUtils/session/Session';

const PageContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});
const FormContainer = styled(Box)({
  backgroundColor: 'white',
  margin: 'auto',
  padding: '50px',
  textAlign: 'center',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
});
const Logo = styled('img')({
  width: 'auto',
  marginBottom: '50px',
});
const BackButton = styled(Button)({
  backgroundColor: '#fff',
  color: '#dc5c87',
  border: '1px solid #dc5c87',
  '&:hover': {
    backgroundColor: '#dc5c87',
    color: '#fff',
    border: '1px solid #dc5c87',
  },
});
const CognitoProvider = ({ lan, navigate, dispatch, fielderMsg }) => {
  const [provider, setProvider] = useState('');
  const [showProvider, setShowProvider] = useState(false);
  const [adProviders] = useState({
    "smart": {
      "id": process.env.COGNITO_IDENTITY_PROVIDER_SMART,
      "name": "Smart",
    },
    "testLocal": {
      "id": process.env.COGNITO_IDENTITY_PROVIDER,
      "name": "Test Local Cognito",
    },
    "camsTech": {
      "id": process.env.COGNITO_IDENTITY_PROVIDER_CAMS,
      "name": "Cams Tech",
    },
    "StefaniniMexico": {
      "id": process.env.COGNITO_IDENTITY_PROVIDER_STEFANINI_MX,
      "name": "Stefanini México",
    }
  });
  useEffect(() => {
    scroll.scrollToBottom();
    const savedProvider = getSelectedCognitoProvider();
    if (savedProvider) {
      try {
        const parsedProvider = JSON.parse(savedProvider);
        setProvider(parsedProvider.id);
      } catch (error) {
        console.error("Error parsing stored provider:", error);
        removeSelectedCognitoProvider();
      }
    }
  }, []);
  const handleContinue = () => {
    if (!provider) {
      fielderMsg({
        message: lan.validProviderName,
        variant: 'error',
        closeButton: true,
        duration: 3000,
      });
      return;
    }
    const selectedProvider =
      Object.values(adProviders).find((p) => p.id === provider);
    if (!selectedProvider) {
      fielderMsg({
        message: lan.validProviderName,
        variant: 'error',
        closeButton: true,
        duration: 3000,
      });
      return;
    }
    setSelectedCognitoProvider(JSON.stringify(selectedProvider));
    fielderMsg({
      message: lan.successProviderName,
      variant: 'success',
      closeButton: true,
      duration: 3000,
    });
    handleBack();
  };
  const handleBack = () => {
    navigate('/login');
  };
  return (
    <PageContainer>
      <FormContainer>
        <Logo src={LogoLogin} alt="Salesforce Logo" />
        <Typography variant="h5" gutterBottom>
          {lan.selectSignInProvider}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {lan.useSignInProvider}
        </Typography>
        <TextField
          fullWidth
          label={lan.providerName}
          variant="outlined"
          margin="normal"
          type={showProvider ? "text" : "password"}
          value={provider}
          onChange={(e) => setProvider(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowProvider(!showProvider)} edge="end">
                  {showProvider ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box mt={2} display="flex" justifyContent="space-between">
          <Grid container>
            <Grid item xs={3}>
              <BackButton id={"btn-provider-back"} variant="outlined" onClick={handleBack} fullWidth>
                {lan.back}
              </BackButton>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <FielderButton id={"btn-provider-continue"} onClick={handleContinue}>
                {lan.selectedProvider}
              </FielderButton>
            </Grid>
          </Grid>
        </Box>
      </FormContainer>
    </PageContainer>
  );
};
export default connect()(withRouter(withSnackbar(CognitoProvider)));
function withSnackbar(Component) {
  return function WrappedComponent(props) {
    const fielderMsg = useFielderSnackBar();
    return <Component {...props} fielderMsg={fielderMsg} />;
  };
}
