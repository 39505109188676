import React, { Component } from 'react';

import FilterIcon from '@assets/icons/filter-icon.svg';
import AddIcon from '@assets/icons/red-add-icon.svg';
import { Badge as MuiBadge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TreeSelect } from 'antd';
import { hasAutoAssignFunctionality } from 'FielderUtils/autoassign/autoassign';
import TaskStatus from 'FielderUtils/status/taskStatus';
import { Row, Col } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';
import { getCookiesRequest, updateCookiesRequest } from '@core/api/cookie/cookie.service';
import { FIELDER } from '@core/constants/base.constants';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { getObjectCookie } from 'FielderUtils/cookie/cookieUtil';
import AdvancedFilterUtilColum from './AdvancedFilterUtilColum';
import AdvancedFilterUtilOperation from './AdvancedFilterUtilOperation';
import AdvancedFilterUtilOperator from './AdvancedFilterUtilOperator';
import {
	columnDataAvancedFilter,
	taskGroupStatusDataAvancedFilter,
	typeOfPauseTaskAdvancedFilter,
	taskStatusDataAvancedFilter,
	operationDataAvancedFilter,
	operatorDataAvancedFilter,
	operatorCategoryDataAvancedFilter,
} from './AvancedFilterUtil';

import './AdvancedFilter.css';
import TextField from '~/TextField/TextField.component';
import FielderButton from 'FielderComponents/FielderButton';
import { DeepSpaceGrey, FielderPink } from '@core/theme/baseTheme';

const Badge = styled(MuiBadge)`
	& .MuiBadge-badge {
		background-color: ${FielderPink};
		color: white;
	}
`

const { btn: BTN_ID, txt: TXT_ID } = FIELDER.idElement;

class AdvancedFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMenu: false,
			resetView: true,
			showApplyButton: true,
			defaultColumData: columnDataAvancedFilter(this.props.lan),
			auxColumn: columnDataAvancedFilter(this.props.lan),
			taskStatus: taskStatusDataAvancedFilter(this.props.lan),
			taskGroupStatus: taskGroupStatusDataAvancedFilter(this.props.lan),
			typeOfPause: typeOfPauseTaskAdvancedFilter(this.props.lan),

			filterOperation: operationDataAvancedFilter(this.props.lan),
			filterOperator: operatorDataAvancedFilter(this.props.lan),
			filterOperatorCategory: operatorCategoryDataAvancedFilter(this.props.lan),

			filters: [],
			sendFilters: [],
			cookieStorageFilter: [],
			menuAnchor: null,
		};
		this.menuRef = React.createRef();
	}

	componentDidMount = () => {
		let autoAssignFunctionality = hasAutoAssignFunctionality(this.props.session.account.funcionalityPurchases);
		if (autoAssignFunctionality) {
			var taskStatus = this.state.taskStatus;
			taskStatus.push(
				{
					label: 'taskStatus_12',
					value: TaskStatus.AUTOASSIGNING,
					disabled: false,
				},
				{
					label: 'taskStatus_13',
					value: TaskStatus.AUTOASSIGNED,
					disabled: false,
				},
				{
					label: 'taskStatus_14',
					value: TaskStatus.AUTOASSIGNED_FAILED,
					disabled: false,
				}
			);
		}
		this.loadCookieAdvancedFilters();
		window.addEventListener('click', this.handleOutsideClick);
	};

	componentWillUnmount = () => {
		window.removeEventListener('click', this.handleOutsideClick);
	}

	handleOutsideClick = (event) => {
		if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
		  this.setState({ showMenu: false });
		}
	};

	handleClick = () => {
		if (!this.props.disabled) {
			this.setState({ showMenu: !this.state.showMenu });
		}
	}


	addFilter = () => {
		let arrayFilter = this.state.filters;
		let setFilter = {};

		this.state.resetView = false;
		let operatorValue = AdvancedFilterUtilOperator.WHERE;

		if (arrayFilter.length >= 1) {
			operatorValue = AdvancedFilterUtilOperator.AND;
		}

		let auxColumn = this.state.auxColumn;
		let columnValue = 0;
		let label = '';

		for (let x = 0; x < auxColumn.length; x++) {
			if (auxColumn[x].disabled === false) {
				this.state.auxColumn[x].disabled = true;
				columnValue = auxColumn[x].value;
				label = auxColumn[x].label;
				break;
			}
		}

		if (
			columnValue != AdvancedFilterUtilColum.PRIORITY &&
			columnValue != AdvancedFilterUtilColum.TYPE_OF_TASK &&
			columnValue != AdvancedFilterUtilColum.STATUS &&
			columnValue != AdvancedFilterUtilColum.SCORE
		) {
			setFilter = {
				operator: operatorValue,
				columSelected: columnValue,
				label: label,
				operation: AdvancedFilterUtilOperation.CONTAINS,
				valueField: '',
				parent: columnValue,
				isParent: true,
				countCategory: 1,
			};
		} else if (
			columnValue == AdvancedFilterUtilColum.STATUS ||
			columnValue == AdvancedFilterUtilColum.STATUS_GROUP ||
			columnValue == AdvancedFilterUtilColum.SCORE
		) {
			setFilter = {
				operator: operatorValue,
				columSelected: columnValue,
				label: label,
				operation: AdvancedFilterUtilOperation.CONTAINS,
				valueField: 0,
				parent: columnValue,
				isParent: true,
				countCategory: 1,
			};
		} else {
			setFilter = {
				operator: operatorValue,
				columSelected: columnValue,
				label: label,
				operation: AdvancedFilterUtilOperation.CONTAINS,
				valueField: 1,
				parent: columnValue,
				isParent: true,
				countCategory: 1,
			};
		}

		arrayFilter.push(setFilter);

		//this.state.filters = arrayFilter;

		this.setState({ filters: arrayFilter }, () => {
			this.setState({ resetView: true });
		});
	};

	deltedFilter = (index, columnSelected, isParent, parent, e) => {
		if (e) {
			e.stopPropagation();
		}

		var auxColumn = this.state.auxColumn;

		this.state.resetView = false;
		var filtersValues = this.state.filters;
		var newArray = [];

		for (var x = 0; x < auxColumn.length; x++) {
			if (auxColumn[x].value == columnSelected && isParent) {
				this.state.auxColumn[x].disabled = false;
			}
		}

		if (isParent) {
			var auxFilter = filtersValues;

			for (var y = 0; y < auxFilter.length; y++) {
				if (parent != auxFilter[y].parent) {
					newArray.push(auxFilter[y]);
				}
			}
		} else {
			filtersValues.splice(index, 1);
			newArray = filtersValues;
		}

		var countCategory = 0;

		for (var x = 0; x < filtersValues.length; x++) {
			if (filtersValues[x].columSelected == columnSelected) {
				countCategory++;
			}
		}

		for (var x = 0; x < filtersValues.length; x++) {
			if (filtersValues[x].columSelected == columnSelected) {
				filtersValues[x].countCategory = countCategory;
			}
		}

		this.setState({ filters: newArray }, () => {
			this.setState({ resetView: true });
		});
	};

	addCategory = (index, parent, label, e) => {
		this.state.resetView = false;
		var filters = this.state.filters;

		const newFilter = {
			label,
			parent,
			columSelected: parent,
			operator: AdvancedFilterUtilOperator.OR,
			operation: AdvancedFilterUtilOperation.CONTAINS,
			isParent: false,
			countCategory: 1,
		};

		const ZeroDefaultValueColumns = new Set([
			AdvancedFilterUtilColum.STATUS,
			AdvancedFilterUtilColum.STATUS_GROUP,
			AdvancedFilterUtilColum.SCORE,
		]);
		const OneDefaultValueColumns = new Set([AdvancedFilterUtilColum.PRIORITY, AdvancedFilterUtilColum.TYPE_OF_TASK]);

		if (ZeroDefaultValueColumns.has(parent)) {
			newFilter.valueField = 0;
		} else if (OneDefaultValueColumns.has(parent)) {
			newFilter.valueField = 1;
		} else {
			newFilter.valueField = '';
		}

		filters.splice(index + 1, 0, newFilter);

		var countCategory = 0;

		for (var x = 0; x < filters.length; x++) {
			if (filters[x].columSelected === parent) {
				countCategory++;
			}
		}

		for (var x = 0; x < filters.length; x++) {
			if (filters[x].columSelected == parent) {
				filters[x].countCategory = countCategory;
			}
		}

		this.setState({
			filters,
			resetView: true,
		});
	};

	changeColumn = (value, valueSelected) => {
		try {
			this.state.resetView = false;

			var filtersAux = this.state.filters;
			var auxCol = this.state.auxColumn;
			var colValue = parseInt(value);
			var label = '';

			for (var x = 0; x < auxCol.length; x++) {
				if (auxCol[x].value == colValue) {
					label = auxCol[x].label;
					this.state.auxColumn[x].disabled = true;
				}
				if (auxCol[x].value == valueSelected) {
					this.state.auxColumn[x].disabled = false;
				}
			}

			for (var z = 0; z < filtersAux.length; z++) {
				if (filtersAux[z].columSelected == valueSelected) {
					filtersAux[z].columSelected = parseInt(value);
					filtersAux[z].label = label;
					filtersAux[z].parent = colValue;

					if (
						filtersAux[z].columSelected != AdvancedFilterUtilColum.PRIORITY &&
						filtersAux[z].columSelected != AdvancedFilterUtilColum.TYPE_OF_TASK &&
						filtersAux[z].columSelected != AdvancedFilterUtilColum.STATUS &&
						filtersAux[z].columSelected != AdvancedFilterUtilColum.STATUS_GROUP &&
						filtersAux[z].columSelected != AdvancedFilterUtilColum.SCORE
					) {
						filtersAux[z].valueField = '';
					} else if (
						filtersAux[z].columSelected == AdvancedFilterUtilColum.STATUS ||
						filtersAux[z].columSelected == AdvancedFilterUtilColum.STATUS_GROUP ||
						filtersAux[z].columSelected == AdvancedFilterUtilColum.SCORE
					) {
						filtersAux[z].valueField = 0;
					} else {
						filtersAux[z].valueField = 1;
					}
				}
			}

			this.setState({ filters: filtersAux }, () => {
				this.setState({ resetView: true });
			});
		} catch (error) {
			console.error(error);
		}
	};

	changeOperator = (event, index) => {
		let filters = this.state.filters;
		filters[index].operator = parseInt(event.target.value);

		this.setState({ filters });
	};

	changeOperation = (value, index) => {
		var filters = this.state.filters;

		for (var x = 0; x < filters.length; x++) {
			if (x == index) {
				filters[x].operation = parseInt(value);
				break;
			}
		}
		this.setState({ filters: filters });
	};

	changeInputValue = (event, index, useOnlyNumber) => {
		let state = this.state;
		const value = event.target.value;

		if (useOnlyNumber) {
			if (value === '' || /^[0-9\b]+$/.test(value)) {
				state.filters[index].valueField = value;
			}
		} else {
			state.filters[index].valueField = value;
		}

		this.setState(state);
	};

	changeSelectPriority = (value, index) => {
		var filters = this.state.filters;

		for (var x = 0; x < filters.length; x++) {
			if (x == index) {
				filters[x].valueField = parseInt(value);
				break;
			}
		}
		this.setState({ filters: filters });
	};

	
	changeRating = (newRating, index) => {
		var filters = this.state.filters;

		for (var x = 0; x < filters.length; x++) {
			if (x == index) {
				filters[x].valueField = newRating;
				break;
			}
		}
		this.setState({ filters: filters });
	};

	stopPropagation = (event) => {
		event.stopPropagation();
	};

	setTypeOfTask = (value, index) => {
		let filters = this.state.filters;


		for (let x = 0; x < filters.length; x++) {
			if (x == index) {
				filters[x].valueField = parseInt(value);
				break;
			}
		}
		this.setState({ filters: filters });
	};

	executeAdvancedFilters = () => {
		this.props.executeAdvancedFilters(this.state.filters);
		this.setState({ showMenu: !this.state.showMenu });
	};

	saveCookieAdvancedFilters = (e) => {
		if (e) {
			e.stopPropagation();
		}

		if (this.state.filters && this.state.filters.length > 0) {
			if (
				localStorage.getItem(this.props.cookieNameAvancedFilter) !== undefined &&
				localStorage.getItem(this.props.cookieNameAvancedFilter) !== null &&
				JSON.parse(localStorage.getItem(this.props.cookieNameAvancedFilter)).length > 0
			) {
				let storageValue = localStorage.getItem(this.props.cookieNameAvancedFilter);
				let cookieStorageFilter = JSON.parse(storageValue);

				let filterName = this.props.lan.filter + (cookieStorageFilter.length + 1);
				if (document.getElementById(TXT_ID.filterName).value && document.getElementById(TXT_ID.filterName).value != '') {
					filterName = document.getElementById(TXT_ID.filterName).value;
				}

				let filterStorage = {
					name: filterName,
					filter: this.state.filters,
				};
				cookieStorageFilter.push(filterStorage);
				localStorage.setItem(this.props.cookieNameAvancedFilter, JSON.stringify(cookieStorageFilter));

				const cookieName = this.props.cookieNameAvancedFilter;
				const body = getObjectCookie(cookieName, cookieStorageFilter);
				updateCookiesRequest(body);
				this.setState({ showApplyButton: false, cookieStorageFilter: cookieStorageFilter }, () => {
					this.refreshViewCookie();
				});
			} else {
				let filterName = this.props.lan.filter + '1';
				if (
					document.getElementById(TXT_ID.filterName).value &&
					document.getElementById(TXT_ID.filterName).value != ''
				) {
					filterName = document.getElementById(TXT_ID.filterName).value;
				}

				let filterStorage = [
					{
						name: filterName,
						filter: this.state.filters,
					},
				];

				localStorage.setItem(this.props.cookieNameAvancedFilter, JSON.stringify(filterStorage));

				const cookieName = this.props.cookieNameAvancedFilter;
				const body = getObjectCookie(cookieName, filterStorage);
				updateCookiesRequest(body);

				this.setState({ showApplyButton: false, cookieStorageFilter: filterStorage }, () => {
					this.refreshViewCookie();
				});
			}
		}
	};

	refreshViewCookie = () => {
		this.setState({ showApplyButton: true });
	};

	loadCookieAdvancedFilters = () => {
		if (
			localStorage.getItem(this.props.cookieNameAvancedFilter) !== undefined &&
			localStorage.getItem(this.props.cookieNameAvancedFilter) !== null &&
			JSON.parse(localStorage.getItem(this.props.cookieNameAvancedFilter)).length > 0
		) {
			let storageValue = localStorage.getItem(this.props.cookieNameAvancedFilter);
			let cookieStorageFilter = JSON.parse(storageValue);

			this.setState({ showApplyButton: false, cookieStorageFilter: cookieStorageFilter }, () => {
				this.refreshViewCookie();
			});
		} else {
			const cookieName = this.props.cookieNameAvancedFilter;
			const body = {
				filterCookie: {
					cookieName,
				},
			};

			const onSuccess = (response) => {
				let avancedFiltersCookie = response.avancedFiltersCookie;
				if (avancedFiltersCookie && avancedFiltersCookie.length > 0) {
					localStorage.setItem(this.props.cookieNameAvancedFilter, JSON.stringify(avancedFiltersCookie));

					this.setState({ showApplyButton: false, cookieStorageFilter: avancedFiltersCookie }, () => {
						this.refreshViewCookie();
					});
				}
			};

			getCookiesRequest(body, onSuccess, null, null);
		}
	};

	removeAdvancedFilters = (index, e) => {
		if (e) {
			e.stopPropagation();
		}

		if (
			localStorage.getItem(this.props.cookieNameAvancedFilter) !== undefined &&
			localStorage.getItem(this.props.cookieNameAvancedFilter) !== null
		) {
			let storageValue = localStorage.getItem(this.props.cookieNameAvancedFilter);
			let cookieStorageFilter = JSON.parse(storageValue);
			cookieStorageFilter.splice(index, 1);

			localStorage.setItem(this.props.cookieNameAvancedFilter, JSON.stringify(cookieStorageFilter));

			var auxColumn = this.state.defaultColumData;
			for (var x = 0; x < auxColumn.length; x++) {
				auxColumn[x].disabled = false;
			}

			const cookieName = this.props.cookieNameAvancedFilter;
			const body = getObjectCookie(cookieName, cookieStorageFilter);
			updateCookiesRequest(body);

			this.setState({ filters: [], auxColumn: auxColumn, cookieStorageFilter: cookieStorageFilter });
		}
	};

	loadAdvancedFilters = (index) => {
		if (
			localStorage.getItem(this.props.cookieNameAvancedFilter) !== undefined &&
			localStorage.getItem(this.props.cookieNameAvancedFilter) !== null
		) {
			this.state.resetView = false;

			let storageValue = localStorage.getItem(this.props.cookieNameAvancedFilter);
			let cookieStorageFilter = JSON.parse(storageValue);
			let cookieAvancedFilter = cookieStorageFilter[index].filter;

			var auxColumn = this.state.defaultColumData;

			for (var x = 0; x < auxColumn.length; x++) {
				auxColumn[x].disabled = false;
			}

			for (var y = 0; y < cookieAvancedFilter.length; y++) {
				for (var x = 0; x < auxColumn.length; x++) {
					if (auxColumn[x].value == cookieAvancedFilter[y].columSelected && cookieAvancedFilter[y].isParent === true) {
						auxColumn[x].disabled = true;
					}
				}
			}

			this.setState({ filters: cookieAvancedFilter, auxColumn: auxColumn }, () => {
				this.setState({ resetView: true });
			});
		}
	};

	createFilter = () =>
		this.state.filters.map((filter, index) => (
			<React.Fragment>
				{filter.isParent && index !== 0 && <hr className='solid-line' />}

				<Row className={`mt-1 ${(filter.isParent === false && 'pl-4') || ''}`} key={'allRow-' + index}>
					{index === 0 ? (
						<div className='col-auto my-auto' key={'where-' + index}>
							<span key={'span-' + index} style={{ fontSize: '15px' }} aria-hidden='true'>
								{this.props.lan.where}
							</span>
						</div>
					) : (
						<div className='col-auto ml-1'>
							{filter.isParent ? (
								<select
									key={'select-operator' + index}
									value={filter.operator}
									className='custom-select'
									id='operator-id'
									onChange={(e) => this.changeOperator(e, index)}
								>
									{this.state.filterOperator.map((operator, indexOperator) => (
										<option id={"row_" + index + '_operator_' + indexOperator} value={operator.value}>
											{`${this.props.lan[operator.label]}`}
										</option>
									))}
								</select>
							) : (
								<select
									key={'select-operator' + index}
									value={filter.operator}
									className='custom-select'
									id='operator-id'
									onChange={(e) => this.changeOperator(e, index)}
								>
								{this.state.filterOperatorCategory.map((operator, indexOperator) => (
									<option id={"row_"+index+'_operator_'+indexOperator} value={operator.value}>
										{`${this.props.lan[operator.label]}`}
									</option>
								))}
								</select>
							)}
						</div>
					)}

					{filter.isParent && index !== 0 && (
						<div className='col-auto my-auto px-1' key={'where-y-' + index}>
							<span key={'span-' + index} style={{ fontSize: '15px' }} aria-hidden='true'>
								{this.props.lan.where}
							</span>
						</div>
					)}

					<div className='col-auto px-2'>
						{filter.isParent ? (
							<select
								key={'select-colum' + index}
								value={filter.columSelected}
								className='custom-select'
								id='taskType-red-input'
								onChange={(e) => this.changeColumn(e.target.value, filter.columSelected)}
							>
								{this.state.auxColumn.map((column, key) => (
									<option key={'col' + index + '' + key} disabled={column.disabled} value={column.value}>
										{this.props.lan[column.label]}
									</option>
								))}
							</select>
						) : (
							<select
								key={'select-colum-child'  + filter.label + index}
								defaultValue={filter.columSelected}
								className='custom-select'
								id='taskType-red-input'
							>
								<option key={'col'+ filter.label + index} disabled={true} value={filter.columSelected}>
									{this.props.lan[filter.label]}
								</option>
							</select>
						)}
					</div>
					<div className='col-auto px-2'>
						<select
							id='taskType-red-input'
							key={'select-operation' + index}
							defaultValue={filter.operation}
							className='custom-select'
							onChange={(e) => this.changeOperation(e.target.value, index)}
						>
							{this.state.filterOperation.map((operation, keyOperation) => (
								<option key={'opera' + index + '' + keyOperation} value={operation.label}>
									{`${this.props.lan[operation.label]}`}
								</option>
							))}
						</select>
					</div>

					{(filter.columSelected == AdvancedFilterUtilColum.ID_TASK ||
						filter.columSelected == AdvancedFilterUtilColum.ID_CUSTOM ||
						filter.columSelected == AdvancedFilterUtilColum.ID_PARENT_TASK ||
						filter.columSelected == AdvancedFilterUtilColum.BRANCH_ID ||
						filter.columSelected == AdvancedFilterUtilColum.SCORE) &&
						filter.columSelected != AdvancedFilterUtilColum.PRIORITY &&
						filter.columSelected != AdvancedFilterUtilColum.TYPE_OF_TASK &&
						filter.columSelected != AdvancedFilterUtilColum.STATUS &&
						filter.columSelected != AdvancedFilterUtilColum.STATUS_GROUP &&
						filter.columSelected != AdvancedFilterUtilColum.SCORE &&
						filter.columSelected != AdvancedFilterUtilColum.TYPE_PAUSE_REASON && (
							<div className='col px-2'>
								<TextField
									id={`${TXT_ID.filterCondition}-${index}`}
									key={`${TXT_ID.filterCondition}-${index}`}
									label={this.props.lan.value}
									onChange={(e) => this.changeInputValue(e, index, true)}
									value={filter.valueField}
									size='small'
								/>
							</div>
						)}

					{filter.columSelected != AdvancedFilterUtilColum.ID_TASK &&
						filter.columSelected != AdvancedFilterUtilColum.ID_CUSTOM &&
						filter.columSelected != AdvancedFilterUtilColum.ID_PARENT_TASK &&
						filter.columSelected != AdvancedFilterUtilColum.BRANCH_ID &&
						filter.columSelected != AdvancedFilterUtilColum.SCORE &&
						filter.columSelected != AdvancedFilterUtilColum.PRIORITY &&
						filter.columSelected != AdvancedFilterUtilColum.TYPE_OF_TASK &&
						filter.columSelected != AdvancedFilterUtilColum.STATUS &&
						filter.columSelected != AdvancedFilterUtilColum.STATUS_GROUP &&
						filter.columSelected != AdvancedFilterUtilColum.SCORE && 
						filter.columSelected != AdvancedFilterUtilColum.TYPE_PAUSE_REASON &&(
							<div className='col px-2'>
								<TextField
									id={`${TXT_ID.filterValue}-${index}`}
									key={`${TXT_ID.filterValue}-${index}`}
									label={this.props.lan.value}
									onChange={(e) => this.changeInputValue(e, index, false)}
									value={filter.valueField}
									size='small'
								/>
							</div>
						)}

					{filter.columSelected == AdvancedFilterUtilColum.PRIORITY && (
						<div className='col px-2'>
							<select
								id='severity-red-input'
								key='select-country'
								defaultValue={filter.valueField}
								className='custom-select'
								onChange={(e) => this.changeSelectPriority(e.target.value, index)}
							
							>
								{this.props.taskPriority.map((priority, keyOperation) => (
									<option key={'opera' + index + '' + keyOperation} value={priority.value}>
										{priority.label}
									</option>
								))}
							</select>
						</div>
					)}

					{filter.columSelected == AdvancedFilterUtilColum.TYPE_OF_TASK && (
						<div className='col px-2'>
							<div onClick={this.stopPropagation}>
								<TreeSelect
									className='full-width'
									treeData={this.props.taskTypes}
									dropdownStyle={{ maxHeight: '400px', overflow: 'auto' }}
									onChange={(e) => {
										this.setTypeOfTask(e, index);
									}}
									placeholder={this.props.lan.taskType}
									defaultValue={filter.valueField}
								/>
							</div>
						</div>
					)}

					{filter.columSelected == AdvancedFilterUtilColum.STATUS && (
						<div className='col px-2'>
							<select
								id='severity-red-input'
								key='select-country'
								defaultValue={filter.valueField}
								className='custom-select'
								onChange={(e) => this.changeSelectPriority(e.target.value, index)}
								
							>
								{this.state.taskStatus.map((taskStatus, keyOperation) => (
									<option key={'opera' + index + '' + keyOperation} value={taskStatus.value}>
										{`${this.props.lan[taskStatus.label]}`}
									</option>
								))}
							</select>
						</div>
					)}

					{filter.columSelected == AdvancedFilterUtilColum.TYPE_PAUSE_REASON && (
						<div className='col px-2'>
							<select
								id='severity-red-input'
								key='select-country'
								defaultValue={filter.valueField}
								className='custom-select'
								onChange={(e) => this.changeSelectPriority(e.target.value, index)}
								
							>
								{this.state.typeOfPause.map((typeOfPause, keyOperation) => (
									<option key={'opera' + index + '' + keyOperation} value={typeOfPause.value}>
										{`${this.props.lan[typeOfPause.label]}`}
									</option>
								))}
							</select>
						</div>
					)}

					{filter.columSelected == AdvancedFilterUtilColum.STATUS_GROUP && (
						<div className='col px-2'>
							<select
								id='severity-red-input'
								key='select-country'
								defaultValue={filter.valueField}
								className='custom-select'
								onChange={(e) => this.changeSelectPriority(e.target.value, index)}
								
							>
								{this.state.taskGroupStatus.map((taskGroupStatus, keyOperation) => (
									<option key={'opera' + index + '' + keyOperation} value={taskGroupStatus.value}>
										{`${this.props.lan[taskGroupStatus.label]}`}
									</option>
								))}
							</select>
						</div>
					)}

					{filter.columSelected == AdvancedFilterUtilColum.SCORE && (
						<div className='col px-2'>
							<StarRatings
								rating={filter.valueField}
								starSpacing='1px'
								starDimension='15px'
								numberOfStars={5}
								changeRating={(e) => this.changeRating(e, index)}
								starRatedColor='yellow'
								starHoverColor='yellow'
							/>
						</div>
					)}

					{/* ADD SAME CATEGORY */}
					<div className='col-auto my-auto'>
						<>
							{filter.isParent && (
								<IconButton id={`${BTN_ID.filterAdd}-${index}`} size='small'>
									<img
										style={{ width: '34px' }}
										src={AddIcon}
										onClick={(e) => this.addCategory(index, filter.columSelected, filter.label, e)}
									/>
								</IconButton>
							)}
							<IconButton
								id={`${BTN_ID.filterRemove}-${index}`}
								key={`${BTN_ID.filterRemove}-${index}`}
								size='small'
								onClick={(e) => this.deltedFilter(index, filter.columSelected, filter.isParent, filter.parent, e)}
							>
								<ClearIcon color={DeepSpaceGrey} />
							</IconButton>
						</>
					</div>
				</Row>
			</React.Fragment>
		));

	CookieFilter = () => {
		if (!this.state.cookieStorageFilter.length) {
			return null;
		}

		const filter = this.state.cookieStorageFilter.map((filter, i) => (
			<Col sm={'auto'} key={i + '-tag'}>
				<Row className='tag-button my-auto'>
					<div className='col-auto mb-1 pr-1' key={i + 'close-tag-container'}>
						<button
							key={'btn-close-' + i}
							id={'btn-close-' + i}
							className='close'
							aria-label='Close'
							onClick={(e) => this.removeAdvancedFilters(i, e)}
						>
							<span style={{ fontSize: '20px' }} aria-hidden='true'>
								&times;
							</span>
						</button>
					</div>

					<div className='col pl-0 pt-1' key={i + 'load-tag-container'}>
						<button
							key={'btn-close-' + i}
							id={'btn-close-' + i}
							className='close pt-1 pl-1 font-sizeAvancedFilter'
							aria-label='Close'
							onClick={() => this.loadAdvancedFilters(i)}
						>
							{filter.name}
						</button>
					</div>
				</Row>
			</Col>
		));

		return <div className='row mt-4 mb-3 mx-3'>{filter}</div>;
	};

	render() {
		return (
			<div ref={this.menuRef} className='fielder-advancedfiltermenu' style={this.props.style}>
				<Badge badgeContent={this.state.filters.length}>
					<button
						onClick={this.handleClick}
						className='fielder-clear-button'
						style={{ display: 'inline-block' }}
						disabled={this.props.disabled}
					>
						<img src={FilterIcon} style={{ marginRight: '7px', width: '18px', height: '18px' }} />
						<span style={{ marginLeft: '7px' }}>{this.props.lan.advancedFilter}</span>
					</button>
				</Badge>

				{this.state.showMenu && (
					<div className='fielder-avenced-filter'>
						<div className='p-3'>
							{this.state.filters && this.state.filters.length === 0 && (
								<div className='col my-2'>
									<span style={{ marginLeft: '7px', color: 'gray' }}>{this.props.lan.userFilterTo}</span>
								</div>
							)}

							{this.state.resetView && <this.createFilter />}

							{this.state.showApplyButton && (
								<React.Fragment>
									<this.CookieFilter cookieStorageFilter={this.state.cookieStorageFilter} lan={this.props.lan} />

									<div className='row mt-2 mb-3'>
										<div className='col-auto'>
											<FielderButton id={BTN_ID.addFilterCondition} onClick={this.addFilter}>
												{this.props.lan.addFilter}
											</FielderButton>
										</div>

										<div className='col-5 ml-auto'>
											<TextField id={TXT_ID.filterName} size='small' label={this.props.lan.filterName} />
										</div>

										<div className='col-auto'>
											<FielderButton id={BTN_ID.filterSave} onClick={this.saveCookieAdvancedFilters}>
												{this.props.lan.saveFilter}
											</FielderButton>
										</div>

										<div className='col-auto'>
											<FielderButton id={BTN_ID.filterApply} onClick={this.executeAdvancedFilters}>
												{this.props.lan.apply}
											</FielderButton>
										</div>
									</div>
								</React.Fragment>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default AdvancedFilter;
