import React, { Component } from 'react';

import PartnerIcon from '@assets/icons/partner-icon.svg';
import {
	getPartnersByFilterAsyncSelect,
	getPartnerInitialOptionsAsyncSelect,
} from 'FielderUtils/partner/partner';
import { getIdToken } from 'FielderUtils/session/Session';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { assignPartnerTaskRequest } from '@core/api/task/task.service';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import Popup from '../../Popup';

import '../MyOperation.css';

class AssignPartnerPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			partnersListArray: null,
			idPartnerSelected: null,
			//Session
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
		};
	}

	assignPartner = () => {
		if (this.state.idPartnerSelected !== null) {
			for (let i = 0; i < this.props.idTasks.length; i++) {
				const body = {
					taskInfo: {
						idAccountPartner: this.state.idPartnerSelected,
						idTask: this.props.idTasks[i],
						idUser: this.props.session.user.idUser,
					},
				};


				const onSuccess = (response) => {
					this.props.onCloseResponse(true, response);
				}

				const onError = (error) => {
					this.props.onCloseResponse(false, error);
				}

				const loadingConfig = { dispatch: this.props.dispatch }
				assignPartnerTaskRequest(body, onSuccess, onError, loadingConfig)

			}
		} else {
			this.props.onCloseResponse(false);
		}
	};

	setPartner = (event) => {
		this.setState({
			idPartnerSelected: event.value,
		});
	};

	getPartnerList = async (event) => {
		if (event.length > 0) {
			let assignList = await getPartnersByFilterAsyncSelect(
				event,
				this.state.idAccount,
				this.state.idUser,
				getIdToken()
			);
			this.setState({ partnersListArray: assignList });
			return assignList;
		}
	};

	getInitialOptionsPartner = async () => {
		return await getPartnerInitialOptionsAsyncSelect(
			this.state.idAccount,
			this.state.idUser,
			getIdToken()
		);
	};

	render() {
		return (
			<Popup
				title={this.props.lan.assignPartner}
				icon='partner-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.assign}
				buttonOnClick={this.assignPartner}
			>


					<div style={{ color: '#DC5C87' }} className='row d-flex justify-content-end mr-3 mb-1'>
						{this.props.lan.partnerWarningAssing}
					</div>
					<Row className=''>
					<Col sm={'auto'} className='my-auto'>
						<img src={PartnerIcon} className='icon mr-auto' alt='' />
					</Col>
					<Col>
						<AsyncSearch
							className='react-select__control full-width'
							getOptions={(e) => this.getPartnerList(e)}
							onChange={(e) => this.setPartner(e)}
							label={this.props.lan.search}
							lan={this.props.lan}
							id='all-AsyncSearch'
							showInitialOptions
							getInitialOptions={() => this.getInitialOptionsPartner()}
						/>
					</Col>
				</Row>
			</Popup>
		);
	}
}
const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AssignPartnerPopup);
