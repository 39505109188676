export default {
	deleteAgent(agentid) {
		const data = {
			id: agentid,
		};
		return { data };
	},
	
	getNewTaskInfoUpdate(datePass, taskPass) {
		let user;
		if (taskPass.idUser == null) {
			user = null;
		} else {
			user = {
				idUser: taskPass.idUser,
				name: taskPass.clientName,
				lastName: taskPass.clientLastName,
				email: taskPass.clientEmail,
			};
		}
		let branch;
		if (taskPass.idBranch == null) {
			branch = null;
		} else {
			branch = {
				id: taskPass.idBranch,
			};
		}

		return {
			taskInfo: {
				startRangeHour: datePass.startRangeHour,
				startRangeMinute: datePass.startRangeMinute,
				startRangeDay: datePass.startRangeDay,
				startRangeMonth: datePass.startRangeMonth,
				startRangeYear: datePass.startRangeYear,
				endRangeHour: datePass.endRangeHour,
				endRangeMinute: datePass.endRangeMinute,
				endRangeDay: datePass.endRangeDay,
				endRangeMonth: datePass.endRangeMonth,
				endRangeYear: datePass.endRangeYear,

				task: {
					idTask: taskPass.idTask,
					startTimeSort: taskPass.startTimeSort,

					// Date Range
					startDateTimeRange: taskPass.startDateTimeRange,
					endDateTimeRange: taskPass.endDateTimeRange,

					description: taskPass.description,
					createTime: taskPass.createTime,
					status: taskPass.status,
					severity: taskPass.severity,
					serviceTypeId: taskPass.serviceTypeId,
					folio: taskPass.folio,
					caseNumber: taskPass.caseNumber,

					// Program Date
					startTime: taskPass.startTime,
					user,
					branch,
					startLocation: {
						idLocation: taskPass.idLocation,
						address: taskPass.address,
						state: taskPass.state,
						country: taskPass.country,
						postalCode: taskPass.postalCode,
						latitude: taskPass.latitude,
						longitud: taskPass.longitud,
					},
					// Tags
					tags: taskPass.tags,
					idTeam: taskPass.idTeam
				},
				routeVariables: taskPass.routeVariables,
				updateAdress: taskPass.updateAdress,
			},
		};
	},

	doActionCreateCompany(companyInfoPass) {
		let id = null;

		if (companyInfoPass.id != null) {
			id = {
				id: companyInfoPass.id,
			};
		}

		const data = {
			company: {
				idCustom: companyInfoPass.idCustom,
				name: companyInfoPass.name,
				businessName: companyInfoPass.businessName,
				parentCompany: id,
			},
		};
		return { data };
	},

	doActionCreateBranch(branchInfoPass) {
		let company;
		if (branchInfoPass.parentCompanyId != null) {
			company = {
				id: branchInfoPass.parentCompanyId,
			};
		}

		const data = {
			branch: {
				id: branchInfoPass.idBranch,
				idCustom: branchInfoPass.idCustom,
				name: branchInfoPass.name,
				company,
				location: {
					state: branchInfoPass.state,
					country: branchInfoPass.country,
					postalCode: branchInfoPass.postalCode,
					timeZone: branchInfoPass.timeZone,
					address: branchInfoPass.address,
					latitude: branchInfoPass.latitude,
					longitud: branchInfoPass.longitud,
				},
				attentionTimeFrom: branchInfoPass.attentionTimeFrom,
				attentionTimeTo: branchInfoPass.attentionTimeTo,
				legalAddress: branchInfoPass.legalAddress,
				nameMoreInfo: branchInfoPass.nameMoreInfo,
				lastNameMoreInfo: branchInfoPass.lastNameMoreInfo,
				emailMoreInfo: branchInfoPass.emailMoreInfo,
				cellphoneMoreInfo: branchInfoPass.cellphoneMoreInfo,
				officePhoneMoreInfo: branchInfoPass.officePhoneMoreInfo,
				commentMoreInfo: branchInfoPass.commentMoreInfo,
				idAccount: branchInfoPass.idAccount
			},
		};

		if (branchInfoPass.idLocation != null) {
			data.branch.location.idLocation = branchInfoPass.idLocation;
		}

		return { data };
	},

	getCreateNewTicket(category, description) {
		const data = {
			issuesArea: category,
			comment: description,
		};
		return { data };
	},
	getDataFile(bin, idTicket, mime) {
		const data = {
			file: bin,
			idTicketSupport: idTicket,
			contentType: mime,
		};
		return { data };
	},
	getDataMail(idTicket, category, comment) {
		const data = {
			id: idTicket,
			issuesAreaTitle: category,
			comment,
		};
		return { data };
	},
	getViewTask(
		requestType,
		offset,
		limit,
		fromDay,
		fromMonth,
		fromYear,
		toDay,
		toMonth,
		toYear,
		filter,
		tagFilter,
		onTrash,
		byEmail,
		datesFilterValues,
		dataToExport,
		advancedFilter
	) {
		const data = {
			type: requestType,
			offset,
			limit,
			fromDay,
			fromMonth,
			fromYear,
			toDay,
			toMonth,
			toYear,
			filter,
			tagsFilter: tagFilter,
			onTrash,
			byEmail,
			datesFilterType: datesFilterValues,
			dataToExport,
			avancedFilter: advancedFilter,
		};
		return data;
	},

	getExportCalendarTask(
		requestType,
		offset,
		limit,
		fromDay,
		fromMonth,
		fromYear,
		toDay,
		toMonth,
		toYear,
		datesFilterValues
	) {
		const data = {
			type: requestType,
			offset,
			limit,
			fromDay,
			fromMonth,
			fromYear,
			toDay,
			toMonth,
			toYear,
		};
		return { ...data };
	},

	getViewRoute(offset, limit, fromDay, fromMonth, fromYear, toDay, toMonth, toYear, filter, idTeam) {
		return {
			type: 'GET_VIEW_ROUTES',
			offset,
			limit,
			fromDay,
			fromMonth,
			fromYear,
			toDay,
			toMonth,
			toYear,
			filter,
			idTeam,
		};
	},
	getAgentsForRoute(fromDay, fromMonth, fromYear, toDay, toMonth, toYear, idTeam) {
		return {
			type: 'GET_AGENTS_FOR_ROUTE',
			fromDay,
			fromMonth,
			fromYear,
			toDay,
			toMonth,
			toYear,
			idTeam,
		};
	},

	getRequestRescheduleRoute(idRoute, year, month, day) {
		const data = {
			idRoute,
			toYear: year,
			toMonth: month,
			toDay: day,
		};
		return { data };
	},
	getRequestCancelRoutes(idRoutes, idAgents, comment) {
		const data = {
			idRoutes,
			idAgents,
			comment,
		};
		return { data };
	},
};
