import React, { useState, useEffect } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import {
	getBaseColumsGroupTask,
} from 'FielderUtils/groupTask/groupTaskColumn';
import TaskGroupStatus from '~/Status/TaskGroupStatus';
import groupTaskConst from 'FielderUtils/roleManagementConst/groupTasksConst';
import Delete from '@mui/icons-material/Delete';
import useValidateRoles from '@core/utils/roles/useValidateRoles';
import POPUPNAME from 'FielderUtils/popupName/popupName';
import { Row, Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import { SuperAdmin, Admin, Manager } from 'FielderUtils/roles/roles';
import MainTable from 'OldComponents/MainTable';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { getTaskGroupsRequest, getTaskGroupRealTimeRequest } from '@core/api/task/task.group.service';
import 'FielderUtils/style.css';

var moment = require('moment-timezone');
let selectedRows = [];

export default function TaskGroupTab(props) {

	const user = useSelector(selectUser);
	const lan = useLanguage();
	const [offset, setOffset] = useState(0);
	const [columns, setColumns] = useState(getBaseColumsGroupTask());
	const [rows, setRows] = useState([]);
	const { isRoleValid } = useValidateRoles();
	const [numberOfElements, setNumberOfElements] = useState(10);
	const [canDeleteGroupTask, setCanDeleteGroupTask] = useState(isRoleValid(groupTaskConst.GROUPTASK, groupTaskConst.DELETE))
	const [tooltips, setTooltips] = useState([]);

	const [timeTo, setTimeTo] = useState(null);
	const [filter, setFilter] = useState(null);
	const [executeLoadMore, setExecuteLoadMore] = useState(false);
	const [hasMore, setHasMore] = useState(true);



	useEffect(() => {
		loadMore();
	}, [props.to, props.filter, props.tagsFilter, props.executeLoadMore]);

	useEffect(() => {
		selectedRows = [];
	}, [props.refreshSelected]);

	useEffect(() => {
		if (props.rowToAddTaskGroup != null) {
			addRow(props.rowToAddTaskGroup);
		}
	}, [props.rowToAddTaskGroup]);

	useEffect(() => {
		if (props.rowToUpdateTaskGroup != null) {
			updateRow(props.rowToUpdateTaskGroup);
		}
	}, [props.rowToUpdateTaskGroup]);

	useEffect(() => {
		if (props.rowToUpdateStatusTaskGroup != null) {
			updateRowStatus(props.rowToUpdateStatusTaskGroup);
		}
	}, [props.rowToUpdateStatusTaskGroup]);

	useEffect(() => {
		if (canDeleteGroupTask) {
			setTooltips([
				{
					title: lan.deleteGroupTaskTooltip,
					action: () => {props.deleteGroupTask()},
					icon: <Delete />,
				},
			])
		}
	}, []);


	const addRow = (rowToAdd) => {
		const allRows = rows;
		const { idGroup } = rowToAdd;
		let willAddRow = true;

		for (let i = 0; i < allRows.length; i++) {
			if (allRows[i].idGroupTask == idGroup) {
				willAddRow = false;
				break;
			}
		}

		if (willAddRow) {
			const body = {
				idGroup: idGroup
			};

			const onSuccess = ({ group }) => {
				if (group != null) {
					if (
						props.filter == null &&
						props.showTaskGroupUpdates &&
						props.to == null
					) {
						allRows.unshift(groupTaskToRow(group));
						setRows(allRows);

						//setResetFlagOffset(true);
						props.addNumberOfElementsTaskGroup();
					} else if (!props.showTaskUpdates) {
						props.addNotificationCountRealGroup();
					}
				}
			};

			getTaskGroupRealTimeRequest(body, onSuccess);
		}
	};


	const updateRow = (rowToUpdate) => {

		const allRows = rows;
		const { idGroup } = rowToUpdate;

		if (props.showTaskGroupUpdates) {
			let updateRow = false;
			for (let i = 0; i < allRows.length; i++) {
				if (allRows[i].idGroupTask === idGroup) {
					var index = i;
					updateRow = true;
					break;
				}
			}


			const body = { idGroup: idGroup };

			const onSuccess = ({ group }) => {

				if (group != null) {
					const continueUpdatedRow = validateRowToUpdate(group);
					if (updateRow && continueUpdatedRow) {
						allRows[index] = groupTaskToRow(group);
						setRows([...allRows]);
					} else {
						allRows.unshift(groupTaskToRow(group));
						setRows([...allRows]);
					}
				}
			};

			getTaskGroupRealTimeRequest(body, onSuccess);


		} else {
			props.addNotificationCountRealGroup();
		}
	}

	const updateRowStatus = (rowToUpdate) => {
		const allRows = rows;
		const { idGroup } = rowToUpdate;

		if (props.showTaskGroupUpdates) {
			let updateRow = false;
			for (let i = 0; i < allRows.length; i++) {
				if (allRows[i].idGroupTask === idGroup) {
					var index = i;
					updateRow = true;
					break;
				}
			}

			if (updateRow) {
				const body = { idGroup: idGroup };

				const onSuccess = ({ group }) => {
					if (group != null) {
						allRows[index] = groupTaskToRow(group);
						setRows([...allRows]);
					}
				};

				getTaskGroupRealTimeRequest(body, onSuccess);
			}
		} else {
			props.addNotificationCountRealGroup();
		}
	}

	const validateRowToUpdate = (group) => {
		if (user && (user.role == SuperAdmin.name || user.role == Admin.name) && user.idAccount == group.idAccount) {
			return true;
		} else if (user && user.role === Manager.name && user.idTeams.includes(group.idTeam)) {
			return true;
		} else if (user && (user.role == SuperAdmin.name || user.role == Admin.name ) && user.idAccount == group.idPartner ) {
			return true;
		}
		return false;
	}

	const loadMore = () => {
		props.changeLoadingFilter(true);
		let offsetGroupTask = offset;

		if (
			timeTo !== props.to ||
			filter !== props.filter ||
			executeLoadMore !== props.executeLoadMore
		) {
			offsetGroupTask = 0;
			setHasMore(true);
		}

		let from = props.from ? props.from.getTime() : null;
		if (props.from == null) {
			let newDate = new Date();
			newDate.setMonth(newDate.getMonth() - 3);
			from = newDate.getTime();
		}

		let to = props.to ? props.to.setHours(23, 59, 59) : null;
		if (props.to == null) {
			let newDate = new Date();
			newDate.setMonth(newDate.getMonth() + 3);
			to = newDate.getTime();
		}

		const body = {
			filter: props.filter,
			offset: offsetGroupTask,
			limit:10,
			fromDate: from,
			toDate: to,
			activeGroupTask: true,
		};
		const onSuccess = ({ groupTask, count }) => {
			props.changeLoadingFilter(false);

			if (groupTask.length > 0) {
				//showNotGroupTask = false;
				setOffset(offsetGroupTask + 10);
				const newRows = rowsReducer(groupTask);

				if (newRows.concat === true) {
					let stateRow = rows;
					stateRow = stateRow.concat(newRows.newRows);
					setRows([...stateRow]);
				} else {
					setRows([...newRows.newRows]);
				}
			}

			if (count == 0) {
				const resetRows = [];
				setRows([...resetRows]);
				setHasMore(false);
			}

			props.setElementsGroupTask(count);

			setTimeTo(props.to);
			setFilter(props.filter);
			setExecuteLoadMore(props.executeLoadMore);

		};

		const onError = (e) => {
			props.changeLoadingFilter(false);
		}

		getTaskGroupsRequest(body, onSuccess, onError);
	}



	const tableCookie = {
		tableCookieName: 'taskGroupTable',
		colOrderCookieName: 'taskGroupTableColOrder',
		cookie: new Cookies(),
	};


	const rowsReducer = (groupTask) => {
		const newRows = groupTask ? groupTask.map(groupTaskToRow) : [];
		let concat = true;

		if (groupTask.length < numberOfElements) {
			setHasMore(false);
		}

		if (
			timeTo !== props.to ||
			filter !== props.filter ||
			executeLoadMore !== props.executeLoadMore
		) {
			concat = false;
		}

		return {
			concat,
			newRows,
		};


	}


	const groupTaskToRow = (taskGroup) => {

		return {
			idGroupTask: taskGroup.id,
			idCustomGroup: taskGroup.idCustomGroup,
			name: taskGroup.name,
			executionDate: taskGroup.executionDateTime
				? moment(taskGroup.executionDateTime).format('DD-MM-YYYY hh:mm a')
				: '',
			branch: taskGroup.branch ? taskGroup.branch.name : '',
			taskGroupStatusLowerCase: <TaskGroupStatus taskGroup={taskGroup} />,
			idAccount:taskGroup.idAccount
		}

	};




	const reorderModify = () => {
		if (rows != null && rows.length > 0) {
			const trueFirst = rows.sort((a, b) => Number(b.modifyTaskStatusComment) - Number(a.modifyTaskStatusComment));
			setRows([...trueFirst]);
		}
	};

	const onRowClickButton = (taskGroup) => props.onRowClickButtonAction(POPUPNAME.EDIT_GROUP_TASK, taskGroup);


	const onCheckboxClick = (selectedRow, selectAll) => {
		if (selectedRow != null) {
			const { idGroupTask } = selectedRow;
			let addValue = true;

			for (let i = 0; i < selectedRows.length; i++) {
				if (selectedRows[i].idGroupTask === idGroupTask) {
					if (!selectAll) {
						selectedRows.splice(i, 1);
					}
					addValue = false;
				}
			}

			if (addValue) {
				selectedRows.push(selectedRow);
			}
		} else {
			selectedRows = [];
		}

		if (selectedRows.length > 0) {
			props.handleCheckTaskGroupTab(selectedRows);
		} else {
			props.handleCheckTaskGroupTab(selectedRows);
		}
	};



	return (
		<Container fluid style={{ overflow: 'none' }}>
			<Row>
				<MainTable
					InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					reorderCols={setColumns}
					refreshSelected={props.refreshSelected}
					cols={columns}
					rows={rows}
					onCheckboxClick={onCheckboxClick}
					onRowClickButton={onRowClickButton}
					disabledDetailButton={props.disabledDetailButton}
					tooltips={tooltips}
					cookie={tableCookie}
					//getDynamoCookies
					//isMyOperation
					reorderModify={reorderModify}
					stickyHeader
					height={500}
					{...props}
				/>
			</Row>
		</Container>
	);
}
