const { API_LOGIN } = process.env;
const { API_TOP_MENU } = process.env;
const { API_TASK } = process.env;
const { API_FUNCTIONALITY_TASK } = process.env;
const { API_AUTO_ASSIGN_TASK } = process.env;
const { API_MODULES } = process.env;
const { API_AGENT } = process.env;
const { API_ACCOUNT } = process.env;
const { API_USER } = process.env;
const { API_USER_MANAGER } = process.env;
const { API_CALENDAR } = process.env;
const { API_DASHBOARD } = process.env;
const { API_ANALYTICS } = process.env;
const { API_COMMAND_CENTER } = process.env;
const { API_EXPORT_INFORMATION } = process.env;
const { API_ASSISTED_FORM } = process.env;
const { API_S3 } = process.env;
const { API_MASSIVE_DOWNLOAD } = process.env;
const { API_LEX_SENDER } = process.env;
const { API_PRESIGNED_URL_GENERATOR } = process.env;

const API_LINKS = {
	loginUserManager: `${API_LOGIN}/user_login`,
	topMenuInfo: `${API_TOP_MENU}/menuinfo`,
	topMenuNews: `${API_TOP_MENU}/getnews`,
	topMenuNotifications: `${API_TOP_MENU}/getsystemnotifications`,
	topMenuShareApp: `${API_TOP_MENU}/sendgeneralemail`,
	createTicketSupport: `${API_TOP_MENU}/createticketsupport`,
	updateNotifications: `${API_TOP_MENU}/updatestatussystemnotifications`,
	globalSearchManager: `${API_TOP_MENU}/globalsearchmanager`,
  taskManager: `${API_TASK}/task-manager`,
  assetManager: `${API_FUNCTIONALITY_TASK}/asset-manager`,
  inventoryManager: `${API_FUNCTIONALITY_TASK}/inventory-manager`,
  functionalityTaskManager: `${API_FUNCTIONALITY_TASK}/functionality-task-manager`,
  autoAssignTaskManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-task-manager`,
  autoAssignTemplateManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-template-manager`,
  autoAssignIsochronousManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-isochronous-manager`,
  autoAssignRouteManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-route-manager`,
	agentManager: `${API_AGENT}/agentManager`,
	register: `${API_ACCOUNT}/createaccount`,
	getPartners: `${API_ACCOUNT}/getpartners`,
	accountManager: `${API_ACCOUNT}/accountmanager`,
	userManager: `${API_USER}/usermanager`,
	apiUserManager: `${API_USER_MANAGER}/usermanager`,
	calendarManager: `${API_CALENDAR}/calendarmanager`,
	dashboardManager: `${API_DASHBOARD}/dashboardmanager`,
	analyticsManager: `${API_ANALYTICS}/analyticsmanager`,
	formsManager: `${API_MODULES}/formsmanager`,
	generalModules: `${API_MODULES}/generalmodulesmanager`,
	roleManagement: `${API_MODULES}/rolemanagement`,
	formsPdfParser: `${API_MODULES}/formspdfparsermanager`,
	formsHtmlParser: `${API_MODULES}/formshtmlparsermanager`,
	formsXlsxParser: `${API_MODULES}/formsxlsxparsermanager`,
	taskCalendarXlsx: `${API_MODULES}/tasktoexcelformat`,
	commandCenter: `${API_COMMAND_CENTER}`,
	commandCenterAgents: `${API_COMMAND_CENTER}/agents`,
	commandCenterTasks: `${API_COMMAND_CENTER}/tasks`,
	awsS3Link: `${API_S3}`,

	exportTasks: `${API_EXPORT_INFORMATION}/myOperation`,
	exportGroupTask: `${API_EXPORT_INFORMATION}/groupTask`,
	exportUsers: `${API_EXPORT_INFORMATION}/users`,
	exportBranches: `${API_EXPORT_INFORMATION}/branches`,
	exportAgents: `${API_EXPORT_INFORMATION}/agents`,
	exportCompanies: `${API_EXPORT_INFORMATION}/companies`,
	exportModules: `${API_EXPORT_INFORMATION}/modules`,
	exportReport: `${API_EXPORT_INFORMATION}/taskReport`,
	exportApprovedForms: `${API_EXPORT_INFORMATION}/approvedForms`,
	exportTaskWithForms: `${API_EXPORT_INFORMATION}/tasksWithForms`,

	assistedForm:`${API_ASSISTED_FORM}/form`,
	massiveTaskFilesDownload: `${API_MASSIVE_DOWNLOAD}/taskFilesMassiveDownload`,
	lexMessageSender: `${API_LEX_SENDER}/fielder-bedrock-lex`,
	presignedUrlGenerator: `${API_PRESIGNED_URL_GENERATOR}/s3_upload_url`,

	// NOT IN CODE
	// Review Teams API GATEWAY
	getPartnerInvitations: `${API_ACCOUNT}/getinvitations`,
	sendPartnerInvitations: `${API_ACCOUNT}/sendinvitationtopartner`,
	acceptPartnerInvitations: `${API_ACCOUNT}/acceptinvitation`,
	rejectPartnerInvitations: `${API_ACCOUNT}/rejectinvitation`,
	cancelPartnership: `${API_ACCOUNT}/cancelpartnership`,
	sendEmailUser: `${API_USER}/sendnewclientuseremail`,
	sendEmailUserAdministrator: `${API_USER}/sendnewuseradministratoremail`,
};

export default API_LINKS;
