import React, { useState, useEffect } from 'react';

import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { isClient, isExecutor, isOwner, isLastOwner, hasPartnerRelation } from '@core/utils/task/taskOwnership';
import { getStatus } from '@core/utils/task/taskStatusToShow';
import { Typography } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import { useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatusContainer, TaskStatusTooltip } from './styled';

const TaskStatus = ({ task, status, setStatusParent }) => {
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const controls = useAnimation();

	const [statusInfo, setStatusInfo] = useState(null);
	const [background, setBackground] = useState(null);
	const [color, setColor] = useState(null);
	const [firstTime, setFirstTime] = useState(true);

	useEffect(() => {
		let statusInformation = null;

		if (task) {
			const taskOwnership = {
				isClient: isClient(user, task),
				isExecutor: isExecutor(user, task),
				isOwner: isOwner(user, task),
				isLastOwner: isLastOwner(user, task),
				hasPartnerRelation: hasPartnerRelation(user, task),
			};

			statusInformation = getStatus(task.status, taskOwnership);
		} else {
			statusInformation = status;
		}

		if (statusInformation) {
			setStatusInfo(lan[statusInformation.text]);
			if(setStatusParent){
				setStatusParent(lan[statusInformation.text]);
			}
		
			setBackground(statusInformation.background);
			setColor(statusInformation.color);
		}
	}, [task, user, lan]);

	useEffect(() => {
		if (statusInfo == null) return;
		if (firstTime) {
			setFirstTime(false);
			return;
		}

		controls.start({
			scale: [1, 2, 2, 1, 1],
			rotate: [0, 0, 15, 15, 0],
		});
	}, [statusInfo, controls]);

	if (statusInfo === null) {
		return null;
	}

	return (
		<TaskStatusTooltip title={statusInfo} background={background} color={color}>
			<StatusContainer
				background={background}
				color={color}
				key={task && `task-status-${task.idTask}-${statusInfo}`}
				animate={controls}
			>
				<Typography noWrap style={{ fontFamily: 'Lato', fontSize: 14 }}>
					{statusInfo}
				</Typography>
			</StatusContainer>
		</TaskStatusTooltip>
	);
};

TaskStatus.propTypes = {
	task: PropTypes.object,
};

export default TaskStatus;
