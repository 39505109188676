import React, { useEffect, useState } from 'react';

import UserIcon from '@assets/icons/add-client-icon.svg';
import { getUserByFilterAsyncSelect } from 'FielderUtils/user/user';
import { getAgentsByFilterAsyncSelectMfa } from 'FielderUtils/agent/agent';
import { Row, Col, Nav, Navbar } from 'react-bootstrap';
import { useLanguage } from 'Context/LanguageContext';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser } from '@core/store/modules/session/sessionSelectors';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import Switch from '~/Switch/Switch.component';

import Popup from '../../Popup';

import { enableDisableMfaRequest, enableDisableMfaAgentRequest } from '@core/api/masterUser/masterUser.service';

const EnableDisableMfa = ({closeDefaultModal, closeModalSuccess, errorMessageCreation }) => {

	const lan = useLanguage();

	const [idUser, setIdUser] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [switchValue, setSwitchValue] = useState(false);
	const [userName, setUserName] = useState(null);

	const [idAgent, setIdAgent] = useState(null);
	const [disabledAgent, setAgentDisabled] = useState(true);
	const [switchAgentValue, setSwitchAgentValue] = useState(false);
	const [agentUserName, setAgentUserName] = useState(null);

	const [activaTab, setActiveTab] = useState('mfaUser');
	
	const user = useSelector(selectUser);
	const dispatch = useDispatch();

	const onChangeUser = (e) => {
		console.log(e)
		if(e != null){
			setIdUser(e.value.idUser);
			setUserName(e.value.userName);
			setSwitchValue(e.value.mfaAuthentication);
		} else {
			setIdUser(null);
			setUserName(null);
			setSwitchValue(false);
		}
	}

	const enableDisableMfa = async (e) => {
		console.log(e)
		
		setSwitchValue(e);

		const onSuccess = () => {
		
			closeModalSuccess();
		}

		const onError = (error) => {
			errorMessageCreation(error)
		}

		const loadingConfig = { dispatch }
		const body = {
			idUser: idUser,
			enableMfa: e,
			username: userName
		}
		enableDisableMfaRequest(body, onSuccess, onError, loadingConfig)
	}


	
	const onChangeAgent = (e) => {
		console.log(e)
		if(e != null){
			setIdAgent(e.value.idAgent);
			setAgentUserName(e.value.userName);
			setSwitchAgentValue(e.value.mfaAuthentication);
		} else {
			setIdAgent(null);
			setAgentUserName(null);
			setSwitchAgentValue(false);
		}
	}

	useEffect(() => {
		if (idUser != null) {
			setDisabled(false);
			
		} else {
			setDisabled(true);
		}
	}
	, [idUser]);

	const enableAgentDisableMfa = async (e) => {
		console.log(e)
		
		setSwitchAgentValue(e);

		const onSuccess = () => {
		
			closeModalSuccess();
		}

		const onError = (error) => {
			errorMessageCreation(error)
		}

		const loadingConfig = { dispatch }
		const body = {
			idAgent: idAgent,
			enableMfa: e,
			username: agentUserName
		}
		enableDisableMfaAgentRequest(body, onSuccess, onError, loadingConfig)
	}

	useEffect(() => {
		if (idAgent != null) {
			setAgentDisabled(false);
			
		} else {
			setAgentDisabled(true);
		}
	}
	, [idAgent]);



	const changeTab = (tab) => { setActiveTab(tab); }
	const isActive = (t) => (activaTab === t ? 'td-active' : '');

	



		return (
			<Popup
				title={lan.enableDisableMfa}
				icon='domain-icon.svg'
				show
				closeButton
				onClose={closeDefaultModal}
			>

				<Row className='mx-3'>
					<Navbar className='td-navbar mb-3 d-flex justify-content-center'>
						<Nav.Item id='nav-user' className={`text-center ${isActive('mfaUser')} full-width`}>
							<Nav.Link className='td-nav-title' onClick={() => changeTab('mfaUser')}>
								{lan.user}
							</Nav.Link>
						</Nav.Item>

						<Nav.Item id='nav-agent' className={`text-center ${isActive('mfaAgent')} full-width`}>
							<Nav.Link className='td-nav-title' onClick={() => changeTab('mfaAgent')}>
								{lan.agent}
							</Nav.Link>
						</Nav.Item>
					</Navbar>
				</Row>

				{activaTab === 'mfaUser' && (
					<>		
						<Row className='Fielder-input-border'>
							<Col sm={'auto'} className='mt-2'>
								<img src={UserIcon} className='icon mr-auto' alt='' />
							</Col>
							<Col>
								<AsyncSearch
									className='react-select__control full-width mt-1 mb-3'
									getOptions={(e) => getUserByFilterAsyncSelect(e, user.role, user.userName)}
									onChange={(e) => onChangeUser(e)}
									label={lan.search}
									lan={lan}
									id='select-mfa-user'
								/>
							</Col>
						</Row>

						<Row className='ml-1'>
							<Switch label={lan.enableDisableMfa} disabled={disabled} value={switchValue} onChange={(e) =>enableDisableMfa(e)} />
						</Row>
					</>
				)}

				{activaTab === 'mfaAgent' && (
					<>		
						<Row className='Fielder-input-border'>
							<Col sm={'auto'} className='mt-2'>
								<img src={UserIcon} className='icon mr-auto' alt='' />
							</Col>
							<Col>
								<AsyncSearch
									className='react-select__control full-width mt-1 mb-3'
									getOptions={(e) => getAgentsByFilterAsyncSelectMfa(e, user.role, user.userName)}
									onChange={(e) => onChangeAgent(e)}
									label={lan.search}
									lan={lan}
									id='select-mfa-agent'
								/>
							</Col>
						</Row>

						<Row className='ml-1'>
							<Switch label={lan.enableDisableMfa} disabled={disabledAgent} value={switchAgentValue} onChange={(e) =>enableAgentDisableMfa(e)} />
						</Row>
					</>
				)}
			</Popup>
		);
	
}


export default EnableDisableMfa;
