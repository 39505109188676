export const AUTO_ASSIGN_TYPES = {
	branch: 'BRANCH',
	company: 'COMPANY',
	taskType: 'TASK_TYPE',
	coverage: 'COVERAGE',
};
export const groupIsochronousUsers = (isochronousUsers) => {
	const agents = [];
	const teams = [];
	const partners = [];
	Object.keys(isochronousUsers).forEach((id) => {
		if (isochronousUsers[id].agents) {
			agents.push(...isochronousUsers[id].agents);
		}
		if (isochronousUsers[id].teams) {
			teams.push(...isochronousUsers[id].teams);
		}
		if (isochronousUsers[id].partners) {
			partners.push(...isochronousUsers[id].partners);
		}
	});
	agents.sort((a, b) => a.value.idAgent - b.value.idAgent);
	teams.sort((a, b) => a.value.id - b.value.id);
	partners.sort((a, b) => a.value.id - b.value.id);
	return { agents, teams, partners };
};
export const validateCoverage = (isochronous) => isochronous.length > 0;
export const validateTaskTypes = (taskTypes) => taskTypes.length > 0;
export const validateResources = (assignTo) =>
	assignTo.some(
		({ active, users, skills }) => active && ((users && users.length > 0) || (skills && skills.length > 0))
	);
  export const validateServiceLevelAndTaskPriorities = (serviceLevel, taskPriorities) => {
    const isValidServiceLevel = Object.keys(serviceLevel).some(
        key => serviceLevel[key].active && (serviceLevel[key].immediateResponse || serviceLevel[key].time != null)
    );
    const isValidTaskPriorities = taskPriorities.length > 0;
    return isValidServiceLevel || isValidTaskPriorities;
};
