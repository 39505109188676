export enum REQUEST_TYPES {
	GET_BRANCHES_BY_FILTER = 'GET_BRANCHES_BY_FILTER',
	GET_BRANCHES_BY_ID_COMPANY = 'GET_BRANCHES_BY_ID_COMPANY',
	GET_BRANCHES_BY_ID_COMPANY_AND_FILTER = 'GET_BRANCHES_BY_ID_COMPANY_AND_FILTER',
	GET_BRANCH_INFO = 'GET_BRANCH_INFO',
	GET_BRANCHES_VIEW = 'GET_BRANCHES_VIEW',
	GET_BRANCHES_VIEW_OPTIMIZE = 'GET_BRANCHES_VIEW_OPTIMIZE',
	GET_BRANCHES_TABLE = 'GET_BRANCHES_TABLE',
	GET_BRANCH_SELECT = 'GET_BRANCH_SELECT',
	GET_BRANCHES_PENDING_TO_UPDATE_VIEW = 'GET_BRANCHES_PENDING_TO_UPDATE_VIEW',
	DO_ACTION_CHANGE_BRANCH_STATUS = 'DO_ACTION_CHANGE_BRANCH_STATUS',
	GET_BRANCH_BY_ID = 'GET_BRANCH_BY_ID',
	GET_ALL_BRANCHES_BY_FILTER = 'GET_ALL_BRANCHES_BY_FILTER',
	GET_DISABLED_BRANCHES_VIEW = 'GET_DISABLED_BRANCHES_VIEW',
	GET_DISABLED_BRANCHES_VIEW_OPTIMIZE = 'GET_DISABLED_BRANCHES_VIEW_OPTIMIZE',
	DO_ACTION_CREATE_BRANCH = 'DO_ACTION_CREATE_BRANCH',
	DO_ACTION_UPDATE_BRANCH = 'DO_ACTION_UPDATE_BRANCH',
	GET_BRANCH_LOGS = 'GET_BRANCH_LOGS',
	DO_ACTION_DISABLE_BRANCH = 'DO_ACTION_DISABLE_BRANCH',
	DO_ACTION_ENABLE_BRANCH = 'DO_ACTION_ENABLE_BRANCH',
	DO_ACTION_DELETE_BRANCH = 'DO_ACTION_DELETE_BRANCH',
	GET_ALL_BRANCHES_BY_FILTER_SELECT = 'GET_ALL_BRANCHES_BY_FILTER_SELECT',
	GET_BRANCHES_DEFAULT_OPTIONS = 'GET_BRANCHES_DEFAULT_OPTIONS',
	DO_ACTION_UPDATE_LOCATION_UPDATE = 'DO_ACTION_UPDATE_LOCATION_UPDATE',
	GET_LAST_IDS_CUSTOM_BRANCH_USED = 'GET_LAST_IDS_CUSTOM_BRANCH_USED'
}
