import React, { useState, useImperativeHandle, forwardRef } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import { Col } from 'react-bootstrap';
import TextField from '~/TextField/TextField.component';
import { Modal } from 'react-bootstrap';
import { Row } from 'antd';

const TaskCaseNumber = ({ createClicked, colSize, caseNumberValue}, ref ) => {
	const lan = useLanguage();

	const [caseNumber, setCaseNumber] = useState(caseNumberValue ? caseNumberValue : '');
    const [showNumberTask, setShowNumberTask] = useState(false);

	useImperativeHandle(ref, () => caseNumber, [caseNumber]);

	const handleChange = (event) => {
		setCaseNumber(event.target.value);
	};

    const handleClose = () => {
        setShowNumberTask(false)
    }

    const handleShow = () => {
        setShowNumberTask(true)
    }


	return (
		
        <>
            <div>
                <Row>
                    <Col sm={colSize} className='d-flex justify-content-end ml-4'>
                        <span
                            onClick={handleShow}
                            style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                        >
                           {lan.moreInformation}
                        </span>
                    </Col> 
                </Row>

            </div>

                <Modal
                    id='task-number-modal'
                    size='md'
                    show={showNumberTask}
                    onHide={handleClose}
                    centered
                >
                    <Modal.Header className='modal-header-style'>
                        <Modal.Title />
                        {lan.enterCaseNumber}
                    </Modal.Header>

                    <Modal.Body className='text-center'>
                    <Col className='mt-1'>
                        <TextField
                            id='txt-task-caseNumber'
                            value={caseNumber}
                            variant='outlined'
                            label={`${lan.caseNumber} (${lan.optional})`}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Col>
                    </Modal.Body>

                    <Modal.Footer className="modal-footer-custom">
                    
                    </Modal.Footer>
                </Modal>
        </>

	);
};

export default forwardRef(TaskCaseNumber);
