import { REQUEST_TYPES } from '@core/constants/autoAssign/templates.requests.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

export const getAutoAssignTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		idUser: user.idUser,
		requestType: REQUEST_TYPES.GET_AUTO_ASSIGN_PROCESS_TASK,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAutoAssignTasksRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		idUser: user.idUser,
		requestType: REQUEST_TYPES.GET_AUTO_ASSIGN_PROCESS_TASKS,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};
