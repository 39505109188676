import React from 'react';
import { Avatar, Grid, InputAdornment, Typography } from '@mui/material';
import { Label, LogInput } from './LogTextField.styles';
import { LightGreySubtitleColor, PersianGreen } from '@core/theme/baseTheme';

function ltrim(str) {
	if(!str) return str;
	return str.replace(/^\s+/g, '');
}

const LogTextField = ({ label, text, updated, textClass, labelClass, icon }) => {
	const Icon = React.useMemo(() => {
		if (icon) {
			const words = text ? ltrim(text).split(' ')[0][0].toUpperCase() : '';
			return (
				<InputAdornment position='start'>
					<Avatar alt={words} src={icon} sx={{ width: '2rem', height: '2rem' }} />
				</InputAdornment>
			);
		}
	}, [icon, label]);

	return (
		<>
			<Grid container>
				<Grid item xs={12} className={labelClass}>
					<Label>{label}</Label>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<LogInput
						size='small'
						variant='filled'
						startAdornment={Icon}
						fullWidth
						color={updated ? PersianGreen : LightGreySubtitleColor}
						value={text}
						updated={updated}
						disabled
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default LogTextField;
