import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

/** RESOURCE ALLOCATION MY OPERATION * */
export const resourceAllocationMyOperationRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

/** RESOURCE ISOCHRONOUS MY OPERATION * */
export const resourceIsochronousMyOperationRequest = async (body, onSuccess, onError, loadingConfig) => {
  const user = await getSelectUser();
  const requestBody = {
    idUser: user.idUser,
    idAccount: user.idAccount,
    ...body,
  };
  return handlePostRequest(API_LINKS.autoAssignTaskManager, requestBody, onSuccess, onError, loadingConfig);
};
