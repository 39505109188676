const API_LOGIN = process.env.API_LOGIN;
const API_TOP_MENU = process.env.API_TOP_MENU;
const API_TASK = process.env.API_TASK;
const API_FUNCTIONALITY_TASK = process.env.API_FUNCTIONALITY_TASK;
const API_AUTO_ASSIGN_TASK = process.env.API_AUTO_ASSIGN_TASK;
const API_MODULES = process.env.API_MODULES;
const API_AGENT = process.env.API_AGENT;
const API_ACCOUNT = process.env.API_ACCOUNT;
const API_USER = process.env.API_USER;
const { API_USER_MANAGER } = process.env;
const API_CALENDAR = process.env.API_CALENDAR;
const API_DASHBOARD = process.env.API_DASHBOARD;
const API_ANALYTICS = process.env.API_ANALYTICS;
const API_S3 = process.env.API_S3;

const API_LINKS = {
	loginUserManager: `${API_LOGIN}/user_login`,
	topMenuInfo: `${API_TOP_MENU}/menuinfo`,
	topMenuNews: `${API_TOP_MENU}/getnews`,
	topMenuNotifications: `${API_TOP_MENU}/getsystemnotifications`,
	topMenuShareApp: `${API_TOP_MENU}/sendgeneralemail`,
	createTicketSupport: `${API_TOP_MENU}/createticketsupport`,
	updateNotifications: `${API_TOP_MENU}/updatestatussystemnotifications`,
	globalSearchManager: `${API_TOP_MENU}/globalsearchmanager`,
  taskManager: `${API_TASK}/task-manager`,
  assetManager: `${API_FUNCTIONALITY_TASK}/asset-manager`,
  inventoryManager: `${API_FUNCTIONALITY_TASK}/inventory-manager`,
  functionalityTaskManager: `${API_FUNCTIONALITY_TASK}/functionality-task-manager`,
  autoAssignTaskManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-task-manager`,
  autoAssignTemplateManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-template-manager`,
  autoAssignIsochronousManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-isochronous-manager`,
  autoAssignRouteManager: `${API_AUTO_ASSIGN_TASK}/auto-assign-route-manager`,
  agentManager: `${API_AGENT}/agentManager`,
	register: `${API_ACCOUNT}/createaccount`,
	getPartners: `${API_ACCOUNT}/getpartners`,
	accountManager: `${API_ACCOUNT}/accountmanager`,
	userManager: `${API_USER}/usermanager`,
	apiUserManager: `${API_USER_MANAGER}/usermanager`,
	calendarManager: `${API_CALENDAR}/calendarmanager`,
	dashboardManager: `${API_DASHBOARD}/dashboardmanager`,
	analyticsManager: `${API_ANALYTICS}/analyticsmanager`,
	formsManager: `${API_MODULES}/formsmanager`,
	generalModules: `${API_MODULES}/generalmodulesmanager`,
	roleManagement: `${API_MODULES}/rolemanagement`,
	formsPdfParser: `${API_MODULES}/formspdfparsermanager`,
	formsHtmlParser: `${API_MODULES}/formshtmlparsermanager`,
	formsXlsxParser: `${API_MODULES}/formsxlsxparsermanager`,
	taskCalendarXlsx: `${API_MODULES}/tasktoexcelformat`,
	awsS3Link: `${API_S3}`,

	// NOT IN CODE
	// Review Teams API GATEWAY
	getPartnerInvitations: `${API_ACCOUNT}/getinvitations`,
	sendPartnerInvitations: `${API_ACCOUNT}/sendinvitationtopartner`,
	acceptPartnerInvitations: `${API_ACCOUNT}/acceptinvitation`,
	rejectPartnerInvitations: `${API_ACCOUNT}/rejectinvitation`,
	cancelPartnership: `${API_ACCOUNT}/cancelpartnership`,
	sendEmailUser: `${API_USER}/sendnewclientuseremail`,
	sendEmailUserAdministrator: `${API_USER}/sendnewuseradministratoremail`,
};

export default API_LINKS;
