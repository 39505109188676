import React, { Component } from 'react';

import AgentProfileImage from '@assets/icons/default-profile-icon.svg';
import ProfileImage from '@assets/icons/default-profile-icon.svg';
import { getAccountPreferences } from 'FielderUtils/accountUtils';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import TypeUserModify from 'FielderUtils/typeUserModify/typeUserModify';
import TaskStatus from 'FielderUtils/status/taskStatus';
import { isClient, isExecutor, isOwner, isLastOwner, hasPartnerRelation } from '@core/utils/task/taskOwnership';
import { getStatus } from '@core/utils/task/taskStatusToShow';
import {
	getTaskActivities,
	returnSeverity,
	returnValidationStatus,
	returnAgentInfo,
	returnUserCreateTask,
	returnCancelComments,
} from 'FielderUtils/taskDetail/taskDetailUtil';
import {
	listToTree,
	getAllTaskTypeList,
	getAllTaskTypeListPartner,
	hasTaskTypeDynamoTable,
	hasTaskTypeDynamoTablePartner,
} from 'FielderUtils/taskType/taskType';
import moment from 'moment';
import { Modal, Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import TaskActivity from './TaskActivity';
import TaskAgent from './TaskAgent';
import TaskDetailHeader from './TaskDetailHeader';
import TaskDetailNavTab from './TaskDetailNavTab';
import TaskDocument from './TaskDocument';
import TaskExecution from './TaskExecution';
import TaskGallery from './TaskGallery';
import TaskInfo from './TaskInfo';
import TaskModules from './TaskModules';
import TaskRoute from './TaskRoute';
import { Scrollbars } from 'react-custom-scrollbars';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import { useModal } from '~/FielderElements/ModalContext';
// Stylesheets
import 'FielderUtils/style.css';
import './TaskDetails.css';
import { getTaskMyOperationRequest } from '@core/api/task/task.service';
import { removeFormTaskRequest } from '@core/api/task/taskModules.service';

/**
 * Route Details Pop-up Component according to Fielder App
 * requirements, .
 */
class TaskDetails extends Component {
	/**
	 * @typedef  {Object} Task
	 * @property {string} name - Task Name to be displayed
	 * @property {string} act - Activity to be done
	 * @property {string} date - Task Date
	 * @property {string} location - Location in which the task
	 *                               must be done
	 * @property {string} status - Status in which the task is
	 *                             currently in
	 * @property {string} code - Code name for the status
	 *
	 */
	constructor(props) {
		super(props);
		this.taskPhotosRef = React.createRef();
		if (this.props.user.language == 'en_US') {
			moment.locale('en', {
				months: [
					'January',
					'February',
					'March',
					'April',
					'May',
					'June',
					'July',
					'August',
					'September',
					'October',
					'November',
					'December',
				],
			});
		} else if (this.props.user.language == 'es_MX') {
			moment.locale('es', {
				months: [
					'Enero',
					'Febrero',
					'Marzo',
					'Abril',
					'Mayo',
					'Junio',
					'Julio',
					'Agosto',
					'Septiembre',
					'Octubre',
					'Noviembre',
					'Diciembre',
				],
			});
		} else if (this.props.user.language == 'pt_BR') {
			moment.locale('pt', {
				months: [
					'Janeiro',
					'Fevereiro',
					'Março',
					'Abril',
					'Maio',
					'Junho',
					'Julho',
					'Agosto',
					'Setembro',
					'Outubro',
					'Novembro',
					'Dezembro',
				],
			});
		} else {
			moment.locale('en', {
				months: [
					'January',
					'February',
					'March',
					'April',
					'May',
					'June',
					'July',
					'August',
					'September',
					'October',
					'November',
					'December',
				],
			});
		}
		// Set the initial state
		this.state = {
			tab: 'info',
			taskData: {
				idAccount: null,
				partnerTaskRelations: null,
				number: '',
				idFielder: null,
				folio: '',
				idParent: '',
				programmedDate: '',
				timeExecution: '',
				until: '',
				endDate: '',
				assignedTeam: '',
				coords: null,
				address: '',
				status: '',
				code: '',
				company: '',
				taskType: '',
				serviceOrigin: '',
				taskPartner: '',
				showMyTaskPartner: false,
				priority: '',
				description: '',
				tags: [],
				lastName: '',
				userName: '',
				client: {
					name: '',
					phone: '',
					mail: '',
				},
				rating: 0,
				showClientFields: true,
				comments: [],
				cancelReason: null,
				activity: [],
				taskPin: '',
			},
			statusEditTask: false,
			taskAgent: {
				name: '',
				workSchedule: '',
				phone: '',
				email: '',
				vehicleType: '',
				vehicleId: '',
				timeTaken: '',
				distanceTraveled: '',
				profilePicture: ProfileImage,
			},
			taskActivity: [],
			response: null,
			modules: {
				forms: null,
				assets: null,
				inventories: null,
			},

			taskTypes: [],
			taskTypeFunctionality: false,
			updated: 0,
			showForms: false,
			opacityValue: 1,
			showLoadingDetail: true,
			showIconModify: false,
			allowGenerateFormsIfTaskStatusWasModify: false,
			showUtcMessage:false,
			idBranch: 0,
			allowPartnerToGenerateForms: true,
		};
	}
	componentWillMount = async () => {
		var partnerTaskRelations = this.props.partnerTaskRelations;
		getAccountPreferences(this, this.props.session);
		var idAccount = this.props.session.user.idAccount;
		var idUser = this.props.session.user.idUser;
		if (
			partnerTaskRelations !== undefined &&
			partnerTaskRelations != null &&
			partnerTaskRelations.accountOwner != null &&
			partnerTaskRelations.accountOwner.id != idAccount &&
			partnerTaskRelations.accountOwner.idUserClient != idUser &&
			partnerTaskRelations.partnerLevel == 1
		) {
			var idAccountPartner = partnerTaskRelations.accountOwner.id;
			var idUserPartner = partnerTaskRelations.accountOwner.idUserClient;

			this.executeGetAllTaskInfoPartner(idAccountPartner, idUserPartner);
		} else {
			this.executeGetAllTaskInfo();
		}
	};

	executeGetAllTaskInfo = () => {
		hasTaskTypeDynamoTable()
			.then((taskTypeFunctionality) => {
				if (taskTypeFunctionality) {
					getAllTaskTypeList(
						(formatTypes, taskTypes) => {
							let types = listToTree(formatTypes);
							let state = this.state;
							state.taskTypes = types;
							state.taskTypeFunctionality = taskTypeFunctionality;
							this.setState(state);
						},
						this.props,
						true
					);
				}
			})
			.then(() => {
				this.setTaskTypeInfo();
			});
	};

	executeGetAllTaskInfoPartner = (idAccountPartner, idUserPartner) => {
		hasTaskTypeDynamoTablePartner(idAccountPartner, idUserPartner)
			.then((taskTypeFunctionality) => {
				if (taskTypeFunctionality) {
					getAllTaskTypeListPartner(
						(formatTypes) => {
							let types = listToTree(formatTypes);
							let state = this.state;
							state.taskTypes = types;
							state.taskTypeFunctionality = taskTypeFunctionality;
							this.setState(state);
						},
						this.props,
						idAccountPartner,
						idUserPartner
					);
				}
			})
			.then(() => {
				this.setTaskTypeInfo();
			});
	};

	realTimeUpdateDetail = () => {
		this.executeGetAllTaskInfo();
		if (this.state.tab === 'pics') {
			this.taskPhotosRef.current.refreshImages();
		}
	};

	returnCanEditTaskStatus = (status, session) => {
		var statusEditTask = false;
		if (
			(status == TaskStatus.MANAGING ||
				status == TaskStatus.WAITING_FOR_AGENT ||
				status == TaskStatus.IN_PROGRESS ||
				status == TaskStatus.ASSIGNED_TO_PARTNER ||
				status == TaskStatus.ASSIGNED_TO_TEAM ||
				status == TaskStatus.FINISH ||
				status == TaskStatus.REVIEW) &&
			session &&
			session.user &&
			session.user.role &&
			session.user.role != 'Fielder Client User'
		) {
			statusEditTask = true;
		} else if (
			session &&
			session.user &&
			session.user.role &&
			session.user.role == 'Fielder Client User' &&
			status != TaskStatus.EXECUTING &&
			status != TaskStatus.FINISH &&
			status != TaskStatus.CANCEL &&
			status != TaskStatus.START_ROUTE &&
			status != TaskStatus.AUTOASSIGNED
		) {
			statusEditTask = true;
		}
		return statusEditTask;
	};

	changeLoadingState = () => {
		this.setState({ showLoadingDetail: !this.state.showLoadingDetail });
	};

	returnAllComments = (taskCancelComments, timeZone) => {
		var tempComments = [];
		var number = 0;
		var n = 0;

		for (let i = 0; i < taskCancelComments.length; i++) {
			if (taskCancelComments[i].type == 0) {
				n = number + 1;
				number = number + 1;
			} else {
				n = 0;
			}

			var userComentName = this.props.lan.unknown;

			if (taskCancelComments[i].agent) {
				let name = taskCancelComments[i].agent.name ? taskCancelComments[i].agent.name : '';
				let lastName = taskCancelComments[i].agent.lastName ? taskCancelComments[i].agent.lastName : '';
				userComentName = name + ' ' + lastName;
			}

			tempComments.push({
				comment: taskCancelComments[i].comment,
				type: taskCancelComments[i].type,
				userComentName: userComentName,
				commentDate:  moment.tz(taskCancelComments[i].commentDate, timeZone).format('DD-MM-YYYY hh:mm a') ,
				number: n,
			});
		}
		return tempComments;
	};

	getIdTask = (idTasks) => {
		let taskId = null;
		if (idTasks != undefined && idTasks.length > 0) {
			taskId = idTasks[0];
		} else {
			taskId = this.props.idAuxDetailMakerTaskId;
		}
		return taskId;
	};

	getIdAgent = (idAgents) => {
		let idAgent = null;
		if (idAgents != undefined && idAgents.length > 0) {
			idAgent = idAgents[0].idAgent;
		}
		return idAgent;
	};

	formatDistance = (meters) => {
		if (meters && meters !== '') {
			let km = parseInt(meters) / 1000;
			return km.toFixed(1) + ' km';
		}
		return '';
	};

	formatTime = (text) => {
		let patternBoth = /(\d+) hours (\d+) mins/g;
		let patternHours = /(\d+) hours/g;
		let patternMins = /(\d+) mins/g;

		if (patternBoth.test(text)) {
			text = text.replace(patternBoth, "$1 hrs $2 min");
		} else if (patternHours.test(text)) {
			text = text.replace(patternHours, "$1 hrs");
		} else if (patternMins.test(text)) {
			text = text.replace(patternMins, "$1 min");
		}
		return text;
	}


	setTaskTypeInfo = () => {
		var name = '',
			lastName = '',
			idCustom = '',
			phone = '',
			mail = '',
			branch = '',
			userName = '',
			timeExecution = '',
			until = '',
			clientType = '',
			status = 1,
			userCreateTask = '',
			origin = this.props.lan.partner,
			agentInfo = {
				name: this.props.lan.notAssigned,
				profilePicture: ProfileImage,
				phone: '',
				distanceTraveled: 0,
			},
			comments = [];

		var showClientFields = true;
		var showBranchFields = false;

		const body = {
			taskInfo: {
				task: {
					idTask: this.getIdTask(this.props.idTasks),
					idAccount: this.props.session.user.idAccount,
				},
			},
			clientUser: RoleUtils.isUserClient(this.props.session.user),
		};


		const onSuccess = (res) => {
			const task = res.taskInfo.task;
			const session = this.props.session;
			const userLoggedIdAccount = session.user.idAccount;
			const partnerTaskRelations = task.partnerTaskRelations;

	
			
			let lastPartnerId = null;
			let lastOwner = null;
			if (partnerTaskRelations && partnerTaskRelations.length > 0) {
				lastPartnerId = partnerTaskRelations[partnerTaskRelations.length - 1].idAccountPartner;
				lastOwner = partnerTaskRelations[partnerTaskRelations.length - 1].idAccountOwner;
			}

			let severity = returnSeverity(task.severity, task.severityName, this.props.lan);

			let taskActivity = getTaskActivities(task.taskActivities, session, this.props.lan, partnerTaskRelations, lastPartnerId, lastOwner, task, userLoggedIdAccount);	 
		
			let timeZone = task.startLocation ? task.startLocation.timeZone : 'UTC';
			let showUtcMessage = timeZone == 'UTC' ? true : false;
			
			if (task.startDateRange !== null) {
				timeExecution = moment.tz(task.startDateRange, timeZone).format('DD-MMMM-YYYY');
			}
			if (task.startDateTimeRange !== null) {
				timeExecution = moment.tz(task.startDateTimeRange, timeZone).format('DD-MMMM-YYYY hh:mm a');
			}

			if (task.endDateRange !== null) {
				until = moment.tz(task.endDateRange, timeZone).format('DD-MMMM-YYYY ');
			}
			if (task.endDateTimeRange !== null) {
				until = moment.tz(task.endDateTimeRange, timeZone).format('DD-MMMM-YYYY hh:mm a');
			}

			if ((task.comments && task.agent && task.agent.idAccount == userLoggedIdAccount) || task.group) {
				comments = this.returnAllComments(task.comments, timeZone);
			}

			if (task.status !== null) {
				status = parseInt(task.status);
			}

			let agentTimeTaken = task.timeTravel ? task.timeTravel : '';
			let agentTimeTakenFormat = this.formatTime(agentTimeTaken);
			
			let agentDistanceTraveled = task.distanceTraveled ? task.distanceTraveled : 0;
			let agentDistanceTraveledFormat = this.formatDistance(agentDistanceTraveled);
			

			if (task.user) {
				// Client found
				showClientFields = true;
				clientType = 'client';
				name = task.user.name;
				lastName = task.user.lastName;
				userName = task.user.userName;
				phone = task.user.mobileNumber;
				mail = task.user.email;
				idCustom = task.user.idUser;
				branch = task.user.company;
			} else if (task.branch) {
				// Branch found
				showClientFields = false;
				showBranchFields = true;
				clientType = 'branch';
				name = task.branch.name;
				idCustom = task.branch.idCustom ? task.branch.idCustom : '';
				branch += task.branch.company ? task.branch.company.name : '';
			}

			var cancelComments = returnCancelComments(task.taskCancelComments, session);

			/***************  STATUS VALIDATION ***************/

			status = returnValidationStatus(
				task.status,
				session,
				task.agentCancelled,
				task.cancelByAnyAgent,
				task.showCancelStatus,
				task.cancelByAdmin
			);

			let taskPartner = '';
			let taskPartnerBusinessName = '';
			let taskPartnerRfc='';
			let showMyTaskPartner = true;
			let taskPartnerPhone ='';
			let taskPartnerAdresss ='';
			let taskPartnerIdAccount = '';

			if (partnerTaskRelations && partnerTaskRelations.length > 0) {
				var lastPartner = partnerTaskRelations[partnerTaskRelations.length - 1];
				

				taskPartnerBusinessName = lastPartner.accountPartner.name;
				taskPartnerRfc = lastPartner.accountPartner.rfc;
				taskPartnerPhone = lastPartner.accountPartner.contactPhone;
				taskPartnerAdresss = lastPartner.accountPartner.address;
				taskPartnerIdAccount = lastPartner.accountPartner.id;

				if (userLoggedIdAccount == lastPartnerId) {
					taskPartner = lastPartner.accountOwner.name;
				} else {
					taskPartner = lastPartner.accountPartner.name;
					showMyTaskPartner = false;
				}

				if (!taskPartner) {
					taskPartner = this.props.lan.unnamed;
				}

				if (session.user.role == 'Fielder Client User') {
					taskPartner = this.props.lan.partner;
				}
				if (
					lastPartnerId != userLoggedIdAccount &&
					task.idAccount != userLoggedIdAccount &&
					status == TaskStatus.ASSIGNED_TO_TEAM
				) {
					status = TaskStatus.IN_PROGRESS;
				}

				if (
					(task.idAccount == userLoggedIdAccount || lastPartnerId != userLoggedIdAccount) &&
					status == TaskStatus.CANCEL
				) {
					status = TaskStatus.CANCEL_BY_PARTNER;
				}

				if (
					status &&
					status == TaskStatus.ASSIGNED_TO_PARTNER &&
					session.user.role != 'Fielder Client User' &&
					task.agentCancelled &&
					lastPartnerId != userLoggedIdAccount
				) {
					task.agentCancelled = false;
					status = TaskStatus.IN_PROGRESS;
				}

				if (
					status &&
					status == TaskStatus.ASSIGNED_TO_PARTNER &&
					session.user.role != 'Fielder Client User' &&
					task.agentCancelled &&
					lastPartnerId == userLoggedIdAccount
				) {
					status = TaskStatus.MANAGING;
				}
			}

			let agentImage = AgentProfileImage;
			if (task.agent !== null) {
				agentImage = task.agent.profileImage ? task.agent.profileImage : AgentProfileImage;

				agentInfo = returnAgentInfo(
					task.agent,
					agentImage,
					this.props.lan,
					lastPartnerId,
					userLoggedIdAccount,
					session,
					agentTimeTaken,
					agentDistanceTraveled
				);
			}

			if (task.userCreateTaskActivity) {
				userCreateTask = returnUserCreateTask(task.userCreateTaskActivity);
			}

			var statusEditTask = this.returnCanEditTaskStatus(status, session);

			
				/**  REJECT AND PAUSE REASON */
			
			let taskRejectionReason = [];
			if (task.rejectTaskCommments && task.rejectTaskCommments.length > 0) {
				let rejectTaskComments = task.rejectTaskCommments;
				rejectTaskComments.forEach((rejectTaskComment) => {
					let rejectTaskReason = rejectTaskComment.rejectTaskReason;
					let parentReasonReject =
						rejectTaskReason.parentReason == 0 ? this.props.lan.rejectedByAgent : this.props.lan.rejectedByFinalClient;

					taskRejectionReason.push({
						name: rejectTaskComment.agent
							? (rejectTaskComment.agent.name ? rejectTaskComment.agent.name : '') +
							  ' ' +
							  (rejectTaskComment.agent.lastName ? rejectTaskComment.agent.lastName : '')
							: this.props.lan.unnamed,
						date: moment.tz(rejectTaskComment.dateComment, timeZone).format('DD-MM-YYYY hh:mm a'),
						dependency: parentReasonReject,
						reason: rejectTaskReason.reasonName,
						comment: rejectTaskComment.comment,
					});
				});
			}
			let taskPauseReason = [];
			if (task.pauseTaskComments && task.pauseTaskComments.length > 0) {
				let pauseTaskComments = task.pauseTaskComments;

				pauseTaskComments.forEach((pauseTaskComment) => {
					let pauseTaskReason = pauseTaskComment.pauseTaskReason;
					let parentReasonPause =
						pauseTaskReason.parentReason == 0 ? this.props.lan.pausedByAgent : this.props.lan.pausedByFinalClient;

					taskPauseReason.push({
						name: pauseTaskComment.agent
							? (pauseTaskComment.agent.name ? pauseTaskComment.agent.name : '') +
							  ' ' +
							  (pauseTaskComment.agent.lastName ? pauseTaskComment.agent.lastName : '')
							: this.props.lan.unnamed,
						date: moment.tz(pauseTaskComment.dateComment, timeZone).format('DD-MM-YYYY hh:mm a'),
						dependency: parentReasonPause,
						reason: pauseTaskReason.reasonName,
						comment: pauseTaskComment.comment,
					});
				});
			}
			let modifyTaskStatusCommentAdmin = [];
			let modifyTaskStatusCommentManager = [];

			let allowGenerateFormsIfTaskStatusWasModify = false;
			if (task.modifyTaskStatusComments && task.modifyTaskStatusComments.length > 0 && task.agent == null) {
				allowGenerateFormsIfTaskStatusWasModify = true;
			}

			if (task.modifyTaskStatusComments && task.modifyTaskStatusComments.length > 0) {
				let modifyTaskStatusComments = task.modifyTaskStatusComments;

				modifyTaskStatusComments.forEach((modifyTaskStatusComment) => {
					if (modifyTaskStatusComment.typeUser == TypeUserModify.ADMIN) {
						modifyTaskStatusCommentAdmin.push({
							name: modifyTaskStatusComment.user
								? (modifyTaskStatusComment.user.name ? modifyTaskStatusComment.user.name : '') +
								  ' ' +
								  (modifyTaskStatusComment.user.lastName ? modifyTaskStatusComment.user.lastName : '')
								: this.props.lan.unnamed,
							date: moment.tz(modifyTaskStatusComment.dateComment, timeZone).format('DD-MM-YYYY hh:mm a'),
							comment: modifyTaskStatusComment.comment,
						});
					} else {
						modifyTaskStatusCommentManager.push({
							name: modifyTaskStatusComment.user
								? (modifyTaskStatusComment.user.name ? modifyTaskStatusComment.user.name : '') +
								  ' ' +
								  (modifyTaskStatusComment.user.lastName ? modifyTaskStatusComment.user.lastName : '')
								: this.props.lan.unnamed,
							date: moment.tz(modifyTaskStatusComment.dateComment, timeZone).format('DD-MM-YYYY hh:mm a'),
							comment: modifyTaskStatusComment.comment,
						});
					}
				});
			}

			let hasGroup = task.group ? true : false;
			let hasRoute = task.idRoute ? true : false;

			if (task.group && task.group.agents != null && task.status != TaskStatus.FINISH) {
				agentInfo = {
					name: this.props.lan.assignedToTaskGroup,
					phone: '',
					distanceTraveled: agentDistanceTraveled,
					profilePicture: ProfileImage,
				}
			}


			let rescheduleComments = null;
			if (task.rescheduled != null || task.rescheduledDescription != null || task.dateOfReschedule) {


				rescheduleComments = {
					rescheduleTypeOfUser: task.rescheduledRole ? task.rescheduledRole: this.props.lan.noData,
					rescheduledUserName: task.rescheduleUserName ? task.rescheduleUserName : this.props.lan.noData,
					rescheduledDate:  moment.tz(task.rescheduled, timeZone).format('DD-MM-YYYY hh:mm a'),
					commentReschedule: task.rescheduledDescription,
					dateOfReschedule:  moment.tz(task.dateOfReschedule, timeZone).format('DD-MM-YYYY hh:mm a'),
				}
			}

			let modules = {
				forms: task.forms,
				assets: task.assets,
				inventories: task.inventory,
			};

			
			const taskOwnership = {
				isClient: isClient(this.props.user, task),
				isExecutor: isExecutor(this.props.user, task),
				isOwner: isOwner(this.props.user, task),
				isLastOwner: isLastOwner(this.props.user, task),
				hasPartnerRelation: hasPartnerRelation(this.props.user, task),
			};
			const statusInfoAux =  getStatus(task.status, taskOwnership)
			const statusInfo = this.props.lan[statusInfoAux.text]

			this.setState({
				taskData: {
					idAccount: task.idAccount,
					partnerTaskRelations,
					agent: task.agent,
					taskPin: task.taskPin,
					offlineExecution: task.offlineExecution,
					number: task.idCustom,
					idFielder: task.idTask,
					idParent: task.idParent,
					folio: task.folio ? task.folio : '',
					caseNumber: task.caseNumber ? task.caseNumber : '',
					programmedDate: task.startTime ? moment.tz(task.startTime, timeZone).format('DD-MM-YYYY hh:mm a') : '',
					timeExecution: timeExecution,
					until: until,
					endDate: task.endAgent
						? moment.tz(task.endAgent, timeZone).format('DD-MM-YYYY hh:mm a')
						: this.props.lan.notAssigned,
					startDate: task.startAgent ? moment.tz(task.startAgent, timeZone).format('DD-MM-YYYY hh:mm a') : '',
					assignedTeam: task.team ? task.team.name : this.props.lan.withoutTeam,
					lat: task.startLocation ? task.startLocation.latitude : 0,
					lng: task.startLocation ? task.startLocation.longitud : 0,
					coords: {
						lat: task.startLocation.latitude,
						lng: task.startLocation.longitud,
					},
					address: task.startLocation ? task.startLocation.address : 0,
					status: status,
					code: 'in-progress',
					lastName: lastName,
					userName: userName,
					company: branch,
					taskType: task.taskTypeInfo,
					taskTypeId: task.serviceTypeId,
					serviceOrigin: origin,
					taskPartner: taskPartner,
					showMyTaskPartner: showMyTaskPartner,
					priority: severity,
					comments: comments,
					rateComment: task.rating ? task.rating.comment : this.props.lan.nothingToComment,
					description: task.description,
					tags: task.tags ? task.tags : [],
					noTags: task.tags.length > 0 ? '' : this.props.lan.noTags,
					branch: task.branch,
					branchId: task.branch ? task.branch.id : '',
					branchIdCustom: task.branch ? task.branch.idCustom : '',
					startLocation: task.startLocation,
					client: {
						clientType: clientType,
						idCustom: idCustom,
						name: name,
						phone: phone,
						mail: mail,
					},
					rating: task.rating ? parseInt(task.rating.rating) : 0,
					showClientFields: showClientFields,
					showBranchFields: showBranchFields,
					cancelReason: cancelComments.cancelCommentsAgent,
					cancelCommentsUser: cancelComments.cancelCommentsUser,
					routeVariables: task.routeVariables,
					userCreateTask: userCreateTask,
					startAgent: task.startAgent,
					endAgent: task.endAgent,
					startRoute: task.startRoute ? task.startRoute.dateStart : '',
					endRoute: task.startRoute ? task.startRoute.dateArrive : '',
					serviceFilesPdf: task.serviceFilesPdf ? task.serviceFilesPdf : null,
					taskRejectionReason: taskRejectionReason,
					taskPauseReason: taskPauseReason,
					modifyTaskStatusCommentAdmin: modifyTaskStatusCommentAdmin,
					modifyTaskStatusCommentManager: modifyTaskStatusCommentManager,
					hasGroup: hasGroup,
					hasRoute: hasRoute,
					rescheduleComments: rescheduleComments,
					agentDistanceTraveled: agentDistanceTraveledFormat,
					agentTimeTaken: agentTimeTakenFormat,
					taskPartnerBusinessName,
					taskPartnerRfc,
					taskPartnerPhone,
			 		taskPartnerAdresss,
					taskPartnerIdAccount,
					statusInfo: statusInfo,
				},
				showUtcMessage :showUtcMessage,
				taskAgent: agentInfo,
				statusEditTask: statusEditTask,
				taskActivity: taskActivity,
				response: res,
				modules: modules,
				updated: this.state.updated + 1,
				showLoadingDetail: false,
				idAccountTask: task.idAccount,
				showIconModify: true,
				allowGenerateFormsIfTaskStatusWasModify:allowGenerateFormsIfTaskStatusWasModify,
				idBranch: task.branch ? task.branch.id : 0,
				allowPartnerToGenerateForms: task.allowPartnerToGenerateForms,
			});
			
		};

		const onError = this.props.onClose
		getTaskMyOperationRequest(body, onSuccess, onError);
	};

	removeTaskForm = (idTaskForm) => {
		const body = {
			idTaskForm: idTaskForm,
			idTask: this.state.taskData.idFielder,
			singleAccount: false,
		};

		const loadingConfig = { dispatch: this.props.dispatch, name: 'REMOVE_FORM' };

		const onSuccess = (response) => {
			if (response.forms != null) {
				let state = this.state;
				state.modules.forms = response.forms;
				this.setState(state);
			}
		};

		const onError = (error) => console.log(error);
		removeFormTaskRequest(body, onSuccess, onError, loadingConfig);
	};

	isActive = (tab) => (this.state.tab === tab ? 'td-active' : '');
	changeTab = (tab) => this.setState({ tab: tab });
	changeOpacity = () => this.setState({ opacityValue: 0 });
	changeOpacityReset = () => this.setState({ opacityValue: 1 });
	refreshViewDetail = this.componentWillMount;
	executeRefresh = this.componentWillMount;

	refreshActivity = (tab) => {
		if (tab === 'act') {
			this.componentWillMount();
		}
	};

	render() {
		return (
			<React.Fragment>
				<Modal
					size='xl'
					aria-labelledby='contained-modal-title-vcenter'
					show
					onHide={this.props.onClose}
					style={{ opacity: this.state.opacityValue }}
					id='task-detail-modal'
					enforceFocus={false}
				
				>
					<Modal.Header closeButton className='no-border'>
						<TaskDetailHeader
							tab={this.state.tab}
							taskData={this.state.taskData}
							lan={this.props.lan}
							executePopupAction={this.props.executePopupAction}
							showMenuActions={this.props.showMenuActions}
							changeOpacity={this.changeOpacity}
							session={this.props.session}
							showTaskByEmail={this.props.showTaskByEmail}
							showDisabledTasks={this.props.showDisabledTasks}
							statusTask={this.state.taskData.status}
							showLoadingDetail={this.state.showLoadingDetail}
              				fielderMsg={this.props.fielderMsg}
              				onCloseModal={this.props.onCloseModal}
						/>
					</Modal.Header>

					<Modal.Body className='px-0'>
						<TaskDetailNavTab
							changeTab={(tab) => {
								this.setState({ tab: tab }, () => this.refreshActivity(tab));
							}}
							isActive={(tab) => (this.state.tab === tab ? 'td-active' : '')}
							user={this.props.user}
							lan={this.props.lan}
							modules={this.state.modules}
						/>

						<div style={{ height: '600px', minHeight: '400px', overflow:'auto' }}>
							{this.state.tab === 'info' && (
								<Scrollbars style={{ height: '590px', width: '100%' }}>
									<Container>
										<TaskInfo
											{...this.props}
											onCloseModal={this.props.onCloseModal}
											taskData={this.state.taskData}
											idTask={this.getIdTask(this.props.idTasks)}
											value={1}
											allData={this.state.response}
											canNotEdit={false}
											statusEditTask={this.state.statusEditTask}
											showIconModify={this.state.showIconModify}
											changeOpacity={this.changeOpacity}
											changeOpacityReset={this.changeOpacityReset}
											executeRefresh={this.executeRefresh}
											partnerTaskRelations={this.props.partnerTaskRelations}
											idBranch={this.state.idBranch}
										/>
									</Container>
								</Scrollbars>
							)}

							{this.state.tab === 'exe' && (
								<Scrollbars style={{ height: '590px', width: '100%' }}>
									<Container>
										<TaskExecution {...this.props} taskData={this.state.taskData} showUtcMessage={this.state.showUtcMessage } />
									</Container>
								</Scrollbars>
							)}

							{this.state.tab === 'agent' && (
								<Container>
									<TaskAgent {...this.props} taskAgent={this.state.taskAgent} />
								</Container>
							)}

							{this.state.tab === 'document' && (
								<Container className='no-padding'>
									<TaskDocument
										{...this.props}
										idTask={this.getIdTask(this.props.idTasks)}
										idAccountTask={this.state.idAccountTask}
										changeLoadingState={this.changeLoadingState}
									/>
								</Container>
							)}

							{this.state.tab === 'pics' && (
								<Container>
									<TaskGallery
										{...this.props}
										ref={this.taskPhotosRef}
										idTask={this.getIdTask(this.props.idTasks)}
										changeLoadingState={this.changeLoadingState}
									/>
								</Container>
							)}
							{this.state.tab === 'act' && (
								<Container>
									<TaskActivity taskActivity={this.state.taskActivity} {...this.props} />
								</Container>
							)}
							{this.state.tab === 'mod' && (
								<TaskModules
									{...this.state.modules}
									taskData={this.state.taskData}
									idAccountTask={this.state.idAccountTask}
									taskAgent={this.state.taskAgent}
									lan={this.props.lan}
									removeTaskForm={this.removeTaskForm}
									session={this.props.session}
									reloadData={this.setTaskTypeInfo}
									updated={this.state.updated}
									history={this.props.history}
									hasFormsFunctionality={this.state.showForms}
									refreshViewDetail={this.refreshViewDetail}
									allowGenerateFormsIfTaskStatusWasModify={this.state.allowGenerateFormsIfTaskStatusWasModify}
									allowPartnerToGenerateForms={this.state.allowPartnerToGenerateForms}
								/>
							)}

							{this.state.tab === 'modClient' && (
								<TaskModules
									{...this.state.modules}
									idAccountTask={this.state.idAccountTask}
									lan={this.props.lan}
									session={this.props.session}
									forms={this.state.modules.forms}
									taskData={this.state.taskData}
									taskAgent={this.state.taskAgent}
									removeTaskForm={this.removeTaskForm}
									reloadData={this.setTaskTypeInfo}
									updated={this.state.updated}
									hasFormsFunctionality={false}
									allowGenerateFormsIfTaskStatusWasModify={this.state.allowGenerateFormsIfTaskStatusWasModify}
									allowPartnerToGenerateForms={this.state.allowPartnerToGenerateForms}
									client
								/>
							)}

							{this.state.tab === 'route' && (
								<TaskRoute
									{...this.props}
									taskData={this.state.taskData}
									idTask={this.getIdTask(this.props.idTasks)}
									taskAgent={this.state.taskAgent}
									idAgent={this.getIdAgent(this.props.idAgent)}
									changeLoadingState={this.changeLoadingState}
								/>
							)}
						</div>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
	user: state.utils.session.user,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(withSnackbar(TaskDetails));
export function withSnackbar(Component) {
  return function WrappedComponent(props) {
  const fielderModal = useModal();
      const fielderMsg = useFielderSnackBar();
      return <Component {...props} fielderMsg={fielderMsg} fielderModal={fielderModal} />;
  }
}
