import { REQUEST_TYPES } from '@core/constants/autoAssign/templates.requests.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// PUT
export const updateTemplateStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		accountId: user.idAccount,
		idUser: user.idUser,
		userId: user.idUser,
		requestType: REQUEST_TYPES.UPDATE_TEMPLATE_STATUS,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

// GET
export const getTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		accountId: user.idAccount,
		idUser: user.idUser,
		userId: user.idUser,
		requestType: REQUEST_TYPES.GET_TEMPLATE_BY_ID,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAutoAssignTableRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		accountId: user.idAccount,
		idUser: user.idUser,
		userId: user.idUser,
		requestType: REQUEST_TYPES.GET_AUTO_ASSIGN_TEMPLATES_TABLE,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const createTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		accountId: user.idAccount,
		idUser: user.idUser,
		userId: user.idUser,
		requestType: REQUEST_TYPES.DO_ADD_ACCOUNT_TEMPLATES,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		accountId: user.idAccount,
		idUser: user.idUser,
		userId: user.idUser,
		requestType: REQUEST_TYPES.DO_UPDATE_ACCOUNT_TEMPLATE,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

// DELETE
export const deleteTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		accountId: user.idAccount,
		idUser: user.idUser,
		userId: user.idUser,
		requestType: REQUEST_TYPES.DELETE_TEMPLATE,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const doFilterTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		idAccount: user.idAccount,
		accountId: user.idAccount,
		idUser: user.idUser,
		userId: user.idUser,
		requestType: REQUEST_TYPES.DO_FILTER_AUTO_ASSIGN_TEMPLATES,
		...body,
	};
	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

