import React, { useEffect } from 'react';

import RoutePages from '@core/constants/fielderRoutes/fielderRoutes';
import Layout from '@layouts/FielderLayout/FielderLayout';
import Login from '@pages/LoginPage/LoginPage';
import { useLanguage, useLanguageUpdate } from 'Context/LanguageContext';
import Forbidden403 from '@pages/ErrorPages/Forbidden403.page';
import NotFound404 from '@pages/ErrorPages/NotFound400.page';
import PrivateRoute from 'OldComponents/PrivateRoute/PrivateRoute';
import RecoverPassword from 'OldComponents/RecoverPassword';
import LogoutSSOMobile from 'OldComponents/LogoutSSOMobile';
import CognitoProvider from 'OldComponents/CognitoProvider';
import Register from 'OldComponents/Register/RegisterComponent';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { selectUser, selectRoles, selectValidPages } from '@core/store/modules/session/sessionSelectors';
import { setLogOutUser } from '@core/store/modules/session/sessionSlice';
import { getIdToken, getRefreshToken } from 'FielderUtils/session/Session';
import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';


const FielderRoutes = () => {
	const [isLogged, setLogged] = React.useState(false);

	const user = useSelector(selectUser);
	const lan = useLanguage();
	const setLan = useLanguageUpdate();

	const roles = useSelector(selectRoles);
	const validPages = useSelector(selectValidPages);
	const idToken = getIdToken();
	const refreshToken = getRefreshToken();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const isLogged = user && idToken && refreshToken && roles && validPages;
		setLogged(isLogged);
		if (!isLogged) {
			dispatch(setLogOutUser());
			if (location.pathname !== ENDPOINTS.register) {
				navigate(ENDPOINTS.login);
			}
		}
	}, [idToken, refreshToken]);

	useEffect(() => setLan(user ? user.language : 'en_US'), [user]);

	return (
		<Routes>
			<Route path='/' element={<Layout />}>
				<Route index path={ENDPOINTS.root} element={<Login />} />
				<Route path={ENDPOINTS.login} element={<Login />} />
				<Route path={ENDPOINTS.recoverPassword} element={<RecoverPassword lan={lan} />} />
        <Route path={ENDPOINTS.cognitoProvider} element={<CognitoProvider lan={lan} />} />
				<Route path={ENDPOINTS.register} element={<Register lan={lan} />} />
        <Route path={ENDPOINTS.logoutSSOMobile} element={<LogoutSSOMobile lan={lan} redirect={true} />} />

				{RoutePages.map(({ path, pageName, Component }) => (
						<Route
							key={path}
							path={path}
							element={
								<PrivateRoute pageName={pageName}>
									<Component lan={lan} pageName={pageName} />
								</PrivateRoute>
							}
						/>
					))}

				<Route
					path={ENDPOINTS.forbidden}
					element={<Forbidden403 lan={lan} redirect={true} />} />
				<Route path='*' element={<NotFound404 lan={lan} />} />
			</Route>
		</Routes>
	);
};

export default React.memo(FielderRoutes);
