import API_LINKS from 'FielderAPILinks';
import { getIdToken } from 'FielderUtils/session/Session';

import RoleUtils from '../roles/roleUtils';
import TeamStatus from '../team/teamStatus';

export const getAgentsByFilterAsyncSelect = async (filter, idAccount, user, idToken, lan) => {
	const agents = await getAgentsByFilter(filter, idAccount, user.idUser, idToken);
	return formatAgent(agents, lan, user);
};

export const formatAgent = (agents, lan, user) => {
	const agentsList = [];

	agents.forEach((agent) => {
		let agentTeamName;
		let teamInfo = '';
		let oneTeamDisable = 0;
		let teamControl = false;

		if (agent.teams.length > 0) {
			agent.teams.forEach((team) => {
				if (
					(RoleUtils.isUserManager(user) && team.idManagers && team.idManagers.includes(user.idUser)) ||
					RoleUtils.isUserSuperAndAdministrator(user)
				) {
					if (team.status === TeamStatus.ENABLE) {
						agentTeamName = team.name;
						teamInfo = ` - ${lan.team}: ${agentTeamName}`;
						teamControl = true;
					} else {
						teamInfo = '';
						oneTeamDisable++;
						teamControl = false;
						team.id = null;
					}

					if ((agent.status === 0 && teamControl) || oneTeamDisable <= 1) {
						let offDuty = '';
						if ('offDuty' in agent && !agent.onDuty) {
							offDuty = ` - ${lan.offDuty} `;
						}

						agentsList.push({
							label: `[${agent.idAgent}] - ${agent.name} ${agent.lastName} (${agent.email}) ${offDuty} ${teamInfo}`,
							value: { idAgent: agent.idAgent, idTeam: team.id },
						});
					}
				}
			});
		} else if (agent.status === 0) {
			let offDuty = '';
			if (!agent.onDuty) {
				offDuty = ` - ${  lan.offDuty  } `;
			}
			agentsList.push({
				label: `[${agent.idAgent}] - ${agent.name} ${agent.lastName} (${agent.email}) ${offDuty} ${teamInfo}`,
				value: { idAgent: agent.idAgent, idTeam: null },
			});
		}
	});

	return agentsList;
};

export const getAgentsByFilter = (filter, idAccount, idUser, idToken, bringOnlyAgentsWithoutTeam) => {
	const body = {
		type: 'GET_AGENTS_BY_FILTER',
		idUser,
		idAccount,
		filter,
		bringOnlyAgents: bringOnlyAgentsWithoutTeam
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.agents;
			}
			console.error(res);
			return [];
		});
};

export const getOnlyAgentsByFilterAsyncSelect = async (filter, idAccount, user, idToken, lan, bringOnlyAgentsWithoutTeam) => {
	const agents = await getAgentsByFilter(filter, idAccount, user.idUser, idToken, bringOnlyAgentsWithoutTeam);
	return formatOnlyAgents(agents, lan, user);
};

export const formatOnlyAgents = (agents, lan, user) => {
	const agentsList = [];

	agents.forEach((agent) => {
		const teamNumber = agent.teams.length;
		let agentTeamName;
		let teamInfo = '';
		let value;
		let extraInfo = '';

		if (agent.teams.length > 0) {
			agentTeamName = agent.teams[0].name;
			value = agent.teams.length - 1;

			if (teamNumber > 1) {
				extraInfo = `(${value} ${lan.plus} )`;
			}

			teamInfo = ` - ${lan.team}: ${agentTeamName} ${extraInfo}`;

			if (!RoleUtils.isUserManager(user)) {
				value = 0;

				if (teamNumber > 0) {
					agent.teams.forEach((team) => {
						if (team.idManagers && team.idManagers.includes(user.id)) {
							agentTeamName = team.name;
							value++;
						}
					});

					if (value > 0) {
						extraInfo = ` ( ${value} ${lan.plus} )`;
					}
					teamInfo = ` - ${lan.team}: ${agentTeamName} ${extraInfo}`;
				}
			}
		}
		if (agent.status == 0) {
			let offDuty = '';
			if (!agent.onDuty) {
				offDuty = ` - ${lan.offDuty} `;
			}
			agentsList.push({
				label: `${agent.idAgent  } ${  agent.name  } ${  agent.lastName  } (${  agent.email  })${  offDuty  }${teamInfo}`,
				value: agent,
			});
		}
	});

	return agentsList;
};

/** ********************  DEFATULT OPTIONS  *************** */
export const getOnlyAgentsInitialOptionsAsyncSelect = async (idAccount, user, idToken, lan, bringOnlyAgentsWithoutTeam) => {
	const agents = await getAgentsInitialOptions(idAccount, user.idUser, idToken, bringOnlyAgentsWithoutTeam);
	return formatOnlyAgents(agents, lan, user);
};

export const getAgentsInitialOptionsAsyncSelect = async (idAccount, user, idToken, lan) => {
	const agents = await getAgentsInitialOptions(idAccount, user.idUser, idToken);
	return formatAgent(agents, lan, user);
};

export const getAgentsInitialOptions = (idAccount, idUser, idToken, bringOnlyAgentsWithoutTeam) => {
	const body = {
		type: 'GET_AGENTS_DEFAULT_OPTIONS',
		idUser,
		idAccount,
		bringOnlyAgents: bringOnlyAgentsWithoutTeam
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.agents;
			}
			console.error(res);
			return [];
		});
};



export const getAllAgentsByFilter = (filter, role, userName, idToken) => {
	let body = {
		requestType: 'GET_AGENTS_BY_FILTER',
		filter: filter,
		user: {
			role: role,
			userName: userName,
		},
	};

	return fetch(API_LINKS.accountManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.agents;
			} else {
				console.error(res);
				return [];
			}
		});
};



export const getAgentsByFilterAsyncSelectMfa = async (filter, role, userName) => {

	let agents = await getAllAgentsByFilter(filter, role, userName, getIdToken());
	let usersList = [];

	agents.forEach((agent) => {
		usersList.push({
			label:
				agent.idAgent +
				' ' +
				agent.name +
				' '+
				agent.lastName +
				' (' +
				agent.userName + ' '+
				') ',
			value: agent,
		});
	});

	return usersList;
};


