import React, { useState, useEffect } from 'react';

//Library
import Logo from '@assets/icons/logo-login-icon.svg';
import { Modal, Image } from 'react-bootstrap';;
import { Row, Col } from 'react-bootstrap';

import MfaCardContainer from './MfaCardContainer';
import {  selectUser } from '@core/store/modules/session/sessionSelectors';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import { setLogOutUser } from '../../core/store/modules/session/sessionSlice';
import { useLanguage } from 'Context/LanguageContext';
import { useDispatch, useSelector } from 'react-redux';

//Css
import './MfaAuthentication.css';
import 'FielderUtils/style.css';
import { verifyMfaCode } from '@core/api/user/user.service';

const MfaAuthentication = (props) => {

	const fielderMsg = useFielderSnackBar();
	const dispatch = useDispatch();
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const [mfaCode, setMfaCode] = useState(null);

	const handleClose = () => {
		dispatch(setLogOutUser());
		props.closePrivatePolicy();
	};

	const componentCleanup = () => {
		dispatch(setLogOutUser());
	};

	useEffect(() => {
	
	const handleBeforeUnload = (event) => {
		componentCleanup();
		event.preventDefault();
		event.returnValue = "";
	};

	window.addEventListener("beforeunload", handleBeforeUnload);

	return () => {
		componentCleanup();
		window.removeEventListener("beforeunload", handleBeforeUnload);
	};
	}, []);

	const handleEnterMfaCode = (value) => {
			const onSuccess = (res) => {
				fielderMsg({ message: lan.mfaCodeSuccess, variant: 'success', closeButton: true, duration: 5000 });
				handleClose();
			};
			const onError = (res) => {
				if(res.status === 342) {
					fielderMsg({message: lan.mfaCodeIncorrect , variant: 'error', closeButton: true, duration: 5000})
			   } else if(res.status === 343) {
					fielderMsg({message: lan.mfaCodeIncorrect2 , variant: 'error', closeButton: true, duration: 5000})
			   } else{
					fielderMsg({message: lan.mfaCodeError , variant: 'error', closeButton: true, duration: 5000})
				}

			};
			
			const loadingConfig = { dispatch }
			verifyMfaCode({ mfaCode: value }, onSuccess, onError, loadingConfig);
	};
	
	return (
		<React.Fragment>
			<Modal
				id='ErrorPrivatePolicy'
				size='md'
				show={true}
				onHide={handleClose}
				dialogClassName='shadow-lg'
				className='Fielder-modals-header-border Fielder-modals-content-border shadow-lg'
			>
				<Modal.Header style={{justifyContent:'center', alignItems:'center'}}> 
					<Modal.Title>
						<div className='row'>
							<div className='col'>
								<Image src={Logo} />
							</div>
						</div>
						<div className='row'>
							<div className='col pl-0'>{lan.setUpDevice}</div>
						</div>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body className='error-message-modal-body text-center'>
					<MfaCardContainer  handleEnterMfaCode={handleEnterMfaCode} />
				</Modal.Body>

				<Modal.Footer>
					
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
	
}

export default MfaAuthentication;
