import React, { Component } from 'react';

//Library
import ExportTaskIcon from '@assets/icons/download-export-task.svg';
import ExportTask from 'FielderUtils/task/exportTask';
import { exportTaskColumns, buildTasksToExcel } from 'FielderUtils/task/exportTaskColums';
import { getExportTasksQueryParams, getExportGroupTasksQueryParams } from 'FielderUtils/task/exportTaskQueryParams';
import { Modal, Row, Image } from 'react-bootstrap';
import ReactExport from 'react-export-excel';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import LoadingConst from '../../Loading/LoadingConst';
import { getExportTaskCount , getExportGroupTaskCount } from 'FielderUtils/exportUtil/exportUtil';
import { getExportTaskList } from '@core/api/task/task';

//Css
import 'FielderUtils/style.css';
import 'rc-time-picker/assets/index.css';
import '../MyOperation.css';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';

// Export task
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var downloadData = [0];
var columnsExcel = [];

var LIMIT = 100;
var CHUNK_SIZE = 5;

class GenerateExportTask extends Component {
	constructor(props) {
		super(props);

		const excelColumns = exportTaskColumns(this.props.lan);

		this.state = {
			excelColumns: excelColumns,
			session: this.props.session,
			selectedCount: 0,
			total: excelColumns.length,
			filter: this.props.filter,
			to: this.props.to,
			from: this.props.from,
			datesFilterValues: this.props.datesFilterValues,
			advancedFilterValues: this.props.advancedFilterValues,
			data: [],
		};
	}

	componentDidMount = () => {
		columnsExcel = [
			{
				label: this.props.lan.idFielder,
				value: 'idFielder',
				number: ExportTask.ID_FIELDER,
			},
		];
	};

	addDataToDownload(value, columData) {
		downloadData.push(value);
		var selectedCount = this.state.selectedCount + 1;
		this.setState({ selectedCount: selectedCount });

		columnsExcel.push(columData);
	}

	deleteDataToDownload(value, columData) {
		for (let i = 0; i < downloadData.length; i++) {
			if (downloadData[i] == value) {
				downloadData.splice(i, 1);
			}
		}

		for (let i = 0; i < columnsExcel.length; i++) {
			if (columnsExcel[i].number == columData.number) {
				columnsExcel.splice(i, 1);
			}
		}

		var selectedCount = this.state.selectedCount - 1;
		this.setState({ selectedCount: selectedCount });
	}

	handleSelectAll = () => {
		downloadData = [0];
		columnsExcel = [
			{
				label: this.props.lan.idFielder,
				value: 'idFielder',
				number: ExportTask.ID_FIELDER,
			},
		];
		var excelValue = this.state.excelColumns;
		for (let i = 0; i < excelValue.length; i++) {
			document.getElementById('checkboxTask' + i).checked = true;

			if (excelValue[i].value != 0) {
				downloadData.push(excelValue[i].value);
				columnsExcel.push({
					label: excelValue[i].name,
					value: excelValue[i].key,
					number: excelValue[i].value,
				});
			}
		}

		this.setState({ selectedCount: this.state.total });
	};

	handleUncheckSelectAll = () => {
		downloadData = [0];
		columnsExcel = [
			{
				label: this.props.lan.idFielder,
				value: 'idFielder',
				number: ExportTask.ID_FIELDER,
			},
		];
		var excelValue = this.state.excelColumns;
		for (let i = 0; i < excelValue.length; i++) {
			if (excelValue[i] != 0) {
				document.getElementById('checkboxTask' + i).checked = false;
			}
		}

		this.setState({ selectedCount: 0 });
	};

	getFilter = (filter = this.state.filter) => {
		let tags = [];
		let filters = '';

		if (filter) {
			var splited = filter.split(' ');
			for (let i = 0; i < splited.length; i++) {
				let fltr = splited[i];

				if (fltr.includes('#')) {
					tags.push(fltr.slice(1));
				} else {
					filters = filters + ' ' + fltr;
				}
			}
		}

		if (tags.length === 0) {
			tags = null;
		}
		if (filters === '') {
			filters = null;
		}

		return {
			filter: filters,
			tags: tags,
		};
	};


	generateParams = (bringTasks, offset, limit) => {
		if (this.props.idGroup) {
			return getExportGroupTasksQueryParams(
				this.props.idGroup,
				this.props.filter,
				downloadData,
				offset,
				limit,
				this.state.session.user.idUser,
				bringTasks,
				this.props.filterFilterIdsTask
			);
		} else {
			return getExportTasksQueryParams(
				this.state.from.getDate(),
				this.state.from.getMonth(),
				this.state.from.getFullYear(),
				this.state.to.getDate(),
				this.state.to.getMonth(),
				this.state.to.getFullYear(),
				this.props.filter,
				this.props.tagsFilter,
				this.state.datesFilterValues,
				downloadData,
				this.state.advancedFilterValues,
				offset,
				limit,
				this.state.session.user.idUser,
				bringTasks
			);
		}
	};

	handleExportTasksExcel = async () => {
		this.props.dispatch(
			loadingStart({
				name: "GENERATE_EXPORT_TASK",
				config: {
					icon: LoadingConst.EXPORT,
					text: this.props.lan.exportMessage,
					progress: 0,
				},
			})
		);

		this.setState({ downloadData: downloadData });
		
		let queryExportParams = this.generateParams(false, 0, 0);		
		let totalTasks = 0;

		if (this.props.idGroup) {
			totalTasks = await getExportGroupTaskCount(queryExportParams);
		} else {
			totalTasks = await getExportTaskCount(queryExportParams);
		}

		const totalRequests = Math.ceil(totalTasks / LIMIT);
		const requestsArray = Array.from({ length: totalRequests }, (v, k) => k);
		let data = [];
		let progress = 0;

		for (let i = 0; i < requestsArray.length; i += CHUNK_SIZE) {
			const chunk = requestsArray.slice(i, i + CHUNK_SIZE);

			await Promise.all(
				chunk.map((i) => {
					const offset = i * LIMIT;
					progress = (offset * 100) / totalTasks;
					this.props.dispatch(
						loadingStart({
							name: 'GENERATE_EXPORT_TASK',
							config: {
								icon: LoadingConst.EXPORT,
								text: this.props.lan.exportMessage,
								progress,
							},
						})
					);

					const onSuccess = ({ tasks }) => data.push(...buildTasksToExcel(tasks, this.state.session, this.props.lan));
					const onError = () => this.props.dispatch(loadingStop('GENERATE_EXPORT_TASK'));
					const queryExportParams = this.generateParams(true, offset, LIMIT);
					return getExportTaskList(queryExportParams, onSuccess, onError, this.props.idGroup);


				})
			).then(() => this.setState({ data: data }));
		}

		this.downloadExcel(this.state.data);
	};


	downloadExcel = (data) => {
		data.sort((a, b) => b.idFielder - a.idFielder);
		this.props.dispatch(loadingStop('GENERATE_EXPORT_TASK'));

		this.setState({ exportableData: data }, () => {
			document.getElementById('myExcellModal').click();
		});
	};

	render() {
		return (
			<React.Fragment>
				<Modal
					show={true}
					size='xl'
					className='Fielder-modals-header-border Fielder-modals-content-border'
					onHide={this.props.onClose}
				>
					<Modal.Header
						className='Fielder-modals-header-border no-padding-bottom Fielder-modals-content-border'
						closeButton
					>
						<Modal.Title className='full-width'>
							<Row className='full-width ml-2 '>
								<div className='col-auto padding-right'>
									<Image src={ExportTaskIcon} style={{ width: '20px' }} alt='' />
								</div>
								<div className='col-auto padding-lef ml-2 mt-2'>
									<p className='Fielder-modal-popUp-title padding-lef-title '> {this.props.lan.exportTask} </p>
								</div>

								<div className='col d-flex justify-content-end mt-2'>
									<span style={{ fontSize: '16px' }}>
										{this.state.selectedCount} {this.props.lan.selectedElements} / {this.state.total}
									</span>
								</div>
							</Row>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ExcelFile
							filename={this.props.lan.downloadTaskFileName}
							element={<button id='myExcellModal' hidden></button>}
						>
							<ExcelSheet
								data={this.state.exportableData}
								name={this.props.lan.tasks}
							>
								{columnsExcel.map((col) => {
									return <ExcelColumn label={col.label} value={col.value} />;
								})}
							</ExcelSheet>
						</ExcelFile>

						<div className='row'>
							<div className='col mt-3 mx-4 mb-3'>{this.props.lan.selectOptions}</div>
						</div>

						<Scrollbars style={{ height: '350px', width: '100%' }}>
							<div className='row mx-2'>
								{this.state.excelColumns.map((excelColumn, index) => {
									return (
										<React.Fragment>
											<div className='col-4' key={index}>
												<div className='row'>
													<div className='col-auto'>
														<input
															className='form-check-input labelCheckbox d-none'
															type='checkbox'
															value={excelColumn.value}
															checked={excelColumn.value == 0 ? true : null}
															disabled={excelColumn.value == 0 ? true : null}
															id={'checkboxTask' + index}
															onClick={() => {
																var validateChecked = document.getElementById('checkboxTask' + index).checked;
																if (validateChecked == true) {
																	this.addDataToDownload(excelColumn.value, {
																		label: excelColumn.name,
																		value: excelColumn.key,
																		number: excelColumn.value,
																	});
																} else {
																	this.deleteDataToDownload(excelColumn.value, {
																		label: excelColumn.name,
																		value: excelColumn.key,
																		number: excelColumn.value,
																	});
																}
															}}
														/>

														<label
															key={excelColumn.key}
															htmlFor={'checkboxTask' + index}
															className='round-checkbox'
														></label>
													</div>
													<div className='col-auto no-padding'>
														<span className='textFormat-taskGroupDetail-task'>{excelColumn.name}</span>
													</div>
												</div>
											</div>
										</React.Fragment>
									);
								})}
							</div>
						</Scrollbars>

						<div className='row mb-3 mx-2'>
							<div className='col-auto d-flex justify-content-start mt-2'>
								<button
									type='submit'
									className='btn btn-outline-primary btn-success create-edit-modal-btnsave-size font-size-taskgroup'
									onClick={this.handleSelectAll}
								>
									{this.props.lan.selectAll}
								</button>
							</div>

							<div className='col-auto d-flex justify-content-start mt-2'>
								<button
									type='submit'
									className='btn btn-outline-primary btn-success create-edit-modal-btnsave-size font-size-taskgroup'
									onClick={this.handleUncheckSelectAll}
								>
									{this.props.lan.uncheckEverything}
								</button>
							</div>

							<div className='col d-flex justify-content-end mt-2'>
								<button
									type='submit'
									className='btn btn-outline-primary btn-success create-edit-modal-btnsave-size font-size-taskgroup'
									onClick={this.handleExportTasksExcel}
								>
									{this.props.lan.download}
								</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(GenerateExportTask);
