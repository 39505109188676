import { REQUEST_TYPES } from '@core/constants/taskPriority/taskPriority.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';

export const getTaskPriorityRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASK_PRIORITIES,
		idAccount: user.idAccount,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getTaskPriorityByOriginRequest = async (body,onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASK_PRIORITIES_BY_ORIGINS,
		idAccount: user.idAccount,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getTaskPriorityByFilter = async (body, onSuccess, onError) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASK_PRIORITIES_BY_FILTER,
		idAccount: user.idAccount,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError);
};


export const saveTaskPriorityRequest = async (body,onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_ACTION_CREATE_TASK_PRIORITY,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const editTaskPriorityRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_ACTION_EDIT_TASK_PRIOIRITY,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};


export const deleteTaskPriorityRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_ACTION_DELETE_TASK_PRIORITY,
		idAccount: user.idAccount,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);

}
