import React, { Component } from 'react';

import TeamsIcon from '@assets/icons/teams-icon.svg';
import { getIdToken } from 'FielderUtils/session/Session';
import {
	getTeamsByFilterAsyncSelect,
	getTeamsInitialOptionsAsyncSelect,
} from 'FielderUtils/team/team';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import Popup from '../../Popup';

import { assingTeamTaskRequest} from '@core/api/task/task.service';

import '../MyOperation.css';

class AssignTeamPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			idTeamSelected: null,
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
		};
	}

	assignTeam = async ( ) => {
		this.props.dispatch(loadingStart({ name: this.props.pageName }))
		
		if (this.state.idTeamSelected !== null) {	
			for (let i = 0; i < this.props.idTasks.length; i++) {
				
				var body = {
					taskInfo: {
						idTask: this.props.idTasks[i],
						idUser: this.state.idUser,
						idTeam: this.state.idTeamSelected,
						resetPartner: false,
						idAccount: this.state.idAccount,
					},
				};

				const onSuccess = (response) => {		
					if (this.props.idTasks.length === (i + 1)) {

						this.props.onClose({
							statusCode: 200,
							response: response,
						});
						this.props.dispatch(loadingStop(this.props.pageName))
					}
				}
				await assingTeamTaskRequest(body, onSuccess, null, null)
			}
		}
	};

	setFieldAssign = (event) => {
		this.setState({
			idTeamSelected: event.value.id,
		});
	};

	getAssignList = async (event) => {
		if (event.length > 0) {
			var teamsListArray = await getTeamsByFilterAsyncSelect(
				event,
				this.state.idAccount,
				this.state.idUser,
				getIdToken(),
				this.props.lan
			);

			return teamsListArray;
		}
	};

	getInitialOptionsTeam = async () => {
		return await getTeamsInitialOptionsAsyncSelect(
			this.state.idAccount,
			this.state.idUser,
			getIdToken(),
			this.props.lan
		);
	};

	render() {
		var assingTeamLabel = this.props.lan.createAgentSkillsFieldPlaceHolder;
		var managerName = '';

		if (
			this.props.teamTask !== undefined &&
			this.props.teamTask !== null &&
			this.props.teamTask.length > 0 &&
			this.props.teamTask[0].user
		) {
			managerName =
				' (' + this.props.teamTask[0].user.name + ' ' + this.props.teamTask[0].user.lastName + ')';
		}

		if (
			this.props.teamTask !== undefined &&
			this.props.teamTask !== null &&
			this.props.teamTask.length > 0 &&
			this.props.idTasks.length === 1
		) {
			assingTeamLabel = this.props.teamTask[0].id + ' ' + this.props.teamTask[0].name + managerName;
		}

		return (
			<Popup
				title={this.props.lan.assignTeam}
				icon='teams-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText='Asignar'
				buttonOnClick={this.assignTeam}
			>
				<Row className=''>
					<Col sm={'auto'} className='my-auto'>
						<img src={TeamsIcon} className='icon mr-auto' alt='' />
					</Col>
					<div className='col'>
						<AsyncSearch
							className='react-select__control full-width'
							getOptions={(e) => this.getAssignList(e)}
							onChange={(e) => this.setFieldAssign(e)}
							label={assingTeamLabel}
							lan={this.props.lan}
							id='all-AsyncSearch'
							showInitialOptions
							getInitialOptions={() => this.getInitialOptionsTeam()}
						/>
					</div>
				</Row>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AssignTeamPopup);
