import React, { useState, useEffect } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import TextareaAutosize from 'react-autosize-textarea';
import { Col, Row } from 'react-bootstrap';

import { getFieldQuestion } from '../util';

const AssetInventoryHeader = ({
	showFormFile,
	assetInventories,
	activeAssetInventory,
	timezone,
	getTextFileHeader
}) => {
	const lan = useLanguage();

	const [fields, setFields] = useState([]);

	useEffect(() => {
		setFields(assetInventories[activeAssetInventory].fields);
	}, [activeAssetInventory, assetInventories]);

	const AssetInventoryFields = () => {
		let assetInventoryFields = [];
		var defaultFields = ['id','name', 'type', 'brand', 'model', 'serialNumber'];
		let value = '';

		defaultFields.forEach((defaultField) => {
			value = assetInventories[activeAssetInventory][defaultField];
			if (value != null && value !== '') {
				assetInventoryFields.push(
					<Row className='mt-4'>
						<Col sm={10} className='ml-auto mr-5'>
							<Row>
								<Col sm={3}>{lan[defaultField]}</Col>
								<Col sm={9}>
									<TextareaAutosize
										type='text'
										readOnly
										className={'task-details-input'}
										value={value}
										style={{
											width: '100%',
											paddingTop: '5px',
											paddingLeft: '10px',
											paddingBottom: '5px',
											paddingRight: '10px',
										}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				);
			}
		});
		

		fields?.forEach((field, i) => {
			if (
				field.title !== 'Name' &&
				field.title !== 'Type' &&
				field.title !== 'Brand' &&
				field.title !== 'Serial Number' &&
				field.title !== 'Model'
			) {
				assetInventoryFields.push(
					<div key={'Forms-row-' + i} className='mt-4'>
						{getFieldQuestion(field, showFormFile, timezone, lan, getTextFileHeader, true)}
					</div>
				);
			}
		});

		return assetInventoryFields;
	};

	return <AssetInventoryFields />;
};
export default AssetInventoryHeader;
