import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {  Typography } from '@mui/material';


class TooltipFormatter extends React.Component {
	render() {
		return (
			<div className='row no-padding '>
				<Tooltip title={this.props.value} placement='right'>
                    <Typography sx={{
                        fontSize: '12px',       
                        fontFamily: 'Lato',   
                       
                    }} className='tooltip-text-format'>{this.props.value}</Typography>
				</Tooltip>
			</div>
		);
	}
}

export default TooltipFormatter;
