import React, { useEffect, useState, useRef } from 'react';

import AddIcon from '@assets/icons/red-add-icon.svg';
import Required from '@assets/icons/required.svg';
import CreateGroup from '@assets/icons/create-group-tasks.svg';
import {
	getBranchesByFilterAsyncSelectCreateTask,
	getBranchesInitialOptionsAsyncSelect,
} from 'FielderUtils/branch/branch';
import { getPartnersByFilterAsyncSelect, getPartnerInitialOptionsAsyncSelect } from 'FielderUtils/partner/partner';
import { getIdToken } from 'FielderUtils/session/Session';
import { getTeamsByFilterAsyncSelect, getTeamsInitialOptionsAsyncSelect } from 'FielderUtils/team/team';
import { Row, Col, Form, Image, Container, Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { useLanguage } from 'Context/LanguageContext';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import DatePickerFielder from '../../DatePickerFielder/DatePickerFielder';
import { getMyOperationAgentsRequest } from '@core/api/agents/agents.service';
import { createTaskGroupRequest } from '@core/api/task/task.group.service';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import Scrollbars from 'react-custom-scrollbars';
import Lottie from 'react-lottie';
import lottie from '@assets/lotties/loadingFielder.json';

import '../MyOperation.css';
/**
 *
 *  Popup used to Create a Group task
 */
const CreateGroupTasksPopup = (props) => {

	const lan = useLanguage();
	const user = useSelector(selectUser);
	const dispatch = useDispatch();

	const [idCustomGroupTask, setIdCustomGroupTask] = useState('');
	const [name, setName] = useState('');

	const cbnoassign = useRef({});
	const cbagent = useRef({});
	const cbteam = useRef({});
	const cbpartner = useRef({});

	const [showNoAssign, setShowNoAssign] = useState(RoleUtils.isUserManager(user) ? false : true);
	const [showTeamSearch, setShowTeamSearch] = useState(false);
	const [showPartnerSearch, setShowPartnerSearch] = useState(false);
	const [showAgentSearch, setShowAgentSearch] = useState(false);

	const [dateSelected, setDateSelected] = useState(new Date());
	const [isDateSelected, setIsDateSelected] = useState(true);
	const [minTime, setMinTime] = useState(new Date());

	const [selectedAgents, setSelectedAgents] = useState([]);
	const [agent, setAgent] = useState([]);
	const [agents, setAgents] = useState([]);
	const [idBranch, setIdBranch] = useState(null);
	const [idTeam, setIdTeam] = useState(null);
	const [idPartner, setIdPartner] = useState(null);


	const [requiredIdCustomGroupTask, setRequiredIdCustomGroupTask] = useState(false);
	const [requiredName, setRequiredName] = useState(false);
	const [requiredComment, setRequiredComment] = useState(false);
	const [requiredSchedule, setRequiredSchedule] = useState(false);
	const [requiredBranch, setRequiredBranch] = useState(false);

	const [dontShowToManager, setDontShowToManager]= useState(RoleUtils.isUserManager(user) ? false: true );
	const [activateLoadingManager, setActivateLoadingManager] = useState(false);

	useEffect(() => {
		if (!RoleUtils.isUserManager(user)) {
			manageGetAgents(0);
		}
		else {
			setShowTeamSearch(true)
		}
	}, [])

	useEffect(() => {
		if (RoleUtils.isUserManager(user) && idTeam != null) {
			manageGetAgents(idTeam);
		}
	}, [idTeam])

	const manageGetAgents = (idTeam) => {
		const onSuccess = (res) => {
			let agents = [];
			if (res.agents) {
				agents = res.agents.map((element) => ({
					label: element.name + ' ' + element.lastName + ', ' + element.email,
					value: element,
				}));
			}
			setAgents(agents);
			setActivateLoadingManager(false);
		};

		const loadingConfig = { dispatch, config: { allowTopBar: true } }
		getMyOperationAgentsRequest(onSuccess, null, loadingConfig, idTeam);
	}


	const addAgent = (agent) => {

		let selectedAgentsAux = selectedAgents ? selectedAgents : [];

		if (agent && !validateHasAgent(selectedAgentsAux, agent)) {
			// Add new tag
			selectedAgentsAux.push(agent);

			// Reset input and send new type to state
			setSelectedAgents([...selectedAgentsAux])
		}
	};

	const validateHasAgent = (allAgents, agent) => {
		for (let i = 0; i < allAgents.length; i++) {
			const element = allAgents[i];
			if (agent.idAgent === element.idAgent) return true;
		}
		return false;
	};

	const removeAgent = (index) => {
		var agents = selectedAgents;
		agents.splice(index, 1);

		setSelectedAgents([...agents])
	};

	const CreateAgents = () => {
		return(
			selectedAgents.map((agent, i) => (
				<Col sm={'auto'} className='m-1' key={agent.id + '-tag'}>
					<Row className='tag-button my-auto'>
						<div className='mt-1'>
							<Container key={agent.id + '-tag-container'}>
								<span className='mr-3'>{agent.name + ' ' + agent.lastName} </span>
								<button
									key={'btn-close-' + i}
									id={'btn-close-' + i}
									className='close'
									aria-label='Close'
									onClick={() => removeAgent(i)}
								>
									<span style={{ fontSize: '20px', margin:'0px', position:'relative', top:'-4px', right:'-6px'}} aria-hidden='true'>
										&times;
									</span>
								</button>
							</Container>
						</div>
					</Row>
				</Col>
			))
		)
	}


	const getIdsAgents = () => {
		const agents = selectedAgents;
		let ids = [];

		for (var i = 0; i < agents.length; i++) {
			const agent = agents[i];
			ids.push(agent.idAgent);
		}
		return ids;
	};

	const handleCloseModal=() => {
		props.setDefaultFalse();
	}

	const createGroupTasks = () => {
		const hasIdCustom = idCustomGroupTask.localeCompare('') !== 0;
		const hasName = name.localeCompare('') !== 0;
		const comment = document.getElementById('comment').value;
		const hasComment = comment.localeCompare('') !== 0;
		const hasSchedule = isDateSelected;
		const hasBranch = idBranch !== null;
		const idAgents = getIdsAgents();

		if (hasIdCustom && hasName && hasComment && hasBranch) {
			const body = {
				groupTask: {
					idAccount: user.idAccount,
					idCustomGroup: idCustomGroupTask,
					name: name,
					description: comment,
					createdDateTime: new Date().getTime(),
					executionDateTime: dateSelected.getTime(),
					idBranch: idBranch,
					idsAgent: idAgents,
					idTeam: idTeam,
					idPartner: idPartner,
				},
				idTasks: props.idTasks,
			};

			const onSuccess = () => {
				handleCloseModal();
				props.onClose();
			}

			const loadingConfig = { dispatch };
			createTaskGroupRequest(body, onSuccess, console.error, loadingConfig);
		} else {
			setRequiredIdCustomGroupTask(!hasIdCustom)
			setRequiredName(!hasName)
			setRequiredComment(!hasComment)
			setRequiredSchedule(!hasSchedule)
			setRequiredBranch(!hasBranch)
		}
	};

	/**
	 * @desc This function receives two strings and changes
	 * the state of the userInfo object depending on
	 * the second parameter that it receives
	 *
	 * @param {string} value - value string param
	 * @param {string} field - field string param
	 *
	 *
	 **/


	const handleProgramDate = (date) => {
		var minTime = new Date();
		if (
			date.getDate() !== new Date().getDate() ||
			date.getMonth() !== new Date().getMonth() ||
			date.getFullYear() !== new Date().getFullYear()
		) {
			minTime = new Date().setHours(0, 0);
		}

		setDateSelected(date)
		setIsDateSelected(true)
		setMinTime(minTime)

	};

	/********************************************* */

	const getTeamList = async (event) => {
		if (event.length > 0) {
			let assignList = [];

			assignList = await getTeamsByFilterAsyncSelect(event, user.idAccount, user.idUser, getIdToken(), lan);

			return assignList;
		}
	};

	const getInitialOptionsTeam = async () => {
		return await getTeamsInitialOptionsAsyncSelect(user.idAccount, user.idUser, getIdToken(), lan);
	};

	const setFieldTeam = (event) => {
		if (event && event.value && event.value.id != idTeam) {
			setIdTeam(event.value.id);
			setActivateLoadingManager(true)
			setSelectedAgents([])
		}
	};

	const getPartnerList = async (event) => {
		if (event.length > 0) {
			let assignList = [];

			assignList = await getPartnersByFilterAsyncSelect(event, user.idAccount, user.idUser, getIdToken());

			return assignList;
		}
	};

	const getInitialOptionsPartner = async () => {
		return await getPartnerInitialOptionsAsyncSelect(user.idAccount, user.idUser, getIdToken());
	};

	const setFieldPartner = (event) => {
		if (event != null) {
			setIdPartner(event.value);
		} else {
			setIdPartner(null);
		}
	};

	const getBranchOptions = async (e) => {
		return await getBranchesByFilterAsyncSelectCreateTask(e, user.idAccount, user, getIdToken());
	};

	const getInitialOptionsBranch = async () => {
		return await getBranchesInitialOptionsAsyncSelect(user.idAccount, user, getIdToken());
	};

	const setFieldBranch = (event) => {
		if (event != null) {
			setIdBranch(event.value.id)
		} else {
			setIdBranch(null)
		}
	};

	const getUserNameHelperText = () => {
	 if (idCustomGroupTask.length >= 30) {
			return lan.userNameFieldHelp;
		}

		return null;
	};

	return (

		<Modal
			show={true}
			size='xl'
			className='Fielder-modals-header-border Fielder-modals-content-border'
			onHide={props.onClose}
		>

			<Modal.Header
						className='Fielder-modals-header-border no-padding-bottom Fielder-modals-content-border'
						closeButton
					>
						<Modal.Title style={{ width: '100%' }}>
							<Row style={{ width: '100%' }}>
								<div className='col-auto pr-0'>
									<Image src={CreateGroup} className='Add-branch-add-icon-position' alt='' />
								</div>
								<div className='col mt-2'>
									<p className='Fielder-modal-popUp-title Add-branch-text-title-position'>
										{' '}
										&nbsp; {lan.createGroupTaskTitle}
									</p>
								</div>
							</Row>
						</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<Row className='mx-2'>
				<Col sm={6} className='mx-0'>
					<div className='mt-1'>
						<Row>
							<Col className='small-popup-label'>{lan.idCustomGroupTask}</Col>
						</Row>
						<Row className='mr-1'>
							<div className='form-group col'>
								<Form.Control
									type='text'
									name='idCustom'
									id='idCustom'
									placeholder={lan.idCustomGroupTask}
									value={idCustomGroupTask}
									onChange={(e) => setIdCustomGroupTask(e.target.value)}
									maxLength={30}

								/>
							</div>
							<div className='col-auto pl-0'>
								{requiredIdCustomGroupTask && <img src={Required} alt='' />}
							</div>
						</Row>
						<Row className='ml-2' style={{color:'#dc5c87'}}>
								{idCustomGroupTask.length >= 30 && lan.maxLimitTaskGroupReached}
						</Row>	
					</div>

					{/* Date Picker */}
					<div className='mt-3'>
						<Row>
							<Col className='small-popup-label'>{lan.executionDate}</Col>
						</Row>

						<DatePickerFielder
							id='executionDate-red-input'
							selected={dateSelected}
							handleDate={handleProgramDate}
							minDate={new Date()}
							timeFormat='HH:mm'
							minTime={minTime}
							maxTime={new Date().setHours(23, 59)}
							dateFormat='dd/MM/yyyy h:mm aa'
							timeIntervals={30}
							classNamePicker='MyOperation-eschedule form-control mt-1 text-left datePicker-zIndex'
							imgId='executionDate-alert-icon'
							classNameImg='CreateTask-dir-desc-fieldContainer-icon'
						/>
					</div>

					<div className='mt-3'>
						<Row>
							<Col className='small-popup-label'>{lan.description}</Col>
						</Row>
						<Row className='mr-1'>
							<div className='form-group col'>
								<textarea className='form-control' rows='3' cols='40' id='comment'></textarea>
							</div>
							<div className='col-auto pl-0 pt-2 mt-3'>
								{requiredComment && <img src={Required} alt='' />}
							</div>
						</Row>
					</div>

				</Col>
				<Col sm={6} className='mx-0'>
					<div className='mt-1'>
						<Row>
							<Col className='small-popup-label'>{lan.name}</Col>
						</Row>
						<Row className='mr-1'>
							<div className='form-group col'>
								<Form.Control
									type='text'
									name='name'
									id='name'
									placeholder={lan.name}
									value={name}
									onChange={(e) => setName(e.target.value)}
									maxLength={128}
								/>
							</div>
							<div className='col-auto pl-0'>{requiredName && <img src={Required} alt='' />}</div>
						</Row>
						<Row className='ml-2' style={{color:'#dc5c87'}}>
								{name.length >= 128 && lan.maxLimitTaskGroupNameReached}
						</Row>
					</div>

					<div className='mt-3'>
						<Row>
							<Col className='small-popup-label'>{lan.branch}</Col>
						</Row>
						<Row className='mt-2 mr-1'>
							<div className='col'>
								<AsyncSearch
									className='react-select__control full-width mt-1 mb-3 search-zIndex'
									multiple={false}
									getOptions={getBranchOptions}
									onChange={(e) => setFieldBranch(e)}
									label={lan.placeHolderBranch}
									id='branch-AsyncSearch'
									showInitialOptions
									getInitialOptions={getInitialOptionsBranch}
									style={{height:'25px'}}
								/>
							</div>
							<div className='col-auto pl-0 pt-2 mt-3'>
								{requiredBranch && <img src={Required} alt='' />}
							</div>
						</Row>
					</div>
				</Col>
				<Scrollbars className='my-3' style={!showNoAssign ? { height: '300px' }: { height: '100px' } }

				>
				<Col sm={12} className='mx-0  track-horizontal track-horizontal-height '  style={{ overflowX: 'hidden !important' }}>
					<Row className='mt-3'>
					{dontShowToManager && (
						<Col>
							<div className='card'>
								<Row className='mx-auto mt-2'>
									<input
										type='checkbox'
										className='form-check-input labelCheckbox d-none'
										id='checkbox-no-assign'
										name='checkbox-no-assign'
										ref={cbnoassign}
										checked={showNoAssign}
									/>

									<label
										htmlFor='checkbox-branch'
										className='square-checkbox ml-2'
										onClick={() => {
											setSelectedAgents([])
											setIdPartner(null);
											setIdTeam(null);
											setShowNoAssign(true)
											setShowTeamSearch(false)
											setShowPartnerSearch(false);
											setShowAgentSearch(false)

										}}
									></label>
									<div className='tag ml-4' style={{ textAlign: 'center' }}>
										{lan.doNotAssign}
									</div>
								</Row>
							</div>
						</Col>
						)}

						<Col className={ dontShowToManager ? ''  : 'col-4'}>
							<div className='card'>
								<Row className='mx-auto mt-2'>
									<input
										type='checkbox'
										className='form-check-input labelCheckbox d-none'
										id='checkbox-team'
										name='checkbox-team'
										ref={cbteam}
										checked={showTeamSearch}
									/>

									<label
										htmlFor='checkbox-branch'
										className='square-checkbox ml-2'
										onClick={() => {

											setSelectedAgents([])
											setIdPartner(null);
											setShowNoAssign(false)
											setShowTeamSearch(true)
											setShowPartnerSearch(false);
											setShowAgentSearch(false)
										}}
									></label>
									<div className='tag ml-4' style={{ textAlign: 'center' }}>
										{lan.team}
									</div>
								</Row>
							</div>
						</Col>
						{dontShowToManager && (
							<Col>
								<div className='card'>
									<Row className='mx-auto mt-2'>
										<input
											type='checkbox'
											className='form-check-input labelCheckbox d-none'
											id='checkbox-partner'
											name='checkbox-partner'
											ref={cbpartner}
											checked={showPartnerSearch}
										/>
										<label
											htmlFor='checkbox-partner'
											className='square-checkbox ml-2'
											onClick={() => {
												setSelectedAgents([])
												setIdTeam(null);
												setShowNoAssign(false)
												setShowTeamSearch(false)
												setShowPartnerSearch(true);
												setShowAgentSearch(false)

											}}
										></label>
										<div className='tag ml-4' style={{ textAlign: 'center' }}>
											{lan.partners}
										</div>
									</Row>
								</div>
							</Col>
						)}
						{dontShowToManager && (
						<Col>
							<div className='card'>
								<Row className='mx-auto mt-2'>
									<input
										type='checkbox'
										className='form-check-input labelCheckbox d-none'
										id='checkbox-agent'
										name='checkbox-agent'
										ref={cbagent}
										checked={showAgentSearch}
									/>
									<label
										htmlFor='checkbox-agent'
										className='square-checkbox ml-2'
										onClick={() => {
											setIdPartner(null);
											setIdTeam(null);
											setShowNoAssign(false)
											setShowTeamSearch(false)
											setShowPartnerSearch(false);
											setShowAgentSearch(true)
											setActivateLoadingManager(false)
										}}
									></label>
									<div className='tag ml-4' style={{ textAlign: 'center' }}>
										{lan.agent}
									</div>
								</Row>
							</div>
						</Col>
						)}
					</Row>
					{/***********************************************************/}
					<React.Fragment>
						{(showNoAssign || RoleUtils.isUserManager(user))  && (
							<Row className="mb-3" />
						)}

						{showTeamSearch && (
							<React.Fragment>
								<Row>
									<Col sm={12} className='mt-3 ml-1 mb-4'>
										<span className='title-padding text-title'>{lan.addTeam}</span>
									</Col>
									<Col key='ml-2 col-task-assing-to'>
										<AsyncSearch
											className='react-select__control full-width mt-1 mb-3'
											getOptions={getTeamList}
											onChange={setFieldTeam}
											label={lan.team}
											showInitialOptions
											getInitialOptions={getInitialOptionsTeam}
										/>
									</Col>
								</Row>
							</React.Fragment>
						)}

						{showPartnerSearch && (
							<React.Fragment>
								<Row>
									<Col sm={12} className='mt-3 ml-1 mb-4'>
										<span className='title-padding text-title'>{lan.addPartner}</span>
									</Col>
									<Col key='ml-2 col-task-assing-to'>
										<AsyncSearch
											className='react-select__control full-width mt-1 mb-3'
											getOptions={getPartnerList}
											onChange={setFieldPartner}
											label={lan.partner}
											id='all-AsyncSearch'
											showInitialOptions
											getInitialOptions={getInitialOptionsPartner}
										/>
									</Col>
								</Row>
							</React.Fragment>
						)}

						{(RoleUtils.isUserManager(user) ||   showAgentSearch) && (
							<React.Fragment>
								<Row>
									<Col sm={12} className='mt-3 ml-1 mb-4'>
										<span className='title-padding text-title'>{lan.addAgents}</span>
									</Col>
									{!activateLoadingManager ? (
													<>
									<Col sm={10}>
										<Select
											className='react-select__control full-width mt-1 mb-3'
											options={agents}
											onChange={(event) =>  setAgent(event.value)}
											placeholder={lan.placeHolderClient}
										/>
									</Col>
									<Col sm={2} className='d-flex justify-content-end'>
										<Image
											className='ml-3'
											src={AddIcon}
											alt={lan.searchBy}
											onClick={() => addAgent(agent)}
										/>
									</Col>
									</>
											) : (
												<div className='col-auto mt-1 mr-2 no-padding ml-4'>
												<Lottie
													options={{
														loop: true,
														autoplay: true,
														animationData: lottie,
													}}
													height={'40px'}
													width={'40px'}
												/>
											</div>
											)}
								</Row>
								<Row className='ml-3 mb-3'>
									<CreateAgents />
								</Row>
							</React.Fragment>
						)}



					</React.Fragment>

					{(requiredIdCustomGroupTask ||
					requiredName ||
					requiredComment ||
					requiredSchedule ||
					requiredBranch) && (
					<div className='justify-content-center mt-2 mb-4 row Fielder-field-required'>
						*{lan.requiredFields}
					</div>

								)}

				</Col>
				</Scrollbars>
				<div className="col d-flex justify-content-center mt-3">
					<Button
						className='btn btn-outline-primary btn-success create-client-modal-btnsave-size mx-auto my-auto'
						onClick={createGroupTasks}
					>
					{lan.create}
					</Button>
				</div>
			</Row>

			</Modal.Body>

		</Modal>
	);
}

export default CreateGroupTasksPopup;
