export enum REQUEST_TYPES {
	GET_ASSET_CONFIG = 'GET_ASSET_CONFIG',
	DO_UPDATE_ASSET_CONFIG = 'DO_UPDATE_ASSET_CONFIG',
	GET_VIEW_CATEGORIES = 'GET_VIEW_CATEGORIES',
	GET_VIEW_ASSETS_TABLE = 'GET_VIEW_ASSETS_TABLE',
	DO_DELETE_ASSET_MGMT_CATEGORY = 'DO_DELETE_ASSET_MGMT_CATEGORY',
	DO_CREATE_ASSET_MGMT_CATEGORIES = 'DO_CREATE_ASSET_MGMT_CATEGORIES',
	DO_UPDATE_ASSET_MGMT_CATEGORIES = 'DO_UPDATE_ASSET_MGMT_CATEGORIES',
	GET_SPECIFIC_ASSET = 'GET_SPECIFIC_ASSET',
	DO_CREATE_ASSET= 'DO_CREATE_ASSET',
	DO_UPDATE_ASSET = 'DO_UPDATE_ASSET',
	DO_REMOVE_ASSET_IMAGE = 'DO_REMOVE_ASSET_IMAGE',
	DO_REMOVE_ASSET_IMAGE_FROM_TASK = 'DO_REMOVE_ASSET_IMAGE_FROM_TASK',
	GET_ASSETS_TO_EXPORT = 'GET_ASSETS_TO_EXPORT',
	DO_IMPORT_CATEGORY_ASSETS = 'DO_IMPORT_CATEGORY_ASSETS',
	CHANGE_ASSET_STATUS = 'CHANGE_ASSET_STATUS',
	DO_DELETE_ASSET = 'DO_DELETE_ASSET',
	GET_ALL_ASSETS = 'GET_ALL_ASSETS',
	GET_ASSETS_FROM_TASKS = 'GET_ASSETS_FROM_TASKS',
	DO_ACTION_GENERATE_ASSETS_REPORT = 'DO_ACTION_GENERATE_ASSETS_REPORT',
	DO_UPLOAD_ASSET_CATEGORY_TEMPLATE = 'DO_UPLOAD_ASSET_CATEGORY_TEMPLATE',
	DO_ACTION_GET_ASSET_HTML = 'DO_ACTION_GET_ASSET_HTML',
	DO_UPDATE_TASK_ASSET = 'DO_UPDATE_TASK_ASSET',
	DO_ACTION_GET_ASSET_XLSX_OR_HTML_FROM_FILE = 'DO_ACTION_GET_ASSET_XLSX_OR_HTML_FROM_FILE',
	GET_ASSET_CATEGORY_BY_ID = 'GET_ASSET_CATEGORY_BY_ID',
	DO_CHANGE_CATEGORY_ASSET = 'DO_CHANGE_CATEGORY_ASSET'
}