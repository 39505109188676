import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid, Typography, Link, Box } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { recordLoginRequest } from '@core/api/account/account.service';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import { selectSession, selectRequest, selectUser } from '@core/store/modules/session/sessionSelectors';
import { loginUser, setResetSelectRequest } from '@core/store/modules/session/sessionSlice';
import { useLanguage } from 'Context/LanguageContext';
import FielderButton from 'FielderComponents/FielderButton';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import { getLoginAccountRedirect, getErrorMessage } from '../LoginPage.utils';
import { MFA_REQUIRED, MFA_ENABLED } from '@core/constants/errorCodes.constants';
import LoginCard from './LoginCard';
import { FielderFormFieldLogin } from '~/FielderElements/FielderElements.component';
import { FIELDER } from '@core/constants/base.constants';
import Checkbox from '~/Checkbox/Checkbox.component';
import MicrosoftLogo from '@assets/icons/microsoft_logo.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { ENDPOINTS } from '../../../core/constants/pageEndpoints.constants';
import {
  getSelectedCognitoProvider,
  setCognitoUser,
  getCognitoUser,
  removeSelectedCognitoProvider,
  removeCognitoUser
} from 'FielderUtils/session/Session';

const Login = ({ setShowExternalMessage, setActivateMultiFactorAuthentication }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const session = useSelector(selectSession);
	const { isLoading, isSuccess, isError, error } = useSelector(selectRequest);
	const fielderMsg = useFielderSnackBar();
	const lan = useLanguage();
	const fielderId = FIELDER.idElement;
	const [mfaAuthentication, setMfaAuthentication] = useState(false);
	const [showMfaCodeInput, setShowMfaCodeInput] = useState(false);
	const [tokkenSession, setTokkenSession] = useState(null);
  const [signInWithCognito, setSignInWithCognito] = useState(false);
  const [cognitoProvider, setCognitoProvider] = useState(() => {
    const savedProvider = getSelectedCognitoProvider();
    return savedProvider ? JSON.parse(savedProvider) : null;
  });
  const [cognitoAuthentication, setCognitoAuthentication] = useState(false);
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		if (isSuccess && user && user.acceptPrivacyPolicy === true && user.showMfaAuthentication === false) {
			recordLoginRequest().then(r => console.log("recordLoginRequest"));
			const linkToRedirect = getLoginAccountRedirect(user, location);
			let welcomeMsg = `${lan.welcome} ${user.name || ''} ${user.lastName || ''}`;
			fielderMsg({
        message: welcomeMsg, variant: 'success', closeButton: true, duration: 6000
      });
			navigate(linkToRedirect);
		}
		if(isSuccess && user && user.showMfaAuthentication === true)
      setActivateMultiFactorAuthentication({showMfaAuthentication: true, session});
		if (isSuccess && user && user.acceptPrivacyPolicy === false && user.showMfaAuthentication === false)
      setShowExternalMessage({acceptPrivacyPolicy: true,session});
		if (isError) {
			if (error) {
				fielderMsg({
          message: getErrorMessage(error.status, lan), variant: 'error',	closeButton: true, duration: 6000
        });
				if(error.status === MFA_REQUIRED) {
					setTokkenSession(error.session);
					setShowMfaCodeInput(true);
				}
			}
			dispatch(setResetSelectRequest());
		}
	}, [isSuccess, isError, error]);
	useEffect(() => {
		if (isLoading) {
			dispatch(loadingStart({ name: 'LOGIN' }));
		} else dispatch(loadingStop('LOGIN'));
	}, [isLoading]);
  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      || (window.innerWidth <= 768);
    const params = new URLSearchParams(window.location.search);
    const logoutMobile = params.get("logout-mobile");
    const hasCognitoCode = params.get("code");
    if (isMobile && logoutMobile === "true" && (!hasCognitoCode || !cognitoAuthentication)) {
      setTimeout(() => {
        navigate(ENDPOINTS.logoutSSOMobile, { replace: true });
        window.location.href = 'com.smartservice.mx.fielder://oauth2redirect';
      }, 500);
    }
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authCode = params.get("code");
    if (authCode) {
      console.log("Authorization Code:", authCode);
      getCognitoToken(authCode).then(r => console.log("getCognitoToken"));
    }
  }, []);
  useEffect(() => {
    if (cognitoAuthentication) {
      const storedCognitoUser = getCognitoUser();
      if (storedCognitoUser) {
        const { ad_user } = JSON.parse(storedCognitoUser);
        handleCognitoLogin(ad_user).then(r => console.log("handleCognitoLogin"));
      }
    }
  }, [cognitoAuthentication]);
  useEffect(() => {
    if (cognitoProvider) {
      try {
        setSignInWithCognito(true);
      } catch (error) {
        console.error("Error setting cognitoProvider:", error);
        removeSelectedCognitoProvider();
      }
    }
  }, [cognitoProvider]);
	const handleLogin = async (data) => {
		const formData = { ...data, mfaAuthentication, session: tokkenSession };
		dispatch(loginUser(formData));
	};
  const handleLoginAD = async () => {
    console.log("Cognito Provider:", cognitoProvider);
    const domain = process.env.COGNITO_DOMAIN_NAME;
    const clientId = process.env.COGNITO_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.COGNITO_REDIRECT_SIGN_IN);
    const identityProvider = cognitoProvider.id;
    const scope = encodeURIComponent(process.env.COGNITO_SCOPE);
    if (!domain || !clientId || !redirectUri || !scope) {
      console.error("Missing environment variables!");
      return;
    }
    window.location.href=`${domain}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${redirectUri}&response_type=CODE&client_id=${clientId}&scope=${scope}`;
  };
  const getCognitoToken = async (authCode) => {
    const clientId = process.env.COGNITO_CLIENT_ID;
    const redirectUri = process.env.COGNITO_REDIRECT_SIGN_IN;
    const domain = process.env.COGNITO_DOMAIN_NAME;
    if (!clientId || !redirectUri || !domain) {
      console.error("Missing environment variables!");
      return;
    }
    try {
      const tokenUrl = `${domain}/oauth2/token`;
      const params = new URLSearchParams({
        grant_type: "authorization_code",
        client_id: clientId,
        redirect_uri: redirectUri,
        code: authCode,
      });
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      const response = await axios.post(tokenUrl, params, { headers });
      console.log("Cognito Token Response:", response.data);
      const { id_token, access_token, refresh_token } = response.data;
      const activeDirectoryUser = jwtDecode(id_token);
      if (!activeDirectoryUser) {
        console.error("Error decoding Azure AD User Info!");
        setCognitoAuthentication(false);
        return;
      }
      console.log("Azure AD User Info:", activeDirectoryUser);
      setCognitoAuthentication(true);
      setCognitoUser(JSON.stringify({
        id_token, ad_user: {
          preferred_username: activeDirectoryUser.preferred_username
        }
      }));
    } catch (error) {
      console.error("Error getCognitoToken:", error);
    }
  };
  const handleLogoutCognito = async () => {
    const domain = process.env.COGNITO_DOMAIN_NAME;
    const clientId = process.env.COGNITO_CLIENT_ID;
    const logoutRedirectUri = encodeURIComponent(process.env.COGNITO_REDIRECT_LOGOUT);
    if (!domain || !clientId || !logoutRedirectUri) {
      console.error("Missing environment variables for logout!");
      return;
    }
    try {
      removeCognitoUser();
      sessionStorage.clear();
      setCognitoAuthentication(false);
      window.location.href = `${domain}/logout?client_id=${clientId}&logout_uri=${logoutRedirectUri}`;
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  const handleCognitoLogin = async (adUserData) => {
    const data = {
      "username": "",
      "password": "",
      "adUserId": adUserData.preferred_username,
      "adProvider": cognitoProvider.id,
      "cognitoAuthentication": cognitoAuthentication,
    }
    const formData = { ...data, mfaAuthentication, session: tokkenSession };
    dispatch(loginUser(formData));
  };
	return (
		<LoginCard
      show={!isLoading}
      setSignInWithCognito={setSignInWithCognito}
      cognitoProvider={cognitoProvider}
      setCognitoProvider={setCognitoProvider}
    >
			<form onSubmit={handleSubmit(handleLogin)}>
				<Grid item xs={8} className='mx-auto'>
					<FielderFormFieldLogin
						name='username'
						label={lan.user}
						id={fielderId.txt.username}
						control={control}
						rules={{ required: `${lan.user} ${lan.isRequired}` }}
						error={errors.username}
						className='my-3'
					/>
					<FielderFormFieldLogin
						name='password'
						label={lan.password}
						id={fielderId.txt.password}
						type='password'
						control={control}
						rules={{
							required: `${lan.password} ${lan.isRequired}`,
							minLength: { value: 3, message: lan.minimunCharacters.replace('{0}', 3) },
						}}
						error={errors.password}
						className='my-3'
					/>
					{showMfaCodeInput && (
						<FielderFormFieldLogin
							name='mfaCode'
							label={lan.mfaCode}
							id={fielderId.txt.mfaCode}
							control={control}
							rules={{ required: `${lan.mfaCode} ${lan.isRequired}` }}
							error={errors.mfaCode}
							className='my-3'
						/>
					)}
				</Grid>
				<Grid item className="">
					<Row className="d-flex justify-content-center">
						<Checkbox
							label={lan.activateMultiFactorAuthentication}
							checked={mfaAuthentication}
							onChange={(e) => { setMfaAuthentication(!mfaAuthentication)}}
						/>
						<Link  
							className='mt-2 pt-1'
							href="https://www.appfielder.com/help-mfa/" 
      						underline="hover" 
      						target="_blank" 
     						rel="noopener noreferrer" 
						>
							<Col>
								<Box display="flex" alignItems="center" justifyContent="center">
									<Typography style={{fontSize:'12px', color: '#ED176B'}} align="center">{lan.moreInformation}</Typography>
									<OpenInNewIcon style={{  fontSize:'12px', color: '#ED176B', marginLeft: 4 }} />
								</Box>
							</Col>
						</Link>
					</Row>
				</Grid>
				<Grid item xs={8} className='mx-auto' sx={{ width: '50%', p: 2 }}>
					<FielderButton id='login' type='submit'>
						{lan.login_button}
					</FielderButton>
				</Grid>
        {signInWithCognito && (
          <Grid
            container
            spacing={cognitoAuthentication ? 2 : 0}
            justifyContent="center"
            alignItems="center"
            sx={{ width: cognitoAuthentication ? '65%' : '50%', p: 2, mx: "auto" }}>
            <Grid item xs={cognitoAuthentication ? 9 : 12} sm={cognitoAuthentication ? 8 : 12}>
              <Button
                id="cognito-ad"
                onClick={handleLoginAD}
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                <Box
                  component="img"
                  src={MicrosoftLogo}
                  alt="Microsoft Logo"
                  sx={{ height: 20, width: 20, marginRight: '7%', marginLeft: 0 }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000',
                    flexGrow: 1,
                    textAlign: 'center',
                    textTransform: 'none'
                  }}
                >
                  {lan.Login_cognito}{cognitoProvider.name ? ` ${cognitoProvider.name}` : ''}
                </Typography>
              </Button>
            </Grid>
            {cognitoAuthentication && (
              <Grid item xs={3} sm={2}>
                <IconButton onClick={handleLogoutCognito}>
                  <RefreshOutlinedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
			</form>
		</LoginCard>
	);
};

export default Login;
