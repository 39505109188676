import API_LINKS from 'FielderAPILinks';
import { REQUEST_TYPES } from '@core/constants/branch/request.constants';
/** BRANCHES * */
export const getBranchesByFilter = (filter, idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_ALL_BRANCHES_BY_FILTER,
		idUser,
		idAccount,
		filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.branches;
			}
			console.error(res);
			return [];
		});
};

export const formarFilterBranches = (branches, onlyId) => {
	const branchesList = [];
	branches.forEach((branch) => {
		let companyName = '';
		let idCompany = '';
		let idCustom = '';

		if (branch.company && branch.company.idCustom) {
			idCompany = `[${branch.company.idCustom}] - `;
		}
		if (branch.company && branch.company.name) {
			companyName = branch.company.name;
		}

		idCustom = branch.idCustom ? `${branch.idCustom} ` : ' ';
		branchesList.push({
			label: `[${branch.id}] ${idCustom} - ${branch.name} (${idCompany}${companyName})`,
			value: branch,
		});
	});
	return branchesList;
};

export const getBranchesByFilterAsyncSelect = async (filter, idAccount, user, idToken) => {
	const branches = await getBranchesByFilter(filter, idAccount, user.id, idToken);
	return formarFilterBranches(branches);
};

/** BRANCHES PARTNER  * */

export const getBranchesPartnerByFilter = (filter, idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_BRANCHES_BY_FILTER,
		idUser,
		idAccount,
		filter,
	};

	return fetch(API_LINKS.apiUserManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success == true) {
				return res.branches;
			}
			console.error(res);
			return [];
		});
};

export const formatBranches = (branches, onlyId) =>
	branches.map((branch) => {
		let companyName = '';
		let idCompany = '';
		let branchIdCustom = '';

		if (branch.company && branch.company.idCustom) {
			idCompany = branch.company.idCustom;
		}

		if (branch.company && branch.company.name) {
			companyName = branch.company.name;
		}

		branchIdCustom = branch.idCustom ? `${branch.idCustom} ` : '';

		const label = `[${branch.id}] ${branchIdCustom}- ${branch.name} ([${idCompany}] - ${companyName})`;

		if (onlyId) {
			return {
				label,
				value: branch.id,
			};
		}

		return {
			label,
			value: branch,
		};
	});

export const getBranchesPartnerByFilterAsyncSelect = async (filter, idAccount, user, idToken) => {
	const branches = await getBranchesPartnerByFilter(filter, idAccount, user.id, idToken);
	return formatBranches(branches);
};

/** GET BRANCHES WITH COMPANIES NOT DISABLED  * */

export const getBranchesByFilterCreateTask = (filter, idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_ALL_BRANCHES_BY_FILTER_SELECT,
		idUser,
		idAccount,
		filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.branches;
			}
			console.error(res);
			return [];
		});
};

export const getBranchesByFilterAsyncSelectCreateTask = async (filter, idAccount, user, idToken) => {
	const branches = await getBranchesByFilterCreateTask(filter, idAccount, user.id, idToken);
	return formarFilterBranches(branches);
};


export const getBranchesByFilterAndIdCompanyCreateTask = (filter, idCompany,idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_BRANCHES_BY_ID_COMPANY_AND_FILTER,
		idUser,
		idAccount,
		filter,
		idCompany
	};

	return fetch(API_LINKS.apiUserManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.branches;
			}
			console.error(res);
			return [];
		});
};


export const getBranchesByIdCompanyAndFilterAsyncSelectCreateTask = async (filter, idCompany, idAccount, idUser, idToken) => {
	const branches = await getBranchesByFilterAndIdCompanyCreateTask(filter,idCompany ,idAccount, idUser, idToken);
	return formarFilterBranches(branches);
};

/** ********************  DEFATULT OPTIONS  *************** */

export const getBranchesInitialOptions = (idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_BRANCHES_DEFAULT_OPTIONS,
		idUser,
		idAccount,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.branches;
			}
			console.error(res);
			return [];
		});
};

export const getBranchesInitialOptionsAsyncSelect = async (idAccount, user, idToken) => {
	const branches = await getBranchesInitialOptions(idAccount, user.id, idToken);
	return formarFilterBranches(branches);
};
