import API_LINKS from 'FielderAPILinks';

/**
 *
 * @param {string} filter       - Filter to search the partners by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the partners found (returns empty if error)
 */
export const getPartnersByFilter = (filter, idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_PARTNERS_BY_FILTER',
		idUser: idUser,
		idAccount: idAccount,
		filter: filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res && res.success === true) {
				return res.partners;
			} else {
				console.error(res);
				return [];
			}
		});
};

export const getPartnersByFilterOptimize = (filter, idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_PARTNERS_BY_FILTER_OPTIMIZE',
		idUser: idUser,
		idAccount: idAccount,
		filter: filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res && res.success === true) {
				return res.partners;
			} else {
				console.error(res);
				return [];
			}
		});
};



/**
 *
 * @param {string} filter       - Filter to search the partner by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all partners by the filter send and sets the information and format for the asyncSelect component
 */
export const getPartnersByFilterAsyncSelect = async (filter, idAccount, idUser, idToken) => {
	let partners = await getPartnersByFilter(filter, idAccount, idUser, idToken);
	return formatPartner(partners);
};

export const formatPartner = (partners) => {
	let partnersList = [];

	partners.forEach((partner) => {
		if (!partner.account.name) {
			partnersList.push({
				label: `[${partner.account.id}] - ${partner.account.user?.name} ${partner.account.user?.lastName}`,
				value: partner.account.id,
			});
		} else {
			partnersList.push({
				label: `[${partner.account.id}] - ${partner.account.name} (${partner.account.user?.name} ${partner.account.user?.lastName})`,
				value: partner.account.id,
			});
		}
	});

	return partnersList;
};

export const getPartnersByFilterAsyncSelectOptimize = async (filter, idAccount, idUser, idToken) => {
	let partners = await getPartnersByFilterOptimize(filter, idAccount, idUser, idToken);
	return formatPartner(partners);
}

/**
 *
 * @param {string} filter       - Filter to search the partners by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the partners found (returns empty if error)
 */
export const getIntegratedPartnersByFilter = (filter, idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_INTEGRATED_PARTNERS_BY_FILTER',
		idUser: idUser,
		idAccount: idAccount,
		filter: filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res && res.success === true) {
				return res.partners;
			} else {
				console.error(res);
				return [];
			}
		});
};

/**
 *
 * @param {string} filter       - Filter to search the partner by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all partners by the filter send and sets the information and format for the asyncSelect component
 */
export const getAllPartnersByFilterAsyncSelect = async (
	filter,
	idAccount,
	idUser,
	idToken,
	lan
) => {
	let partnersIntegrated = await getIntegratedPartnersByFilter(filter, idAccount, idUser, idToken);
	let partners = await getPartnersByFilter(filter, idAccount, idUser, idToken);

	let partnersList = [];

	partners.forEach((partner) => {
		if (!partner.account.name) {
			partnersList.push({
				label:
					partner.account.user.name +
					' ' +
					partner.account.user.lastName +
					' *' +
					lan.integratedByMe,
				value: partner.account.id,
				integratedByMe: true,
			});
		} else {
			partnersList.push({
				label:
					partner.account.name +
					' (' +
					partner.account.user.name +
					' ' +
					partner.account.user.lastName +
					') *' +
					lan.integratedByMe,
				value: partner.account.id,
				integratedByMe: true,
			});
		}
	});

	partnersIntegrated.forEach((partner) => {
		if (!partner.account.name) {
			partnersList.push({
				label: partner.account.user.name + ' ' + partner.account.user.lastName,
				value: partner.account.id,
				integratedByMe: false,
			});
		} else {
			partnersList.push({
				label:
					partner.account.name +
					' (' +
					partner.account.user.name +
					' ' +
					partner.account.user.lastName +
					')',
				value: partner.account.id,
				integratedByMe: false,
			});
		}
	});

	return partnersList;
};

/**********************  DEFATULT OPTIONS  ****************/
export const getPartnerInitialOptionsAsyncSelect = async (idAccount, idUser, idToken) => {
	let partners = await getPartnersInitialOptions(idAccount, idUser, idToken);
	return formatPartner(partners);
};

export const getPartnersInitialOptions = (idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_PARTNERS_DEFAULT_OPTIONS',
		idUser: idUser,
		idAccount: idAccount,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res && res.success === true) {
				return res.partners;
			} else {
				console.error(res);
				return [];
			}
		});
};
