import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import {
    NotifyContainerGridStyled,
    NotifyContainerEmptyBoxStyled,
    NotifyContainerTypoSubTitleStyled, } from '../StyleComponents';
import { AnimationLottie } from '../../../AnimationLottie/index';

import NotificationItem from './NotificationItem';
import { Typography } from '@mui/material';

import { getNotificationsRequest } from '@core/api/notification/notification.service';
import FielderConst from 'FielderUtils/fielderConst';
import request from 'FielderRequestTypes';

const getMomentLang = (lan) => {
	switch (lan) {
		case 'es_MX':
			return 'es';
		case 'pt_BR':
			return 'pt-br';
		default:
			return 'en';
	}
};

const NotificationContainer = ({
    lan,
    totalNotifications: initialTotalNotifications,
    jsonS: initialJsonS,
    setIdTaskDetail,
    setShowDetailTask,
    setNotificationOpen
}) => {
		const momentLocale = getMomentLang(lan._language);
		moment.locale(momentLocale);
    const [data, setData] = useState([]);
    const [hasMoreNotifications, setHasMoreNotifications] = useState(true);
    const [jsonS, setJsonS] = useState(initialJsonS);
    const [obtainedNotifications, setObtainedNotifications] = useState(0);
    const [totalNotifications, setTotalNotifications] = useState(
        initialTotalNotifications === 0 && initialJsonS.length !== 0 ? 300 : initialTotalNotifications);

    useEffect(() => {
        buildNotification(jsonS);
    }, [jsonS]);

    const buildTask = (item, lan) => {
        const getTypeDescription = (i, lan) => lan[`notificationType${i.type}`];
        const getPartnerName = (i) => i.partnerAccount?.name || i.partnerAccount?.contactName || '';
        const getPartnerAccountName = (i) => i.partnerAccount?.user ? `${i.partnerAccount.user.name} ${i.partnerAccount.user.lastName}` : '';
        const getTechnicianName = (i) => i.agent ? `${i.agent.name} ${i.agent.lastName}` : '';
        const getImgTechnician = (i) => i.imgTechnician?.scalePath || '';
        const getClientUserName = (i) => i.clientUser ? `${i.clientUser.name} ${i.clientUser.lastName}` : '';
        const getImgClientUser = (i) => i.clientUser?.profileImage?.scalePath || '';

        const type = parseInt(item.type);
        if (!FielderConst.ACTIVITY_CODES.includes(type)) return null;
    
        return {
            idNotification: item.idNotification,
            read: item.shown,
            idTask: item.objectId || item.task.idTask || '',
            description: getTypeDescription(item, lan),
            partner: {
                name: getPartnerName(item),
                userName: getPartnerAccountName(item)
            },
            technician: {
                name: getTechnicianName(item),
                image: getImgTechnician(item)
            },
            clientUser: {
                name: getClientUserName(item),
                image: getImgClientUser(item)
            }
        };
    };

    const buildNotificationItem = (item, task, lan, setIdTaskDetail, setShowDetailTask, setNotificationOpen) => {
        const key = `${item.task}${item.objectId}`;
        return <NotificationItem
            key={key}
            type={item.type}
            folio={item.task ? item.task.folio : ''}
            id={task.idTask}
            task={task}
            time={moment(item.date, 'x').format('YYYY/MM/DD hh:mm a')}
            setIdTaskDetail={setIdTaskDetail}
            setShowDetailTask={setShowDetailTask}
            setNotificationOpen={setNotificationOpen}
            lan={lan}
        />;
    };

    const buildNotification = useCallback((jsonS) => {
        if (!Array.isArray(jsonS) || jsonS.length === 0) {
            setData(<NotifyContainerEmptyBoxStyled>
                <Typography variant="h6" gutterBottom>{lan.notificationWhitout}</Typography>
            </NotifyContainerEmptyBoxStyled>);
            setHasMoreNotifications(false);
            return;
        }

        const recentNotifications = [];
        const olderNotifications = [];
        const sevenDaysAgo = moment().subtract(1, 'days');

        jsonS.forEach((item) => {
            const task = buildTask(item, lan);
            if (task) {
                const notification = buildNotificationItem(item, task, lan, setIdTaskDetail, setShowDetailTask, setNotificationOpen);
                if (moment(item.date, 'x').isAfter(sevenDaysAgo)) {
                    recentNotifications.push(notification);
                } else {
                    olderNotifications.push(notification);
                }
            }
        });

        setData([
            <NotifyContainerTypoSubTitleStyled key="recentHeader">{lan.recentNotifications}</NotifyContainerTypoSubTitleStyled>,
            ...recentNotifications,
            <NotifyContainerTypoSubTitleStyled key="olderHeader">{lan.olderNotifications}</NotifyContainerTypoSubTitleStyled>,
            ...olderNotifications
        ]);
    }, [lan, setIdTaskDetail, setShowDetailTask, setNotificationOpen]);

    const updateItemsNotifications = () => {
        if (totalNotifications >= obtainedNotifications) {
            const body = request.getDataToGetNotification(obtainedNotifications + 10, 10);
            getNotificationsRequest(body, (res) => {
                const newJsonS = [...jsonS, ...res.notification];
                const newTotalNotifications = initialTotalNotifications === 0 && res.notification.length !== 0 ? 300 : initialTotalNotifications;
                setJsonS(newJsonS);
                setHasMoreNotifications(true);
                setObtainedNotifications(prev => prev + 10);
                setTotalNotifications(newTotalNotifications);
            });
        } else {
            setHasMoreNotifications(false);
            setTotalNotifications(300);
        }
    };

    return (
        <NotifyContainerGridStyled>
            <InfiniteScroll
                pageStart={0}
                loadMore={updateItemsNotifications}
                hasMore={hasMoreNotifications}
                useWindow={false}
                loader={<AnimationLottie type="loader" height={'10%'} width={'10%'} key={0} />}
            >
                {data}
            </InfiniteScroll>
        </NotifyContainerGridStyled>
    );
}

export default NotificationContainer;
