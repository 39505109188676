import React, { useState, useEffect, useReducer } from 'react';

import AutoAssignTaskStatus from 'OldComponents/Status/AutoAssignTaskStatus';
import { Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import MainTable from 'OldComponents/MainTable';
import AutoAssignDetail from '~/AutoAssignDetail/AutoAssignDetail.component';

import 'FielderUtils/style.css';
import { getAutoAssignTasksRequest } from '@core/api/autoAssign/autoAssign.service';

let request = require('FielderRequestTypes');

const LAST_NUM = 920000000000000000;
const tableCookie = {
	tableCookieName: 'autoAssignTable',
	colOrderCookieName: 'autoAssignTableColOrder',
	cookie: new Cookies(),
};

export default function AutoassignTab({ lan, ...props }) {
	const [lastTask, setLastTask] = useState(LAST_NUM);
	const [numberOfElements, setNumberOfElements] = useState(10);
	const [taskTypes, setTaskTypes] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [detail, setDetail] = useState(null);

	const tooltips = null;

	const getDetaultNameOrNormal = (id, name) => {
		if (id == 1 || id == 2 || id == 3) {
			return lan['severity_' + id];
		} else {
			return name;
		}
	}

	const rowsReducer = (state, processTasks) => {
		const newRows = [];
		if (processTasks) {
			processTasks.forEach((task) => {
				const taskType = taskTypes.find((type) => type.id == task.taskType);
				const status = <AutoAssignTaskStatus value={task.status} />;
				let responseTime;
				let origin;
				let pri;
				if (task.priority === 1) {
					pri = task.templateServiceLevel.high;
				} else if (task.priority === 2) {
					pri = task.templateServiceLevel.med;
				} else {
					pri = task.templateServiceLevel.low;
				}

				if (pri.immediateResponse) {
					responseTime = lan.immediateResponse;
				} else {
					const hours = Math.floor(pri.time / 3600);
					const minutes = Math.floor((pri.time - hours * 3600) / 60);

					responseTime = `${hours} hrs ${minutes} min`;
				}

        if (task.branch && task.branch.name) {
          origin = task.branch.name;
      } else if (task.client) {
          const clientName = task.client.name || '';
          const clientLastName = task.client.lastName || '';
          origin = `${clientName} ${clientLastName}`.trim();
          if (!origin) {
              origin = 'No client information available';
          }
      } else {
          origin = 'Unknown origin';
      }

				newRows.push({
					idTask: task.taskId,
					idCustom: task.idCustom,
					folio: task.folio ? task.folio : '',
					origin,
					template: task.templateName.charAt(0).toUpperCase() + task.templateName.substring(1),
					status,
					taskType: taskType.name,
					priority: getDetaultNameOrNormal(task.priority,task.priorityName ),
					responseTime,
					templateStatus: task.templateStatus
				});
			});

			setLastTask(newRows[newRows.length - 1].idTask);
		}

		if (processTasks.length < numberOfElements) {
			setHasMore(false);
		}

		return [...state].concat(newRows);
	};

	const [rows, setRows] = useReducer(rowsReducer, []);
	const [columns, setColumns] = useState([
		{ id: 'idTask', padding: 'none' },
		{ id: 'idCustom', padding: 'none' },
		{ id: 'folio', padding: 'none' },
		{ id: 'origin', name: `${lan.company}/${lan.client}`, padding: 'default' },
		{ id: 'template', name: 'templateName', padding: 'default' },
		{ id: 'status', padding: 'default', noOrdering: true },
		{ id: 'taskType', padding: 'default' },
		{ id: 'priority', padding: 'none' },
		{ id: 'responseTime', padding: 'default' },
	]);

	useEffect(() => loadMore(), []);

	const loadMore = () => {
		const body = {
			taskId: lastTask,
			numberOfElements: numberOfElements,
		};

		const onSuccess = (res) => {
			if (res.processTasks != null && res.processTasks.length > 0) {
				setTaskTypes(res.taskTypes);
				setRows(res.processTasks);
			} else {
				setHasMore(false);
			}
		}

		getAutoAssignTasksRequest(body, onSuccess)
		return rows;
	};

	return (
		<Container fluid>
			{detail && (
				<AutoAssignDetail idTask={detail} setDetail={setDetail} request={request} taskTypes={taskTypes} {...props} />
			)}

			<MainTable
				InfiniteScroll
				loadMore={loadMore}
				hasMore={hasMore}
				reorderCols={setColumns}
				cols={columns}
				rows={rows}
				onRowClick={(e) => setDetail(e.idTask)}
				tooltips={tooltips}
				cookie={tableCookie}
				height={500}
				{...props}
			/>
		</Container>
	);
}
