import React, { Component } from 'react';

import { sanitizeInput } from '@core/utils/dompurify/dompurify.js';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { Badge, Button, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FielderTab, FielderTabs } from '~/Tabs/Tabs.style';
import TodayIcon from '@mui/icons-material/Today';
import { TimePicker } from 'antd';
import FielderButton from 'FielderComponents/FielderButton';
import { getBranchLogsType } from 'FielderUtils/branch/branchLogs';

import {
	getCompaniesByFilterAsyncSelectCreateTask,
	getCompaniesInitialOptionsAsyncSelect,
} from 'FielderUtils/company/company';
import branchConst from 'FielderUtils/roleManagementConst/branchConst';
import { GOOGLE_API_KEY } from 'FielderUtils/session/Session';
import MainTable from 'OldComponents/MainTable';

import TextareaAutosize from 'react-autosize-textarea';
import { DateRange } from 'react-date-range';
import Geocode from 'react-geocode';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { Row, Col, Container } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import { isRoleValid } from '@core/utils/roles/validateRoles';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import BranchLog from './BranchLog';
import Location from '~/Location/Location';
import Map from '~/Map/Map.component';
import './AddBranchOffice.css';
import 'FielderUtils/style.css';
import '../Register/RegisterComponentStyles.css';
import './DateTime.css';
import {
	updateBranchRequest,
	getBranchInfoRequest,
	getBranchLogsRequest,
	updateBranchLocation,
} from '@core/api/branch/branch.service';
import model from 'Object/model/model.js';
import { LightGreySubtitleColor } from '@core/theme/baseTheme';
import MoreBranchInformation from './MoreBranchInformation';

const { RangePicker } = TimePicker;
var typeRequest = require('FielderRequestTypes');
const moment = require('moment');
Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();
const UPDATE_LOCATION_TAB = 0;
const BRANCH_INFO_TAB = 1;
const BRANCH_LOGS_TAB = 2;
const tableCookie = {
	tableCookieName: 'branchLogsTable',
	cookie: new Cookies(),
};
let lastItem = 920000000000000000;
const EditButton = styled(Button)`
	color: ${LightGreySubtitleColor};
	font-family: 'Lato';
`
class DetailEditBranchOffice extends Component {
	constructor(props) {
		super(props);
		this.moreBranchInformationRef = React.createRef();
		this.state = {
			branch: {
				idBranch: '',
				idCustom: '',
				name: '',
				company: null,
				legalAddress: '',
				lat: 0,
				lng: 0,
				newDirection: '',
				timezone: null,
				nameMoreInfo:'',
				lastNameMoreInfo:'',
				emailMoreInfo:'', 
				cellphoneMoreInfo:'',
				officePhoneMoreInfo:'', 
				commentMoreInfo:''
			},
			idLocationUpdate: 0,
			attentionTime: {
				from: '',
				to: '',
				formatFrom: '',
				formatTo: '',
			},
			errors: {
				name: false,
				location: false,
				company: false,
			},
			logs: {
				cols: [
					{
						id: 'date',
						padding: '',
					},
					{ id: 'idUser', padding: '' },
					{ id: 'name', padding: '' },
					{ id: 'idAgent', padding: '' },
					{ id: 'agent', padding: '' },
					{ id: 'logAction', name: 'type', padding: '' },
				],
				rows: [],
				complete: [],
				hasMore: true,
				maxItems: 10,
				lastItem: 920000000000000000,
				activeLog: null,
				pastLog: null,
				activeLogIndex: null,
			},
			filterMenu: null,
			filterRange: {},
			optionsSelect: [],
			tab: props.branchesToShow.locationUpdate ? UPDATE_LOCATION_TAB : BRANCH_INFO_TAB,
			editData: false,
			editButton: false,
			idLocation: 0,
			selectedOption: null,
			changedMapPosition: true,
			showDates: false,
			selectedCompany: [],

			idAccount: props.session.user ? props.session.user.idAccount : 0,
			user: props.session.user ? props.session.user : null,
			idUser: props.session.user ? props.session.user.idUser : 0,
			canEditBranch: false,
			showMoreBranchInfo: false,
			canEditBranchPartner: false,
			idAccountBranch: 0
		};
	}
	componentDidMount = () => {
		lastItem = 920000000000000000;

		this.getLogs();
		const loadingConfig = { dispatch: this.props.dispatch };

		const body = { idBranch: this.props.branchesToShow.id, idAccountBranch: this.props.idAccountBranch };

		getBranchInfoRequest(body, this.manageGetBranchInfoRequest, null, loadingConfig);

		const state = this.state;
		state.canEditBranch = isRoleValid(branchConst.BRANCHES, branchConst.EDIT, this.props.session.roles);

		this.setState(state);
	};


	validatePartnerCanEditBranch = (idAccountBranch, editByPartner) => 
		this.props.session.account.id === idAccountBranch || editByPartner;



	manageGetBranchInfoRequest = (response) => {
		const branch = response.branch;
		const editByPartner = response.editByPartner;

		let idLocation = branch.location ? branch.location.idLocation : null;
		let lat = branch.location ? branch.location.latitude : 0;
		let lng = branch.location ? branch.location.longitud : 0;
		let address = branch.location ? branch.location.address : null;
		let attentionTimeFrom = branch.attentionTimeFrom != null && branch.attentionTimeFrom !== '' ?  moment(branch.attentionTimeFrom, 'h:mm a') :'' ;
		let formatAttentionTimeFrom = branch.attentionTimeFrom != null && branch.attentionTimeFrom !== '' ? moment(branch.attentionTimeFrom, 'hh:mm a') : '';
		let attentionTimeTo = branch.attentionTimeTo !== null && branch.attentionTimeTo !== '' ? moment(branch.attentionTimeTo, 'h:mm a') : '';
		let formatAttentionTimeTo = branch.attentionTimeTo !== null && branch.attentionTimeTo !== '' ? moment(branch.attentionTimeTo, 'hh:mm a') : '';

		let selectedCompany = {};
		if (branch.company) {
			const { id, name, idCustom } = branch.company;
			const label = `${id} ${name}${idCustom ? ` (${idCustom})` : ''}`;
			selectedCompany = { label, value: branch.company };
		}

		this.setState({
			branch: {
				idBranch: branch.id ? branch.id : 0,
				idCustom: branch.idCustom ? branch.idCustom : '',
				name: branch.name ? branch.name : '',
				company: branch.company,
				legalAddress: branch.legalAddress ? branch.legalAddress : '',
				lat: lat,
				lng: lng,
				newDirection: address,
				nameMoreInfo: branch.nameMoreInfo,
				lastNameMoreInfo:branch.lastNameMoreInfo,
				emailMoreInfo:branch.emailMoreInfo,
				cellphoneMoreInfo:branch.cellphoneMoreInfo,
				officePhoneMoreInfo:branch.officePhoneMoreInfo,
				commentMoreInfo: branch.commentMoreInfo,
				idAccount: branch.idAccount,
			},
			attentionTime: {
				from: attentionTimeFrom,
				to: attentionTimeTo,
				formatFrom: formatAttentionTimeFrom,
				formatTo: formatAttentionTimeTo,
			},
			selectedCompany: selectedCompany,
			idLocation: idLocation,
			idLocationUpdate: idLocation,
			showDates: true,
			idAccountBranch: branch.idAccount,
			canEditBranchPartner: this.validatePartnerCanEditBranch(branch.idAccount, editByPartner)

		}, () => { console.log(this.state.branch)});
	
	};
	getLogs = () => {
		const body = {
			maxNumber: this.state.logs.maxItems,
			lastDate: lastItem,
			idBranch: this.props.branchesToShow.id,
			branch: {},
		};

		lastItem--;

		const onSuccess = ({ branchLogs }) => this.formatRows(branchLogs);
		const loadingConfig = { dispatch: this.props.dispatch };
		getBranchLogsRequest(body, onSuccess, console.error, loadingConfig);
	};
	formatRows = (branchLogs, cleanRows) => {
		let state = this.state;
		if (!branchLogs || branchLogs.length == 0) {
			state.logs.hasMore = false;
			this.setState(state);
			return;
		}

		if (cleanRows) {
			state.logs.rows = [];
			state.logs.complete = [];
		}

		const rows = [...state.logs.rows];
		state.logs.complete = state.logs.complete.concat(branchLogs);

		if (branchLogs.length < state.logs.maxItems) {
			state.logs.hasMore = false;
		}
	

		branchLogs.forEach(({ user, agent, date, logType, branch }, i) => {
			let userName = '';
			let userId = '';
			let agentName = '';
			let agentId = '';

			if (user) {
				userName = `${user.name} ${user.lastName}`;
				userId = user.idUser;
			} else if (agent) {
				agentId = agent.idAgent;
				agentName = `${agent.name} ${agent.lastName}`;
			}
			rows.push({
				date: moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss'),
				idUser: userId,
				name: userName,
				idAgent: agentId,
				branch,
				agent: agentName,
				logAction: getBranchLogsType(logType, this.props.lan),
				index: state.logs.rows.length + i,
			});

			if (i == branchLogs.length - 1) {
				lastItem = date;
			}
		});

		state.logs.rows = rows;
		this.setState(state);
	};
	getCompanyList = async (event) => {
		if (event.length > 0) {
			return await getCompaniesByFilterAsyncSelectCreateTask(event);
		}
	};
	getInitialOptionsCompany = async () => {
		return await getCompaniesInitialOptionsAsyncSelect();
	};
	manageGetCompaniesRequest = (res) => {
		let companiesListParse = res;
		let companiesList = companiesListParse.companies;
		let companiesOptionSelected = [];

		for (let i = 0; i < companiesList.length; i++) {
			companiesOptionSelected.push({
				value: companiesList[i],
				label: companiesList[i].name + ' ' + companiesList[i].businessName,
			});
		}

		this.setState({ optionsSelect: companiesOptionSelected });
	};
	handleOptionSelected = (selectedOption) => {
		let state = this.state;
		state.branch.company = selectedOption.value;
		this.setState(state);
	};
	/**
	 * @desc This function receives two strings and changes
	 * the state of the userInfo object depending on
	 * the second parameter that it receives
	 *
	 * @param {string} value      - value string param
	 * @param {string} field - field string param
	 *
	 *
	 **/
	setFieldValue = (value, field) => {
		const sanitizedInput = sanitizeInput(value);

		let state = this.state;
		if (field === 'idCustom') {
			state.branch.idCustom = sanitizedInput;
			this.setState(state);
		}

		if (field === 'name') {
			state.branch.name = sanitizedInput;
			this.setState(state);
		}
	};
	validateName = () => {
		let state = this.state;
		state.errors.name = state.branch.name === '';
		this.setState(state);
		return !state.errors.name;
	};
	validateLocation = () => {
		let state = this.state;
		let branch = state.branch;
		let isValid = branch.lat !== 0 && branch.lng !== 0 && branch.newDirection !== '';
		state.errors.location = !isValid;
		this.setState(state);
		return isValid;
	};
	validateCompany = (id) => {
		let state = this.state;
		state.errors.company = id === null;
		this.setState(state);
		return !state.errors.company;
	};
	manageGetUpdateBranchRequest = (response) => {

		if(response && response.branch && this.props.setBranchInfo){
			this.props.setBranchInfo(response.branch);
		}
		
		setTimeout(() => {
			this.handleCloseModal();
		}, 500);
	};
	handleDoActionUpdateBranch = (e) => {
		const isValidName = this.validateName(this.state.branch.name);
		const isValidLocation = this.validateLocation();
		const isValidIdCompany = this.validateCompany(this.state.branch.company.id);

		if (isValidName && isValidLocation && isValidIdCompany && !this.state.activateAlert) {
			let attentionTimeFrom = this.state.attentionTime.from
				? moment(this.state.attentionTime.from, 'hh:mm a').format('HH:mm')
				: '';
			let attentionTimeTo = this.state.attentionTime.to
				? moment(this.state.attentionTime.to, 'hh:mm a').format('HH:mm')
				: '';

			const branch = this.state.branch;
			let branchInfoPass = {
				idBranch: this.props.branchesToShow.id,
				idAccount: branch.idAccount,
				idLocation: this.state.idLocation,
				idCustom: branch.idCustom,
				name: branch.name,
				parentCompanyId: branch.company.id,
				state: branch.state,
				country: branch.country,
				postalCode: branch.postalCode,
				timeZone: branch.timezone,
				address: branch.newDirection,
				latitude: branch.lat,
				longitud: branch.lng,
				legalAddress:  branch.legalAddress != null && branch.legalAddress !='' ? branch.legalAddress : null ,
				attentionTimeFrom: attentionTimeFrom,
				attentionTimeTo: attentionTimeTo,
				nameMoreInfo: this.moreBranchInformationRef.current.name,
				lastNameMoreInfo: this.moreBranchInformationRef.current.lastName,
				emailMoreInfo: this.moreBranchInformationRef.current.email,
				cellphoneMoreInfo: this.moreBranchInformationRef.current.cellphone,
				officePhoneMoreInfo: this.moreBranchInformationRef.current.officePhone,
				commentMoreInfo: this.moreBranchInformationRef.current.comment,
				
			};

			let requestData = typeRequest.getRequestDoActionUpdateBranch();
			let newCompanyInfo = model.doActionCreateBranch(branchInfoPass);
			let body = Object.assign(requestData.data, newCompanyInfo.data);

			const onSuccess = (response) => {
				this.manageGetUpdateBranchRequest(response);
			};

			const loadingConfig = { dispatch: this.props.dispatch };
			updateBranchRequest(body, onSuccess, null, loadingConfig);
		}
	};
	handleDoActionUpdateLocation = (isDeleteLocation) => {
		const body = {
			idBranch: this.props.branchesToShow.id,
			deleteLocationUpdate: isDeleteLocation,
			branch: {
				location: { idLocation: this.state.idLocationUpdate },
			},
			locationUpdate: {
				latitude: this.props.branchesToShow.locationUpdate.latitude,
				longitude: this.props.branchesToShow.locationUpdate.longitude,
				address: this.props.branchesToShow.locationUpdate.address,
				timeZone: this.props.branchesToShow.locationUpdate.timeZone,
			},
		};

		const onSuccess = this.manageGetUpdateBranchRequest();
		const onError = console.error;
		const loadingConfig = { dispatch: this.props.dispatch };
		updateBranchLocation(body, onSuccess, onError, loadingConfig);
	};
	handleEdit = () =>
		this.setState({
			editData: !this.state.editData,
			editButton: !this.state.editButton,
	});
	handleClose = () => this.props.setDefaultFalse();
	handleCloseModal = () => this.props.setDefaultFalseModal();
	changeWorkingHours = (newWorkingHours) => {
		let state = this.state;
		state.attentionTime.from = newWorkingHours[0];
		state.attentionTime.to = newWorkingHours[1];
		state.activateAlert = false;
		this.setState(state);
	};
	updateLegalAddress = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.branch.legalAddress = sanitizedInput;
		this.setState(state);
	};
	LocationUpdate = () => {
		return (
			<React.Fragment>
				<Row className='my-3'>
					<Col sm={4} className='ml-4'>
						{this.props.lan.newLocationAvailable}
					</Col>

					<Col sm={7} className='branch-newLocation'>
						{this.props.branchesToShow.locationUpdate.address}
					</Col>
				</Row>

				<Col sm={12} style={{ minHeight: '200px', maxHeight: '400px', height: '300px' }}>
					<Map
						marker
						zoom={15}
						center={{
							lat: parseFloat(this.props.branchesToShow.locationUpdate.latitude),
							lng: parseFloat(this.props.branchesToShow.locationUpdate.longitude),
						}}
					/>
				</Col>

				<Row className='my-4 justify-content-center'>
					<Col sm={6} className='text-center'>
						<FielderButton size='medium' onClick={() => this.handleDoActionUpdateLocation(false)}>
							{this.props.lan.cancel}
						</FielderButton>
					</Col>
					<Col sm={6} className='text-center'>
						<FielderButton outlined size='medium' onClick={() => this.handleDoActionUpdateLocation(true)}>
							{this.props.lan.update}
						</FielderButton>
					</Col>
				</Row>
			</React.Fragment>
		);
	};
	handleFilterLogsMenu = (e) => {
		let state = this.state;
		state.filterMenu = e;
		this.setState(state);
	};
	updateLocation = (newLocation, coordinates) => {
		const branch = this.state.branch;
		if (coordinates.lat != branch.lat && coordinates.lng != branch.lng) {
			branch.lat = coordinates.lat;
			branch.lng = coordinates.lng;
			branch.newDirection = newLocation.address;
			branch.postalCode = newLocation.postalCode;
			branch.state = newLocation.state;
			branch.timezone = newLocation.timezone;
			branch.country = newLocation.country;

			let state = this.state;
			state.branch = branch;
			this.setState(state);
		}
	};

	openMoreBranchInfo = () => {
		this.setState({ showMoreBranchInfo: !this.state.showMoreBranchInfo });
	}


	BranchDetail = () => {
		return (
			
			<React.Fragment>


				{this.state.canEditBranch && this.state.canEditBranchPartner && (
					<Col sm='auto' className='ml-auto mt-2 mb-3'>
						<EditButton
							id='editBranch-button'
							onClick={this.handleEdit}
							startIcon={<EditIcon />}
						>
							{this.props.lan.editBranch}
						</EditButton>
					</Col>
				)}
				<Col sm={12} className='branch-label-input'>
					{this.props.lan.idBranch}
				</Col>
				<Col sm={12} className='mb-2'>
					<TextareaAutosize
						type='text'
						className={'task-details-input'}
						value={this.state.branch.idBranch}
						style={{
							width: '100%',
							paddingTop: '5px',
							paddingLeft: '10px',
							paddingBottom: '5px',
							paddingRight: '10px',
						}}
						disabled
					/>
				</Col>
				<Col sm={12} className='branch-label-input'>
					{this.props.lan.idCustomBranch}
				</Col>
				<Col sm={12} className='mb-2'>
					<TextareaAutosize
						id='branchIdCustom-input'
						type='text'
						className={'task-details-input'}
						value={this.state.branch.idCustom}
						onChange={(e) => this.setFieldValue(e.target.value, 'idCustom')}
						style={{
							width: '100%',
							paddingTop: '5px',
							paddingLeft: '10px',
							paddingBottom: '5px',
							paddingRight: '10px',
						}}
						disabled={!this.state.editData}
					/>
				</Col>
				<Col sm={12} className='branch-label-input'>
					{this.props.lan.branchNameText}
				</Col>
				{this.state.errors.name && (
					<Col sm='auto' className='pr-0 mt-1'>
						<img src={redAlertIcon} alt='' />
					</Col>
				)}
				<Col className='mb-2'>
					<TextareaAutosize
						id='branchName-input'
						type='text'
						className={'task-details-input'}
						value={this.state.branch.name}
						onChange={(e) => this.setFieldValue(e.target.value, 'name')}
						style={{
							width: '100%',
							paddingTop: '5px',
							paddingLeft: '10px',
							paddingBottom: '5px',
							paddingRight: '10px',
						}}
						disabled={!this.state.editData}
					/>
				</Col>
				<Col sm={12} className='branch-label-input'>
					{this.props.lan.rangeOfAttentionTime}
				</Col>
				<Col sm={12}>
					{this.state.showDates && (
						<RangePicker
							id='attentionTime-rangePicker'
							onChange={this.changeWorkingHours}
							className='full-width'
							size='large'
							format={'HH:mm'}
							defaultValue={[this.state.attentionTime.from, this.state.attentionTime.to]}
							disabled={!this.state.editData}
						/>
					)}
				</Col>
				{this.state.activateAlert && (
					<div className='alert alert-warning' role='alert' id='showAlert'>
						{this.props.lan.hourValidation}
					</div>
				)}
				<Col sm={12} className='branch-label-input'>
					{this.props.lan.company}
				</Col>
				{this.state.errors.company && (
					<Col sm={'auto'} className='pr-0 mt-1'>
						<img src={redAlertIcon} alt='' className='icon' />
					</Col>
				)}
				<Col>
					{this.state.showDates && (
						<AsyncSearch
							className='id-select-branch-company full-width'
							getOptions={this.getCompanyList}
							defaultValue={this.state.selectedCompany}
							disabled={!this.state.editData}
							onChange={this.handleOptionSelected}
							label={this.props.lan.placeHolderBranch}
							lan={this.props.lan}
							id='id-select-branch-company'
							showInitialOptions
							getInitialOptions={this.getInitialOptionsCompany}
						/>
					)}
					<img src={redAlertIcon} alt='' id='company-alert-icon' className='CreateTask-nameContainer-icon' />
				</Col>
				<Col sm={12} className='branch-label-input'>
					{this.props.lan.branchLocation}
				</Col>

				<Location
					includeCoordinates
					edit={this.state.editData}
					label={this.props.lan.branchLocation}
					locationUpdate={this.updateLocation}
					locationInfo={this.state.branch}
					error={this.state.errors.location}
				/>

				<Col sm={12} className='branch-label-input mt-2'>
					{this.props.lan.legalAddress}
				</Col>
				<Col sm={12}>
					<TextareaAutosize
						id='legalAddress-input'
						type='text'
						className={'task-details-input'}
						value={this.state.branch.legalAddress}
						onChange={this.updateLegalAddress}
						style={{
							width: '100%',
							paddingTop: '5px',
							paddingLeft: '10px',
							paddingBottom: '5px',
							paddingRight: '10px',
						}}
						disabled={!this.state.editData}
					/>
				</Col>
				<Col sm={12} className='branch-label-input mt-2'>
					<span
						onClick={this.openMoreBranchInfo}
						style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
					>
						{this.props.lan.moreInformation}
					</span>
				</Col>
				<Col sm={12}>
					<MoreBranchInformation 
						showMoreBranchInfo={this.state.showMoreBranchInfo} 
						ref={this.moreBranchInformationRef}
						nameValue={this.state.branch.nameMoreInfo}
						lastNameValue={this.state.branch.lastNameMoreInfo}
						emailValue={this.state.branch.emailMoreInfo} 
						cellphoneValue={this.state.branch.cellphoneMoreInfo}
						officePhoneValue={this.state.branch.officePhoneMoreInfo} 
						commentValue={this.state.branch.commentMoreInfo}
						editData={this.state.editData}
					/>
				</Col>
				{this.state.editData && (
					<Col sm={5} className='mt-4 mx-auto'>
						<FielderButton id='edit-branch' size='medium' onClick={this.handleDoActionUpdateBranch} className='mx-auto mb-3'>
							{this.props.lan.save}
						</FielderButton>
					</Col>
				)}
			</React.Fragment>
		);
	};
	changeSelectionRange = (ranges) => {
		console.log()	
		let state = this.state;
		state.filterRange = ranges.range1;
		state.logs.hasMore = false;
		this.setState(state);

		const body = {
			maxNumber: this.state.logs.maxItems,
			idBranch: this.props.branchesToShow.id,
			branch: {},
			dateFrom: state.filterRange.startDate.getTime(),
			dateTo: state.filterRange.endDate.getTime(),
		};

		lastItem = 920000000000000000;

		const onSuccess = (response) => this.formatRows(response.branchLogs, true);
		const loadingConfig = { dispatch: this.props.dispatch };
		getBranchLogsRequest(body, onSuccess, null, loadingConfig);
	};
	reorderCols = (newCols) => {
		let state = this.state;
		state.logs.cols = newCols;
		this.setState(state);
	};
	openLog = (log) => {
		let state = this.state;
		state.logs.activeLog = state.logs.complete[log.index];

		if (state.logs.complete.length > log.index + 1) {
			state.logs.pastLog = state.logs.complete[log.index + 1];
		} else {
			state.logs.pastLog = null;
		}

		state.logs.activeLogIndex = log.index;
		this.setState(state);
	};
	changeLog = (index) => {
		let state = this.state;

		if (!index && index !== 0) {
			state.logs.activeLog = null;
			state.logs.activeLogIndex = null;
		} else {
			state.logs.activeLog = state.logs.complete[index];
			state.logs.activeLogIndex = index;

			if (state.logs.complete.length > index + 1) {
				state.logs.pastLog = state.logs.complete[index + 1];
			} else {
				state.logs.pastLog = null;
			}
		}

		this.setState(state);
	};
	BranchLogs = () => {
		return (
			<React.Fragment>
				<Col sm='auto' className='ml-auto'>
					<EditButton
						id='filterByDate-button'
						className='ml-5 mt-3 mb-1'
						aria-controls='simple-menu'
						aria-haspopup='true'
						onClick={(e) => this.handleFilterLogsMenu(e.currentTarget)}
						startIcon={<TodayIcon />}
					>
						{this.props.lan.filterByDate}
					</EditButton>
					<Menu
						id='simple-menu'
						anchorEl={this.state.filterMenu}
						keepMounted
						open={Boolean(this.state.filterMenu)}
						onClose={() => this.handleFilterLogsMenu(null)}
					>
						<MenuItem>
							<DateRange
								id='logFilter-dateRange'
								editableDateInputs
								ranges={[this.state.filterRange]}
								onChange={this.changeSelectionRange}
							/>
						</MenuItem>
					</Menu>
				</Col>
				<Col sm={12}>
					<MainTable
						InfiniteScroll
						reorderCols={this.reorderCols}
						cols={this.state.logs.cols}
						rows={this.state.logs.rows}
						hasMore={this.state.logs.hasMore}
						loadMore={this.getLogs}
						hideToolbar
						onRowClick={this.openLog}
						cookie={tableCookie}
						height={350}
						{...this.props}
					/>
				</Col>
			</React.Fragment>
		);
	};
	changeTab = (event, newValue) => {
		let state = this.state;
		state.tab = newValue;
		this.setState(state);
	};
	onClose = () => {
		let state = this.state;
		state.logs.activeLog = null;
		this.setState(state);
	};
	render() {
		let BranchDetailsContent = (
			<React.Fragment>	
				<FielderTabs
					id='branchTabs'
					value={this.state.tab}
					onChange={this.changeTab}
					indicatorColor='secondary'
					textColor='inherit'
					centered
				>
					{this.props.branchesToShow.locationUpdate && (
						<FielderTab
							label={
								<Badge badgeContent='!' color='secondary'>
									{this.props.lan.newLocation}
								</Badge>
							}
							value={UPDATE_LOCATION_TAB}
						/>
					)}
					<FielderTab value={BRANCH_INFO_TAB} label={this.props.lan.detailBranch} />
					{this.props.session.account.id === this.state.idAccountBranch && (
						<	FielderTab value={BRANCH_LOGS_TAB} label={this.props.lan.branchLogs} />
					)}
				</FielderTabs>
				<Row className='mt-4'>
					<Container>
						<Row className='mx-auto' style={{ height: '100%', width: '100%' }}>
							{this.state.tab === UPDATE_LOCATION_TAB && (
								<Col sm={12}>
									<this.LocationUpdate />
								</Col>
							)}

							{this.state.tab === BRANCH_INFO_TAB && <this.BranchDetail />}
							{this.state.tab === BRANCH_LOGS_TAB && <this.BranchLogs />}
						</Row>
					</Container>
				</Row>
			</React.Fragment>
		);

		return (
			<React.Fragment >
				{this.state.logs.activeLog && (
					<BranchLog
						log={this.state.logs.activeLog}
						pastLog={this.state.logs.pastLog}
						logIndex={this.state.logs.activeLogIndex}
						changeLog={this.changeLog}
						logLength={this.state.logs.complete.length}
						onClose={this.onClose}
						{...this.props}
					/>
				)}
				{BranchDetailsContent}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(DetailEditBranchOffice);
