import logTypes from './branchLogTypes';

export const getBranchLogsType = (type, lan) => {
    switch(type) {
        case logTypes.CREATE_BRANCH_STR:
            return lan.createBranchLog;
        case logTypes.EDIT_BRANCH_STR:
            return lan.editBranchLog;
        case logTypes.UPDATE_LOCATION_STR:
            return lan.updateLocationBranchLog;
        case logTypes.ENABLE_BRANCH_STR:
            return lan.enabledBranchLog;
        case logTypes.DISABLE_BRANCH_STR:
            return lan.disabledBranchLog;
        case logTypes.CREATE_BRANCH_API_STR:
            return lan.createBranchAPILog;
        case logTypes.API_UPDATE_STR:
            return lan.apiUpdateBranchLog;
        case logTypes.CREATE_BRANCH_MOBILE_STR:
            return lan.createBranchMobileLog;
        case logTypes.IMPORTED_BRANCH_STR:
            return lan.importedBranchLog;
        case logTypes.UPDATE_LOCATION_REQUEST_STR:
            return lan.updateLocationRequestLog;
        case logTypes.DENIED_LOCATION_UPDATE_STR:
            return lan.deniedLocationRequestLog;
        default:
            return lan.updated
    }
}