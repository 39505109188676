import API_LINKS from 'FielderAPILinks';

/**
 *
 * @param {string} filter       - Filter to search the partners by
 * @param {number} idAccount    - Id of the account in use
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the partners found (returns empty if error)
 */
export const getInventoriesByFilter = (filter, idAccount, idToken, ignoreInventoryStatus) => {
	let body = {
		type: 'GET_INVENTORY_BY_FILTER',
		idAccount: idAccount,
		filter: filter,
		ignoreStatus: ignoreInventoryStatus ? ignoreInventoryStatus : false,
	};

	return fetch(API_LINKS.functionalityTaskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res && res.success === true) {
				return res.inventories;
			} else {
				console.error(res);
				return [];
			}
		});
};

/**
 *
 * @param {string} filter       - Filter to search the partner by
 * @param {number} idAccount    - Id of the account in use
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all partners by the filter send and sets the information and format for the asyncSelect component
 */
export const getInventoriesByFilterAsyncSelect = async (
	filter,
	idAccount,
	idToken,
	ignoreInventoryStatus,
	lan
) => {
	let inventories = await getInventoriesByFilter(filter, idAccount, idToken, ignoreInventoryStatus);
	let inventoryList = [];
	inventories.forEach((inventory) => {
    let label = `${inventory.id} - ${inventory.name}${inventory.category?.name ? ` [${inventory.category.name}]` : ''}`;
		let inventoryInfo = [];

		if (inventory.brand) {
			inventoryInfo.push(inventory.brand + ' (' + lan.brand + ')');
		}
		if (inventory.type) {
			inventoryInfo.push(inventory.type + ' (' + lan.type + ')');
		}
		if (inventory.model) {
			inventoryInfo.push(inventory.model + ' (' + lan.model + ')');
		}
		if (inventory.serialNumber) {
			inventoryInfo.push(inventory.serialNumber + ' (' + lan.serialNumber + ')');
		}
		if (inventory.contract) {
			inventoryInfo.push(inventory.contract + ' (' + lan.contractNumber + ')');
		}
		label += inventoryInfo.join(' - ');

		inventoryList.push({
			label: label,
			value: inventory,
		});
	});

	return inventoryList;
};



export const getInventoriesByFilterAllInventories = (filter, idAccount, idToken, ignoreInventoryStatus) => {
	let body = {
		requestType: 'GET_ALL_INVENTORIES',
		idAccount: idAccount,
		filter: filter,
		ignoreStatus: ignoreInventoryStatus ? ignoreInventoryStatus : false,
	};

	return fetch(API_LINKS.inventoryManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res && res.success === true) {
				return res.inventories;
			} else {
				console.error(res);
				return [];
			}
		});
};



export const getInventoriesByFilterAsyncSelectAllInvetories = async (
	filter,
	idAccount,
	idToken,
	ignoreInventoryStatus,
	lan
) => {
	let inventories = await getInventoriesByFilterAllInventories(filter, idAccount, idToken, ignoreInventoryStatus);
	let inventoryList = [];
	inventories.forEach((inventory) => {
    let label = `${inventory.id} - ${inventory.name}${inventory.category?.name ? ` [${inventory.category.name}]` : ''}`;
		let inventoryInfo = [];

		if (inventory.brand) {
			inventoryInfo.push(inventory.brand + ' (' + lan.brand + ')');
		}
		if (inventory.type) {
			inventoryInfo.push(inventory.type + ' (' + lan.type + ')');
		}
		if (inventory.model) {
			inventoryInfo.push(inventory.model + ' (' + lan.model + ')');
		}
		if (inventory.serialNumber) {
			inventoryInfo.push(inventory.serialNumber + ' (' + lan.serialNumber + ')');
		}
		if (inventory.contract) {
			inventoryInfo.push(inventory.contract + ' (' + lan.contractNumber + ')');
		}
		label += inventoryInfo.join(' - ');

		inventoryList.push({
			label: label,
			value: inventory,
		});
	});

	return inventoryList;
};
