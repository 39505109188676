const Demo = 'DEMO';
const Agents = 'AGENTS';
const Support = 'SUPPORT';
const MyOperation = 'MY_OPERATION';
const Profile = 'PROFILE';
const Dashboard = 'DASHBOARD';
const CommandCenter = 'COMMAND_CENTER';
const CreateTask = 'CREATE_TASK';
const AutoAssign = 'AUTO_ASSIGN';
const Companies = 'COMPANIES';
const Branches = 'BRANCHES';
const Clients = 'CLIENTS';
const Managers = 'MANAGERS';
const Administrators = 'ADMINISTRATORS';
const Partners = 'PARTNERS';
const Teams = 'TEAMS';
const Analytics = 'ANALYTICS';
const ChangePassword = 'CHANGE_PASSWORD';
const RecoverPassword = 'RECOVER_PASSWORD';
const Calendar = 'CALENDAR';
const Configuration = 'CONFIGURATION';
const ConfigurationFielder = 'CONFIGURATION';
const GlobalSearch = 'GLOBAL_SEARCH';
const Marketplace = 'MARKETPLACE';
const Forms = 'FORMS';
const AssetConfig = 'ASSET_CONFIG';
const AssetManagement = 'ASSET_MANAGEMENT';
const Inventory = 'INVENTORY';
const Skills = 'SKILLS';
const UserApiPreferences = 'USER_API_PREFERENCES';
const Isochronous = 'ISOCHRONOUS';
const RouteOptimizationPreferences = 'ROUTE_OPTIMIZATION_PREFERENCES';
const ResourceAllocationPreferences = 'RESOURCE_ALLOCATION_PREFERENCES';
const TaskTypes = 'TASK_TYPES';
const ApiPreferences = 'API_PREFERENCES';
const SMS = 'SMS';
const DynamicAllocation = 'DYNAMIC_ALLOCATION';
const BillingSummary = 'BILLING_SUMMARY';
const DashboardGlobalAccount = 'DASHBOARD_GLOBAL_ACCOUNT';
const DashboardClientGlobalAccount = 'DASHBOARD_CLIENT_GLOBAL_ACCOUNT';
const DashboardInternalAdmin = 'DASHBOARD_INTERNAL_ADMIN';
const AccountsInternalAdmin = 'ACCOUNTS_INTERNAL_ADMIN';
const Records = 'RECORDS';
const GlobalUser = 'GLOBAL_USER';
const AccountReview = 'ACCOUNT_REVIEW';
const RoleManagement = 'ROLE_MANAGEMENT';
const Expenses = 'EXPENSES';
const TaskToCash = 'TASK_TO_CASH';
const TaskFilesMassiveDownloader = 'TASK_FILES_MASSIVE_DOWNLOADER';

export default {
	Demo,
	Agents,
	Support,
	MyOperation,
	Profile,
	Dashboard,
	CommandCenter,
	CreateTask,
	AutoAssign,
	Companies,
	Branches,
	Clients,
	Analytics,
	Marketplace,
	Forms,
	AssetConfig,
	AssetManagement,
	Inventory,
	Managers,
	Administrators,
	Partners,
	Teams,
	ChangePassword,
	RecoverPassword,
	Calendar,
	Configuration,
	GlobalSearch,
	Skills,
	UserApiPreferences,
	Isochronous,
	TaskTypes,
	RouteOptimizationPreferences,
	ResourceAllocationPreferences,
	ApiPreferences,
	SMS,
	DynamicAllocation,
	Expenses,
	BillingSummary,
	DashboardGlobalAccount,
	DashboardClientGlobalAccount,
	DashboardInternalAdmin,
	AccountsInternalAdmin,
	Records,
	GlobalUser,
	AccountReview,
	RoleManagement,
	TaskToCash,
	TaskFilesMassiveDownloader,
};
