import React, { useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { selectSession } from '../../../core/store/modules/session/sessionSelectors';

const AccountPreferencesContext = React.createContext();
const AccountPreferencesUpdateContext = React.createContext();

export function useAccountPreferences() {
	return useContext(AccountPreferencesContext);
}

export function useAccountPreferencesUpdate() {
	return useContext(AccountPreferencesUpdateContext);
}

function getModule(accountModules, funcCode) {
	switch (funcCode) {
		case 'AUTO_ASSIGN':
			accountModules.showAutoAssign = true;
			return;
		case 'ASSETS':
			accountModules.showAsset = true;
			return;
		case 'INVENTORY':
			accountModules.showInventory = true;
			return;
		case 'FORMS':
			accountModules.showForms = true;
			return;
		case 'GROUPS':
			accountModules.showGroups = true;
			return;
		case 'RESOURCE_ALLOCATION':
			accountModules.showResourceAlloc = true;
			return;
		case 'PERSONALIZATION_MODULE':
			accountModules.showPersonalization = true;
			return;
		case 'ISOCHRONOUS_MODULE':
			accountModules.showIsochronous = true;
			return;
		case 'ROUTE_OPTIMIZATION':
			accountModules.showRouteOpt = true;
			return;
		case 'TAGS':
			accountModules.showTags = true;
			return;
		case 'FIELDER_API':
			accountModules.showApiPref = true;
			return;
		case 'SKILLS':
			accountModules.showSkills = true;
			return;
		case 'SMS':
			accountModules.showSms = true;
			return;
		case 'TASK_TYPE':
			accountModules.showTaskType = true;
			return;
		case 'ADVANCED_ANALYTICS':
			accountModules.showAdvancedAnalytics = true;
			return;
		case 'MAIL_INTEGRATION':
			accountModules.showMailIntegration = true;
			return;
		case 'DYNAMIC_ALLOCATION':
			accountModules.showDynamicAllocation = true;
			return;
		case 'CALENDAR_DOWNLOAD':
			accountModules.showCalendarDownload = true;
			return;
		case 'ROUTE_EXPENSES':
			accountModules.showRouteExpenses = true;
			return;
		case 'ROLE_MANAGEMENT':
			accountModules.showRoleManagement = true;
			return;
		case 'FORMS_UPLOAD_EXCEL_TEMPLATE':
			accountModules.showFormsUploadExcelTemplate = true;
			return;
		case 'COMMAND_CENTER':
			accountModules.showCommandCenter = true;
			return;
		case 'PDF_CONVERTER':
			accountModules.showPdfConverter = true;
			return;
		case 'TASK_FILES_MASSIVE_DOWNLOADER':
			accountModules.showPdfConverter = true;
			return;
		default:
	}
}

function getGrantedAction(preferences, actionName) {
	if (preferences) {
		return preferences[actionName];
	}
	return false;
}

function getAllowedModules(account) {
	const functionalities = account.funcionalityPurchases; // Typo in Backend

	const accountModules = {
		showAutoAssign: false,
		showAsset: false,
		showInventory: false,
		showForms: false,
		showRouteOpt: false,
		showResourceAlloc: false,
		showApiPref: false,
		showSkills: false,
		showIsochronous: false,
		showPersonalization: false,
		showTags: false,
		showGroups: false,
		showSms: false,
		showPdfConverter: false,
		showDynamicAllocation: false,
		showTaskType: false,
		showAdvancedAnalytics: false,
		showMailIntegration: false,
		showCalendarDownload: false,
		showRouteExpenses: false,
		showRoleManagement: false,
		showFormsUploadExcelTemplate: false,
		showCommandCenter: false,
	};

	functionalities.forEach((func) => {
		getModule(accountModules, func.functionality.customCode);
	});

	return accountModules;
}

function getGrantedActions(account) {
	const { preferences } = account;

	return {
		allowManagerCreate: getGrantedAction(preferences, 'allowManagerToCreate'),
		allowAgentExecuteTask: getGrantedAction(
			preferences,
			'allowAgentToAllwaysExecuteTasks' // Typo in Backend
		),
		allowAgentCreateTask: getGrantedAction(preferences, 'allowAgentToCreateTasks'),
		allowAgentDuplicateTask: getGrantedAction(preferences, 'allowAgentToDuplicateTasks'),
		allowAgentChangeTypeTask: getGrantedAction(preferences, 'allowAgentChangeTypeTasks'),
		allowAgentRescheduleTasks: getGrantedAction(preferences, 'allowAgentRescheduleTasks'),
		allowManagerAccessRoute: getGrantedAction(preferences, 'allowManagerAccessToRoute'),
		allowManagerAccessResource: getGrantedAction(preferences, 'allowManagerAccessToResource'),
		allowManagerAccessIso: getGrantedAction(preferences, 'allowManagerAccessToIsochronous'),
		allowManagerDownloadTask: getGrantedAction(preferences, 'allowManagerAccessToDownloadTasks'),
		allowManagerToImportTask: getGrantedAction(preferences, 'allowManagerAccessToImportTasks'),
		allowAdminAccessAnalytics: getGrantedAction(preferences, 'allowAdminAccessToAnalytics'),
		allowManagerAccessAnalytics: getGrantedAction(preferences, 'allowManagerAccessToAnalytics'),
		allowClientToImport: getGrantedAction(preferences, 'allowClientToImport'),
		allowClientToExport: getGrantedAction(preferences, 'allowClientToExport'),
		allowManagerToModifyTasksStatus: getGrantedAction(preferences, 'allowManagerToModifyTasksStatus'),
		allowManagerToDownloadFormExcelTaskDetail: getGrantedAction(preferences, 'allowManagerToDownloadFormExcelTaskDetail'),
		allowManagerToEditFormTaskDetail: getGrantedAction(preferences, 'allowManagerToEditFormTaskDetail'),
		allowManagerToSeeTaskByMail: getGrantedAction(preferences, 'allowManagerToSeeTaskByMail'),
		allowManagerToGeneratePdf: getGrantedAction(preferences, 'allowManagerToGeneratePdf'),
    	allowManagerToApproveForms: getGrantedAction(preferences, 'allowManagerToApproveForms'),
    	allowManagerToGenerateAutoAssignTasks: getGrantedAction(preferences, 'allowManagerToGenerateAutoAssignTasks'),
		allowAdminToModifyTasksStatus: getGrantedAction(preferences, 'allowAdminToModifyTasksStatus'),
		allowManagerToGenerateAndDownloadInventories: getGrantedAction(preferences, 'allowManagerToGenerateAndDownloadInventories'),
		allowManagerToGenerateAndDownloadAssets: getGrantedAction(preferences, 'allowManagerToGenerateAndDownloadAssets'),
		allowManagerToGenerateAndDownloadForms: getGrantedAction(preferences, 'allowManagerToGenerateAndDownloadForms'),
		allowPartnerToEditBranch: getGrantedAction(preferences, 'allowPartnerToEditBranch'),
		allowPartnerToGenerateForms: getGrantedAction(preferences, 'allowPartnerToGenerateForms'),
	};
}

function getAccountPreferences(session) {
	let allowedModules = {};
	let grantedActions = {};

	if (session.account) {
		allowedModules = getAllowedModules(session.account);
		grantedActions = getGrantedActions(session.account);
	}

	return {
		loading: false,
		accountInfoUpdated: true,
		...allowedModules,
		...grantedActions,
	};
}

export const AccountPreferencesProvider = ({ children }) => {
	const session = useSelector(selectSession);

	const [AccountPreferences, setAccountPreferences] = useState({});

	useEffect(() => {
		if (session && session.user && session.user.language) {
			// Get Account Preferences
			const accountPreferences = getAccountPreferences(session);
			setAccountPreferences(accountPreferences);
		}
	}, []);

	function updateAccountPreferences(session) {
		const accountPreferences = getAccountPreferences(session);
		setAccountPreferences(accountPreferences);
	}

	return (
		<AccountPreferencesContext.Provider value={AccountPreferences}>
			<AccountPreferencesUpdateContext.Provider value={updateAccountPreferences}>
				{children}
			</AccountPreferencesUpdateContext.Provider>
		</AccountPreferencesContext.Provider>
	);
};
