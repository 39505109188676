import React, { Component } from 'react';

import TeamsIcon from '@assets/icons/teams-icon.svg';
import { getIdToken } from 'FielderUtils/session/Session';
import {
	getTeamsByFilterAsyncSelect,
	getTeamsInitialOptionsAsyncSelect,
} from 'FielderUtils/team/team';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import Popup from '../../Popup';

import { assingTeamTaskRequest} from '@core/api/task/task.service';

import '../MyOperation.css';

class AssignTeamPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			idTeamSelected: null,
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
		};
	}

	assignTeam = async ( ) => {
		this.props.dispatch(loadingStart({ name: this.props.pageName }))
		
		if (this.state.idTeamSelected !== null) {	
			for (let i = 0; i < this.props.idTasks.length; i++) {
				
				var body = {
					taskInfo: {
						idTask: this.props.idTasks[i],
						idUser: this.state.idUser,
						idTeam: this.state.idTeamSelected,
						resetPartner: false,
						idAccount: this.state.idAccount,
					},
				};

				const onSuccess = (response) => {		
					if (this.props.idTasks.length === (i + 1)) {

						this.props.onClose(true, response);
						this.props.dispatch(loadingStop(this.props.pageName))
					}
				}

				const onError = (error) => {
					this.props.onClose(false, error);
					this.props.dispatch(loadingStop(this.props.pageName))
				};

				await assingTeamTaskRequest(body, onSuccess, onError, null)
			}
		}
	};

	setFieldAssign = (event) => {
		this.setState({
			idTeamSelected: event.value.id,
		});
	};

	getAssignList = async (event) => {
		if (event.length > 0) {
			var teamsListArray = await getTeamsByFilterAsyncSelect(
				event,
				this.state.idAccount,
				this.state.idUser,
				getIdToken(),
				this.props.lan
			);

			return teamsListArray;
		}
	};

	getInitialOptionsTeam = async () => {
		return await getTeamsInitialOptionsAsyncSelect(
			this.state.idAccount,
			this.state.idUser,
			getIdToken(),
			this.props.lan
		);
	};

	render() {
		
		return (
			<Popup
				title={this.props.lan.assignTeam}
				icon='teams-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText='Asignar'
				buttonOnClick={this.assignTeam}
			>
				<Row className=''>
					<Col sm={'auto'} className='my-auto'>
						<img src={TeamsIcon} className='icon mr-auto' alt='' />
					</Col>
					<div className='col'>
						<AsyncSearch
							className='react-select__control full-width'
							getOptions={(e) => this.getAssignList(e)}
							onChange={(e) => this.setFieldAssign(e)}
							label={this.props.teamTask}
							lan={this.props.lan}
							id='all-AsyncSearch'
							showInitialOptions
							getInitialOptions={() => this.getInitialOptionsTeam()}
						/>
					</div>
				</Row>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AssignTeamPopup);
