import React, { useState } from 'react';
import { Dialog, DialogContent, Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LogoLogin from '@assets/icons/microsoft_logo_text.svg';

const LogoutSSOMobile = () => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isMobile =
    useMediaQuery(theme.breakpoints.down("sm")) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    window.innerWidth <= 768;
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: isMobile ? "100%" : "auto",
          borderRadius: "12px",
        }
      }}
      slotProps={{
        backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.95)" } }
      }}
    >
      <DialogContent sx={{ padding: isMobile ? 2 : 3, textAlign: "left" }}>
        <Box display="flex" flexDirection="column" alignItems="left">
          <img src={LogoLogin} alt="Logo" style={{ width: isMobile ? "80px" : "100px", marginBottom: 12 }} />
          <Typography variant={isMobile ? "subtitle1" : "h6"} sx={{ fontWeight: "bold", marginBottom: 1 }}>
            You signed out of your account
          </Typography>
          <Typography variant="body2" sx={{ color: "#242424" }}>
            It's a good idea to close all browser windows.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default LogoutSSOMobile;
