import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

import LANGUAGE from 'FielderUtils/languages/fielderLanguages';
import { getSession } from '../../../core/store/modules/session/sessionSelectors';
import language from 'FielderUtils/languages/languages';

export const INVALID_TOKEN = 401;
export const REQUEST_TIME_OUT = 504;
export const INVALID_FIELDER_SESSION = 101;
export const { GOOGLE_API_KEY } = process.env;
export const GOOGLE_URL_WITH_LIBRARIES = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
export const GOOGLE_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`;

const ENVIRONMENT = process.env.ENVIRONMENT ? process.env.ENVIRONMENT : '';
const KEY_FIELDER_SESSION = `${ENVIRONMENT}fielder_session`;
const KEY_ID_TOKEN = `${ENVIRONMENT}fielder_session_1`;
const KEY_REFRESH_TOKEN = `${ENVIRONMENT}fielder_session_2`;
const KEY_ACCESS_TOKEN = `${ENVIRONMENT}fielder_session_3`;
const KEY_MFA_AUTHENTICATION = `${ENVIRONMENT}fielder_session_4`;
const KEY_MFA_QR = `${ENVIRONMENT}fielder_session_5`;
const KEY_SELECTED_COGNITO_PROVIDER = 'selectedCognitoProvider';
const KEY_COGNITO_USER = 'cognitoUser';

const oneYear = 365;

const HTTPS_STRING = 'https://';
const AWS_LINK = '.s3.amazonaws.com/';
const CSV_TEMPLATES_FOLDER = 'csvtemplates/';
const IMPORT_BRANCH_FILE = 'import-branch-v5.csv';
const IMPORT_MANAGER_FILE = 'import-managers-v1.csv';
const IMPORT_CLIENT_FILE = 'import-clients-v3.csv';
const IMPORT_ADMIN_FILE = 'import-admin-v1.csv';
const IMPORT_AGENT_FILE = 'import-agent-v2.csv';
const IMPORT_COMPANY_FILE = 'import-company-v4.csv';
const IMPORT_ASSET_FILE = 'import-assets-v1.csv';
const IMPORT_INVENTORY_FILE = 'import-inventory-v1.csv';
const IMPORT_TASKS_ADMIN_FILE = 'SampleTasksImportation_admin.csv';
const IMPORT_TASKS_CLIENT_FILE = 'SampleTasksImportation_client.csv';
const IMPORT_TASK_MANAGER_FILE = 'SampleTasksImportation_manager.csv';
const UPATE_TASKS_ADMIN_FILE = 'SampleTasksUpdate_admin.csv';
const UPDATE_TASK_MANAGER_FILE = 'SampleTasksUpdate_manager.csv';
const IMPORT_ASSET_SAMPLE_FILE = 'AssetsSample-v1.csv';

const S3_TEMPLATE_URL = `${process.env.FIELDER_CONTENT_LINK}/${CSV_TEMPLATES_FOLDER}`;

export const getImportBranchFileSample = (language) => { 
	return getCorrectLanguage( language, IMPORT_BRANCH_FILE);
}

export const getImportCompanyFileSample = (language) => {
	return getCorrectLanguage( language, IMPORT_COMPANY_FILE);
};

export const getImportTasksSampleAdmin = (language) => {
	return getCorrectLanguage(language, IMPORT_TASKS_ADMIN_FILE);
}

export const getImportTaskSampleManager = (language) => {
	return getCorrectLanguage(language, IMPORT_TASK_MANAGER_FILE);
}

export const getImportTasksSampleClient = (language) => { 
	return getCorrectLanguage(language, IMPORT_TASKS_CLIENT_FILE);
}

export const getUpdateTasksSampleAdmin = (language) => { 
	return getCorrectLanguage(language, UPATE_TASKS_ADMIN_FILE);
}

export const getUpdateTasksSampleManager = (language) => {
	return getCorrectLanguage(language, UPDATE_TASK_MANAGER_FILE);
}

export const getImportAgentFileSample = (language) => {
	return getCorrectLanguage(language, IMPORT_AGENT_FILE);
}

export const getImportAdminFileSample = (language) => {
	return getCorrectLanguage(language, IMPORT_ADMIN_FILE);
}

export const getImportManagerFileSample = (language) => {
	return getCorrectLanguage(language, IMPORT_MANAGER_FILE);
}

export const getImportClientFileSample = (language) => {
	return getCorrectLanguage(language, IMPORT_CLIENT_FILE);
}

const getCorrectLanguage = (language, templatePath) => {
	if ((language == LANGUAGE.es || language == LANGUAGE.mx)) {
		return S3_TEMPLATE_URL + templatePath.replace('.csv', '-es.csv');
	} else if (language == LANGUAGE.pt) {
		return S3_TEMPLATE_URL + templatePath.replace('.csv', '-pt.csv');
	} else { 
		return S3_TEMPLATE_URL + templatePath.replace('.csv', '-en.csv');
	}
}

export const getImportAssetsFileSample = () => S3_TEMPLATE_URL + IMPORT_ASSET_FILE;
export const geImportInventoryFileSample = () => S3_TEMPLATE_URL + IMPORT_INVENTORY_FILE;

export const getCookie = (key) => {
	const cookieVal = Cookies.get(key);
	let cookie = null;

	if (cookieVal) {
		const bytes = CryptoJS.AES.decrypt(cookieVal, process.env.SECRET_KEY);
		cookie = bytes.toString(CryptoJS.enc.Utf8);
	}
	return cookie;
};

// TODO: Remove
const saveLocalStorage = (key, value) => {
	value = CryptoJS.AES.encrypt(JSON.stringify(value), process.env.SECRET_KEY).toString();
	localStorage.setItem(key, value);
};

// TODO: Remove
export const saveUserSession = (session) => {
	saveLocalStorage(KEY_FIELDER_SESSION, session);
};

const getLocalStorage = (key) => {
	const value = localStorage.getItem(key);
	if (value) {
		const bytes = CryptoJS.AES.decrypt(value, process.env.SECRET_KEY);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	}

	return null;
};

export const setCookie = (key, value, time) => {
	value = CryptoJS.AES.encrypt(value, process.env.SECRET_KEY).toString();
	Cookies.set(key, value, { expires: time, sameSite: 'strict' });
};
export const getFielderSession = async () => await getSession();

export const validSession = async () => {
	const session = await getFielderSession();
	const idToken = getIdToken();
	return !!session && !!session.user && !!idToken;
};

export const setIdToken = (token) => setCookie(KEY_ID_TOKEN, token, oneYear);
export const setIdOldToken = (token) => setCookie(KEY_ID_TOKEN, token, oneYear);
export const setAccessToken = (token) => setCookie(KEY_ACCESS_TOKEN, token, oneYear);

export const setSelectedCognitoProvider = (data) => setCookie(KEY_SELECTED_COGNITO_PROVIDER, data, oneYear);
export const getSelectedCognitoProvider = () => getCookie(KEY_SELECTED_COGNITO_PROVIDER);
export const setCognitoUser = (data) => setCookie(KEY_COGNITO_USER, data, oneYear);
export const getCognitoUser = () => getCookie(KEY_COGNITO_USER);

export const getRefreshToken = () => getCookie(KEY_REFRESH_TOKEN);
export const getIdToken = () => getCookie(KEY_ID_TOKEN);
export const getAccessToken = () => getCookie(KEY_ACCESS_TOKEN);
export const getMfaAuthentication = () => getCookie(KEY_MFA_AUTHENTICATION);
export const getMfaQr = () => getCookie(KEY_MFA_QR);

export const buildSession = (idToken, accessToken, refreshToken, mfaAuthentication, mfaQr, isRefreshSession) => {
	Cookies.remove(KEY_ID_TOKEN);
	Cookies.remove(KEY_ACCESS_TOKEN);
	Cookies.remove(KEY_MFA_AUTHENTICATION);
	Cookies.remove(KEY_MFA_QR);

	if (!isRefreshSession) {
		Cookies.remove(KEY_REFRESH_TOKEN);
		setCookie(KEY_REFRESH_TOKEN, refreshToken, oneYear);
	}

	setCookie(KEY_ID_TOKEN, idToken, oneYear);
	setCookie(KEY_ACCESS_TOKEN, accessToken, oneYear);
	setCookie(KEY_MFA_AUTHENTICATION, mfaAuthentication, oneYear);
	setCookie(KEY_MFA_QR, mfaQr, oneYear);
};

export const removeSession = () => {
	Cookies.remove(KEY_ID_TOKEN);
	Cookies.remove(KEY_REFRESH_TOKEN);
	Cookies.remove(KEY_ACCESS_TOKEN);
	Cookies.remove(KEY_MFA_AUTHENTICATION);
	Cookies.remove(KEY_MFA_QR);
};

export const removeSelectedCognitoProvider = (key) => {
  Cookies.remove(KEY_SELECTED_COGNITO_PROVIDER);
};
export const removeCognitoUser = (key) => {
  Cookies.remove(KEY_COGNITO_USER);
};
