import React, { useState, useEffect, useRef } from 'react';

import FielderButton from 'FielderComponents/FielderButton';
import dislikeIcon from '@assets/icons/bad-icon-png.png';
import likeIcon from '@assets/icons/good-icon-png.png';
import sadIcon from '@assets/icons/not-satisfactory-icon-png.png';
import happyIcon from '@assets/icons/satisfactory-icon-png.png';
import presignedUrlForUpload from '@core/utils/aws/s3/presignedUrlForUpload';
import { Checkbox, Icon, IconButton, Paper, Typography, Button, Slider, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import TextField from '~/TextField/TextField.component';
import Clear from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutline';
import DoneAll from '@mui/icons-material/DoneAll';
import SaveIcon from '@mui/icons-material/Save';
import Cleave from 'cleave.js/react';
import Types from 'FielderUtils/forms/types';
import moment from 'moment';
import TextareaAutosize from 'react-autosize-textarea';
import { Container, Row, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import StarRatings from 'react-star-ratings';
import ApprovedCheckbox from './ApprovedCheckbox';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import AprovedTextInfo from './AprovedTextInfo';
import { useLanguage } from 'Context/LanguageContext';
import {
	deleteFormImageRequest,
	updateFormFromTaskRequest,
} from '@core/api/form/form.service';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';

import {
	updateAssetsFromTaskRequest,
	deleteAssetImageFromTaskRequest
} from '@core/api/asset/asset.service';

import {
	updateInventoryFromTaskRequest,
	deleteInventoryImageFromTaskRequest
} from '@core/api/inventory/inventory.service';


import { selectSession } from '../../core/store/modules/session/sessionSelectors';
import Popup from '../Popup';

import 'moment-timezone';

import './TaskDetails.css';
import { LightGreySubtitleColor } from '@core/theme/baseTheme';
import { dissoc } from 'lodash/fp';

const GreenCheckbox = styled(Checkbox)(() => ({
	color: green[400],
	'&.Mui-checked': {
		color: green[600],
	},
}))

export default function EditForm(props) {
	const session = useSelector(selectSession);
	const isManager = RoleUtils.isUserManager(session.user);
	const showApprovedForm = session?.accountPreferences?.allowManagerToApproveForms

	const dispatch = useDispatch();
	const lan = useLanguage();

	const [deletePopup, setDeletePopup] = useState(false);
	const [deleteSignaturesPopup, setDeleteSignaturesPopup] = useState(false);
	const [questionIndex, setQuestionIndex] = useState(null);
	const [signatures, setSignatures] = useState({});

	const [form, setForm] = useState(null);
	const [asset, setAsset] = useState(null);
	const [inventory, setInventory] = useState(null);
	const [viewFields, setViewFields] = useState(true);
	const [index, setIndex] = useState(null);

	const checkboxRefs = useRef({});

	useEffect(() => {
		let formCopy;
		let assetCopy;
		let inventoryCopy;

		if (props.form) {
			formCopy = JSON.parse(JSON.stringify(props.form));
			setForm(formCopy);
		} else if (props.asset) {
			assetCopy = JSON.parse(JSON.stringify(props.asset));
			setAsset(assetCopy);
		} else if (props.inventory) {
			inventoryCopy = JSON.parse(JSON.stringify(props.inventory));
			setInventory(inventoryCopy);
		}
	

	}, [props.show, props.form, props.asset, props.inventory]);

	useEffect(() => {
		setViewFields(true);
	}, [form, asset, inventory]);

	const selectStyle = {
		control: (base, state) => ({
			...base,
			borderColor: '#e6ebf0',
		}),
	};

	const fingersOptions = [
		{
			value: true,
			label: (
				<div style={{ zIndex: '9999999' }}>
					<img src={likeIcon} className='icon' alt='' />
				</div>
			),
		},
		{
			value: false,
			label: (
				<div style={{ zIndex: '9999999' }}>
					<img src={dislikeIcon} className='icon' alt='' />
				</div>
			),
		},
	];

	const facesOptions = [
		{
			value: true,
			label: (
				<div style={{ zIndex: '9999999' }}>
					<img src={happyIcon} className='icon' alt='' />
				</div>
			),
		},
		{
			value: false,
			label: (
				<div style={{ zIndex: '9999999' }}>
					<img src={sadIcon} className='icon' alt='' />
				</div>
			),
		},
	];

	const valueLabelComponent = ({ children, open, value }) => (
		<Tooltip open={open} enterTouchDelay={0} placement='top' title={value}>
			{children}
		</Tooltip>
	);

	const updateDateHour = (event, index, type) => {

		if (type == 'TIME') {
			if (event.target.value.length == 5) {
				
				const hoursToken = event.target.value.split(':');
				const hours = parseInt(hoursToken[0]) * 3600000;
				const minutes = parseInt(hoursToken[1]) * 60000;

				if (form) {
					const data = form;
					data.fields[index].question.time = hours + minutes;
					updateQuestionApprovedOnUpdate(data, hours + minutes, index);
					setForm(data);
				} else if (asset) {
					const data = asset;
					data.questions[index].time = hours + minutes;
					setAsset(data);
				} else if (inventory) {
					const data = inventory;
					data.questions[index].time = hours + minutes;
					setInventory(data);
				}
			}
		} else if (event.target.value.length == 10) {
			const dateToken = event.target.value.split('/');
			const date = Date.UTC(dateToken[2], dateToken[1] - 1, dateToken[0]);

			if (form) {
				const data = form;
				data.fields[index].question.dateTime = date;
				updateQuestionApprovedOnUpdate(data, date, index);
				setForm(data);
			} else if (asset) {
				const data = asset;
				data.questions[index].dateTime = date;
				setAsset(data);
			} else if (inventory) {
				const data = inventory;
				data.questions[index].time = date;
				setInventory(data);
			}
		} else {
			if (form) {
				const data = form;
				disapproveFormQuestionByIndex(data, index);
			}
		}
	};

	const updateAssetInventory = (event, index, textFileName, updateOptions) => {
		let question_type;
		let newAssetInventory;
		let assetInventory;

		if (asset) {
			question_type = asset.questions[index].type;
			newAssetInventory = asset;
			assetInventory = asset;
		} else {
			question_type = inventory.questions[index].type;
			newAssetInventory = inventory;
			assetInventory = inventory;
		}

		switch (parseInt(question_type)) {
			case Types.MULTIPLE_CHOICE:
				if (assetInventory.questions[index].singleAnswer) {
					assetInventory.questions[index].options.forEach((opt, i) => {
						if (opt.name == event.label) {
							newAssetInventory.questions[index].options[i].checked = true;
						} else {
							newAssetInventory.questions[index].options[i].checked = false;
						}
					});
				} else {
					assetInventory.questions[index].options.forEach((opt, i) => {
						newAssetInventory.questions[index].options[i].checked = false;
					});

					assetInventory.questions[index].options.forEach((opt, i) => {
						event.forEach((el) => {
							if (opt.name == el.label) {
								newAssetInventory.questions[index].options[i].checked = true;
							}
						});
					});
				}

				if (asset) {
					setAsset(newAssetInventory);
				} else {
					setInventory(newAssetInventory);
				}
				break;
			case Types.TEXT_FIELD:
				newAssetInventory.questions[index].text = event;
				if (asset) {
					setAsset(newAssetInventory);
				} else {
					setInventory(newAssetInventory);
				}
				break;
			case Types.CODEBAR_TEXT:
			case Types.NUMERIC_RATING:
				newAssetInventory.questions[index].value = event;
				if (asset) {
					setAsset(newAssetInventory);
				} else {
					setInventory(newAssetInventory);
				}
				break;
			case Types.BINARY_RATING:
			case Types.STARS_RATING:
				newAssetInventory.questions[index].value = event;
				if (asset) {
					setAsset({ ...newAssetInventory });
				} else {
					setInventory({ ...newAssetInventory });
				}
				break;
			case Types.FILES:
			case Types.CODEBAR_IMG:
			case Types.ELECTRONIC_SIGNATURE:
				newAssetInventory.questions[index].file = null;
				newAssetInventory.questions[index].value = event;
				newAssetInventory.questions[index].text = textFileName;

				if (asset) {
					setAsset({ ...newAssetInventory });
				} else {
					setInventory({ ...newAssetInventory });
				}
				break;
			case Types.DATE_HOUR:
				break;
			case Types.TEXT_FILE:
				newAssetInventory.questions[index].value = event;
				newAssetInventory.questions[index].fileName = textFileName;
				if (asset) {
					setAsset({ ...newAssetInventory });
				} else {
					setInventory({ ...newAssetInventory });
				}
				break;
			case Types.CATALOGUE:
				newAssetInventory.questions[index].options = event;

				if (updateOptions) {
					setViewFields(false);
					if (asset) {
						setAsset({ ...newAssetInventory });
					} else {
						setInventory({ ...newAssetInventory });
					}
					//setForm({ ...newForm });
				} else {
					if (asset) {
						setAsset({ ...newAssetInventory });
					} else {
						setInventory({ ...newAssetInventory });
					}
					//setForm(newForm);
				}
				break;
		}
	};

	const updateForm = (event, index, textFileName, updateOptions, resetSignatues) => {
		const question_type = form.fields[index].question.type;
		const newForm = form;

		switch (parseInt(question_type)) {
			case Types.MULTIPLE_CHOICE:
				if (form.fields[index].question.singleAnswer) {
					form.fields[index].question.options.forEach((opt, i) => {
						if (opt.name == event.label) {
							newForm.fields[index].question.options[i].checked = true;
							approveFormQuestionByIndex(newForm, index);
						} else {
							newForm.fields[index].question.options[i].checked = false;
						}
					});
				} else {
					form.fields[index].question.options.forEach((opt, i) => {
						newForm.fields[index].question.options[i].checked = false;
						disapproveFormQuestionByIndex(newForm, index);
					});

					form.fields[index].question.options.forEach((opt, i) => {
						event.forEach((el) => {
							if (opt.name == el.label) {
								newForm.fields[index].question.options[i].checked = true;
								approveFormQuestionByIndex(newForm, index);
							}
						});
					});
				}
				break;
			case Types.TEXT_FIELD:
				newForm.fields[index].question.text = event;
				updateQuestionApprovedOnUpdate(newForm, event, index);
				setForm(newForm);
				break;
			case Types.CODEBAR_TEXT:
			case Types.NUMERIC_RATING:
				newForm.fields[index].question.value = event;
				updateQuestionApprovedOnUpdate(newForm, event, index);
				setForm(newForm);
				break;
			case Types.BINARY_RATING:
			case Types.STARS_RATING:
				newForm.fields[index].question.value = event;
				approveFormQuestionByIndex(newForm, index);
				setForm({ ...newForm });
				break;
			case Types.FILES:
			case Types.CODEBAR_IMG:
				newForm.fields[index].question.file = null;
				newForm.fields[index].question.value = event;
				newForm.fields[index].question.text = textFileName;	
				updateQuestionApprovedOnUpdate(newForm, event, index);
				setForm({ ...newForm });
				break;
			case Types.ELECTRONIC_SIGNATURE:
				newForm.fields[index].question.file = null;
				newForm.fields[index].question.value = event;
				newForm.fields[index].question.text = textFileName;
				if(resetSignatues){
					newForm.fields[index].question.file = null;
					newForm.fields[index].question.value = null;
					newForm.fields[index].question.approved = false;
					
				}
				console.log("newForm.fields[index].question ntreeeeeeeeeee");
				console.log(newForm.fields[index].question);
				updateQuestionApprovedOnUpdate(newForm, event, index);
				setForm({ ...newForm });
				break;
			case Types.DATE_HOUR:
				break;
			case Types.TEXT_FILE:
				newForm.fields[index].question.value = event;
				newForm.fields[index].question.fileName = textFileName;
				updateQuestionApprovedOnUpdate(newForm, event, index);
				setForm({ ...newForm });
				break;
			case Types.CATALOGUE:
				newForm.fields[index].question.options = event;
				
				disapproveFormQuestionByIndex(newForm, index);
				event.forEach(option => {
					if (option.checked) {
						approveFormQuestionByIndex(newForm, index);
					}
				});
				
				if (updateOptions) {
					setViewFields(false);
					setForm({ ...newForm });
				} else {
					setForm(newForm);
				}
				break;
		}
	};

	const update = async (e, index, textFileName, updateOptions, resetSignatures) => {
		if (form) {
			// Envuelve la función en una promesa si no es asíncrona
			await new Promise((resolve, reject) => {
				try {
					updateForm(e, index, textFileName, updateOptions, resetSignatures);
					resolve(); // Resuelve si la función termina sin errores
				} catch (error) {
					reject(error); // Rechaza la promesa si hay un error
				}
			});
		} else {
			await new Promise((resolve, reject) => {
				try {
					updateAssetInventory(e, index, textFileName, updateOptions);
					resolve();
				} catch (error) {
					reject(error);
				}
			});
		}
	};

	const updateQuestionApproved = (index, question) => {
	
		if (question.approved == null) {
			question.approved = false;
		}

		if (form) {
			const newForm = form;
			newForm.fields[index].question.approved = !question.approved;
			setForm({ ...newForm });
		} else if (asset) {
			const newAsset = asset;
			newAsset.questions[index].approved = !question.approved;
			setAsset({ ...newAsset });
		} else if (inventory) {
			const newInventory = inventory;
			newInventory.questions[index].approved = !question.approved;
			setInventory({ ...newInventory });
		}
	};

	const updateQuestionApprovedOnUpdate = (newForm, event, index) => {
		
		if (event != null && event != "") {
			approveFormQuestionByIndex(newForm, index);
		} else if (event != null && event == "") {
			disapproveFormQuestionByIndex(newForm, index);
		} else if (event == null) {
			disapproveFormQuestionByIndex(newForm, index);
		}
		
	}

	const approveFormQuestionByIndex = (newForm, index) => {
		if (newForm.reviewable && newForm.fields[index].question.approved != true && (!isManager || (isManager && showApprovedForm))) {
			newForm.fields[index].question.approved = true;
			
			const checkbox = document.getElementById('form-index' + index);
			if(checkbox){
				checkbox.checked = true;
			}
			
			
			const elemento = document.getElementById('form-reason-index' + index);
			if (elemento) {
				elemento.style.display = 'none';
			}
		}
	}

	const disapproveFormQuestionByIndex = (newForm, index) => {
		if (newForm.reviewable && (!isManager || (isManager && showApprovedForm))) {
			newForm.fields[index].question.approved = false;

			const checkbox = document.getElementById('form-index' + index);
			if(checkbox){
				checkbox.checked = false;
			}

			const elemento = document.getElementById('form-reason-index' + index);
			if (elemento) {
				elemento.style.display = '';
			}
		}
	}

	const updateQuestionApprovedReason = (index, question, value) => {
		if (form) {
			const newForm = form;
			newForm.fields[index].question.notApprovedReason = value;
			setForm(newForm);
		}
	}

	const handleKeyPress = (event, restrictValue) => {
		if (restrictValue === 0) return;
		else if (restrictValue === 1) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		} else if (restrictValue === 2) {
			const charCode = event.which ? event.which : event.keyCode;
			if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
				event.preventDefault();
			}
		}
	};

	const handlePaste = (event, restrictValue) => {
		if (restrictValue === 0) return;
		
		const pastedText = event.clipboardData.getData('text/plain');
		const onlyLetters = restrictValue === 1 ? pastedText.replace(/[^\d]/g, '') : restrictValue === 2 ? pastedText.replace(/[^A-Za-z]/g, '') : '';
	
		const selection = window.getSelection();
		if (!selection.isCollapsed) {
			document.execCommand('insertText', false, onlyLetters);
		} else {
			document.execCommand('insertText', false, onlyLetters);
		}
	
		event.preventDefault();
	};

	const getMultipleChoice = (question, index) => {
		const options = [];
		const selected = [];
		let disabled = false;

		question.options.forEach((opt, i) => {
			const op = {
				value: i,
				label: opt.name,
			};

			if (opt.checked) {
				selected.push(op);
			}

			options.push(op);
		});

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		return (
			<Select
				isMulti={!question.singleAnswer}
				defaultValue={selected}
				options={options}
				placeholder={lan.select}
				onChange={(e) => update(e, index)}
				isDisabled={disabled}
			/>
		);
	};

	const getTextField = (question, index) => {
		let { text } = question;
		let disabled = false;

		if (text == null) {
			text = '';
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		let restrictValue = 0;
		if (question.restrictValue != null) {
			restrictValue = question.restrictValue;
		}

		return (
			<>
				<TextareaAutosize
					type='text'
					className='task-details-input'
					defaultValue={text}
					maxLength={question.maxLength}
					onChange={(e) => update(e.target.value, index)}
					onKeyPress={(e) => handleKeyPress(e, restrictValue)}
					onPaste={(e) => handlePaste(e, restrictValue)}
					style={{
						width: '100%',
						paddingTop: '5px',
						paddingLeft: '10px',
						paddingBottom: '5px',
						paddingRight: '10px',
					}}
					disabled={disabled}
				/>
				<Col style={{ color: '#DC5C87', fontSize: '14px' }}>
					{restrictValue === 0 ? '*' + lan.alphanumericValue : restrictValue === 1 ? '*' + lan.numericValue : restrictValue === 2 ? '*' + lan.stringValue : ''}
				</Col>
			</>
		);
	};

	const getNumericRating = (question, index) => {
		let defaultValue = question.value;
		let disabled = false;

		if (!defaultValue || defaultValue < question.startValue) {
			defaultValue = question.startValue;
		} else if (defaultValue > question.endValue) {
			defaultValue = question.endValue;
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		return (
			<Slider
				ValueLabelComponent={valueLabelComponent}
				min={question.startValue}
				max={question.endValue}
				defaultValue={defaultValue}
				onChange={(e, val) => update(val, index)}
				disabled={disabled}
			/>
		);
	};

	const getBinaryRating = (question, index) => {
		let defaultValue;
		let options;
		const styles = selectStyle;
		let disabled = false;

		if (question.iconType === 'FINGERS') {
			options = fingersOptions;
		} else {
			options = facesOptions;
		}

		if (question.value) {
			defaultValue = options[0];
		} else {
			defaultValue = options[1];
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		return (
			<Select
				options={options}
				styles={styles}
				defaultValue={defaultValue}
				onChange={(e) => update(e.value, index)}
				isDisabled={disabled}
			/>
		);
	};

	const getStarsRating = (question, index) => {
		const { value } = question;
		const disabled = false;

		return (
			<StarRatings
				rating={value}
				starSpacing='5px'
				starDimension='30px'
				changeRating={(e) => update(e, index)}
				numberOfStars={5}
				starRatedColor='rgb(250,192,30)'
				starHoverColor='rgb(250,192,30)'
				disabled={disabled}
			/>
		);
	};

	const toBase64 = (file) =>
		new Promise((res, rej) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => res(reader.result);
			reader.onerror = (error) => rej(error);
		});

	const deleteImage = () => {

		if (form && form.fields[questionIndex].question.file != null) {
			const body = {
				idTask: props.taskId,
				idUser: session.user.idUser,
				questionId: questionIndex,
				idTaskForm: form.idTaskForm,
				accountId: props.idAccountTask,
			};

			const onSuccess = (response) => {
				props.reloadData();
				//props.onClose(false);
				setQuestionIndex(null);
				update(null, questionIndex);
				setDeletePopup(false);
			}

			const onError = (error) => {
				console.log(error)
			}

			const loadingConfig = { dispatch }
			deleteFormImageRequest(body, onSuccess, onError, loadingConfig)

		} else if (asset && asset.questions[questionIndex].file != null) {


			const body = {
				questionId: questionIndex + 1,
				idAsset: asset.id,
				accountId: props.idAccountTask,
				idTask: props.taskId
			};

			const onSuccess = (response) => {
				props.reloadData();
				props.onClose(false);
				setQuestionIndex(null);
				update(null, questionIndex);
				setDeletePopup(false);
			}

			const onError = (error) => {
				console.log(error)
			}

			const loadingConfig = { dispatch }
			deleteAssetImageFromTaskRequest(body, onSuccess, onError, loadingConfig)

		} else if (inventory && inventory.questions[questionIndex].file != null) {
						
			const body = {
				questionId: questionIndex + 1,
				idInventory: inventory.id,
				accountId: props.idAccountTask,
				idTask: props.taskId
			};

			const onSuccess = (response) => {
				props.reloadData();
				props.onClose(false);
				setQuestionIndex(null);
				update(null, questionIndex);
				setDeletePopup(false);
			}

			const onError = (error) => {
				console.log(error)
			}

			const loadingConfig = { dispatch }
			deleteInventoryImageFromTaskRequest(body, onSuccess, onError, loadingConfig)

		} else {
			update(null, questionIndex);
			setDeletePopup(false);
			setQuestionIndex(null);
		}
	};

	const setSignatureRef = (index, ref) => {
		const newSignatures = signatures;
		newSignatures[index] = ref;
		setSignatures(newSignatures);
	};

	const deleteElectronicSignature = (index, title) => {
		if (signatures[index]) {
			signatures[index].clear();
		} else {
			setDeletePopup(true);
			setQuestionIndex(index);
		}
	};

	const dataURLToFile = (base64, filename, mime) =>
		fetch(base64)
			.then((res) => res.arrayBuffer())
			.then((buf) => new File([buf], filename, { type: mime }));

	const saveElectronicSignature = (index, title) => {
		const mime = 'image/png';
		const fileName = `${title}-${index}.jpg`;
		const base64 = signatures[index].toDataURL(mime);

		dataURLToFile(base64, fileName, mime).then(async (file) => {
			if (file instanceof Error) {
				console.error(file.message);
			} else {
				update(base64, index, file);
			}
		});
	};

	const getElectronicSignature = (question, index) => {

		let image = null;
		let disabled = false;

		if (question.file) {
			image = question.file;
		} else if (question.value) {
			image = question.value;
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		return (
			<Row className='dashed-box-border'>
				<Col className='mt-2'>
					{image ? (
						<>
							<Row className='justify-content-center'>
								<Col sm={10}>
									<img className='my-2 mx-auto' src={image} style={{ maxHeight: '150px', display: 'block' }} alt='' />
								</Col>
							</Row>
							<Row>
								<Col sm='auto' className='ml-auto'>
									<IconButton
										onClick={() => {
											deleteElectronicSignature(index, question.title);
										}}
									>
										<DeleteOutlinedIcon color='disabled' />
									</IconButton>
								</Col>
							</Row>
						</>
					) : (
						<>
							<Row className='justify-content-center'>
								{!disabled && (
									<SignatureCanvas
										penColor='blue'
										canvasProps={{ className: 'EditForm-Signature-canvas' }}
										ref={(ref) => {
											setSignatureRef(index, ref);
										}}
									/>
								)}
							</Row>
							<Row>
								<Col sm='auto' className='ml-auto'>
									{image ? (
										<IconButton
											onClick={() => {
												deleteElectronicSignature(index);
											}}
											title={lan.delete}
										>
											<DeleteOutlinedIcon color='disabled' />
										</IconButton>
									) : (
										<IconButton
											onClick={() => {
												saveElectronicSignature(index, question.title);
											}}
											title={lan.save}
										>
											<SaveIcon color='disabled' />
										</IconButton>
									)}
								</Col>
							</Row>
						</>
					)}
				</Col>
			</Row>
		);
	};

	const getFile = (question, index, allowUpload, type) => {
		let image = null;
		let disabled = false;

		if (question.file) {
			// image = question.value;
			image = question.file;
		} else if (question.value) {
			image = question.value;
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		const { getRootProps, getInputProps, isDragActive } = useDropzone({
			// accept: 'image/*',
			noDrag: false,
			onDrop: async (acceptedFiles) => {
				const acceptedFile = acceptedFiles[0];

				const base64file = await toBase64(acceptedFile).catch((e) => Error(e));
				if (base64file instanceof Error) {
					console.error(base64file.message);
				} else {
					update(base64file, index, acceptedFile);
				}
			},
		});

		let label;
		if (type == 'image') {
			isDragActive
				? (label = <p>{lan.selectImageOrDragAndDrop}</p>)
				: (label = <p>{lan.clickToSelectImage}</p>);
		} else {
			isDragActive ? (label = <p>{lan.selectOrDragAndDrop}</p>) : (label = <p>{lan.clickToSelectFile}</p>);
		}

		return (
			<Row className='dashed-box-border'>
				<Col className='mt-2'>
					{allowUpload && !disabled && (
						<Row className='justify-content-center'>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								{label}
							</div>
						</Row>
					)}
					{image && (
						<>
							<Row className='justify-content-center'>
								<Col sm={10}>
									<img className='my-2 mx-auto' src={image} style={{ maxHeight: '150px', display: 'block' }} alt='' />
								</Col>
							</Row>
							<Row>
								<Col sm='auto' className='ml-auto'>
									<IconButton
										onClick={() => {
											setDeletePopup(true);
											setQuestionIndex(index);
										}}
									>
										<DeleteOutlinedIcon color='disabled' />
									</IconButton>
								</Col>
							</Row>
						</>
					)}
				</Col>
			</Row>
		);
	};

	const getBarCode = (question, index) => {
		let text = question.value;
		let disabled = false;
		if (text == null) {
			text = '';
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		return (
			<TextareaAutosize
				type='text'
				className='task-details-input'
				defaultValue={text}
				maxLength={50}
				onChange={(e) => update(e.target.value, index)}
				style={{
					width: '100%',
					paddingTop: '5px',
					paddingLeft: '10px',
					paddingBottom: '5px',
					paddingRight: '10px',
				}}
				disabled={disabled}
			/>
		);
	};

	const getDateHour = (question, index) => {
		let completeDate = question.parsedDate;
		const { dateFormat } = question;
		const hasMinutes = dateFormat.includes('HH:mm');
		let day;
		let month;
		let year;
		let hourMinute = '';
		let disabled = false;

		if (question.date) {
			const { timeZone } = props.taskData.startLocation;

			if (hasMinutes) {
				completeDate = moment(question.date).tz(timeZone).format('DD/MM/YYYY hh:mm');
			} else {
				completeDate = moment(question.date).tz(timeZone).format('DD/MM/YYYY');
			}
		}
		if (completeDate) {
			const hasSlash = completeDate.includes('/');
			// const hasSlash = dateFormat.includes('/');

			let splitter = '/';
			if (!hasSlash) {
				splitter = ' ';
			}

			const dateSplit = completeDate.split(splitter);
			const patternSplit = dateFormat.split(splitter);

			if (patternSplit[0] == 'MM' || patternSplit[0] == 'MMMM') {
				month = dateSplit[0];
				day = dateSplit[1];
				year = dateSplit[2];
			} else {
				day = dateSplit[0];
				month = dateSplit[1];
				year = dateSplit[2];
			}

			if (hasMinutes) {
				// hourMinute = completeDate.split(', ')[1];
				hourMinute = completeDate.split(' ')[1];
				year = year.slice(0, -1);
			}
		
			update(`${day}/${month}/${year}`, index, 'DATE');
			update(hourMinute, index, 'TIME');
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}

		return (
			<Row>
				<Col>
					<Cleave
						className='form-control full-width'
						placeholder='dd/MM/yyyy'
						options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
						onChange={(e) => updateDateHour(e, index, 'DATE')}
						value={`${day}/${month}/${year}`}
						disabled={disabled}
					/>
				</Col>
				{hasMinutes && (
					<Col>
						<Cleave
							className='form-control full-width'
							placeholder='HH:mm'
							options={{ time: true, timePattern: ['h', 'm'] }}
							value={hourMinute}
							onChange={(e) => updateDateHour(e, index, 'TIME')}
							disabled={disabled}
						/>
					</Col>
				)}
			</Row>
		);
	};


	const validateManagerGeneratePdf = () => {
		console.log("session")
		console.log(session)
		/*console.log("session?.accountPreferences?.allowManagerToApproveForms")
		console.log(session?.accountPreferences?.allowManagerToApproveForms)
		if (RoleUtils.isUserManager(session.user) && session?.accountPreferences?.allowManagerToApproveForms) { 
			return true;
		}
		return false;*/
		return true;
	}


	const getTextFile = (question, index, allowUpload) => {
		let file = null;
		let fileName = '';
		let disabled = false;

		if (question.file) {
			file = question.file;
			fileName = question.title;
		} else if (question.value) {
			file = question.value;
			fileName = question.fileName;
		}

		if (props.client && !question.client.edit) {
			disabled = true;
		}



		// [ ] HANDLE EMPTY FILES
		const { getRootProps, getInputProps } = useDropzone({
			accept: 'text/plain',
			onDrop: async (acceptedFiles) => {
				const acceptedFile = acceptedFiles[0];
				const base64file = await toBase64(acceptedFile).catch((e) => Error(e));

				if (base64file instanceof Error) {
					console.error('Error: ', base64file.message);
				} else {
					update(base64file.split(',')[1], index, acceptedFile.name);
				}
			},
		});

		return (
			<Row className='dashed-box-border'>
				<Col className='mt-2'>
					{allowUpload && !disabled && (
						<Row className='justify-content-center'>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<p>{lan.selectOrDragAndDrop}</p>
							</div>
						</Row>
					)}
					{file && (
						<Row className='justify-content-center'>
							<Col sm={10}>
								<Row className='justify-content-center'>
									<Col sm='auto' className='px-0 mr-1'>
										<Icon>attachment</Icon>
									</Col>
									<Col sm='auto' className='px-0'>
										<span className='my-auto mx-auto'>{fileName}</span>
									</Col>
								</Row>
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		);
	};

	const updateCatalogue = (question, index, option, selectedVal, comment, value, selectedOptions, updatedOptions) => {
		if (selectedOptions != null) {
			question.options.forEach((opt, i) => {
				question.options[i].checked = false;
			});

			selectedOptions.forEach((selOpt) => {
				question.options.some((opt, i) => {
					if (selOpt.label == opt.name) {
						question.options[i].checked = true;
						return true;
					}
				});
			});

			question.options.forEach((opt, i) => {
				if (opt.checked === false) {
					question.options[i].comment = '';
					question.options[i].value = '';
				}
			});
		} else {
			question.options.some((opt, i) => {
				if (option == opt.name) {
					if (selectedVal != null) {
						if (selectedVal === false) {
							question.options[i].comment = '';
							question.options[i].value = '';
						}
						question.options[i].checked = selectedVal;
					} else if (comment != null) {
						question.options[i].comment = comment;
					} else if (value != null) {
						question.options[i].value = value;
					}
					return true;
				}
			});
		}

		update(question.options, index, null, updatedOptions);
	};

	const getCatalogue = (question, index) => {
		const options = [];
		const selected = [];

		question.options.forEach((opt, i) => {
			const op = { value: opt, label: opt.name };
			options.push(op);

			if (opt.checked) {
				selected.push(op);
			}
		});

		const catalogueData = question.options
			.map((opt, i) => {
				if (opt.checked) {
					return (
						<Paper className='my-4' key={`Option-${opt.name}-${i}`} style={{ width: '100%', borderRadius: '10px' }}>
							<Col sm={12} className='p-4'>
								<Row className='mb-4 justify-content-center'>
									{opt.id && (
										<Col className='my-auto'>
											<TextField
												id={`txt-form-catalogue-option-id-${opt.id}`}
												style={{ width: '100%' }}
												label={lan.id}
												value={opt.id}
												disabled
											/>
										</Col>
									)}

									<Col className='my-auto'>
										<TextField
											id={`txt-form-catalogue-option-name-${opt.id || i}`}
											style={{ width: '100%' }}
											label={lan.option}
											defaultValue={opt.name}
											disabled
										/>
									</Col>
									<Col sm='auto' className='p-0'>
										<IconButton
											onClick={(e) => updateCatalogue(question, index, opt.name, false, null, null, null, true)}
										>
											<CloseIcon color='disabled' />
										</IconButton>
									</Col>
								</Row>

								<Row>
									{question.includeValue && (
										<Col className='my-auto'>
											<TextField
												id={`txt-form-catalogue-option-value-${opt.id || i}`}
												multiline
												style={{ width: '100%' }}
												label={lan.value}
												defaultValue={opt.value}
												onChange={(e) =>
													updateCatalogue(question, index, opt.name, null, null, e.target.value, null, false)
												}
											/>
										</Col>
									)}

									{question.includeComment && (
										<Col className='my-auto'>
											<TextField
												id={`txt-form-catalogue-option-comment-${opt.id || i}`}
												multiline
												style={{ width: '100%' }}
												label={lan.comment}
												defaultValue={opt.comment}
												onChange={(e) =>
													updateCatalogue(question, index, opt.name, null, e.target.value, null, null, false)
												}
											/>
										</Col>
									)}
									<Col sm={1} className='ml-auto' />
								</Row>
							</Col>
						</Paper>
					);
				}

				return null;
			})
			.filter((n) => n);

		return (
			<Col sm={12} className='mx-auto px-3 py-2'>
				<Row className='mt-3'>
					<Col sm={12}>
						<Typography variant='subtitle2'>{lan.select}</Typography>
					</Col>
					<Col sm={12}>
						<Select
							style={{ width: '100%', zIndex: '999999' }}
							styles={{
								menu: (provided) => ({ ...provided, zIndex: 9999 }),
							}}
							className='mx-auto mt-'
							isMulti
							defaultValue={selected}
							options={options}
							placeholder={lan.select}
							onChange={(e) => updateCatalogue(question, index, null, null, null, null, e, true)}
						/>
					</Col>
				</Row>
				{catalogueData}
			</Col>
		);
	};

	const GetFieldQuestionEdit = (question, index) => {

		let question_element;

		switch (parseInt(question.type)) {
			case Types.MULTIPLE_CHOICE:
				question_element = getMultipleChoice(question, index);
				break;
			case Types.TEXT_FIELD:
				question_element = getTextField(question, index);
				break;
			case Types.NUMERIC_RATING:
				question_element = getNumericRating(question, index);
				break;
			case Types.BINARY_RATING:
				question_element = getBinaryRating(question, index);
				break;
			case Types.STARS_RATING:
				question_element = getStarsRating(question, index);
				break;
			case Types.ELECTRONIC_SIGNATURE:
				question_element = getElectronicSignature(question, index);
				break;
			case Types.FILES:
				question_element = getFile(question, index, true, 'image');
				break;
			case Types.CODEBAR_IMG:
				question_element = getFile(question, index, true, 'image');
				break;
			case Types.CODEBAR_TEXT:
				question_element = getBarCode(question, index);
				break;
			case Types.DATE_HOUR:
				question_element = getDateHour(question, index);
				break;
			case Types.TEXT_FILE:
				question_element = getTextFile(question, index, true);
				break;
			case Types.CATALOGUE:
				question_element = (
					<div
						style={{
							maxWidth: '100%',
							height: '500px',
							border: 'dashed 2px #979797',
							borderRadius: '10px',
						}}
					>
						<Scrollbars>{getCatalogue(question, index)}</Scrollbars>
					</div>
				);
		}

		return (
	
		
			< Row className = 'mt-4' >
				<Col sm={10} className='ml-auto mr-5'>
					<>
						<Row>
							<Col sm={2}>{question.title}</Col>
							<Col>{question_element}</Col>
							{form && form.reviewable && (!isManager || (isManager && showApprovedForm) )  && (
								<ApprovedCheckbox
									checked={question.approved == null ? false : question.approved}
									onChange={() => updateQuestionApproved(index, question)}
									name='checkedG'
									id={'form-index' + index}
									label={lan.approveQuestion}
								/>
							)}
						</Row>
						{(!isManager || (isManager && showApprovedForm) )  && (
							<AprovedTextInfo
								form={form}
								question={question}
								index={index}
								updateQuestionApprovedReason={updateQuestionApprovedReason}
								lan={lan}
								id={'form-reason-index' + index}
							/>
						)}
					</>
				</Col>
			</Row >
		);
	};

	const GenerateFields = () => {
		if (form || asset || inventory) {
			const fields = [];

			if (form) {
				form.fields.forEach((field, i) => {
					fields.push(
						<div key={`Forms-row-${i}`} className='mt-4'>
							{GetFieldQuestionEdit(field.question, i)}
						</div>
					);
				});
			} else if (asset) {
				asset.questions.forEach((field, i) => {
					fields.push(
						<div key={`Asset-row-${i}`} className='mt-4'>
							{GetFieldQuestionEdit(field, i)}
						</div>
					);
				});
			} else if (inventory) {
				inventory.questions.forEach((field, i) => {
					fields.push(
						<div key={`Inventory-row-${i}`} className='mt-4'>
							{GetFieldQuestionEdit(field, i)}
						</div>
					);
				});
			}

			return (
				<div>
					{/* Client/Branch Name */}
					<Row className='mt-4'>
						<Col sm={10} className='ml-auto mr-5'>
							<Row>
								<Col sm='auto'>
									<b>{lan.questions}</b>
								</Col>
								<Col>
									<hr />
								</Col>
							</Row>
						</Col>
					</Row>
					{form && form.reviewable && (!isManager || (isManager && showApprovedForm) )  && (
						<Row>
							<Col sm='auto' className='mx-auto mt-3 mb-4'>
								<Button
									variant='outlined'
									startIcon={<DoneAll color='disabled' />}
									onClick={() => approveAllQuestions(true)}
									sx={{color: LightGreySubtitleColor}}
								>
									{lan.approveAllQuestions}
								</Button>
							</Col>
							<Col sm='auto' className='mx-auto mt-2 mb-4'>
								<Button
									variant='outlined'
									startIcon={<Clear color='disabled' />}
									onClick={() => approveAllQuestions(false)}
									sx={{color: LightGreySubtitleColor}}
								>
									{lan.disapproveAllQuestions}
								</Button>
							</Col>
						</Row>
					)}
					{fields}
				</div>
			);
		}
		return '';
	};

	const approveAllQuestions = (val) => {
		const newForm = form;
		setViewFields(false);

		newForm.fields = newForm.fields.map((field) => {
			field.question.approved = val;
			return field;
		});

		setForm({ ...newForm });
	};

	/**
	 *
	 * @param {*} file - File to be uploaded
	 * @param {*} prefix - folder inside the task to upload the file in (asset/inventory/form)
	 * @param {*} id - id of the form, asset or inventory
	 * @param {*} questionId - id of the question
	 * @returns
	 */
	const uploadToS3 = (file, prefix, id, questionId) => {
		const bucket = `${session.user.idAccount}/tasks/${props.taskId}/${prefix}/${id}`;
		const key = `question-${questionId}`;
		return presignedUrlForUpload(bucket, key, file, { contentType: file.type, acl: 'public-read' }, session.user.idUser);
	};

	async function asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index);
		}
	}

	const saveForm = (fields) => {
		let approvedAll = true;

		const totalFields = fields.length;

		asyncForEach(fields, async (field, i) => {
			approvedAll &= field.question.approved;

			field.question.approved = field.question.approved != null ? field.question.approved : false;

			if (
				field.question.type == Types.FILES ||
				field.question.type == Types.CODEBAR_IMG ||
				field.question.type == Types.ELECTRONIC_SIGNATURE
			) {
				if (field.question.text) {
					let dateTimeForm = new Date().getTime();
					const data = await uploadToS3(field.question.text, 'form', form.id, field.question.id+"_"+dateTimeForm).catch((e) => {
						console.error(e);
						return Error(e);
					});

					if (data instanceof Error) {
						console.error(data);
					} else {
						const newField = form.fields[i];

						newField.question.file = data;
						newField.question.value = null;
						newField.question.text = null;

						const newForm = form;
						newForm.fields[i] = newField;
						setForm({ ...newForm });
					}
				}
			} else if (field.question.type == Types.DATE_HOUR) {
				const newField = form.fields[i];

				let time;

				if (field.question.time) {
					time = field.question.time;
				} else {
					time = 0;
				}

				const { timeZone } = props.taskData.startLocation;

				const momentTime = moment(time + field.question.dateTime).tz(timeZone);
				const hourOffset = momentTime._offset;
				const offsetMilliseconds = hourOffset * 60000;

				newField.question.date = time + field.question.dateTime - offsetMilliseconds;
				newField.question.time = null;
				newField.question.dateTime = null;
				newField.question.parsedDate = null;

				const newForm = form;
				newForm.fields[i] = newField;
				setForm(newForm);
			}

			if (i == totalFields - 1) {
				if (form.reviewable) {
					form.status = approvedAll ? 'FINISHED' : 'NOT_APPROVED';
				} else {
					form.status = 'CREATED';
				}

				const body = {
					form,
					idUser: session.user.idUser,
					idTask: props.taskId,
				};


				const onSuccess = (response) => {
					props.reloadData();
					props.onClose(false);
				}

				const onError = (error) => {
					console.error(error)
				}

				const loadingConfig = { dispatch }
				updateFormFromTaskRequest(body, onSuccess, onError, loadingConfig)

			}
		});
	};

	const saveAsset = (questions) => {
		const totalQuestions = questions.length;

		asyncForEach(questions, async (question, i) => {
			if (
				question.type == Types.FILES ||
				question.type == Types.CODEBAR_IMG ||
				question.type == Types.ELECTRONIC_SIGNATURE
			) {
				if (question.text) {
					let dateTimeForm = new Date().getTime();
					const data = await uploadToS3(question.text, 'asset', asset.id, question.id+"_"+dateTimeForm).catch((e) => {
						console.error(e);
						return Error(e);
					});
					if (data instanceof Error) {
						console.error(data);
					} else {
						const newQuestion = questions[i];

						newQuestion.file = data;
						newQuestion.value = null;
						newQuestion.text = null;

						const newAsset = asset;
						newAsset.questions[i] = newQuestion;

						setAsset({ ...newAsset });
					}
				}
			} else if (question.type == Types.DATE_HOUR) {
				const newQuestion = questions[i];

				let time;

				if (question.time) {
					time = question.time;
				} else {
					time = 0;
				}
				const { timeZone } = props.taskData.startLocation;

				const momentTime = moment(time + question.dateTime).tz(timeZone);
				const hourOffset = momentTime._offset;
				const offsetMillis = hourOffset * 60000;

				newQuestion.date = time + question.dateTime - offsetMillis;
				newQuestion.time = null;
				newQuestion.dateTime = null;
				newQuestion.parsedDate = null;

				const newAsset = asset;
				newAsset.questions[i] = newQuestion;
				setAsset(newAsset);
			}

			if (i == totalQuestions - 1) {
				const body = {
					asset,
					idUser: session.user.idUser,
					idTask: props.taskId,
				};


				const onSuccess = (response) => {
					props.reloadData();
					props.onClose(false);
				}

				const onError = (error) => {
					console.log(error)
				}

				const loadingConfig = { dispatch }
				updateAssetsFromTaskRequest(body, onSuccess, onError, loadingConfig)

			}
		});
	};

	const saveInventory = (questions) => {
		const totalQuestions = questions.length;

		asyncForEach(questions, async (question, i) => {
			if (
				question.type == Types.FILES ||
				question.type == Types.CODEBAR_IMG ||
				question.type == Types.ELECTRONIC_SIGNATURE
			) {
				if (question.text) {
					let dateTimeForm = new Date().getTime();
					const data = await uploadToS3(question.text, 'inventory', inventory.id, question.id+"_"+dateTimeForm).catch((e) => {
						console.error(e);
						return Error(e);
					});

					if (data instanceof Error) {
						console.error('Error: ', data);
					} else {
						const newQuestion = questions[i];

						newQuestion.file = data;
						newQuestion.value = null;
						newQuestion.text = null;

						const newInventory = inventory;
						newInventory.questions[i] = newQuestion;
						setInventory({ ...newInventory });
					}
				}
			} else if (question.type === Types.DATE_HOUR) {
				const newQuestion = question[i];

				let time;

				if (question.time) {
					time = question.time;
				} else {
					time = 0;
				}

				const { timeZone } = props.taskData.startLocation;

				const momentTime = moment(time + question.dateTime).tz(timeZone);
				const hourOffset = momentTime._offset;
				const offsetMillis = hourOffset * 60000;

				newQuestion.date = time + question.dateTime - offsetMillis;
				newQuestion.time = null;
				newQuestion.dateTime = null;
				newQuestion.parsedDate = null;

				const newInventory = inventory;
				newInventory.questions[i] = newQuestion;
				setInventory(newInventory);
			}

			if (i == totalQuestions - 1) {
				const body = {
					inventory,
					idUser: session.user.idUser,
					idTask: props.taskId,
				};


				const onSuccess = (response) => {
					props.reloadData();
					props.onClose(false);
				}

				const onError = (error) => {
					console.log(error)
				}

				const loadingConfig = { dispatch }
				updateInventoryFromTaskRequest(body, onSuccess, onError, loadingConfig)

			}
		});
	};

	const validateSignaturesForm = (form) => {
		if(form.reviewable == false){
			return true;
		} else if (form.reviewable && form.fields.some((field) => field.question.approved == false || field.question.approved == null)) {
			setDeleteSignaturesPopup(true);
			return false;
		} else {
			return true;
		}
	}

	const save = async () => {
		if (form) {
			if(validateSignaturesForm(form)){
				saveForm(form.fields);
			}
		} else if (asset) {
			saveAsset(asset.questions);
		} else if (inventory) {
			saveInventory(inventory.questions);
		}
	};

	const showMessage = () => {
		setDeleteSignaturesPopup(false);	
	}

	const handleCloseCancel = () => {
		saveForm(form.fields);
		setDeleteSignaturesPopup(false);
	}

	const handleCloseAccept = () => {
		deleteSignatures();
		setDeleteSignaturesPopup(false);
	}

	const deleteSignatures = async () => {
	
		const promises = form.fields.map(async (field, i) => {
			if (form && field.question.file != null && field.question.type == Types.ELECTRONIC_SIGNATURE) {
				const body = {
					idTask: props.taskId,
					idUser: session.user.idUser,
					questionId: parseInt(field.question.id),
					idTaskForm: form.idTaskForm,
					accountId: props.idAccountTask,
				};
	
				try {
					await new Promise((resolve, reject) => {
						const onSuccess = async (response) => {
							await  update(null, i, null, null, true);
							saveForm(form.fields);
							resolve(response);
						};
	
						const onError = (error) => {
							console.log(error);
							reject(error);
						};
	
						const loadingConfig = { dispatch };
						deleteFormImageRequest(body, onSuccess, onError, loadingConfig);
					});
				} catch (error) {
					console.error(`Error deleting signature at index ${i}:`, error);
				}
			}
		});
	
		await Promise.all(promises);
		console.log("All signatures deleted");
	};
	

	return (
		<Popup centered width='xl' onClose={() => props.onClose(false)} closeButton>
			{deletePopup && (
				<Popup
					centered
					width='sm'
					onClose={() => {
						setDeletePopup(false);
						setQuestionIndex(null);
					}}
					closeButton
					buttonText={lan.accept}
					buttonOnClick={() => deleteImage()}
				>
					<span>{lan.deleteFormImageConfirmation}</span>
				</Popup>
			)}

			{deleteSignaturesPopup && (
				<ErrorMessage
					closeErrorMessage={showMessage}
					handleCloseCancel={handleCloseCancel}
					handleCloseAccept={handleCloseAccept}
					cancelText={lan.no}
					acceptText={lan.yes}
					showCancelButton={true}
					message={lan.deleteFormSignaturesConfirmation}
					extraMessage={lan.deleteFormSignaturesExtraMessage}
					showErrorMessage={deleteSignaturesPopup}
				/>
			)}

			<Container>
				<div style={{ height: '60%' }} className='mt-4'>
					<Row style={{ height: '70%', minHeight: '600px' }}>
						<Scrollbars
							style={{ height: '600px' }}
							renderThumbHorizontal={(scrollbarProps) => <div {...scrollbarProps} style={{ display: 'none' }} />}
						>
							{props.Header}

							{viewFields && <GenerateFields />}

							<Row className='my-3'>
								<Col sm={3} className='mx-auto'>
									<FielderButton id='save-button' onClick={save} outlined>
										{lan.save}
									</FielderButton>
								</Col>
							</Row>
						</Scrollbars>
					</Row>
				</div>
			</Container>
		</Popup>
	);
}


