import { REQUEST_TYPES } from '@core/constants/skills/skills.request.constants';
import { pipe } from 'lodash/fp';

import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

export const getSkills = async (onSuccess, onError, loading) => {
	const user = await getSelectUser();
	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.GET_SKILLS,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loading);
};

export const accountHasSkillsFunctionality = async (onSuccess, onError) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		idUser: user.idUser,
		requestType: REQUEST_TYPES.GET_SKILLS_ACTIVE_IN_ACCOUNT,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError);
}

export const postAddSkillsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.DO_ADD_ACCOUNT_SKILLS,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
}

/** GET ACCOUNT SKILLS * */
export const getAllActionsSkillsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};
