import { REQUEST_TYPES } from '@core/constants/masterUser/masterUser.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';


export const getAccoutsForMaterUserRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_ACCOUNTS_VIEW,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getBillingInformationRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	let billingDate = null;
	let idAccount = null;
	if (body) {
		if (body.billingDate) {
			billingDate = body.billingDate;
		}
		idAccount = body.idAccount;
	} else {
		idAccount = session.user.idAccount;
	}

	const requestBody = {
		requestType: REQUEST_TYPES.GET_BILLING_INFO_VIEW,
		idUser: user.idUser,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getCountUserByAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_COUNT_USERS_BY_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};



export const getCountTaskByAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_COUNT_TASK_BY_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getAccountDetailRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_ACCOUNT_DETAIL,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateLocationPreferencesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.UPDATE_ACCOUNT_LOCATION_PREFERENCE,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateBillingPreferencesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.UPDATE_ACCOUNT_BILLING_PREFERENCES,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const enableAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ENABLE_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const disableAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DISABLE_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getHistoryListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_BILLING_HISTORY,
		idUser: user.idUser,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getLogsListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const addDomainAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ADD_DOMAIN_TO_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const enableDisableMfaRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ENABLE_DISABLE_MFA,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};

export const enableDisableMfaAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ENABLE_DISABLE_AGENT_MFA,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getGlobalAccountsListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_GLOBAL_ACCOUNTS_VIEW,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getGlobalAccountInformationRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_GLOBAL_ACCOUNT_INFO,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const sendEmailToGlobalAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.SEND_EMAIL_TO_GLOBAL_USER,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};



export const activateGlobalUserRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_ENABLE_GLOBAL_USER,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const desactivateGlobalUserRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_DISABLE_GLOBAL_USER,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};

export const addAccountToGlobalAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ADD_ACCOUNT_TO_GLOBAL_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const createGlobalAccountRequest = async (body, onSuccess, onError, loadingConfig) => {

	const requestBody = {
		requestType: REQUEST_TYPES.CREATE_GLOBAL_ACCOUNT,
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getCountryCodeListRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_COUNTRIES_CODES,
		user: {
			role: user.role,
			userName: user.userName,
		}
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getClientUserByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_CLIENT_USER_BY_ID,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const addUserToGlobalAccountRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ADD_USER_TO_GLOBAL_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getGlobalUserInformationByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_GLOBAL_USER_BY_ID,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateGlobalUserRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_GLOBAL_USER,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const showAlertNonPayment = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.SHOW_ALERT_NON_PAYMENT,
		user: {
			role: user.role,
			userName: user.userName,
		},
		...body
	};
	
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getBillingSummaryInformationRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_BILLING_SUMMARY,
		idAccount: user.idAccount,
		idUser: user.idUser,
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateAccountModule = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_MODULE_ACCOUNT,
		user: {
			role: user.role,
			userName: user.userName,
			idUser: user.idUser,
			idAccount: user.idAccount
		},
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};