const UPDATED                   = -1; // Used for unknown modifications
const CREATE_BRANCH             = 0;
const EDIT_BRANCH               = 1;
const UPDATE_LOCATION           = 2;
const DISABLE_BRANCH            = 3;
const ENABLE_BRANCH             = 4;
const CREATE_BRANCH_API         = 5;
const API_UPDATE                = 6;
const CREATE_BRANCH_MOBILE      = 7;
const IMPORTED_BRANCH           = 8;
const UPDATE_LOCATION_REQUEST   = 9;
const DENIED_LOCATION_UPDATE    = 10;

const UPDATED_STR                   = 'UPDATED';
const CREATE_BRANCH_STR             = 'CREATE_BRANCH';
const EDIT_BRANCH_STR               = 'EDIT_BRANCH';
const UPDATE_LOCATION_STR           = 'UPDATE_LOCATION';
const DISABLE_BRANCH_STR            = 'DISABLE_BRANCH';
const ENABLE_BRANCH_STR             = 'ENABLE_BRANCH';
const CREATE_BRANCH_API_STR         = 'CREATE_BRANCH_API';
const API_UPDATE_STR                = 'API_UPDATE';
const CREATE_BRANCH_MOBILE_STR      = 'CREATE_BRANCH_MOBILE';
const IMPORTED_BRANCH_STR           = 'IMPORTED_BRANCH';
const UPDATE_LOCATION_REQUEST_STR   = 'UPDATE_LOCATION_REQUEST';
const DENIED_LOCATION_UPDATE_STR    = 'DENIED_LOCATION_UPDATE';

export default {
    UPDATED,
    CREATE_BRANCH,
    EDIT_BRANCH,
    UPDATE_LOCATION,
    DISABLE_BRANCH,
    ENABLE_BRANCH,
    CREATE_BRANCH_API,
    API_UPDATE,
    CREATE_BRANCH_MOBILE,
    IMPORTED_BRANCH,
    UPDATE_LOCATION_REQUEST,
    DENIED_LOCATION_UPDATE,

    UPDATED_STR,
    CREATE_BRANCH_STR,
    EDIT_BRANCH_STR,
    UPDATE_LOCATION_STR,
    DISABLE_BRANCH_STR,
    ENABLE_BRANCH_STR,
    CREATE_BRANCH_API_STR,
    API_UPDATE_STR,
    CREATE_BRANCH_MOBILE_STR,
    IMPORTED_BRANCH_STR,
    UPDATE_LOCATION_REQUEST_STR,
    DENIED_LOCATION_UPDATE_STR,
}