import React , { useState } from 'react';

import AssetIcon from '@assets/icons/assets-icon.svg';
import { makeStyles } from '@mui/styles';
import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import taskConst from 'FielderUtils/roleManagementConst/taskConst';
import ModulesNames from 'FielderUtils/module/moduleNamesConst.js';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadFormsIcon from '@assets/icons/donwload-fom-icon.svg';
import DownloadFormsImages from '@assets/icons/download-form-images.svg';
import FormIcon from '@assets/icons/form-icon.svg';
import ExportCalendarIcon from '@assets/icons/calendar-time-icon.svg';
import ImportIcon from '@assets/icons/export-icon.svg';
import InventoryIcon from '@assets/icons/inventory-icon.svg';
import ExportIcon from '@assets/icons/import-icon.svg';
import { Fade, IconButton, Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from 'moment-timezone';
import LoadingConst from 'OldComponents/Loading/LoadingConst';
import { taskForDowloadModulesFormater } from 'FielderUtils/task/taskForDowloadModulesFormater.js';
import { getExportTasksQueryParams } from 'FielderUtils/task/exportTaskQueryParams.js';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';
import GenerateExportTaskPopup from '../popups/generate-export-task-popup';
import GenerateReportsPopup from '../popups/generate-report-popup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLanguage } from 'Context/LanguageContext';
import Collapse from '@mui/material/Collapse';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import useValidateRoles from '@core/utils/roles/useValidateRoles';
import { selectUser, selectSession } from '@core/store/modules/session/sessionSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import FormTypes from 'FielderUtils/forms/types';
import {
	isClientToExport,
	isClientToImport,
	isFormsPermissionValidWitManager,
	isInventoryPermissionValidWithManager,
	isAssetPermissionValidWithManager,
	isManagerToExport,
	isManagerToImport,
} from 'FielderUtils/validatePermissions';
import ImportTaskPopup from '../popups/import-task-popup';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';

import { getExportTaskModulesOrFormsApprovedList } from '@core/api/task/task';
import { getExportTaskModulesCount } from 'FielderUtils/exportUtil/exportUtil';
import { getFormHTMLRequest, getPdfFromHtmlRequest, getXLSXOrHTMLFromFormRequest } from '@core/api/form/form.service';
import { getInventoryHTMLRequest } from '@core/api/inventory/inventory.service';
import { getAssetHTMLRequest } from '@core/api/asset/asset.service';
import model from 'Object/model/model.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getTaskCalendarXlsxRequest } from '@core/api/task/task.service';

const IMPORT_TASKS_POPUP = 0;
const ERROR_POPUP = 1;

const DOWNLOAD_FORMS = 0;
const DOWNLOAD_FORMS_IMAGES = 1;
const DOWNLOAD_ASSETS = 2;
const DOWNLOAD_INVENTORY = 3;
const DOWNLOAD_APPROVED_FORMS =  4;

const XLSX_EXTENSION = 'xlsx';
const PDF_EXTENSION = 'pdf';

const TASK_BATCH_SIZE = 50;
const CHUNK_SIZE = 5;

const REQUEST_EXPORT_CALENDAR_TASKS = 'GET_TASK_TO_EXPORT_AS_CALENDAR';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

const TaskExportImportMenu = (props) => {

	const classes = useStyles();
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const { isRoleValid } = useValidateRoles();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [showPopup, setShowPopup] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const session = useSelector(selectSession);
	const showForms = session && session.accountPreferences ? session.accountPreferences.showForms : false;
	const showInventory = session && session.accountPreferences ? session.accountPreferences.showInventory : false;
	const showAsset = session && session.accountPreferences ? session.accountPreferences.showAsset : false;

	const allowClientToExport = session && session.accountPreferences ? session.accountPreferences.allowClientToExport : false;
	const allowClientToImport = session && session.accountPreferences ? session.accountPreferences.allowClientToImport : false;

	const allowManagerToExport =  session && session.accountPreferences ? session.accountPreferences.allowManagerDownloadTask : false;					
	const allowManagerToImport =  session && session.accountPreferences ? session.accountPreferences.allowManagerToImportTask : false;
	const showCalendarDownload = false;


	const [viewFormsMenu, setViewFormsMenu] = useState(false);
	const [viewAssetsMenu, setViewAssetsMenu] = useState(false);
	const [viewInventoryMenu, setViewInventoryMenu] = useState(false);
	const [generateReportForms, setGenerateReportForms] = useState(false);
	const [generateTasksToExport, setGenerateTasksToExport] = useState(false);
	const [generateReportAssets, setGenerateReportAssets] = useState(false);
	const [generateReportInventory, setGenerateReportInventory] = useState(false);

	const handlePopups = (popup) => {
		showPopup === popup ? setShowPopup(null) : setShowPopup(popup);
		setMenuAnchor(null);
	};

	const hasDateFilter = () => props.timeFrom && props.timeTo;
	const handleGenerateTaskReportPopup = (val) => {
		setGenerateTasksToExport(val);
		setMenuAnchor(null);
	};

	const handleExportCalendarExcel = () => {
		const isDateFilter = hasDateFilter();
		props.setDatesError(!isDateFilter);
		if (props.timeFrom != null && props.timeTo != null) {
			getTasksToExportCalendarExcel(0, 1, []);

			dispatch(
				loadingStart({
					name: 'EXCEL_CALENDAR',
					config: {
						icon: LoadingConst.EXPORT,
						text: lan.exportMessage,
						progress: 0,
					},
				})
			);
		}
		setMenuAnchor(null);
	};

	const getTasksToExportCalendarExcel = (taskOffset, totalCount, data) => {
		const limit = 10;

		if (taskOffset >= totalCount) {
			dispatch(
				loadingStart({
					name: 'EXCEL_CALENDAR',
					config: {
						icon: LoadingConst.EXPORT,
						text: lan.exportMessage,
						progress: 100,
					},
				})
			);
			downloadExcelCalendar(data);
		} else {
			const dataViewTask = model.getExportCalendarTask(
				REQUEST_EXPORT_CALENDAR_TASKS,
				taskOffset,
				limit,
				timeFrom.getDate(),
				timeFrom.getMonth(),
				timeFrom.getFullYear(),
				timeTo.getDate(),
				timeTo.getMonth(),
				timeTo.getFullYear()
			);

			getTaskList(
				dataViewTask,
				(res) => {
					if (res && res.success == true) {
						if (res.success && res.tasksExcelCalendar && res.tasksExcelCalendar.length > 0 && res.totalTasks > 0) {
							data = data.concat(res.tasksExcelCalendar);
							taskOffset += limit;
							const progress = (taskOffset * 100) / res.totalTasks;

							dispatch(
								loadingStart({
									name: 'EXCEL_CALENDAR',
									config: {
										icon: LoadingConst.EXPORT,
										text: lan.exportMessage,
										progress,
									},
								})
							);
							getTasksToExportCalendarExcel(taskOffset, res.totalTasks, data);
						} else if (data && data.length > 0) {
							downloadExcelCalendar(data);

							dispatch(
								loadingStart({
									name: 'EXCEL_CALENDAR',
									config: {
										icon: LoadingConst.EXPORT,
										text: lan.exportMessage,
										progress: 100,
									},
								})
							);
						} else {
							dispatch(loadingStop({ name: 'EXCEL_CALENDAR' }));
						}
					}
				},
				null
			);
		}
	};

	const downloadExcelCalendar = (tasks) => {
		const date = moment().format('MMMM DD, YYYY hh:mm a');
		const body = { date, tasks };

		const onSuccess = ({ link }) => window.open(link);
		const onError = (error) => console.log(error);

		dispatch(loadingStop({ name: 'EXCEL_CALENDAR' }));

		const loadingConfig = { dispatch };
		getTaskCalendarXlsxRequest(body, onSuccess, onError, loadingConfig);
	};


	const changeSubMenuVisibility = (type) => {
		if (type === ModulesNames.FORMS) {
			setViewFormsMenu(!viewFormsMenu);
			setViewAssetsMenu(false);
			setViewInventoryMenu(false);
		} else if (type === ModulesNames.ASSETS) {
			setViewFormsMenu(false);
			setViewAssetsMenu(!viewAssetsMenu);
			setViewInventoryMenu(false);
		} else if (type === ModulesNames.INVENTORIES) {
			setViewFormsMenu(false);
			setViewAssetsMenu(false);
			setViewInventoryMenu(!viewInventoryMenu);
		}
	};

	const getFilter = () => {
		let tags = [];
		let filter = '';

		if (props.filter) {
			const splitted = props.filter.split(' ');
			for (let i = 0; i < splitted.length; i++) {
				const filter_splitted = splitted[i];

				if (filter_splitted.includes('#')) {
					tags.push(filter_splitted.slice(1));
				} else {
					filter += ` ${filter_splitted}`;
				}
			}
		}

		if (tags.length === 0) {
			tags = null;
		}

		if (filter === '') {
			filter = null;
		}

		return { filter, tags };
	};

	const handleExportModules = (moduleName) => {
		const isDateFilter = hasDateFilter();
		props.setDatesError(!isDateFilter);

		if (props.timeFrom != null && props.timeTo != null) {
			let moduleToDownload = DOWNLOAD_FORMS;

			if (moduleName === ModulesNames.FORMS) {
				moduleToDownload = DOWNLOAD_FORMS;
			} else if (moduleName === ModulesNames.APPROVED_FORMS) {
				moduleToDownload = DOWNLOAD_APPROVED_FORMS;
			} else if (moduleName === ModulesNames.ASSETS) {
				moduleToDownload = DOWNLOAD_ASSETS;
			} else if (moduleName === ModulesNames.INVENTORIES) {
				moduleToDownload = DOWNLOAD_INVENTORY;
			} else if (moduleName == ModulesNames.FORMS_IMAGES) {
				moduleToDownload = DOWNLOAD_FORMS_IMAGES;
			}

			getTasksForExportModules(moduleToDownload, moduleName);
		}

		setMenuAnchor(null);
	};

	const generateParams = (bringTasks, offset, limit, moduleName) => {
		const taskFilter = getFilter();

		return getExportTasksQueryParams(
			props.timeFrom.getDate(),
			props.timeFrom.getMonth(),
			props.timeFrom.getFullYear(),
			props.timeTo.getDate(),
			props.timeTo.getMonth(),
			props.timeTo.getFullYear(),
			taskFilter.filter,
			taskFilter.tags,
			props.datesFilterValues,
			null,
			props.advancedFilterValues,
			offset,
			limit,
			user.idUser,
			bringTasks,
			moduleName
		);
	};

	const getTasksForExportModules = async (moduleToDownload, moduleName) => {
		dispatch(
			loadingStart({
				name: 'EXPORT_MODULES',
				config: {
					icon: LoadingConst.EXPORT,
					text: lan.exportMessage,
					progress: 0,
				},
			})
		);

		const queryExportParams = generateParams(false, 0, 0, moduleName);
		const totalTasks = await getExportTaskModulesCount(queryExportParams, moduleName);
		const totalRequests = Math.ceil(totalTasks / TASK_BATCH_SIZE);
		const requestsArray = Array.from({ length: totalRequests }, (v, k) => k);
		let data = [];
		let progress = 0;

		for (let i = 0; i < requestsArray.length; i += CHUNK_SIZE) {
			const chunk = requestsArray.slice(i, i + CHUNK_SIZE);

			await Promise.all(
				chunk.map((i) => {
					const offset = i * TASK_BATCH_SIZE;
					progress = (offset * 100) / totalTasks;

					dispatch(
						loadingStart({
							name: 'EXPORT_MODULES',
							config: {
								icon: LoadingConst.EXPORT,
								text: lan.exportMessage,
								progress,
							},
						})
					);

					const onSuccess = (res) => {
						data = data.concat(res.tasks);
					};

					const onError = () => {
						console.log("ERROR")
					
						dispatch(loadingStop({ name: 'EXPORT_MODULES' }));
					};
					const queryExportParamsTask = generateParams(true, offset, TASK_BATCH_SIZE, moduleName);
					return getExportTaskModulesOrFormsApprovedList(queryExportParamsTask, onSuccess, onError, moduleName);
				})
			);
		}
		downloadTaskData(data, moduleToDownload);
	};

	const downloadTaskData = (tasks, moduleToDownload) => {
		if (moduleToDownload == DOWNLOAD_FORMS) {
			downloadFormsFromTasks(tasks);
		} else if (moduleToDownload == DOWNLOAD_APPROVED_FORMS) {
			downloadApprovedFormFromTasks(tasks);
		} else if (moduleToDownload === DOWNLOAD_FORMS_IMAGES) {
			downloadImagesFromForm(tasks);
		} else if (moduleToDownload === DOWNLOAD_ASSETS) {
			downloadAssetFromTasks(tasks);
		} else if (moduleToDownload === DOWNLOAD_INVENTORY) {
			downloadInventoryFromTasks(tasks);
		}
	};

	const downloadFormsFromTasks = async (tasks) => {
		try {
			
		
		dispatch(
			loadingStart({
				name: 'EXPORT_MODULES',
				config: {
					icon: LoadingConst.EXPORT,
					text: lan.exportingModules,
					progress: 100,
				},
			})
		);

		const zip = new JSZip();
		let counter = 0;

		const batchSize = 6;
		const taskPromises = [];

		for (let i = 0; i < tasks.length; i += batchSize) {
			const batch = tasks.slice(i, i + batchSize);

			const batchPromises = batch.map(async (task) => {
				counter++;
				const formattedTask = taskForDowloadModulesFormater(task);

				if (formattedTask.forms != null) {
					const { forms } = formattedTask;
					formattedTask.forms = null;
					console.log("forms");
					console.log(forms);

					const formPromises = forms.map(async (form) => {
						console.log("form");
						console.log(form);
						const formFilePDF = await generateFormPDF(form, formattedTask, formattedTask.agent);
						console.log("formFilePDF");
						console.log(formFilePDF);
						await setFileToZip(formattedTask.idTask, formFilePDF, `${lan.form}-${form.name}`, zip, PDF_EXTENSION);

						if (form.templateType && form.templateType === XLSX_EXTENSION) {
							const formFileXLSX = await generateFormXlsx(form, formattedTask, formattedTask.agent);
							await setFileToZip(formattedTask.idTask, formFileXLSX, `${lan.form}-${XLSX_EXTENSION}-${form.name}`, zip, XLSX_EXTENSION);
						}
					});

					await Promise.all(formPromises);
				}
				dispatch(
					loadingStart({
						name: 'EXPORT_MODULES',
						config: {
							icon: LoadingConst.EXPORT,
							text: lan.exportingModules,
							progress: Math.ceil((counter * 100) / tasks.length),
						},
					})
				);
			});

			taskPromises.push(...batchPromises);

			// Wait for the batch to complete before moving to the next batch
			await Promise.all(batchPromises);
			
		}

		// Wait for all tasks to complete
		await Promise.all(taskPromises);

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `${lan.forms}-${moment().format('DD-MM-YYYY')}.zip`);
			dispatch(
				loadingStop({
					name: 'EXPORT_MODULES',
				})
			);
		});

		} catch (error) {
				console.log("error", error);
		}
	};

	const generateFormPDF = async (form, task, agent) => {
		const reqAgent = {
			name: '',
		};

		if (agent) {
			reqAgent.name = `${agent.name} ${agent.lastName}`;
		}

		let body = {
			idTask: task.idTask,
			task,
			agent: reqAgent,
			form,
			lan: lan._language,
			idCompany: task.branch.company.company_id
		};

		const link = await new Promise((resolve, reject) => {
			const onSuccess = (res) => {
				const bodyHtml = {
					idTask: task.idTask,
					htmlUrlFile: res.link,
					footerHtml: res.footer_link,
					filename: `${task.idTask}-${Math.random().toString(36).slice(2)}-pdf`,
					idAccount: task.idAccount,
					idCompany: task.branch.company.company_id
				};

				const onSuccessHtml = (response) => {
					resolve(response.pdf);
				};
				const onErrorHtml = (error) => {
					console.log(error);
					reject();
				};

				getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, null);
			};

			const onError = reject;
			getFormHTMLRequest(body, onSuccess, onError, null);
		});

		return link;
	};

	const generateFormXlsx = async (form, task, agent) => {
		const reqAgent = {
			name: '',
		};

		if (agent) {
			reqAgent.name = `${agent.name} ${agent.lastName}`;
		}

		let body = {
			idTask: task.idTask,
			task,
			agent: reqAgent,
			form,
			lan: lan._language,
			idCompany: task.branch.company.company_id
		};

		const link = await new Promise((resolve, reject) => {
			const onSuccess = (res) => {
				resolve(res.link);
			};

			const onError = reject;
			getXLSXOrHTMLFromFormRequest(body, onSuccess, onError, null);
		});

		return link;
	};


	const setFileToZip = async (idTask, fileLink, fileName, zip, extension) => {
		await new Promise((resolve, reject) => {
			const fileLinkAux = fileLink.split(process.env.FIELDER_CONTENT_LINK);
			const fileUrl = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${fileLinkAux[1]}`;

			JSZipUtils.getBinaryContent(fileUrl, (err, data) => {
				const newFileName = `${idTask}-${fileName}.${extension}`;

				// Add File To Zip
				zip.file(newFileName, data, { binary: true });
				resolve();
			});
		});
	};



	/*const downloadApprovedFormFromTasks = async (tasks) => {
		const zip = new JSZip();
		let counter = 0;

		const batchSize = 5;
		let promises = [];

		for (let i = 0; i < tasks.length; i += batchSize) {
			const batch = tasks.slice(i, i + batchSize);

			const batchPromises = batch.map(async (task) => {
				counter++;

				let fileLink = task.fileUrl;
				let documentName = task.documentName && task.documentName !="" ? task.documentName : lan.document+"-"+task.idTask+".pdf" ;

				if (fileLink && fileLink !="" ) {

					if (fileLink.includes(process.env.FIELDER_CONTENT_LINK)) {
						const fileLinkAux = fileLink.split(process.env.FIELDER_CONTENT_LINK);
						fileLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${fileLinkAux[1]}`;
					}

					await setApprovedFormToZip(documentName, fileLink, zip);

					dispatch(
						loadingStart({
							name: 'EXPORT_MODULES',
							config: {
								icon: LoadingConst.EXPORT,
								text: lan.exportMessage,
								progress: Math.ceil((counter * 100) / tasks.length),
							},
						})
					);

				}
			});

			promises.push(...batchPromises);
		}

		await Promise.all(promises);

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `${lan.approvedForms}-${moment().format('DD-MM-YYYY')}.zip`);
			dispatch(
				loadingStop({
					name: 'EXPORT_MODULES',
				})
			);
		});

	}*/

	const downloadApprovedFormFromTasks = async (tasksLinks) => {
		const splitTasks = [];

  		
		for (let i = 0; i < tasksLinks.length; i += 2000) {
			splitTasks.push(tasksLinks.slice(i, i + 2000));
		}
		
		for (const element of splitTasks) {

			const tasks = element;
			const zip = new JSZip();

			const batchSize = 5;
			let counter = 0;
			let promises = [];

			for (let i = 0; i < tasks.length; i += batchSize) {
				const batch = tasks.slice(i, i + batchSize);

				const batchPromises = batch.map(async (task) => {
					counter++;

					let fileLink = task.fileUrl;

					let documentName = task.documentName && task.documentName != "" ? task.idTask + "-" + counter + "-" + task.documentName : task.idTask + "-" + lan.document + ".pdf";

					if (fileLink && fileLink != "") {

						if (fileLink.includes(process.env.FIELDER_CONTENT_LINK)) {
							const fileLinkAux = fileLink.split(process.env.FIELDER_CONTENT_LINK);
							fileLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${fileLinkAux[1]}`;
						}

						await setApprovedFormToZip(documentName, fileLink, zip);

						dispatch(
							loadingStart({
								name: 'EXPORT_MODULES',
								config: {
									icon: LoadingConst.EXPORT,
									text: lan.exportMessage,
									progress: Math.ceil((counter * 100) / tasks.length),
								},
							})
						);

					}
				});

				promises.push(...batchPromises);
			}

			await Promise.all(promises);

			await generateAndSaveZip(zip);
				
		}
		dispatch(
			loadingStop({
				name: 'EXPORT_MODULES',
			})
		);
	}

	const generateAndSaveZip = async (zip) => {
		try {
			const content = await new Promise((resolve, reject) => {
				zip.generateAsync({ type: 'blob' })
					.then(resolve)
					.catch(reject);
			});
	  
			saveAs(content, `${lan.approvedForms}-${moment().format('DD-MM-YYYY')}.zip`);
		} catch (error) {
			console.error(error);
		}
	}



	const setApprovedFormToZip = async (fileName, link, zip) => {
		await new Promise((resolve, reject) => {
			JSZipUtils.getBinaryContent(link, (err, data) => {
				zip.file(fileName, data, { binary: true });
				resolve();
			});
		});
	};



	const downloadImagesFromForm = async (tasks) => {
		dispatch(
			loadingStart({
				name: 'EXPORT_MODULES',
				config: {
					icon: LoadingConst.EXPORT,
					text: lan.exportingModules,
					progress: 100,
				},
			})
		);

		const links = [];
		let counter = 0;

		for (var i = 0; i < tasks.length; i++) {
			counter++;
			const task = taskForDowloadModulesFormater(tasks[i]);

			if (task.forms != null) {
				task.forms.forEach((form) => {
					form.fields.forEach((field) => {
						if (
							(field.question.type == FormTypes.CODEBAR_IMG || field.question.type == FormTypes.FILES) &&
							field.question.file != null &&
							field.question.file != ''
						) {
							links.push({
								idTask: task.idTask,
								form: form.name,
								question: field.question.title,
								link: field.question.file,
							});
						}
					});
				});
			}

			dispatch(
				loadingStart({
					name: 'EXPORT_MODULES',
					config: {
						icon: LoadingConst.EXPORT,
						text: lan.exportingModules,
						progress: Math.ceil((counter * 100) / tasks.length),
					},
				})
			);
		}

		const zip = new JSZip();
		counter = 0;


		const batchSize = 5;
		const promises = [];

		for (let i = 0; i < links.length; i += batchSize) {
			const batch = links.slice(i, i + batchSize);

			const batchPromises = batch.map(async (link) => {
				counter++;
				const fileName = `${link.idTask}-${link.form}-${link.question}`;
				let fileLink = link.link;

				if (fileLink.includes(process.env.FIELDER_CONTENT_LINK)) {
					const fileLinkAux = fileLink.split(process.env.FIELDER_CONTENT_LINK);
					fileLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${fileLinkAux[1]}`;
				}

				await setFormImagesToZip(fileName, fileLink, zip);

				dispatch(
					loadingStart({
						name: 'EXPORT_MODULES',
						config: {
							icon: LoadingConst.EXPORT,
							text: lan.exportMessage,
							progress: Math.ceil((counter * 100) / links.length),
						},
					})
				);
			});

			promises.push(...batchPromises);
		}

		await Promise.all(promises);



		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `${lan.images}-${moment().format('DD-MM-YYYY')}.zip`);
			dispatch(
				loadingStop({
					name: 'EXPORT_MODULES',
				})
			);
		});
	};



	const setFormImagesToZip = async (fileName, link, zip) => {
		await new Promise((resolve, reject) => {
			JSZipUtils.getBinaryContent(link, (err, data) => {
				if (link.includes('.png')) {
					fileName += '.png';
				} else if (link.includes('.jpeg')) {
					fileName += '.jpeg';
				} else {
					fileName += '.jpg';
				}

				zip.file(fileName, data, { binary: true });
				resolve();
			});
		});
	};


	const downloadAssetFromTasks = async (tasks) => {
		dispatch(
			loadingStart({
				name: 'EXPORT_MODULES',
				config: {
					icon: LoadingConst.EXPORT,
					text: lan.exportingModules,
					progress: 100,
				},
			})
		);

		const zip = new JSZip();
		let counter = 0;

		for (let i = 0; i < tasks.length; i++) {
			counter++;
			const task = taskForDowloadModulesFormater(tasks[i]);

			if (task.assets != null) {
				const { assets } = task;

				await Promise.all(
					assets.map(async (asset) => {
						const assetFile = await generateAssetPDF(asset, task, task.agent);
						await setFileToZip(task.idTask, assetFile, `${lan.asset}-${asset.name}`, zip, PDF_EXTENSION);
					})
				);
			}

			dispatch(
				loadingStart({
					name: 'EXPORT_MODULES',
					config: {
						icon: LoadingConst.EXPORT,
						text: lan.exportingModules,
						progress: Math.ceil((counter * 100) / tasks.length),
					},
				})
			);
		}

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `${lan.assets}-${moment().format('DD-MM-YYYY')}.zip`);
			dispatch(loadingStop({ name: 'EXPORT_MODULES' }));
		});
	};


	const generateAssetPDF = async (asset, task, agent) => {
		const reqAgent = { name: '' };

		if (agent) {
			reqAgent.name = `${agent.name} ${agent.lastName}`;
		}

		const bodyAsset = JSON.parse(JSON.stringify(asset));
		bodyAsset.logs = [];
		bodyAsset.category = asset.category.name;

		let body = {
			idTask: task.idTask,
			task,
			agent: reqAgent,
			asset: bodyAsset,
			lan: lan._language,
		};

		const link = await new Promise((resolve, reject) => {
			const onSuccess = (res) => {
				const bodyHtml = {
					idTask: task.idTask,
					htmlUrlFile: res.link,
					filename: `${task.idTask}-${Math.random().toString(36).slice(2)}-pdf`,
					idAccount: task.idAccount,
				};

				const onSuccessHtml = (response) => {
					resolve(response.pdf);
				};
				const onErrorHtml = (error) => {
					console.log(error);
					reject();
				};

				getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, null);
			};

			const onError = (error) => {
				console.log(error);
				reject();
			};

			getAssetHTMLRequest(body, onSuccess, onError, null);
		}).catch(() => {});

		return link;
	};




	const downloadInventoryFromTasks = async (tasks) => {
		dispatch(
			loadingStart({
				name: 'EXPORT_MODULES',
				config: {
					icon: LoadingConst.EXPORT,
					text: lan.exportingModules,
					progress: 100,
				},
			})
		);

		const zip = new JSZip();
		let counter = 0;

		for (const element of tasks) {
			counter++;
			const task = taskForDowloadModulesFormater(element);

			if (task.inventories != null) {
				const { inventories } = task;

				await Promise.all(
					inventories.map(async (inventory) => {
						const inventoryFile = await generateInventoryPDF(inventory, task, task.agent);
						await setFileToZip(task.idTask, inventoryFile, `${lan.inventory}-${inventory.name}`, zip, PDF_EXTENSION);
					})
				);
			}

			dispatch(
				loadingStart({
					name: 'EXPORT_MODULES',
					config: {
						icon: LoadingConst.EXPORT,
						text: lan.exportMessage,
						progress: Math.ceil((counter * 100) / tasks.length),
					},
				})
			);
		}

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `${lan.inventories}-${moment().format('DD-MM-YYYY')}.zip`);
			dispatch(
				loadingStop({
					name: 'EXPORT_MODULES',
				})
			);
		});
	};


	const generateInventoryPDF = async (inventory, task, agent) => {
		const reqAgent = { name: '' };

		if (agent) {
			reqAgent.name = `${agent.name} ${agent.lastName}`;
		}

		const bodyInventory = JSON.parse(JSON.stringify(inventory));
		bodyInventory.logs = [];
		bodyInventory.category = inventory.category.name;

		let body = {
			idTask: task.idTask,
			task,
			agent: reqAgent,
			inventory: bodyInventory,
			lan: lan._language,
		};

		const link = await new Promise((resolve, reject) => {
			const onSuccess = (res) => {
				const bodyHtml = {
					idTask: task.idTask,
					htmlUrlFile: res.link,
					filename: `${task.idTask}-${Math.random().toString(36).slice(2)}-pdf`,
					idAccount: task.idAccount,
				};

				const onSuccessHtml = (response) => {
					resolve(response.pdf);
				};
				const onErrorHtml = (error) => {
					console.log(error);
					reject();
				};

				getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, null);
			};

			const onError = (error) => {
				console.log(error);
				reject();
			};

			getInventoryHTMLRequest(body, onSuccess, onError, null);
		});

		return link;
	};

	const AssetMenu = () => {

		return (
			<>
				<MenuItem onClick={() => changeSubMenuVisibility(ModulesNames.ASSETS)}>
					<ListItemIcon>
						<img id='MyOperation-export-icon' src={AssetIcon} className='icon Fielder-icon-button' alt='' />
					</ListItemIcon>
					<ListItemText primary={lan.assets} />
					{viewAssetsMenu ? <ExpandLess /> : <ExpandMore />}
				</MenuItem>
				<Collapse in={viewAssetsMenu} timeout='auto' unmountOnExit>
					<MenuItem
						onClick={() => handleExportModules(ModulesNames.ASSETS)}
						className={classes.nested}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<img id='MyOperation-export-icon' src={DownloadFormsIcon} className='icon Fielder-icon-button' alt='' />
						</ListItemIcon>
						<ListItemText primary={lan.downloadAssets} />
					</MenuItem>

					<MenuItem
						onClick={() => handleGenerateReportPopup(true, ModulesNames.ASSETS)}
						className={classes.nested}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<BallotOutlinedIcon color='disabled' fontSize='small' />
						</ListItemIcon>
						<ListItemText primary={lan.generateAssetReport} />
					</MenuItem>
				</Collapse>
			</>
		);
	};

	const FormsMenu = () => {

		return (
			<>
				<MenuItem onClick={() => changeSubMenuVisibility(ModulesNames.FORMS)}>
					<ListItemIcon>
						<img id='MyOperation-export-icon' src={FormIcon} className='icon Fielder-icon-button' alt='' />
					</ListItemIcon>
					<ListItemText primary={lan.forms} />
					{viewFormsMenu ? <ExpandLess /> : <ExpandMore />}
				</MenuItem>
				<Collapse in={viewFormsMenu} timeout='auto' unmountOnExit>
					<MenuItem
						className={classes.nested}
						onClick={() => handleExportModules(ModulesNames.FORMS)}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<img id='MyOperation-export-icon' src={DownloadFormsIcon} className='icon Fielder-icon-button' alt='' />
						</ListItemIcon>
						<ListItemText primary={lan.downloadForms} />
						<ListItemIcon>
							<Tooltip title={lan.formDownloadInfo} arrow placement='left'>	
								<InfoOutlinedIcon className='ml-2'/>
							</Tooltip>	
						</ListItemIcon>					
					</MenuItem>

					<MenuItem
						className={classes.nested}
						onClick={() => handleExportModules(ModulesNames.APPROVED_FORMS)}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<PictureAsPdfIcon className='icon Fielder-icon-button' alt='' />
						</ListItemIcon>
						<ListItemText primary={lan.downloadApprovedForms} />
						<ListItemIcon>
							<Tooltip title={lan.formApprovedDownloadInfo} arrow placement='left'>	
								<InfoOutlinedIcon className='ml-2'/>
							</Tooltip>	
						</ListItemIcon>	
					</MenuItem>

					<MenuItem
						className={classes.nested}
						onClick={() => handleExportModules(ModulesNames.FORMS_IMAGES)}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<img id='MyOperation-export-icon' src={DownloadFormsImages} className='icon Fielder-icon-button' alt='' />
						</ListItemIcon>
						<ListItemText primary={lan.downloadFormsImages} />
					</MenuItem>

					<MenuItem
						onClick={() => handleGenerateReportPopup(true, ModulesNames.FORMS)}
						className={classes.nested}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<BallotOutlinedIcon color='disabled' fontSize='small' />
						</ListItemIcon>
						<ListItemText primary={lan.generateFormsReport} />
					</MenuItem>
				</Collapse>
			</>
		);
	};

	const InventoryMenu = () => {

		return (
			<>
				<MenuItem onClick={() => changeSubMenuVisibility(ModulesNames.INVENTORIES)}>
					<ListItemIcon>
						<img id='MyOperation-export-icon' src={InventoryIcon} className='icon Fielder-icon-button' alt='' />
					</ListItemIcon>
					<ListItemText primary={lan.inventory} />
					{viewInventoryMenu ? <ExpandLess /> : <ExpandMore />}
				</MenuItem>
				<Collapse in={viewInventoryMenu} timeout='auto' unmountOnExit>
					<MenuItem
						onClick={() => handleExportModules(ModulesNames.INVENTORIES)}
						className={classes.nested}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<img id='MyOperation-export-icon' src={DownloadFormsIcon} className='icon Fielder-icon-button' alt='' />
						</ListItemIcon>
						<ListItemText primary={lan.downloadInventories} />
					</MenuItem>

					<MenuItem
						onClick={() => handleGenerateReportPopup(true, ModulesNames.INVENTORIES)}
						className={classes.nested}
						disabled={!(props.timeTo != null && props.timeFrom != null)}
					>
						<ListItemIcon>
							<BallotOutlinedIcon color='disabled' fontSize='small' />
						</ListItemIcon>
						<ListItemText primary={lan.generateInventoryReport} />
					</MenuItem>
				</Collapse>
			</>
		);
	};


	const handleGenerateReportPopup = (val, type) => {
		const isDateFilter = hasDateFilter();
		props.setDatesError(!isDateFilter);

		if ((val && props.timeFrom != null && props.timeTo != null) || !val) {
			if (type === ModulesNames.FORMS) {
				setGenerateReportForms(val);
			} else if (type === ModulesNames.ASSETS) {
				setGenerateReportAssets(val);
			} else if (type === ModulesNames.INVENTORIES) {
				setGenerateReportInventory(val);
			}
		}

		setMenuAnchor(null);
	};


	const closeImportTasksPopup = (res) => {
		let msg;
		setShowPopup(null);

		if (res === undefined) {
			return;
		}

		if (res && res.statusCode === 200 && res.response && res.response.errorCode === 0) {
			const mail = user ? user.email : '';
			msg = `${lan.importTaskSuccess} ${mail}`;
		} else if (res && res.statusCode === 200 && res.response && res.response.errorCode === 591) {
			msg = lan.importTaskExceeds;
		} else if (res && res.statusCode === 200 && res.response && res.response.errorCode === 592) {
			msg = lan.importTaskNotLastCVS;
		} else {
			msg = lan.importTaskError;
		}

		setErrorMessage(msg);
		handlePopups(ERROR_POPUP);
	};


	return (
		<>
			{showPopup === IMPORT_TASKS_POPUP && <ImportTaskPopup lan={lan} onClose={closeImportTasksPopup} />}

			{showPopup === ERROR_POPUP && (
				<ErrorMessage
					{...props}
					closeErrorMessage={() => setShowPopup(null)}
					message={errorMessage}
					icon={SuccessIcon}
					showErrorMessage
					lan={lan}
				/>
			)}


			{generateTasksToExport && (
				<GenerateExportTaskPopup
					lan={lan}
					onClose={() => handleGenerateTaskReportPopup(false)}
					from={props.timeFrom}
					to={props.timeTo}
					filter={props.filter}
					tagsFilter={props.tagsFilter}
					datesFilterValues={props.datesFilterValues}
					advancedFilterValues={props.advancedFilterValues}
					executeExport
				/>
			)}

			{generateReportForms && (
				<GenerateReportsPopup
					lan={lan}
					onClose={() => handleGenerateReportPopup(false, ModulesNames.FORMS)}
					from={props.timeFrom}
					to={props.timeTo}
					taskFilter={props.filter}
					tagsFilter={props.tagsFilter}
					datesFilterType={props.datesFilterValues}
					avancedFilter={props.advancedFilterValues}
					isForm
				/>
			)}

			{generateReportAssets && (
				<GenerateReportsPopup
					lan={lan}
					onClose={() => handleGenerateReportPopup(false, ModulesNames.ASSETS)}
					from={props.timeFrom}
					to={props.timeTo}
					taskFilter={props.filter}
					tagsFilter={props.tagsFilter}
					datesFilterType={props.datesFilterValues}
					avancedFilter={props.advancedFilterValues}
					isAsset
				/>
			)}

			{generateReportInventory && (
				<GenerateReportsPopup
					lan={lan}
					onClose={() => handleGenerateReportPopup(false, ModulesNames.INVENTORIES)}
					from={props.timeFrom}
					to={props.timeTo}
					taskFilter={props.filter}
					tagsFilter={props.tagsFilter}
					datesFilterType={props.datesFilterValues}
					avancedFilter={props.advancedFilterValues}
					isInventory
				/>
			)}

		<Row>
			{(RoleUtils.isUserSuperAndSuperViewerAndAdministrator(user) ||
				(RoleUtils.isUserClient(user) && (allowClientToImport || allowClientToExport)) ||
				(RoleUtils.isUserManager(user) && (allowManagerToImport || allowManagerToExport))) && (
				<>
					<Col sm='auto' className='ml-auto'>
						<IconButton className='my-auto iconSubMenuSize' onClick={(e) => setMenuAnchor(e.currentTarget)}>
							<Tooltip title={lan.improveDownloadExperienceText} arrow placement='left' disableHoverListener={(props.timeTo != null && props.timeFrom != null)}>									
								<MoreVertIcon color='disabled' />
							</Tooltip>
						</IconButton>

						<Menu
							anchorEl={menuAnchor}
							keepMounted
							open={Boolean(menuAnchor)}
							onClose={() => setMenuAnchor(null)}
							TransitionComponent={Fade}
							PaperProps={{
								style: { maxHeight: '300px' },
							}}
						>
							{/** ******** IMPORT ICON ******** */}
							{(isRoleValid(taskConst.TASKS, taskConst.IMPORT) ||
								isClientToImport(user, allowClientToImport) ||
								isManagerToImport(user, allowManagerToImport)) && (
								<MenuItem onClick={() => handlePopups(IMPORT_TASKS_POPUP)}>
									<ListItemIcon>
										<img src={ImportIcon} id='MyOperation-import-icon' className='icon Fielder-icon-button' alt='' />
									</ListItemIcon>
									<ListItemText primary={lan.importTask} />
								</MenuItem>
							)}

							{/** ******** EXPORT ICON ******** */}
							{(isRoleValid(taskConst.TASKS, taskConst.EXPORT) ||
								isClientToExport(user, allowClientToExport) ||
									isManagerToExport(user, allowManagerToExport)) && (
									<Tooltip title={lan.selectPeriodOfTime} arrow placement='left' disableHoverListener={(props.timeTo != null && props.timeFrom != null)}>
										<div>
										<MenuItem
											onClick={() => handleGenerateTaskReportPopup(true)}
											disabled={!(props.timeTo != null && props.timeFrom != null)}
									>
										
											<ListItemIcon>
												<img id='MyOperation-export-icon' src={ExportIcon} className='icon Fielder-icon-button' alt='' />
											</ListItemIcon>
											<ListItemText primary={lan.exportTask} />
											
											</MenuItem>
											</div>
										</Tooltip>
							)}

							{showCalendarDownload && !RoleUtils.isUserClient(user) && (
								<MenuItem onClick={handleExportCalendarExcel} disabled={!(props.timeTo != null && props.timeFrom != null)}>
									<ListItemIcon>
										<img
											id='MyOperation-export-calendar-icon'
											src={ExportCalendarIcon}
											className='icon Fielder-icon-button'
											alt=''
										/>
									</ListItemIcon>
									<ListItemText primary={lan.exportCalendar} />
								</MenuItem>
							)}

							{/* FORMS  */}
								{!RoleUtils.isUserClient(user) && isFormsPermissionValidWitManager(user, session) && showForms &&
									<Tooltip title={lan.selectPeriodOfTime} arrow placement='left' disableHoverListener={(props.timeTo != null && props.timeFrom != null)}>
										<div>
											<FormsMenu />
										</div>
									</Tooltip>
								}

							{/* ASSET  */}
								{!RoleUtils.isUserClient(user) && isAssetPermissionValidWithManager(user, session) && showAsset &&
									<Tooltip title={lan.selectPeriodOfTime} arrow placement='left' disableHoverListener={(props.timeTo != null && props.timeFrom != null)}>
										<div>
											<AssetMenu />
										</div>
									</Tooltip>
								}
								

							{/* INVENTORY  */}
								{!RoleUtils.isUserClient(user) && isInventoryPermissionValidWithManager(user, session) && showInventory && (
									<Tooltip title={lan.selectPeriodOfTime} arrow placement='left' disableHoverListener={(props.timeTo != null && props.timeFrom != null)}>
										<div>
											<InventoryMenu />
										</div>
									</Tooltip>
							)}
						</Menu>
					</Col>
				</>
			)}
		</Row>
		</>
	);


}

export default TaskExportImportMenu;