import React, { useState, useEffect } from 'react';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getBranchLogsType } from 'FielderUtils/branch/branchLogs';
import { Col, Row } from 'react-bootstrap';

import Popup from '../Popup';
import { Alert, Grid, IconButton } from '@mui/material';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import LogTextField from '~/LogTextField/LogTextField.components';
import { getCompanyByIdRequest } from '@core/api/company/company.service';

var moment = require('moment');

export default function ({ log, pastLog, onClose, ...props }) {
	const lan = useLanguage();
	const [logType, setLogType] = useState('');
	const [agent, setAgent] = useState(null);
	const [user, setUser] = useState(null);
	const [date, setDate] = useState('');
	const [loadLog, setLoadLog] = useState(false);
	const [companyName, setCompanyName] = useState('loading ...');
	const [companyIcon, setCompanyIcon] = useState(null);
	const [updatedFields, setUpdatedFields] = useState({
		idCustom: false,
		name: false,
		company: false,
		attentionTimeFrom: false,
		attentionTimeTo: false,
		location: false,
		legalAddress: false,
	});

	useEffect(() => {
		const onSuccess = ({ company }) => {
			setCompanyName(company.name);
			setCompanyIcon(company.image);
		};
		getCompanyByIdRequest(log.branch.company.id, onSuccess)

		const { branch } = log;

		setLoadLog(false);
		setLogType(getBranchLogsType(log.logType, lan));
		if (log.user) {
			setUser({
				name: `${log.user.name} ${log.user.lastName}`,
				icon: null,
			});
			setAgent(null)
		}
		if (log.agent) {
			setAgent({
				name: `${log.agent.name} ${log.agent.lastName}`,
				icon: null,
			});
			setUser(null)
		}
		setDate(moment.utc(log.date).local().format('DD/MM/YYYY HH:mm:ss'));

		if (pastLog) {
			const { branch: pastBranch } = pastLog;

			setUpdatedFields({
				idCustom: branch.idCustom !== pastBranch.idCustom,
				name: branch.name !== pastBranch.name,
				company: branch.company.id !== pastBranch.company.id,
				workingHours:
					branch.attentionTimeFrom !== pastBranch.attentionTimeFrom ||
					branch.attentionTimeTo !== pastBranch.attentionTimeTo,
				location: branch.location.address !== pastBranch.location.address,
				legalAddress: branch.legalAddress !== pastBranch.legalAddress,
			});
		}
	}, [log, pastLog]);

	useEffect(() => {
		setLoadLog(true);
	}, [date]);

	const LogHeader = () => {
		return (
			<Grid container style={{ borderBottom: '#dadadada 2px solid', padding:'0.5rem' }}>
				<Grid item sm={1} className='my-auto'>
					{props.logIndex != 0 && (
						<IconButton
							onClick={() => {
								props.changeLog(props.logIndex - 1);
							}}
						>
							<NavigateBeforeIcon color='disabled' />
						</IconButton>
					)}
				</Grid>
				<Grid item sm>
					<>
						<Row className='justify-content-center mx-3'>
							<Col sm={12}>
								<LogTextField label={lan.type} text={logType} className='my-1' />
							</Col>
						</Row>

						<Row className='mx-3'>
							<Col sm={6}>
								<LogTextField label={lan.date} text={date} className='my-1' />
							</Col>
							<Col sm={6}>
								{user &&
								<LogTextField label={lan.user} text={user.name} icon={user.icon} className='my-1' />

								}
								{agent &&
								<LogTextField label={lan.agent} text={agent.name} icon={agent.icon} className='my-1' />
								}
							</Col>
						</Row>
					</>
				</Grid>
				<Grid item sm={1} className='my-auto'>
					{props.logIndex != props.logLength - 1 && (
						<IconButton
							onClick={() => {
								props.changeLog(props.logIndex + 1);
							}}
						>
							<NavigateNextIcon color='disabled' />
						</IconButton>
					)}
				</Grid>
			</Grid>
		);
	};

	return (
		<Popup
			closeButton
			onClose={onClose}
			onHide={onClose}
			width={'lg'}
			title={lan.branchLogs}
			icon={'assets-light-grey-icon.svg'}
		>
			<LogHeader />
			{loadLog && (
				<Grid container className='mt-3 px-4' spacing={1}>
					<Grid item xs={12}>
						<Alert severity='info'>{lan.versionUpdatesHilighted}</Alert>
					</Grid>

					<Grid item xs={6}>
						<LogTextField label={lan.idBranch} text={log.branch.id} className='my-1' />
					</Grid>

					<Grid item xs={6}>
						<LogTextField
							label={lan.idCustom}
							text={log.branch.idCustom}
							className='my-1'
							updated={updatedFields.idCustom}
						/>
					</Grid>

					<Grid item xs={12}>
						<LogTextField label={lan.name} text={log.branch.name} className='my-1 ' updated={updatedFields.name} />
					</Grid>

					<Grid item xs={8}>
						<LogTextField label={lan.company} text={companyName} icon={companyIcon}  className='my-1 ' updated={updatedFields.company} />
					</Grid>

					<Grid item xs={4}>
						<LogTextField
							label={lan.rangeOfAttentionTime}
							text={`${log.branch.attentionTimeFrom} - ${log.branch.attentionTimeTo}`}
							updated={updatedFields.workingHours}
							className='my-1'
						/>
					</Grid>

					<Grid item xs={12}>
						<LogTextField
							label={lan.branchLocation}
							text={log.branch.location.address}
							className='my-1 '
							updated={updatedFields.location}
						/>
					</Grid>

					<Grid item xs={12}>
						<LogTextField
							label={lan.legalAddress}
							text={log.branch.legalAddress}
							className='my-1'
							updated={updatedFields.legalAddress}
						/>
					</Grid>
				</Grid>
			)}
		</Popup>
	);
}
