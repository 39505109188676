import React, { Component } from 'react';

import lottie from '@assets/lotties/loadingFielder.json';
import API_LINKS from 'FielderAPILinks';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { getIdToken } from 'FielderUtils/session/Session';
import TeamStatus from 'FielderUtils/team/teamStatus';
import { Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import Select from 'react-select';
import { AddAgentIcon, AgentIcon, DeepSpaceGrey, Gainsboro } from '@core/theme/baseTheme';

import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import Popup from '../../Popup';

import '../MyOperation.css';
import { assignAgentToTaskRequest } from '@core/api/task/task.service';
class AssignAgentPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			agentsListArray: null,
			idAgentSelected: null,
			confirmationDialog: false,
			messageToConfirm: '',
			onDuty: false,
			disableSelect: true,
			//Session
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
			currentUser: session.user,
			idTeam: 0,
		};
	}

	componentDidMount = () => {
		this.getAvailableAgents();
	};

	assignAgent = () => {
		if (this.state.idAgentSelected !== null) {
			for (let i = 0; i < this.props.idTasks.length; i++) {
				const body = {
					taskInfo: {
						technicianId: this.state.idAgentSelected,
						idTask: this.props.idTasks[i],
						idTeam: this.state.idTeam,
						idUser: this.props.session.user.idUser,
						idAccount: this.props.session.user.idAccount,
					},
				};

				const onSuccess = (response) => {
					if (this.props.idTasks.length === i + 1) {
						this.props.onClose(true, response);
					}
				};

				const onError = (error) => {
					this.props.onClose(false, error);
				};

				const loadingConfig = { dispatch: this.props.dispatch }

				assignAgentToTaskRequest(body, onSuccess, onError, loadingConfig);
			}
		}
	};

	checkAgent = () => {
		if (this.state.idAgentSelected !== null) {
			if (this.state.onDuty) {
				this.assignAgent();
			} else {
				this.setState({
					confirmationDialog: true,
					messageToConfirm: this.props.lan.messageConfirmationAssignAgent,
				});
			}
		}
	};

	setAgent = (event) => {
		this.setState({
			idAgentSelected: event.value.idAgent,
			idTeam: event.value.idTeam,
			onDuty: event.value.onDuty,
		});
	};

	getAvailableAgents = () => {
		var idToken = getIdToken();
		const session = this.props.session;

		var bodySend = {
			type: 'GET_AGENT_FOR_MY_OPERATION',
			idAccount: session.user.idAccount,
			idUser: session.user.idUser,
		};

		const request = {
			method: 'POST',
			body: JSON.stringify(bodySend),
			headers: {
				'Content-type': 'application/json',
				Authorization: idToken,
			},
		};
		return fetch(API_LINKS.taskManager, request)
			.then((res) => res.json())
			.then((res) => {
				if (res.success === true) {
					var agentList = res;
					var agentsListArray = [];
					var agents = agentList.agents;

					agents.forEach((agent) => {
						var agentTeamName;
						var teamInfo = '';
						var oneTeamDisable = 0;
						var teamControl = false;
						var skill = '';
						if (agent.skillsObj != null) {
							var skillObject = agent.skillsObj;
							for (let x = 0; x < skillObject.length; x++) {
								skill = skill + ' ' + skillObject[x].title;
							}
						}

						if (agent.teams && agent.teams.length > 0) {
							agent.teams.forEach((team) => {
								if (
									(RoleUtils.isUserManager(this.state.currentUser) &&
										 team.idManagers && team.idManagers.includes(this.state.currentUser.idUser)) ||
									RoleUtils.isUserSuperAndAdministrator(this.state.currentUser)
								) {
									if (team.status === TeamStatus.ENABLE) {
										agentTeamName = team.name;
										teamInfo = ' - ' + this.props.lan.team + ': ' + agentTeamName;
										teamControl = true;
									} else {
										teamInfo = '';
										oneTeamDisable++;
										teamControl = false;
										team.id = null;
									}

									if ((agent.status === 0 && teamControl) || oneTeamDisable <= 1) {
										let offDuty = '';
										if (!agent.onDuty) {
											offDuty = ' - ' + this.props.lan.offDuty + ' ';
										}
										agentsListArray.push({
											label: agent.name + ' ' + agent.lastName + ' (' + agent.email + ')' + offDuty + teamInfo,
											value: {
												idAgent: agent.idAgent,
												idTeam: team.id,
												onDuty: agent.onDuty,
											},
											skill: skill,
										});
									}
								}
							});
						} else {
							if (agent.status === 0) {
								let offDuty = '';
								if (!agent.onDuty) {
									offDuty = ' - ' + this.props.lan.offDuty + ' ';
								}
								agentsListArray.push({
									label: agent.name + ' ' + agent.lastName + ' (' + agent.email + ')' + offDuty + teamInfo,
									value: {
										idAgent: agent.idAgent,
										idTeam: null,
										onDuty: agent.onDuty,
									},
									skill: skill,
								});
							}
						}
					});

					this.setState({ agentsListArray,  disableSelect: false });
				}
			});
	};

	customFilter(option, searchText) {
		return (
			option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.value.idAgent === searchText ||
			option.data.skill.toLowerCase().includes(searchText)
		);
	}

	closeDeleteConfirmationDialog = () => {
		let state = this.state;
		state.confirmationDialog = false;
		this.setState(state);
	};

	assignAgentConfirmation = () => {
		this.assignAgent();
		this.closeDeleteConfirmationDialog();
	};

	render() {
		var agentLabel = this.props.lan.searchBy;
		if (
			this.props.agentsTask !== undefined &&
			this.props.agentsTask !== null &&
			this.props.agentsTask.length > 0 &&
			this.props.idTasks.length === 1
		) {
			agentLabel = this.props.agentsTask[0].name + ', ' + this.props.agentsTask[0].lastName;
		}

		return (
			<Popup
				title={this.props.lan.assignAgent}
				icon={<AddAgentIcon sx={{ color: DeepSpaceGrey,  width: '24px' }} className='mr-2' />}
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.assign}
				buttonOnClick={this.checkAgent}
			>
				<Row className=''>
					{this.state.confirmationDialog && (
						<ConfirmationDialog
							{...this.props}
							handleAction={this.assignAgentConfirmation}
							handleClose={this.closeDeleteConfirmationDialog}
							message={this.state.messageToConfirm}
						/>
					)}
					<Col sm='auto' >
						<AgentIcon sx={{ color: Gainsboro}} />
					</Col>
					<div className='col-10 '>
						<Select
							className='react-select__control full-width'
							multi='false'
							cacheOptions
							options={this.state.agentsListArray}
							filterOption={this.customFilter}
							onChange={this.setAgent}
							placeholder={agentLabel}
							isDisabled={this.state.disableSelect}
						/>
					</div>
					{this.state.disableSelect && (
						<div className='col no-padding'>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: lottie,
								}}
								height='40px'
								width='40px'
							/>
						</div>
					)}
				</Row>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(AssignAgentPopup);
