import React from 'react'

import { Cultured, DeepSpaceGrey, LightGreySubtitleColor, RosePompadour } from '@core/theme/baseTheme';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { motion } from 'framer-motion';

export const FielderTabPanel = motion(Grid);

export const FielderTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	fontWeight: theme.typography.fontWeightRegular,
	fontSize: theme.typography.pxToRem(15),
	fontFamily: 'Lato',
	color: LightGreySubtitleColor,
	backgroundColor: Cultured,
	'&.Mui-selected': {
		color: DeepSpaceGrey,
		backgroundColor: 'white'
	}
}));

export const FielderTabs = styled((props) => (
	<Tabs {...props} variant='fullWidth' />
))({
	'& .MuiTabs-indicator': {
		backgroundColor: `${RosePompadour} !important`,
	},
});
