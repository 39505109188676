import React, { useEffect, useState } from 'react';

import DefaultProfileImage from '@assets/icons/default-profile-icon.svg';
import { Avatar, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLanguage } from 'Context/LanguageContext';
import billingConst from 'FielderUtils/roleManagementConst/billingConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { removeSession } from 'FielderUtils/session/Session';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isLocalUser } from 'Utils/account/utils';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';
import { selectUser } from '../../../../core/store/modules/session/sessionSelectors';
import { setLogOutUser } from '../../../../core/store/modules/session/sessionSlice';
import useValidateRoles from '../../../../core/utils/roles/useValidateRoles';
import { ProfileNavDropdown } from './styled';
import { isClient } from '@core/utils/roles/isRole';
import {
  getCognitoUser,
  removeCognitoUser
} from 'FielderUtils/session/Session';

const AvatarContainerButton = styled(Button)`
	text-transform: none;
	font-family: 'Lato';
	font-size: 14px;
	color: #1b253a;
	font-weight: normal;
	font-style: normal;
`
const TopMenuProfile = () => {
	const [profileImage, setProfileImage] = useState(DefaultProfileImage);

	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const lan = useLanguage();
	const navigate = useNavigate();
	const { isRoleValid } = useValidateRoles();

	const isSuperAdminOrViewer = RoleUtils.isUserSuperAndSuperViewerAndAdministrator(user);
	const localUser = isLocalUser(user);
	const isInternalAdmin = RoleUtils.isInternalAdminUser(user);
	const isManager = RoleUtils.isUserManager(user);

	useEffect(() => {
		if (user?.profileImage?.scalePath) {
			setProfileImage(user.profileImage.scalePath);
		}
	}, [user]);

	var validateChangePassword = false;
	if (
		RoleUtils.isRootGlobalAccountUser(user) ||
		RoleUtils.isClientGlobalAccountUser(user) ||
		(!isInternalAdmin && user && user.users == null) ||
		isSuperAdminOrViewer
	) {
		validateChangePassword = true;
	}

	const logOut = () => {
    const storedCognitoUser = getCognitoUser();
    if (storedCognitoUser) {
      handleLogoutCognito().then(r => console.log("handleLogoutCognito"))
    } else {
      dispatch(setLogOutUser());
      removeSession();
      navigate(ENDPOINTS.login);
    }
	};
  const handleLogoutCognito = async () => {
    const domain = process.env.COGNITO_DOMAIN_NAME;
    const clientId = process.env.COGNITO_CLIENT_ID;
    const logoutRedirectUri = encodeURIComponent(process.env.COGNITO_REDIRECT_LOGOUT);
    if (!domain || !clientId || !logoutRedirectUri) {
      console.error("Missing environment variables for logout!");
      return;
    }
    try {
      dispatch(setLogOutUser());
      sessionStorage.clear();
      removeCognitoUser();
      removeSession();
      window.location.href = `${domain}/logout?client_id=${clientId}&logout_uri=${logoutRedirectUri}`;
    } catch (error) {console.error("Error during logout:", error);}
  };
	const ProfileAvatar = () => (
		<AvatarContainerButton>
			<Avatar src={profileImage} className='mr-2' />
			{user ? `${user.name} ${user.lastName}` : ''}
		</AvatarContainerButton>
	);

	return (
		<>
			<ProfileNavDropdown title={<ProfileAvatar />} id='collasible-nav-dropdown'>
				{!isInternalAdmin && localUser && (
					<NavDropdown.Item id='profile' onClick={() => navigate(ENDPOINTS.profile)}>
						{lan.myProfile}
					</NavDropdown.Item>
				)}

				{validateChangePassword && (
					<NavDropdown.Item
						id='changePassword'
						onClick={() => navigate(ENDPOINTS.changePassword)}
					>
						{lan.changePassword}
					</NavDropdown.Item>
				)}

				{!isInternalAdmin && localUser && (
					<NavDropdown.Item id='configuration' onClick={() => navigate(ENDPOINTS.configuration)}>
						{lan.configuration}
					</NavDropdown.Item>
				)}

				{!isInternalAdmin &&
					isSuperAdminOrViewer &&
					isRoleValid(billingConst.BILLING, billingConst.VIEW) && (
						<NavDropdown.Item
							id='billingSummary'
							onClick={() => navigate(ENDPOINTS.billingSummary)}
						>
							{lan.billing}
						</NavDropdown.Item>
					)}

				{((!isInternalAdmin && isSuperAdminOrViewer) || isManager || isClient(user)) && (
					<NavDropdown.Item id='support' onClick={() => navigate(ENDPOINTS.support)}>
						{lan.support}
					</NavDropdown.Item>
				)}

				{!isInternalAdmin && !localUser && (
					<NavDropdown.Item
						id='support'
						onClick={() =>
							RoleUtils.isRootGlobalAccountUser(user)
								? navigate(ENDPOINTS.globalAccountDashboard)
								: navigate(ENDPOINTS.globalAccountClientDashboard)
						}
					>
						{lan.globalDashboard}
					</NavDropdown.Item>
				)}

				<NavDropdown.Item id='logout' className='dropdown-color' onClick={logOut}>
					{lan.logOut}
				</NavDropdown.Item>
			</ProfileNavDropdown>
		</>
	);
};

export default TopMenuProfile;
