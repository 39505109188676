import React, { Component } from 'react';

import AddIcon from '@assets/icons/red-add-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { Alert } from '@mui/material'
import { getAccountPreferences } from 'FielderUtils/accountUtils';
import {
	getBranchesByFilterAsyncSelectCreateTask,
	getBranchesInitialOptionsAsyncSelect,
} from 'FielderUtils/branch/branch';
import { getDefaultPriorities } from 'FielderUtils/Constants/TaskPriorityConstants';
import { getClientsByFilterAsyncSelect, getClientInitialOptionsAsyncSelect } from 'FielderUtils/client/client';
import countries from 'FielderUtils/countries';
import FielderConst from 'FielderUtils/fielderConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { GOOGLE_API_KEY, getIdToken } from 'FielderUtils/session/Session';
import TaskStatus from 'FielderUtils/status/taskStatus';
import { hasMailIntegrationFunctionality } from 'FielderUtils/task/taskFuntionalities';
import { hasTaskTypeFunctionality, getAccountTaskTypes, checkTaskTypeRouteVariables } from 'FielderUtils/taskType/taskType';
import { getTaskPriorityByOriginRequest }  from '@core/api/taskPriority/taskPriority.service';
import { Row, Col, Card, Image, Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Geocode from 'react-geocode';
import { connect } from 'react-redux';
import Select from 'react-select';

import { getTeamsByFilterAsyncSelect, getTeamsInitialOptionsAsyncSelect } from 'FielderUtils/team/team';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import InfoTaskVariables from './InfoTaskVariables';
import SelectTaskType from './components/SelectTaskType';
import TaskVariables from './components/TaskVariables';
import TaskCaseNumber from './components/TaskCaseNumber';

//Css
import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import './CreateTask.css';
import model from 'Object/model/model.js';
import { updateTaskRequest } from '@core/api/task/task.service';
import MapStaticComponent from '~/MapStatic/MapStatic.component';
import StaticMarker from '~/MapStatic/components/StaticMarker';
import GoogleMapsAutocomplete from '~/GoogleMapsAutocomplete/GoogleMapsAutocomplete.component';

var moment = require('moment');
var optionRecurentTask = 0;

//Dates
var compareDate = new Date();

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();


/**
 *  Create Task Page for the Fielder App
 *  @extends Component
 */
class EditorTask extends Component {
	constructor(props) {
		super(props);
		this.numberCaseRef = React.createRef();
		let mailIntegrationFunctionality = hasMailIntegrationFunctionality(props.session.account.funcionalityPurchases);
		let taskTypeFunctionality = hasTaskTypeFunctionality(props.session.account.funcionalityPurchases);
		let task = this.props.taskInfo.task;

		//Partner Validation
		let iAmNotPartner = (task && task.partnerTaskRelations && task.partnerTaskRelations.length > 0
			&& task.partnerTaskRelations[0].idAccountOwner == this.props.session.account.id) || !task.partnerTaskRelations || task.partnerTaskRelations.length <= 0 ? true : false;

		//Disable Map Auto Complete
		let disableAutoCompleteMap = task.group ? true  : false;

		//Search Views
		let showBranchSearch = task && task.user ? false : true;
		let showClientSearch = task && task.user ? true : false;

		//Change Map Position
		let changedMapPosition = false;

		//Branch
		let idBranch = null;
		let branchName = '';
		let branchCompanyName = '';

		//Client
		let idClient = null;
		let clientName = '';
		let clientLastName = '';
		let clientMovileNumber = '';
		let clientEmail = '';

		//Location
		let taskNewDirection = '';
		let state = null;
		let country = null;
		let postalCode = null;
		let clientTimeZone = null;
		let clientLatitude = 19.3916915;
		let clientLongitud = -99.2809795;

		//Roles Client Brances
		let branchListArrayClient = [];


		let taskStatus = this.props.taskInfo.task.status;

		if (
			(taskStatus == TaskStatus.EXECUTING ||
				taskStatus == TaskStatus.FINISH ||
				taskStatus == TaskStatus.CANCEL ||
				taskStatus == TaskStatus.START_ROUTE) &&
			this.props.session.user.role &&
			this.props.session.user.role == 'Fielder Client User' &&
			this.props.taskInfo.task.group == null
		) {
			disableAutoCompleteMap = true;
		}

		if (
			(taskStatus == TaskStatus.EXECUTING ||
				taskStatus == TaskStatus.FINISH ||
				taskStatus == TaskStatus.CANCEL ||
				taskStatus == TaskStatus.START_ROUTE ||
				taskStatus == TaskStatus.CANCEL_BY_PARTNER) &&
			this.props.session.user.role &&
			this.props.session.user.role != 'Fielder Client User' &&
			this.props.taskInfo.task.group == null
		) {

			disableAutoCompleteMap = true;
		}



		if (task.branch != null) {

			idBranch = task.branch.id;
			branchName = task.branch.name;
			branchCompanyName = task.branch.company ? task.branch.company.name : '';

			state = task.startLocation.state;
			country = task.startLocation.country;
			postalCode = task.startLocation.postalCode;
			taskNewDirection = task.startLocation.address;
			clientTimeZone = task.startLocation.timeZone;
			clientLatitude = parseFloat(task.startLocation.latitude);
			clientLongitud = parseFloat(task.startLocation.longitud);

		} else {

			idClient = task.user.idUser;
			clientName = task.user.name;
			clientLastName = task.user.lastName;
			clientMovileNumber = task.user.mobileNumber;
			clientEmail = task.user.email;

			state = task.startLocation.state;
			country = task.startLocation.country;
			postalCode = task.startLocation.postalCode;
			taskNewDirection = task.startLocation.address;
			clientTimeZone = task.startLocation.timeZone;
			clientLatitude = parseFloat(task.startLocation.latitude)
			clientLongitud = parseFloat(task.startLocation.longitud)

		}


		if (RoleUtils.isUserClient(this.props.user)) {
			if (this.props.user.branches) {
				branchListArrayClient = this.setClientBranches(this.props.user.branches);
			} else {
				showBranchSearch = false;
				showClientSearch = false;
				changedMapPosition = true;
			}
		}

		this.state = {

			mailIntegrationFunctionality: mailIntegrationFunctionality,
			taskTypeFunctionality: taskTypeFunctionality,
			accountPreferences: props.session.accountPreferences,

			taskTypes: {
				taskTypes: [],
				taskTypeList: [],
				taskTypeSelected: null,
			},

			defaultPriority: task.severity,
			taskPriorities:getDefaultPriorities(this.props.lan),

			taskInfo: {
				taskType: task.serviceTypeId,
				description: task.description,
				folio: task.folio,
				caseNumber: task.caseNumber,
				severity: task.severity,
				idCustom: task.idCustom,

			},

			setTaskTypeForRequest: task.serviceTypeId,

			executeCounter: (task.status == TaskStatus.REVIEW && task.serviceTypeId == FielderConst.TASK_BY_MAIL) ? true : false,
			validateShowTeam:  (task.status == TaskStatus.REVIEW &&  task.serviceTypeId == FielderConst.TASK_BY_MAIL && RoleUtils.isUserManager(this.props.user)) ? true :false,

			disabledAllInput: task.group ? true : false,
			disableAutoCompleteMap:  disableAutoCompleteMap,
			hideOnTaskGroup: task.group ? true : false,

			//Search Views
			showClientSearch: showClientSearch,
			showBranchSearch: showBranchSearch,


			dateInit: task.startDateTimeRange,
			dateEnd: task.endDateTimeRange,
			hourValidationEndDate: null,

			branchListArray: null,

			//Branch
			idBranch: idBranch,
			branchName: branchName,
			branchCompanyName: branchCompanyName,


			//Client
			idClient: idClient,
			clientName: clientName,
			clientLastName: clientLastName,
			clientMovileNumber: clientMovileNumber,
			clientEmail: clientEmail,

			//Location
			state: state,
			country: country,
			postalCode: postalCode,
			taskNewDirection: taskNewDirection,
			clientTimeZone: clientTimeZone,
			clientLatitude: clientLatitude,
			clientLongitud: clientLongitud,

			//Brances Role Client
			branchListArrayClient: branchListArrayClient,


			unlockToDate: true,

			changedMapPosition: changedMapPosition,

			tags: task.tags,

			//Range Date End
			endRangeYear: null,
			endRangeMonth: null,
			endRangeDay: null,
			endRangeHour: null,
			endRangeMinute: null,

			recurrentDatesMap: null,

			compareDate: new Date(),
			hourValidationInitDate: new Date(),
			searchLabel: '',

			readOnly: true,
			validateDateInit: false,
			validateDateEnd: false,

			//Session
			session: props.session,
			idUser: props.session.user ? props.session.user.idUser : 0,
			idAccount: props.session.user ? props.session.user.idAccount : 0,
			currentUser: props.session.user,

			//taskTypeSelected: null,
			showWarningAddress: false,

			iAmNotPartner: iAmNotPartner,

			idTeam: null,
			firtsTime: true,

		};
	}

	componentDidMount = () => {
		getAccountPreferences(this, this.props.session);

		let task = this.props.taskInfo.task;
		let idBranch = task.branch ? task.branch.id : null;
		let idClient = task.user ? task.user.idUser : null;
		this.getTaskTypes(idBranch, idClient);
		this.getTaskPriority(idBranch, idClient, this.state.defaultPriority);
	
		if (!RoleUtils.isUserClient(this.props.user) && this.state.iAmNotPartner == true) {
			document.getElementById('checkbox-branch').disabled = this.state.disabledAllInput;
			document.getElementById('checkbox-client').disabled = this.state.disabledAllInput;
		}
	};

	getTaskTypes = (idBranch, idClient) => {
		if (this.state.taskTypeFunctionality) {
			const setTaskTypeVariables = (accountTaskTypes, hasRouteVariables) => {
				let taskTypes = {
					taskTypes: this.validateReviewType(accountTaskTypes.taskTypes),
					taskTypeList: this.validateListType(accountTaskTypes.taskTypeList),
					taskTypeSelected: accountTaskTypes.taskTypeSelected,
				}

				this.setState({ taskTypes: taskTypes, hasRouteVariables: hasRouteVariables },
					() => { this.initTaskType(accountTaskTypes.taskTypeSelected)})
			};

			getAccountTaskTypes(
				this.state.accountPreferences,
				idBranch,
				idClient,
				null,
				this.props.lan,
				setTaskTypeVariables
			);
		}
	};

	getTaskPriority = (idBranch, idClient, defaultPriority) => {
		console.log("defaultPriority");
		console.log(defaultPriority);
		const onSuccess = (response) => {;
			const taskPriorityResponse = response.taskPriority;
			if (((idBranch != null && idBranch != "" && idBranch > 0) ||
					(idClient != null && idClient != "" && idClient > 0))
				&& taskPriorityResponse.length > 0) {
				if (defaultPriority) {
					this.setSeverityValue(defaultPriority);
				} else { 
					this.setSeverityValue(taskPriorityResponse[0].id);
				}
			} else { 
				if (defaultPriority) {
					this.setSeverityValue(defaultPriority);
				} else { 
					this.setSeverityValue(3);
				}
				
			}
			this.setState({ taskPriorities: taskPriorityResponse });
		}
		
		const onError = (error) => {
			this.setState({ taskPriorities: getDefaultPriorities(this.props.lan) });
		}

		const body = { idBranch, idClient };
		const loadingConfig = { dispatch:this.props.dispatch, config: { allowTopBar: true } };
		getTaskPriorityByOriginRequest(body, onSuccess, onError, loadingConfig);
	}

	setSeverityValue = (value) => {
		let state = this.state;
		state.taskInfo.severity = value;
		this.setState(state);
	}

	getDetaultNameOrNormal = (id, name) => {
		if (id == 1 || id == 2 || id == 3) {
			return this.props.lan['severity_' + id];
		} else {
			return name;
		}
	}

	validateReviewType = (taskTypes) => {
		if (taskTypes && taskTypes.length > 0 &&
			this.state.mailIntegrationFunctionality &&
			this.props.taskInfo.task.status == TaskStatus.REVIEW
		) {
			taskTypes.push({
				children: null,
				hours: '0 hrs',
				id: 100001,
				key: 100001,
				minutes: '32 min',
				name: this.props.lan.tasktipeMail,
				parent: 0,
				routeVariables: null,
				status: true,
				title: this.props.lan.tasktipeMail,
				value: 100001,
				disabled: true,
			});
		}
		return taskTypes
	}

	validateListType = (taskTypeList) => {
		if (taskTypeList && taskTypeList.length > 0 &&
			this.state.mailIntegrationFunctionality &&
			this.props.taskInfo.task.status == TaskStatus.REVIEW
		) {
			taskTypeList.push({
				hideToAgent:false,
				id:100001,
				name:this.props.lan.tasktipeMail,
				originIds:{companies: null, branches: null, clients: null, applyToAll: true},
				origins:{companies: null, branches: null, clients: null},
				parent:null,
				price:null,
				routeVariables:[],
				status:true,
				time:3660,
			});
		}
		return taskTypeList
	}


	initTaskType = (taskTypeSelected) => {
		let selected = taskTypeSelected;

		if (this.state.firtsTime && this.props.taskInfo.task.status == TaskStatus.REVIEW) {
			selected = this.state.taskTypes.taskTypeList.find((type) => type.id === this.state.taskInfo.taskType);
		} else if (this.state.firtsTime && this.props.taskInfo.task.status != TaskStatus.REVIEW) {
			selected = this.state.taskTypes.taskTypeList.find((type) => type.id === this.state.taskInfo.taskType);
		}

		this.updateSelectedTaskType(selected , true)
	};



	updateSelectedTaskType = (selectedType, changeTypeVal) => {

		const routeVariables = checkTaskTypeRouteVariables(
			this.state.accountPreferences,
			this.state.taskTypes.taskTypeList,
			selectedType
		);

		let state = this.state;
		state.taskTypes.taskTypeSelected = selectedType;
		state.hasRouteVariables = routeVariables;

		if (!this.state.firtsTime) {
			state.taskInfo.taskType = selectedType.id
		}
		this.setState(state, () => { this.updateFirst(changeTypeVal)});

	};

	updateFirst = (changeTypeVal) => {
		if (changeTypeVal) {
			this.setState({ firtsTime: false })
		}
	}



	setClientBranches = (branches) => {
		let branchListArrayClient = [];

		for (let i = 0; i < branches.length; i++) {
			if (branches[i].idCustom == null || branches[i].company.idCustom == null) {
				branches[i].idCustom = '';
				branches[i].company.idCustom = '';
			}
			branchListArrayClient.push({
				label:
					branches[i].idCustom +
					' ' +
					branches[i].name +
					' ' +
					branches[i].company.idCustom +
					' ' +
					branches[i].company.name,
				value: branches[i],
			});
		}
		return branchListArrayClient;
	};


	obtainDirectionData = (clientLatitude, clientLongitud) => {
		Geocode.fromLatLng(clientLatitude, clientLongitud).then(
			(response) => {
				const address = response.results[0].formatted_address;
				let state;
				let country;
				let postalCode;

				for (let i = 0; i < response.results[0].address_components.length; i++) {
					if (response.results[0].address_components[i].types[0] === 'administrative_area_level_1') {
						state = response.results[0].address_components[i].long_name;
					}

					if (response.results[0].address_components[i].types[0] === 'country') {
						country = response.results[0].address_components[i].long_name;
					}

					if (response.results[0].address_components[i].types[0] === 'postal_code') {
						postalCode = response.results[0].address_components[i].long_name;
					}
				}

				this.setState({
					taskNewDirection: address,
					state: state,
					country: country,
					postalCode: postalCode,
				});
			},
			(error) => {
				console.error(error);
			}
		);
	};

	//Validations
	validateDesciption = (description) => {
		if (description !== '') {
			document.getElementById('description-alert-icon').style.display = 'none';
			return true;
		}
		document.getElementById('description-alert-icon').style.display = 'inline';
		document.getElementById('description-red-input').style.borderColor = '#c70101';
		return false;
	};

	validateChangeAddress = (originalLocation, clientLatitude, clientLongitud) => {
		var originLatitude = parseFloat(originalLocation.latitude);
		var originLongitud = parseFloat(originalLocation.longitud);
		var currentLatitude = parseFloat(clientLatitude);
		var currentLongitud = parseFloat(clientLongitud);

		currentLatitude = currentLatitude.toFixed(6);
		currentLongitud = currentLongitud.toFixed(6);
		if (originLatitude == clientLatitude && originLongitud == clientLongitud) return false;
		else return true;
	};

	validateTypeTask = (taskType) => {
		if (taskType !== null) {
			document.getElementById('taskType-alert-icon').style.display = 'none';
			return true;
		}
		document.getElementById('taskType-alert-icon').style.display = 'inline';
		document.getElementById('taskType-red-input').style.borderColor = '#c70101';
		return false;
	};

	isValidClientNameLastnameMovileEmail = (name, lastName, movileNumber, email) => {
		if (name !== '' || lastName !== '' || movileNumber !== '' || email !== '') {
			document.getElementById('client_data-alert-icon').style.display = 'none';
			return true;
		}

		document.getElementById('client_data-alert-icon').style.display = 'inline';
		document.getElementById('client_data-red-input').style.borderColor = '#c70101';
		document.getElementById('client_data-red-input-2').style.borderColor = '#c70101';
		document.getElementById('client_data-red-input-3').style.borderColor = '#c70101';
		document.getElementById('client_data-red-input-4').style.borderColor = '#c70101';

		return false;
	};

	validateDirection = (direction) => {
		if (direction !== '') {
			document.getElementById('direction-alert-icon').style.display = 'none';
			return true;
		}
		document.getElementById('direction-alert-icon').style.display = 'inline';
		document.getElementById('direction-red-input').style.borderColor = '#c70101';
		return false;
	};

	validatePriority = (severity) => {
		if (severity !== null) {
			document.getElementById('severity-alert-icon').style.display = 'none';
			return true;
		}
		document.getElementById('severity-alert-icon').style.display = 'inline';
		document.getElementById('severity-red-input').style.borderColor = '#c70101';
		return false;
	};

	isValidBranchNameCompanyName = (branchName, branchCompanyName) => {
		if (branchName !== '' || branchCompanyName !== '') {
			document.getElementById('branch_data-alert-icon').style.display = 'none';
			return true;
		}

		document.getElementById('branch_data-alert-icon').style.display = 'inline';
		document.getElementById('branch_data-red-input').style.borderColor = '#c70101';
		document.getElementById('branch_data-red-input-2').style.borderColor = '#c70101';

		return false;
	};

	handleDoActionUpdateTask = (e) => {
		e.preventDefault();

		var isValidDescription;
		var isValidTypeTask;
		var isValidBranchNameCompanyName;
		var isValidClientNameLastnameMovileEmail;
		var isValidDirection;
		var isValidPriority;

		var allCheck = false;
		var inicialDateToEndDate = false;

		// Branch Validation
		if (this.state.showBranchSearch === true) {
			if (
				this.state.taskInfo.taskType === null ||
				this.state.branchName === '' ||
				this.state.branchCompanyName === '' ||
				this.state.taskNewDirection === '' ||
				this.state.taskInfo.description === '' ||
				this.state.severity === null ||
				this.state.dateInit === null ||
				this.state.dateEnd === null
			) {
				if (this.state.dateInit != null && this.state.dateEnd != null) {
					if (
						this.state.taskInfo.taskType !== null ||
						this.state.clientName !== '' ||
						this.state.clientLastName !== '' ||
						this.state.clientMovileNumber !== '' ||
						this.state.clientEmail !== '' ||
						this.state.taskNewDirection !== '' ||
						this.state.taskInfo.description !== '' ||
						this.state.severity !== null
					) {
						allCheck = true;
						inicialDateToEndDate = true;
					} else {
						allCheck = false;
					}
				} else if (this.state.dateInit == null && this.state.dateEnd == null) {
					if (
						this.state.taskInfo.taskType !== null ||
						this.state.clientName !== '' ||
						this.state.clientLastName !== '' ||
						this.state.clientMovileNumber !== '' ||
						this.state.clientEmail !== '' ||
						this.state.taskNewDirection !== '' ||
						this.state.taskInfo.description !== '' ||
						this.state.severity !== null
					) {
						allCheck = true;
						inicialDateToEndDate = true;
					}
				} else if (this.state.dateInit == null && this.state.dateEnd != null) {
					document.getElementById('programDate-alert-icon').style.display = 'inline';
					document.getElementById('programDate-red-input').style.borderColor = '#c70101';
					allCheck = false;
					inicialDateToEndDate = false;
				} else if (this.state.dateInit != null && this.state.dateEnd == null) {
					document.getElementById('programDate-alert-icon').style.display = 'inline';
					document.getElementById('programDate-red-input').style.borderColor = '#c70101';
					allCheck = false;
					inicialDateToEndDate = false;
				}

				isValidDescription = this.validateDesciption(this.state.taskInfo.description);
				isValidTypeTask = this.validateTypeTask(this.state.taskInfo.taskType);
				isValidBranchNameCompanyName = this.isValidBranchNameCompanyName(
					this.state.branchName,
					this.state.branchCompanyName
				);
				isValidDirection = this.validateDirection(this.state.taskNewDirection);
				isValidPriority = this.validatePriority(this.state.taskInfo.severity);

				if (
					isValidDescription &&
					isValidTypeTask &&
					isValidBranchNameCompanyName &&
					isValidDirection &&
					isValidPriority
				) {
					//SEND REQUEST TO SERVER
					document.getElementById('register-form-hint').style.display = 'none';
					allCheck = true;

					//alert("success!");
				} else {
					document.getElementById('register-form-hint').style.display = 'inline';
					allCheck = false;
				}
			} else {
				allCheck = true;
			}
		} else if (this.state.showClientSearch == true || RoleUtils.isUserClient(this.props.user)) {
			if (
				this.state.taskInfo.taskType === null ||
				this.state.clientName === '' ||
				this.state.clientLastName === '' ||
				this.state.clientMovileNumber === '' ||
				this.state.clientEmail === '' ||
				this.state.taskNewDirection === '' ||
				this.state.taskInfo.description === '' ||
				this.state.severity === null ||
				this.state.dateInit === null ||
				this.state.dateEnd === null
			) {
				if (this.state.dateInit != null && this.state.dateEnd != null) {
					if (
						this.state.taskInfo.taskType !== null ||
						this.state.clientName !== '' ||
						this.state.clientLastName !== '' ||
						this.state.clientMovileNumber !== '' ||
						this.state.clientEmail !== '' ||
						this.state.taskNewDirection !== '' ||
						this.state.taskInfo.description !== '' ||
						this.state.severity !== null
					) {
						allCheck = true;
						inicialDateToEndDate = true;
					} else {
						allCheck = false;
					}
				}

				if (this.state.dateInit == null && this.state.dateEnd == null) {
					if (
						this.state.taskInfo.taskType !== null ||
						this.state.clientName !== '' ||
						this.state.clientLastName !== '' ||
						this.state.clientMovileNumber !== '' ||
						this.state.clientEmail !== '' ||
						this.state.taskNewDirection !== '' ||
						this.state.taskInfo.description !== '' ||
						this.state.severity !== null
					) {
						allCheck = true;
						inicialDateToEndDate = true;
					}
				}

				if (this.state.dateInit == null && this.state.dateEnd != null) {
					document.getElementById('programDate-alert-icon').style.display = 'inline';
					document.getElementById('programDate-red-input').style.borderColor = '#c70101';
					allCheck = false;
					inicialDateToEndDate = false;
				}
				if (this.state.dateInit != null && this.state.dateEnd == null) {
					document.getElementById('programDate-alert-icon').style.display = 'inline';
					document.getElementById('programDate-red-input').style.borderColor = '#c70101';
					allCheck = false;
					inicialDateToEndDate = false;
				}

				isValidDescription = this.validateDesciption(this.state.taskInfo.description);
				isValidTypeTask = this.validateTypeTask(this.state.taskInfo.taskType);
				isValidClientNameLastnameMovileEmail = this.isValidClientNameLastnameMovileEmail(
					this.state.clientName,
					this.state.clientLastName,
					this.state.clientMovileNumber,
					this.state.clientEmail
				);
				isValidDirection = this.validateDirection(this.state.taskNewDirection);
				isValidPriority = this.validatePriority(this.state.taskInfo.severity);

				if (
					isValidDescription &&
					isValidTypeTask &&
					isValidClientNameLastnameMovileEmail &&
					isValidDirection &&
					isValidPriority &&
					inicialDateToEndDate
				) {
					//SEND REQUEST TO SERVER
					document.getElementById('register-form-hint').style.display = 'none';
					allCheck = true;
				} else {
					document.getElementById('register-form-hint').style.display = 'inline';
					allCheck = false;
				}
			} else {
				allCheck = true;
			}
		}

		if (
			allCheck == true &&
			this.state.mailIntegrationFunctionality &&
			this.props.taskInfo.task.status == TaskStatus.REVIEW &&
			this.props.taskInfo.task.serviceTypeId == FielderConst.TASK_BY_MAIL &&
			this.state.taskInfo.taskType == FielderConst.TASK_BY_MAIL
		) {
			allCheck = false;
			document.getElementById('taskType-form-hint').style.display = 'inline';
		} else {
			document.getElementById('taskType-form-hint').style.display = 'none';
		}

		if (
			allCheck == true &&
			this.state.mailIntegrationFunctionality &&
			this.props.taskInfo.task.status == TaskStatus.REVIEW &&
			this.props.taskInfo.task.serviceTypeId == FielderConst.TASK_BY_MAIL &&
			RoleUtils.isUserManager(this.props.user) &&
			this.state.idTeam == null
		) {
			allCheck = false;
			document.getElementById('idTeam-form-hint').style.display = 'inline';
		} else {
			document.getElementById('idTeam-form-hint').style.display = 'none';
		}

		if (allCheck === true) {
			var newDateInit = new Date(this.state.dateInit);
			var startRangeYear = null;
			var startRangeMonth = null;
			var startRangeDay = null;
			var startRangeHour = null;
			var startRangeMinute = null;

			var newDateEnd = new Date(this.state.dateEnd);
			var endRangeYear = null;
			var endRangeMonth = null;
			var endRangeDay = null;
			var endRangeHour = null;
			var endRangeMinute = null;

			if (this.state.dateInit != null) {
				startRangeYear = newDateInit.getFullYear();
				startRangeMonth = newDateInit.getMonth();
				startRangeDay = newDateInit.getDate();
				startRangeHour = newDateInit.getHours();
				startRangeMinute = newDateInit.getMinutes();
			}

			if (this.state.dateEnd != null) {
				endRangeYear = newDateEnd.getFullYear();
				endRangeMonth = newDateEnd.getMonth();
				endRangeDay = newDateEnd.getDate();
				endRangeHour = newDateEnd.getHours();
				endRangeMinute = newDateEnd.getMinutes();
			}

			var datePass = {
				startRangeHour: startRangeHour,
				startRangeMinute: startRangeMinute,
				startRangeDay: startRangeDay,
				startRangeMonth: startRangeMonth,
				startRangeYear: startRangeYear,
				endRangeHour: endRangeHour,
				endRangeMinute: endRangeMinute,
				endRangeDay: endRangeDay,
				endRangeMonth: endRangeMonth,
				endRangeYear: endRangeYear,
			};

			if (this.state.dateInit != null) {
				var dateInitM = new Date(this.state.dateInit);
				var dateInitMiliseconds = dateInitM.getTime();

				var dateEndM = new Date(this.state.dateEnd);
				var dateEndMiliseconds = dateEndM.getTime();
			}

			var idUserSelected = this.state.idClient;
			var clientName = this.state.clientName;
			var clientLastName = this.state.clientLastName;
			var clientEmail = this.state.clientEmail;

			var idBranch = this.state.idBranch;

			if (idBranch) {
				idUserSelected = null;
				clientName = null;
				clientLastName = null;
				clientEmail = null;
			} else {
				idBranch = null;
			}

			var date = new Date();
			var milliseconds = date.getTime();

			var tagsLowerCase = [];
			for (var i = 0; i < this.state.tags.length; i++) {
				tagsLowerCase.push(this.state.tags[i].toLowerCase());
			}

			var updateAdress = this.validateChangeAddress(
				this.props.taskInfo.task.startLocation,
				this.state.clientLatitude,
				this.state.clientLongitud
			);

			var taskPass = {
				//ID task
				idTask: this.props.taskInfo.task.idTask,
				startTimeSort: this.props.taskInfo.task.startTimeSort,
				//Range Date
				startDateTimeRange: dateInitMiliseconds,
				endDateTimeRange: dateEndMiliseconds,

				description: this.state.taskInfo.description,
				createTime: milliseconds,
				status: this.props.taskInfo.task.status,
				severity: this.state.taskInfo.severity,
				serviceTypeId: this.state.taskInfo.taskType,
				folio: this.state.taskInfo.folio,
				caseNumber:  this.numberCaseRef.current ?  this.numberCaseRef.current : null,
				startTime: this.props.taskInfo.task.startTime,
				//Tags
				tags: tagsLowerCase,

				//Client
				idUser: idUserSelected,

				clientName: clientName,
				clientLastName: clientLastName,
				clientEmail: clientEmail,

				//Branch
				idBranch: idBranch,
				idTeam: this.state.idTeam,

				idLocation: this.props.taskInfo.task.startLocation.idLocation,
				address: this.state.taskNewDirection,
				state: this.state.state,
				country: this.state.country,
				postalCode: this.state.postalCode,
				latitude: this.state.clientLatitude,
				longitud: this.state.clientLongitud,
				routeVariables: this.state.taskTypes.taskTypeSelected ? this.state.taskTypes.taskTypeSelected.routeVariables : null,
				updateAdress: updateAdress,
			};

			const body = model.getNewTaskInfoUpdate(datePass, taskPass);
			body.taskInfo.task.idAccount = this.props.user.idAccount;

			const loadingConfig = { dispatch: this.props.dispatch };
			const onSuccess = () => this.props.onCloseModal(this.state.executeCounter);
			updateTaskRequest(body, onSuccess, null, loadingConfig);
		}
	};

	/** FIRST DATE  **/

	handleDateInit = (dateInitPiker) => {
		if (dateInitPiker == null) {
			if (this.state.dateEnd != null) {
				this.setState({
					dateEnd: dateInitPiker,
					readOnly: true,
				});
			}

			this.setState({
				dateInit: null,
				dateEnd: null,
				readOnly: true,
			});
		} else {
			if (this.state.dateEnd != null) {
				this.setState({
					dateEnd: dateInitPiker,
					readOnly: true,
				});
			}

			var dateDay = dateInitPiker.getDate();
			var dateMonth = dateInitPiker.getMonth();
			var dateYear = dateInitPiker.getFullYear();

			var compareDateDay = compareDate.getDate();
			var compareDateMonth = compareDate.getMonth();
			var compareDateYear = compareDate.getFullYear();

			if (compareDateDay == dateDay && compareDateMonth == dateMonth && compareDateYear == dateYear) {
				if (dateInitPiker < compareDate) {
					this.setState({ dateInit: compareDate, hourValidationInitDate: compareDate });
				} else {
					this.setState({ dateInit: dateInitPiker });
				}
			} else {
				this.setState({
					dateInit: dateInitPiker,
					hourValidationInitDate: new Date().setHours(0, 0),
				});
			}

			this.setState({
				readOnly: false,
				validateDateInit: true,
				hourValidationEndDate: compareDate,
			});

			var dateInitPikerHours = dateInitPiker.getHours();
			var dateInitPikerMinutes = dateInitPiker.getMinutes();

			if (dateInitPikerHours == 0 && dateInitPikerMinutes == 0) {
				this.setState({
					hourValidationEndDate: dateInitPiker.setHours(0, 0),
				});
			} else {
				this.setState({
					hourValidationEndDate: dateInitPiker,
				});
			}
		}
	};

	/** END DATE*/
	handleDateEnd = (dateEndPicker) => {
		this.setState({ hourValidationEndDate: this.state.dateInit });

		if (dateEndPicker === null) {
			this.setState({
				dateEnd: dateEndPicker,
			});
		} else {
			var dateDay = dateEndPicker.getDate();
			var dateMonth = dateEndPicker.getMonth();
			var dateYear = dateEndPicker.getFullYear();

			var compareDateInit = this.state.dateInit;

			var compareDateDay = compareDateInit.getDate();
			var compareDateMonth = compareDateInit.getMonth();
			var compareDateYear = compareDateInit.getFullYear();

			if (compareDateDay == dateDay && compareDateMonth == dateMonth && compareDateYear == dateYear) {
				this.setState({ hourValidationEndDate: this.state.dateInit });
				if (dateEndPicker <= compareDateInit) {
					this.setState({ dateEnd: compareDateInit, hourValidationEndDate: this.state.dateInit });
				} else {
					this.setState({ dateEnd: dateEndPicker });
				}
			} else {
				if (dateEndPicker <= compareDateInit) {
					this.setState({ dateEnd: compareDateInit });
				} else {
					this.setState({
						dateEnd: dateEndPicker,
						hourValidationEndDate: new Date().setHours(0, 0),
					});
				}
				this.setState({
					hourValidationEndDate: new Date().setHours(0, 0),
				});
			}

			this.setState({ validateDateEnd: true });
			this.setValidateDateProgram();
		}

		if (this.state.programDate == null) {
			this.setState({ disabledRecurrentTask: true });
		}
	};

	/************************************************/

	//Get array of daily dates

	getDateArray(start, end, numberOfDays) {
		var dateArr = new Array();

		var endT = new Date(end);
		endT.setDate(endT.getDate() + numberOfDays);

		var dt = new Date(start);

		while (dt < endT) {
			dateArr.push(dt.getTime());
			dt.setDate(dt.getDate() + numberOfDays);
		}
		return dateArr;
	}

	getDateArrayWeekly(start, end, numberOfDays) {
		var dateArr = new Array();

		var endT = new Date(end);
		endT.setDate(endT.getDate() + numberOfDays);

		var dt = new Date(start);

		while (dt < endT) {
			dateArr.push(dt.getTime());
			dt.setDate(dt.getDate() + numberOfDays);
		}
		return dateArr;
	}

	getDateArrayMonth(start, end, optionName) {
		let dateArr = new Array();
		let endT = new Date(end);
		let dt = new Date(start);
		endT.setDate(endT.getDate() + 1);

		while (dt <= endT) {
			dateArr.push(dt.getTime());
			const future_date = moment(dt).add(1, optionName);
			const toDateFormat = new Date(future_date);
			dt = toDateFormat;
		}
		return dateArr;
	}

	getDateArrayDailyMondaytoFriday(start, end) {
		let dateArr = new Array();
		let endT = new Date(end);
		let dt = new Date(start);
		endT.setDate(endT.getDate() + 1);

		while (dt <= endT) {
			var weekday = new Array(7);
			weekday[0] = 'Sunday';
			weekday[1] = 'Monday';
			weekday[2] = 'Tuesday';
			weekday[3] = 'Wednesday';
			weekday[4] = 'Thursday';
			weekday[5] = 'Friday';
			weekday[6] = 'Saturday';

			var nameday = weekday[dt.getDay()];

			if (
				nameday === 'Monday' ||
				nameday === 'Tuesday' ||
				nameday === 'Wednesday' ||
				nameday === 'Thursday' ||
				nameday === 'Friday'
			) {
				dateArr.push(dt.getTime());
			}

			var future_date = moment(dt).add(1, 'days');
			var toDateFormat = new Date(future_date);
			dt = toDateFormat;
		}
		return dateArr;
	}

	//To Date
	handletoDate = (date) => {
		var dateArr = [];

		if (optionRecurentTask == 1) {
			var numberOfDays = 1;
			dateArr = this.getDateArray(this.state.programDate, date, numberOfDays);
		}

		if (optionRecurentTask == 2) {
			var numberOfDays = 7;
			dateArr = this.getDateArrayWeekly(this.state.programDate, date, numberOfDays);
		}

		if (optionRecurentTask == 3) {
			var optionName = 'months';
			dateArr = this.getDateArrayMonth(this.state.programDate, date, optionName);
		}

		if (optionRecurentTask == 4) {
			var optionName = 'years';
			dateArr = this.getDateArrayMonth(this.state.programDate, date, optionName);
		}

		if (optionRecurentTask == 5) {
			dateArr = this.getDateArrayDailyMondaytoFriday(this.state.programDate, date);
		}

		var i;
		var recurrentDatesMap = [];

		for (i = 0; i < dateArr.length; i++) {
			var milliseconds = new Date(dateArr[i]);

			var month = milliseconds.getMonth();
			var hour = milliseconds.getHours();
			var year = milliseconds.getFullYear();
			var day = milliseconds.getDate();
			var minute = milliseconds.getMinutes();

			var listDates = {
				month: month,
				hour: hour,
				year: year,
				day: day,
				minute: minute,
			};

			recurrentDatesMap.push(listDates);
		}

		this.setState({
			toDate: date,
			recurrentDatesMap: recurrentDatesMap,
		});
	};

	setValidateTypeTask = () => {
		if (this.state.taskInfo.taskType !== null) {
			document.getElementById('taskType-alert-icon').style.display = 'none';
			document.getElementById('taskType-red-input').style.borderColor = '#ced4da';
		}
	};

	setValidateDescription() {
		if (this.state.taskInfo.taskType !== '') {
			document.getElementById('description-alert-icon').style.display = 'none';
			document.getElementById('description-red-input').style.borderColor = '#ced4da';
		}
	}

	setValidateSeverity = () => {
		if (this.state.taskInfo.taskType !== '') {
			document.getElementById('severity-alert-icon').style.display = 'none';
			document.getElementById('severity-red-input').style.borderColor = '#ced4da';
		}
	};

	setValidateDateProgram() {
		if (this.state.validateDateInit == true || this.state.validateDateEnd == true) {
			document.getElementById('programDate-alert-icon').style.display = 'none';
			document.getElementById('programDate-red-input').style.borderColor = '#ced4da';
		}
	}

	unlockToDate() {
		if (this.state.taskInfo.recurentTask != null) {
			this.setState({ unlockToDate: false });
		}
	}
	/**
	 * @desc This function receives two strings and changes
	 * the state of the userInfo object depending on
	 * the second parameter that it receives
	 *
	 * @param {string} value - value string param
	 * @param {string} field - field string param
	 *
	 *
	 **/
	setFieldValue = (value, field) => {
		let state = this.state;

		switch (field) {
			case 'taskType':
				state.taskInfo.taskType = value;
				this.setState(state);
				this.setValidateTypeTask();
				break;
			case 'description':
				state.taskInfo.description = value;
				this.setState(state);
				this.setValidateDescription();
				break;
			case 'folio':
				state.taskInfo.folio = value;
				this.setState(state);
				break;
			case 'severity':
				state.taskInfo.severity = value;
				this.setState(state);
				this.setValidateSeverity();
				break;
			case 'recurentTask':
				state.taskInfo.recurentTask = value;
				this.setState(state);
				optionRecurentTask = value;

				if (this.state.toDate) {
					this.handletoDate(this.state.toDate);
				}

				this.unlockToDate();
				break;
			case 'volumenTask': /// Not used but was left to refactor with an 'utils' function  and remember to consider this part
				let volume = value > 300 ? 300 : value;
				state.taskInfo.volumenTask = volume;
				this.setState(state);
				break;
			case 'idCustom':
				state.taskInfo.idCustom = value;
				this.setState(state);
				break;
		}
	};

	/**
	 *  @desc Handler for the changing recurrent tasks button,
	 *
	 *  Closes the Checkbox-volume menu to only allow recurrent
	 *  task options to be active
	 */
	changeRecurrentTask = () => {
		var cbrecurrent = document.getElementById('checkbox-recurrent-task');
		var cbvolume = document.getElementById('checkbox-volume');

		if (this.state.programDate !== null) {
			this.setState({ recurrentTask: !this.state.recurrentTask });
			this.setState({ volume: false });

			cbrecurrent.checked = this.state.recurrentTask;
			cbvolume.checked = false;
		}
	};

	/**
	 *  @desc Handler for the changing volume tasks button,
	 *
	 *  Closes the Checkbox-recurrent-task menu to only
	 *  allow volume task options to be active
	 */
	changeVolume = () => {
		var cbvolume = document.getElementById('checkbox-volume');
		var cbrecurrent = document.getElementById('checkbox-recurrent-task');

		this.setState({ volume: !this.state.volume });
		this.setState({ recurrentTask: false });

		cbvolume.checked = this.state.volume;
		cbrecurrent.checked = false;
	};

	/**
	 *  Creates the options for the Select with Countries elements from the
	 *  countries file
	 *  @see utils/countries
	 */
	createCountries = () => {
		return countries.map((element, index) => {
			return (
				<option key={element} value={element}>
					{element}
				</option>
			);
		});
	};

	/**
	 *  function used in the Personalization tab to Remove a type of task
	 *  from the list, by splicing the array of task types adn updating the
	 *  state
	 *
	 * @param {number} index - Index of the Task Type to be removed
	 */
	removeTag = (index) => {
		var tags = this.state.tags;
		tags.splice(index, 1);

		this.setState({ tags: tags });
	};

	/**
	 *  Function used in the Personalization tab to add a new type of task
	 *  entered by the user. It adds it to a new array and updates the state.
	 *  Receives the task by reading the input value
	 */
	addTag = () => {
		var input = document.getElementById('input-tags-edit-task');
		var newTag = input.value;
		var tagList = this.state.tags;

		if (!tagList.includes(newTag)) {
			// Add new tag
			tagList.push(newTag);

			// Reset input and send new type to state
			this.setState({ tags: tagList });
		}
		input.value = '';
	};

	/**
	 *  Function used in the Personalization tab to generate the Task types
	 *  objects by reading the state taskTypes array
	 */
	createTags = () =>
		this.state.tags.map((type, i) => (
			<Col sm={'auto'} className='m-1' key={type + '-tag'}>
				<Row className='tag-button my-auto'>
					<div className='mt-1'>
						<Container key={type + '-tag-container'}>
							<span className='mr-3'>#{type.toLowerCase()} </span>
							<button
								key={'btn-close-' + i}
								id={'btn-close-' + i}
								className='close'
								aria-label='Close'
								onClick={() => this.removeTag(i)}
								disabled={this.state.disabledAllInput}
							>
								<span style={{ fontSize: '20px' }} aria-hidden='true'>
									&times;
								</span>
							</button>
						</Container>
					</div>
				</Row>
			</Col>
		));


	setBranchInfo(branch) {
		var branchName,
			branchCompanyName,
			idBranch,
			state,
			country,
			postalCode,
			clientLatitude,
			clientLongitud,
			clientTimeZone,
			taskNewDirection;

		if (branch) {
			//When a Task comes from a Client with Branch
			branchName = branch.name;
			branchCompanyName = branch.company ? branch.company.name : '';
			idBranch = branch.id;
			state = branch.location.state;
			country = branch.location.country;
			postalCode = branch.location.postalCode;
			clientLatitude = branch.location.latitude;
			clientLongitud = branch.location.longitud;
			clientTimeZone = branch.location.timeZone;
			taskNewDirection = branch.location.address;
			this.obtainDirectionData(clientLatitude, clientLongitud);
		}

		this.setState({
			branchName: branchName,
			branchCompanyName: branchCompanyName,
			idBranch: idBranch,
			state: state,
			country: country,
			postalCode: postalCode,
			clientLatitude: clientLatitude,
			clientLongitud: clientLongitud,
			clientTimeZone: clientTimeZone,
			taskNewDirection: taskNewDirection,
			idClient: null,
			changedMapPosition: true,
		});
	}

	setClientBranchInfo(user, branch) {
		this.setBranchInfo(branch);
		this.setState({
			clientName: user.name,
			clientLastName: user.lastName,
			clientMovileNumber: user.mobileNumber,
			clientEmail: user.email,
		});
	}

	setClientInfo(user) {
		this.obtainDirectionData(user.location.latitude, user.location.longitud);
		this.setState({
			clientName: user.name,
			clientLastName: user.lastName,
			clientMovileNumber: user.mobileNumber,
			clientEmail: user.email,

			idClient: user.idUser,

			clientLatitude: user.location.latitude,
			clientLongitud: user.location.longitud,
			clientTimeZone: user.location.timeZone,
			taskNewDirection: user.location.address,
			changedMapPosition: true,
			idBranch: null,
		});
	}

	///SET BRANCH VALUES
	setFieldBranch = (event) => {
		if (event != null) {
			document.getElementById('branch_data-alert-icon').style.display = 'none';
			document.getElementById('branch_data-red-input').style.borderColor = '#ced4da';
			document.getElementById('branch_data-red-input-2').style.borderColor = '#ced4da';

			document.getElementById('direction-alert-icon').style.display = 'none';
			document.getElementById('direction-red-input').style.borderColor = '#ced4da';

			this.setBranchInfo(event.value);
			this.getTaskTypes(event.value.id, null, true);
			this.getTaskPriority(event.value.id, null, null);
		} else {
			this.setState({
				branchName: '',
				branchCompanyName: '',
				idBranch: null,
			});
		}
	};

	///SET CLIENTS VALUES
	setFieldClients = (event) => {
		if (event != null) {
			document.getElementById('client_data-alert-icon').style.display = 'none';
			document.getElementById('client_data-red-input').style.borderColor = '#ced4da';
			document.getElementById('client_data-red-input-2').style.borderColor = '#ced4da';
			document.getElementById('client_data-red-input-3').style.borderColor = '#ced4da';
			document.getElementById('client_data-red-input-4').style.borderColor = '#ced4da';

			document.getElementById('direction-alert-icon').style.display = 'none';
			document.getElementById('direction-red-input').style.borderColor = '#ced4da';

			let idClient = null;
			let idBranch = null;
			if (event.value.branch) {
				this.setClientBranchInfo(event.value, event.value.branch);
				idBranch = event.value.branch.id;
			} else {
				this.setClientInfo(event.value);
				idClient = event.value.idUser;
			}
			this.getTaskTypes(idBranch, idClient);
			this.getTaskPriority(null, idClient, null);
		} else {
			this.setState({
				clientName: '',
				clientLastName: '',
				clientMovileNumber: '',
				clientEmail: '',
				idClient: null,

				changedMapPosition: true,
			});
		}
	};

	setFieldAssign = (event) => {
		if (event != null) {
			this.setState({ idTeam: event.value.id });
		} else {
			this.setState({ idTeam: null });
		}
	};

	markerChanged = (cordinates) => {
		this.setState({
			clientLatitude: cordinates.lat,
			clientLongitud: cordinates.lng,
			changedMapPosition: true,
			showWarningAddress: true,
		});

		Geocode.fromLatLng(cordinates.lat, cordinates.lng).then(
			(response) => {
				const address = response.results[0].formatted_address;

				var i;
				let state;
				let country;
				let postalCode;

				for (let i = 0; i < response.results[0].address_components.length; i++) {
					if (response.results[0].address_components[i].types[0] === 'administrative_area_level_1') {
						state = response.results[0].address_components[i].long_name;
					}

					if (response.results[0].address_components[i].types[0] === 'country') {
						country = response.results[0].address_components[i].long_name;
					}

					if (response.results[0].address_components[i].types[0] === 'postal_code') {
						postalCode = response.results[0].address_components[i].long_name;
					}
				}

				this.setState({
					taskNewDirection: address,
					state: state,
					country: country,
					postalCode: postalCode,
				});
			},
			(error) => {}
		);
	};

	/*        Autocomplete cordinates            */
	onPlaceSelected = (place) => {
		if (place !== null) {
			document.getElementById('direction-alert-icon').style.display = 'none';
			document.getElementById('direction-red-input').style.borderColor = '#ced4da';
		}

		const { address, coordinates, country, state, postalCode } = place;

		this.setState({
			state,
			country,
			postalCode,
			taskNewDirection: address,
			clientLatitude: coordinates.lat,
			clientLongitud: coordinates.lng,
			showWarningAddress: true
		});
	};

	getClientList = async (event) => {
		if (event.length > 0) {
			let assignList = [];
			assignList = await getClientsByFilterAsyncSelect(
				event,
				this.state.currentUser.idAccount,
				this.state.currentUser,
				getIdToken()
			);
			this.setState({ clientListArray: assignList });
			return assignList;
		}
	};

	getInitialOptionsClient = async () => {
		return await getClientInitialOptionsAsyncSelect(this.state.idAccount, this.state.idUser, getIdToken());
	};

	getBranchList = async (event) => {
		if (event.length > 0) {
			let assignList = [];
			assignList = await getBranchesByFilterAsyncSelectCreateTask(
				event,
				this.state.currentUser.idAccount,
				this.state.currentUser,
				getIdToken()
			);
			this.setState({ branchListArray: assignList });
			return assignList;
		}
	};

	getInitialOptionsBranch = async () => {
		return await getBranchesInitialOptionsAsyncSelect(
			this.state.currentUser.idAccount,
			this.state.currentUser,
			getIdToken()
		);
	};

	/****************************************************************************/
	getAssignList = async (event) => {
		if (event.length > 0) {
			let assignList = [];

			assignList = await getTeamsByFilterAsyncSelect(event, this.state.idAccount, this.state.idUser, getIdToken(), this.props.lan);
			return assignList;
		}
	};

	getInitialOptionsTeam = async () => {
		return await getTeamsInitialOptionsAsyncSelect(this.state.idAccount, this.state.idUser, getIdToken(), this.props.lan);
	};

	/****************************************************************************/

	setBranchId = (event) => {
		if (event != null) {
			this.setState({ changedMapPosition: true });
			document.getElementById('branch_data-alert-icon').style.display = 'none';
			document.getElementById('branch_data-red-input').style.borderColor = '#ced4da';
			document.getElementById('branch_data-red-input-2').style.borderColor = '#ced4da';

			document.getElementById('direction-alert-icon').style.display = 'none';
			document.getElementById('direction-red-input').style.borderColor = '#ced4da';

			this.setBranchInfo(event.value);
		} else {
			this.setState({
				branchName: '',
				branchCompanyName: '',
				idBranch: null,
			});
		}
	};

	validateBranchsArray = (idBranch) => {
		let idBranches = [this.props.taskInfo.task.branch.id];

		var newArrayId = false;
		for (let i = 0; i < idBranches.length; i++) {
			if (idBranch === idBranches[i]) {
				newArrayId = true;
				return newArrayId;
			}
		}

		return newArrayId;
	};

	handleChangeDirection = (event) => this.setState({ taskNewDirection: event.target.value, showWarningAddress: true });
	closeModal = () => this.setState({ showModalInfoTask: false, showModalInfoTaskVariables: false });
	handleShowModalInfoTask = () => this.setState({ showModalInfoTask: !this.state.showModalInfoTask });
	handleShowModalInfoTaskVariables = () => this.setState({ showModalInfoTaskVariables: !this.state.showModalInfoTaskVariables });


	render() {


		return (
			<div className='row'>
				{/* First Half of the page */}
				<div className='col'>
					{!RoleUtils.isUserClient(this.props.user) && this.state.iAmNotPartner && (
						<Col sm={12} className='mb-2 title-padding text-title'>
							<span>{this.props.lan.select}</span>
						</Col>
					)}

					{RoleUtils.isUserClient(this.props.user) && this.props.user.branches != null && (
						<Col sm={12} className='mb-1 title-padding text-title'>
							<span>{this.props.lan.select + ' ' + this.props.lan.branch} </span>
						</Col>
					)}

					{RoleUtils.isUserClient(this.props.user) && this.props.user.branches != null && (
						<React.Fragment>
							<Select
								defaultValue={
									this.props.taskInfo.task.branch.id != null
										? this.state.branchListArrayClient.filter((option) => this.validateBranchsArray(option.value.id))
										: null
								}
								cacheOptions
								defaultOptions
								options={this.state.branchListArrayClient}
								onChange={this.setBranchId}
								placeholder={this.props.lan.placeHolderBranch}
								className='react-select__control full-width mb-1'
								isSearchable={true}
								isClearable={false}
							/>
						</React.Fragment>
					)}

					<Row>
						<Col>
							{!RoleUtils.isUserClient(this.props.user) && this.state.iAmNotPartner && (
								<Card hidden={this.state.hideBranchOnArriveStatus}>
									<Row className='mx-auto mt-2'>
										<input
											type='checkbox'
											className='form-check-input labelCheckbox d-none'
											id='checkbox-branch'
											name='checkbox-branch'
											ref='cbbranch'
											checked={this.state.showBranchSearch}
										/>

										<label
											htmlFor='checkbox-branch'
											className='square-checkbox ml-2'
											onClick={() => {
												this.refs.cbclient.checked = false;
												this.setState({
													showClientSearch: false,
													showBranchSearch: true,
												});
											}}
										/>
										<div className='tag ml-4' style={{ textAlign: 'center' }}>
											{this.props.lan.branch}
										</div>
									</Row>
								</Card>
							)}
						</Col>
						<Col>
							{!this.state.onSelectGroupDisabled &&
								!RoleUtils.isUserClient(this.props.user) &&
								this.state.iAmNotPartner && (
									<Card hidden={this.state.hideOnTaskGroup}>
										<Row className='mx-auto mt-2'>
											<input
												type='checkbox'
												className='form-check-input labelCheckbox d-none'
												id='checkbox-client'
												name='checkbox-client'
												ref='cbclient'
												checked={this.state.showClientSearch}
											/>
											<label
												htmlFor='checkbox-client'
												className='square-checkbox ml-2'
												onClick={() => {
													this.refs.cbbranch.checked = false;
													this.setState({
														showClientSearch: true,
														showBranchSearch: false,
													});
												}}
											/>
											<div className='tag ml-4' style={{ textAlign: 'center' }}>
												{this.props.lan.client}
											</div>
										</Row>
									</Card>
								)}
						</Col>
					</Row>

					{!RoleUtils.isUserClient(this.props.user) && this.state.iAmNotPartner && (
						<Col sm={12} className='mb-1 title-padding text-title'>
							<span>{this.props.lan.searchBy}</span>
						</Col>
					)}

					<React.Fragment>
						{this.state.showBranchSearch && !RoleUtils.isUserClient(this.props.user) && this.state.iAmNotPartner && (
							<AsyncSearch
								className='react-select__control full-width mb-1'
								getOptions={this.getBranchList}
								onChange={this.setFieldBranch}
								label={this.props.lan.placeHolderBranch}
								lan={this.props.lan}
								disabled={this.state.disabledAllInput}
								id='select-add-branch'
								showInitialOptions
								getInitialOptions={this.getInitialOptionsBranch}
							/>
						)}
						{(this.state.showBranchSearch || (RoleUtils.isUserClient(this.props.user) && this.props.user.branch)) && (
							<div>
								<img
									src={redAlertIcon}
									alt=''
									id='branch_data-alert-icon'
									className='CreateTask-select-fieldContainer-icon'
								/>
								<Row>
									<Col key='col-task-name-branch' className='mb-1 '>
										<span className='title-padding text-title'>{this.props.lan.nameBranch}</span>
										<input
											id='branch_data-red-input'
											type='text'
											key='task-branch-name'
											className='form-control text-left'
											placeholder={this.props.lan.nameBranch}
											value={this.state.branchName}
											readOnly
										/>
									</Col>
								</Row>
								<Row>
									<Col key='col-task-name-company-branch' className='mb-1'>
										<span className='title-padding text-title'>{this.props.lan.nameCompany}</span>
										<input
											id='branch_data-red-input-2'
											type='text'
											key='task-branch-name-company'
											className='form-control text-left'
											placeholder={this.props.lan.nameCompany}
											value={this.state.branchCompanyName}
											readOnly
										/>
									</Col>
								</Row>
							</div>
						)}
					</React.Fragment>

					<React.Fragment>
						{this.state.showClientSearch && !RoleUtils.isUserClient(this.props.user) && this.state.iAmNotPartner && (
							<AsyncSearch
								className='react-select__control full-width mb-1'
								getOptions={this.getClientList}
								onChange={this.setFieldClients}
								label={this.props.lan.placeHolderClient}
								lan={this.props.lan}
								disabled={this.state.disabledAllInput}
								id='select-add-client'
								showInitialOptions
								getInitialOptions={this.getInitialOptionsClient}
							/>
						)}

						{(this.state.showClientSearch || (RoleUtils.isUserClient(this.props.user) && !this.props.user.branch)) && (
							<div>
								<img
									src={redAlertIcon}
									alt=''
									id='client_data-alert-icon'
									className='CreateTask-select-fieldContainer-icon'
								/>
								<Row className='mb-1'>
									<Col key='col-task-client-name'>
										<span className='title-padding text-title'>{this.props.lan.nameClient}</span>
										<input
											id='client_data-red-input'
											type='text'
											key='task-client-name'
											className='form-control text-left'
											placeholder={this.props.lan.nameClient}
											value={this.state.clientName}
											readOnly
										/>
									</Col>
									<Col key='col-task-client-lastname'>
										<span className='title-padding text-title'>{this.props.lan.lastNameClient}</span>
										<input
											id='client_data-red-input-2'
											type='text'
											key='task-client-lastname'
											className='form-control text-left'
											placeholder={this.props.lan.lastNameClient}
											value={this.state.clientLastName}
											readOnly
										/>
									</Col>
								</Row>

								<Row className='mb-1'>
									<Col className='mb-1' key='col-task-client-movile-phone'>
										<span className='title-padding text-title'>{this.props.lan.cellphoneClient}</span>
										<input
											id='client_data-red-input-3'
											type='text'
											key='task-client-phone'
											className='form-control text-left'
											placeholder={this.props.lan.cellphoneClient}
											value={this.state.clientMovileNumber}
											readOnly
										/>
									</Col>
									<Col className='mb-1' key='col-task-client-email'>
										<span className='title-padding text-title'>{this.props.lan.emailClient}</span>
										<input
											id='client_data-red-input-4'
											type='text'
											key='task-client-email'
											className='form-control text-left'
											placeholder={this.props.lan.emailClient}
											value={this.state.clientEmail}
											readOnly
										/>
									</Col>
								</Row>
							</div>
						)}
					</React.Fragment>

					{this.state.validateShowTeam && (
						<React.Fragment>
							<Col sm={12} className='mb-1 title-padding text-title'>
								<span> {this.props.lan.team} </span>
							</Col>

							<AsyncSearch
								className='my-0'
								getOptions={this.getAssignList}
								onChange={this.setFieldAssign}
								label={this.state.searchLabel}
								lan={this.props.lan}
								id='all-AsyncSearch'
								showInitialOptions
								getInitialOptions={this.getInitialOptionsTeam}
							/>
						</React.Fragment>
					)}

					<Col className='col-12 mb-1 create-task-dropdown-size' key='col-task-taskType'>

						<SelectTaskType
							taskTypeOptions={this.state.taskTypes}
							updateTaskType={this.updateSelectedTaskType}
							selectedType={this.state.taskTypes.taskTypeSelected}
							dontUseMargin={true}
						/>



						<img src={redAlertIcon} alt='' id='taskType-alert-icon' className='CreateTask-fieldContainer-icon' />
					</Col>

					<div className='row mb-1'>
						<Col key='col-map' className='mt-1 mb-1 mx-auto'>
							<MapStaticComponent size='500x200'>
								<StaticMarker
									location={{
										lat: parseFloat(this.state.clientLatitude),
										lng: parseFloat(this.state.clientLongitud),
									}}
									icon={this.props.taskData.taskPin}
								/>
							</MapStaticComponent>
						</Col>
					</div>

					<Col key='col-task-direction' className='mb-1'>
						{this.state.showWarningAddress && (
							<div className='mb-1'>
								<Alert icon={false} severity='warning'>
									{this.props.lan.warningAddress}
								</Alert>
							</div>
						)}

						<GoogleMapsAutocomplete
							id='direction-red-input'
							fullWidth
							label={this.props.lan.directionClient}
							onSelect={this.onPlaceSelected}
							value={this.state.taskNewDirection}
							disabled={this.state.disableAutoCompleteMap}
						/>

						<img
							src={redAlertIcon}
							alt=''
							id='direction-alert-icon'
							className='CreateTask-dir-desc-fieldContainer-icon'
						/>
					</Col>
				</div>

				{/* Second Half of the Page */}
				<div className='col mx-1'>
					<Row className='mb-1'>
						<Col sm={12} className='mb-1' key='col-task-client-folio'>
							<span className='title-padding text-title'>{this.props.lan.idFielder}</span>
							<input
								type='text'
								key='task-client-phone'
								className='form-control text-left'
								placeholder={this.props.lan.idFielder}
								value={this.props.taskInfo.task.idTask}
								readOnly={true}
							/>
						</Col>
					</Row>

					<Row className='mb-1'>
						<Col sm={12} className='mb-1' key='col-task-client-folio'>
							<span className='title-padding text-title'>{this.props.lan.customId}</span>
							<input
								type='text'
								key='task-client-phone'
								className='form-control text-left'
								placeholder={this.props.lan.customId}
								value={this.state.taskInfo.idCustom}
								readOnly={true}
							/>
						</Col>
					</Row>

					<Row className='mb-1'>
						<Col sm={12} className='mb-1' key='col-task-client-folio'>
							<span className='title-padding text-title'>{this.props.lan.folio}</span>
							<input
								type='text'
								key='task-client-phone'
								className='form-control text-left'
								placeholder={this.props.lan.folio}
								value={this.state.taskInfo.folio}
								onChange={(e) => this.setFieldValue(e.target.value, 'folio')}
							/>
						</Col>
					</Row>

					<TaskCaseNumber colSize={12} ref={this.numberCaseRef} caseNumberValue={this.state.taskInfo.caseNumber}	/>

					<Col key='col-task-priority' className='mb-1 title-padding'>
						<span className='title-padding text-title mb-1'>{this.props.lan.priority}</span>
							<select
								id='severity-red-input'
								key='select-country'
								defaultValue={this.state.defaultPriority}
								value={this.state.taskInfo.severity}
								className='custom-select'
								onChange={(e) => this.setFieldValue(e.target.value, 'severity')}
								onClick={this.state.setValidateSeverity}
								disabled={this.state.disabledAllInput}
							>
	
								{this.state.taskPriorities.map(({ id, name }) => (
									<option key={id} value={id}>
										{this.getDetaultNameOrNormal(id, name)}
									</option>
								))}
							</select>
						<img
							src={redAlertIcon}
							alt=''
							id='severity-alert-icon'
							className='CreateTask-severity-fieldContainer-icon'
						/>
					</Col>

					<Row className='mb-1 '>
						<Col className='mb-1' key='col-task-time-execution'>
							<span className='title-padding text-title'>{this.props.lan.executionPeriod}</span>

							<Row>
								<Col sm={5}>
									<DatePicker
										selected={this.state.dateInit}
										onChange={this.handleDateInit}
										minDate={new Date()}
										timeFormat='HH:mm'
										showMonthDropdown
										showYearDropdown
										showTimeSelect
										minTime={this.state.hourValidationInitDate}
										maxTime={new Date().setHours(23, 59)}
										dateFormat='dd/MM/yyyy h:mm aa'
										timeIntervals={30}
										isClearable={true}
										placeholderText={this.props.lan.initialDate}
										className='form-control text-left'
										disabled={this.state.disabledAllInput}
									/>
								</Col>

								<Col sm={2} className='title-padding mt-2'>
									<span className='ml-2 title-padding text-title'>{this.props.lan.toDate}</span>
								</Col>

								<Col sm={5} className='title-padding'>
									<DatePicker
										selected={this.state.dateEnd}
										onChange={this.handleDateEnd}
										minDate={this.state.dateInit}
										timeFormat='HH:mm'
										showMonthDropdown
										showYearDropdown
										showTimeSelect
										minTime={this.state.hourValidationEndDate}
										maxTime={new Date().setHours(23, 59)}
										dateFormat='dd/MM/yyyy h:mm aa'
										timeIntervals={30}
										isClearable={true}
										placeholderText={this.props.lan.finalDate}
										className='form-control text-left'
										readOnly={this.state.readOnly}
										id='programDate-red-input'
										disabled={this.state.disabledAllInput}
									/>
									<img
										src={redAlertIcon}
										alt=''
										id='programDate-alert-icon'
										className='CreateTask-programDate-fieldContainer-icon'
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Col key='col-task-description' className='mb-1'>
						<span className='title-padding text-title'>{this.props.lan.description}</span>
						<textarea
							className='form-control text-left'
							rows='4'
							cols='40'
							id='description-red-input'
							placeholder={this.props.lan.description}
							value={this.state.taskInfo.description}
							onChange={(e) => this.setFieldValue(e.target.value, 'description')}
						/>
						<img
							src={redAlertIcon}
							alt=''
							id='description-alert-icon'
							className='CreateTask-dir-desc-fieldContainer-icon'
						/>
					</Col>

					<Col className='mt-3 ml-1'>
						<Row className='my-2'>
							<Col className='label-input title-padding text-title mb-1'>{`${this.props.lan.tags}: `}</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<div className='input-group'>
									<div className='input-group-prepend'>
										<div className='input-group-text hashtag-label '>#</div>
									</div>
									<input
										type='text'
										key='input-tags-edit-task'
										id='input-tags-edit-task'
										placeholder='tag'
										className='form-control'
									/>
								</div>
							</Col>
							<Col sm={6} className='d-flex justify-content-end'>
								<Image className='ml-3' src={AddIcon} alt='Agregar tag' onClick={this.addTag} />
							</Col>
						</Row>

						<Row className='ml-3'>
							<this.createTags />
						</Row>
					</Col>

					<TaskVariables
						hasRouteVariables={this.state.hasRouteVariables}
						taskType={this.state.taskTypes.taskTypeSelected}
						ref='routeVariablesRef'
						isNotAClient={!RoleUtils.isUserClient(this.props.user)}
					/>

					<div id='register-form-hint' className='Register-form-hint mt-3'>
						<p>*{this.props.lan.requiredFields}</p>
					</div>

					<div id='taskType-form-hint' className='Register-form-hint mt-2'>
						<p>*{this.props.lan.requiredTaskType}</p>
					</div>

					<div id='idTeam-form-hint' className='Register-form-hint mt-2'>
						<p>*{this.props.lan.requiredTeam}</p>
					</div>

					<Row className='justify-content-center mt-3'>
						<button className='Fielder-button' onClick={this.handleDoActionUpdateTask}>
							{this.props.lan.save}
						</button>
					</Row>
				</div>

			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
	user: state.utils.session.user,
});
export default connect(mapStateToProps)(EditorTask);
