/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { login, doSwitchAccount, getUserGlobalInfo } from '../../../api/user/user.service';
import { handleLogin } from '../../../utils/account/LoginAccountUtils';
import { loadingStart, loadingStop } from '../loading/loading.slice';
import { setTaskTypes } from '../taskTypes/taskTypes.slice';

const initialState = {
	user: null,
	account: null,
	validPages: null,
	accountPreferences: null,
	roles: null,
	request: {
		isLoading: false,
		isSuccess: false,
		isError: false,
		error: null,
	},
};

export const loginUser = createAsyncThunk('session/loginUser', async (userInfo, thunkAPI) => {
	const onSuccess = (response) => {
		const data = handleLogin(response);
		thunkAPI.dispatch(setTaskTypes(data.taskTypes));
		return thunkAPI.fulfillWithValue(data.session);
	};

	const onError = (error) => {	
		if(error.auxInfo){
			const data = handleLogin(error.auxInfo);
			return thunkAPI.fulfillWithValue(data.session);
		} else{
			return thunkAPI.rejectWithValue(error);
		} 
		
	};

	return login(userInfo, onSuccess, onError);
});

export const switchAccount = createAsyncThunk('session/switchAccount', async (user, thunkAPI) => {
	const onError = (error) => thunkAPI.rejectWithValue(error);

	thunkAPI.dispatch(loadingStart({ name: 'SWITCH_ACCOUNT' }));

	const res = await doSwitchAccount(user, null, onError);
	const { accessToken, idToken, refreshToken } = res;

	const onSuccess = (response) => {
		const { session } = handleLogin({ ...response, accessToken, idToken, refreshToken });
		return thunkAPI.fulfillWithValue(session);
	};

	return getUserGlobalInfo(user.userName, onSuccess, onError).then((res) => {
		thunkAPI.dispatch(loadingStop('SWITCH_ACCOUNT'))
		return res;
	});
});

const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setUserInfo: (state, action) => {
			const { status, profileImage, users } = action.payload;
			state.user.status = status;
			state.user.profileImage = { scalePath: profileImage };
			state.user.users = users;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setRoles: (state, action) => {
			state.roles = action.payload;
		},
		setUserPolicyTrue: (state) => {
			state.user.acceptPrivacyPolicy = true;
		},
		setAccount: (state, action) => {
			state.account.id = action.payload.id;
			state.account.name = action.payload.name;
			state.account.address= action.payload.address;
			state.account.phone = action.payload.phone;
			state.account.rfc = action.payload.rfc;
			state.account.country = action.payload.country;
			state.account.currency = action.payload.currency;
		},
		setAccountLogo: (state, action) => {
			state.account.logo = action.payload;
		},
		setLogOutUser: (state) => {
			// Remove the persistent storage
			storage.removeItem('persist:utils');

			state.user = null;
			state.roles = null;
			state.account = null;
			state.validPages = null;
			state.accountPreferences = null;
			state.request = {
				isLoading: false,
				isSuccess: false,
				isError: false,
				error: null,
			};
		},
		setResetSelectRequest: (state) => {
			state.request = {
				isLoading: false,
				isSuccess: false,
				isError: false,
				error: null,
			};
		},
	},
	extraReducers: {
		[loginUser.fulfilled]: (state, action) => {
			state.user = action.payload.user;
			state.roles = action.payload.roles;
			state.account = action.payload.account;
			state.validPages = action.payload.validPages;
			state.accountPreferences = action.payload.accountPreferences;
			state.request = {
				isSuccess: true,
				isLoading: false,
				isError: false,
				error: null,
			};
		},
		[loginUser.pending]: (state) => {
			state.request = {
				isLoading: true,
				isSuccess: false,
				isError: false,
				error: null,
			};
		},
		[loginUser.rejected]: (state, action) => {
			state.user = null;
			state.roles = null;
			state.account = null;
			state.validPages = null;
			state.accountPreferences = null;
			state.request = {
				isSuccess: false,
				isError: true,
				error: action.payload,
			};
		},
		[switchAccount.fulfilled]: (state, action) => {
			state.user = action.payload.user;
			state.roles = action.payload.roles;
			state.account = action.payload.account;
			state.validPages = action.payload.validPages;
			state.accountPreferences = action.payload.accountPreferences;
			state.request = {
				isSuccess: true,
				isLoading: false,
				isError: false,
				error: null,
			};
		},
		[switchAccount.pending]: (state) => {
			state.request = {
				isLoading: true,
				isSuccess: false,
				isError: false,
				error: null,
			};
		},
		[switchAccount.rejected]: (state, action) => {
			state.user = null;
			state.roles = null;
			state.account = null;
			state.validPages = null;
			state.accountPreferences = null;
			state.request = {
				isSuccess: false,
				isError: true,
				error: action.payload,
			};
		},
	},
});

export const {
	setRoles,
	setUser,
	setUserInfo,
	setUserPolicyTrue,
	setLogOutUser,
	setResetSelectRequest,
	setAccountPreferences,
	setAccountLogo,
	setAccount,
} = sessionSlice.actions;

export default sessionSlice.reducer;
