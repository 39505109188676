import React, { Component } from 'react';

import { sanitizeInput } from '@core/utils/dompurify/dompurify.js';
import { FIELDER } from '@core/constants/base.constants';
import {
	validateUsername,
	validateName,
	validateLastname,
	validateEmail,
	validateSecondEmail,
} from '@core/utils/validations/FieldValidations';
import { getIdToken } from 'FielderUtils/session/Session';
import { getTeamsByFilterAsyncSelect, getTeamsInitialOptionsAsyncSelect } from 'FielderUtils/team/team';
import { Modal, Row, Image, Col } from 'react-bootstrap';
import { formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import SelectJobPosition from '../UsersSectionComponents/SelectJobPosition';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import { Manager } from 'FielderUtils/roles/roles';

import './AddAdministrator.css';
import 'FielderUtils/style.css';
import 'rc-time-picker/assets/index.css';
import '../Register/RegisterComponentStyles.css';
import 'react-phone-number-input/style.css';
import { createManagerRequest } from '@core/api/manager/manager.service';
import TextField from '~/TextField/TextField.component';
import ErrorCodes from 'FielderUtils/ErrorCodes';
import {FielderPhoneField} from '~/FielderElements/FielderElements.component';

const { txt: TXT_ID, btn: BTN_ID } = FIELDER.idElement;

class AddManager extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			userInfo: {
				username: '',
				name: '',
				lastName: '',
				email: '',
				cellphone: '',
				officePhone: '',
				jobPosition: '',
				otherJobPosition: '',
			},
			errors: {
				username: null,
				name: null,
				lastName: null,
				email: null,
				cellphone: null,
			},
			assignListArray: null,
			activateAlertUserName: false,

			showAlert: false,
			showAlertSuccess: false,
			countryIsoName: null,

			countryCallingCode: this.props.lan.phoneNumberInput,

			showModalAddManager: props.showModalAddManager,

			validateSameEmail: true,
			sameEmail: false,

			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
			errorOfficePhone: '',
			errorMobilePhone: ''
		};
	}

	getAssignList = async (event) => {
		if (event.length > 0) {
			let assignList = [];

			assignList = await getTeamsByFilterAsyncSelect(event, this.state.idAccount, this.state.idUser, getIdToken(), this.props.lan);
			return assignList;
		}
	};

	getInitialOptionsTeam = async () => {
		return await getTeamsInitialOptionsAsyncSelect(this.state.idAccount, this.state.idUser, getIdToken(), this.props.lan);
	};

	setFieldAssign = (event) => {
		if (event != null) {
			var idListArray = [];
			for (let i = 0; i < event.length; i++) {
				idListArray.push({ id: event[i].value.id });
				this.setState({ idListArray: idListArray });
			}
		} else {
			this.setState({ idListArray: null });
		}
	};

	onChangeField = (event) => {
		if (!event) return;

		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo[event.target.name] = sanitizedInput;
		this.setState(state);
	};

	handleChangeJobPosition = (value) => {
		let state = this.state;
		state.userInfo.jobPosition = value;
		this.setState(state);
	};

	handleClose = () => this.props.setDefaultFalse();
	handleCloseModal = () => this.props.setDefaultFalseModal();

	validateCellPhone = (cellphone) => {
		if(cellphone.length > 0 && isValidPhoneNumber(cellphone)) {
			return true;
		} else {
			const state = this.state;
			state.errorMobilePhone = this.props.lan.messageErrorPhone
			return false; 
		}
	};

	validateOfficePhone = (officePhone) => {
		if(officePhone.length > 0) {
			if(isValidPhoneNumber(officePhone)) {
				return true;
			} else { 
				const state = this.state;
				state.errorOfficePhone = this.props.lan.messageErrorPhone
				return false; 
			}
		} else return true;
	};

	handleDoActionAddManager = () => {
		const secondEmail = document.getElementById(TXT_ID.emailConfirm).value;
		const isValidUserName = validateUsername(this.state.userInfo.username);
		const isValidName = validateName(this.state.userInfo.name);
		const isValidLastName = validateLastname(this.state.userInfo.lastName);
		const isValidEmail = validateEmail(this.state.userInfo.email);
		const isValidSecondEmail = validateSecondEmail(this.state.userInfo.email, secondEmail);
		const isValidOfficePhone = this.validateOfficePhone(this.state.userInfo.officePhone);
		const isValidCellPhone = this.validateCellPhone(this.state.userInfo.cellphone);

		let state = this.state;
		state.activateAlertUserName = false;
		state.errors = {
			username: !isValidUserName,
			name: !isValidName,
			lastName: !isValidLastName,
			email: !isValidEmail,
			emailConfirmation: !isValidSecondEmail,
			officePhone: !isValidOfficePhone,
			cellphone: !isValidCellPhone,
		};
		this.setState(state);

		if (isValidUserName && isValidName && isValidLastName && isValidEmail 
			&& isValidSecondEmail && isValidCellPhone && isValidOfficePhone) {
			document.getElementById('register-form-hint').style.display = 'none';
			this.createManager();
		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
	};

	createManager = () => {
		let idTeamsListArray = [];
		let jobPosition;

		if (this.state.idListArray != null) {
			var idTeamsArray = this.state.idListArray;

			for (let i = 0; i < idTeamsArray.length; i++) {
				idTeamsListArray.push(idTeamsArray[i].id);
			}
		} else {
			idTeamsListArray = null;
		}

		if (this.state.userInfo.jobPosition != this.props.lan.other) {
			jobPosition = this.state.userInfo.jobPosition;
		} else {
			jobPosition = this.state.userInfo.otherJobPosition;
		}

		const body = {
			clientUser: {
				idAccount: this.props.session.account.id,
				idUser: this.props.session.user.idUser,
				userName: this.state.userInfo.username,
				name: this.state.userInfo.name,
				lastName: this.state.userInfo.lastName,
				email: this.state.userInfo.email,
				phoneNumber: formatPhoneNumberIntl(this.state.userInfo.officePhone),
				status: 0,
				mobileNumber: formatPhoneNumberIntl(this.state.userInfo.cellphone),
				jobPosition: jobPosition,
				role: Manager.name,
				language: this.props.session.user.language,
			},
			idTeams: idTeamsListArray,
			isoName: this.state.countryIsoName,
		};

		const loadingConfig = { name: 'ADD_MANAGER', dispatch: this.props.dispatch };
		const onSuccess = () => this.props.setDefaultFalseAdd();
		const onError = ({ status }) => {
			if (status === ErrorCodes.DUPLICATED_USERNAME || status === ErrorCodes.FAIL_DUPLICATE_COGNITO_USER) {
				let state = this.state;
				state.activateAlertUserName = true;
				state.errors.username = true;
				this.setState(state);
			} else {
				this.props.errorMessageCreation();
			}
		};

		createManagerRequest(body, onSuccess, onError, loadingConfig);
	};

	getUserNameHelperText = () => {
		if (this.state.activateAlertUserName) {
			return this.props.lan.userNameValidation;
		} else if (this.state.errors.username) {
			return this.props.lan.userNameFieldHelp;
		}

		return null;
	};

	render() {
		return (
			<>
				<div className='Fielder-body p-3' style={{ backgroundColor: 'white' }}>
					<Row>
						<Col sm={12} md={6} className='px-4'>
							<div className='row my-3'>
								<div className='col-sm'>
									<TextField
										id={TXT_ID.username}
										name='username'
										label={`${this.props.lan.userName}*`}
										value={this.state.userInfo.username}
										onChange={this.onChangeField}
										error={this.state.errors.username}
										helperText={this.getUserNameHelperText()}
										maxSize={150}	
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.name}
										name='name'
										label={`${this.props.lan.name}*`}
										value={this.state.userInfo.name}
										onChange={this.onChangeField}
										error={this.state.errors.name}
										helperText={this.state.errors.name && this.props.lan.nameFieldHelp}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.lastName}
										name='lastName'
										label={`${this.props.lan.lastName}*`}
										value={this.state.userInfo.lastName}
										onChange={this.onChangeField}
										error={this.state.errors.lastName}
										helperText={this.state.errors.lastName && this.props.lan.lastNameFieldHelp}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.email}
										name='email'
										type='email'
										label={`${this.props.lan.email}*`}
										value={this.state.userInfo.email}
										onChange={this.onChangeField}
										error={this.state.errors.email}
										helperText={this.state.errors.email && this.props.lan.emailFieldHelp}
									/>
								</div>
							</div>
							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.emailConfirm}
										name='emailConfirm'
										type='email'
										label={`${this.props.lan.confirmEmail}*`}
										value={this.state.userInfo.emailConfirm}
										onChange={this.onChangeField}
										error={this.state.errors.emailConfirmation}
										helperText={this.state.errors.emailConfirmation && this.props.lan.validateSameEmail}
									/>
								</div>
							</div>
						</Col>

						<Col sm={12} md={6} className='px-4'>
							<div className='row'>
								<div className='col-sm-12 Fielder-cards-txt-label-input'>{this.props.lan.jobPosition}</div>
								<div className='col-sm-12'>
									<SelectJobPosition
										lan={this.props.lan}
										onChange={this.handleChangeJobPosition}
										defaultValue='0'
									/>
								</div>

								{this.state.userInfo.jobPosition == this.props.lan.other && (
									<div className='col-sm-8 my-3'>
										<TextField
											id={TXT_ID.jobPosition}
											name='otherJobPosition'
											label={this.props.lan.anotherJob}
											value={this.state.userInfo.otherJobPosition}
											onChange={this.onChangeField}
											size='small'
										/>
									</div>
								)}
							</div>

							<div className='row my-3'>
								<div className='col'>
									<FielderPhoneField
										id={TXT_ID.phone}
										label={this.props.lan.officePhone}
										value={this.state.userInfo.officePhone}
										onChange={value => {
											let state = this.state;
											state.errorOfficePhone = '';
											state.userInfo.officePhone = value === undefined? '' : value;
											this.setState(state);
										}}
										lan={this.props.lan}
										isRequired={false}
										externalError={this.state.errorOfficePhone}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<FielderPhoneField 
										id={TXT_ID.mobile}
										label={this.props.lan.mobilePhone}
										value={this.state.userInfo.cellphone}
										onChange={value => {
											const valueNumber = value || '';
											const formattedPhone = formatPhoneNumberIntl(valueNumber);
											let parsedNumber;
											try {
												parsedNumber = parsePhoneNumber(valueNumber);
											} catch (err) {
												console.warn('Failed to parse phone number:', err);
											}
											this.setState(prevState => ({
												...prevState,
												errorMobilePhone: '',
												userInfo: {
													...prevState.userInfo,
													cellphone: formattedPhone
												},
												countryIsoName: parsedNumber?.country,
												countryCallingCode: parsedNumber?.countryCallingCode
											}));
										}}
										lan={this.props.lan}
										isRequired={true}
										externalError={this.state.errorMobilePhone}
									/>
								</div>
							</div>

							<div className='row mb-4'>
								<div className='col-sm-12 Fielder-cards-txt-label-input'>{this.props.lan.selectTeamZone}</div>
								<div className='col-sm-12 '>
									<AsyncSearch
										className='my-0'
										getOptions={this.getAssignList}
										multiple
										defaultValue={[]}
										onChange={this.setFieldAssign}
										label={this.state.searchLabel}
										lan={this.props.lan}
										id='all-AsyncSearch'
										showInitialOptions
										getInitialOptions={this.getInitialOptionsTeam}
									/>
								</div>
							</div>

							<div id='register-form-hint' className='Register-form-hint mt-3'>
								<p>*{this.props.lan.requiredFields}</p>
							</div>

							<div className='mb-4'>
								<div className='col-sm-12'>
									<button
										id={BTN_ID.create}
										type='submit'
										className='btn btn-outline-primary btn-success create-client-modal-btnsave-size'
										onClick={this.handleDoActionAddManager}
									>
										{this.props.lan.add}
									</button>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AddManager);
