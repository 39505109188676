import React, { Component } from 'react';

import { sanitizeInput } from '@core/utils/dompurify/dompurify.js';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { createClientRequest } from '@core/api/client/client.service';
import { FIELDER } from '@core/constants/base.constants';
import {
	getBranchesByFilterAsyncSelectCreateTask,
	getBranchesInitialOptionsAsyncSelect,
} from 'FielderUtils/branch/branch';
import ErrorCodes from 'FielderUtils/ErrorCodes';
import { Client } from 'FielderUtils/roles/roles';
import { getIdToken } from 'FielderUtils/session/Session';
import { GOOGLE_API_KEY } from 'FielderUtils/session/Session';
import { Modal, Row, Image, Col } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Geocode from 'react-geocode';
import { formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import TextField from '~/TextField/TextField.component';
import Location from '~/Location/Location';
import {
	validateUsername,
	validateName,
	validateLastname,
	validateSecondEmail,
	validateEmail,
} from '@core/utils/validations/FieldValidations';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';
import SelectJobPosition from '../UsersSectionComponents/SelectJobPosition';
import {FielderPhoneField} from '~/FielderElements/FielderElements.component';
import './DateTime.css';
import './AddClient.css';
import 'FielderUtils/style.css';
import 'rc-time-picker/assets/index.css';
import '../Register/RegisterComponentStyles.css';
Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();
const { txt: TXT_ID, btn: BTN_ID, dtpckr: DTPCK_ID } = FIELDER.idElement;
var moment = require('moment');
let beginningTime = null;
let endTime = null;
let validTime;
var branchMarkertsGlobal = [];

class AddClient extends Component {
	constructor(props) {
		super(props);
		const session = this.props.session;
		this.state = {
			userInfo: {
				username: '',
				name: '',
				lastName: '',
				email: '',
				company: '',
				cellphone: '',
				officePhone: '',
				jobPosition: '',
				otherJobPosition: '',
				location: {
					address: '',
					latitude: '',
					longitud: '',
				},
			},
			errors: {
				username: null,
				name: null,
				lastName: null,
				email: null,
				emailConfirmation: null,
				cellphone: null,
				location: null,
			},
			emailConfirmation: '',
			emailValid: false,
			formValid: false,
			changedMapPosition: false,
			attentionTimeFrom: null,
			attentionTimeTo: null,
			activateAlert: false,
			activateAlertUserName: false,
			editMarker: true,
			phoneNumberValue: '',
			countryIsoName: null,
			countryCallingCode: this.props.lan.phoneNumberInput,
			showModalAddClient: props.showModalAddClient,
			idAccount: session.user ? session.user.idAccount : 0,
			user: session.user ? session.user : null,
			idUser: session.user ? session.user.idUser : 0,
			showErrorMessage: false,
			showErrorMessageIcon: null,
			idBranchListArray: [],
			branchMarkerts: [],
			errorOfficePhone: '',
			errorMobilePhone: ''
		};
	}
	onChangeFieldConfirmation = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state[event.target.name] = sanitizedInput;
		this.setState(state);
	}
 	onChangeField = (event) => {
		if (!event) return;

		const NUMBER_INPUTS = new Set([TXT_ID.phone, TXT_ID.mobile]);

		if (NUMBER_INPUTS.has(event.target.id)) {
			const re = /^[0-9\b]+$/;
			if (!re.test(event.target.value)) {
				return;
			}
		}

		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo[event.target.name] = sanitizedInput;
		this.setState(state);
	};
	handleChangeJobPosition = (value) => {
		let state = this.state;
		state.userInfo.jobPosition = value;
		this.setState(state);
	};
	validateForm = () => this.setState({ formValid: this.state.emailValid });
	getAssignList = async (event) => {
		if (event.length > 0) {
			let assignList = [];

			assignList = await getBranchesByFilterAsyncSelectCreateTask(
				event,
				this.state.idAccount,
				this.state.user,
				getIdToken()
			);

			return assignList;
		}
	};
	getInitialOptionsBranch = async () =>
		await getBranchesInitialOptionsAsyncSelect(this.state.idAccount, this.state.user, getIdToken());
	componentDidMount = () => {
		this.setState({ changedMapPosition: false });
	};
	getAllBranchMarkets = async (branches) => {
		var markers = [],
			i = 0;
		for (var elem = 0; elem < branches.length; elem++) {
			markers[i] = {
				id: branches[elem].value.id,
				position: {
					lat: parseFloat(branches[elem].value.location.latitude),
					lng: parseFloat(branches[elem].value.location.longitud),
				},
				title: branches[elem].value.idCustom
					? branches[elem].value.idCustom + ' - ' + branches[elem].value.name
					: branches[elem].value.name,
			};
			i++;
		}
		return markers;
	};
	setFieldBranches = async (event) => {
		var idBranchListArray = [];
		var editMarker = false;

		if (event != null && event.length > 0) {
			for (let i = 0; i < event.length; i++) {
				idBranchListArray.push(event[i].value.id);
			}
			var branchMarkerts = await this.getAllBranchMarkets(event);
			var arrayMarkers = [{ markers: branchMarkerts }];
			branchMarkertsGlobal = arrayMarkers;
		} else {
			editMarker = true;
			branchMarkertsGlobal = [];
			idBranchListArray = [];
		}
		this.setState({
			editMarker: editMarker,
			idBranchListArray: idBranchListArray,
		});
	};
	updateLocation = (newLocation, coordinates) => {
		let location = this.state.userInfo.location;

		if (coordinates.lat != location.latitude && coordinates.lng != location.longitud) {
			location.latitude = coordinates.lat;
			location.longitud = coordinates.lng;
			location.address = newLocation.address;

			let state = this.state;
			state.userInfo.location = location;
			this.setState(state);
		}
	};
	/**
	 * @desc This function execute the funtion setDefaultFalse()
	 * in the parent component when the modal is closed
	 *
	 **/
	handleClose = () => this.props.setDefaultFalseAddClient();
	handleCloseModal = () => this.props.setDefaultFalseModal();
	onChangeTimeOne = (value) => {
		if (value != undefined) {
			var timeFrom = new Date(value._d);
			beginningTime = timeFrom;
			this.validateTime();
		}
	};
	onChangeTimeTwo = (value) => {
		if (value != undefined) {
			var timeFromTo = new Date(value._d);
			endTime = timeFromTo;
			this.validateTime();
		}
	};
	validateTime = () => {
		if (beginningTime != null && endTime != null) {
			let initialTime = beginningTime;
			let finalTime = endTime;
			validTime = moment(initialTime, 'h:mm a').isBefore(moment(finalTime, 'h:mm a'));
			if (validTime == true) {
				let formatInitialTime = moment(initialTime, 'hh:mm a').format('HH:mm');
				let formatEndTime = moment(finalTime, 'hh:mm a').format('HH:mm');
				this.setState({
					attentionTimeFrom: formatInitialTime,
					attentionTimeTo: formatEndTime,
					activateAlert: false,
				});
			} else {
				this.setState({ activateAlert: true });
			}
		}
	};
	validateCellPhone = (cellphone) => {
		if(cellphone.length > 0 && isValidPhoneNumber(cellphone)) {
			return true;
		} else {
			const state = this.state;
			state.errorMobilePhone = this.props.lan.messageErrorPhone
			return false; 
		}
	};
	validateOfficePhone = (officePhone) => {
		if(officePhone.length > 0) {
			if(isValidPhoneNumber(officePhone)) {
				return true;
			} else { 
				const state = this.state;
				state.errorOfficePhone = this.props.lan.messageErrorPhone
				return false; 
			}
		} else return true;
	};
	validateLocation = () => {
		let location = this.state.userInfo.location;
		let isValid =
			(location.latitude !== 0 && location.longitud !== 0 && location.address !== '') ||
			this.state.idBranchListArray.length > 0;
		return isValid;
	};
	handleDoActionAddClient = () => {
		this.setState({ showModalAddClient: false });
		const isValidUserName = validateUsername(this.state.userInfo.username);
		const isValidName = validateName(this.state.userInfo.name);
		const isValidLastName = validateLastname(this.state.userInfo.lastName);
		const isValidEmail = validateEmail(this.state.userInfo.email);
		const isValidSecondEmail = validateSecondEmail(this.state.userInfo.email, this.state.emailConfirmation);
		const isValidOfficePhone = this.validateOfficePhone(this.state.userInfo.officePhone);
		const isValidCellPhone = this.validateCellPhone(this.state.phoneNumberValue);
		const isValidLocation = this.validateLocation();

		let state = this.state;
		state.activateAlertUserName = false;
		state.errors = {
			username: !isValidUserName,
			name: !isValidName,
			lastName: !isValidLastName,
			email: !isValidEmail,
			emailConfirmation: !isValidSecondEmail,
			officePhone: !isValidOfficePhone,
			cellphone: !isValidCellPhone,
			location: !isValidLocation,
		};

		if (
			isValidUserName &&
			isValidName &&
			isValidLastName &&
			isValidEmail &&
			isValidSecondEmail &&
			isValidLocation &&
			isValidCellPhone &&
			isValidOfficePhone &&
			this.state.activateAlert === false
		) {
			document.getElementById('register-form-hint').style.display = 'none';
			this.executeCreateClient();
		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
		this.setState(state);
	};
	executeCreateClient = () => {
		let jobPosition;
		let location = {
			address: this.state.userInfo.location.address,
			latitude: this.state.userInfo.location.latitude,
			longitud: this.state.userInfo.location.longitud,
		};

		if (this.state.idBranchListArray.length > 0) {
			location = null;
		}

		if (this.state.userInfo.jobPosition !== this.props.lan.other) {
			jobPosition = this.state.userInfo.jobPosition;
		} else {
			jobPosition = this.state.userInfo.otherJobPosition;
		}

		if (location) {
			location.accountId = this.props.session.account.id;
		}

		var body = {
			clientUser: {
				idAccount: this.props.session.account.id,
				idUser: this.props.session.user.idUser,
				userName: this.state.userInfo.username,
				name: this.state.userInfo.name,
				lastName: this.state.userInfo.lastName,
				email: this.state.userInfo.email,
				phoneNumber: formatPhoneNumberIntl(this.state.userInfo.officePhone),
				status: 0,
				mobileNumber: formatPhoneNumberIntl(this.state.phoneNumberValue),
				attentionTimeFrom: this.state.attentionTimeFrom,
				attentionTimeTo: this.state.attentionTimeTo,
				jobPosition: jobPosition,
				company: this.state.userInfo.company,

				location: location,
				idBranches: this.state.idBranchListArray,
				role: Client.name,
				language: this.props.session.user.language,
			},
			isoName: this.state.countryIsoName,
		};

		const onSuccess = this.props.setDefaultFalseAddClient;
		const onError = ({ status }) => {
			if (status === ErrorCodes.FAIL_DUPLICATE_COGNITO_USER || status === ErrorCodes.DUPLICATED_USERNAME) {
				let state = this.state;
				state.activateAlertUserName = true;
				state.showModalAddClient = true;
				state.errors.username = true;
				this.setState(state);
			} else {
				this.props.errorMessageCreation();
			}
		};

		const loadingConfig = { name: 'CREATE_CLIENT', dispatch: this.props.dispatch };
		createClientRequest(body, onSuccess, onError, loadingConfig);
	};
	showMessage = () => this.setState({ showErrorMessage: !this.state.showErrorMessage });
	getUserNameHelperText = () => {
		if (this.state.activateAlertUserName) {
			return this.props.lan.userNameValidation;
		} else if (this.state.errors.username) {
			return this.props.lan.userNameFieldHelp;
		}

		return null;
	};
	render() {
		return (
			<>
				<ErrorMessage
					{...this.props}
					icon={this.state.showErrorMessageIcon}
					closeErrorMessage={this.showMessage}
					message={this.state.message}
					showErrorMessage={this.state.noImagesMessage}
				/>
				<div className='Fielder-body p-3' style={{ height: '80vh', background: 'white', overflowY: 'auto' }}>
					<Row>
						<Col sm={12} md={6} className='px-4'>
							<div className='row my-3'>
								<div className='col-sm'>
									<TextField
										id={TXT_ID.username}
										name='username'
										label={`${this.props.lan.userName}*`}
										value={this.state.userInfo.username}
										onChange={this.onChangeField}
										error={this.state.errors.username}
										helperText={this.getUserNameHelperText()}
										maxSize={150}	
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.name}
										name='name'
										label={`${this.props.lan.name}*`}
										value={this.state.userInfo.name}
										onChange={this.onChangeField}
										error={this.state.errors.name}
										helperText={this.state.errors.name && this.props.lan.nameFieldHelp}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.lastName}
										name='lastName'
										label={`${this.props.lan.lastName}*`}
										value={this.state.userInfo.lastName}
										onChange={this.onChangeField}
										error={this.state.errors.lastName}
										helperText={this.state.errors.lastName && this.props.lan.lastNameFieldHelp}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.email}
										name='email'
										type='email'
										label={`${this.props.lan.email}*`}
										value={this.state.userInfo.email}
										onChange={this.onChangeField}
										error={this.state.errors.email}
										helperText={this.state.errors.email && this.props.lan.emailFieldHelp}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<TextField
										id={TXT_ID.emailConfirm}
										name='emailConfirmation'
										type='email'
										label={`${this.props.lan.confirmEmail}*`}
										value={this.state.emailConfirmation}
										onChange={this.onChangeFieldConfirmation}
										error={this.state.errors.emailConfirmation}
										helperText={this.state.errors.emailConfirmation && this.props.lan.validateSameEmail}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col-sm'>
									<Location
										edit={this.state.editMarker}
										includeCoordinates={this.state.idBranchListArray.length <= 0}
										height='200px'
										label={this.props.lan.directionClient}
										locationUpdate={this.updateLocation}
										error={this.state.errors.location}
										showDirection={this.state.idBranchListArray.length > 0}
										markers={branchMarkertsGlobal}
									/>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} className='px-4'>
							<div className='row my-3'>
								<div className='col-sm-10'>
									<TextField
										id={TXT_ID.companyName}
										name='company'
										label={this.props.lan.company}
										onChange={this.onChangeField}
										value={this.state.userInfo.company}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col-sm-12 Fielder-cards-txt-label-input'>{this.props.lan.branch}</div>
								<div className='col font-inputs'>
									<AsyncSearch
										className='my-0'
										getOptions={this.getAssignList}
										multiple
										defaultValue={[]}
										onChange={this.setFieldBranches}
										label={this.props.lan.placeHolderBranch}
										lan={this.props.lan}
										id='select-branch-add-client'
										showInitialOptions
										getInitialOptions={this.getInitialOptionsBranch}
									/>
								</div>
								<div className='col-sm-2'>
									<img
										id='branch-alert-icon'
										src={redAlertIcon}
										alt=''
										className='icon'
										style={{ display: 'none' }}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col-sm-12 Fielder-cards-txt-label-input'>{this.props.lan.workingHours}</div>
								<div className='col'>
									{this.state.activateAlert && (
										<div className='alert alert-warning' role='alert' id='showAlert'>
											{this.props.lan.hourValidation}
										</div>
									)}

									<Row className='ml-2'>
										<Datetime
											id={DTPCK_ID.workingHoursFrom}
											className='id-date-from'
											inputProps={{ placeholder: this.props.lan.from, readOnly: true }}
											dateFormat={false}
											onChange={this.onChangeTimeOne}
										/>
										<p> &nbsp; - &nbsp; </p>
										<Datetime
											id={DTPCK_ID.workingHoursTo}
											className='id-date-to'
											inputProps={{ placeholder: this.props.lan.to, readOnly: true }}
											dateFormat={false}
											onChange={this.onChangeTimeTwo}
										/>
									</Row>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<FielderPhoneField
										id={TXT_ID.phone}
										label={this.props.lan.officePhone}
										value={this.state.userInfo.officePhone}
										onChange={value => {
											let state = this.state;
											state.errorOfficePhone = '';
											state.userInfo.officePhone = value === undefined? '' : value;
											this.setState(state);
										}}
										lan={this.props.lan}
										isRequired={false}
										externalError={this.state.errorOfficePhone}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col'>
									<FielderPhoneField 
										id={TXT_ID.mobile}
										label={this.props.lan.mobilePhone}
										value={this.state.userInfo.cellphone}
										onChange={value => {
											const valueNumber = value || '';
											const formattedPhone = formatPhoneNumberIntl(valueNumber);
											let parsedNumber;
											try {
												parsedNumber = parsePhoneNumber(valueNumber);
											} catch (err) {
												console.warn('Failed to parse phone number:', err);
											}
											this.setState(prevState => ({
												...prevState,
												errorMobilePhone: '',
												phoneNumberValue: formattedPhone,
												countryIsoName: parsedNumber?.country,
												countryCallingCode: parsedNumber?.countryCallingCode
											}));
										}}
										lan={this.props.lan}
										isRequired={true}
										externalError={this.state.errorMobilePhone}
									/>
								</div>
							</div>

							<div className='row my-3'>
								<div className='col-sm-12 Fielder-cards-txt-label-input'>{this.props.lan.jobPosition}</div>
								<div className='col-sm-10'>
									<SelectJobPosition
										lan={this.props.lan}
										onChange={this.handleChangeJobPosition}
										defaultValue='0'
									/>
								</div>
							</div>

							{this.state.userInfo.jobPosition == this.props.lan.other && (
								<div className='col-sm-8 my-3'>
									<TextField
										id={TXT_ID.jobPosition}
										name='otherJobPosition'
										label={this.props.lan.anotherJob}
										value={this.state.userInfo.otherJobPosition}
										onChange={this.onChangeField}
										size='small'
									/>
								</div>
							)}

							<div id='register-form-hint' className='Register-form-hint mt-3'>
								<p>*{this.props.lan.requiredFields}</p>
							</div>
							<div className='mb-2'>
								<div className='col-sm-12'>
									<button
										type='submit'
										id={BTN_ID.create}
										className='btn btn-outline-primary btn-success create-client-modal-btnsave-size create-client-btnSave-margin'
										onClick={this.handleDoActionAddClient}
									>
										{this.props.lan.add}
									</button>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AddClient);
