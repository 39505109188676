const UserRolesUtil = {
    ROLE_CLIENT_USER: "Fielder Client User",
    ROLE_CLIENT_ADMIN: "Fielder Administrator",
    ROLE_ROOT_GLOBAL_ACCOUNT: "Fielder Root Global Account",
    ROLE_CLIENT_GLOBAL_ACCOUNT: "Fielder Client Global Account",
    ROLE_CLIENT_SUPER_ADMIN: "Fielder Super Administrator",
    ROLE_CLIENT_SUPER_ADMIN_VIEWER: "Fielder Super Administrator Viewer",
    ROLE_CLIENT_MANAGER: "Fielder Manager",
    ROLE_FIELDER_ADMIN_FUNCTIONALITY: "Fielder Administrator Funcionality",
    ROLE_API_USER: "Fielder API User",
    ROLE_API_MANAGER: "Fielder API Manager",
    ROLE_AGENT: "Fielder Agent"
};

export default UserRolesUtil;