import React, { useState, useEffect, useCallback } from 'react';
import { AddAgentIcon, DeepSpaceGrey } from '@core/theme/baseTheme';
import AddManagerIcon from '@assets/icons/add-manager-icon.svg';
import AddAdministratorIcon from '@assets/icons/add-administrator-icon.svg';
import { useLanguage } from 'Context/LanguageContext';
import ResultMessage from 'FielderComponents/ResultMessage';
import AddAdministrator from 'OldComponents/Administrators/AddAdministrator';
import CreateDetailAgent from 'OldComponents/Agents/CreateDetailAgent';
import AddBranchOffice from 'OldComponents/BranchOffices/AddBranchOffice';
import AddClient from 'OldComponents/Clients/AddClient';
import AddManager from 'OldComponents/Managers/AddManager';
import { useDispatch } from 'react-redux';
import BranchIcon from '@assets/icons/branch-icon.svg';
import ClientIcon from '@assets/icons/add-client-icon.svg';
import { accountHasSkillsFunctionality } from '@core/api/skills/skills.service'
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import { useModal } from '~/FielderElements/ModalContext';
import Modal from '~/FielderElements/Modal';
import { FIELDER } from '@core/constants/base.constants';

const { pop: POP_ID } = FIELDER.idElement;
const POP_ADD_BRANCH = POP_ID.userModal.replace('{0}', 'addBranchModal');
const POP_ADD_CLIENT = POP_ID.userModal.replace('{0}', 'addClientModal');
const POP_ADD_AGENT = POP_ID.userModal.replace('{0}', 'addAgentModal');
const POP_ADD_ADMIN = POP_ID.userModal.replace('{0}', 'addAdminModal');
const POP_ADD_MANAGER = POP_ID.userModal.replace('{0}', 'addManagerModal');
const modalConfig = {
	newAdministrator: {
		id: POP_ADD_ADMIN,
		titleKey: 'addAdministrator',
		icon: AddAdministratorIcon,
		fullWidth: true,
	},
	newManager: {
		id: POP_ADD_MANAGER,
		titleKey: 'addManager',
		icon: AddManagerIcon,
		fullWidth: true,
	},
	newAgent: {
		id: POP_ADD_AGENT,
		titleKey: 'createAgentTitle',
		icon: <AddAgentIcon sx={{ color: DeepSpaceGrey, width: '24px' }} />,
		fullWidth: true,
	},
	newClient: {
		id: POP_ADD_CLIENT,
		titleKey: 'addClient',
		icon: ClientIcon,
		fullWidth: true,
	},
	newBranch: {
		id: POP_ADD_BRANCH,
		titleKey: 'addBranch',
		icon: BranchIcon,
	},
};
const AddUserModals = ({ history, showModal, hideModals }) => {
	const { modals, openModal, closeModal, setContent } = useModal();
	const fielderMsg = useFielderSnackBar();
	const dispatch = useDispatch();
	const lan = useLanguage();
	const [accountHasSkills, setAccountHasSkills] = useState(false);
	const [resultModal, setResultModal] = useState({
		show: false,
		success: false,
		text: lan.createUserError,
	});
	const [shouldCloseAll, setShouldCloseAll] = useState(false);
	const commonProps = { lan, history, dispatch, showModal };
	const hideAddUserModals = useCallback((success, title, message, id, keepModal) => () => {
		if (success !== undefined) {
			const modalMessage = message || lan.createUserError;
			fielderMsg({
				title: title,
				message: modalMessage, 
				variant: success ? 'success' : 'error', 
				closeButton: true, duration: 5000});
		}
		if (keepModal !== true) {
			setShouldCloseAll(true);
			closeModal(id);
			hideModals();
		}
	}, []);
	const handleModalContentAndOpen = useCallback((content, modalId) => {
		setContent(modalId, content);
		openModal(modalId);
	}, [setContent, openModal]);
	const getModalContent = useCallback((type) => {
		const title = lan[modalConfig[type]?.titleKey] || undefined;
		const id = modalConfig[type]?.id || undefined;
		if (!title || !id) return null;

		switch (type) {
			case 'newBranch':
				return (
					<AddBranchOffice
						lan={lan}
						history={history}
						setDefaultFalse={hideAddUserModals(
							true, title, lan.agentAddedCorrectly, id)}
						setDefaultFalseModal={hideAddUserModals(
							undefined, title, undefined, id)}
						errorMessageCreation={hideAddUserModals(
							false, title, undefined, id, true)}
						showCreateBranchWithTask={true}
					/>
				);
			case 'newClient':
				return (
					<AddClient
						lan={lan}
						setDefaultFalseAddClient={hideAddUserModals(
							true, title, lan.agentAddedCorrectly, id)}
						setDefaultFalseModal={hideAddUserModals(
							undefined, title, undefined, id)}
						errorMessageCreation={hideAddUserModals(
							false, title, undefined, id, true)}
						showModalAddClient={showModal.newClient}
					/>
				);
			case 'newAgent':
				return (
					<CreateDetailAgent
						lan={lan}
            handleCreateError={(message) => {
              const hideError = hideAddUserModals(false, title, message, id, true);
              hideError();
            }}
						handleCreateSuccess={hideAddUserModals(
							true, title, lan.agentAddedCorrectly, id)}
						showAction={hideAddUserModals(
							undefined, title, undefined, id)}
						skillsElegible={accountHasSkills}
					/>
				);
			case 'newAdministrator':
				return (
					<AddAdministrator
						lan={lan}
						dispatch={dispatch}
						setDefaultFalseModal={hideAddUserModals(
							undefined, title, undefined, id)}
						setDefaultFalse={hideAddUserModals(
							true, title, lan.agentAddedCorrectly, id)}
						setDefaultFalseAdd={hideAddUserModals(
							true, title, lan.agentAddedCorrectly, id)}
						errorMessageCreation={hideAddUserModals(
							false, title, undefined, id, true)}
						showModalAddAdministrator={showModal.newAdministrator}
					/>
				);
			case 'newManager':
				return (
					<AddManager
						lan={lan}
						setDefaultFalse={hideAddUserModals(
							true, title, lan.agentAddedCorrectly, id)}
						setDefaultFalseModal={hideAddUserModals(
							undefined, title, undefined, id)}
						setDefaultFalseAdd={hideAddUserModals(
							true, title, lan.agentAddedCorrectly, id)}
						errorMessageCreation={hideAddUserModals(
							false, title, undefined, id, true)}
						showModalAddManager={showModal.newManager}
					/>
				);
			default:
				return null;
		}
	}, [commonProps]);
	const getSkillsEligible = useCallback(() => {
		const onSuccess = (response) => setAccountHasSkills(response.skillsElegible);
		accountHasSkillsFunctionality(onSuccess);
	}, []);
	const closeResultModal = useCallback(() => {
		setResultModal({ show: false, success: false });
	}, []);
	useEffect(() => {
		const modalConfig = {
			newBranch: POP_ADD_BRANCH,
			newClient: POP_ADD_CLIENT,
			newAgent: POP_ADD_AGENT,
			newAdministrator: POP_ADD_ADMIN,
			newManager: POP_ADD_MANAGER,
		};
		Object.keys(showModal).forEach((key) => {
			if (showModal[key]) {
				const content = getModalContent(key, commonProps);
				handleModalContentAndOpen(content, modalConfig[key]);
			}
		});
		getSkillsEligible();
	}, [showModal]);
	useEffect(() => {
		const areAllModalsClosed = Object.keys(modals).every((key) => !modals[key].isOpen);
		if (areAllModalsClosed || shouldCloseAll) {
            hideModals();
            setShouldCloseAll(false);
        }
    }, [modals, shouldCloseAll]);
	return (
		<>
			{Object.keys(modalConfig).map((key) => {
				if (showModal[key]) {
					const { id, titleKey, icon, fullWidth } = modalConfig[key];
					return (
						<Modal
							key={id}
							id={id}
							title={lan[titleKey]}
							icon={typeof icon === 'string' ? 
								<img src={icon} alt={lan[titleKey]} /> : icon}
							fullWidth={fullWidth}
						/>
					);
				}
				return null;
			})}
			{resultModal.show && (
				<ResultMessage 
					text={resultModal.text} 
					success={resultModal.success} 
					onClose={closeResultModal} />
			)}
		</>
	);
};
export default AddUserModals;
