import React, { Component } from 'react';

import AlerYellowtIcon from '@assets/icons/alert-icon.svg';
import {
	getOnlyAgentsByFilterAsyncSelect,
	getOnlyAgentsInitialOptionsAsyncSelect,
} from 'FielderUtils/agent/agent';
import { getIdToken } from 'FielderUtils/session/Session';
import Popup from 'OldComponents/Popup';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';

import '../MyOperation.css';
import { reassignAgentToRouteRequest } from '@core/api/route/route.service';
class ReassignRouteAgentPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		var idUserAssigned = [];
		var rescheduleSuccess = true;
		var rescheduleFail = false;

		this.state = {
			idUserAssigned: idUserAssigned,
			showErrorMessage: false,
			showErrorMessageText: '',
			showErrorMessageIcon: null,
			rescheduleSuccess: rescheduleSuccess,
			rescheduleFail: rescheduleFail,
			idAgent: null,
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
			user: session.user,
			errorMessage: false,
		};
	}

	closePopup = () => {
		this.setState({ errorMessage: !this.state.errorMessage });
	};

	manageGetReassignAgent = (res) => {
		this.props.onClose(true, false);
	};

	reassignAgent = (e) => {

		if (this.state.idAgent != null) {
			const body = {
				idRoute: this.props.idRoute,
				idAgent: this.state.idAgent,
			}

			const loadingConfig = { dispatch: this.props.dispatch }
			const onSuccess = this.manageGetReassignAgent;
			const onError = ({ status }) => {
				if (status === 950) {
					this.props.onClose(false, false);
				} else {
					this.props.onClose(false, true);
				}
			}

			reassignAgentToRouteRequest(body, onSuccess, onError, loadingConfig);

		} else {
			this.setState({ errorMessage: true });
		}
	};

	getAssignList = async (event) => {
		if (event.length > 0) {
			let assignList = [];

			assignList = await getOnlyAgentsByFilterAsyncSelect(
				event,
				this.state.idAccount,
				this.state.user,
				getIdToken(),
				this.props.lan
			);

			return assignList;
		}
	};

	getInitialOptions = async () => {
		return await getOnlyAgentsInitialOptionsAsyncSelect(
			this.state.idAccount,
			this.state.user,
			getIdToken(),
			this.props.lan
		);
	};

	setFieldAssign = (event) => {
		this.setState({
			searchAssignValue: event,
		});

		if (event != null) {
			this.setState({ idAgent: event.value.idAgent });
		} else {
			this.setState({ idAgent: null });
		}
	};

	render() {
		return (
			<React.Fragment>
				{this.state.errorMessage && (
					<ErrorMessage
						{...this.props}
						closeErrorMessage={() => this.closePopup()}
						message={this.props.lan.messageSelectAgent}
						showErrorMessage={this.state.errorMessage}
						icon={AlerYellowtIcon}
					/>
				)}

				<Popup
					title={this.props.lan.assignAgent}
					icon='add-agents-icon.svg'
					show
					closeButton
					onClose={this.props.onClose}
					buttonText={this.props.lan.assign}
					requiredFields={this.props.lan.requiredFields}
					buttonOnClick={this.reassignAgent}
					size='sm'
				>
					<Row>
						<Col sm={12}>
							<React.Fragment>
								<Col sm={12} className='mt-3'>
									<span className='title-padding text-title'>{this.props.lan.assignTo}</span>
								</Col>
								<Col key='col-task-assing-to'>
									<AsyncSearch
										className='react-select__control full-width'
										getOptions={(e) => this.getAssignList(e)}
										onChange={(e) => this.setFieldAssign(e)}
										label={this.props.lan.roleSearch}
										lan={this.props.lan}
										id='select-add-client'
										showInitialOptions
										getInitialOptions={() => this.getInitialOptions()}
									/>
								</Col>
							</React.Fragment>
						</Col>
					</Row>
				</Popup>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(ReassignRouteAgentPopup);
