import React, { Component } from 'react';
import { AddAgentIcon, DeepSpaceGrey } from '@core/theme/baseTheme';
import CalendarIcon from '@assets/icons/calendar-icon.svg';
import cancelIcon from '@assets/icons/cancel.svg';
import RepositionIcon from '@assets/icons/changeOrder.png';
import ClockIcon from '@assets/icons/clock-icon.svg';
import ProfileImage from '@assets/icons/default-profile-icon.svg';
import DeleteIcon from '@assets/icons/delete-icon.svg';
import DetailTaskIcon from '@assets/icons/detalle-task-icon.svg';
import DropDown from '@assets/icons/dropdown.svg';
import editImageIcon from '@assets/icons/edit-icon.svg';
import ExpensesIcon from '@assets/icons/expenses.png';
import RefreshIcon from '@assets/icons/refresh-icon.svg';
import RepositionSmallIcon from '@assets/icons/reposition-icon.svg';
import SearchIcon from '@assets/icons/search-icon.svg';
import AssetsIcon from '@assets/icons/taskGroup-assets-icon.svg';
import FormIcon from '@assets/icons/taskGroup-form-icon.svg';
import ImagesIcon from '@assets/icons/taskGroup-image-icon.svg';
import InventoryIcon from '@assets/icons/taskGroup-inventory-icon.svg';
import RoutesImage from '@assets/images/routesimage.svg';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { getAccountPreferences } from 'FielderUtils/accountUtils';
import ErrorCodes from 'FielderUtils/ErrorCodes';
import routeConst from 'FielderUtils/roleManagementConst/routeConst';
import { GOOGLE_URL_WITH_LIBRARIES } from 'FielderUtils/session/Session';
import { getStatus } from 'FielderUtils/status/statusmapper';
import TaskStatusConst from 'FielderUtils/status/taskStatus';
import { getPinColor } from 'FielderUtils/task/pinColor';
import { getSeverityText, getSeverityClassName } from 'FielderUtils/task/taskSeverity';
import {
	listToTree,
	getAllTaskTypeList,
	hasTaskTypeDynamoTable,
	getTaskTypeParents,
} from 'FielderUtils/taskType/taskType';
import { Droppable, DragDropContext, Draggable } from '@hello-pangea/dnd';
import { Navbar, Image, Button, Nav, Container, Row, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import LightSpeed from 'react-reveal/LightSpeed';
import { isRoleValid } from '../../core/utils/roles/validateRoles';
import AgentDetail from '../Agents/AgentDetailContainer';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';
import Map from '../Map';
import StatusFormatter from '../SmartTable/StatusFormatter';
import RouteStatus from '../Status/RouteStatus';
import TaskDetails from '../TaskDetails';
import EditExpenses from './EditExpenses';
import EditRouteEndPoint from './EditRouteEndPoint';
import TaskSearchResultsPopup from './TaskSearchResultPopup';
import './RouteDetails.css';
import 'FielderUtils/style.css';
import { doActionReorderRouteRequest, getRouteByIdRequest, getRouteTaskToShowRequest, getRouteTaskFolioRequest } from '@core/api/route/route.service';
import { reorderArrayElementByIndex } from '@core/utils/array/reorder.utils';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import Modal from '~/FielderElements/Modal';
import { FIELDER } from '@core/constants/base.constants';

const { pop: POP_ID } = FIELDER.idElement;
const POP_EDIT_AGENT = POP_ID.routeDetail.replace('{0}', 'editAgentModal');
var Request = require('FielderRequestTypes');
const LOC_ACTIVITY_TYPE = 'activity_type_';
var moment = require('moment-timezone');
/**
 * Route Details Pop-up Component according to Fielder App
 * requirements, it shows the Tasks required by an agent,
 * it shows the agent's information and can handle the change
 * of task order, as well as shows the Map routes.
 *
 */
class RouteDetails extends Component {
	/**
	 * @typedef {Object} Task
	 * @property {string} name - Task Name to be displayed
	 * @property {string} act - Activity to be done
	 * @property {string} date - Task Date
	 * @property {string} location - Location in which the task
	 *                               must be done
	 * @property {string} status - Status in which the task is
	 *                             currently in
	 * @property {string} code - Code name for the status
	 *
	 */
	constructor(props) {
		super(props);
		this.editAgentModal = this.editAgentModal.bind(this);
		this.onChangeInitialMap = this.onChangeInitialMap.bind(this);
		this.state = {
			routeDetailsInfo: true,
			route: {},
			items: [],
			markers: [],
			originalMarkers: [],
			originalItems: [],
			routePoints: [],
			editable: false,
			add: false,
			loading: true,
			showActivity: false,
			position: 0,
			showRouteInfo: true,
			showEmergencies: false,
			from: null,
			to: null,
			opacity: 1,
			tasksToShow: [],
			showTasks: false,
			showDetailTask: false,
			idTask: null,
			errorMessage: false,
			limit: 50,
			offset: 0,
			mycount: 0,
			initialMap: true,
			origin: 'routeDetail',
			taskTypes: {
				taskTypeFunctionality: false,
				types: [],
			},
			kmCost: 'hola',
			startLocation: {},
			endLocation: {},
			configExpenses: false,

			isAllowedRouteEdit: false,
			isAllowedRouteAddTask: false,
			isAllowedReorganizeRoute: false,
			isAllowedDeleteTask: false,
		};
	}
	componentDidMount = () => {
		getAccountPreferences(this, this.props.session);

		let state = this.state;
		state.isAllowedRouteEdit = isRoleValid(
			routeConst.ROUTES,
			routeConst.EDIT,
			this.props.session.roles
		);

		state.isAllowedRouteAddTask = isRoleValid(
			routeConst.ROUTES,
			routeConst.ADD_TASK_BY_FOLIO,
			this.props.session.roles
		);

		state.isAllowedReorganizeRoute = isRoleValid(
			routeConst.ROUTES,
			routeConst.REORGANIZE_ROUTE,
			this.props.session.roles
		);

		state.isAllowedDeleteTask = isRoleValid(
			routeConst.ROUTES,
			routeConst.DELETE_TASK_FROM_ROUTE,
			this.props.session.roles
		);

		this.setState(state);

		hasTaskTypeDynamoTable().then((taskTypeFunctionality) => {
			if (taskTypeFunctionality) {
				getAllTaskTypeList(
					(formatTypes, taskTypes) => {
						let types = listToTree(formatTypes);

						let state = this.state;
						state.taskTypes.types = types;
						state.taskTypes.taskTypeFunctionality = taskTypeFunctionality;
						this.setState(state);
					},
					this.props,
					true
				);
			}
		});

		const onSuccess = (response) => {
			this.renderRouteResponse(response);
		}

		const loadingConfig = { dispatch: this.props.dispatch }
		getRouteByIdRequest(Request.getRouteById(this.props.idRoute), onSuccess, null, loadingConfig);

	};
	/**
	 * Task List Style object to be assigned to the
	 * droppable list
	 *
	 * @param {bool} isDraggingOver - Tells if an element is
	 *                                being dragged over the
	 *                                droppable.
	 *
	 * @returns {style} - Object with the style for the droppable
	 *                    list
	 */
	getListStyle = (isDraggingOver) => ({
		background: 'white',
		padding: 8,
		height: '100%',
		width: '100%',
	});
	/**
	 * Task List Style object to be assigned to the
	 * droppable object
	 *
	 * @param {bool} isDragging - Tells if an element is
	 *                            being dragged
	 * @param {style} draggableStyle - Style carried by the
	 *                                 (handles the dragging
	 *                                 style)
	 *
	 * @returns {style} - Object with the style for the droppable
	 *                    element
	 */
	getItemStyle = (isDragging, draggableStyle) => ({
		userSelect: 'none',
		MozUserSelect: 'none',

		padding: 16,
		margin: `0 0 8px 0`,

		background: isDragging ? '#4a4a4a4a' : 'white',
		...draggableStyle,
	});
	/**
	 * Reorders the array when an element is dragged
	 *
	 * @param {array} list - List of {@link Task} displayed
	 *                       in the page
	 * @param {Task} startIndex - Index where the start element
	 *                            changed order
	 * @param {Task} endIndex - Index where the element
	 *                          changed ordere
	 *
	 * @returns {array} - Contains the new ordering
	 */
	reorder(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	}
	/**
	 *  function that handles the actions made when
	 *  the Dragging of a task is completed.
	 *
	 * It verifies that the task destination is inside
	 * the droppable zone and reorders and updates the
	 * state with the new order
	 *
	 * @param {object} res - Object that contains the
	 *                       moved task information
	 *
	 */
	onDragEnd = (e) => {
		if (!e.destination || e.source.index === e.destination.index) return;
		const items = reorderArrayElementByIndex(this.state.items, e.source.index, e.destination.index);
		this.setState({ items });
	}
	onChangeTaskPosition = (event) => {
		this.setState({ position: event.target.value });
	};
	renderReorderRoute = (res) => {
		const routeResponse = res;
		if (routeResponse.success) {
			this.setState({
				route: routeResponse.route,
				items: routeResponse.route.tasks,
				editable: false,
			});

			this.getMarkers();
		}
	};
	canUpdateRoute = (tasks) => {
		let canUpdateRoute = true;
		let prevStatus, currentStatus;
		if (tasks.length != 0) {
			prevStatus = tasks[0].status;

			for (let index = 0; index < tasks.length; index++) {
				currentStatus = tasks[index].status;
				if (
					(prevStatus == TaskStatusConst.MANAGING ||
						prevStatus == TaskStatusConst.WAITING_FOR_AGENT ||
						prevStatus == TaskStatusConst.IN_PROGRESS) &&
					(currentStatus == TaskStatusConst.FINISH ||
						currentStatus == TaskStatusConst.EXECUTING ||
						currentStatus == TaskStatusConst.START_ROUTE ||
						currentStatus == TaskStatusConst.ARRIVED)
				) {
					canUpdateRoute = false;
					break;
				}
				prevStatus = tasks[index].status;
			}
		} else {
			canUpdateRoute = false;
		}
		return canUpdateRoute;
	};
	updateTaskOrders = () => {
		const route = this.state.route;
		route.tasks = this.state.items;

		if (this.canUpdateRoute(route.tasks)) {
			route.endPoint = this.state.endLocation;
			route.startPoint = this.state.startLocation;

			let body = Request.getOrderRoute(route);

			const onSuccess = (response) => {
				this.renderReorderRoute(response);
				this.props.fielderMsg({message: this.props.lan.savedCorrectly, variant: 'success', closeButton: true, duration: 5000});
			}

			const onError = (response) => {
				if (response.errorCode == ErrorCodes.ROUTE_MODIFIED_BY_AGENT) {
					this.props.fielderMsg({message: this.props.lan.routeChangedByAgent, variant:  'warning', closeButton: true, duration: 6000});
					this.refresh();
				} else {
					this.props.fielderMsg({message: this.props.lan.unexpectedErrorOcurred, variant: 'error', closeButton: true, duration: 5000});
				}
			}

			const loadingConfig = { dispatch: this.props.dispatch }
			doActionReorderRouteRequest(body, onSuccess, onError, loadingConfig);

		} else {
			this.props.fielderMsg({message: this.props.lan.messageUpdateRoute, variant:  'warning', closeButton: true, duration: 6000});
		}
	};
	showDetailTask = (id) => {
		if (id && id > 0) {
			this.setState({ showDetailTask: true, idTask: id });
		}
	};
	showDetailTaskDetail = (id) => {
		if (id && id > 0) {
			this.setState({ showDetailTask: true, idTask: id });
		}
	};
	getMarkerDescription = (task, position) => {
		let description = '';
		const priority = task?.severity ? task.severity : task.priority;
		const priorityName = task?.severityName ? task.severityName : task.priorityName;

		if (position) {
			description = description + ' ( ' + position + ' ) ';
		}
		let taskFolio = task.folio ? task.folio : '';
		if (task.idTask) {
			description = description + '\n- ' + this.props.lan.task + ': ' + task.idTask + ' [' + taskFolio + ']';
		}
		if (task.severity) {
			description = description + '\n- ' + this.props.lan.priority + ': ' + getSeverityText(priority, this.props.lan, priorityName);
		}
		if (task.branch) {
			description = description + '\n- '+ this.props.lan.branch + ': ' + task.branch.name;
		} else if (task.user && task.user.branch) {
			description = description + '\n- '+ this.props.lan.branch + ': ' + + task.user.branch.name;
		} else if (task.user) {
			description = description + '\n- '+ this.props.lan.client + ': ' + + task.user.name;
		}

		return description;
	};
	setDefaultTasksMarkers() {
		const markers = [];
		const routePoints = [];
		const component = this;

		this.state.items.forEach(function (element, index) {
			const loc = element.startLocation;
			let color = getPinColor([element], 0);
			const position = index + 1;
			markers.push({
				coordinates: { lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitud) },
				pin: color,
				draggable: false,
				id: element.idTask,
				onMarkerClick: (id) => component.showDetailTask(id),
				description: component.getMarkerDescription(element, position),
			});
			routePoints.push({
				coordinates: { lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitud) },
				pin: color,
				draggable: false,
			});
		});

		var latitude = '18.4316459';
		var longi = '-100.2870970';
		for (let i = 0; i < this.state.route.emergencies.length; i++) {
			let color = 'yellow';

			markers.push({
				coordinates: {
					lat: parseFloat(this.state.route.emergencies[i].latitude),
					lng: parseFloat(this.state.route.emergencies[i].longitude),
				},
				pin: color,
				draggable: false,
				description: this.state.route.emergencies[i].emergencyText
					? this.state.route.emergencies[i].emergencyText
					: '',
			});
		}

		this.setState({ markers: markers, routePoints: routePoints });
	};
	getAgentMarkerDescription = (agent) => {
		let description = '';
		if (agent) {
			const name = agent.name ? agent.name : '';
			const lname = agent.lastName ? agent.lastName : '';
			const agentCompanyName = agent.companyName ? agent.companyName : '';
			description = '- '+name + ' ' + lname+ '\n- '+ this.props.lan.company+': '+agentCompanyName;
			return description;
		}
		return description;
	};
	showDetailAgent = (id) => {
		if (id && id > 0) {
			this.setState({
				showDetailAgent: true, 
				idAgent: id 
			}, () => {
				this.editAgentModal();
			});
		}
	};
	getEndPoint = () => {
		if (this.state.route && this.state.route.endPoint) {
			var endPoint = this.state.route.endPoint;
			var pin = 'end-route';
			var endPointLocation = {
				coordinates: { lat: parseFloat(endPoint.latitude), lng: parseFloat(endPoint.longitud) },
				pin: pin,
				draggable: false,
				id: endPoint.idLocation,
				description: this.props.lan.routeEndPoint,
			};
			return endPointLocation;
		}
		return null;
	};
	getMarkers = () => {
		const markers = [];
		const routePoints = [];
		const component = this;
		var agentLocation = null;
		if (
			this.state.route &&
			this.state.route.agent &&
			(this.state.route.agent.geolocation || this.state.route.agent.location)
		) {
			var loc = null;
			if (this.state.route.status === 3 && this.state.route.agent.geolocation) {
				loc = this.state.route.agent.geolocation;
			} else if (this.state.route.agent.location) {
				loc = this.state.route.agent.location;
			}

			if (loc != null) {
				const agent = this.state.route.agent;

				if (loc.activeIcon == true) {
					var pin = 'agent';
				} else {
					var pin = 'inactiveAgent';
				}
				agentLocation = {
					coordinates: { lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitud) },
					pin: pin,
					draggable: false,
					id: agent.idAgent,
					onMarkerClick: (id) => this.showDetailAgent(id),
					description: this.getAgentMarkerDescription(agent),
				};
				markers.push(agentLocation);
			}
		}

		if (agentLocation != null) {
			var coords = agentLocation.coordinates;
			routePoints.push({
				coordinates: coords,
				pin: 'agentroute',
				draggable: false,
			});
		}

		this.state.items.forEach(function (element, index) {
			const loc = element.startLocation;

			let color = getPinColor([element], 0);
			const position = index + 1;
			let latitude = parseFloat(loc.latitude);
			let longitud = parseFloat(loc.longitud);
			markers.push({
				coordinates: { lat: latitude, lng: longitud },
				pin: color,
				draggable: false,
				id: element.idTask,
				onMarkerClick: (id) => component.showDetailTask(id),
				description: component.getMarkerDescription(element, position),
			});

			routePoints.push({
				coordinates: { lat: latitude, lng: longitud },
				pin: color,
				draggable: false,
			});
		});

		this.state.tasksToShow.forEach(function (element, index) {
			const loc = element.startLocation;
			let color = getPinColor([element], 0);
			markers.push({
				coordinates: { lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitud) },
				pin: color,
				draggable: false,
				id: element.idTask,
				onMarkerClick: (id) => component.showDetailTask(id),
				description: component.getMarkerDescription(element),
			});
		});

		var endPointLocation = this.getEndPoint();
		if (endPointLocation) {
			routePoints.push({
				coordinates: endPointLocation.coordinates,
				pin: 'agentroute',
				draggable: false,
			});
			markers.push(endPointLocation);
		}

		var latitude = '18.4316459';
		var longi = '-100.2870970';
		for (let i = 0; i < this.state.route.emergencies.length; i++) {
			let tz = this.state.timeZone;
			let ft = 'DD-MM-YYYY hh:mm a';

			let color = 'yellow';

			markers.push({
				coordinates: {
					lat: parseFloat(this.state.route.emergencies[i].latitude),
					lng: parseFloat(this.state.route.emergencies[i].longitude),
				},
				pin: color,
				draggable: false,
				description: this.state.route.emergencies[i].emergencyText
					? this.state.route.emergencies[i].emergencyText +
					  '  ' +
					  moment.tz(this.state.route.emergencies[i].createdDate, tz).format(ft)
					: '' + moment.tz(this.state.route.emergencies[i].createdDate, tz).format(ft),
			});
		}

		this.setState({ markers: markers, originalMarkers: markers, routePoints: routePoints });
	};
	getTimeZone = (route) => {
		if (route && route.tasks && route.tasks.length > 0) {
			if (route.tasks[0] && route.tasks[0].startLocation && route.tasks[0].startLocation.timeZone) {
				return route.tasks[0].startLocation.timeZone;
			}
		}
		return 'America/Mexico_City';
	};
	renderRouteResponse = (res) => {
			const routeResponse = res;

			if (routeResponse.route && routeResponse.route.expenses) {
				this.calculateRouteExpenses(routeResponse.route, routeResponse.route.expenses);
			}
			const routeData = this.calculateRouteData(routeResponse.route);
			routeResponse.route.km = routeData.kmCost;

			var startLocation;
			if (routeResponse.route.startPoint) {
				startLocation = routeResponse.route.startPoint;
			} else {
				startLocation = routeResponse.route.agent.location;
				startLocation.idLocation = 0;
			}
			var endLocation = routeResponse.route.endPoint;

			this.setState({
				loading: false,
				route: routeResponse.route,
				timeZone: this.getTimeZone(routeResponse.route),
				items: routeResponse.route.tasks,
				originalItems: routeResponse.route.tasks,
				routeExpensesPreferences: routeResponse.routeExpensesPreferences,
				km: routeData.km,
				kmRouteCost: '$ ' + routeData.kmCost,
				startLocation: startLocation,
				endLocation: endLocation,
			});

			this.getMarkers();

	};
	calculateRouteData = (route) => {
		const km = this.getKm(route.metersValue);
		const kmCost = this.getKMCost(route);

		return { kmCost: kmCost, km: km };
	};
	setTaskToRouteResponse = (res) => {
		const routeResponse = res;

		if (routeResponse.route) {
			let tasks = this.state.items;
			routeResponse.task.added = true;
			tasks.splice(this.state.position, 0, routeResponse.task);
			this.getMarkers();
			this.setState({ idTask: undefined, items: tasks });
		}
	};
	getTaskById = () => {
		const body = { folio: this.state.idTask };
		const loadingConfig = { dispatch: this.props.dispatch }
		const onSuccess = this.setTaskToRouteResponse;
		const onError = console.log

		getRouteTaskFolioRequest(body, onSuccess, onError, loadingConfig)
	};
	getKm = (value) => {
		let km = parseFloat(value) / 1000;
		let result = km + ' km';

		return result;
	};
	getTime = (date) => {
		date = Number(date);
		const hours = Math.floor(date / 3600);
		const minutes = Math.floor((date % 3600) / 60);
		const seconds = Math.floor((date % 3600) % 60);

		const hoursDisplay =
			hours > 0
				? hours + (hours == 1 ? ' ' + this.props.lan.hour + ', ' : ' ' + this.props.lan.hours + ' ')
				: '';
		const minutesDisplay =
			minutes > 0
				? minutes +
				  (minutes == 1 ? ' ' + this.props.lan.minute + ', ' : ' ' + this.props.lan.minutes + ' ')
				: '';
		const secondsDisplay =
			seconds > 0
				? seconds +
				  (seconds == 1 ? ' ' + this.props.lan.second : ' ' + this.props.lan.seconds + ' ')
				: '';
		return hoursDisplay + minutesDisplay + secondsDisplay;
	};
	getTaskNumberSelect = () => this.state.items.map((item, i) => this.getOption(i));
	getOption = (element) => {
		return <option value={element}>{element + 1}</option>;
	};
	validateAddTask = () => {
		if (this.state.add && this.state.idTask) {
			this.getTaskById();
		} else {
			this.setState({ add: !this.state.add });
		}
	};
	onIdTaskChange = (event) => {
		let value = event.target.value;

		if (value && value !== '') {
			this.setState({ idTask: value });
		} else {
			this.setState({ idTask: undefined });
		}
	};
	deleteTaskFromRoute = (index) => {
		let tasks = this.state.items;
		tasks.splice(index, 1);
		this.setState({ items: tasks });
	};
	formatDate = (dateInMillis) => {
		var formattedDate = moment(dateInMillis).format('DD-MM-YYYY HH:mm');

		return formattedDate;
	};
	handleFromDate = (date) => {
		if (date == null) {
			this.setState({ items: this.state.originalItems, tasksToShow: [], markers: [] });
			this.setDefaultTasksMarkers();
		}
		this.setState({
			from: date,
		});
	};
	handleToDate = (date) => {
		if (date && this.state.from && date.getTime() >= this.state.from.getTime()) {
			this.setState(
				{
					to: date,
					offset: 0,
					tasksToShow: [],
				},
				() => this.getTasks()
			);
		}
	};
	getTasks = () => {
		const from = this.state.from;
		const to = this.state.to;

		const body = {
			requestType: 'GET_TASK_TO_SHOW',
			fromYear: from.getFullYear(),
			fromMonth: from.getMonth(),
			fromDay: from.getDate(),
			toYear: to.getFullYear(),
			toMonth: to.getMonth(),
			toDay: to.getDate(),
			offset: this.state.offset,
			limit: this.state.limit,
		};

		const onSuccess = this.renderTasks;
		const onError = console.log
		const loadingConfig = { dispatch: this.props.dispatch }

		getRouteTaskToShowRequest(body, onSuccess, onError, loadingConfig)
	};
	renderTasks = (res) => {
		let markers = this.state.originalMarkers;
		if (res) {

			if (res && res.services) {
				if (!markers && markers.length === 0) {
					markers = [];
				}

				if (res.services.length > 0) {
					const tasks = res.services;
					let allTasks = this.state.tasksToShow.concat(tasks);

					this.setState({
							markers: markers,
							tasksToShow: allTasks,
							mycount: res.total,
							showTasks: true,
							offset: this.state.offset + this.state.limit,
						},
						() => this.getMarkers()
					);
				}
			} else {
				this.setState({ markers: markers, showTasks: true, tasksToShow: [] }, () =>
					this.getMarkers()
				);
			}
		} else {
			this.setState({ markers: markers, showTasks: true, tasksToShow: [] }, () =>
				this.getMarkers()
			);
		}
	};
	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			this.setState({
				showTasksResult: true,
				opacity: 0,
			});
		}
	};
	onChangeInitialMap() {
		if (this.state.initialMap) {
			this.setState({ initialMap: false });
		}
	};
	refresh = () => {
		let body = Request.getRouteById(this.props.idRoute);
		const onSuccess = this.renderRouteResponse;
		const loadingConfig = { dispatch: this.props.dispatch }
		getRouteByIdRequest(body, onSuccess, null, loadingConfig);
	};
	calculateRouteExpenses = (route, preferences) => {
		const kmCost = this.getKMCost(route);
		const kmCostWithExpenses = this.getKMCostWithExpenses(kmCost, preferences);
		const totalCost = this.getTotalCost(kmCostWithExpenses, preferences);

		this.setState({
			kmCost: kmCost,
			kmCostWithExpenses: kmCostWithExpenses,
			totalCost: totalCost,
		});
	};
	getTotalCost = (kmCostWithExpenses, preferences) => {
		var total = parseFloat(kmCostWithExpenses);

		if (preferences.defaultExpenses) {
			preferences.defaultExpenses.map((element, index) => {
				if (element.id !== 3) {
					total = total + element.value;
				}
			});
			preferences.dynamicExpenses.map((element, index) => {
				if (element.id !== 3) {
					total = total + element.value;
				}
			});
		}

		return total;
	};
	getKMCost = (props) => {
		try {
			let cost = ((props.metersValue * props.kmCost) / 1000).toFixed(2);

			return cost;
		} catch (error) {
			return 0.0;
		}
	};
	isValidThePercentageKm = (preferences) => {
		return (
			preferences &&
			preferences.defaultExpenses &&
			preferences.defaultExpenses.expensesKm &&
			typeof preferences.defaultExpenses.expensesKm === 'number' &&
			preferences.defaultExpenses.expensesKm > 0 &&
			preferences.defaultExpenses.expensesKm < 101
		);
	};
	getKMCostWithExpenses = (kmCost, preferences) => {
		try {
			let cost = kmCost;

			const isValid = this.isValidThePercentageKm(preferences);
			if (isValid) {
				let percentage = preferences.percentageKm;
				percentage = percentage / 100;
				percentage = percentage + 1;

				cost = (cost * percentage).toFixed(2);
			}
			return cost;
		} catch (error) {
			return 0.0;
		}
	};
	getActivityTime = (millis) => {
		const date = new Date(millis);
		var hour, minutes;

		if (date.getHours() < 10) {
			hour = '0' + date.getHours();
		} else {
			hour = date.getHours();
		}

		if (date.getMinutes() < 10) {
			minutes = '0' + date.getMinutes();
		} else {
			minutes = date.getMinutes();
		}
		return hour + ' : ' + minutes + ' hrs';
	};
	getActivityDate = (millis) => {
		const date = new Date(millis);
		const month = date.toLocaleString('es-es', { month: 'long' });
		return date.getDate() + ' - ' + month + ' - ' + date.getFullYear();
	};
	getActivityUser = (element) => {
		let name = '';
		let image = ProfileImage;
		if (element.agent) {
			name = element.agent.name + ' ' + element.agent.lastName;
			if (element.agent.profileImage) {
				image = element.agent.profileImage;
			}
		} else if (element.clientUser) {
			name = element.clientUser.name + ' ' + element.clientUser.lastName;
			if (element.clientUser.profileImage) {
				image = element.clientUser.profileImage.scalePath;
			}
		}

		return (
			<TableCell align='left' className='rd-activity-table-cell rd-activity-user-image'>
				<Image src={image} className='rd-activity-user-pic d-block mx-auto mb-2' roundedCircle />
				<span className='rd-activity-user-text'>{name}</span>
			</TableCell>
		);
	};
	getEmergencyDate = (emergency) => {
		return moment.tz(emergency.createdDate, this.state.timeZone).format('DD-MM-YYYY hh:mm a');
	};
	hideModal = (onClose) => {
		onClose();
	};
	setDefaultFalse() {
		this.setState({
			showTasksResult: false,
			opacity: 1,
		});
	};
	setDefaultFalseModal(task, position) {
		//this.state.position;
		let tasks = this.state.items;
		var canInsert = true;
		for (let i = 0; i < this.state.items.length; i++) {
			if (task.idTask == this.state.items[i].idTask) {
				canInsert = false;
			}
		}

		if (canInsert) {
			tasks.splice(position, 0, task);
		}

		this.setState({ items: tasks });
		this.getMarkers();
		this.setState({
			showTasksResult: false,
			opacity: 1,
		});
	};
	closePopup = () => this.setState({ errorMessage: !this.state.errorMessage });
	renderRoute = () => {
		const inactiveSection = 'col text-center rd-nav-item';
		const activeSection = inactiveSection + ' rd-active';

		if (this.state.loading) {
			return <div align='center' className='route-details-loading-view'></div>;
		} else {
			return (
				<Container fluid>
					<Row className='mx-3 ml-5'>
						<Col className='col-sm-1 '>
							<Image src={RoutesImage} className='mr-2' />
						</Col>

						<Col className='col-sm-6'>
							<Navbar className='rd-navbar mb-3'>
								<Nav.Item
									key='route-nav'
									id='route-nav'
									className={this.state.showRouteInfo ? activeSection : inactiveSection}
								>
									<Nav.Link
										id='route-nav-link'
										className='rd-nav-title'
										onClick={() =>
											this.setState({
												showActivity: false,
												showRouteInfo: true,
												showEmergencies: false,
											},() => { this.refresh(); })
										}
									>
										{this.props.lan.route}
									</Nav.Link>
								</Nav.Item>

								<Nav.Item
									key='activity-nav'
									id='activity-nav'
									className={this.state.showActivity ? activeSection : inactiveSection}
								>
									<Nav.Link
										className='rd-nav-title'
										onClick={() =>
											this.setState({
												showActivity: true,
												showRouteInfo: false,
												showEmergencies: false,
											})
										}
									>
										{this.props.lan.activity}
									</Nav.Link>
								</Nav.Item>

								<Nav.Item
									key='emergencies-nav'
									id='emergencies-nav'
									className={this.state.showEmergencies ? activeSection : inactiveSection}
								>
									<Nav.Link
										className='rd-nav-title'
										onClick={() =>
											this.setState({
												showActivity: false,
												showRouteInfo: false,
												showEmergencies: true,
											})
										}
									>
										{this.props.lan.incidences}
									</Nav.Link>
								</Nav.Item>
							</Navbar>
						</Col>

						<Col sm={5}>
							<Row className={'justify-content-end mr-2'}>
								<Col sm={'auto'} className={'no-padding'}>
									<Image
										src={RefreshIcon}
										className='rd-click-image'
										alt='Refresh'
										onClick={() => {
											this.refresh();
										}}
									/>
								</Col>

								<Col className='col-sm-1'>
									<Image
										src={cancelIcon}
										className='mr-2 rd-click-image'
										onClick={this.props.onClose}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					{this.state.showRouteInfo ? (
						<Row className='mx-4'>
							<Col className='col-sm-6 offset-sm-6 mb-3'>
								<Row
									className={
										this.state.datesError ? 'Fielder-input-border-alert' : 'Fielder-input-border'
									}
								>
									<Col sm={'auto'}>
										<img src={CalendarIcon} className='icon my-2' alt='' />
									</Col>
									<Col>
										<label>
											<DatePicker
												selected={this.state.from} // seleccionada fecha
												onChange={this.handleFromDate}
												minTime={this.state.minTime}
												id='input-date-from'
												showMonthDropdown
												showYearDropdow
												maxTime={new Date().setHours(23, 59)}
												dateFormat='dd/MM/yyyy' // Formato de fecha
												className='MyOperation-eschedule form-control mt-1 text-left'
												placeholderText='De'
											/>
											<img src={DropDown} className='icon my-2 dropDownCalendar' alt='' />
										</label>
									</Col>
									<Col sm={'auto'} className={this.state.from ? '' : 'rd-disable'}>
										<img
											src={CalendarIcon}
											className='icon my-2 MyOperation-img-input Fielder-icon-button'
											alt=''
										/>
									</Col>
									<Col className={this.state.from ? '' : 'rd-disable'}>
										<label>
											<DatePicker
												selected={this.state.to}
												onChange={this.handleToDate}
												minTime={this.state.minTime}
												showMonthDropdown
												showYearDropdow
												maxTime={new Date().setHours(23, 59)}
												dateFormat='dd/MM/yyyy'
												className='MyOperation-eschedule form-control mt-1 text-left'
												placeholderText='A'
											/>
											<img
												src={DropDown}
												className='icon my-2 dropDownCalendar Fielder-icon-button'
												alt=''
											/>
										</label>
									</Col>
								</Row>
							</Col>
							{this.state.showTasks && (
								<Col className='col-sm-4 offset-sm-8 mb-3'>
									<span className='rd-tasks-to-show'>
										{this.state.tasksToShow.length +
											' ' +
											this.props.lan.tasks +
											' ' +
											this.props.lan.from +
											' ' +
											this.state.mycount}
									</span>
									{this.state.offset < this.state.mycount && (
										<Button className='rd-clear-button' onClick={this.getTasks}>
											<u>{this.props.lan.showMore}</u>
										</Button>
									)}
								</Col>
							)}

							{/* Labels And Values */}
							<Col sm={3}>
								<Row className='mx-1'>
									<Row className='mx-1 full-width'>
										<Col id='route-id' className='p-2'>
											<Col sm={12} className='p-0'>
												<span className='label-input'>{this.props.lan.routeNumber}</span>
											</Col>
											<Col sm={12} className='p-0'>
												<span className='route-text-large'>{this.state.route.id}</span>
											</Col>
										</Col>
										<Col id='route-status' className='mt-1 p-2'>
											<RouteStatus value={this.state.route.status} {...this.props} />
										</Col>

										{this.state.route.status !== 4 &&
											this.state.route.status !== 5 &&
											this.state.isAllowedRouteEdit && (
												<Col sm={3} className='p-2 my-auto'>
													<Image
														src={RepositionIcon}
														className='Fielder-icon mx-2 Fielder-icon-button'
														alt='Change Position'
														onClick={() =>
															this.setState({ editable: !this.state.editable, add: false })
														}
													/>

													{this.state.showRouteExpenses && this.state.routeExpensesPreferences && (
														<Image
															src={ExpensesIcon}
															className='Fielder-icon mx-2 Fielder-icon-button'
															alt='Change Position'
															onClick={() =>
																this.setState({ configExpenses: !this.state.configExpenses })
															}
														/>
													)}
												</Col>
											)}
									</Row>
								</Row>

								{/* Labels for the information */}

								<Row className='mx-1 mt-3'>
									<Col sm={12} className={'mt-1'}>
										<Row>
											<Col className={'label-input '}>{this.props.lan.routeStartPointTitle}</Col>
										</Row>
										<Row>
											<Col id={'route-name'} className={'rd-text'}>
												<input
													type='text'
													className={'route-details-input '}
													defaultValue={this.state.startLocation.address}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</Col>
											{this.state.route.status !== 3 &&
												this.state.route.status !== 4 &&
												this.state.route.status !== 5 &&
												this.state.isAllowedRouteEdit &&
												this.state.editable && (
													<Col sm={1} className='da-mr-none'>
														<img
															src={editImageIcon}
															className={'icon my-1'}
															alt=''
															onClick={() => {
																this.setState({
																	showEditRouteStartPoint: !this.state.showEditRouteStartPoint,
																});
															}}
														/>
													</Col>
												)}
										</Row>
									</Col>
									<Col sm={12} className={'mt-1'}>
										<Row>
											<Col className={'label-input'}>{this.props.lan.routeEndPointTitle}</Col>
										</Row>
										<Row>
											<Col id={'route-name'} className={'rd-text'}>
												<input
													type='text'
													className={'route-details-input '}
													defaultValue={
														this.state.endLocation ? this.state.endLocation.address : null
													}
													readOnly
													style={{ width: '100%' }}
												/>
											</Col>

											{this.state.route.status !== 4 &&
												this.state.route.status !== 5 &&
												this.state.isAllowedRouteEdit &&
												this.state.editable && (
													<Col sm={1} className='da-mr-none'>
														<img
															src={editImageIcon}
															className={'icon my-1'}
															alt=''
															onClick={() => {
																this.setState({
																	showEditRouteEndPoint: !this.state.showEditRouteEndPoint,
																});
															}}
														/>
													</Col>
												)}
										</Row>
									</Col>
									<Col sm={12} className={'mt-1'}>
										<Row>
											<Col className={'label-input '}>{this.props.lan.routeName}</Col>
										</Row>
										<Row>
											<Col id={'route-name'} className={'rd-text'}>
												<input
													type='text'
													className={'route-details-input '}
													defaultValue={this.state.route.name}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</Col>
										</Row>
									</Col>

									{this.state.route.agent && (
										<Col sm={12} className={'mt-4'}>
											<Row>
												<Col className={'label-input '}>{this.props.lan.agentName}</Col>
											</Row>
											<Row>
												<Col id={'route-agent'} className={'rd-text'}>
													<input
														type='text'
														className={'route-details-input '}
														defaultValue={
															this.state.route.agent.name + ' ' + this.state.route.agent.lastName
														}
														readOnly={true}
														style={{ width: '90%' }}
													/>
													<Image
														src={DetailTaskIcon}
														className='Fielder-icon Fielder-icon-button'
														alt='Change Position'
														style={{ width: '8%', marginLeft: '2%' }}
														onClick={() => this.showDetailAgent(
															this.state.route.agent.idAgent)}
													/>
												</Col>
											</Row>
										</Col>
									)}

									{!this.state.route.agent && (
										<Col sm={12} className={'mt-4'}>
											<Row>
												<Col className={'label-input '}>{this.props.lan.agentName}</Col>
											</Row>
											<Row>
												<Col id={'route-agent'} className={'rd-text'}>
													<input
														type='text'
														className={'route-details-input '}
														defaultValue={this.props.lan.unassigned}
														readOnly={true}
														style={{ width: '90%' }}
													/>
												</Col>
											</Row>
										</Col>
									)}
									<Col sm={12} className={'mt-4'}>
										<Row>
											<Col className={'label-input '}>{this.props.lan.executionDate}</Col>
										</Row>
										<Row>
											<Col id={'route-executionDate'} className={'rd-text'}>
												<input
													type='text'
													className={'route-details-input '}
													defaultValue={this.state.route.executionFormattedDate}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</Col>
										</Row>
									</Col>
									{this.state.route.startedFormattedDate && (
										<Col sm={12} className={'mt-4'}>
											<Row>
												<Col className={'label-input '}>{this.props.lan.startedFormattedDate}</Col>
											</Row>
											<Row>
												<Col id={'route-startDate'} className={'rd-text'}>
													<input
														type='text'
														className={'route-details-input '}
														defaultValue={this.state.route.startedFormattedDate}
														readOnly={true}
														style={{ width: '100%' }}
													/>
												</Col>
											</Row>
										</Col>
									)}
									{this.state.route.finishedFormattedDate && (
										<Col sm={12} className={'mt-4'}>
											<Row>
												<Col className={'label-input '}>{this.props.lan.finishedFormattedDate}</Col>
											</Row>
											<Row>
												<Col id={'route-finishDate'} className={'rd-text'}>
													<input
														type='text'
														className={'route-details-input '}
														defaultValue={this.state.route.finishedFormattedDate}
														readOnly={true}
														style={{ width: '100%' }}
													/>
												</Col>
											</Row>
										</Col>
									)}
									<Col sm={12} className={'mt-4'}>
										<Row>
											<Col className={'label-input '}>{this.props.lan.timeTraveled}</Col>
										</Row>
										<Row>
											<Col id={'route-estimatedTime'} className={'rd-text'}>
												<input
													type='text'
													className={'route-details-input '}
													defaultValue={this.getTime(this.state.route.estimatedTimeValue)}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</Col>
										</Row>
									</Col>
									<Col sm={12} className={'mt-4'}>
										<Row>
											<Col className={'label-input '}>{this.props.lan.distanceTraveled}</Col>
										</Row>
										<Row>
											<Col id={'route-meters'} className={'rd-text'}>
												<input
													type='text'
													className={'route-details-input '}
													defaultValue={this.state.km}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</Col>
										</Row>
									</Col>
									<Col sm={12} className={'mt-4'}>
										<Row>
											<Col className={'label-input '}>{this.props.lan.routeCost + ' (km) '}</Col>
										</Row>
										<Row>
											<Col id={'route-charge'} className={'rd-text'}>
												<input
													type='text'
													className={'route-details-input '}
													defaultValue={this.state.kmRouteCost}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</Col>
										</Row>
									</Col>
									{this.state.route.comment && this.state.route.status === 4 && (
										<Col sm={12} className={'mt-4'}>
											<Row>
												<Col className={'label-input '}>{this.props.lan.cancelReason}</Col>
											</Row>
											<Row>
												<Col id={'route-cancel-comment'} className={'rd-text'}>
													<input
														type='text'
														className={'route-details-input '}
														defaultValue={this.state.route.comment}
														readOnly={true}
														style={{ width: '100%' }}
													/>
												</Col>
											</Row>
										</Col>
									)}
									{this.state.route.expenses &&
										this.state.route.expenses.defaultExpenses &&
										this.state.route.expenses.defaultExpenses.map((element, index) => {
											if (element.enabled) {
												return (
													<div>
														<Col sm={12} className={'mt-4'}>
															<Row>
																<Col className={'label-input '}>
																	{element.name === 'expensesFood'
																		? this.props.lan.expensesFood
																		: element.name === 'expensesSleeping'
																		? this.props.lan.expensesSleeping
																		: element.name === 'expensesKm'
																		? this.props.lan.expensesKm
																		: element.name}
																</Col>
															</Row>
															<Row>
																<Col id={'route-food-average'} className={'rd-text'}>
																	<input
																		type='text'
																		className={'route-details-input '}
																		defaultValue={
																			element.name === 'expensesKm'
																				? '% ' + element.value
																				: '$ ' + element.value
																		}
																		readOnly={true}
																		style={{ width: '100%' }}
																	/>
																</Col>
															</Row>
														</Col>
													</div>
												);
											}
										})}
									{this.state.route.expenses &&
										this.state.route.expenses.dynamicExpenses &&
										this.state.route.expenses.dynamicExpenses.map((element, index) => {
											if (element.enabled) {
												return (
													<div>
														<Col sm={12} className={'mt-4'}>
															<Row>
																<Col className={'label-input '}>{element.name}</Col>
															</Row>
															<Row>
																<Col id={'route-food-average'} className={'rd-text'}>
																	<input
																		type='text'
																		className={'route-details-input '}
																		defaultValue={'$ ' + element.value}
																		readOnly={true}
																		style={{ width: '100%' }}
																	/>
																</Col>
															</Row>
														</Col>
													</div>
												);
											}
										})}
								</Row>
							</Col>

							{/* SearchBar And Tasks */}
							<div className='col-sm-4'>
								{/* Hidden when Can edit position */}
								{this.state.editable && (
									<Row className='rd-add-task'>
										{this.state.isAllowedRouteAddTask && ( //this.state.editable && this.state.add &&
											<LightSpeed left>
												<Col className='Fielder-input-border my-auto route-input '>
													<Row className='mt-1 justify-content-center'>
														<Image
															src={SearchIcon}
															className='fielder-icon mt-1 Fielder-icon-button'
															alt='Search'
														/>
														<input
															type='text'
															key='route-details-search'
															className='Fielder-input no-border ml-2 mt-1'
															placeholder={
																this.props.lan.searchBy +
																' ID ' +
																this.props.lan.and +
																' ' +
																this.props.lan.folio
															}
															style={{ width: '80%' }}
															onChange={this.onIdTaskChange}
															onKeyPress={this.handleKeyPress}
														/>
													</Row>
												</Col>
											</LightSpeed>
										)}
										{this.state.editable && this.state.add && (
											<Row className='rd-add-task-row'>
												<span className=' col-sm-8'>{this.props.lan.label_position_add_task}</span>

												<select
													className='custom-select col-sm-4'
													onChange={this.onChangeTaskPosition}
													value={this.state.position}
												>
													{this.getTaskNumberSelect()}
												</select>
											</Row>
										)}
									</Row>
								)}

								<Row className='mt-3'>
									{/* Draggable space */}
									<DragDropContext onDragEnd={this.onDragEnd}>
										<Scrollbars style={{ height: '550px' }} className='rd-timeline'>
											<Droppable droppableId='droppable' style={{ height: 'inherit' }}>
												{(provided, snapshot) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														style={this.getListStyle(snapshot.isDraggingOver)}
														className='rd-droppable-bg'
													>
														{this.state.items.map((element, i) => (
															<Draggable
																draggableId={`${element.idTask}-task-draggable`}
																index={i}
																style={{ width: '100%' }}
																isDragDisabled={!this.state.isAllowedReorganizeRoute}
															>
																{(provided, snapshot) => {
																	let taskType = '';

																	if (this.state.taskTypes.taskTypeFunctionality) {
																		let parents = getTaskTypeParents(
																			element.serviceTypeId,
																			this.state.taskTypes.types
																		);
																		taskType = parents.join(' > ');
																	} else {
																		switch (element.serviceTypeId) {
																			case 1:
																				taskType = this.props.lan.tasktype1;
																				break;
																			case 2:
																				taskType = this.props.lan.tasktype2;
																				break;
																			case 3:
																				taskType = this.props.lan.tasktype3;
																				break;
																			case 4:
																				taskType = this.props.lan.tasktype4;
																				break;
																			default:
																				taskType = '';
																		}
																	}

																	return (
																		<div className='row' style={{ maxWidth: '100%' }}
																		>
																			<div
																				className={
																					'col ml-1  rd-list-item ' +
																					getStatus(element.status) +
																					' ' +
																					(this.state.editable && 'route-details-editable ')
																				}
																				ref={provided.innerRef}
																				{...provided.dragHandleProps}
																				{...provided.draggableProps}
																				style={this.getItemStyle(
																					snapshot.isDragging,
																					provided.draggableProps.style
																				)}
																			>
																				<Col xs='auto' className='ml-3'>
																					<Row className='ml-1 my-auto'>
																						<Col className='col-auto ml-3 no-padding'>
																							<div className='rd-task-element-id'>
																								{this.props.lan.idFielder}
																							</div>
																						</Col>

																						<Col className='col-auto ml-1 no-padding'>
																							<div
																								id={'task-idFielder-' + i}
																								className='rd-task-element-name'
																							>
																								{element.idTask}
																							</div>
																						</Col>
																						<Col className='col rd-task-status'>
																							<StatusFormatter
																								{...this.props}
																								value={element.status}
																							/>
																						</Col>
																					</Row>

																					<Row className='ml-1 my-auto'>
																						<Col className='col-auto ml-3 no-padding'>
																							<div className='rd-task-element-id'>
																								{this.props.lan.idCustom}
																							</div>
																						</Col>

																						<Col className='col-auto no-padding ml-1'>
																							<div
																								id={'task-idCustom-' + i}
																								className='rd-task-element-name2'
																							>
																								{element.idCustom}
																							</div>
																						</Col>
																					</Row>

																					<Row className='ml-1 my-auto'>
																						<Col className='col-auto ml-3 no-padding'>
																							<div className='rd-task-element-id'>
																								{this.props.lan.folio}
																							</div>
																						</Col>
																						<Col className='col-auto no-padding ml-1'>
																							<div
																								id={'task-folio-' + i}
																								className='rd-task-element-name2'
																							>
																								{element.folio
																									? element.folio
																									: this.props.lan.noFolio}
																							</div>
																						</Col>
																					</Row>

																					<Row className='ml-1 my-auto'>
																						<Col className='col-auto no-padding ml-3'>
																							<div className='rd-task-element-id pr-1'>
																								{this.props.lan.priority}
																							</div>
																						</Col>
																						<Col className='col-auto pl-1 ml-1'>
																							<div
																								id={'task-severity-' + i}
																								className={getSeverityClassName(element.severity)}
																							>
																								{getSeverityText(
																									element.severity,
																									this.props.lan, 
																									element.severityName,
																								)}
																							</div>
																						</Col>

																						<Col className='col-auto pl-1  ml-3'>
																							{element.countAssets > 0 ? (
																								<img
																									src={AssetsIcon}
																									className='img-fluid mr-1 mb-2'
																									alt=''
																								/>
																							) : null}
																							{element.countInventories > 0 ? (
																								<img
																									src={InventoryIcon}
																									className='img-fluid mr-1 mb-2'
																									alt=''
																								/>
																							) : null}
																							{element.countImages > 0 ? (
																								<img
																									src={ImagesIcon}
																									className='img-fluid mr-1 mb-2'
																									alt=''
																								/>
																							) : null}
																							{element.countForms > 0 ? (
																								<img
																									src={FormIcon}
																									className='img-fluid mr-1 mb-2'
																									alt=''
																								/>
																							) : null}
																						</Col>
																					</Row>

																					<Row className='ml-1 my-auto'>
																						<Col
																							id={'task-type-' + i}
																							sm={6}
																							className='rd-task-element-act mt-2'
																						>
																							{taskType}
																						</Col>
																						<div className='col-6 '>
																							<button
																								type='submit'
																								className='btn btn-outline-primary btn-success create-edit-modal-btnsave-size font-size-taskgroup buttonDetailcss'
																								onClick={() => this.showDetailTaskDetail(element.idTask)}
																							>
																								{this.props.lan.showDetail}
																							</button>
																						</div>
																					</Row>

																					<Row className='ml-1 my-auto'>
																						<Col
																							id={'task-executeTime-' + i}
																							sm={12}
																							className='rd-task-element-id'
																						>
																							{element.startTimeFormatted}
																						</Col>
																					</Row>

																					{element.user ? (
																						<Row className='ml-1 mt-3'>
																							<Col className='col-auto ml-3 no-padding'>
																								<div className='rd-task-element-id'>
																									{this.props.lan.client}
																								</div>
																							</Col>
																							<Col className='col-auto no-padding ml-1'>
																								<div
																									id={'task-iduser-' + i}
																									className='rd-task-element-name2'
																								>
																									{element.user.name +
																										' ' +
																										element.user.lastName}
																								</div>
																							</Col>
																						</Row>
																					) : null}
																					{element.branch ? (
																						<Row className='ml-1 mt-3'>
																							<Col className='col-auto ml-3 no-padding'>
																								<div className='rd-task-element-id'>
																									{this.props.lan.branch}
																								</div>
																							</Col>
																							<Col className='col-auto no-padding ml-1'>
																								<div
																									id={'task-idBranch-' + i}
																									className='rd-task-element-name2'
																								>
																									{(element.branch.idCustom
																										? element.branch.idCustom + ' '
																										: '') + element.branch.name}
																								</div>
																							</Col>
																						</Row>
																					) : null}
																					<Row className='ml-1 '>
																						<Col
																							id={'task-address-' + i}
																							sm={12}
																							className='rd-task-element-location'
																						>
																							{element.startLocation.address}
																						</Col>
																					</Row>
																					<Row className='ml-1 mt-3'>
																						<Col className='col-auto '>
																							<div className='rd-task-element-id'>
																								{this.props.lan.originalDates}
																							</div>
																						</Col>
																					</Row>
																					<Row className='ml-1 my-auto'>
																						<Col className='col'>
																							<div className='rd-task-element-id'>
																								{this.props.lan.startedFormattedDate}
																							</div>
																						</Col>
																						<Col className='col'>
																							<div className='rd-task-element-id'>
																								{this.props.lan.finishedFormattedDate}
																							</div>
																						</Col>
																					</Row>
																					<Row className='ml-1 my-auto'>
																						<Col className='col'>
																							<div
																								id={'task-startDate-' + i}
																								className='rd-task-element-name2'
																							>
																								{element.startDateTimeRange
																									? this.formatDate(element.startDateTimeRange)
																									: 'No date'}
																							</div>
																						</Col>
																						<Col className='col'>
																							<div
																								id={'task-finishDate-' + i}
																								className='rd-task-element-name2'
																							>
																								{element.endDateTimeRange
																									? this.formatDate(element.endDateTimeRange)
																									: 'No date'}
																							</div>
																						</Col>
																					</Row>
																				</Col>
																			</div>

																			{this.state.editable && (
																				<div className='col-sm-1 my-auto'>
																					{this.state.isAllowedReorganizeRoute && (
																						<Image src={RepositionSmallIcon} className='my-1' />
																					)}
																					{this.state.isAllowedDeleteTask && (
																						<Image
																							src={DeleteIcon}
																							className='my-1'
																							onClick={() => this.deleteTaskFromRoute(i)}
																						/>
																					)}
																				</div>
																			)}
																		</div>
																	);
																}}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</Scrollbars>
									</DragDropContext>
								</Row>
							</div>

							{/* Map */}
							<Col>
								<div className='col-sm-12'>
									<Map
										googleMapURL={GOOGLE_URL_WITH_LIBRARIES}
										loadingElement={<div style={{ height: `100%` }} />}
										containerElement={<div style={{ height: `400px` }} />}
										mapElement={<div style={{ height: `100%` }} />}
										center={{ lat: 19.3901284, lng: -99.2896576 }}
										zoom={15}
										markersData={this.state.markers}
										routePoints={this.state.routePoints}
										origin={this.state.origin}
										changeMap={this.state.initialMap}
										onChangeInitialMap={this.onChangeInitialMap}
									/>
								</div>
								<Row className='mt-5 justify-content-center'>
									{this.state.editable && (
										<button className='Fielder-button' onClick={this.updateTaskOrders}>
											{this.props.lan.save}
										</button>
									)}
								</Row>
							</Col>
						</Row>
					) : null}
					{this.state.showActivity && (
						<Row className='rd-add-activity-view'>
							<Table className='rd-activity-table'>
								<TableBody>
									{this.state.route.logs &&
										this.state.route.logs.map((element, i) => (
											<TableRow key={i} className='rd-activity-table-row'>
												<TableCell align='left' className='rd-activity-table-cell'>
													<img src={ClockIcon} className='rd-activity-table-pic mr-3 ml-5' alt='' />
													{this.getActivityTime(element.createdDate)}
												</TableCell>
												<TableCell align='left' className='rd-activity-table-cell'>
													{this.getActivityDate(element.createdDate)}
												</TableCell>
												<TableCell align='left' className='rd-activity-table-cell'>
													{this.props.lan[LOC_ACTIVITY_TYPE + element.type]}
												</TableCell>

												{this.getActivityUser(element)}
											</TableRow>
										))}
								</TableBody>
							</Table>
						</Row>
					)}
					{this.state.showEmergencies && (
						<Row className='rd-add-activity-view rd-emergencies-table'>
							<span className='rd-emergencies-agent-span'>{this.props.lan.incidenceAgent}</span>
							{this.state.route.emergencies &&
								this.state.route.emergencies.map((element, i) => (
									<Row key={i} className='rd-emergencies-row'>
										<h4>{this.props.lan.incidenceCount + '' + (parseInt(i) + 1)}</h4>
										<span>{this.getEmergencyDate(element)}</span>
										<p>{element.emergencyText}</p>
									</Row>
								))}
						</Row>
					)}
				</Container>
			);
		}
	};
	editAgentModal = () => {
		this.props.fielderModal.setContent(null);
		this.props.fielderModal.closeModal(POP_EDIT_AGENT);
		const dynamicContent = (
			<AgentDetail
				{...this.props}
				showAction={() => {
					this.closeRenderModal();
				}}
				dataAgent={this.state.dataAgent}
				idAgent={this.state.idAgent}
			/>
		);
		this.props.fielderModal.setContent(POP_EDIT_AGENT, dynamicContent);
		this.props.fielderModal.openModal(POP_EDIT_AGENT);
	};
	renderAgentModal = (showModal, titleKey, id, fullWidth = false) => {
		if (!showModal) return null;
		return (
			<Modal 
				id={id}
				title={this.props.lan[titleKey]} 
				icon={<AddAgentIcon sx={{ color: DeepSpaceGrey, width: '24px' }} />} 
				fullWidth={fullWidth}
			/>
		);
	};
	closeRenderModal = () => {
		this.setState({
			showDetailAgent: false
		}, () => {
			this.props.fielderModal.closeAllModals();
		});
	};
	render() {
		return (
			<React.Fragment>
				{this.renderAgentModal(
					this.state.showDetailAgent, 'detailAgentTitle', POP_EDIT_AGENT, true)}
				{this.state.errorMessage && (
					<ErrorMessage
						{...this.props}
						closeErrorMessage={() => this.closePopup()}
						message={this.state.message}
						showErrorMessage={this.state.errorMessage}
						icon={this.state.icon}
					/>
				)}
				<div className='rd-body'>
					{this.state.showDetailTask && (
						<TaskDetails
							{...this.props}
							onClose={() => {
								this.setState({
									showDetailTask: false,
									idTask: null,
								});
							}}
							onCloseModal={(res, res1) => this.closeEditTaskPopup(res, res1)}
							idTasks={[this.state.idTask]}
							canNotEdit={true}
						/>
					)}
					{this.state.showTasksResult && (
						<TaskSearchResultsPopup
							{...this.props}
							showPopup={true}
							search={this.state.idTask}
							setDefaultFalse={() => this.setDefaultFalse()}
							setDefaultFalseModal={(task, position) => this.setDefaultFalseModal(task, position)}
							sourceItems={this.state.items}
						/>
					)}
					{this.state.showEditRouteEndPoint && (
						<EditRouteEndPoint
							{...this.props}
							endPoint={this.state.endLocation}
							onClose={() => {
								this.setState({
									showEditRouteEndPoint: false,
								});
							}}
							onSaveEndPoint={(endPoint) => {
								this.setState({
									endLocation: endPoint,
									showEditRouteEndPoint: false,
								});
							}}
						/>
					)}
					{this.state.showEditRouteStartPoint && (
						<EditRouteEndPoint
							{...this.props}
							endPoint={this.state.startLocation}
							onClose={() => {
								this.setState({
									showEditRouteStartPoint: false,
								});
							}}
							onSaveEndPoint={(startPoint) => {
								this.setState({
									startLocation: startPoint,
									showEditRouteStartPoint: false,
								});
							}}
						/>
					)}
					{this.state.configExpenses && (
						<EditExpenses
							{...this.props}
							route={this.state.route}
							onClose={() => {
								this.setState({
									configExpenses: false,
								});
								this.refresh();
							}}
						/>
					)}
					<this.renderRoute />
				</div>
			</React.Fragment>
		);
	};
}
const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(withSnackbar(RouteDetails));
export function withSnackbar(Component) {
    return function WrappedComponent(props) {
        const fielderMsg = useFielderSnackBar();
        return <Component {...props} fielderMsg={fielderMsg} />;
    }
}