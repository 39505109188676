import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNotificationsRequest, updateSetAsShowNotify } from '@core/api/notification/notification.service';
const request = require('FielderRequestTypes');

// Thunk for getting notifications
export const fetchNotifications = createAsyncThunk(
    'notifications/fetch',
    async (obtainedNotifications, { rejectWithValue }) => {
        try {
            const body = request.getDataToGetNotification(obtainedNotifications, 10);
            return await getNotificationsRequest(body);
        } catch (err) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

// Thunk for update notifications
export const updateNotification = createAsyncThunk(
    'notifications/update',
    async ({ notificationId, onSuccess, onError, loadingConfig }, { rejectWithValue }) => {
        try {
            return await updateSetAsShowNotify(notificationId, onSuccess, onError, loadingConfig);
        } catch (err) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

// Slice
export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        allTask: '',
        totalNotifications: 0,
        loading: false,
        error: null,
        updateLoading: false,
        updateError: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.allTask = action.payload.notification;
                state.totalNotifications = action.payload.countNotShown;
                state.loading = false;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateNotification.fulfilled, (state, action) => {
                state.updateLoading = false;
            })
            .addCase(updateNotification.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            });
    },
});

export default notificationsSlice.reducer;
