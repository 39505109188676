export enum REQUEST_TYPES {
	GET_ACCOUNTS_VIEW = 'GET_ACCOUNTS_VIEW',
	GET_BILLING_INFO_VIEW = 'GET_BILLING_INFO_VIEW',
	GET_COUNT_USERS_BY_ACCOUNT = 'GET_COUNT_USERS_BY_ACCOUNT',
	GET_COUNT_TASK_BY_ACCOUNT = 'GET_COUNT_TASK_BY_ACCOUNT',
	GET_ACCOUNT_DETAIL = 'GET_ACCOUNT_DETAIL',
	UPDATE_ACCOUNT_LOCATION_PREFERENCE = 'UPDATE_ACCOUNT_LOCATION_PREFERENCE',
	UPDATE_ACCOUNT_BILLING_PREFERENCES = 'UPDATE_ACCOUNT_BILLING_PREFERENCES',
	ENABLE_ACCOUNT = 'ENABLE_ACCOUNT',
	DISABLE_ACCOUNT = 'DISABLE_ACCOUNT',
	GET_BILLING_HISTORY = 'GET_BILLING_HISTORY',
	GET_ACCOUNT_LOGIN_LOGS = 'GET_ACCOUNT_LOGIN_LOGS',
	GET_ACCOUNT_ROUTES_LOGS = 'GET_ACCOUNT_ROUTES_LOGS',
	GET_ACCOUNT_AGENTS_LOGS = 'GET_ACCOUNT_AGENTS_LOGS',
	ADD_DOMAIN_TO_ACCOUNT = 'ADD_DOMAIN_TO_ACCOUNT',
	ENABLE_DISABLE_MFA = 'ENABLE_DISABLE_MFA',
	ENABLE_DISABLE_AGENT_MFA ='ENABLE_DISABLE_AGENT_MFA',
	GET_GLOBAL_ACCOUNTS_VIEW = 'GET_GLOBAL_ACCOUNTS_VIEW',
	GET_GLOBAL_ACCOUNT_INFO = 'GET_GLOBAL_ACCOUNT_INFO',
	SEND_EMAIL_TO_GLOBAL_USER = 'SEND_EMAIL_TO_GLOBAL_USER',
	DO_ENABLE_GLOBAL_USER = 'DO_ENABLE_GLOBAL_USER',
	DO_DISABLE_GLOBAL_USER = 'DO_DISABLE_GLOBAL_USER',
	ADD_ACCOUNT_TO_GLOBAL_ACCOUNT = 'ADD_ACCOUNT_TO_GLOBAL_ACCOUNT',
	CREATE_GLOBAL_ACCOUNT = 'CREATE_GLOBAL_ACCOUNT',
	GET_COUNTRIES_CODES = 'GET_COUNTRIES_CODES',
	GET_CLIENT_USER_BY_ID = 'GET_CLIENT_USER_BY_ID',
	ADD_USER_TO_GLOBAL_ACCOUNT = 'ADD_USER_TO_GLOBAL_ACCOUNT',
	GET_GLOBAL_USER_BY_ID = 'GET_GLOBAL_USER_BY_ID',
	DO_UPDATE_GLOBAL_USER = 'DO_UPDATE_GLOBAL_USER',
	DO_UPDATE_MODULE_ACCOUNT = 'DO_UPDATE_MODULE_ACCOUNT',
	SHOW_ALERT_NON_PAYMENT = 'SHOW_ALERT_NON_PAYMENT',
	GET_BILLING_SUMMARY = 'GET_BILLING_SUMMARY'
}