import React, { Component } from 'react';
import YellowAlertIcon from '@assets/icons/alert-icon.svg';
import DangerIcon from '@assets/icons/danger-icon.svg';
import DeleteIcon from '@assets/icons/delete-icon.svg';
import AlertIcon from '@assets/icons/redAlert-icon.svg';
import ResetFilterIcon from '@assets/icons/reset-filter-icon.svg';
import SearchIcon from '@assets/icons/search-icon.svg';
import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import lottie from '@assets/lotties/loadingFielder.json';
import TASK_STATUS from '@core/constants/task/TaskStatus.constants';
import withRouter from '~/withRouter/withRouter.component';
import { getAccountPreferences } from 'FielderUtils/accountUtils';
import { hasAutoAssignFunctionality } from 'FielderUtils/autoassign/autoassign';
import {
	rowUpdateActions,
	rowAddActions,
	rowUpdateActionsTaskOnGroup,
	rowDeleteActions,
	rowDisableActions,
	rowEnableActions,
	agentUpdateActions,
	agentStatusUpdateActions,
	mailCounterUpdateActions,
	imageDeleteActions,
	rowAddActionsTaskGroup,
	rowUpdateActionsTaskGroup,
	rowUpdateStatusActionsTaskGroup,
} from '@core/constants/realTime/pages/myOperation';
import POPUPNAME from 'FielderUtils/popupName/popupName';
import {
	popupValidateName,
	getSmartTable,
	validateTasksInSameTeams,
	validateStartTimeForRoutes,
	validateNoRowSelected,
} from 'FielderUtils/popupValidations/popupValidations';
import { hasGroupTasKFunctionality } from 'FielderUtils/groupTask/groupTaskColumn';
import { exportPopupList } from 'FielderUtils/popupList/popupList';
import groupTaskConst from 'FielderUtils/roleManagementConst/groupTasksConst';
import resourceAllocationConst from 'FielderUtils/roleManagementConst/resourceAllocationConst';
import routeConst from 'FielderUtils/roleManagementConst/routeConst';
import taskConst from 'FielderUtils/roleManagementConst/taskConst';
import { hasMailIntegrationFunctionality } from 'FielderUtils/task/taskFuntionalities';
import pages from '../../client/utils/pages/pages';
import { hasTaskTypeFunctionality, listToTree, getTaskTypeList } from 'FielderUtils/taskType/taskType';
import { Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie';

import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import Tooltip from '@mui/material/Tooltip';
import MyOperationMap from '@pages/MyOperationPage/components/MyOperationMap/MyOperationMap';
import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';
import { isRoleValid } from '../../core/utils/roles/validateRoles';
import { getWSService } from '../../services/WebSocket';
import WS_ROUTE_NAME from '../../services/WebSocketRouteNames';
import AdvancedFilter from '../AdvancedFilter/AdvancedFilter';
import FormAdvancedFilter from '../FormAdvancedFilter/FormAdvancedFilter';
import AgentDetailContainer from '../Agents/AgentDetailContainer';
import DropdownDatesFilter from '../Analitics/DropdownDatesFilter';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';
import PermanenDeleteMessage from '../PermanenDeleteMessage/PermanenDeleteMessage';
import RouteDetails from '../RouteDetails';
import SmartTable from '../SmartTable';
import TaskDetails from '../TaskDetails';
import 'react-toastify/dist/ReactToastify.css';
import AutoAssignTab from '~/AutoAssignTab/AutoAssignTab.component';
import TaskMenu from './components/TaskMenu';
import TaskTab from './components/TaskTab';
import TaskTabForm from './components/TaskTabForm';
import TaskGroupTab from './components/TaskGroupTab';
import AssignAgentPopup from './popups/assign-agent-popup';
import AssignAssetPopup from './popups/assign-asset-popup';
import AssignFormPopup from './popups/assign-form-popup';
import AssignGroupTaskPopup from './popups/assign-group-task-popup';
import AssignInventoryPopup from './popups/assign-inventory-popup';
import AssignPartnerPopup from './popups/assign-partner-popup';
import AssignResourcesPopup from './popups/assign-resources-popup';
import AssignTeamPopup from './popups/assign-team-popup';
import CancelRoutePopup from './popups/cancel-route-popup';
import CancelTaskPopup from './popups/cancel-task-popup';
import CreateGroupTasksPopup from './popups/create-group-tasks-popup';
import DuplicateTaskPopup from './popups/duplicate-task-popup';
import EditGroupTasksPopup from './popups/edit-group-task-popup';
import EditTask from './popups/edittask-popup';
import GenerateRoutePopup from './popups/generate-route-popup';
import TaskGroupActionPopup from './popups/groupTaskAction-popup';
import RateTaskPopup from './popups/rate-task-popup';
import ReAssignPartnerPopup from './popups/reassign-partner-popup';
import ReassignRouteAgentPopup from './popups/reassign-route-agent-popup';
import RescheduleRoutePopup from './popups/reschedule-route-popup';
import RescheduleTaskPopup from './popups/reschedule-task-popup';
import ResetCanceledTasksPopup from './popups/reset-canceled-tasks-popup';
import ResetRouteDateAgentPopup from './popups/reset-route-date-agent-popup';
import ResetRouteTasksPopup from './popups/reset-route-tasks-popup';
import RouteActionPopup from './popups/routeAction-popup';
import TaskActionPopup from './popups/taskAction-popup';
import TaskSwitchHeader from './components/TaskSwitchHeader';
import request from 'FielderRequestTypes';
import moment from 'moment';
import model from 'Object/model/model';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import './MyOperation.css';
import 'react-day-picker/lib/style.css';
import 'FielderUtils/style.css';
import {
	countTasksByMailRequest,
	deleteTaskRequest,
	disableTaskRequest,
	restartTaskRequest,
} from '@core/api/task/task.service';
import { disableTaskGroupRequest } from '@core/api/task/task.group.service';
import { doActionTrashRouteRequest, getRouteListRequest } from '@core/api/route/route.service';
import { AddAgentIcon, DeepSpaceGrey } from '@core/theme/baseTheme';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import { useModal } from '~/FielderElements/ModalContext';
import Modal from '~/FielderElements/Modal';
import { FIELDER } from '@core/constants/base.constants';
import MyOperationDatePicker from './components/MyOperationDatePicker';
import MyOperationNavigationBar from './components/MyOperationNavigationBar';
import MyOperationRangeDatePicker from './components/MyOperationRangeDatePicker';
import MyOperationHeaderTitle from './components/MyOperationHeaderTitle';
import MyOperationResourceAllocationAndGroupTaskIconMenu from './components/MyOperationResourceAllocationAndGroupTaskIconMenu';
import { setRoute, setMapClean } from '@core/store/modules/map/mapSlice';
import { getTaskPriorityByOriginRequest }  from '@core/api/taskPriority/taskPriority.service';
import { getDefaultPriorities } from 'FielderUtils/Constants/TaskPriorityConstants';
import { getPrioritiesAvancedFilter } from '../AdvancedFilter/AvancedFilterUtil';


const { pop: POP_ID } = FIELDER.idElement;
const POP_DETAIL_AGENT = POP_ID.myOperation.replace('{0}', 'detailAgentModal');
//Cookie
var cookieTable = new Cookies();
var cookieName = 'myOperationTaskPreferences';
var cookieUpdateTask = new Cookies();
var cookieUpdateTaskPreference = 'UpdateTaskPreferences';
var cookieUpdateTaskGroup = new Cookies();
var cookieUpdateTaskGroupPreference = 'UpdateTaskGroupPreferences';
var cookieNameAvancedFilter = 'MyOperationTaskTableAvancedFilter';
// SELECTION VARIABLES
const SELECTION_TASK = 1;
const SELECTION_ROUTE = 2;
const SELECTION_TASKGROUP = 3;
const SELECTION_AUTOASSIGN = 4;
var selectedRows = [];
let idTaskButton = [];
let partnerTaskRelationsTaskButton = [];
class MyOperation extends Component {
	constructor(props) {
		super(props);
		this.detailAgentModal = this.detailAgentModal.bind(this);
		this.taskDetailRef = React.createRef();

		let option = SELECTION_TASK;
		if (this.props.location?.state && this.props.location?.state?.routes) {
			option = SELECTION_ROUTE;
		}
		this.state = {
			refreshTable: false,
			showMap: false,
			successRoute: true,
			map: false,
			pop: true,
			options: option,
			showRoutes: false,
			exportableData: [],
			exportableDataHeaders: [],
			datesError: false,
			showDisabledTasks: false,
			showTaskByEmail: false,
			tasks: [],
			popups: exportPopupList(),
			route: {
				elements: [],
			},
			table: {
				elements: [],
			},
			taskGroup: {
				elements: [],
			},
			daypickers: {
				daypickerone: false,
				daypickertwo: false,
			},
			selected: '10',
			sizeTask: 0,
			sizeTaskGroup: 0,
			sizeRoutes: 0,
			page: 0,
			clientLatitude: 20.5532108,
			clientLongitud: -59.8989981,
			noPrev: true,
			noNext: false,
			from: null,
			to: null,
			message: '',
			markers: [],
			stateTable: [],
			offset: 0,
			routes: null,
			routeDate: null,
			confirmationDialog: false,
			confirmationDialogText: '',
			dateTo: new Date(),
			minTime: new Date().setHours(8, 0),
			agentsIds: [],
			scheduleInit: '',
			agentRoute: null,
			dataAgent: {},
			messageCreateGroupTask: this.props.lan.addedCorrectly,
			showErrorMessageIcon: SuccessIcon,
			dangerIcon: DangerIcon,
			YellowAlertIcon: YellowAlertIcon,
			showRouteOpt:  this.props.session.accountPreferences ? this.props.session.accountPreferences.showRouteOpt : false,
			allowManagerToSeeTaskByMail: false,
			showTaskType: false,
			myIdRoutes: [],
			routesIdAgents: [],
			routesIdAgentsSelected: [],
			globalCombo: '',
			useGlobalCombo: false,
			globalComboTask: '',
			useGlobalComboTask: false,
			selectedIndexes: [],
			globalComboTaskGroup: '10',
			useGlobalComboTaskGroup: false,
			listOfErrorDeleteGroupTask: [],
			numberSuccess: 0,
			numberLinked: 0,
			errormessageDelete: false,
			elementName: this.props.lan.groupTask,
			messageElement: this.props.lan.linkedTextAdministratador,
			//USER SESSION
			session: this.props.session,
			idUser: this.props.user.idUser,
			idAccount: this.props.session.user.idAccount,
			taskInfoRequest: null,
			resetFilter: false,
			//Tasktype for filter
			taskTypeFunctionality: hasTaskTypeFunctionality(this.props.session.account.funcionalityPurchases),
			hasMailIntegrationFunctionality: hasMailIntegrationFunctionality(
				this.props.session.account.funcionalityPurchases
			),
			groupTaskFunctionality: hasGroupTasKFunctionality(this.props.session.account.funcionalityPurchases),

			autoassign: {
				hasFunctionality: hasAutoAssignFunctionality(this.props.session.account.funcionalityPurchases),
			},
			anchorEl: null,
			forms: {
				viewMenu: false,
				generateReport: false,
			},
			asset: {
				viewMenu: false,
				generateReport: false,
			},
			inventory: {
				viewMenu: false,
				generateReport: false,
			},
			showTaskActionPopup: false,
			selectedTaskCount: 0,
			showRouteActionPopup: false,
			selectedRouteCount: 0,
			showGroupTaskActionPopup: false,
			selectedGroupTaskCount: 0,
			notificationCount: 0,
			notificationCountGroup: 0,
			showTaskUpdates: false,
			showTaskGroupUpdates: false,
			customActions: [],
			showNoForms: false,
			showNoFormsImages: false,
			//FILTER
			filter: null,
			tagsFilter: null,
			executeLoadMore: false,
			//REFRESH SELECTED
			refreshSelected: false,
			//ROW TO UPDATE
			rowToUpdate: null,
			rowToAdd: null,
			rowToUpdateTaskOnGroup: null,
			rowToDisable: null,
			rowToDelete: null,
			rowToEnable: null,
			//ROW TO UPDATE TASK GROUP
			rowToAddTaskGroup: null,
			rowToUpdateTaskGroup: null,
			idTasks: null,
			showMailCount: true,
			//TASK TYPES
			taskTypes: null,
			taskPriority: getDefaultPriorities(this.props.lan),
			idAuxDetailMakerTaskId: null,
			advancedFilterValues: null,
			advancedFilterFormValues:null,
			showLoading: true,
			canViewTasks: isRoleValid(taskConst.TASKS, taskConst.VIEW, this.props.session.roles),
			canViewRoutes: isRoleValid(routeConst.ROUTES, routeConst.VIEW, this.props.session.roles),
			canViewTaskGroups: isRoleValid(groupTaskConst.GROUPTASK, groupTaskConst.VIEW, this.props.session.roles),
			canGenerateResourceAllocation: isRoleValid(
				resourceAllocationConst.RESOURCEALLOCATION,
				resourceAllocationConst.GENERATE,
				this.props.session.roles
			),
			canCreateGroupTasks: isRoleValid(groupTaskConst.GROUPTASK, groupTaskConst.CREATE, this.props.session.roles),
			disabledDetailButton: false,
			taskGroupFromTable: false,
			disabledGeneralSearch: false,
			showNotTask: false,
			showSearchByForms: false,
			startDate: new Date(),
			endDate: new Date(),
			
		};
		this.smartTable = React.createRef();
	}
	realTimeActionHandler = (message) => {
		const { action } = message;
		if (rowUpdateActions.has(action)) {
			this.setState({ rowToUpdate: message });
		} else if (rowAddActions.has(action)) {
			this.setState({ rowToAdd: message });
		} else if (rowUpdateActionsTaskOnGroup.has(action)) {
			this.setState({ rowToUpdateTaskOnGroup: message });
		} else if (rowDisableActions.has(action)) {
			this.setState({ rowToDisable: message });
		} else if (rowDeleteActions.has(action)) {
			this.setState({ rowToDelete: message });
		} else if (rowEnableActions.has(action)) {
			this.setState({ rowToEnable: message });
		} else if (agentUpdateActions.has(action)) {
			//this.setState({ agentToUpdate: message });
		} else if (agentStatusUpdateActions.has(action)) {
			this.setState({ agentToUpdateStatus: message });
		} else if (mailCounterUpdateActions.has(action)) {
			if (this.state.hasMailIntegrationFunctionality && this.state.options === SELECTION_TASK) {
				this.executeCountTaskByMail();
			}
		} else if (rowAddActionsTaskGroup.has(action) && this.state.options === SELECTION_TASKGROUP) {
			this.setState({ rowToAddTaskGroup: message });
		} else if (rowUpdateActionsTaskGroup.has(action)) {
			this.setState({ rowToUpdateTaskGroup: message });
		} else if (rowUpdateStatusActionsTaskGroup.has(action)) {
			this.setState({ rowToUpdateStatusTaskGroup: message });
		}

		var idTasks = this.getSelectedId(selectedRows);

		if (
			this.state.popups.taskdetail === true &&
			idTasks !== undefined &&
			message.idTask == idTasks[0] &&
			(rowUpdateActions.has(action) ||
				rowDisableActions.has(action) ||
				rowEnableActions.has(action) ||
				rowDeleteActions.has(action) ||
				imageDeleteActions.has(action))
		) {
			this.taskDetailRef.current.realTimeUpdateDetail();
		}
	};
	componentDidMount = () => {
		if (this.props.location.search) {
			let urlParams = new URLSearchParams(this.props.location.search);
			let task = urlParams.get('id');
			this.showDetailTask(task);
		} else {
			scroll.scrollToTop();
			//Real Time Socket
			getWSService(WS_ROUTE_NAME.MY_OPERATION);
			getWSService().addRealTimeListener(this.realTimeActionHandler);

			const idUser = this.props.session.user.idUser;
			const idAccount = this.props.session.user.idAccount;

			getAccountPreferences(this, this.props.session);
			let state = this.state;



			if (
				this.props.session &&
				this.props.session.account &&
				this.props.session.account.preferences &&
				this.props.session.account.preferences.allowManagerToSeeTaskByMail
			) {
				state.allowManagerToSeeTaskByMail = true;
			}

			if (this.state.options === SELECTION_ROUTE) {
				this.getRouteDate();
			}

			if (this.state.hasMailIntegrationFunctionality && this.state.options === SELECTION_TASK) {
				this.executeCountTaskByMail();
			}

			if (cookieUpdateTask.get(cookieUpdateTaskPreference) !== undefined) {
				let showTaskUpdates = JSON.parse(cookieUpdateTask.get(cookieUpdateTaskPreference));
				state.showTaskUpdates = showTaskUpdates;
			}

			if (cookieUpdateTaskGroup.get(cookieUpdateTaskGroupPreference) !== undefined) {
				let showTaskGroupUpdates = JSON.parse(cookieUpdateTaskGroup.get(cookieUpdateTaskGroupPreference));
				state.showTaskGroupUpdates = showTaskGroupUpdates;
			}

			this.setState(state);

			this.getTaskTypeForAdvancedFilters();
			this.getTaskPriorityForAdvancedFilters();
			this.updateSessionInfoWebSocket(idUser, idAccount);

			window.addEventListener('beforeunload', this.componentCleanup);
		}
	};
	componentCleanup = () => {
		this.props.dispatch(setRoute(null));

		if (this.props.session) {
			const idUser = this.props.session.user.idUser;
			const idAccount = this.props.session.user.idAccount;
			getWSService().deleteConnectionUserInfo(WS_ROUTE_NAME.DELETE_CONNECTION, idUser, idAccount, pages.MyOperation);
		}
		
	};

	componentWillUnmount = () => {
		this.componentCleanup();
		window.removeEventListener('beforeunload', this.componentCleanup);
	};
	executeCountTaskByMail = () => {
		const onSuccess = ({ totalTasks }) => {
			if (totalTasks > 0) {
				this.setState({ showMailCount: false });
			} else {
				this.setState({ showMailCount: true });
			}
		};

		countTasksByMailRequest(onSuccess);
	};
	getTaskTypeForAdvancedFilters = async () => {
		if (this.state.taskTypeFunctionality) {
			const { formatTypes } = await getTaskTypeList(true, this.props);
			let types = listToTree(formatTypes);

			let state = this.state;
			state.taskTypes = types;
			this.setState(state);
		}
	};

	getTaskPriorityForAdvancedFilters = async () => {
		
		const onSuccess = (response) => {
			const taskPriorityResponse = response.taskPriority;
			const taskPriorityLabels = getPrioritiesAvancedFilter(taskPriorityResponse, this.props.lan);

			let state = this.state;
			state.taskPriority = taskPriorityLabels;
			this.setState(state);
		}
		
		const onError = (error) => {
			const taskPriorityResponse = getDefaultPriorities(this.props.lan);
			const taskPriorityLabels = getPrioritiesAvancedFilter(taskPriorityResponse, this.props.lan);
			
			let state = this.state;
			state.taskPriority = taskPriorityLabels;
			this.setState(state);
		}
		const body = { idBranch: null, idClient: null };
		getTaskPriorityByOriginRequest(body, onSuccess, onError, null);
	}

	updateSessionInfoWebSocket = (idUser, idAccount) => {
		setTimeout(() => {
			getWSService().updateConnectionUserInfo(WS_ROUTE_NAME.UPDATE_CONNECTION, idUser, idAccount, pages.MyOperation);
		}, 3000);
	};

	changeTasksNormal = (checked) => {
		selectedRows = [];
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			showDisabledTasks: checked,
			showTaskByEmail: checked,
			showSearchByForms: false,
		});
		this.closeInfoToTaskActionPopup();
	};
	changeTasksShowing = (checked) => {
		selectedRows = [];
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			showDisabledTasks: checked,
			showTaskByEmail: false,
			showSearchByForms: false,
		});
		this.closeInfoToTaskActionPopup();
	};

	changeSearchByForms = (checked) => {
		selectedRows = [];
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			showSearchByForms: checked,
			showTaskByEmail: false,
			showDisabledTasks: false,
			numberOfElements: 0,
			to: null,
			from: null
		});
		this.closeInfoToTaskActionPopup();
	};

	setNumberOfElements = (number) => {
		this.setState({
			numberOfElements: number
		});
	};
	

	changeTasksToByEmail = (checked) => {
		selectedRows = [];
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			showTaskByEmail: checked,
			showDisabledTasks: false,
			showSearchByForms: false
		});
		this.closeInfoToTaskActionPopup();
	};


	handleTaskShowUpdates = (checked) => {
		cookieUpdateTask.set(cookieUpdateTaskPreference, checked, { path: '/' });
		selectedRows = [];

		let state = this.state;
		state.showTaskUpdates = checked;
		state.refreshSelected = !this.state.refreshSelected;
		this.setState(state);

		this.closeInfoToTaskActionPopup();
	};
	handleTaskGroupShowUpdates = (checked) => {
		cookieUpdateTaskGroup.set(cookieUpdateTaskGroupPreference, checked, { path: '/' });
		this.setState({ showTaskGroupUpdates: checked });
		selectedRows = [];
		this.setState({
			refreshSelected: !this.state.refreshSelected,
		});
		this.closeInfoToTaskGroupActionPopup();
	};
	getRoutesTable = (routes) => {
		let totalRoutes = [];

		var idAgents = [];
		var myIdRoutes = [];

		if (routes && routes.length > 0) {
			for (var index = 0; index < routes.length; index++) {
				const route = routes[index];
				const emergencies = route.countEmergencies ? route.countEmergencies : this.props.lan.noneEmergencies;

				myIdRoutes.push(routes[index].id);
				idAgents.push(routes[index].agent.idAgent);

				totalRoutes.push({
					id: route.id,
					name: route.name,
					routeStatus: route.status,
					totalTasks: route.countTasks ? route.countTasks : this.props.lan.noneTasks,
					executionFormattedDate: route.executionFormattedDate,
					startedFormattedDate: route.startedFormattedDate,
					finishedFormattedDate: route.finishedFormattedDate,
					emergencies: emergencies,
					agent: route.agent ? route.agent.name : this.props.lan.unassigned,
					totalShipment: route.totalShipment,
				});
			}

			this.setState({ routesIdAgents: idAgents, myIdRoutes: myIdRoutes });
		}
		return totalRoutes;
	};
	getRouteTableWarning = (routes) => {
		var flagConstruction = false;
		var listOfErrorDeleteRoute = this.state.listOfErrorDeleteRoute;
		let totalRoutes = [];

		var idAgents = [];
		var myIdRoutes = [];

		if (routes && routes.length > 0) {
			for (var index = 0; index < routes.length; index++) {
				const route = routes[index];
				const emergencies = route.countEmergencies ? route.countEmergencies : this.props.lan.noneEmergencies;

				myIdRoutes.push(routes[index].id);
				idAgents.push(routes[index].agent.idAgent);

				for (let x = 0; x < listOfErrorDeleteRoute.length; x++) {
					flagConstruction = false;

					if (listOfErrorDeleteRoute[x].idRoute == routes[index].id) {
						totalRoutes.push({
							iconWarning: listOfErrorDeleteRoute[x].statusCode,
							id: route.id,
							name: route.name,
							routeStatus: route.status,
							totalTasks: route.countTasks ? route.countTasks : this.props.lan.noneTasks,
							executionFormattedDate: route.executionFormattedDate,
							startedFormattedDate: route.startedFormattedDate,
							finishedFormattedDate: route.finishedFormattedDate,
							emergencies: emergencies,
							agent: route.agent ? route.agent.name : this.props.lan.unassigned,
							totalShipment: route.totalShipment,
						});
						flagConstruction = true;
					}
					if (flagConstruction) {
						break;
					}
				}

				if (flagConstruction != true) {
					totalRoutes.push({
						iconWarning: 0,
						id: route.id,
						name: route.name,
						routeStatus: route.status,
						totalTasks: route.countTasks ? route.countTasks : this.props.lan.noneTasks,
						executionFormattedDate: route.executionFormattedDate,
						startedFormattedDate: route.startedFormattedDate,
						finishedFormattedDate: route.finishedFormattedDate,
						emergencies: emergencies,
						agent: route.agent ? route.agent.name : this.props.lan.unassigned,
						totalShipment: route.totalShipment,
					});
				}
			}

			this.setState({ routesIdAgents: idAgents, myIdRoutes: myIdRoutes });
		}
		return totalRoutes;
	};
	showDetailTask = (id) => {
		let state = this.state;
		state.idAuxDetailMakerTaskId = id;
		state.stateTable.selectedRows = [{ idTask: id }];
		state.popups.taskdetail = true;
		this.setState(state);
	};
	showDetailAgent = (dataAgent) => {
		if (dataAgent) {
			this.setState({ dataAgent: dataAgent }, () => {
				this.detailAgentModal();
			});
		}
	};
	setRouteData = (limit, offset, labelSelect, from, to, filter) => {
		let markers = [];

		if (this.state.useGlobalCombo) {
			var combo = this.state.globalCombo;
			var selected = combo;
		} else {
			var combo = document.getElementById('pagination');

			if (combo != null) {
				var selected = combo.options[combo.selectedIndex].text;
			}
		}

		this.setState({
			route: {
				elements: [],
			},
			table: {
				elements: [],
			},
		});

		if (to != null) {
			var dataViewTask = model.getViewRoute(
				offset,
				limit,
				from.getDate(),
				from.getMonth(),
				from.getFullYear(),
				to.getDate(),
				to.getMonth(),
				to.getFullYear(),
				filter
			);
		} else {
			var toDate = moment().format('YYYY-MM-DD HH:mm');
			var fromDate = moment(toDate).subtract(1, 'month');
			var dataViewTask = model.getViewRoute(
				offset,
				limit,
				fromDate.date(),
				fromDate.month(),
				fromDate.year(),
				31,
				12,
				2099,
				filter
			);
		}

		const onSuccess = (response) => {
			let info = response;
			const routes = this.getRoutesTable(info.routes);

			let state = this.state;

			if (info.count <= 0) {
				state.globalCombo = selected;
				state.useGlobalCombo = true;
			} else {
				state.useGlobalCombo = false;
			}

			state.route = { elements: routes };
			state.table = { elements: [] };
			state.selected = labelSelect;
			state.sizeTask = 0;
			state.sizeRoutes = info.count;
			state.tasks = info.tasks;
			state.from = from;
			state.to = to;
			state.markers = markers;

			this.setState(state);
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		getRouteListRequest(dataViewTask, onSuccess, null, loadingConfig);
	};
	setRouteDataWithWarnings = (limit, offset, labelSelect, from, to, filter) => {
		let markers = [];
		let combo;
		let selected;

		if (this.state.useGlobalCombo) {
			combo = this.state.globalCombo;
			selected = combo;
		} else {
			combo = document.getElementById('pagination');

			if (combo != null) {
				selected = combo.options[combo.selectedIndex].text;
			}
		}

		this.setState({
			route: {
				elements: [],
			},
			table: {
				elements: [],
			},
		});

		if (to != null) {
			var dataViewTask = model.getViewRoute(
				offset,
				limit,
				from.getDate(),
				from.getMonth(),
				from.getFullYear(),
				to.getDate(),
				to.getMonth(),
				to.getFullYear(),
				filter
			);
		} else {
			let toDate = moment().format('YYYY-MM-DD HH:mm');
			let fromDate = moment(toDate).subtract(1, 'month');
			var dataViewTask = model.getViewRoute(
				offset,
				limit,
				fromDate.date(),
				fromDate.month(),
				fromDate.year(),
				31,
				12,
				2099,
				filter
			);
		}

		const onSuccess = (response) => {
			let info = response;
			const routes = this.getRouteTableWarning(info.routes);
			let state = this.state;

			if (info.count <= 0) {
				state.globalCombo = selected;
				state.useGlobalCombo = true;
			} else {
				state.useGlobalCombo = false;
			}

			state.route = { elements: routes };
			state.table = { elements: [] };
			state.selected = labelSelect;
			state.sizeTask = 0;
			state.sizeRoutes = info.count;
			state.from = from;
			state.to = to;
			state.tasks = info.tasks;
			state.markers = markers;

			this.setState(state);
		};

		const loadingConfig = { dispatch: this.props.dispatch, config: { allowTopBar: true } };
		getRouteListRequest(dataViewTask, onSuccess, null, loadingConfig);
	};
	getRouteDate = () => {
		this.setRouteData(10, 0, '10', this.state.from, this.state.to, this.state.filter);
	};
	updateTable = () => {
		if (!this.smartTable.current) return;

		var stateTable = this.smartTable.current.getState();
		stateTable.selectedRows = [];
		stateTable.selectedIndexes = [];
		var combo = document.getElementById('pagination');
		var selected = combo.options[combo.selectedIndex].text;

		if (this.state.options === SELECTION_ROUTE) {
			this.setRouteData(
				parseInt(selected),
				this.state.offset,
				selected,
				this.state.from,
				this.state.to,
				this.state.filter
			);
		}
		this.setState({ showTaskActionPopup: false, showGroupTaskActionPopup: false });
	};
	setRouteLimit = () => {
		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
		});
		var combo = document.getElementById('pagination');
		var selected = combo.options[combo.selectedIndex].text;
		var posibleselected = 0;
		if (combo.selectedIndex - 1 >= 0) posibleselected = combo.options[combo.selectedIndex - 1].text;

		if (parseInt(selected) < this.state.sizeRoutes || parseInt(posibleselected) < this.state.sizeRoutes) {
			this.setRouteData(parseInt(selected), 0, selected, this.state.from, this.state.to, '');
			this.setState({
				page: 0,
			});
		}
	};

	handleFromDate = (date) => {

		this.setState({ from: date });

		if (this.state.to && date && this.state.to.getTime() >= date.getTime()) {
			this.setState({ from: date });
		} else if (this.state.to != null) {
			if (this.state.to && date && this.state.to.getTime() <= date.getTime()) {
				this.setState({
					message: this.props.lan['messagePrevious'],
					datesError: true,
					to: null,
				});
				this.changePopupState(POPUPNAME.ERROR_MESSAGE);
			}
		}
	};

	handleToDate = (date) => {
		this.setState({ to: date });

		if (date && this.state.from && date.getTime() >= this.state.from.getTime()) {
			if (this.state.options == SELECTION_ROUTE) {
				var combo;
				var selected;

				if (this.state.useGlobalCombo) {
					combo = this.state.globalCombo;
					selected = combo;
				} else {
					combo = document.getElementById('pagination');
					if (combo != null) {
						selected = combo.options[combo.selectedIndex].text;
					}
				}
				this.setRouteData(parseInt(selected), 0, selected, this.state.from, date, this.state.filter);

				this.setState({ showLoading: false });
			} else if (this.state.options == SELECTION_TASK || this.state.options == SELECTION_TASKGROUP) {

				let filter = null;
				let tagsFilter = null;

				if (this.state.options != SELECTION_TASK) {
					let input = document.getElementById('filter');

					if (input.value !== '') {
						filter = input.value.trim();

						const tasksFilter = this.getFilterTaskTab(filter);

						if (tasksFilter) {
							filter = tasksFilter.filter;
							tagsFilter = tasksFilter.tags;
						}
					}
				}

				selectedRows = [];
				this.closeInfoToTaskActionPopup();
				this.setState({
					filter: filter,
					tagsFilter: tagsFilter,
					executeLoadMore: !this.state.executeLoadMore,
					refreshSelected: !this.state.refreshSelected,
					showLoading: true,
				});
			}

			var noNext = false;

			this.setState({
				page: 0,
				noPrev: true,
				noNext: noNext,
				datesError: false,
				showLoading: false,
			});
		} else if (this.state.from && date && this.state.from && date.getTime() <= this.state.from.getTime()) {
			this.setState({
				datesError: true,
				to: null,
			});
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan['messagePrevious'],
				variant: 'warning', closeButton: true, duration: 7000});
		}
	};

	handleFromDateFromForm = (date) => {
		const paserDate = date.startOf('day');
		this.setState({ from: paserDate.toDate() });
	}

	handleToDateFromForm = (date) => {
		const paserDate = date.endOf('day');
		this.setState({ to: paserDate.toDate(), showLoading: true });
	}


	handleResetFilter = () => {
		if (this.state.options != SELECTION_TASK) {
			document.getElementById('filter').value = '';
		}
		this.setState({ filter: null });
		this.handleFilter();
	};
	handleFilter = () => {
		if (
			this.state.table.elements.length > 0 &&
			this.state.options != SELECTION_TASK &&
			this.state.options != SELECTION_TASKGROUP
		) {
			var stateTable = this.smartTable.current.getState();
			stateTable.selectedRows = [];
			stateTable.selectedIndexes = [];
		}

		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
		});

		if (this.state.to != null && this.state.from == null) {
			this.setState({
				datesError: true,
			});
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan['messageTo'],
				variant: 'error', closeButton: true, duration: 5000});
		} else if (this.state.to == null && this.state.from != null) {
			this.setState({
				datesError: true,
			});
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan['messageForm'],
				variant: 'error', closeButton: true, duration: 5000});
		} else {
			this.setState({ datesError: false });

			let filter = null;
			let tagsFilter = null;

			if (this.state.options != SELECTION_TASK) {
				let input = document.getElementById('filter');

				if (input.value !== '') {
					filter = input.value.trim();

					const tasksFilter = this.getFilterTaskTab(filter);

					if (tasksFilter) {
						filter = tasksFilter.filter;
						tagsFilter = tasksFilter.tags;
					}
				}
			}

			selectedRows = [];
			if (this.state.options != SELECTION_TASK) {
				this.closeInfoToTaskActionPopup();
			} else if (this.state.options != SELECTION_TASKGROUP) {
				this.closeInfoToTaskGroupActionPopup();
			}

			this.setState({
				filter: filter,
				tagsFilter: tagsFilter,
				executeLoadMore: !this.state.executeLoadMore,
				refreshSelected: !this.state.refreshSelected,
				showLoading: true,
			});

			if (this.state.options == SELECTION_ROUTE) {
				if (this.state.useGlobalCombo) {
					var combo = this.state.globalCombo;
					var selected = combo;
				} else {
					var combo = document.getElementById('pagination');
					if (combo != null) {
						var selected = combo.options[combo.selectedIndex].text;
					}
				}
				this.setRouteData(parseInt(selected), 0, selected, this.state.from, this.state.to, filter);
			}

			this.setState({ page: 0 });
		}
	};
	onClickPrev = () => {
		var stateTable = this.smartTable.current.getState();
		stateTable.selectedRows = [];
		stateTable.selectedIndexes = [];

		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
		});
		var combo = document.getElementById('pagination');
		var selected = combo.options[combo.selectedIndex].text;
		var noPrev = false;
		if (this.state.page > 0) {
			var newpage = this.state.page - 1;
			if (this.state.options == SELECTION_ROUTE) {
				this.setRouteData(
					parseInt(selected),
					newpage * parseInt(selected),
					selected,
					this.state.from,
					this.state.to,
					this.state.filter
				);
			}
			if (this.state.page === 1) {
				noPrev = true;
			}

			this.setState({
				page: newpage,
				noPrev: noPrev,
				noNext: false,
				offset: newpage * parseInt(selected),
			});
		}
	};
	onClickNext = () => {
		var stateTable = this.smartTable.current.getState();
		stateTable.selectedRows = [];
		stateTable.selectedIndexes = [];

		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
		});
		var combo = document.getElementById('pagination');
		var selected = combo.options[combo.selectedIndex].text;
		var newpage = this.state.page + 1;
		var noNext = false;

		let size = 0;

		if (this.state.options == SELECTION_ROUTE) {
			size = this.state.sizeRoutes;
		} else if (this.state.options == SELECTION_TASK) {
			size = this.state.sizeTask;
		} else if (this.state.options == SELECTION_TASKGROUP) {
			size = this.state.sizeTaskGroup;
		}

		if (newpage * parseInt(selected) < size) {
			if (this.state.options == SELECTION_ROUTE) {
				this.setRouteData(
					parseInt(selected),
					newpage * parseInt(selected),
					selected,
					this.state.from,
					this.state.to,
					this.state.filter
				);
			}

			if ((newpage + 1) * parseInt(selected) > size) {
				noNext = true;
			}

			this.setState({
				page: newpage,
				noNext: noNext,
				noPrev: false,
				offset: newpage * parseInt(selected),
			});
		}
	};
	validateReAssignPartner = (rows) => {
		let isValid = true;
		let message = this.props.lan.messageErrorReAssignPartnerNoPartner;
		if (rows && rows.length > 0) {
			for (let index = 0; index < rows.length; index++) {
				const row = rows[index];
				const relations = row.partnerTaskRelations;
				if (!relations || relations.length <= 0) {
					isValid = false;
					message = this.props.lan.messageErrorReAssignPartnerNoPartner;
					break;
				}

				if (
					row.status == TASK_STATUS.START_ROUTE ||
					row.status == TASK_STATUS.ARRIVED ||
					row.status == TASK_STATUS.EXECUTING ||
					row.status == TASK_STATUS.FINISH
				) {
					isValid = false;
					message = this.props.lan.messageErrorReAssignPartner;
					break;
				}
				if (relations) {
					const lastIndex = relations.length - 1;
					var lastPartnerId = relations[lastIndex].idAccountPartner;

					var userLoggedIdAccount = this.state.session.user.idAccount;

					if (lastPartnerId === userLoggedIdAccount) {
						isValid = false;
						message = this.props.lan.messageErrorReAssignPartnerExecutor;
						break;
					}
				}
			}
		}
		return { isValid: isValid, message: message };
	};
	onRowClickButtonAction = (popupName, row) => {
		idTaskButton = row.taskId;
		partnerTaskRelationsTaskButton = row.partnerTaskRelations;
		selectedRows = [row];

		this.changePopupState(popupName);
	};
	validateGenerateRouteStatus = (status) => {
		switch (status) {
			case TASK_STATUS.MANAGING:
			case TASK_STATUS.WAITING_FOR_AGENT:
			case TASK_STATUS.ASSIGNED_TO_PARTNER:
			case TASK_STATUS.ASSIGNED_TO_TEAM:
			case TASK_STATUS.AUTO_ASSIGNED_FAILED:
				return true;
			default:
				return false;
		}
	};
	validateTasksForRoutes = (rows) => {
		let firstDate;

		if (rows.length <= 1) {
			return { tasks: true };
		}

		if (rows.length > 0) {
			firstDate = moment(rows[0].startTime);
			firstDate.tz(rows[0].location.timeZone);
		}

		for (let i = 0; i < rows.length; i++) {
			let date = moment(rows[i].startTime);
			date.tz(rows[i].location.timeZone);

			if (!firstDate.isSame(date, 'day')) {
				return { date: true };
			} else if (!this.validateGenerateRouteStatus(rows[i].status)) {
				return { status: true };
			} else if (rows[i].taskIcons.route) {
				return { route: true };
			} else if (rows[i].group) {
				return { group: true };
			}
		}

		this.setState({ routeDate: firstDate });
		return null;
	};
	changePopupState = (popupName) => {
		let errorPopUp = false;
		this.handleCloseTaskDetails();
		if (popupValidateName(popupName)) {
			let stateTable = getSmartTable(
				popupName,
				this.state.options,
				this.state.smartTable,
				selectedRows,
				SELECTION_TASK
			);
			if (validateNoRowSelected(stateTable, popupName)) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan['messageEmptyTasks'],
					variant: 'warning', closeButton: true, duration: 7000});
			} else if (popupName === POPUPNAME.DUPLICATE_TASK) {
				if (stateTable.selectedRows.length > 20) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messagDuplicateTasksLimitError,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					var error = false;
					const taskBranch = stateTable.selectedRows[0].branch;
					const taskUser = stateTable.selectedRows[0].taskUser;

					for (const element of stateTable.selectedRows) {

						const task = element;
						
						/*if (task.partnerTaskRelations && task.partnerTaskRelations.length >0) {
							let partnerTaskRelations = task.partnerTaskRelations;
							let partnerSize = task.partnerTaskRelations.length;
							let lastOwnerId = partnerTaskRelations[partnerSize-1].idAccountOwner;
							let userLoggedIdAccount = this.state.session.user.idAccount;

							if (lastOwnerId != userLoggedIdAccount) {
								errorPopUp = true;
								this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageYouCanOnlyDuplicateOwnTasks,
								variant: 'warning', closeButton: true, duration: 7000});
								error = true;
								break;
							}
						}*/



						if (task.status != TASK_STATUS.FINISH && task.status != TASK_STATUS.PAUSED) {
              				errorPopUp = true;
              				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageDuplicateTasksStatusError,
                			variant: 'warning', closeButton: true, duration: 7000});
							error = true;
							break;
						}

						if (task.branch !== taskBranch || task.taskUser !== taskUser) {
							error = true;
             				errorPopUp = true;
             				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageDuplicateTasksBranchClientError,
               				variant: 'warning', closeButton: true, duration: 7000});
							break;
						}
					}

					if (!error) {
						this.state.scheduleInit = stateTable.selectedRows[0].startWay;
						this.state.stateTable = stateTable;
					}
				}
			} else if (popupName === POPUPNAME.PARENT_TASK_DETAIL) {
				if (stateTable.selectedRows.length > 1) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messagMoreTasksSelected,
							variant: 'warning', closeButton: true, duration: 7000});
				} else {
					var error = false;
					const parentTask = stateTable.selectedRows[0].parentTask;

					for (let i = 0; i < stateTable.selectedRows.length; i++) {
						const task = stateTable.selectedRows[i];
						if (task.parentTask === 0 || parentTask != task.parentTask) {
              errorPopUp = true;
              this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messagParentTasksError,
                variant: 'warning', closeButton: true, duration: 7000});
							error = true;
							break;
						}
					}

					if (!error) {
						this.state.scheduleInit = stateTable.selectedRows[0].startWay;
						this.state.stateTable = stateTable;
					}
				}
			} else if (stateTable.selectedRows.length > 1 && popupName === POPUPNAME.TASK_DETAIL) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messagMoreTasksSelected,
					variant: 'warning', closeButton: true, duration: 7000});
			} else if (stateTable.selectedRows.length > 1 && popupName === POPUPNAME.RATE_TASK) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messagMoreTasksSelected,
					variant: 'warning', closeButton: true, duration: 7000});
			} else if (stateTable.selectedRows.length == 1 && popupName === POPUPNAME.RATE_TASK) {
				this.state.stateTable = stateTable;
				var isValid = true;
				var alreadyRated = false;

				if (stateTable.selectedRows[0].status != TASK_STATUS.FINISH) {
					isValid = false;
				} else {
					if (stateTable.selectedRows[0].scoreValidation == true) {
						alreadyRated = true;
					} else {
						alreadyRated = false;
					}
				}

				if (!isValid) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageRateFinishedTaskOnly,
						variant: 'warning', closeButton: true, duration: 7000});
				}

				if (alreadyRated) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageRatedAlready,
						variant: 'warning', closeButton: true, duration: 7000});
				}
			} else if (popupName === POPUPNAME.EDIT_GROUP_TASK) {
				if (selectedRows.length > 1) {
          errorPopUp = true;
          this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messagMoreTasksSelected,
            variant: 'warning', closeButton: true, duration: 7000});
				} else {

					if (!this.state.taskGroupFromTable && !selectedRows[0].group) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messagNoGroup,
              variant: 'warning', closeButton: true, duration: 7000});
					}
				}
			} else if (popupName === POPUPNAME.ASSIGN_TEAM) {
				this.state.stateTable = stateTable;
				var validTask = true;

				for (var i = 0; i < selectedRows.length; i++) {
					const row = stateTable.selectedRows[i];
					const partnerTaskRelations = row.partnerTaskRelations;
					const partnerSize = partnerTaskRelations.length;

					if (row.group) {
						errorPopUp = true;
						this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorRescheduleGpoTask,
						variant: 'warning', closeButton: true, duration: 7000});
						break;
					}

					if (
						row.status == TASK_STATUS.START_ROUTE ||
						row.status == TASK_STATUS.ARRIVED ||
						row.status == TASK_STATUS.EXECUTING ||
						row.status == TASK_STATUS.FINISH ||
						row.restrictAssign === true ||
						(row.status == TASK_STATUS.IN_PROGRESS && partnerSize > 0 && row.restrictAssign === true)
					) {
						validTask = false;
						break;
					}

					if (partnerSize > 0) {
						let userLoggedIdAccount = this.state.session.user.idAccount;

						if (row.status == TASK_STATUS.CANCEL_BY_PARTNER) {
							let lastOwnerId = partnerTaskRelations[partnerSize-1].idAccountOwner;

							if (lastOwnerId != userLoggedIdAccount) {
								validTask = false;
								break;
							}
						}

						if (row.status == TASK_STATUS.ASSIGNED_TO_PARTNER) {
							let lastPartnerId = partnerTaskRelations[partnerSize-1].idAccountPartner;

							if (lastPartnerId != userLoggedIdAccount && userLoggedIdAccount != row.accountId ) {
								validTask = false;
								break;
							}
						}
					}

				}

				if (!validTask) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorAssignTeamPopUp,
						variant: 'warning', closeButton: true, duration: 7000});
				}
			} else if (popupName === POPUPNAME.CANCEL_TASK && stateTable.selectedRows.length >= 1) {
				this.state.stateTable = stateTable;
				var tasksToAssign = selectedRows;
				var taskInfoRequest = [];

				const rows = this.state.stateTable.selectedRows;

				for (var i = 0; i < rows.length; i++) {
					const task = rows[i];
					var taskId = task.taskId;

					if (task.group) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorRescheduleGpoTask,
              variant: 'warning', closeButton: true, duration: 7000});
						break;
					}

					if (
						task.status == TASK_STATUS.FINISH ||
						task.status == TASK_STATUS.CANCEL ||
						task.status == TASK_STATUS.CANCEL_BY_PARTNER
					) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorCancelTaksOwner,
              variant: 'warning', closeButton: true, duration: 7000});
						break;
					}

				
					if (task.partnerTaskRelations.length > 0) {
						let partnerTaskRelations = task.partnerTaskRelations;
						let lastPartnerId = partnerTaskRelations[partnerTaskRelations.length - 1].idAccountPartner;
						let userLoggedIdAccount = this.state.session.user.idAccount;
						const isLastPartner = lastPartnerId !== userLoggedIdAccount;

						taskInfoRequest.push({
							idTask: taskId,
							typeOfRequest: isLastPartner ? 'DO_ACTION_CANCEL_PARTNER_TASK' : 'DO_ACTION_CANCEL_TASK',
						});
					} else {
						taskInfoRequest.push({
							idTask: taskId,
							typeOfRequest: 'DO_ACTION_CANCEL_TASK',
						});
					}
				}

				this.setState({ taskInfoRequest });
			} else if (popupName === POPUPNAME.CREATE_GROUP_TASK || popupName === POPUPNAME.ASSIGN_GROUP_TASK) {
				this.state.stateTable = stateTable;

				for (var i = 0; i < selectedRows.length; i++) {
					const task = selectedRows[i];

					if (task.group) {
						errorPopUp = true;
						this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorGroupTaskWitTask,
						variant: 'warning', closeButton: true, duration: 7000});
									break;
					}

					if (
						task.status === TASK_STATUS.START_ROUTE ||
						task.status === TASK_STATUS.ARRIVED ||
						task.status === TASK_STATUS.EXECUTING ||
						task.status === TASK_STATUS.FINISH
					) {
						errorPopUp = true;
						this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorGroupTaskStatus,
						variant: 'warning', closeButton: true, duration: 7000});
									break;
					}

					if (
						task.partnerTaskRelations.length &&
						(task.status === TASK_STATUS.CANCEL_BY_PARTNER ||
							task.status === TASK_STATUS.PAUSED ||
							task.status == TASK_STATUS.CANCEL)
					) {
						const partnerTaskRelations = task.partnerTaskRelations;
						const size = partnerTaskRelations.length - 1;
						const lastOwnerId = partnerTaskRelations[size].idAccountOwner;
						const userLoggedIdAccount = this.state.session.user.idAccount;

						if (lastOwnerId != userLoggedIdAccount) {
							errorPopUp = true;
							this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorGroupTaskParner,
								variant: 'warning', closeButton: true, duration: 7000});
							break;
						}
					}
				}
			} else if (popupName === POPUPNAME.ASSIGN_ASSETS) {
				this.state.stateTable = stateTable;
				if (!this.handleAssignAsset(stateTable)) {
					errorPopUp = true;
				}
			} else if (popupName === POPUPNAME.ASSIGN_FORMS) {
				this.state.stateTable = stateTable;

				for (let i=0; i < selectedRows.length; i++) {
					const task = selectedRows[i];
					if (task.status == TASK_STATUS.CANCEL || task.status == TASK_STATUS.FINISH) {
						errorPopUp = true;
						this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorAssignFormWrongStatus,
						variant: 'warning', closeButton: true, duration: 7000});
					}
				}

			} else if (popupName === POPUPNAME.ASSIGN_INVENTORIES) {
				this.state.stateTable = stateTable;

				for (var i = 0; i < selectedRows.length; i++) {
					const task = selectedRows[i];

					if (task.status == TASK_STATUS.CANCEL || task.status == TASK_STATUS.FINISH) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorAssignInventoryWrongStatus,
              variant: 'warning', closeButton: true, duration: 7000});
						break;
					} else if (task.partnerTaskRelations.length) {
						const partnerTaskRelations = task.partnerTaskRelations;
						const size = partnerTaskRelations.length - 1;
						const lastOwnerId = partnerTaskRelations[size].idAccountOwner;
						const userLoggedIdAccount = this.state.session.user.idAccount;

						if (lastOwnerId != userLoggedIdAccount) {
              errorPopUp = true;
              this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorAssignInventaryPartner,
                variant: 'warning', closeButton: true, duration: 7000});
							break;
						}
					}
				}
			} else if (popupName === POPUPNAME.ASSIGN_PARTNER) {
				this.state.stateTable = stateTable;
				for (var i = 0; i < selectedRows.length; i++) {
					const task = selectedRows[i];

					if (task.group) {
						errorPopUp = true;
						this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorRescheduleGpoTask,
						variant: 'warning', closeButton: true, duration: 7000});
						break;
					}

					if (task.restrictAssign === true) {
						errorPopUp = true;
						this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorUserReasingPartnerCancelClient,
						variant: 'warning', closeButton: true, duration: 7000});
						break;
					}

					if (
						task.status === TASK_STATUS.IN_PROGRESS ||
						task.status === TASK_STATUS.START_ROUTE ||
						task.status === TASK_STATUS.ARRIVED ||
						task.status === TASK_STATUS.EXECUTING ||
						task.status === TASK_STATUS.FINISH ||
						task.status === TASK_STATUS.CANCEL_BY_PARTNER
					) {
						errorPopUp = true;
						this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorUserReasingPartner,
						variant: 'warning', closeButton: true, duration: 7000});
						break;
					}

					if (task.status == TASK_STATUS.ASSIGNED_TO_PARTNER && task.partnerTaskRelations.length) {
						const userLoggedIdAccount = this.state.session.user.idAccount;
						const partnerTaskRelations = task.partnerTaskRelations;
						const partnerSize = task.partnerTaskRelations.length;
						const lastPartnerId = partnerTaskRelations[partnerSize - 1].idAccountPartner;

						if (lastPartnerId !== userLoggedIdAccount) {
							errorPopUp = true;
							this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorAssignPartner,
							variant: 'warning', closeButton: true, duration: 7000});
							break;
						}
					}
				}
			} else if (popupName === POPUPNAME.RE_ASSIGN_PARTNER) {
				this.state.stateTable = stateTable;
				var validTask = true;

				const result = this.validateReAssignPartner(selectedRows);
				if (!result.isValid) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: result.message,
						variant: 'warning', closeButton: true, duration: 7000});
				}
			} else if (popupName === POPUPNAME.ASSIGN_AGENT) {
				var validTask = true;
				var validLoggedUser = true;
				var isGroupTask = false;
				for (var i = 0; i < selectedRows.length; i++) {
					const task = selectedRows[i];
					if (task.group) {
						isGroupTask = true;
						break;
					}
					if (task.status == TASK_STATUS.AUTO_ASSIGNING) {
						validTask = false;
						break;
					}
					if ((task.status == TASK_STATUS.IN_PROGRESS &&
							task.partnerTaskRelations.length === 0 &&
							task.restrictAssign == true) ||
						task.restrictAssign == true ||
						task.status == TASK_STATUS.EXECUTING ||
						task.status == TASK_STATUS.START_ROUTE ||
						task.status == TASK_STATUS.ARRIVED ||
						task.status == TASK_STATUS.FINISH
					) {
						validTask = false;
						break;
					}
					if (task.partnerTaskRelations.length) {
						const  partnerTaskRelations = task.partnerTaskRelations;
						const size = partnerTaskRelations.length - 1;
						if (task.status == TASK_STATUS.CANCEL_BY_PARTNER) {
							const lastOwnerId = partnerTaskRelations[size].idAccountOwner;
							const userLoggedIdAccount = this.state.session.user.idAccount;
							if (lastOwnerId != userLoggedIdAccount) {
								validTask = false;
								break;
							}
						}
					
						if (task.status == TASK_STATUS.ASSIGNED_TO_PARTNER) {
							const lastPartnerId = partnerTaskRelations[size].idAccountPartner;
							const userLoggedIdAccount = this.state.session.user.idAccount;
							if (lastPartnerId != userLoggedIdAccount && userLoggedIdAccount != task.accountId) {
								validTask = false;
								validLoggedUser = false;
								break;
							}
						}
					}
				}
				let state = this.state;
				if (isGroupTask) {
					errorPopUp = true;
					this.props.fielderMsg({
						title: this.props.lan.myOperation, 
						message: this.props.lan.messageErrorRescheduleGpoTask,
						variant: 'warning', closeButton: true, duration: 7000});
				} else if (!validTask && validLoggedUser) {
					errorPopUp = true;
					this.props.fielderMsg({
						title: this.props.lan.myOperation, 
						message: this.props.lan.messageAutoassignError,
						variant: 'warning', closeButton: true, duration: 7000});
				} else if (!validTask && !validLoggedUser) {
					errorPopUp = true;
					this.props.fielderMsg({
						title: this.props.lan.myOperation, 
						message: this.props.lan.messageAssignAgentPartnersError,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					state.stateTable = stateTable;
				}
				this.setState(state);
			} else if (popupName === POPUPNAME.GENERATE_ROUTE) {
				let validateRoute = this.validateTasksForRoutes(selectedRows);
				let validateTeams = validateTasksInSameTeams(selectedRows);
				let validateStartTime = validateStartTimeForRoutes(selectedRows);

				if (validateRoute !== null) {
					if (validateRoute.date) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorTasksForRouteDate,
              variant: 'warning', closeButton: true, duration: 7000});
					} else if (validateRoute.status) {
						let errorMsg = `${this.props.lan.errorTasksForRouteStatus} (${this.props.lan.taskStatus_0} ${this.props.lan.or} ${this.props.lan.taskStatus_3} ${this.props.lan.or} ${this.props.lan.taskStatus_8} ${this.props.lan.or} ${this.props.lan.taskStatus_9})`;
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: errorMsg,
              variant: 'warning', closeButton: true, duration: 7000});
					} else if (validateRoute.route) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorTasksForRouteRoute,
              variant: 'warning', closeButton: true, duration: 7000});
					} else if (validateRoute.group) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorTasksForRouteGroup,
              variant: 'warning', closeButton: true, duration: 7000});
					} else if (validateRoute.tasks) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageNoMoreThanTwoTaskRoutes,
              variant: 'warning', closeButton: true, duration: 7000});
					}
				}

				if (!validateTeams) {
          errorPopUp = true;
          this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageIdTeams,
            variant: 'warning', closeButton: true, duration: 7000});
				}

				if (!validateStartTime) {
          errorPopUp = true;
          this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageNullStartTime,
            variant: 'warning', closeButton: true, duration: 7000});
				}
			} else if (popupName === POPUPNAME.RESCHEDULE_TASK) {
				this.setState({
					showRoutes: false,
					options: SELECTION_TASK,
				});

				for (var i = 0; i < selectedRows.length; i++) {
					if (selectedRows[i].group) {
            errorPopUp = true;
            this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorRescheduleGpoTask,
              variant: 'warning', closeButton: true, duration: 7000});
					} else if (
						selectedRows[i].status == TASK_STATUS.START_ROUTE ||
						selectedRows[i].status == TASK_STATUS.ARRIVED ||
						selectedRows[i].status == TASK_STATUS.EXECUTING ||
						selectedRows[i].status == TASK_STATUS.FINISH ||
						selectedRows[i].status == TASK_STATUS.CANCEL ||
						selectedRows[i].status == TASK_STATUS.CANCEL_BY_PARTNER
					) {
						if (this.props.session.user.role && this.props.session.user.role == 'Fielder Client User') {
              errorPopUp = true;
              this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorRescheduleClient,
                variant: 'warning', closeButton: true, duration: 7000});
						} else {
              errorPopUp = true;
              this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorReschedule,
                variant: 'warning', closeButton: true, duration: 7000});
						}
						break;
					}
				}

				if (stateTable.selectedRows.length > 0) {
					this.state.scheduleInit = stateTable.selectedRows[0].startTime;
					this.state.stateTable = stateTable;
				}
			} else {
				this.state.stateTable = stateTable;
			}
		} else if (popupName === POPUPNAME.CANCEL_ROUTE) {
			var stateTable = this.smartTable.current.getState();
			if (stateTable.selectedRows.length == 0) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyRoutes,
					variant: 'warning', closeButton: true, duration: 7000});
			} else {
				var validRoute = true;
				for (var i = 0; i < stateTable.selectedRows.length; i++) {
					if (
						stateTable.selectedRows[i].routeStatus != 0 &&
						stateTable.selectedRows[i].routeStatus != 1 &&
						stateTable.selectedRows[i].routeStatus != 2 &&
						stateTable.selectedRows[i].routeStatus != 6
					) {
						validRoute = false;
						break;
					}
				}
				if (!validRoute) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageCancelRoutes,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					this.state.scheduleInit = stateTable.selectedRows[0].startWay;
					this.state.stateTable = stateTable;
				}
			}
		} else if (popupName === POPUPNAME.RESET_ROUTE_TASK) {
			var stateTable = this.smartTable.current.getState();
			if (stateTable.selectedRows.length == 0) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyRoutes,
					variant: 'warning', closeButton: true, duration: 7000});
			} else {
				var validRoute = true;
				for (var i = 0; i < stateTable.selectedRows.length; i++) {
					if (
						stateTable.selectedRows[i].routeStatus != 0 &&
						stateTable.selectedRows[i].routeStatus != 1 &&
						stateTable.selectedRows[i].routeStatus != 2 &&
						stateTable.selectedRows[i].routeStatus != 6
					) {
						validRoute = false;
						break;
					}
				}
				if (!validRoute) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageResetRouteTasks,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					this.state.scheduleInit = stateTable.selectedRows[0].startWay;
					this.state.stateTable = stateTable;
				}
			}
		} else if (popupName === POPUPNAME.RESET_CAMCELED_TASK) {
			var stateTable = this.smartTable.current.getState();
			if (stateTable.selectedRows.length == 0) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyRoutes,
					variant: 'warning', closeButton: true, duration: 7000});
			} else if (stateTable.selectedRows.length > 1) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageMoreRoutesSelected,
					variant: 'warning', closeButton: true, duration: 7000});
			} else {
				var validRoute = true;
				for (var i = 0; i < stateTable.selectedRows.length; i++) {
					if (stateTable.selectedRows[i].routeStatus != 4) {
						validRoute = false;
						break;
					}
				}
				if (!validRoute) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageResetCanceledTasks,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					this.state.scheduleInit = stateTable.selectedRows[0].startWay;
					this.state.stateTable = stateTable;
				}
			}
		} else if (popupName === POPUPNAME.RESTART_ROUTE) {
			var stateTable = this.smartTable.current.getState();
			if (stateTable.selectedRows.length == 0) {
					errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyRoutes,
				variant: 'warning', closeButton: true, duration: 7000});
			} else if (stateTable.selectedRows.length > 1) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageMoreRoutesSelected,
					variant: 'warning', closeButton: true, duration: 7000});
			} else {
				var validRoute = true;
				for (var i = 0; i < stateTable.selectedRows.length; i++) {
					if (stateTable.selectedRows[i].routeStatus != 4 && stateTable.selectedRows[i].routeStatus != 6) {
						validRoute = false;
						break;
					}
				}
				if (!validRoute) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageRestartRoute,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					this.state.scheduleInit = stateTable.selectedRows[0].startWay;
					this.state.stateTable = stateTable;
				}
			}
		} else if (popupName === POPUPNAME.REASSIGN_ROUTE_AGENT) {
			var stateTable = this.smartTable.current.getState();
			if (stateTable.selectedRows.length == 0) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyRoutes,
					variant: 'warning', closeButton: true, duration: 7000});
			} else if (stateTable.selectedRows.length > 1) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageMoreRoutesSelected,
					variant: 'warning', closeButton: true, duration: 7000});
			} else {
				var validRoute = true;
				for (var i = 0; i < stateTable.selectedRows.length; i++) {
					if (
						stateTable.selectedRows[i].routeStatus != 1 &&
						stateTable.selectedRows[i].routeStatus != 2 &&
						stateTable.selectedRows[i].routeStatus != 6
					) {
						validRoute = false;
						break;
					}
				}
				if (!validRoute) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageReassignAgent,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					this.state.scheduleInit = stateTable.selectedRows[0].startWay;
					this.state.stateTable = stateTable;
				}
			}
		} else if (popupName === POPUPNAME.RESCHEDULE_ROUTE) {
			var stateTable = this.smartTable.current.getState();
			if (stateTable.selectedRows.length == 0) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyRoutes,
					variant: 'warning', closeButton: true, duration: 7000});
			} else if (stateTable.selectedRows.length > 1) {
				errorPopUp = true;
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageMoreRoutesSelected,
					variant: 'warning', closeButton: true, duration: 7000});
			} else {
				var validRoute = true;
				for (var i = 0; i < stateTable.selectedRows.length; i++) {
					if (
						stateTable.selectedRows[i].routeStatus != 0 &&
						stateTable.selectedRows[i].routeStatus != 1 &&
						stateTable.selectedRows[i].routeStatus != 2 &&
						stateTable.selectedRows[i].routeStatus != 6
					) {
						validRoute = false;
						break;
					}
				}
				if (!validRoute) {
					errorPopUp = true;
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageRescheduleRoutes,
						variant: 'warning', closeButton: true, duration: 7000});
				} else {
					this.state.scheduleInit = stateTable.selectedRows[0].startWay;
					this.state.stateTable = stateTable;
				}
			}
		} else {
			this.state.stateTable = this.smartTable.current ? this.smartTable.current.getState() : null;
		}
		if (!errorPopUp) {
			var popups = { ...this.state.popups };
			popups[popupName] = !this.state.popups[popupName];
			this.setState({ popups });
		} else {
			this.handleCloseTaskDetails();
			if (this.state.options == SELECTION_TASKGROUP) {
				this.closeInfoToTaskGroupActionPopup();
			}
			if (this.state.refreshTable == true) {
				selectedRows = [];
				this.closeInfoToTaskActionPopup();
				this.setState({
					refreshTable: false,
					refreshSelected: !this.state.refreshSelected,
					executeLoadMore: !this.state.executeLoadMore,
				});
			}
		}
	};
	handleAssignAsset = (stateTable) => {
		const rows = stateTable.selectedRows;

		for (let i = 0; i < rows.length; i++) {
			const task = rows[i];

			if (task.status == TASK_STATUS.CANCEL || task.status == TASK_STATUS.FINISH) {
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorAssignAssetWrongStatus,
				variant: 'warning', closeButton: true, duration: 7000});
				return false;

			} else if (task.partnerTaskRelations.length) {
				const partnerTaskRelations = task.partnerTaskRelations;
				const size = partnerTaskRelations.length - 1;
				const lastOwnerId = partnerTaskRelations[size].idAccountOwner;
				const userLoggedIdAccount = this.state.session.user.idAccount;

				if (lastOwnerId != userLoggedIdAccount) {
					this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageErrorAssignAssetPartner,
						variant: 'warning', closeButton: true, duration: 7000});
					return false;
				}
			}
		}

		return true;
	};
	showDayPickerInput = (daypickername) => {
		var daypickers = { ...this.state.daypickers };
		daypickers[daypickername] = !this.state.daypickers[daypickername];
		this.setState({ daypickers });
	};
	getSelectedId = (rows) => {
		if (!rows) {
			return [];
		}

		return rows.map((row) => row.taskId);
	};
	getSelectedAgents = (rows) => {
		if (!rows.length) {
			return;
		}

		const selectedAgents = [];
		for (var i = 0; i < rows.length; i++) {
			if (rows[i].agent) {
				selectedAgents.push(rows[i].agent);
			}
		}
		return selectedAgents;
	};
	

	getSelectedTeam = (rows = []) => 
		rows
			.filter(row => row.taskIcons?.team)
			.map(row => row.taskIcons.team)
			.join(" / ");


	getSelectedPartnerTaskRelation = (rows) => {
		var taskPartnerRelation = null;

		if (rows !== undefined && rows.length !== 0) {
			for (var i = 0; i < rows.length; i++) {
				taskPartnerRelation = rows[i].partnerTaskRelations;
			}
		}
		if (taskPartnerRelation != undefined && taskPartnerRelation != null) {
			return taskPartnerRelation[0];
		} else {
			return taskPartnerRelation;
		}
	};
	getSelectedIdGroup = (rows) => {
		if (!rows && !rows.length) {
			return;
		}

		return rows.map((row) => {
			// Task group tab and task tab uses the same functions
			if (row.idGroupTask) {
				return row.idGroupTask;
			}

			return row.group; // for task tab
		});
	};
	getSelectedIdRoutes = (rows) => {
		var idSelected = [];

		if (rows !== undefined && rows.length !== 0) {
			for (var i = 0; i < rows.length; i++) {
				idSelected.push(rows[i].id);
			}
		}
		return idSelected;
	};
	getSelectedIdGroupTask = (rows) => {
		var idSelected = [];

		if (rows !== undefined && rows.length !== 0) {
			for (var i = 0; i < rows.length; i++) {
				if (rows[i].idGroupTask) {
					idSelected.push(rows[i].idGroupTask);
				}
			}
		}
		return idSelected;
	};
	getSelectedIdGroupTaskInfo = (rows) => {
		let idSelected = [];
		let canDelete = true;

		if (rows !== undefined && rows.length !== 0) {
			for (var i = 0; i < rows.length; i++) {
				if (rows[i].idAccount != this.state.session.user.idAccount) {
					canDelete = false;
				}

				if (rows[i].idGroupTask) {
					idSelected.push(rows[i].idGroupTask);
				}
			}
		}
		return {
			idSelected,
			canDelete,
		};
	};
	onRouteRowClick = (rowId, row) => {
		if (row) {
			let pops = this.state.popups;
			pops.routedetail = true;
			this.setState({ popups: pops, idRouteSelected: row.id });
		}

		this.setState({ showRouteActionPopup: false });
	};
	validateTabView = (options) => {
		this.setState({ options });
		if (options == SELECTION_ROUTE) {
			cookieName = 'myOperationRoutePreferences';
			this.getRouteDate();
			this.setState({ advancedFilterValues: null });
		} else if (options == SELECTION_TASK) {
			//document.getElementById('filter').value = '';
			this.setState({
				executeLoadMore: !this.state.executeLoadMore,
				filter: null,
				tagsFilter: null,
				refreshSelected: !this.state.refreshSelected,
			});
		} else if (options == SELECTION_TASKGROUP) {
			cookieName = 'myOperationTaskGroupPreferences';
			this.setState({
				executeLoadMore: !this.state.executeLoadMore,
				filter: null,
				tagsFilter: null,
				advancedFilterValues: null,
				refreshSelected: !this.state.refreshSelected,
			});
		} else if (options == SELECTION_AUTOASSIGN) {
			this.setState({ advancedFilterValues: null });
		}
	};
	handlerActionDisableGroupTasks = () => {
		const idGroupTasks = this.getSelectedIdGroupTask(selectedRows);
		var listOfErrorDeleteGroupTask = [];

		this.disableGroupTask(idGroupTasks[0], 0, idGroupTasks, 0, 0, listOfErrorDeleteGroupTask);
	};
	disableGroupTask = (idGroupTask, i, idGroupTasks, numberLinked, numberSuccess, listOfErrorDeleteGroupTask) => {
		this.props.dispatch(loadingStart({ name: this.props.pageName }));

		if (i < idGroupTasks.length) {
			const body = { idGroup: idGroupTasks[i] };

			const onSuccess = () => {
				numberSuccess++;
				listOfErrorDeleteGroupTask.push({
					idGroupTask: idGroupTask,
					statusCode: 0,
				});
			};

			const onError = ({ status }) => {
				numberLinked++;
				listOfErrorDeleteGroupTask.push({
					idGroupTask: idGroupTask,
					statusCode: status,
				});
			};

			disableTaskGroupRequest(body, onSuccess, onError).then(() => {
				i++;
				this.disableGroupTask(
					idGroupTasks[i],
					i,
					idGroupTasks,
					numberLinked,
					numberSuccess,
					listOfErrorDeleteGroupTask
				);
			});
		} else {
			selectedRows = [];

			this.closeInfoToTaskGroupActionPopup();
			this.setState({
				confirmationDialogGroupTask: false,
				listOfErrorDeleteGroupTask: listOfErrorDeleteGroupTask,
				numberSuccess: numberSuccess,
				numberLinked: numberLinked,
				elementName: this.props.lan.groupTask,
				errormessageDelete: true,
				refreshSelected: !this.state.refreshSelected,
				executeLoadMore: !this.state.executeLoadMore,
			});
		}

		this.props.dispatch(loadingStop(this.props.pageName));
	};
	handlerActionDeleteRoutes = () => {
		this.props.dispatch(loadingStart({ name: this.props.pageName }));
		let stateTable = this.smartTable.current.getState();
		let idRoutes = this.getSelectedIdRoutes(stateTable.selectedRows);
		let listOfErrorDeleteRoute = [];

		this.disableRoute(idRoutes[0], 0, idRoutes, 0, 0, listOfErrorDeleteRoute);
	};
	disableRoute = (idRoute, i, idRoutes, numberLinked, numberSuccess, listOfErrorDeleteRoute) => {
		this.props.dispatch(loadingStart({ name: this.props.pageName }));
		const loadingConfig = { dispatch: this.props.dispatch };

		if (i < idRoutes.length) {
			let body = request.getRequestDoActionTrashRoute(idRoutes[i]);

			const onSuccess = () => {
				listOfErrorDeleteRoute.push({ idRoute: idRoute, statusCode: 0 });
				numberSuccess += 1;
			};

			const onError = ({ status }) => {
				listOfErrorDeleteRoute.push({ idRoute: idRoute, statusCode: status });
				numberLinked += 1;
			};

			doActionTrashRouteRequest(body, onSuccess, onError, loadingConfig).then(() => {
				i++;
				this.disableRoute(idRoutes[i], i, idRoutes, numberLinked, numberSuccess, listOfErrorDeleteRoute);
			});
		} else {
			this.setState({
				confirmationDialogRoute: false,
				listOfErrorDeleteRoute: listOfErrorDeleteRoute,
			});
			let combo = document.getElementById('pagination');
			let selected = combo.options[combo.selectedIndex].text;

			this.setState({
				numberSuccess: numberSuccess,
				numberLinked: numberLinked,
				elementName: this.props.lan.routes,
				errormessageDelete: true,
			});

			this.setRouteDataWithWarnings(
				parseInt(selected),
				this.state.offset,
				selected,
				this.state.from,
				this.state.to,
				''
			);
			this.props.dispatch(loadingStop(this.props.pageName));
		}
	};
	handlerActionDeleteTasks = () => {
		var idTasks = this.getSelectedId(selectedRows);
		let body = {
			tasks: idTasks,
		};

		const loadingConfig = { dispatch: this.props.dispatch, name: this.props.pageName };

		const onSuccess = () => {
			let message = this.props.lan.messageTaskDisableSucess;

			if (this.state.showDisabledTasks) {
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageTaskDeleteSuccess,
					variant: 'success', closeButton: true, duration: 5000});
			}

			selectedRows = [];

			this.closeDeleteConfirmationDialog();
			this.closeInfoToTaskActionPopup();

			this.setState({
				message,
				changeIcon: SuccessIcon,
				refreshSelected: !this.state.refreshSelected,
				executeLoadMore: !this.state.executeLoadMore,
			});
		};

		const onError = ({ status }) => {
			selectedRows = [];
			if (this.state.showDisabledTasks) {
				this.setState({
					refreshSelected: !this.state.refreshSelected,
					confirmationDialog: false,
				});
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageTaskDeleteError,
					variant: 'error', closeButton: true, duration: 5000});
				getAccountPreferences(this, this.props.session);
			} else {
				let msg;

				if (status === 910) {
					msg = this.props.lan.messageTaskWithRoute;
				} else if (status === 911) {
					msg = this.props.lan.messageTaskDisableError;
				}

				this.setState({
					refreshSelected: !this.state.refreshSelected,
					confirmationDialog: false,
				});
				this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
					variant: 'error', closeButton: true, duration: 5000});
				getAccountPreferences(this, this.props.session);
			}
			this.closeInfoToTaskActionPopup();
		};

		if (this.state.showDisabledTasks) {
			disableTaskRequest(body, onSuccess, onError, loadingConfig);
		} else {
			deleteTaskRequest(body, onSuccess, onError, loadingConfig);
		}
	};
	handlerActionRestoreTasks = (i) => {
		var idTasks = this.getSelectedId(selectedRows);

		this.props.dispatch(loadingStart({ name: this.props.pageName }));

		if (i < idTasks.length) {
			const body = {
				idTask: idTasks[i],
				user: {
					idAccount: this.props.user.idAccount,
					idUser: this.props.user.idUser,
				},
			};
			i++;

			restartTaskRequest(body).then(() => this.handlerActionRestoreTasks(i));
		} else {
			this.props.dispatch(loadingStop(this.props.pageName));
			this.closeRestoreConfirmationDialog();
			this.closeInfoToTaskActionPopup();
			selectedRows = [];

			this.setState({
				message: this.props.lan.messageTaskDisableSucess,
				changeIcon: SuccessIcon,
				refreshSelected: !this.state.refreshSelected,
				executeLoadMore: !this.state.executeLoadMore,
			});
		}
	};
	deleteTask = () => {
		if (selectedRows.length > 0) {
			let typeOfMessage = '';

			if (this.state.showDisabledTasks) {
				typeOfMessage = this.props.lan.messageConfirmDeleteTasks;
			} else {
				typeOfMessage = this.props.lan.messageConfirmaDisableTask;
			}

			this.setState({
				messageConfirmDeleteTasks: typeOfMessage,
				confirmationDialog: true,
			});
		} else {
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageMusntDelete,
				variant: 'error', closeButton: true, duration: 7000});
		}
	};
	deleteGroupTask = () => {
		if (selectedRows.length > 0) {
			const groupTasksInfo = this.getSelectedIdGroupTaskInfo(selectedRows);
			const canDelete = groupTasksInfo.canDelete;
			if (canDelete) {
				this.setState({
					messageConfirmDeleteGroupTasks: this.props.lan.messageConfirmDeleteGroupTasks,
					confirmationDialogGroupTask: true,
				});
			} else {
				this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageTaskGroupDeletePartner,
					variant: 'error', closeButton: true, duration: 7000});
			}

			this.props.dispatch(loadingStop(this.props.pageName));
		} else {
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyGroupTasks,
				variant: 'error', closeButton: true, duration: 7000});
		}
	};
	restoreTask = () => {
		var tasks = selectedRows;

		if (tasks.length > 0) {
			this.setState({
				messageConfirmRestoreTasks: this.props.lan.messageConfirmRestoreTasks,
				confirmationDialogRestoreTask: true,
			});
		} else {
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageMusntDelete,
				variant: 'error', closeButton: true, duration: 7000});
		}
	};
	deleteRoute = () => {
		let stateTable = this.smartTable.current.getState();
		let routes = this.getSelectedIdRoutes(stateTable.selectedRows);
		var canDelete = true && routes.length > 0;

		this.props.dispatch(loadingStop(this.props.pageName));

		if (canDelete) {
			this.setState({
				messageConfirmDeleteRoutes: this.props.lan.messageConfirmDeleteRoutes,
				confirmationDialogRoute: true,
			});
		} else {
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.messageEmptyRoutes,
				variant: 'error', closeButton: true, duration: 7000});
		}
	};
	closeRoutePopup = (res) => {
		this.changePopupState(POPUPNAME.GENERATE_ROUTE);
		let success = false;

		if (res === undefined) {
			success = true;
		}

		if (res && res.statusCode === 200) {
			let info = res.response;
			if (info && info.success && info.routes && info.routes.length > 0) {
				let msg = '';
				success = true;

				if (info.routes.length > 1) {
					msg = this.props.lan.successCreatedRoutes;
					msg = msg.replace('{0}', info.routes.length);
				} else {
					msg = this.props.lan.successCreatedRoute;
				}

				this.setState({
					refreshTable: true,
				});
				this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
					variant: 'success', closeButton: true, duration: 7000});
			}
		}
		if ((res.response.success = false)) {
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorCreatedRoute,
				variant: 'error', closeButton: true, duration: 7000});
		}
	};
	closeReschedulePopup = (success, fail, total) => {
		this.changePopupState(POPUPNAME.RESCHEDULE_TASK);
		if (success && !fail) {
			let msg = this.props.lan.savedCorrectlyReschedule + ' ' + total;
			this.setState({
				refreshTable: true,
			});

			let pops = this.state.popups;
			pops.rescheduleTask = false;
			pops.taskdetail = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
				variant: 'success', closeButton: true, duration: 7000});

			this.setState({
				refreshSelected: !this.state.refreshSelected,
				executeLoadMore: !this.state.executeLoadMore,
			});
		} else if (!success && fail) {
			let pops = this.state.popups;
			pops.rescheduleTask = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 7000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.rescheduleTask = false;
			this.setState({ popups: pops });
		}
	};
	closeDuplicatePopup = (success, fail) => {
		this.changePopupState(POPUPNAME.DUPLICATE_TASK);
		if (success && !fail) {
			this.setState({
				refreshTable: true,
			});

			let pops = this.state.popups;
			pops.duplicateTask = false;
			pops.taskdetail = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 7000});

			this.setState({
				refreshSelected: !this.state.refreshSelected,
				executeLoadMore: !this.state.executeLoadMore,
			});

			this.props.navigate(ENDPOINTS.myOperation);
		} else if (!success && fail) {
			let pops = this.state.popups;
			pops.duplicateTask = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 7000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.duplicateTask = false;
			this.setState({ popups: pops });
		}
	};
	closeEditTaskPopup = (executeCounter) => {
		this.changePopupState('tasks');

		const pops = this.state.popups;
		pops.taskdetail = false;
		pops.editTask = false;
		pops.parenttaskdetail = false;

		this.setState({
			//refreshTable: true,
		});
		this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
			variant: 'success', closeButton: true, duration: 5000});
		if (executeCounter && this.state.hasMailIntegrationFunctionality && this.state.options === SELECTION_TASK) {
			this.setState({executeLoadMore: !this.state.executeLoadMore})
			this.executeCountTaskByMail();
		}
	};
	closeUnlinkGroupPopup = (result) => {
		this.changePopupState(POPUPNAME.EDIT_GROUP_TASK);

		if (result === true) {
			let msg = this.props.lan.groupTasksAreBeingUnlinked;
			this.setState({
				refreshTable: true,
			});

			this.setState({
				showTaskActionPopup: false,
				showRouteActionPopup: false,
				showGroupTaskActionPopup: false,
			});

			let pops = this.state.popups;
			pops.editGroupTask = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
				variant: 'success', closeButton: true, duration: 5000});
		} else if (result === false) {
			let pops = this.state.popups;
			pops.editGroupTask = false;
			this.setState({ popups: pops });
      this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail ,
        variant: 'error', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.editGroupTask = false;
			this.setState({ popups: pops });
		}

	};
	closeEditGroupPopup = (result) => {
		this.changePopupState(POPUPNAME.EDIT_GROUP_TASK);

		if (result === true) {
			let msg = this.props.lan.groupTasksAreBeingAssigned;
			this.setState({
				message: msg,
				changeIcon: SuccessIcon,
				refreshTable: true,
			});

			this.setState({
				showTaskActionPopup: false,
				showRouteActionPopup: false,
				showGroupTaskActionPopup: false,
			});

			let pops = this.state.popups;
			pops.editGroupTask = false;
			this.setState({ popups: pops });
			this.changePopupState(POPUPNAME.ERROR_MESSAGE);
		} else if (result === false) {
			this.setState({ message: this.props.lan.errorGralFail });
			let pops = this.state.popups;
			pops.editGroupTask = false;
			this.setState({ popups: pops });
			this.changePopupState(POPUPNAME.ERROR_MESSAGE);
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.editGroupTask = false;
			this.setState({ popups: pops });
		}
	};
	refreshTable = () => {
		var stateTable = this.smartTable.current.getState();
		stateTable.selectedRows = [];
		stateTable.selectedIndexes = [];

		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
		});
		var combo = document.getElementById('pagination');
		var selected = combo.options[combo.selectedIndex].text;

		if (this.state.options == SELECTION_ROUTE) {
			this.setRouteData(parseInt(selected), this.state.offset, '10', this.state.from, this.state.to, this.state.filter);
		}

		this.setState({ refreshTable: false });
	};
	closeRescheduleRoutePopup = (success, fail) => {
		this.changePopupState(POPUPNAME.RESCHEDULE_ROUTE);

		if (success == null && fail == null) {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.rescheduleRoute = false;
			this.setState({ popups: pops });
		} else if (success && !fail) {
			let pops = this.state.popups;
			pops.rescheduleRoute = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });

			this.refreshTable();
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else if (!success && fail) {
			let pops = this.state.popups;
			pops.rescheduleRoute = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 5000});
		} else if (!success && !fail) {
			let pops = this.state.popups;
			pops.rescheduleRoute = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorRouteTaskNull,
				variant: 'error', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.rescheduleRoute = false;
			this.setState({ popups: pops });
		}
	};
	closeIsochronousPopup = (res) => {
		let msg = '';

		this.changePopupState(POPUPNAME.ASSIGN_RESOURCES);

		if (res === undefined) {
			return;
		}

		if (this.validatePopupRes(res)) {
			msg = this.props.lan.resourceAllocationSuccess;
		} else {
			msg = this.props.lan.errorEnsureInformationCorrect;
		}
		this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
			variant: this.validatePopupRes(res)? 'success' : 'warning', closeButton: true, duration: 5000});
	};
	closeAgentPopup = (success, res) => {
		let msg = this.props.lan.messageAssignAgentSuccessfully;

		this.changePopupState(POPUPNAME.ASSIGN_AGENT);
		if (res === undefined) {
			return;
		}
	
		if (success) {
			selectedRows = [];
			this.closeInfoToTaskActionPopup();

			this.setState({
				refreshTable: false,
				refreshSelected: !this.state.refreshSelected,
				executeLoadMore: !this.state.executeLoadMore,
			});
		} else if (!success && res && res.status === 596) {
			msg = this.props.lan.taskStatusUpdated; 
		} else {
			msg = this.props.lan.messageErrorAssignAgent;
		}
		let pops = this.state.popups;
		pops.taskdetail = false;

		this.setState({ popups: pops });
		this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
				variant: success? 'success' : 'error', closeButton: true, duration: 5000});
	};
	closeTeamPopup = (success, res) => {
		let msg = '';

		this.changePopupState(POPUPNAME.ASSIGN_TEAM);

		if (res === undefined) {
			return;
		}
		
		if (success) {
			msg = this.props.lan.messageAssignTeamSuccessfully;

			selectedRows = [];
			this.closeInfoToTaskActionPopup();
			this.setState({
				refreshTable: false,
				refreshSelected: !this.state.refreshSelected,
				executeLoadMore: !this.state.executeLoadMore,
			});
		} else if (!success && res && res.status === 596) {
			msg = this.props.lan.taskStatusUpdated; 
		} else {
			msg = this.props.lan.messageErrorAssignTeam;
		}

		let pops = this.state.popups;
		pops.taskdetail = false;

		this.setState({ popups: pops });
		this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
			variant: success? 'success' : 'error', closeButton: true, duration: 5000});
	};
	closeCancelPopup = (success) => {
		this.changePopupState(POPUPNAME.CANCEL_ROUTE);

		if (success == true) {
			let msg = this.props.lan.savedCorrectly;
			this.setState({
				refreshTable: true,
			});

			let pops = this.state.popups;
			pops.cancelRoute = false;
			this.setState({ popups: pops });

			this.refreshTable();
			this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
				variant: 'success', closeButton: true, duration: 5000});
		} else if (success == false) {
			let pops = this.state.popups;
			pops.cancelRoute = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.cancelRoute = false;
			this.setState({ popups: pops });
		}
	};
	closeAddInventoryPopup = (success) => {
		this.changePopupState(POPUPNAME.ASSIGN_INVENTORIES);
		if (success) {
			let pops = this.state.popups;
			pops.assignInventories = false;
			pops.taskdetail = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.assignInventories = false;
			this.setState({ popups: pops });
		}
	};
	closeAddAssetsPopup = (success) => {
		this.changePopupState(POPUPNAME.ASSIGN_ASSETS);
		if (success) {
			let pops = this.state.popups;
			pops.assignAssets = false;
			pops.taskdetail = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.assignAssets = false;
			this.setState({ popups: pops });
		}
	};
	closeAddFormsPopup = (success) => {
		this.changePopupState(POPUPNAME.ASSIGN_FORMS);
		if (success) {
			let pops = this.state.popups;
			pops.assignForms = false;
			pops.taskdetail = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.assignForms = false;
			this.setState({ popups: pops });
		}
	};
	closeResetRouteTasksPopup = (success, fail) => {
		this.changePopupState('ResetRouteTasks');

		if (success && !fail) {
			let pops = this.state.popups;
			pops.resetRouteTask = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });
			this.refreshTable();
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else if (!success && fail) {
			let pops = this.state.popups;
			pops.resetRouteTask = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.resetRouteTask = false;
			this.setState({ popups: pops });
		}
	};
	closeResetCanceledTasksPopup = (success, fail) => {
		this.changePopupState(POPUPNAME.RESET_CAMCELED_TASK);

		if (success && !fail) {
			let pops = this.state.popups;
			pops.resetCanceledTask = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });
			this.refreshTable();
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else if (!success && fail) {
			let pops = this.state.popups;
			pops.resetCanceledTask = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.resetCanceledTask = false;
			this.setState({ popups: pops });
		}
	};
	closeResetRouteDateAgentPopup = (success, fail) => {
		this.changePopupState('RestartRoute');

		if (success == null && fail == null) {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.restartRoute = false;
			this.setState({ popups: pops });
		} else if (success && !fail) {
			let pops = this.state.popups;
			pops.restartRoute = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });
			this.refreshTable();
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else if (!success && !fail) {
			let pops = this.state.popups;
			pops.restartRoute = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorRouteTaskNull,
				variant: 'error', closeButton: true, duration: 5000});
		} else if (!success && fail) {
			let pops = this.state.popups;
			pops.restartRoute = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'warning', closeButton: true, duration: 7000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.restartRoute = false;
			this.setState({ popups: pops });
		}
	};
	closeReassignRouteAgentPopup = (success, fail) => {
		this.changePopupState('ReassignRouteAgent');

		if (success == null && fail == null) {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.reassignRouteAgent = false;
			this.setState({ popups: pops });
		} else if (success && !fail) {
			let pops = this.state.popups;
			pops.reassignRouteAgent = false;
			this.setState({ popups: pops });
			this.setState({ refreshTable: true });
			this.refreshTable();
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else if (!success && !fail) {
			let pops = this.state.popups;
			pops.reassignRouteAgent = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorRouteTaskNull,
				variant: 'warning', closeButton: true, duration: 7000});
		} else if (!success && fail) {
			let pops = this.state.popups;
			pops.reassignRouteAgent = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.reassignRouteAgent = false;
			this.setState({ popups: pops });
		}
	};
	closePartnerPopup = (success, response) => {
		console.log(response)
		this.changePopupState(POPUPNAME.ASSIGN_PARTNER);
		console.log("success")
		console.log(success)
		if (success) {
			let pops = this.state.popups;
			pops.assignPartner = false;
			pops.taskdetail = false;
			this.setState({ popups: pops });
			this.closeInfoToTaskActionPopup();
			this.setState({ refreshTable: true, executeLoadMore: !this.state.executeLoadMore, refreshSelected: !this.state.refreshSelected });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.savedCorrectly,
				variant: 'success', closeButton: true, duration: 5000});
		} else if(!success && response && response.status === 596) { 
			let pops = this.state.popups;
			pops.assignPartner = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.taskStatusUpdated,
				variant: 'error', closeButton: true, duration: 5000});
		}  else if (!success) {
			let pops = this.state.popups;
			pops.assignPartner = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 5000});
		} else {
			let pops = this.state.popups;
			pops.errormessage = false;
			pops.assignPartner = false;
			this.setState({ popups: pops });
			this.props.fielderMsg({title: this.props.lan.myOperation, message: this.props.lan.errorGralFail,
				variant: 'error', closeButton: true, duration: 5000});
		}
	};
	closeReassignPartnerPopup = (success) => {
		this.changePopupState(POPUPNAME.RE_ASSIGN_PARTNER);
		this.closeInfoToTaskActionPopup();
		let msg = this.props.lan.savedCorrectly;
		if (!success) {
			msg = this.props.lan.errorGralFail;
		}
		let pops = this.state.popups;
		pops.reAssignPartner = false;
		this.setState({ popups: pops });
		this.setState({ refreshTable: success,  executeLoadMore: !this.state.executeLoadMore, refreshSelected: !this.state.refreshSelected });
		this.props.fielderMsg({title: this.props.lan.myOperation, message: msg,
			variant: success? 'success' : 'error', closeButton: true, duration: 5000});
	};
	validatePopupRes = (res) => {
		let success = false;
		if (res && res.statusCode === 200 && res.response) {
			let info = res.response;

			if (info && info.success) {
				success = true;
			}
		}

		return success;
	};
	closeDeleteConfirmationDialog = () => {
		let state = this.state;
		state.confirmationDialog = false;
		this.setState(state);

		selectedRows = [];
		this.closeInfoToTaskActionPopup();
		this.setState({ refreshSelected: !this.state.refreshSelected });
	};
	closeRestoreConfirmationDialog = () => {
		let state = this.state;
		state.confirmationDialogRestoreTask = false;
		this.setState(state);
	};
	closeDeleteConfirmationDialogRoute = () => {
		let state = this.state;
		state.confirmationDialogRoute = false;
		this.setState(state);
	};
	closeDeleteConfirmationDialogGroupTask = () => {
		let state = this.state;
		state.confirmationDialogGroupTask = false;
		this.setState(state);

		selectedRows = [];
		this.closeInfoToTaskGroupActionPopup();
		this.setState({ refreshSelected: !this.state.refreshSelected });
	};
	handlerEventKey = (target) => {
		if (target.charCode == 13) {
			this.handleFilter();
		}
	};
	hasDates = () => {
		if (this.state.from != null && this.state.to != null) {
			this.setState({ datesError: false });
			return true;
		} else {
			this.setState({ datesError: true });
			return false;
		}
	};
	getFilterTaskTab = (filter) => {
		let tags = [];
		let filters = '';

		if (filter) {
			let splited = filter.split(' ');
			for (let i = 0; i < splited.length; i++) {
				let fltr = splited[i];

				if (fltr.includes('#')) {
					tags.push(fltr.slice(1));
				} else {
					filters = filters + ' ' + fltr;
				}
			}
		}

		if (tags.length === 0) {
			tags = null;
		}
		if (filters === '') {
			filters = null;
		}

		return {
			filter: filters,
			tags: tags,
		};
	};
	handleViewTable = () => {
		// hide the map
		this.setState({ map: false, showMap: false });

		// handle the syles
		var elementHTML = document.createElement('style');
		elementHTML.setAttribute('id', 'Myoperation-table-style');
		elementHTML.innerHTML = '.react-grid-Canvas, .react-grid-Grid{height: 100vh !important;}';
		document.body.appendChild(elementHTML);
	};
	handleViewIcon = () => {
		// Show the map
		this.setState({ map: true, showMap: true });

		//Update the table
		this.updateTable();

		//handle the styles
		const elementHTML = document.getElementById('Myoperation-table-style');
		if (!elementHTML) return;

		const doc = elementHTML.parentNode;
		doc.removeChild(elementHTML);

	};
	closeShowMessage = () => {
		this.setState({ createGroupTaskSuccess: false });
		selectedRows = [];
		this.closeInfoToTaskActionPopup();
		this.setState({ refreshSelected: !this.state.refreshSelected });
	};
	closeShowMessageCancelTask = () => {
		this.setState({ cancelTasksSuccess: false });
	};
	closeNoFormsToDownload = () => {
		this.setState({ showNoForms: false });
	};
	closeNoFormsImagesToDownload = () => {
		this.setState({ showNoFormsImages: false });
	};
	setDefaultFalse = () => {
		this.setState({
			messageCreateGroupTask: this.props.lan.addedCorrectly,
			createGroupTaskSuccess: true,
		});

		selectedRows = [];
		this.closeInfoToTaskActionPopup();
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			executeLoadMore: !this.state.executeLoadMore,
		});
	};
	setDefaultFalseAssign = (success, response) => {
		const popups = this.state.popups;
		popups.taskdetail = false;
		popups.assignGroupTask = false;
		//this.setState(state);

		if(success) {
			this.setState({
				messageCreateGroupTask: this.props.lan.assignGroupTask,
				createGroupTaskSuccess: true,
				showErrorMessageIcon:SuccessIcon,
				
			});
		} else if(!success && response && response.status === 596) {
			this.setState({
				messageCreateGroupTask: this.props.lan.taskStatusUpdated,
				createGroupTaskSuccess: true,
				showErrorMessageIcon:AlertIcon,
				
			});
		} else {
			this.setState({
				messageCreateGroupTask: this.props.lan.errorGralFail,
				createGroupTaskSuccess: true,
				showErrorMessageIcon:AlertIcon,
				
			});
		}
	};
	closeCancelTaskModalSucess = () => {
		const popups = this.state.popups;
		popups.cancelTask = false;
		popups.taskdetail = false;
		selectedRows = [];

		this.closeInfoToTaskActionPopup();
		this.setState({
			popups,
			refreshSelected: !this.state.refreshSelected,
			executeLoadMore: !this.state.executeLoadMore,
			messageCancelTaksSuccess: this.props.lan.cancelSuccessfully,
			showErrorMessageIcon: SuccessIcon,
			cancelTasksSuccess: true,
		});
	};
	closeRateTaskModalSuccess = () => {
		selectedRows = [];
		this.closeInfoToTaskActionPopup();
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			executeLoadMore: !this.state.executeLoadMore,
		});

		this.setState({
			messageCancelTaksSuccess: this.props.lan.rateSuccessfully,
			showErrorMessageIcon: SuccessIcon,
			cancelTasksSuccess: true,
		});

		let pops = this.state.popups;
		pops.rateTask = false;
		this.setState({ popups: pops });
	};
	closeCancelTaskModalFail = () => {
		this.setState({
			messageCancelTaksSuccess: this.props.lan.cancelError,
			showErrorMessageIcon: YellowAlertIcon,
			cancelTasksSuccess: true,
		});
		let pops = this.state.popups;
		pops.cancelTask = false;
		this.setState({ popups: pops });
	};
	closeRateTaskModalFail = () => {
		this.setState({
			messageCancelTaksSuccess: this.props.lan.rateError,
			showErrorMessageIcon: YellowAlertIcon,
			cancelTasksSuccess: true,
		});
		let pops = this.state.popups;
		pops.rateTask = false;
		this.setState({ popups: pops });
	};
	scrollToTable = () => {
		scroll.scrollTo(600);
	};
	closeRouteAction = () => {
		this.closeInfoToRouteActionPopup();
		var stateTable = this.smartTable.current.getState();
		stateTable.selectedRows = [];
		stateTable.selectedIndexes = [];

		this.scrollToTable();
	};
	handleCheckTaskTab = (rowsSelected) => {
		selectedRows = rowsSelected;

		if (rowsSelected.length > 0) {
			this.setState({ disabledDetailButton: true });
			this.passInfoToTaskActionPopup(rowsSelected.length);
		} else {
			this.closeInfoToTaskActionPopup();
		}
	};
	passInfoToTaskActionPopup = (selectedIndexes) => {
		this.setState({
			showTaskActionPopup: true,
			selectedTaskCount: selectedIndexes,
		});
	};
	closeInfoToTaskActionPopup = () => {
		this.setState({
			showTaskActionPopup: false,
			selectedTaskCount: 0,
			disabledDetailButton: false,
		});
	};
	closeTaskAction = () => {
		selectedRows = [];
		this.closeInfoToTaskActionPopup();
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			executeLoadMore: !this.state.executeLoadMore,
		});

		this.scrollToTable();
	};
	handleCheckTaskGroupTab = (rowsSelected) => {
		selectedRows = rowsSelected;

		if (rowsSelected.length != 0) {
			this.setState({ disabledDetailButton: true });
			this.passInfoToGroupTaskActionPopup(rowsSelected.length);
		} else {
			this.closeInfoToTaskGroupActionPopup();
		}
	};
	passInfoToGroupTaskActionPopup = (selectedIndexes) => {
		this.setState({
			showGroupTaskActionPopup: true,
			selectedGroupTaskCount: selectedIndexes,
		});
	};
	closeInfoToTaskGroupActionPopup = () => {
		this.setState({
			showGroupTaskActionPopup: false,
			selectedGroupTaskCount: 0,
			disabledDetailButton: false,
		});
	};
	closeTaskGroupAction = () => {
		selectedRows = [];
		this.closeInfoToTaskGroupActionPopup();
		this.setState({
			refreshSelected: !this.state.refreshSelected,
			executeLoadMore: !this.state.executeLoadMore,
		});

		this.scrollToTable();
	};
	passInfoToRouteActionPopup = (selectedIndexes) => {
		this.setState({
			showRouteActionPopup: true,
			selectedRouteCount: selectedIndexes.length,
		});
	};
	handleRouteCheck = (selectedIndexes) => {
		this.setState({ selectedIndexes: selectedIndexes });

		if (selectedIndexes.length != 0) {
			this.passInfoToRouteActionPopup(selectedIndexes);
		} else {
			this.closeInfoToRouteActionPopup();
		}
	};
	closeSimpleModal = () => {
		let pops = this.state.popups;
		pops.cancelTask = false;
		this.setState({ popups: pops });
	};
	closeSimpleModalRateTask = () => {
		let pops = this.state.popups;
		pops.rateTask = false;
		this.setState({ popups: pops });
	};
	setElements = (numberOfElements, elements, showNotTask) => {
		this.setState({
			numberOfElements: numberOfElements,
			tasks: elements,
			showNotTask: showNotTask,
		});
	};
	setElementsGroupTask = (sizeTaskGroup) => {
		this.setState({
			sizeTaskGroup: sizeTaskGroup,
		});
	};
	showMessageDelete = () => this.setState({ errormessageDelete: false });
	addNumberOfElements = () => this.setState({ numberOfElements: this.state.numberOfElements + 1 });
	addNumberOfElementsTaskGroup = () => this.setState({ sizeTaskGroup: this.state.sizeTaskGroup + 1 });
	changeLoadingFilter = (showLoading) => this.setState({ showLoading });
	setMarkers = (markers) => this.setState({ markers });
	addNotificationCountReal = () => this.setState({ notificationCount: this.state.notificationCount + 1 });
	addNotificationCountRealGroup = () =>
		this.setState({ notificationCountGroup: this.state.notificationCountGroup + 1 });
	setAgentRoute = (markers) => this.setState({ agentRoute: markers });
	setDateError = (dateError) => this.setState({ datesError: dateError });
	executeLoadMore = () => this.setState({ executeLoadMore: !this.state.executeLoadMore });
	closeAssignPartners = () => this.changePopupState(POPUPNAME.ASSIGN_PARTNER);
	closeReAssingPartners = () => this.changePopupState(POPUPNAME.RE_ASSIGN_PARTNER);
	closeGroupTask = () => {
		this.changePopupState(POPUPNAME.ASSIGN_GROUP_TASK);
		const pops = this.state.popups;
		pops.assignGroupTask = false;
	}
	closeErrorMessageAction = () => this.changePopupState(POPUPNAME.ERROR_MESSAGE);
	closeInfoToRouteActionPopup = () => this.setState({ showRouteActionPopup: false });
	handleCloseTaskDetails = () => {
		let popups = this.state.popups;
		popups.taskdetail = false;
		this.setState({ popups });
	};
	getTaskId = () => {
		let selectedIds = this.getSelectedId(selectedRows);

		if (selectedIds) {
			return selectedIds;
		}

		return idTaskButton;
	};
	detailAgentModal = () => {
		this.props.fielderModal.setContent(null);
		this.props.fielderModal.closeModal(POP_DETAIL_AGENT);
		const dynamicContent = (
			<AgentDetailContainer
				{...this.props}
				showAction={() => this.closeRenderModal()}
				dataAgent={this.state.dataAgent}
				idAgent={this.state.dataAgent != null ? this.state.dataAgent.id : 0}
			/>
		);
		this.props.fielderModal.setContent(POP_DETAIL_AGENT, dynamicContent);
		this.props.fielderModal.openModal(POP_DETAIL_AGENT);
	};
	renderAgentModal = (showModal, titleKey, id, fullWidth = false) => {
		if (!showModal) return null;
		return (
			<Modal 
				id={id}
				title={this.props.lan[titleKey]} 
				icon={<AddAgentIcon sx={{ color: DeepSpaceGrey, width: '24px' }} />} 
				fullWidth={fullWidth}
			/>
		);
	};
	closeRenderModal = () => {
		this.setState({
			dataAgent: {}
		}, () => {
			this.props.fielderModal.closeAllModals();
		});
	};

	resetDateValues = () => { 
		this.setState({ to: null, from: null });
	}

	executeAdvancedFormFilters = (filter) => { 
		this.setState({advancedFilterFormValues: [...filter]})
		
	}

	resetTaskSection = () => {
		this.setState({
			taskGroupFromTable: false,
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			notificationCount: 0,
			showGroupTaskActionPopup: false,	
		});
	}

	resetRouteSection = () => {
		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
			showSearchByForms:false,
		});
	}

	resetGroupTaskSection = () => {
		this.setState({
			taskGroupFromTable: true,
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
			notificationCountGroup: 0,
			showSearchByForms:false,
		});
	}

	resetAutoAssignSection = () => {
		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
			showSearchByForms:false,
		});
	}	

	resetGroupTaskSectionView = () => {
		this.setState({
			showTaskActionPopup: false,
			showRouteActionPopup: false,
			showGroupTaskActionPopup: false,
		});
	}



	render() {
		return (
			<>
				{this.renderAgentModal(
					this.state.dataAgent !== null && this.state.dataAgent !== undefined ? true: false, 
					'detailAgentTitle', POP_DETAIL_AGENT, true)}
				<div className='Fielder-body' style={{ width: '100%' }}>
					{this.state.popups.assignTeam && (
						<AssignTeamPopup
							{...this.props}
							teamTask={this.getSelectedTeam(selectedRows)}
							onClose={this.closeTeamPopup}
							idTasks={this.getSelectedId(selectedRows)}
						/>
					)}
					{this.state.popups.assignAgent && (
						<AssignAgentPopup
							{...this.props}
							agentsTask={this.getSelectedAgents(selectedRows)}
							onClose={this.closeAgentPopup}
							idTasks={this.getSelectedId(selectedRows)}
						/>
					)}
					{this.state.popups.assignPartner && (
						<AssignPartnerPopup
							{...this.props}
							onClose={this.closeAssignPartners}
							onCloseResponse={this.closePartnerPopup}
							idTasks={this.getSelectedId(selectedRows)}
						/>
					)}
					{this.state.popups.reAssignPartner && (
						<ReAssignPartnerPopup
							{...this.props}
							onClose={this.closeReAssingPartners}
							onCloseResponse={this.closeReassignPartnerPopup}
							idTasks={this.getSelectedId(selectedRows)}
						/>
					)}
					{this.state.popups.duplicateTask && (
						<DuplicateTaskPopup
							{...this.props}
							onClose={this.closeDuplicatePopup}
							tasks={selectedRows}
							idTasks={this.getSelectedId(this.state.stateTable.selectedRows)}
						/>
					)}
					{this.state.popups.assignResources && (
						<AssignResourcesPopup {...this.props} onClose={this.closeIsochronousPopup} />
					)}
					{this.state.popups.rescheduleTask && (
						<RescheduleTaskPopup
							{...this.props}
							scheduleInit={this.state.scheduleInit}
							onClose={this.closeReschedulePopup}
							tasks={selectedRows}
							idTasks={this.getSelectedId(this.state.stateTable.selectedRows)}
						/>
					)}
					{this.state.popups.generateRoute && (
						<GenerateRoutePopup
							{...this.props}
							onClose={this.closeRoutePopup}
							tasks={selectedRows}
							routeDate={this.state.routeDate}
							idTeam={selectedRows[0].taskIcons.team}
						/>
					)}
					{this.state.popups.errormessage && (
						<ErrorMessage
							{...this.props}
							closeErrorMessage={this.closeErrorMessageAction}
							message={this.state.message}
							showErrorMessage={this.state.popups.errormessage}
							icon={this.state.changeIcon}
						/>
					)}
					{this.state.popups.successmessage && (
						<ErrorMessage
							{...this.props}
							closeErrorMessage={() => this.changePopupState(POPUPNAME.SUCCESS_MESSAGE)}
							message={this.state.message}
							showErrorMessage={this.state.popups.errormessage}
						/>
					)}
					{this.state.popups.cancelRoute && (
						<CancelRoutePopup
							{...this.props}
							onClose={this.closeCancelPopup}
							routes={this.state.stateTable.selectedRows}
							myIdRoutes={this.state.myIdRoutes}
							idAgents={this.state.routesIdAgents}
						/>
					)}
					{this.state.popups.rescheduleRoute && (
						<RescheduleRoutePopup
							{...this.props}
							scheduleInit={this.state.scheduleInit}
							onClose={this.closeRescheduleRoutePopup}
							idRoute={this.state.stateTable.selectedRows[0].id}
							routeName={this.state.stateTable.selectedRows[0].name}
							agent={this.state.stateTable.selectedRows[0].agent}
							currentDate={this.state.stateTable.selectedRows[0].executionFormattedDate}
						/>
					)}
					{this.state.popups.resetRouteTask && (
						<ResetRouteTasksPopup
							{...this.props}
							onClose={this.closeResetRouteTasksPopup}
							routes={this.state.stateTable.selectedRows}
							myIdRoutes={this.state.myIdRoutes}
							idAgents={this.state.routesIdAgents}
						/>
					)}
					{this.state.popups.resetCanceledTask && (
						<ResetCanceledTasksPopup
							{...this.props}
							onClose={this.closeResetCanceledTasksPopup}
							route={this.state.stateTable.selectedRows[0]}
							idRoute={this.state.stateTable.selectedRows[0].id}
						/>
					)}
					{this.state.popups.restartRoute && (
						<ResetRouteDateAgentPopup
							{...this.props}
							scheduleInit={this.state.scheduleInit}
							onClose={this.closeResetRouteDateAgentPopup}
							route={this.state.stateTable.selectedRows[0]}
							idRoute={this.state.stateTable.selectedRows[0].id}
							currentDate={this.state.stateTable.selectedRows[0].executionFormattedDate}
						/>
					)}
					{this.state.popups.reassignRouteAgent && (
						<ReassignRouteAgentPopup
							{...this.props}
							onClose={this.closeReassignRouteAgentPopup}
							route={this.state.stateTable.selectedRows[0]}
							idRoute={this.state.stateTable.selectedRows[0].id}
						/>
					)}
					{this.state.popups.assignForms && (
						<AssignFormPopup
							{...this.props}
							onClose={this.closeAddFormsPopup}
							idTasks={this.getSelectedId(this.state.stateTable.selectedRows)}
							tasks={selectedRows}
						/>
					)}
					{this.state.popups.assignAssets && (
						<AssignAssetPopup {...this.props} onClose={this.closeAddAssetsPopup} tasks={selectedRows} />
					)}
					{this.state.popups.assignInventories && (
						<AssignInventoryPopup
							{...this.props}
							onClose={this.closeAddInventoryPopup}
							idTasks={this.getSelectedId(selectedRows)}
						/>
					)}
					{this.state.popups.createGroupTask && (
						<CreateGroupTasksPopup
							{...this.props}
							onClose={() => this.changePopupState(POPUPNAME.CREATE_GROUP_TASK)}
							setDefaultFalse={this.setDefaultFalse}
							idTasks={this.getSelectedId(this.state.stateTable.selectedRows)}
						/>
					)}
					{this.state.popups.editGroupTask && (
						<EditGroupTasksPopup
							onClose={this.closeEditGroupPopup}
							closeUnlinkGroupPopup={this.closeUnlinkGroupPopup}
							idGroup={this.getSelectedIdGroup(selectedRows)}
							//tasksFromGroup={() => this.getSelectedIdGroupTask(this.state.stateTable.selectedRows)}
						/>
					)}
					{this.state.popups.cancelTask && (
						<CancelTaskPopup
							{...this.props}
							closeErrorMessage={this.closeSimpleModal}
							onCloseModalSuccess={this.closeCancelTaskModalSucess}
							onCloseModalError={this.closeCancelTaskModalFail}
							idTasks={this.getSelectedId(this.state.stateTable.selectedRows)}
							taskInfoRequest={this.state.taskInfoRequest}
						/>
					)}
					{this.state.popups.rateTask && (
						<RateTaskPopup
							{...this.props}
							closeErrorMessage={this.closeSimpleModalRateTask}
							onCloseModalSuccess={this.closeRateTaskModalSuccess}
							onCloseModalError={this.closeRateTaskModalFail}
							idTasks={this.getSelectedId(this.state.stateTable.selectedRows)}
						/>
					)}
					{this.state.popups.assignGroupTask && (
						<AssignGroupTaskPopup
							{...this.props}
							onClose={this.closeGroupTask}
							setDefaultFalseAssign={this.setDefaultFalseAssign}
							idTasks={this.getSelectedId(selectedRows)}
						/>
					)}
					{this.state.showTaskActionPopup && (
						<TaskActionPopup
							{...this.props}
              state={this.state}
              fielderMsg={this.props.fielderMsg}
							showDisabledTasks={this.state.showDisabledTasks}
							showTaskByEmail={this.state.showTaskByEmail}
							selectedTaskCount={this.state.selectedTaskCount}
							changePopupState={this.changePopupState}
							closeTaskAction={this.closeTaskAction}
							restoreTask={this.restoreTask}
							groupTaskFunctionality={this.state.groupTaskFunctionality}
						/>
					)}
					{this.state.showRouteActionPopup && (
						<RouteActionPopup
							{...this.props}
							selectedRouteCount={this.state.selectedRouteCount}
							changePopupState={this.changePopupState}
							closeRouteAction={this.closeRouteAction}
						/>
					)}
					{this.state.showGroupTaskActionPopup && (
						<TaskGroupActionPopup
							{...this.props}
							selectedGroupTaskCount={this.state.selectedGroupTaskCount}
							changePopupState={this.changePopupState}
							closeTaskGroupAction={this.closeTaskGroupAction}
						/>
					)}
					{this.state.confirmationDialog && (
						<ConfirmationDialog
							{...this.props}
							handleAction={this.handlerActionDeleteTasks}
							handleClose={this.closeDeleteConfirmationDialog}
							message={this.state.messageConfirmDeleteTasks}
						/>
					)}
					{this.state.confirmationDialogRestoreTask && (
						<ConfirmationDialog
							{...this.props}
							handleAction={() => this.handlerActionRestoreTasks(0)}
							handleClose={this.closeRestoreConfirmationDialog}
							message={this.state.messageConfirmRestoreTasks}
						/>
					)}
					{this.state.confirmationDialogRoute && (
						<ConfirmationDialog
							{...this.props}
							handleAction={this.handlerActionDeleteRoutes}
							handleClose={this.closeDeleteConfirmationDialogRoute}
							message={this.state.messageConfirmDeleteRoutes}
						/>
					)}
					{this.state.confirmationDialogGroupTask && (
						<ConfirmationDialog
							{...this.props}
							handleAction={this.handlerActionDisableGroupTasks}
							handleClose={this.closeDeleteConfirmationDialogGroupTask}
							message={this.state.messageConfirmDeleteGroupTasks}
						/>
					)}
					{/* Task Details */}
					{this.state.popups.taskdetail && (
						<TaskDetails
							{...this.props}
							ref={this.taskDetailRef}
							onClose={this.handleCloseTaskDetails}
							onCloseModal={this.closeEditTaskPopup}
							idTasks={this.getTaskId()}
							idAgent={this.getSelectedAgents(selectedRows)}
							partnerTaskRelations={
								this.getSelectedPartnerTaskRelation(selectedRows) != null
									? this.getSelectedPartnerTaskRelation(selectedRows)
									: partnerTaskRelationsTaskButton
							}
							executePopupAction={this.changePopupState}
							idAuxDetailMakerTaskId={this.state.idAuxDetailMakerTaskId}
							canNotEdit={false}
							showMenuActions
							showTaskByEmail={this.state.showTaskByEmail}
							showDisabledTasks={this.state.showDisabledTasks}
						/>
					)}
					{/* Task Details */}
					{this.state.popups.parenttaskdetail && (
						<TaskDetails
							{...this.props}
							ref={this.taskDetailRef}
							onClose={() => {
								let pops = this.state.popups;
								pops.parenttaskdetail = false;
								this.setState({
									popups: pops,
								});
							}}
							onCloseModal={this.closeEditTaskPopup}
							idTasks={[selectedRows[0].parentTask]}
							idAuxDetailMakerTaskId={this.state.idAuxDetailMakerTaskId}
							canNotEdit
							showTaskByEmail={this.state.showTaskByEmail}
							showDisabledTasks={this.state.showDisabledTasks}
						/>
					)}
					{/* Edit Task */}
					{this.state.popups.editTask && (
						<EditTask
							{...this.props}
							onClose={() => this.changePopupState(POPUPNAME.EDIT_TASK)}
							idTasks={this.getSelectedId(this.state.stateTable.selectedRows)}
						/>
					)}
					{this.state.popups.routedetail ? (
						<RouteDetails
							{...this.props}
							onClose={() => {
								let pops = this.state.popups;
								pops.routedetail = false;
								this.setState({
									popups: pops,
									idRouteSelected: undefined,
								});
							}}
							idRoute={this.state.idRouteSelected}
						/>
					) : (
						<div className='container-fluid'>
							
							<MyOperationHeaderTitle
								showMap={this.state.showMap}
								handleViewTable={this.handleViewTable}
								handleViewIcon={this.handleViewIcon}
							/>						
								
							<div className='row mx-2 mb-3'>
								<Col sm={12}>
									{this.state.map && (
										<MyOperationMap
											tasks={this.state.markers}
											onTaskClick={this.showDetailTask}
											onAgentClick={this.showDetailAgent}
										/>
									)}
								</Col>
							</div>
							<div className='row'>
								{!this.state.showRoutes && (
									<TaskSwitchHeader 
										showTaskUpdates= {this.state.showTaskUpdates}
										handleTaskShowUpdates ={this.handleTaskShowUpdates}
										groupTaskFunctionality = {this.state.groupTaskFunctionality} 
										canViewTaskGroups = {this.state.canViewTaskGroups}
										showTaskGroupUpdates = {this.state.showTaskGroupUpdates}
										handleTaskGroupShowUpdates = {this.handleTaskGroupShowUpdates}
									/>	
								)}
							</div>
								
							<Row className='row mx-2 mb-3'>
								<div className='card mx-3 container-fluid' bg='white' style={{ width: '100%' }}>
									<Row>
										<MyOperationNavigationBar
											canViewTasks={this.state.canViewTasks}
											options={this.state.options}
											showSearchByForms={this.state.showSearchByForms}
											notificationCount={this.state.notificationCount}
											notificationCountGroup={this.state.notificationCountGroup}
											showRouteOpt={this.state.showRouteOpt}
											canViewRoutes={this.state.canViewRoutes}
											validateTabView={this.validateTabView}
											changeLoadingFilter={this.changeLoadingFilter}
											resetTaskSection={this.resetTaskSection}
											resetRouteSection={this.resetRouteSection}
											resetGroupTaskSection={this.resetGroupTaskSection}
											resetAutoAssignSection={this.resetAutoAssignSection}
											state={this.state}
											groupTaskFunctionality={this.state.groupTaskFunctionality}
											canViewTaskGroups={this.state.canViewTaskGroups}
											autoassign={this.state.autoassign}
											selectedRows = {selectedRows}
										/>			
									</Row>

									{/* Icons */}
									<Row>
										<Col sm={'auto'} className='ml-auto my-3 ml-auto mr-2 pr-0'>
											<div className='row mr-4'>
												{/* SHOW DISABLE TASK AND EMAIL TASK MENU */}
												<TaskMenu 
													options={this.state.options}
													hasMailIntegrationFunctionality={this.state.hasMailIntegrationFunctionality}
													allowManagerToSeeTaskByMail={this.state.allowManagerToSeeTaskByMail}
													showMailCount={this.state.showMailCount}
													changeTasksNormal={this.changeTasksNormal}
													changeTasksToByEmail={this.changeTasksToByEmail}
													changeTasksShowing={this.changeTasksShowing}	
													changeSearchByForms={this.changeSearchByForms}
												/>	
												
												<MyOperationResourceAllocationAndGroupTaskIconMenu
														showDisabledTasks={this.state.showDisabledTasks}
														showTaskByEmail={this.state.showTaskByEmail}
														options={this.state.options}
														showRouteOpt={this.state.showRouteOpt}
														state={this.state}
														changePopupState={this.changePopupState}
														canCreateGroupTasks={this.state.canCreateGroupTasks}
														resetGroupTaskSectionView={this.resetGroupTaskSectionView}
														canGenerateResourceAllocation={this.state.canGenerateResourceAllocation}
														
												/>

												
											</div>
										</Col>
									</Row>

									<Row className='mx-2'>
										<Col lg={4} xs={6} className='ml-4'>
											<Row className={this.state.datesError ? 'Fielder-input-border-alert' : 'Fielder-input-border'}>
												{!this.state.showSearchByForms ? (
													<MyOperationDatePicker
														from={this.state.from}
														handleFromDate={this.handleFromDate}
														to={this.state.to}
														handleToDate={this.handleToDate}
														minTime={this.state.minTime}
														showDayPickerInput={this.showDayPickerInput}
														resetDateValues={this.resetDateValues}
													/>
													) : (
													<MyOperationRangeDatePicker 
														handleFromDateFromForm={this.handleFromDateFromForm}
														handleToDateFromForm={this.handleToDateFromForm}														
													/>
												)}
											</Row>

											{this.state.datesError && (
												<img src={AlertIcon} alt='' id='taskType-alert-icon' className='MyOperation-alert-icon'></img>
											)}
										</Col>

										<Col xs='auto' className='my-auto' >
											<DropdownDatesFilter
												showApplyButton
												disabled={!this.state.to}
												executeFilters={() => {
													this.setState({
														executeLoadMore: !this.state.executeLoadMore,
														showLoading: true,
													});
												}}
												onChangeValues={(values) => this.setState({ datesFilterValues: values })}
											/>
										</Col>

										{/*Advanced filter*/}
										{this.state.options == SELECTION_TASK && (
											<>	
												<Col xs='auto' className='my-auto' >
													<AdvancedFilter
														{...this.props}
														disabled={!this.state.to}
														showApplyButton={true}
														executeAdvancedFilters={(advancedFilter) => {
															this.setState({ advancedFilterValues: advancedFilter, filter: null, tagsFilter: null }, () => {
																this.executeLoadMore();
																if (this.state.showSearchByForms) { 
																	this.changeLoadingFilter(true);
																}
																
															});
														}}
														taskTypes={this.state.taskTypes}
														taskPriority={this.state.taskPriority}
														cookieNameAvancedFilter={cookieNameAvancedFilter}
													/>
												</Col>
													{ this.state.showSearchByForms && (
														<Col xs='auto' className='my-auto' >
															<FormAdvancedFilter
																{...this.props}
																disabled={!this.state.to}
																executeAdvancedFormFilters={this.executeAdvancedFormFilters }
																
															/>
														</Col>
													)}
											</>	
										)}
											



											{this.state.options != SELECTION_TASK && (
												<Col xs={'auto'} className='ml-2 my-auto'>
													<Tooltip
														disableFocusListener={
															this.state.advancedFilterValues != null && this.state.advancedFilterValues.length > 0
																? false
																: true
														}
														disableHoverListener={
															this.state.advancedFilterValues != null && this.state.advancedFilterValues.length > 0
																? false
																: true
														}
														title={this.props.lan.disableAvacedSearch}
														placement='top'
													>
														<Row className='Fielder-input-border'>
															<React.Fragment>
																<div className='col-auto pl-1 pr-0 mt-2'>
																	<img
																		onClick={
																			this.state.advancedFilterValues != null && this.state.advancedFilterValues.length > 0
																				? null
																				: this.handleFilter
																		}
																		src={SearchIcon}
																		className='icon cursor-pointer'
																		alt=''
																	/>
																</div>
																<Col xs={8}>
																	<input
																		className='myoperation-input-select filter-color-hover mt-2 text-left'
																		id='filter'
																		onKeyPress={this.handlerEventKey}
																		disabled={
																			this.state.advancedFilterValues != null && this.state.advancedFilterValues.length > 0
																				? true
																				: false
																		}
																	/>
																</Col>
															</React.Fragment>

															{(this.state.filter != null || this.state.tagsFilter != null) && (
																<div className='mt-2 no-padding col-auto'>
																	<img
																		onClick={this.handleResetFilter}
																		src={ResetFilterIcon}
																		className='icon cursor-pointer'
																		alt=''
																	/>
																</div>
															)}
														</Row>
													</Tooltip>
												</Col>
											)}

											<Col sm='auto' className='ml-auto'>

												{this.state.showLoading && !this.state.showSearchByForms ?
													<Lottie
														options={{
															loop: true,
															autoplay: true,
															animationData: lottie,
														}}
														height={'40px'}
														width={'40px'}
													/>
													:
													<>
														

														{this.state.options == SELECTION_TASK && (
															<Row>
																{this.state.showSearchByForms && this.state.showLoading && (
																	<Lottie
																		options={{
																			loop: true,
																			autoplay: true,
																			animationData: lottie,
																		}}
																		height={'40px'}
																		width={'40px'}
																	/>
																)}
																<>
																	<span className='myoperation-count-title1 mr-1'>{this.props.lan.tasks}</span>
																	<span className='myoperation-count-title2'>{this.state.numberOfElements} </span>
																</>
															</Row>
														)}
														{this.state.options == SELECTION_ROUTE && (
															<>
																<span className='myoperation-count-title1'>{this.props.lan.routes}</span>
																<span className='myoperation-count-title2'>{this.state.sizeRoutes} </span>
															</>
														)}
														{this.state.options == SELECTION_TASKGROUP && (
															<>
																<span className='myoperation-count-title1'>{this.props.lan.groups}</span>
																<span className='myoperation-count-title2'>{this.state.sizeTaskGroup} </span>
															</>
														)}
													</>
												}
											</Col>

									</Row>

									{this.state.showSearchByForms && (
										<Row className="ml-5" style={{color:"#FA7FAB"}}>
											{this.props.lan.pleaseSelectDateOneMonth}
										</Row>
									)}

									{this.state.options == SELECTION_TASK &&
										this.state.numberOfElements <= 0 &&
										(this.state.filter != null ||
											this.state.tagsFilter != null ||
											this.state.advancedFilterValues != null) &&
										this.state.showNotTask && (
											<Row className='d-flex justify-content-center mt-2'>
												<div style={{ color: '#DC5C87' }} className='row'>
													{this.props.lan.didNotFindTasks}
												</div>
											</Row>
										)}

									<Row className='mt-5'>
										<Col className='m-0' sm={12}>
											{this.state.options == SELECTION_ROUTE && this.state.route.elements.length !== 0 && (
												<SmartTable
													{...this.props}
													elements={this.state.route.elements}
													ref={this.smartTable}
													onCheckBoxSelected={this.handleRouteCheck}
													cookieTable={cookieTable}
													showCheckboxComponent={true}
													onClick={this.setRouteLimit}
													cookieName={cookieName}
													showArrangeOptions={true}
													onClickPrev={this.onClickPrev}
													onClickNext={this.onClickNext}
													selected={this.state.selected}
													noPrev={this.state.noPrev}
													height={100}
													onRowClick={this.onRouteRowClick}
													noNext={this.state.noNext}
													customActions={[
														{
															icon: DeleteIcon,
															toolTip: this.props.lan.deleteRouteTooltip,
															action: this.deleteRoute,
														},
													]}
												/>
											)}

											{this.state.options == SELECTION_TASKGROUP && (
												<TaskGroupTab
													setElementsGroupTask={this.setElementsGroupTask}
													from={this.state.from}
													to={this.state.to}
													filter={this.state.filter}
													executeLoadMore={this.state.executeLoadMore}
													refreshSelected={this.state.refreshSelected}
													changeLoadingFilter={this.changeLoadingFilter}
													disabledDetailButton={this.state.disabledDetailButton}
													onRowClickButtonAction={this.onRowClickButtonAction}
													handleCheckTaskGroupTab={this.handleCheckTaskGroupTab}
													deleteGroupTask={this.deleteGroupTask}
													rowToAddTaskGroup={this.state.rowToAddTaskGroup}
													rowToUpdateTaskGroup={this.state.rowToUpdateTaskGroup}
													rowToUpdateStatusTaskGroup={this.state.rowToUpdateStatusTaskGroup}
													addNumberOfElementsTaskGroup={this.addNumberOfElementsTaskGroup}
													showTaskGroupUpdates={this.state.showTaskGroupUpdates}
													addNotificationCountRealGroup={this.addNotificationCountRealGroup}
												/>
											)}

											{this.state.options == SELECTION_TASK && (
												<>
														{!this.state.showSearchByForms ? (
															<TaskTab
																{...this.props}
																setElements={this.setElements}
																from={this.state.from}
																to={this.state.to}
																filter={this.state.filter}
																executeLoadMore={this.state.executeLoadMore}
																tagsFilter={this.state.tagsFilter}
																showDisable={this.state.showDisabledTasks}
																showTaskByEmail={this.state.showTaskByEmail}
																datesFilterValues={this.state.datesFilterValues}
																handleCheckTaskTab={this.handleCheckTaskTab}
																refreshSelected={this.state.refreshSelected}
																setDatesError={this.setDateError}
																changeLoadingFilter={this.changeLoadingFilter}
																// MARKERS ACTIONS
																setMarkers={this.setMarkers}
																setAgentRoute={this.setAgentRoute}
																showDetailTask={this.showDetailTask}
																showDetailAgent={this.showDetailAgent}
																agentsIds={this.state.agentsIds}
																// DELETE RESTORE ACTIONS
																deleteTask={this.deleteTask}
																restoreTask={this.restoreTask}
																// REAL TIME ROW TO UPDATE
																rowToUpdate={this.state.rowToUpdate}
																rowToAdd={this.state.rowToAdd}
																rowToUpdateTaskOnGroup={this.state.rowToUpdateTaskOnGroup}
																rowToDisable={this.state.rowToDisable}
																rowToDelete={this.state.rowToDelete}
																rowToEnable={this.state.rowToEnable}
																showTaskUpdates={this.state.showTaskUpdates}
																agentToUpdate={this.state.agentToUpdate}
																agentToUpdateStatus={this.state.agentToUpdateStatus}
																addNotificationCountReal={this.addNotificationCountReal}
																addNumberOfElements={this.addNumberOfElements}
																//ADVANCED FILTER
																advancedFilterValues={this.state.advancedFilterValues}
																showTaskType={this.state.showTaskType}
																				
																user={this.props.user}
																onRowClickButtonAction={this.onRowClickButtonAction}
																disabledDetailButton={this.state.disabledDetailButton}
															/>
														) : (
																
													
													<TaskTabForm
														{...this.props}
														setElements={this.setElements}
														from={this.state.from}
														to={this.state.to}
														filter={this.state.filter}
														executeLoadMore={this.state.executeLoadMore}
														tagsFilter={this.state.tagsFilter}
														showDisable={this.state.showDisabledTasks}
														showTaskByEmail={this.state.showTaskByEmail}
														datesFilterValues={this.state.datesFilterValues}
														handleCheckTaskTab={this.handleCheckTaskTab}
														refreshSelected={this.state.refreshSelected}
														setDatesError={this.setDateError}
														changeLoadingFilter={this.changeLoadingFilter}
														// MARKERS ACTIONS
														setMarkers={this.setMarkers}
														setAgentRoute={this.setAgentRoute}
														showDetailTask={this.showDetailTask}
														showDetailAgent={this.showDetailAgent}
														agentsIds={this.state.agentsIds}
														// DELETE RESTORE ACTIONS
														deleteTask={this.deleteTask}
														restoreTask={this.restoreTask}
														// REAL TIME ROW TO UPDATE
														rowToUpdate={this.state.rowToUpdate}
														rowToAdd={this.state.rowToAdd}
														rowToUpdateTaskOnGroup={this.state.rowToUpdateTaskOnGroup}
														rowToDisable={this.state.rowToDisable}
														rowToDelete={this.state.rowToDelete}
														rowToEnable={this.state.rowToEnable}
														showTaskUpdates={this.state.showTaskUpdates}
														agentToUpdate={this.state.agentToUpdate}
														agentToUpdateStatus={this.state.agentToUpdateStatus}
														addNotificationCountReal={this.addNotificationCountReal}
														addNumberOfElements={this.addNumberOfElements}
														//ADVANCED FILTER
														advancedFilterValues={this.state.advancedFilterValues}
														advancedFilterFormValues={this.state.advancedFilterFormValues}
														showTaskType={this.state.showTaskType}
																		
														user={this.props.user}
														onRowClickButtonAction={this.onRowClickButtonAction}
														disabledDetailButton={this.state.disabledDetailButton}
														setNumberOfElements={this.setNumberOfElements}
														
														/>
													)}
												</>
												
											)}
											{this.state.options == SELECTION_AUTOASSIGN && <AutoAssignTab {...this.props} />}
											{this.state.createGroupTaskSuccess && (
												<ErrorMessage
													{...this.props}
													icon={this.state.showErrorMessageIcon}
													closeErrorMessage={this.closeShowMessage}
													message={this.state.messageCreateGroupTask}
													showErrorMessage={true}
												/>
											)}
											{this.state.cancelTasksSuccess && (
												<ErrorMessage
													{...this.props}
													icon={this.state.showErrorMessageIcon}
													closeErrorMessage={this.closeShowMessageCancelTask}
													message={this.state.messageCancelTaksSuccess}
													showErrorMessage={true}
												/>
											)}
											{this.state.showNoForms && (
												<ErrorMessage
													{...this.props}
													icon={YellowAlertIcon}
													closeErrorMessage={this.closeNoFormsToDownload}
													message={this.props.lan.noFormsToDownload}
													showErrorMessage={true}
												/>
											)}
											{this.state.showNoFormsImages && (
												<ErrorMessage
													{...this.props}
													icon={YellowAlertIcon}
													closeErrorMessage={this.closeNoFormsImagesToDownload}
													message={this.props.lan.noFormsToDownloadImages}
													showErrorMessage={true}
												/>
											)}
											{this.state.errormessageDelete && (
												<PermanenDeleteMessage
													{...this.props}
													numberSuccess={this.state.numberSuccess}
													numberLinked={this.state.numberLinked}
													closeErrorMessage={this.showMessageDelete}
													showErrorMessage={true}
													messageElement={this.state.messageElement}
													elementName={this.state.elementName}
												/>
											)}
										</Col>
									</Row>
								</div>
							</Row>
						</div>
					)}
				</div>
				<ToastContainer />
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	session: state.utils.session,
	user: state.utils.session.user,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(withRouter(withSnackbar(MyOperation)));
export function withSnackbar(Component) {
    return function WrappedComponent(props) {
		const fielderModal = useModal();
        const fielderMsg = useFielderSnackBar();
        return <Component {...props} fielderMsg={fielderMsg} fielderModal={fielderModal} />;
    }
}
