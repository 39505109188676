import { REQUEST_TYPES } from '@core/constants/taskTypes/taskTypes.requests.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// PUT
export const updateTaskTypesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.UPDATE_TASK_TYPE,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

// POST
export const createTaskTypesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.CREATE_TASK_TYPE,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const checkTaskTypeFunctionalityRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.HAS_TASK_TYPE_FUNCTIONALITY,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const checkTaskTypeFunctionalityPartnerRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		requestType: REQUEST_TYPES.HAS_TASK_TYPE_FUNCTIONALITY,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getTaskTypesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.GET_TASK_TYPE_ELEMENTS,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getTableTaskTypesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.GET_ALL_TASK_TYPE_ELEMENTS,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getTaskTypesPartnerRequest = async (body, onSuccess, onError, loadingConfig) => {

	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASK_TYPE_ELEMENTS,
		...body
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAllTaskTypesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.GET_ALL_TASK_TYPE_ELEMENTS,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getFilteredTaskTypesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.GET_FILTERED_TASK_TYPE_ELEMENTS,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};

// DELETE
export const deleteTaskTypesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		userId: user.idUser,
		requestType: REQUEST_TYPES.DO_DELETE_TASK_TYPES,
		...body,
	};

	return handlePostRequest(API_LINKS.autoAssignTemplateManager, requestBody, onSuccess, onError, loadingConfig);
};
