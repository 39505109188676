import React, { Component } from 'react';

import { getInventoriesByFilterAsyncSelect } from 'FielderUtils/inventory/inventory';
import { getIdToken } from 'FielderUtils/session/Session';
import { connect } from 'react-redux';

import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import Popup from '../../Popup';

import '../MyOperation.css';
import { assignInventoriesToTaskRequest, getTaskInventoriesRequest } from '@core/api/task/taskModules.service';
import TextDetail from '~/TextDetail/TextDetail.component';
import CardComponent from '~/Card/Card.component';
import { Chip, Grid, IconButton } from '@mui/material';
import SubtitleFormInput from '~/SubtitleFormInput/SubtitleFormInput.component';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

class AssignInventoryPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			selectedInventoryIds: [],
			selectedInventories: [],
			assignedInventories: [],
			inventoryIndex: 0,
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
		};
	}

	componentDidMount() {
		this.getInfo();
	}

	getInfo = () => {
		if (this.props.idTasks.length === 1) {
			const body = { idTask: this.props.idTasks[0] };
			const loadingConfig = { dispatch: this.props.dispatch };
			const onSuccess = ({ inventories }) => {
				if (inventories) {
					this.setState({ assignedInventories: inventories });
				}
			};
			getTaskInventoriesRequest(body, onSuccess, null, loadingConfig);
		} else {
			let tasks = [];

			this.props.idTasks.forEach((task) => {
				const body = { idTask: task };
				const loadingConfig = { dispatch: this.props.dispatch };
				const onSuccess = ({ inventories }) => {
					if (inventories) {
						tasks = tasks.concat(inventories);
						this.setState({ assignedInventories: tasks });
					}
				};
				getTaskInventoriesRequest(body, onSuccess, null, loadingConfig);
			});
		}
	};

	AssignedInventories = () =>
		this.state.assignedInventories.map((inventory, i) => (
			<Grid item xs='auto'>
				<Chip
					id={`chp-assigned-inventory-${inventory.id}`}
					label={`${inventory.id} - ${inventory.name}`}
					onDelete={() => this.removeInventory(inventory.id)}
				/>
			</Grid>
		));

	removeInventory = (idToRemove) => {
		let newInventories = this.state.assignedInventories.filter(({ id }) => id !== idToRemove);
		this.setState({ assignedInventories: newInventories })
	};

	getInventoryList = async (event) => {
		if (event.length > 0 || event != null) {
			let inventoryList = await getInventoriesByFilterAsyncSelect(
				event,
				this.state.idAccount,
				getIdToken(),
				true,
				this.props.lan
			);
			return inventoryList;
		}
	};

	setFieldInventory = (inventories) => {
		if (!inventories) {
			this.setState({ selectedInventories: [], selectedInventoryIds: [] });
			return;
		}

		let wasInventoryAdded = inventories.length > this.state.selectedInventoryIds.length;

		let state = this.state;
		state.selectedInventoryIds = [];
		state.selectedInventories = [];

		inventories.forEach(({ value: inventory }) => {
			state.selectedInventories.push({
				id: inventory.id,
				name: inventory.name,
				brand: inventory.brand,
				model: inventory.model,
				type: inventory.type,
				serialNumber: inventory.serialNumber,
				contract: inventory.contract,
			});

			state.selectedInventoryIds.push(inventory.id);
		});

		let isInventoryIndexGreaterThanSelectedInventories =
			this.state.inventoryIndex > 0 && this.state.inventoryIndex >= state.selectedInventories.length;

		if (isInventoryIndexGreaterThanSelectedInventories || wasInventoryAdded) {
			state.inventoryIndex = state.selectedInventoryIds.length - 1;
		}

		this.setState(state);
	};

	assignInventory = () => {
		for (let i = 0; i < this.props.idTasks.length; i++) {
			// get the ids of previously assigned inventories
			let idInventories = this.state.assignedInventories.map((inventory) => inventory.id);
			idInventories = idInventories.concat(this.state.selectedInventoryIds);
			const body = {
				idInventories,
				idTask: this.props.idTasks[i],
			};

			const loadingConfig = { dispatch: this.props.dispatch };
			const onSuccess = () => this.props.onClose(true);
			assignInventoriesToTaskRequest(body, onSuccess, null, loadingConfig);
		}
	};

	advanceInventory = () => this.updateInventoryIndex(1);
	regressInventory = () => this.updateInventoryIndex(-1);
	updateInventoryIndex = (count) => {
		this.setState(({ inventoryIndex: index }) => ({ inventoryIndex: index + count }));
	};

	InventoryCard = () => {

		if (this.state.selectedInventoryIds.length === 0) return null;
		const currentInventory = this.state.selectedInventories[this.state.inventoryIndex];

		return (
			<>
				<Grid item xs={1} sx={{ my: 'auto' }}>
					{this.state.inventoryIndex > 0 && (
						<IconButton onClick={this.regressInventory}>
							<ChevronLeft />
						</IconButton>
					)}
				</Grid>
				<Grid item xs>
					<CardComponent elevation={2} sx={{ p: 3 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<SubtitleFormInput>{this.props.lan.inventory}</SubtitleFormInput>
							</Grid>
							<Grid item xs={4} md={3}>
								<TextDetail label={this.props.lan.id} text={currentInventory.id} />
							</Grid>
							<Grid item xs={8} md={9}>
								<TextDetail label={this.props.lan.name} text={currentInventory.name} />
							</Grid>

							{currentInventory.brand && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.brand} text={currentInventory.brand} />
								</Grid>
							)}
							{currentInventory.model && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.model} text={currentInventory.model} />
								</Grid>
							)}
							{currentInventory.type && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.type} text={currentInventory.type} />
								</Grid>
							)}
							{currentInventory.serialNumber && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.serialNumber} text={currentInventory.serialNumber} />
								</Grid>
							)}
						</Grid>
					</CardComponent>
				</Grid>

				<Grid item xs={1} sx={{ my: 'auto' }}>
					{this.state.inventoryIndex < this.state.selectedInventories.length - 1 && (
						<IconButton onClick={this.advanceInventory}>
							<ChevronRight />
						</IconButton>
					)}
				</Grid>
			</>
		);
	};

	render() {
		return (
			<Popup
				title={this.props.lan.assignInventory}
				icon='add-form-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.save}
				buttonOnClick={this.assignInventory}
			>
				<div>
					<div className='row'>
						<div className='col mx-1'>
							<div className='col infoNewModules-padding'> {this.props.lan.inventories} </div>

							<AsyncSearch
								className='react-select__control full-width mt-1 mb-3 borderNone'
								id='asclt-task-inventories'
								getOptions={this.getInventoryList}
								multiple
								onChange={this.setFieldInventory}
								label={this.props.lan.placeHolderInventory}
								lan={this.props.lan}
							/>
						</div>
					</div>

					<Grid container>
						<this.InventoryCard />
					</Grid>

					{this.props.idTasks.length === 1 && (
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<span className='small-popup-label'>{this.props.lan.inventories}</span>
							</Grid>
							<this.AssignedInventories />
						</Grid>
					)}
				</div>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AssignInventoryPopup);
