import { REQUEST_TYPES } from '@core/constants/asset/asset.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';


export const getAssetsConfigurationRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_ASSET_CONFIG,
		idAccount: user.idAccount,
		accountId: user.idAccount,
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateAssetsConfigurationRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_ASSET_CONFIG,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getAssetsCategoryRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_CATEGORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getAssetsSubCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_CATEGORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getAssetsCategoryById = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_ASSET_CATEGORY_BY_ID,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getAssetsTableRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_ASSETS_TABLE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const deleteAssetsCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_DELETE_ASSET_MGMT_CATEGORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const createAssetCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_CREATE_ASSET_MGMT_CATEGORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const updateAssetCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_ASSET_MGMT_CATEGORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getAssetByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_SPECIFIC_ASSET,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const createAssetRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_CREATE_ASSET,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const updateAssetRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_ASSET,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const deleteAssetImageRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_REMOVE_ASSET_IMAGE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const deleteAssetImageFromTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_REMOVE_ASSET_IMAGE_FROM_TASK,
		idAccount: user.idAccount,
		accountId: body.accountId,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const exportAssetsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_ASSETS_TO_EXPORT,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const importAssetsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_IMPORT_CATEGORY_ASSETS,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const changeStatusAssetsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.CHANGE_ASSET_STATUS,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const deleteAssetsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_DELETE_ASSET,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getTemplateAssetsListRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.GET_ALL_ASSETS,
		idAccount: user.idAccount
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getTaskAssetsReport = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		type: REQUEST_TYPES.GET_ASSETS_FROM_TASKS,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};
export const generateAssetReportRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GENERATE_ASSETS_REPORT,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};
export const uploadAssetCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPLOAD_ASSET_CATEGORY_TEMPLATE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getAssetHTMLRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GET_ASSET_HTML,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};
export const updateAssetsFromTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_TASK_ASSET,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};
export const getXLSXOrHTMLFromAssetRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GET_ASSET_XLSX_OR_HTML_FROM_FILE,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};
