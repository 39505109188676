import React from 'react'

import { getBranchByIdRequest, getBranchesFromCompany } from '@core/api/branch/branch.service';
import { getAssetByIdRequest } from '@core/api/asset/asset.service';
import { createTaskRequest, createAutoAssignTaskRequest } from '@core/api/task/task.service';
import RepetitionDates from 'Constants/RepetitionDates';
import moment from 'moment';
import Geocode from 'react-geocode';
import TextIcon from '@assets/images/txt.svg';
import CsvIcon from '@assets/images/csv.svg';
import XlsIcon from '@assets/images/xls.svg';
import XlsxIcon from '@assets/images/xlsx.svg';
import PdfIcon from '@assets/images/pdf.svg';
import DocIcon from '@assets/images/doc.svg';
import DocxIcon from '@assets/images/docx.svg';
import PptIcon from '@assets/images/ppt.svg';
import PptxIcon from '@assets/images/pptx.svg';
import JpgIcon from '@assets/images/jpg.svg';
import JpegIcon from '@assets/images/jpeg.png';
import PngIcon from '@assets/images/png.svg';
import OdsIcon from '@assets/images/ods.svg';
import OdtIcon from '@assets/images/odt.svg';
import OdpIcon from '@assets/images/odp.svg';
import MsgIcon from '@assets/images/msg.svg';
import Mp4Icon from '@assets/images/mp4.png';
import GifIcon from '@assets/images/gif.svg';
import TifIcon from '@assets/images/tif.png';
import TiffIcon from '@assets/images/tiff.png';
import TxtIcon from '@assets/images/txt.svg';

const request = require('FielderRequestTypes');

export const createTask = (taskInfo, user, dispatch) => {
	
		const newTask = {};
		const task = {};
		if ('assignee' in taskInfo) {
			handleAssignee(taskInfo.assignee, newTask, task);
		} else if ('group' in taskInfo) {
			task.idGroup = taskInfo.group.value.id;
		}
		handleBasicInfo(taskInfo, task);
		handleDates(taskInfo, newTask, task);
		handleBranchClient(taskInfo, task);
		handleVolumeRecurrent(taskInfo.volumeRecurrent, newTask, task);
		if (taskInfo.functionalities) handleFunctionalities(taskInfo.functionalities, newTask);
		task.idAccount = user.idAccount;
		newTask.newTaskInfo.task = task;
		newTask.newTaskInfo.idAccount = user.idAccount;
		const body = {
			clientUser: taskInfo.isClient,
			filesTask: taskInfo.filesTask,
			...newTask,
		};
		const loadingConfig = { dispatch };

	return new Promise((res, rej) => {
		const onSuccess = () => res(true)
		const onError = () => rej(false)
    if ('isAutoAssignTask' in taskInfo && taskInfo.isAutoAssignTask)
		createAutoAssignTaskRequest(body, onSuccess, onError, loadingConfig).then(r => console.log(r));		
	else createTaskRequest(body, onSuccess, onError, loadingConfig).then(r => console.log(r));
	})
};

const handleFunctionalities = (functionalities, newTask) => {
	if (functionalities.assetIds) {
		newTask.idAssets = functionalities.assetIds;
	}

	if (functionalities.inventoryIds) {
		newTask.idInventories = functionalities.inventoryIds;
	}

	if (functionalities.formsInfo) {
		newTask.formsToAdd = functionalities.formsInfo.ids.map((form) => form.id);
		newTask.formsModified = functionalities.formsInfo.modified;
	}
};

const handleVolumeRecurrent = (volumeRecurrent, newTask, task) => {
	if (volumeRecurrent.volumeNumber) {
		newTask.newTaskInfo.tasksNumber = volumeRecurrent.volumeNumber;
	} else if (volumeRecurrent.recurrentInfo) {
		const recurrentDates = [];
		const startTime = moment(task.startTime);
		const { repeatUntil } = volumeRecurrent.recurrentInfo;

		while (startTime.isSameOrBefore(repeatUntil)) {
			recurrentDates.push({
				year: startTime.year(),
				month: startTime.month(),
				day: startTime.date(),
				hour: startTime.hour(),
				minute: startTime.minute(),
			});

			switch (volumeRecurrent.recurrentInfo.repetitionTime) {
				case RepetitionDates.DAILY:
					startTime.add(1, 'd');
					break;
				case RepetitionDates.WEEKLY:
					startTime.add(1, 'w');
					break;
				case RepetitionDates.MONTHLY:
					startTime.add(1, 'M');
					break;
				case RepetitionDates.ANNUALLY:
					startTime.add(1, 'y');
					break;
				case RepetitionDates.WEEKDAYS:
					if (startTime.day() === 5) {
						startTime.add(3, 'd');
					} else {
						startTime.add(1, 'd');
					}
					break;
				case RepetitionDates.QUARTERLY:
					startTime.add(1, 'Q');
					break;
				case RepetitionDates.SEMIANNUALLY:
					startTime.add(6, 'M');
					break;
				default:
					return;
			}
		}
		newTask.newTaskInfo.recurrentDatesMap = recurrentDates;
	}
};

const handleBasicInfo = (taskInfo, task) => {
	console.log(taskInfo);
	task.folio = taskInfo.folio;
	task.caseNumber = taskInfo.caseNumber ? taskInfo.caseNumber : null;
	task.description = taskInfo.description;
	task.status = 0;
	task.severity = taskInfo.priority;
	task.serviceTypeId = taskInfo.taskType.id;
	task.tags = taskInfo.tags.map((tag) => tag.label);
	task.startLocation = { ...taskInfo.location };

	if ('lat' in task.startLocation) {
		// Handling the Typo in the backend
		task.startLocation.latitude = task.startLocation.lat;
		delete task.startLocation.lat;
	}
	if ('lng' in task.startLocation) {
		task.startLocation.longitud = task.startLocation.lng;
		delete task.startLocation.lng;
	}
	if ('longitude' in task.startLocation) {
		task.startLocation.longitud = parseFloat(task.startLocation.longitude);
		delete task.startLocation.longitude;
	}
};

const handleAssignee = (assignee, newTask, task) => {
	if (assignee.agent) {
		task.idAgent = assignee.agent.value.idAgent;
		task.idTeam = assignee.agent.value.idTeam;
	} else if (assignee.team) {
		task.idTeam = assignee.team.value.id;
	} else if (assignee.partner) {
		task.idAccountPartner = assignee.partner.value;
	}
};

const handleBranchClient = (taskInfo, task) => {
	if (taskInfo.branch) {
		task.branch = {
			id: taskInfo.branch.idBranch,
			company: { id: taskInfo.branch.idCompany },
		};
	}

	if (taskInfo.client) {
		const { client } = taskInfo;
		task.user = { ...client };
		task.user.idUser = client.id;

		// Remove unnecessary fields
		delete task.user.id;
		delete task.user.location;
		delete task.user.phone;
	}
};

const handleDates = (taskInfo, newTask, task) => {
	let programmedDate = {};
	let executionDates = {};

	if (taskInfo.programmedDate == null) {
		taskInfo.programmedDate = moment();
	}
	programmedDate = parseDate(taskInfo.programmedDate);

	if (taskInfo.executionDates && taskInfo.executionDates.length > 0) {
		let tempDate = parseDate(taskInfo.executionDates[0]);
		const initialDate = {
			startRangeYear: tempDate.year,
			startRangeMonth: tempDate.month,
			startRangeDay: tempDate.day,
			startRangeHour: tempDate.hour,
			startRangeMinute: tempDate.minute,
		};
		tempDate = parseDate(taskInfo.executionDates[1]);
		const endDate = {
			endRangeYear: tempDate.year,
			endRangeMonth: tempDate.month,
			endRangeDay: tempDate.day,
			endRangeHour: tempDate.hour,
			endRangeMinute: tempDate.minute,
		};
		executionDates = { ...initialDate, ...endDate };

		task.startDateTimeRange = taskInfo.executionDates[0].valueOf();
		task.endDateTimeRange = taskInfo.executionDates[1].valueOf();
	}

	newTask.newTaskInfo = { ...programmedDate, ...executionDates };
	task.startTime = taskInfo.programmedDate.valueOf();
	task.createTime = moment().valueOf();
};

const parseDate = (date) => {
	const parsedDate = {
		year: null,
		month: null,
		day: null,
		hour: null,
		minute: null,
	};

	parsedDate.year = date.year();
	parsedDate.month = date.month();
	parsedDate.day = date.date();
	parsedDate.hour = date.hour();
	parsedDate.minute = date.minute();

	return parsedDate;
};

export const getAssetBranch = (asset, callback, dispatch) => {
	const body = { idAsset: asset }


	const onSuccess = (response) => {
		callback(getBranchSelectElement(response.asset.branch));
	}

	const loadingConfig = { dispatch, config: { allowTopBar: true }}
	getAssetByIdRequest(body, onSuccess, null, loadingConfig)
};

export const getBranchId = (branch, callback, dispatch) => {
	const body = {
		idBranch: branch,
	};

	const onSuccess = (response) => {
		callback(getBranchSelectElement(response.branch));
	}

	const loadingConfig = {dispatch, config: { allowTopBar: true }}
	getBranchByIdRequest(body, onSuccess, null, loadingConfig)
};

const getBranchSelectElement = (branch) => {
	let companyName;
	let idCompany;
	let idCustomBranch = '';

	if (branch.company) {
		idCompany = branch.company.idCustom ? branch.company.idCustom : '';
		companyName = branch.company.name ? branch.company.name : '';
	}

	if (branch.idCustom) {
		idCustomBranch = branch.idCustom;
	}

	return {
		label: `[${branch.id}] ${idCustomBranch} - ${branch.name} ([${idCompany}] - ${companyName})`,
		value: branch,
	};
};

export const verifyAssignee = (assignee, isManager, permissions) => {
	if (!isManager) return true;
  if (isManager && assignee.autoAssignManager === 0 && permissions.allowManagerToGenerateAutoAssignTasks) return true;
  else return assignee.agent != null || assignee.team != null;
};

export const verifyAddress = (address) =>
	address.address !== '' &&
	address.country !== '' &&
	address.timeZone !== '' &&
	address.lat != null &&
	address.lng != null;

const setLocationInfo = (locationInfo, loc) => {
	const address = locationInfo.formatted_address;
	const addressComponents = locationInfo.address_components;

	let state;
	let country;
	let postalCode;

	addressComponents.forEach((component) => {
		if (component.types[0] === 'administrative_area_level_1') {
			state = component.long_name;
		}

		if (component.types[0] === 'country') {
			country = component.long_name;
		}

		if (component.types[0] === 'postal_code') {
			postalCode = component.long_name;
		}
	});

	return {
		address,
		state,
		country,
		postalCode,
	};
};

function range(start, end) {
	if (start === end || end < start) return [start];
	return [start, ...range(start + 1, end)];
}

export const obtainDirectionData = async (location) => {
	const { latitude } = location;
	const { longitude } = location;

	if (latitude != null && longitude != null) {
		return await new Promise((res, rej) =>
			Geocode.fromLatLng(latitude, longitude).then(
				(response) => {
					const locationInfo = setLocationInfo(response.results[0]);
					location = { ...locationInfo, location };
					res(location);
				},
				(error) => {
					rej(null);
				}
			)
		);
	}
	return location;
};

// Can not select days before today and today
export const disabledDate = (current) => current && current < moment().startOf('day');

export const disabledDateTime = (selectedDate) => {
	if (selectedDate) {
		const today = moment();

		if (today.isSame(selectedDate, 'day')) {
			const disabledHours = () => range(0, today.hours() - 1);
			let disabledMinutes = () => range(0, today.minutes());

			if (today.hours() < selectedDate.hours()) {
				disabledMinutes = null;
			}

			return {
				disabledHours,
				disabledMinutes,
			};
		}
	}
};

export const getClientFromUser = (auxUser) => {
	let userLocation = null;

	if (auxUser.location != null) {
		userLocation = { ...auxUser.location };

		userLocation.latitude = parseFloat(auxUser.location.latitude);
		userLocation.longitude = parseFloat(auxUser.location.longitud);
	}

	const clientUser = {
		id: auxUser.idUser,
		name: auxUser.name,
		lastName: auxUser.lastName,
		phone: auxUser.mobileNumber,
		email: auxUser.email,
		language: auxUser.language,
		role: auxUser.role,
		location: userLocation,
	};

	return {
		client: clientUser,
		location: userLocation,
	};
};

export const getBranchesByCompany = (companyId, callback, loadingConfig) => {
	const body = { idCompany: companyId };

	const onSuccess = ({ branches }) => {
		const branchesByCompany = branches.map((branch) => getBranchSelectElement(branch));
		callback(branchesByCompany);
	}

	getBranchesFromCompany(body, onSuccess, null, loadingConfig)
};

export const getSourceFile = (typeFile) => {
	let srcFile = null;
	switch (typeFile) {
		case 'txt':
			return TextIcon;
		case 'csv':
			return CsvIcon;
		case 'xls':
			return XlsIcon;
		case 'xlsx':
			return XlsxIcon;
		case 'pdf':
			return PdfIcon;
		case 'doc':
			return DocIcon;
		case 'docx':
			return DocxIcon;
		case 'ppt':
			return PptIcon;
		case 'pptx':
			return PptxIcon;
		case 'jpg':
			return JpgIcon;
		case 'jpeg':
			return JpegIcon;
		case 'png':
			return PngIcon;
		case 'ods':
			return OdsIcon;
		case 'odt':
			return OdtIcon;
		case 'odp':
			return OdpIcon;
		case 'msg':
			return MsgIcon;
		case 'mp4':
			return Mp4Icon;
		case 'gif':
			return GifIcon;
		case 'tif':
			return TifIcon;
		case 'tiff':
			return TiffIcon;
		default:
			return TxtIcon;
	}
	return srcFile;
};
