import React, { Component } from 'react';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import AssetsIcon from '@assets/icons/assetsm.svg';
import dislikeIcon from '@assets/icons/bad-icon-png.png';
import DropRigth from '@assets/icons/drop-rigth.svg';
import DropdownIcon from '@assets/icons/dropdown.svg';
import FormIcon from '@assets/icons/forms.svg';
import likeIcon from '@assets/icons/good-icon-png.png';
import greyStarIcon from '@assets/icons/grey-star-icon.png';
import InventoryIcon from '@assets/icons/inventory-black.svg';
import sadIcon from '@assets/icons/not-satisfactory-icon-png.png';
import PartnerIcon from '@assets/icons/partner-icon.svg';
import happyIcon from '@assets/icons/satisfactory-icon-png.png';
import yellowStarIcon from '@assets/icons/yellow-star-icon.png';
import { IconButton, Icon, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import ErrorIcon from '@assets/icons/alert-icon.svg';
import Types from 'FielderUtils/forms/types';
import assetConst from 'FielderUtils/roleManagementConst/assetConst';
import formConst from 'FielderUtils/roleManagementConst/formConst';
import inventoryConst from 'FielderUtils/roleManagementConst/inventoryConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from 'moment';
import LoadingConst from 'OldComponents/Loading/LoadingConst';
import MainTable from 'OldComponents/MainTable';
import TextareaAutosize from 'react-autosize-textarea';
import { Row, Col, Form } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import ImgOrient from 'react-fix-image-orientation';
import { connect } from 'react-redux';
import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';
import { isRoleValid } from '../../core/utils/roles/validateRoles';
import EditForm from './EditForm';
import GeneratePdfFromXlsx from './GeneratePdfFromXlsx';
import ReactivateForm from './ReactivateForm';
import RemoveFormPopup from './RemoveFormPopup';
import TaskAssets from './TaskAssets';
import TaskInventories from './TaskInventories';
import './TaskDetails.css';
import UpdateFileMenu from './UpdateFileMenu';
import UpdateDocumentMessage from './UpdateDocumentMessage';
import ExportIcon from '@assets/icons/import-icon.svg';
import ErrorMessage from 'OldComponents/ErrorMessage/ErrorMessage';
import withRouter from '~/withRouter/withRouter.component';
import FielderButton from 'FielderComponents/FielderButton';
import uploadButton from '@assets/icons/export-icon.svg';
import { DeepSpaceGrey } from '@core/theme/baseTheme';
import DowloadFormTemplate from 'OldComponents/Forms/DowloadFormTemplate';

import {
	reactivateFormRequest,
	updateTaskFormPdfRequest,
	uploadTaskFormPdfRequest,
	getXLSXOrHTMLFromFormRequest,
	getFormHTMLRequest,
	getPdfFromHtmlRequest
} from '@core/api/form/form.service';

import {
	getInventoryHTMLRequest,
} from '@core/api/inventory/inventory.service';

import {
	getAssetHTMLRequest
} from '@core/api/asset/asset.service';

import presignedUrlForUpload from '@core/utils/aws/s3/presignedUrlForUpload';

const _ = require('lodash');

const BUCKET_NAME_KEY = 'servicesPdfConverter';
const ACL = 'public-read';
const PDF_SECRECT_KEY = 'ympGWMpn1vt3mcSe';
const ACCEPT_FILES = '.pdf';
/**
 * Contains the Activity content from the {@link TaskDetails} component
 */
class TaskModules extends Component {
	constructor(props) {
		super(props);

		let allowManagerToEditFormTaskDetail = false;
		if (props?.session?.accountPreferences?.allowManagerToEditFormTaskDetail) {
			allowManagerToEditFormTaskDetail = true;
		}

		let allowManagerToApproveForms = false;
		if (props?.session?.accountPreferences?.allowManagerToApproveForms) {
			allowManagerToApproveForms = true;
		}

		let allowManagerToDownloadFormExcelTaskDetail = false;
		if (props?.session?.accountPreferences?.allowManagerToDownloadFormExcelTaskDetail) {
			allowManagerToDownloadFormExcelTaskDetail = true;
		}

		let showPdfConverter = false;
		if ( props?.session?.accountPreferences?.showPdfConverter) { 
			showPdfConverter = true 
		}

		let isAllowedToDownloadForms = isRoleValid(
			formConst.FORMS,
			formConst.DOWNLOAD_TASK_FORM,
			this.props.session.roles
		);

		let isAllowedToViewInventory = isRoleValid(
			inventoryConst.INVENTORY,
			inventoryConst.VIEW,
			this.props.session.roles
		);

		let isAllowedToDeleteTaskForm = isRoleValid(
			formConst.FORMS,
			formConst.DELETE_TASK_FORM,
			this.props.session.roles
		);

		this.state = {
			view: {
				assets: false,
				inventory: false,
				forms: false,
				assetsFields: [],
			},
			forms: [],
			assets: [],
			inventory: [],
			activeInventory: 0,
			activeAsset: 0,
			activeForm: 0,
			formHasImages: false,
			assetHasImages: false,
			inventoryHasImages: false,
			formImages: [],
			showFormFile: {},
			assetImages: [],
			inventoryImages: [],
			formsFunctionalities: {
				excel: false,
				html: false,
			},
			show: {
				editForm: false,
				editAsset: false,
				editInventory: false,
				generatePdfFromXlsx: false,
				reactivateForm: null,
				removeTaskForm: null,
			},
			updated: 0,
			isAllowedToDeleteTaskForm: isAllowedToDeleteTaskForm,
			isAllowedToViewInventory: isAllowedToViewInventory,
			isAllowedToDownloadForms: isAllowedToDownloadForms,
			isAllowedToViewForm:  isRoleValid(formConst.FORMS, formConst.VIEW_TEMPLATES, props.session.roles),
			isAllowedToViewAsset: isRoleValid(assetConst.ASSETS, assetConst.VIEW, props.session.roles),
			isAllowedToEditTask: isRoleValid(formConst.FORMS, formConst.EDIT_TASK_FORM, props.session.roles),

			showPdfConverter: showPdfConverter,
			disablePdfTemplateButton: false,
			showUpdateComponentPdf: false,
			urlFromPdf: null,
			idFilePdf: 0,

			showUpdateMessage: false,
			//Update PDF
			fileUpdate: null,
			fileNameUpdate: null,
			idAccountFileUpdate: null,
			originalIdFile: null,
			originalFileKey: null,
			allowManagerToEditFormTaskDetail: allowManagerToEditFormTaskDetail,
			allowManagerToApproveForms: allowManagerToApproveForms,
			allowManagerToDownloadFormExcelTaskDetail: allowManagerToDownloadFormExcelTaskDetail,

			messageApproved: props.lan.pdfAprovedMessage,
			iconMessage: SuccessIcon,
			viewImport: false,
		};
		
	}


	static getDerivedStateFromProps(nextProps, prevState) {

		let change = {};

		if (nextProps.forms) {
			if (nextProps.forms.length !== prevState.forms.length) {
				change.forms = nextProps.forms;
				change.activeForm = 0;
			}
		}

		if (nextProps.assets) {
			if (nextProps.assets.length !== prevState.assets.length) {
				change.assets = nextProps.assets;
				change.activeAsset = 0;
			}
		}

		if (nextProps.inventories) {
			if (nextProps.inventories.length !== prevState.inventory.length) {
				change.inventory = nextProps.inventories;
				change.activeInventory = 0;
			}
		}

		if (!_.isEmpty(change)) {
			return change;
		} else if (nextProps.updated != prevState.updated) {
			return {
				updated: nextProps.updated,
				forms: nextProps.forms,
				assets: nextProps.assets,
				inventory: nextProps.inventories,
			};
		}
		return null;
	}

	checkCustomTemplate(forms) {
		let xlsxFunc = false;
		let htmlFunc = false;

		let state = this.state;

		if (forms.templatePath) {
			switch (forms.templateType) {
				case 'xlsx':
					xlsxFunc = true;
					break;
				case 'html':
					htmlFunc = true;
					break;
				default:
					break;
			}
		}

		state.formsFunctionalities = {
			excel: xlsxFunc,
			html: htmlFunc,
		};

		this.setState(state);
	}

	checkPdfUpload = (forms) => {
		let disablePdfTemplateButton = false;
		let showUpdateComponentPdf = false;
		let urlFromPdf = null;
		let idFilePdf = 0;

		if (this.props.taskData && this.props.taskData.serviceFilesPdf) {
			for (let i = 0; i < this.props.taskData.serviceFilesPdf.length; i++) {
				let serviceFilesPdf = this.props.taskData.serviceFilesPdf[i];
				if (serviceFilesPdf.idTaskForm == forms.idTaskForm) {
					disablePdfTemplateButton = true;
					showUpdateComponentPdf = true;
					urlFromPdf = serviceFilesPdf.fileUrl;
					idFilePdf = serviceFilesPdf.idFilePdf;
					break;
				}
			}
		}
		let state = this.state;
		state.disablePdfTemplateButton = disablePdfTemplateButton;
		state.showUpdateComponentPdf = showUpdateComponentPdf;
		state.urlFromPdf = urlFromPdf;
		state.idFilePdf = idFilePdf;
		this.setState(state);
	};

	changeView = (viewName) => {
		var view = { ...this.state.view };

		if (viewName === 'forms' && this.state.forms.length > 0) {
			view.forms = !view.forms;
			view.assets = false;
			view.inventory = false;
			this.changeActiveForm(0);
		} else if (viewName === 'assets' && this.state.assets.length > 0) {
			view.assets = !view.assets;
			view.forms = false;
			view.inventory = false;
			this.changeActiveAsset(0);
		} else if (viewName === 'inventory' && this.state.inventory.length > 0) {
			view.inventory = !view.inventory;
			view.assets = false;
			view.forms = false;
			this.changeActiveInventory(0);
		}

		this.setState({ view });
	};

	getField = (type, i, from) => {
		var field = '';
		if (type == 2 && this.props[from].fields[i] !== undefined && this.props[from].fields[i] !== null) {
			return (
				<div key={from + i} className='col'>
					<div key={from + '-div' + i} className='row TaskModules-labelField'>
						{this.props[from].fields[i].title}
					</div>
					<Form.Control
						disabled
						className='row'
						type='text'
						placeholder={this.props[from].fields[i].text}
					></Form.Control>
				</div>
			);
		} else if (type == 1 && this.props[from].fields[i] !== undefined && this.props[from].fields[i] !== null) {
			for (var j = 0; j < this.props[from].fields[i].options.length; j++) {
				if (this.props[from].fields[i].options[j].checked) {
					field = this.props[from].fields[i].options[j].name;
					break;
				}
			}
			return (
				<div key={from + i} className='col'>
					<div key={from + '-div' + i} className='row TaskModules-labelField'>
						{this.props[from].fields[i].title}
					</div>
					<Form.Control disabled className='row' type='text' placeholder={field}></Form.Control>
				</div>
			);
		}
	};


	isPartner = () => {
		let form = this.props.forms[this.state.activeForm];
		let isNotPartner = false;
		if (form && form.accountId != this.props.session.user.idAccount) {
			isNotPartner = true;
		}
		return isNotPartner;
	}

	isNotPartner = () => {
		let form = this.props.forms[this.state.activeForm];
		let isNotPartner = false;
		if (form && form.accountId == this.props.session.user.idAccount) {
			isNotPartner = true;
		}
		return isNotPartner;
	}

	validateShowDowloadPDFConverter = () => {
		let form = this.props.forms[this.state.activeForm];

		let validateShowDowloadPDFConverter = false;
		if (form && form.accountId != this.props.session.user.idAccount && this.state.disablePdfTemplateButton == false) {
			return false;
		} 
		return true;
	}

	validateManagerGeneratePdf = () => {
		if (RoleUtils.isUserManager(this.props.session.user) && this.props?.session?.accountPreferences?.allowManagerToGeneratePdf) { 
			return true;
		}
		return false;
	}
	

	createFields = (from) => {
		let assetsFields = [];
		let typeField;
		let typeField2;

		if (this.props[from] !== null) {
			for (var i = 0; i < this.props[from].fields.length; i += 2) {
				typeField = this.props[from].fields[i].type;

				if (i + 1 < this.props[from].fields.length) {
					typeField2 = this.props[from].fields[i + 1].type;

					assetsFields.push(
						<div key={from + '-main-' + i} className='row'>
							{this.getField(typeField, i, from)}
							{i + 1 < this.props[from].fields.length ? this.getField(typeField2, i + 1, from) : null}
						</div>
					);
				}
			}
		}
		return assetsFields;
	};

	getDate = (question) => {
		if (question.date) {
			let timeZone = this.props.taskData.startLocation.timeZone;
			question.dateFormat.replace('dd', 'DD');
			return moment(question.date).tz(timeZone).format('DD/MM/YYYY hh:mm');
		}

		return '';
	};

	getFieldQuestion = (question) => {
		var text = '';

		switch (parseInt(question.type)) {
			case Types.MULTIPLE_CHOICE:
				let selected = [];
				question.options.some((opt) => {
					if (opt.checked === true) {
						selected.push(opt.name);

						// Check if checkbox or radio button
						if (question.singleAnswer === true) {
							return true;
						}
					}
				});

				text = selected.join(', ');
				break;
			case Types.TEXT_FIELD:
				text = question.text;
				break;
			case Types.NUMERIC_RATING:
				text = question.value;
				break;
			case Types.BINARY_RATING:
				if (question.iconType === 'FINGERS') {
					question.value === true ? (text = likeIcon) : (text = dislikeIcon);
				} else {
					question.value === true ? (text = happyIcon) : (text = sadIcon);
				}
				break;
			case Types.STARS_RATING:
				text = question.value;
				break;
			case Types.ELECTRONIC_SIGNATURE:
				text = question.file;
				break;
			case Types.FILES:
				if (question.enableMetadata) {
					let timeZone = 'x';
					if (question.metaData && question.metaData.timeZone) {
						timeZone = question.metaData.timeZone;
					}

					text = (
						<React.Fragment>
							<div className='row ml-1'>
								<ImgOrient style={{ maxWidth: '418px', maxHeight: '350px', transform: 'none' }} src={question.file} />
							</div>
							<div className='row  mt-2'>
								<div className='col-4 pl-0 d-flex justify-content-center' style={{ fontWeight: 'bold' }}>
									{this.props.lan.latitude}
								</div>
								<div className='col-4 d-flex justify-content-center' style={{ fontWeight: 'bold' }}>
									{this.props.lan.longitude}
								</div>
								<div className='col d-flex justify-content-center' style={{ fontWeight: 'bold' }}>
									{this.props.lan.date}
								</div>
							</div>
							<div className='row mb-3'>
								<div className='col-4 pl-0  d-flex justify-content-center'>
									{question.metaData ? question.metaData.latitude : this.props.lan.noData}
								</div>
								<div className='col-4  d-flex justify-content-center'>
									{question.metaData ? question.metaData.longitude : this.props.lan.noData}
								</div>
								<div className='col  d-flex justify-content-center'>
									{question.metaData
										? moment.tz(question.metaData.creationDate, timeZone).format('DD-MM-YYYY HH:mm')
										: this.props.lan.noData}
								</div>
							</div>
						</React.Fragment>
					);
				} else {
					text = <ImgOrient style={{ maxWidth: '418px', maxHeight: '350px' }} src={question.file} />;
				}
				break;
			case Types.CODEBAR_IMG:
				text = question.file;
				break;
			case Types.CODEBAR_TEXT:
				text = question.value;
				break;
			case Types.DATE_HOUR:
				text = question.parsedDate ? question.parsedDate : this.getDate(question);
				if (text != null) {
					text = text.replace('January', this.props.lan.january);
					text = text.replace('February', this.props.lan.february);
					text = text.replace('March', this.props.lan.march);
					text = text.replace('April', this.props.lan.april);
					text = text.replace('May', this.props.lan.may);
					text = text.replace('June', this.props.lan.june);
					text = text.replace('July', this.props.lan.july);
					text = text.replace('August', this.props.lan.august);
					text = text.replace('September', this.props.lan.september);
					text = text.replace('October', this.props.lan.october);
					text = text.replace('November', this.props.lan.november);
					text = text.replace('December', this.props.lan.december);
				} else {
					text = '';
				}
				break;
			case Types.TEXT_FILE:
				if (question.file) {
					if (this.state.showFormFile[question.id]) {
						text = (
							<TextareaAutosize
								type='text'
								readOnly
								className={'task-details-input'}
								value={this.state.showFormFile[question.id]}
								style={{
									width: '100%',
									paddingTop: '5px',
									paddingLeft: '10px',
									paddingBottom: '5px',
									paddingRight: '10px',
								}}
							/>
						);
					} else {
						text = (
							<button className='Fielder-button-clear' onClick={() => this.getTextFile(question)}>
								{this.props.lan.viewFileContent}
							</button>
						);
					}
				} else {
					text = <button className='Fielder-button-clear-disabled'>{this.props.lan.noFileFound}</button>;
				}
				break;
			case Types.CATALOGUE:
				let columns = [
					{ id: 'id', padding: '' },
					{ id: 'option', padding: '' },
				];

				if (question.includeValue) {
					columns.push({
						id: 'value',
						name: this.props.lan.value,
						padding: '',
					});
				}

				if (question.includeComment) {
					columns.push({
						id: 'comment',
						name: this.props.lan.comment,
						padding: '',
					});
				}

				let rows = question.options
					.map((opt) => {
						if (opt.checked) {
							return {
								id: opt.id,
								option: opt.name,
								value: opt.value ? opt.value : '',
								comment: opt.comment ? opt.comment : '',
							};
						}
					})
					.filter((n) => n);

				return (
					<Row className='mt-4'>
						<Col sm={10} className='ml-auto mr-5'>
							<Row>
								<Col sm={3} style={{ wordWrap: 'break-word' }}>{question.title}</Col>
								<Col sm={9} className='dashed-box-border p-3'>
									{rows && rows.length > 0 && (
										<MainTable dense disabled fullItemsHeight cols={columns} rows={rows} height={500} {...this.props} />
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				);
			default:
				text = '';
				break;
		}

		// delete null spaces
		if (text == null) {
			text = '';
		}

		if (parseInt(question.type) == Types.ELECTRONIC_SIGNATURE || parseInt(question.type) == Types.CODEBAR_IMG) {
			return (
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3} style={{ wordWrap: 'break-word' }}>{question.title}</Col>
							<Col sm={9}>
								<div className='dashed-box-border'>
									{text != '' ? (
										<img
											src={text}
											className='mx-auto d-block my-4'
											style={{ maxWidth: '100%', maxHeight: '400px' }}
											alt=''
										/>
									) : (
										''
									)}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		} else if (parseInt(question.type) == Types.FILES) {
			return (
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col style={{ height: '400px', wordWrap: 'break-word' }} sm={3}>
								{question.title}
							</Col>
							<Col style={{ height: '400px' }} sm={9}>
								<div className='task-details-input-photo' style={{ height: '400px' }}>
									{text != '' ? text : ''}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		} else if (parseInt(question.type) == Types.STARS_RATING) {
			let stars = [];
			for (var i = 0; i < 5; i++) {
				if (i < text) {
					stars.push(
						<div className='ml-2'>
							{' '}
							<img src={yellowStarIcon} style={{ maxHeight: '25px' }} alt='' />{' '}
						</div>
					);
				} else {
					stars.push(
						<div className='ml-2'>
							{' '}
							<img src={greyStarIcon} style={{ maxHeight: '25px' }} alt='' />{' '}
						</div>
					);
				}
			}

			return (
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3} style={{wordWrap: 'break-word'}}>{question.title}</Col>
							<Col sm={9}>
								<div
									className='task-details-input-photo row ml-1'
									style={{ maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px' }}
								>
									{stars}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		} else if (parseInt(question.type) == Types.BINARY_RATING) {
			return (
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3} style={{wordWrap: 'break-word'}}>{question.title}</Col>
							<Col sm={9}>
								<div
									className='task-details-input-photo row ml-1'
									style={{ maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px' }}
								>
									<div className='ml-3'>
										<img src={text} style={{ maxHeight: '25px' }} alt='' />
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		} else if (parseInt(question.type) == Types.TEXT_FILE) {
			return (
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3} style={{wordWrap: 'break-word'}} >{question.title}</Col>
							<Col sm={9}>
								<div className='ml-3'>{text}</div>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		} else {
			return (
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3} style={{wordWrap: 'break-word'}} >{question.title}</Col>
							<Col sm={9}>
								<TextareaAutosize
									type='text'
									readOnly
									className={'task-details-input'}
									value={text}
									style={{
										width: '100%',
										paddingTop: '5px',
										paddingLeft: '10px',
										paddingBottom: '5px',
										paddingRight: '10px',
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		}
	};

	getTextFile = async (question) => {
		let getTextFromFile = () => {
			return new Promise((res, rej) => {
				let request = new XMLHttpRequest();
				request.open('GET', question.file, true);
				request.send();
				request.onreadystatechange = () => {
					if (request.readyState === 4 && request.status == '200') {
						let type = request.getResponseHeader('Content-Type');
						if (type.indexOf('text') != 1) {
							res(request.response);
						}
					}
				};
			});
		};
		let text = await getTextFromFile();
		let state = this.state;
		state.showFormFile[question.id] = text;
		this.setState(state);
	};

	createFieldsForm = () => {
		let fields = this.state.forms[this.state.activeForm].fields;
		var formsFields = [];

		fields.forEach((field, i) => {
			formsFields.push(
				<div key={'Forms-row-' + i} className='mt-4'>
					{this.getFieldQuestion(field.question)}
				</div>
			);
		});

		return (
			<div>
				{/* Client/Branch Name */}
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={'auto'}>
								<b>{this.props.lan.questions}</b>
							</Col>
							<Col>
								<hr />
							</Col>
						</Row>
					</Col>
				</Row>
				{formsFields}
			</div>
		);
	};

	getFieldInventory = (type, j, i, from) => {
		if (
			type == 2 &&
			this.props.inventories[i].fields[j] !== undefined &&
			this.props.inventories[i].fields[j] !== null
		) {
			return (
				<div key={from + j + '' + i} className='col'>
					<div className='row TaskModules-labelField'>{this.props.inventories[i].fields[j].title}</div>
					<Form.Control
						disabled
						className='row'
						type='text'
						placeholder={this.props.inventories[i].fields[j].text}
					></Form.Control>
				</div>
			);
		} else if (
			type == 1 &&
			this.props.inventories[i].fields[j] !== undefined &&
			this.props.inventories[i].fields[j] !== null
		) {
			var field = '';
			for (var k = 0; k < this.props.inventories[i].fields[j].options.length; k++) {
				if (this.props.inventories[i].fields[j].options[k].checked) {
					field = this.props.inventories[i].fields[j].options[k].name;
					break;
				}
			}
			return (
				<div key={from + j + '' + i} className='col'>
					<div className='row TaskModules-labelField'>{this.props.inventories[i].fields[j].title}</div>
					<Form.Control disabled className='row' type='text' placeholder={field}></Form.Control>
				</div>
			);
		}
	};

	getQuestionInventory = (type, j, i, from) => {
		if (
			type == 2 &&
			this.props.inventories[i].questions[j] !== undefined &&
			this.props.inventories[i].questions[j] !== null
		) {
			return (
				<div key={from + j + '' + i} className='col'>
					<div className='row TaskModules-labelField'>{this.props.inventories[i].questions[j].title}</div>
					<Form.Control
						disabled
						className='row'
						type='text'
						placeholder={this.props.inventories[i].questions[j].text}
					></Form.Control>
				</div>
			);
		} else if (
			type == 1 &&
			this.props.inventories[i].questions[j] !== undefined &&
			this.props.inventories[i].questions[j] !== null
		) {
			var field = '';
			for (var k = 0; k < this.props.inventories[i].questions[j].options.length; k++) {
				if (this.props.inventories[i].questions[j].options[k].checked) {
					field = this.props.inventories[i].questions[j].options[k].name;
					break;
				}
			}
			return (
				<div key={from + j + '' + i} className='col'>
					<div className='row TaskModules-labelField'>{this.props.inventories[i].questions[j].title}</div>
					<Form.Control disabled className='row' type='text' placeholder={field}></Form.Control>
				</div>
			);
		}
	};

	getFieldsInventory = (i, from) => {
		var typeField,
			inventoryFields = [];
		for (var j = 0; j < this.props.inventories[i][from].length; j += 2) {
			typeField = this.props.inventories[i][from][j].type;
			inventoryFields.push(
				<div key={from + j + '' + i} className='row'>
					{this.getFieldInventory(typeField, j, i, 'inventory')}
					{j + 1 < this.props.inventories[i][from].length && this.getFieldInventory(typeField, j + 1, i, 'inventory')}
				</div>
			);
		}
		return inventoryFields;
	};

	getQuestionsInventory = (i, from) => {
		var typeField,
			inventoryFields = [];
		for (var j = 0; j < this.props.inventories[i][from].length; j += 2) {
			typeField = this.props.inventories[i][from][j].type;
			inventoryFields.push(
				<div key={from + j + '' + i} className='row'>
					{this.getQuestionInventory(typeField, j, i, 'inventory')}
					{j + 1 < this.props.inventories[i][from].length &&
						this.getQuestionInventory(typeField, j + 1, i, 'inventory')}
				</div>
			);
		}
		return inventoryFields;
	};

	createNavBar = () => {
		var inventory = [],
			contentInventory = [];
		if (this.props.inventories !== null)
			for (var i = 0; i < this.props.inventories.length; i++) {
				inventory.push(
					<a
						key={'NavBar' + i}
						className='nav-link TaskModules-scrollL-a'
						id={'v-pills-inventory-tab-' + i}
						data-toggle='pill'
						href={'#v-pills-inventory-' + i}
						role='tab'
						aria-controls={'v-pills-inventory-' + i}
						aria-selected={i == 0 ? 'true' : 'false'}
					>
						{this.props.inventories[i].name.substring(0, 20)}
					</a>
				);
				contentInventory.push(
					<div
						key={'ContNav' + i}
						className={i == 0 ? 'tab-pane fade show' : 'tab-pane fade show active'}
						id={'v-pills-inventory-' + i}
						role='tabpanel'
						aria-labelledby={'v-pills-inventory-tab-' + i}
					>
						<div className='row TaskModules-labelField'>{this.props.lan.category}</div>
						<Form.Control
							disabled
							className='row'
							type='text'
							placeholder={this.props.inventories[i].category.name}
						></Form.Control>
						<div className='row TaskModules-labelField'>{this.props.lan.name}</div>
						<Form.Control
							disabled
							className='row'
							type='text'
							placeholder={this.props.inventories[i].name}
						></Form.Control>
						<div className='row TaskModules-labelField'>{this.props.lan.brand}</div>
						<Form.Control
							disabled
							className='row'
							type='text'
							placeholder={this.props.inventories[i].brand}
						></Form.Control>
						<div className='row TaskModules-labelField'>{this.props.lan.model}</div>
						<Form.Control
							disabled
							className='row'
							type='text'
							placeholder={this.props.inventories[i].model}
						></Form.Control>
						<div className='row TaskModules-labelField'>{this.props.lan.serialNumber}</div>
						<Form.Control
							disabled
							className='row'
							type='text'
							placeholder={this.props.inventories[i].serialNumber}
						></Form.Control>

						{this.getFieldsInventory(i, 'fields')}
						{this.getQuestionsInventory(i, 'questions')}
					</div>
				);
			}
		return (
			<div className='row mt-3 TaskModules-row'>
				<div className='col-3 TaskModules-scrollL'>
					<div className='nav flex-column nav-pills' id='v-pills-tab' role='tablist' aria-orientation='vertical'>
						{inventory}
					</div>
				</div>
				<div className='col TaskModules-scrollR'>
					<div className='tab-content' id='v-pills-tabContent'>
						{contentInventory}
					</div>
				</div>
			</div>
		);
	};

	getQuestionAsset = () => {
		var type = this.props.assets.question.type;
		var from = 'assets';
		if (type === 2 && this.props[from].question !== undefined && this.props[from].question !== null) {
			return this.props[from].question.text;
		} else if (type === 1 && this.props[from].question !== undefined && this.props[from].question !== null) {
			var field = '';
			for (var j = 0; j < this.props[from].question.options.length; j++) {
				if (this.props[from].question.options[j].checked) {
					field = this.props[from].question.options[j].name;
					break;
				}
			}
			return field;
		} else {
			return '';
		}
	};

	hasFinishForms = () => {
		if (this.state.forms && this.state.forms.length >0) {
			for (let i = 0; i < this.state.forms.length; i++) {
				if(this.state.forms[i].status == 'FINISHED' || this.props.allowGenerateFormsIfTaskStatusWasModify)
				return true
			}
		}
		return false;
	}

	generateModulesZip = async () => {
		let zip = new JSZip();

		let total = this.state.forms.length;
		total += this.state.inventory ? this.state.inventory.length : 0;
		total += this.state.assets ? this.state.assets.length : 0;

		let progressFactor = Math.round(100 / total);
		let progressCount = 0;

		if (this.state.forms && this.state.forms.length >0) {
			for (let i = 0; i < this.state.forms.length; i++) {
				if (this.state.forms[i].status == 'FINISHED' || this.props.allowGenerateFormsIfTaskStatusWasModify ) {
					progressCount++;
					let formFile = await this.generateCustomPDFFromHtml(this.state.forms[i], null, null, false);
					await this.setFileToZip(formFile, this.props.lan.form + '-' + this.state.forms[i].name, zip);


					this.props.dispatch(loadingStart({
						name: this.props.pageName,
						config: {
							icon: LoadingConst.EXPORT,
							text: this.props.lan.exportingModules,
							progress: progressFactor * progressCount
						}
					}));
				}
			}
		}

		if (this.state.assets) {
			for (let i = 0; i < this.state.assets.length; i++) {
				progressCount++;
				let assetFile = await this.generateCustomPDFFromHtml(null, this.state.assets[i], null, false);
				await this.setFileToZip(assetFile, this.props.lan.asset + '-' + this.state.assets[i].name, zip);

				this.props.dispatch(loadingStart({
					name: this.props.pageName,
					config: {
						icon: LoadingConst.EXPORT,
						text: this.props.lan.exportingModules,
						progress: progressFactor * progressCount
					}
				}));
			}
		}

		if (this.state.inventory) {
			for (let i = 0; i < this.state.inventory.length; i++) {
				progressCount++;
				let inventoryFile = await this.generateCustomPDFFromHtml(null, null, this.state.inventory[i], false);
				await this.setFileToZip(inventoryFile, this.props.lan.inventory + '-' + this.state.inventory[i].name, zip);

				this.props.dispatch(loadingStart({
					name: this.props.pageName,
					config: {
						icon: LoadingConst.EXPORT,
						text: this.props.lan.exportingModules,
						progress: progressFactor * progressCount
					}
				}));
			}
		}

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, this.props.taskData.idFielder + '-' + this.props.lan.modulesZipSuffix);
			this.props.dispatch(loadingStop({ name: this.props.pageName }));
		});
	};

	setFileToZip = async (fileLink, fileName, zip) => {
		await new Promise((resolve, reject) => {
			const fileLinkAux = fileLink.split(process.env.FIELDER_CONTENT_LINK);
			const archiveUrl = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${fileLinkAux[1]}`;

			JSZipUtils.getBinaryContent(archiveUrl, (err, data) => {
				let newFileName = this.props.taskData.idFielder + '-' + fileName + '.pdf';

				// Add File To Zip
				zip.file(newFileName, data, { binary: true });
				resolve();
			});
		});
	};

	generatePDF = () => {
		var form = this.state.forms[this.state.activeForm];
		// Check for Custom pdf functionality
		this.generateCustomPDFFromHtml(form, null, null, true);
	};

	generateInventoryPDF = () => {
		var inventory = this.state.inventory[this.state.activeInventory];
		this.generateCustomPDFFromHtml(null, null, inventory, true);
	};

	redirectToMarketPlace = () => {
		this.props.navigate(ENDPOINTS.marketplace);
	};

	generateXlsx = async (generatePdfFromXlsx) => {
		this.props.dispatch(loadingStart({ name: this.props.pageName }));
		let form = this.state.forms[this.state.activeForm];

		const body = {
			form: form,
			idTask: this.props.taskData.idFielder,
			task: this.props.taskData,
			agent: this.props.taskAgent,
			idCompany: this.props.taskData.branch?.idCompany
		};

		const onSuccess = (response) => {
			if (generatePdfFromXlsx) {
				let state = this.state;
				state.show.generatePdfFromXlsx = true;
				state.urlGeneratePdfFromXlsx = response.link;
				this.setState(state);
				this.props.dispatch(loadingStop({ name: this.props.pageName }));
			} else {
				this.props.dispatch(loadingStop({ name: this.props.pageName }));
				window.open(response.link);
			}
		}

		const onError = (error) => {
			this.props.dispatch(loadingStop({ name: this.props.pageName }));
		}

		getXLSXOrHTMLFromFormRequest(body, onSuccess, onError, null);

	};

	uploadTaskFormPdf = async (form, formFileUrl, conversionCost) => {
		const body = {
			conversionCost: conversionCost,
			serviceFilePdf: {
				idService: this.props.taskData.idFielder,
				idTaskForm: form.idTaskForm,
				documentName: `${this.props.taskData.idFielder}-${form.name}.pdf`,
				fileUrl: formFileUrl,
			}
		};

		await new Promise((resolve, reject) => {
			this.props.dispatch(loadingStart({ name: this.props.pageName }));

			const onSuccess = (response) => {
				this.props.reloadData();
				resolve(response);
			}

			const onError = (error) => {
				reject();
			}

			uploadTaskFormPdfRequest(body, onSuccess, onError, null)
		});

		let state = this.state;
		state.messageApproved = this.props.lan.pdfAprovedMessage;
		state.iconMessage = SuccessIcon;
		state.show.generatePdfFromXlsx = false;
		state.showMessageSuccessAprove = true;
		state.view.forms = false;
		this.setState(state);

		this.props.dispatch(loadingStop({ name: this.props.pageName }));
	};

	approveDocument = async (link) => {
		if (link != null && link != '') {
			this.props.dispatch(loadingStart({ name: this.props.pageName }));

			const activeForm = this.state.forms[this.state.activeForm];
			let convertApi = ConvertApi.auth({ secret: process.env.PDF_SECRECT_KEY });
			let params = convertApi.createParams();

			params.add('file', new URL(link));

			let result = await convertApi.convert('xlsx', 'pdf', params);
			
			if (result && result.files && result.files[0].Url) {
				let url = result.files[0].Url;
				let conversionCost = result.dto.ConversionCost;

				let bitesUrl = await new Promise((resolve) => {
					this.getBase64FromUrl(url).then((res) => resolve(res));
				});

				this.dataURLToFile(bitesUrl).then(async (file) => {
					if (file instanceof Error) {
						this.showErrorMessageApprovedLink();
						this.props.dispatch(loadingStop({ name: this.props.pageName }));
					} else {
						const formFileUrl = await this.uploadToS3(file, activeForm, this.props.idAccountTask, this.props.session.user.idUser);
						if (formFileUrl != null) {
							this.uploadTaskFormPdf(activeForm, formFileUrl, conversionCost);
						}else {
							this.showErrorMessageApprovedLink();
							this.props.dispatch(loadingStop({ name: this.props.pageName }));
						}	
					}
				});
			} else{
				this.showErrorMessageApprovedLink();
				this.props.dispatch(loadingStop({ name: this.props.pageName }));
			}
		} else {
			this.showErrorMessageApprovedLink();
		}
	};

	showErrorMessageApprovedLink = () => {
		let state = this.state;
		state.messageApproved = this.props.lan.pdfApprovedMessageError;
		state.iconMessage = ErrorIcon;
		state.show.generatePdfFromXlsx = false;
		state.showMessageSuccessAprove = true;
		state.view.forms = false;
		this.setState(state);
	}

	getBase64FromUrl = async (url) => {
		const data = await fetch(url);
		const blob = await data.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result;
				resolve(base64data);
			};
		});
	};

	dataURLToFile = (base64) => {
		return fetch(base64)
			.then((res) => res.arrayBuffer())
			.then((buf) => new File([buf], 'filename', { type: 'application/pdf' }));
	};

	uploadToS3 = (file, currentForm, accountId, idUser) => {
		const bucket = `${BUCKET_NAME_KEY}/${accountId}/${this.props.taskData.idFielder}`;
		const key = `${currentForm.id}-form-pdf`;
		return presignedUrlForUpload(bucket, key, file, { contentType: file.type, acl: ACL }, idUser);
	};

	uploadToS3WithDate = (file, currentForm, accountId, date ) => {
		const bucket = `${BUCKET_NAME_KEY}/${accountId}/${this.props.taskData.idFielder}`;
		const key = `${currentForm.id}-${date}-form-pdf`;
		return presignedUrlForUpload(bucket, key, file, { contentType: file.type, acl: ACL }, idUser);
	};

	closeErrorMessageAprove = () => {
		let state = this.state;
		state.showMessageSuccessAprove = false;
		this.setState(state);
	};

	closeErrorMessageCancel = () => {
		let state = this.state;
		state.showMessageCancelAprove = false;
		this.setState(state);
	};

	updateFileHandler = (event, idAccount, idFile, fileUrl) => {
		const file = event.target.files[0];
		var auxUrlFileKey = fileUrl.split(BUCKET_NAME_KEY);
		var urlFileKey = BUCKET_NAME_KEY + auxUrlFileKey[1];

		this.setState({
			fileUpdate: file,
			fileNameUpdate: file.name,
			idAccountFileUpdate: idAccount,
			originalIdFile: idFile,
			originalFileKey: urlFileKey,
			showUpdateMessage: true,
		});
	};
	handleCloseUpdate = () => {
		this.setState({
			showUpdateMessage: false,
			fileUpdate: null,
			fileNameUpdate: null,
			idAccountFileUpdate: null,
			originalIdFile: null,
			originalFileKey: null,
		});
	};

	updateFileConfirm = async () => {
		try {
			
		
		this.props.dispatch(loadingStart({ name: this.props.pageName }));

		const activeForm = this.state.forms[this.state.activeForm];
		const formFileUrl = await this.uploadToS3WithDate(this.state.fileUpdate, activeForm, this.state.idAccountFileUpdate, new Date().getTime());
			
		if (formFileUrl) {
			const body = {
				originalFileKey: this.state.originalFileKey,
				serviceFilePdf: {
					idFilePdf: this.state.originalIdFile,
					documentName: this.state.fileNameUpdate,
					idService: this.props.taskData.idFielder,
					fileUrl: formFileUrl,
					idAccount: this.props.session.user.idAccount,
					idUser: this.props.session.user.idUser,
				}
			};

			await new Promise((resolve, reject) => {

				const onSuccess = (response) => {
					this.props.reloadData();
					this.props.dispatch(loadingStop({ name: this.props.pageName }));
					resolve(response);
					this.setState({ showUpdateMessage: false });
				}

				const onError = (error) => {
					console.log(error);
					reject();
					this.props.dispatch(loadingStop({ name: this.props.pageName }));
				}

				updateTaskFormPdfRequest(body, onSuccess, onError, null)

			});
			let state = this.state;
			state.view.forms = false;
			this.setState(state);
		}

			this.props.dispatch(loadingStop({ name: this.props.pageName }));
			
		} catch (error) {
			console.log(error)	
		}
	};


	generateCustomPDFFromHtml = async (form, asset, inventory, openAuto) => {
		// Only have implemented pdf for html, therefore all
		// pdf's will generate with html type
		let loadingConfig = null
		let link = '';

		let body = {
			idTask: this.props.taskData.idFielder,
			task: this.props.taskData,
			agent: {
				name: this.props.taskAgent.name,
			},
		};

		if (!this.state.formsFunctionalities.html) {
			body.lan = this.props.lan._language;
		}


		if (form) {
			body.form = form;

			link = await new Promise((resolve, reject) => {

				const onSuccess = (res) => {

					try {
						let bodyHtml = {};
						bodyHtml.idTask = this.props.taskData.idFielder;
						bodyHtml.htmlUrlFile = res.link;
						bodyHtml.footerHtml = res.footer_link;
						bodyHtml.filename = this.props.taskData.idFielder+"-"+ new Date().getTime() + '-pdf';
						bodyHtml.idCompany = this.props.taskData.branch?.idCompany;
					


						const onSuccessHtml = (res) => {
							resolve(res.pdf);
						}
						const onErrorHtml = (error) => {
							reject();
						}

						getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, loadingConfig);
					} catch(error) { 
						console.log(error)
					}	
				}

				const onError = (error) => {
					console.log(error)
					reject();
				}
				if (openAuto) {
					loadingConfig = { dispatch: this.props.dispatch }
				}

				getFormHTMLRequest(body, onSuccess, onError, loadingConfig);

			}).catch(() => {
				return;
			});

		} else if (asset) {
			let bodyAsset = JSON.parse(JSON.stringify(asset));
			bodyAsset.logs = [];
			bodyAsset.category = asset.category.name;
			body.asset = bodyAsset;

			link = await new Promise((resolve, reject) => {

				const onSuccess = (res) => {
					let bodyHtml = {};
					bodyHtml.idTask = this.props.taskData.idFielder;
					bodyHtml.htmlUrlFile = res.link;
					bodyHtml.footerHtml = res.footer_link;
					bodyHtml.filename = this.props.taskData.idFielder + '-pdf';

					const onSuccessHtml = (res) => {
						resolve(res.pdf);
					}
					const onErrorHtml = (error) => {
						console.log(error)
						reject();
					}

					getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, loadingConfig);
				}

				const onError = (error) => {
					reject();
				}
				if (openAuto) {
					loadingConfig = { dispatch: this.props.dispatch }
				}

				getAssetHTMLRequest(body, onSuccess, onError, loadingConfig);

			}).catch(() => {
				return;
			});
		} else if (inventory) {
			let bodyInventory = JSON.parse(JSON.stringify(inventory));
			bodyInventory.logs = [];
			bodyInventory.category = inventory.category.name;
			body.inventory = bodyInventory;

			link = await new Promise((resolve, reject) => {

				const onSuccess = (res) => {
					let bodyHtml = {};
					bodyHtml.idTask = this.props.taskData.idFielder;
					bodyHtml.htmlUrlFile = res.link;
					bodyHtml.footerHtml = res.footer_link;
					bodyHtml.filename = this.props.taskData.idFielder + '-pdf';

					const onSuccessHtml = (response) => {
						resolve(response.pdf);
					}
					const onErrorHtml = (error) => {
						console.log(error)
						reject();
					}

					getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, loadingConfig);
				}

				const onError = (error) => {
					console.log(error)
					reject();
				}
				if (openAuto) {
					loadingConfig = { dispatch: this.props.dispatch }
				}

				getInventoryHTMLRequest(body, onSuccess, onError, loadingConfig);

			}).catch(() => {
				return;
			});

		} else {
			return;
		}


		if (openAuto) {
			window.open(link);
		} else {
			return link;
		}
	};

	downloadZipFile = (zip) => {
		let name = this.props.taskData.idFielder + '-';

		if (this.state.view.assets) {
			name = name + this.props.lan.asset;
		} else if (this.state.view.forms) {
			name = name + this.props.lan.forms;
		} else {
			name = name + this.props.lan.inventory;
		}

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, name + '.zip');
			this.props.dispatch(loadingStop({ name: this.props.pageName }));
		});
	};

	getImagesFromGroup = async (images, index, zip) => {
		if (index < images.length) {
			const image = images[index];

			if (image) {
				let imageLink = image;
				let bucket = process.env.AWS_BUCKET;
				let link_split = image.split('com/');
				let key = link_split[1];

				if (key.includes(process.env.FIELDER_CONTENT_LINK)) {
					const fileLinkAux = key.split(process.env.FIELDER_CONTENT_LINK);
					imageLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${fileLinkAux[1]}`;
				} else if (!key.includes(bucket)) {
					imageLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com/${key}`;
				} else {
					let auxKey = key.split(process.env.AWS_BUCKET);
					imageLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${auxKey[1]}`;
				}

				await new Promise((resolve, reject) => {
					JSZipUtils.getBinaryContent(imageLink, (err, data) => {
						zip.file(this.getImageName(image) + '.jpeg', data, { binary: true });
						resolve();
					});
				});

				this.getImagesFromGroup(images, index + 1, zip);
			} else {
				this.downloadZipFile(zip);
			}
		} else {
			this.downloadZipFile(zip);
		}
	};

	getImageName = (url) => {
		let separated = url.split('/');
		let imageName = '';
		const lastIndex = separated.length - 1;

		if (lastIndex > 0) {
			try {
				imageName = separated[lastIndex];
			} catch (e) {}
		}
		return imageName;
	};

	handlerDownloadImg = () => {

		this.props.dispatch(loadingStart({
			name: this.props.pageName,
			config: {
				icon: LoadingConst.EXPORT,
				text: this.props.lan.exportMessage
			},
		}));
		this.getImagesFromGroup(this.state.formImages, 0, new JSZip());
	};

	handlerDownloadImgAsset = () => {

		this.props.dispatch(loadingStart({
			name: this.props.pageName,
			config: {
				icon: LoadingConst.EXPORT,
				text: this.props.lan.exportMessage,
			}
		}));

		this.getImagesFromGroup(this.state.assetImages, 0, new JSZip());
	};

	handlerDownloadImgInventory = () => {

		this.props.dispatch(loadingStart({
			name: this.props.pageName,
			config: {
				icon: LoadingConst.EXPORT,
				text: this.props.lan.exportMessage,
			}
		}));

		this.getImagesFromGroup(this.state.inventoryImages, 0, new JSZip());
	};

	changeActiveForm = (i) => {
		let questions = this.state.forms[i].fields;
		let formImages = [];
		let formHasImages = false;

		questions.forEach((question) => {
			if (question.question.file != null && question.question.type != Types.TEXT_FILE  && question.question.type != Types.ELECTRONIC_SIGNATURE ) {
				formImages.push(question.question.file);
			}
		});

		if (formImages.length > 0) {
			formHasImages = true;
		} 

		this.checkCustomTemplate(this.state.forms[i]);
		this.checkPdfUpload(this.state.forms[i]);

		this.setState({
			activeForm: i,
			formHasImages: formHasImages,
			formImages: formImages,
		});
	};

	changeActiveAsset = (i) => {
		let questions = this.state.assets[i].questions;
		let assetImages = [];
		let assetHasImages = false;

		questions.forEach((question) => {
			if (question.file != null) {
				assetImages.push(question.file);
			}
		});

		if (assetImages.length > 0) {
			assetHasImages = true;
		} else {
			assetHasImages = false;
		}

		this.setState({
			activeAsset: i,
			assetHasImages: assetHasImages,
			assetImages: assetImages,
		});
	};

	changeActiveInventory = (i) => {
		let questions = this.state.inventory[i].questions;
		let inventoryImages = [];
		let inventoryHasImages = false;

		questions.forEach((question) => {
			if (question.file != null) {
				inventoryImages.push(question.file);
			}
		});

		if (inventoryImages.length > 0) {
			inventoryHasImages = true;
		} else {
			inventoryHasImages = false;
		}

		this.setState({
			activeInventory: i,
			inventoryHasImages: inventoryHasImages,
			inventoryImages: inventoryImages,
		});
	};

	editFormVisibility = (value) => {
		let state = this.state;
		state.show.editForm = value;
		this.setState(state);
	};

	editPdfFromXlsxVisibility = () => {
		let state = this.state;
		state.show.generatePdfFromXlsx = false;
		//state.showMessageCancelAprove = true;
		this.setState(state);
	};

	reactivateFormPopup = (val) => {
		let state = this.state;
		state.show.reactivateForm = val;
		this.setState(state);
	};

	reactivateForm = (idTaskForm) => {
		const body = {
			idTask: this.props.taskData.idFielder,
			idTaskForm: idTaskForm,
		}

		const onSuccess = (response) => {
			this.reactivateFormPopup(null);
			this.props.reloadData();
		}

		const onError = (error) => {
			console.log(error)
		}

		const loadingConfig = { dispatch: this.props.dispatch }
		reactivateFormRequest(body, onSuccess, onError, loadingConfig)

	};

	removeFormTask = (index) => {
		this.removeTaskFormPopup(null);
		this.props.removeTaskForm(index);
	};

	removeTaskFormPopup = (val) => {
		let state = this.state;
		state.show.removeTaskForm = val;
		this.setState(state);
	};


	validateManagerApproveForm = () => {
		if (RoleUtils.isUserManager(this.props.session.user) && this.state.allowManagerToApproveForms && this.state.allowManagerToEditFormTaskDetail )  {
			return true;
		}

		return false;
	}

	FormHeader = () => {
		let clientType = this.props.taskData.client.clientType;

		// Branch or client's name
		let branchClientName = this.props.taskData.client.name;

		if (this.props.taskData.client.lastName != null) {
			branchClientName += ' ' + this.props.taskData.client.lastName;
		}

		return (
			<div>
				<Row className='mx-auto'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={6}>
								<Row>
									<Col sm={4}> {this.props.lan.customId}</Col>
									<Col sm={8}>
										<input
											type='text'
											disabled
											className={'task-details-input'}
											defaultValue={this.props.taskData.number}
											style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
										/>
									</Col>
								</Row>
							</Col>
							<Col sm={6}>
								<Row>
									<Col sm={4}>{this.props.lan.idFielder}</Col>
									<Col sm={8}>
										<input
											type='text'
											disabled
											className={'task-details-input'}
											defaultValue={this.props.taskData.idFielder}
											style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* Client/Branch Name */}
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3}>{this.props.lan.formClientNameLabel[clientType]}</Col>
							<Col sm={9}>
								<input
									type='text'
									disabled
									className={'task-details-input'}
									defaultValue={branchClientName}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* Client/Branch Id */}
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3}>{this.props.lan.formIdCustomLabel[clientType]}</Col>
							<Col sm={9}>
								<input
									type='text'
									disabled
									className={'task-details-input'}
									defaultValue={this.props.taskData.client.idCustom}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* Agent Name */}
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3}>{this.props.lan.agentName}</Col>
							<Col sm={9}>
								<input
									type='text'
									disabled
									className={'task-details-input'}
									defaultValue={this.props.taskAgent.name}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* Address */}
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3}>{this.props.lan.address}</Col>
							<Col sm={9}>
								<TextareaAutosize
									type='text'
									readOnly
									className={'task-details-input'}
									defaultValue={this.props.taskData.address}
									style={{
										width: '100%',
										paddingTop: '5px',
										paddingLeft: '10px',
										paddingBottom: '5px',
										paddingRight: '10px',
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	};

	FormTitles = () => {
		let titleClass = '';
		return (
			<div className='nav flex-column nav-pills mt-4 ml-3' role='tablist' aria-orientation='vertical'>
				{this.state.forms.map((form, i) => {
					titleClass = '';
					if (this.state.activeForm == i) {
						titleClass = titleClass + ' active form-active';
					} else {
						titleClass = titleClass + ' nav-link form-inactive ';
					}

					let statusIcon = null;

					{/*if (form.status == 'FINISHED') {
						titleClass = titleClass + ' solvedForm';
						statusIcon = (
							<Tooltip title={this.props.lan.reactivateForm} arrow>
								<IconButton className='p-0' onClick={() => this.reactivateFormPopup(form.idTaskForm)}>
									<Icon color='disabled'>restore</Icon>
								</IconButton>
							</Tooltip>
						);
					} */}
					
					if (form.status == 'IN_REVIEW' && form.reviewable) {
						statusIcon = (
							<Tooltip title={this.props.lan.inReviewForm} arrow>
								<Icon style={{ color: '#fbc02d' }}>warning_rounded</Icon>
							</Tooltip>
						);
					} else if (form.status == 'NOT_APPROVED' && form.reviewable) {
						statusIcon = (
							<Tooltip title={this.props.lan.hasNotApprovedForm} arrow>
								<Icon style={{ color: '#F50057' }}>error_rounded</Icon>
							</Tooltip>
						);
					}

					return (
						<div
							data-toggle='pill'
							role='tab'
							aria-controls='v-pills-home'
							key={'forms-' + i}
							aria-selected={(this.state.activeForm == i) + ''}
							className={'nav-link ' + titleClass}
							onClick={() => this.changeActiveForm(i)}
						>
							<Row>
								<Col sm={1}>
									{form.status != 'FINISHED' &&
										form.accountId == this.props.session.account.id &&
										!this.props.client &&
										this.state.isAllowedToDeleteTaskForm && (
											<button
												className='taskModule-deleteForm'
												onClick={() => this.removeTaskFormPopup(form.idTaskForm)}
											>
												&times;
											</button>
										)}
								</Col>

								<Col>{form.name}</Col>

								{form.accountId != this.props.session.account.id && (
									<Col sm={'auto'}>
										<img src={PartnerIcon} alt='' />
									</Col>
								)}

								{/*form.accountId == this.props.session.account.id && (*/
									<Col sm={'auto'} className='pr-1'>
										{statusIcon &&
											(RoleUtils.isUserAdministrator(this.props.session.user) ||
												RoleUtils.isUserSuperAdministrator(this.props.session.user) || this.validateManagerApproveForm()) &&
											statusIcon}
									</Col>
								/*)*/}
								{ form.status != 'FINISHED' && (
									<div>
									{((form.accountId === this.props.session.account.id) ||
										(form.accountId != this.props.session.account.id && form.partnersEditable)) && (
										<Col sm={'auto'} className='pl-0'>
											{(((RoleUtils.isUserAdministrator(this.props.session.user) ||
												RoleUtils.isUserSuperAdministrator(this.props.session.user) ||
												this.props.client) && this.state.isAllowedToEditTask) ||
													(RoleUtils.isUserManager(this.props.session.user) && this.state.allowManagerToEditFormTaskDetail)) && (
													<Tooltip title={this.props.lan.edit} arrow>
														<IconButton className='p-0' onClick={() => this.editFormVisibility(true)}>
															<Icon color='disabled'>edit</Icon>
														</IconButton>
													</Tooltip>
												)}
										</Col>
									)}
									</div>
								)}
							</Row>
						</div>
					);
				})}
			</div>
		);
	};

	viewForm = () => {
		let form = this.props.forms[this.state.activeForm];
		this.props.navigate(`${ENDPOINTS.forms}?id=${form.id}`);
	};


	setViewImport = (value) => {
		if (!value) {
			document.getElementById('task-detail-modal').style.opacity = '1';
		} else { 
			document.getElementById('task-detail-modal').style.opacity = '0';
		}
		let state = this.state;
		state.viewImport = value;
		this.setState(state);
	}



	render() {
		return (
			<div style={{ height: '590px' }}>
				{this.state.showUpdateMessage && (
					<UpdateDocumentMessage
						{...this.props}
						handleClose={this.handleCloseUpdate}
						updateFileConfirm={this.updateFileConfirm}
						message={this.props.lan.updateTemplateMessagePdf}
					/>
				)}

				{this.state.showMessageSuccessAprove && (
					<ErrorMessage
						{...this.props}
						closeErrorMessage={() => this.closeErrorMessageAprove()}
						message={this.state.messageApproved}
						showErrorMessage={true}
						icon={this.state.iconMessage}
					/>
				)}

				
				<DowloadFormTemplate
					setViewImport={this.setViewImport}
					viewImport={this.state.viewImport}
					idFormTemplate={this.props.forms ? this.props.forms[this.state.activeForm]?.id : 0}
					nameFormTemplate={this.props.forms ? this.props.forms[this.state.activeForm]?.name: ""}
				/>
				

				{/*this.state.showMessageCancelAprove && (
					<ErrorMessage
						{...this.props}
						closeErrorMessage={() => this.closeErrorMessageCancel()}
						message={this.props.lan.pdfCancelMessagePart1}
						showPdfCancelMessage={true}
						showErrorMessage={true}
						icon={YellowAlertIcon}
					/>
				)*/}

				<Scrollbars renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}>
					<div className='container px-5'>
						{this.state.show.editForm && (
							<EditForm
								form={this.props.forms[this.state.activeForm]}
								onClose={this.editFormVisibility}
								Header={this.FormHeader}
								taskId={this.props.taskData.idFielder}
								reloadData={this.props.reloadData}
								session={this.props.session}
								client={this.props.client}
								{...this.props}
							/>
						)}

						{this.state.show.generatePdfFromXlsx && (
							<GeneratePdfFromXlsx
								onClose={this.editPdfFromXlsxVisibility}
								urlGeneratePdfFromXlsx={this.state.urlGeneratePdfFromXlsx}
								approve={this.approveDocument}
								cancel={this.editPdfFromXlsxVisibility}
								{...this.props}
							/>
						)}

					

						{this.state.show.reactivateForm != null && (
							<ReactivateForm
								onClose={() => this.reactivateFormPopup(null)}
								reactivateForm={() => this.reactivateForm(this.state.show.reactivateForm)}
								{...this.props}
							/>
						)}

						{this.state.show.removeTaskForm != null && (
							<RemoveFormPopup
								onClose={() => this.removeTaskFormPopup(null)}
								removeFormTask={() => this.removeFormTask(this.state.show.removeTaskForm)}
								{...this.props}
							/>
						)}

						{this.state.assets && this.state.assets.length > 0 && !this.props.client && (
							<React.Fragment>
								{this.state.isAllowedToViewAsset && (
									<Row className='my-5'>
										<div className='col-auto '>
											<img src={AssetsIcon} className='TaskModules-img' alt='' />
										</div>
										<div className='col-auto d-flex align-items-end'>{this.props.lan.assetManagement}</div>
										<div className='col d-flex flex-row-reverse'>
											{this.state.view.assets && (
												<img
													id='task-module-asset-dropdown'
													src={DropdownIcon}
													className='Fielder-icon-button'
													onClick={() => this.changeView('assets')}
													alt=''
												/>
											)}
											{!this.state.view.assets && (
												<img
													id='task-module-asset-dropup'
													src={DropRigth}
													className='Fielder-icon-button'
													onClick={() => this.changeView('assets')}
													alt=''
												/>
											)}
										</div>
									</Row>
								)}

								{this.state.view.assets && (
									<TaskAssets
										assets={this.state.assets}
										activeAsset={this.state.activeAsset}
										assetHasImages={this.state.assetHasImages}
										images={this.state.assetImages}
										changeActiveAsset={this.changeActiveAsset}
										client={this.props.client}
										timezone={this.props.taskData.startLocation.timeZone}
										taskData={this.props.taskData}
										taskAgent={this.props.taskAgent}
										reloadData={this.props.reloadData}
										idAccountTask={this.props.idAccountTask}
									/>
								)}

								{this.state.isAllowedToViewAsset && <div className='TaskModules-div row mx-3 my-4 py-2' />}
							</React.Fragment>
						)}

						{this.state.inventory && this.state.inventory.length > 0 && !this.props.client && (
							<React.Fragment>
								{this.state.isAllowedToViewInventory && (
									<Row className='my-5'>
										<div className='col-auto'>
											<img src={InventoryIcon} className='TaskModules-img' alt='' />
										</div>
										<div className='col-auto d-flex align-items-end'>{this.props.lan.inventories}</div>
										<div className='col d-flex flex-row-reverse'>
											{this.state.view.inventory && (
												<img
													id='task-module-inventory-dropdown'
													src={DropdownIcon}
													className='Fielder-icon-button'
													onClick={() => this.changeView('inventory')}
													alt=''
												/>
											)}
											{!this.state.view.inventory && (
												<img
													id='task-module-inventory-dropup'
													src={DropRigth}
													className='Fielder-icon-button'
													onClick={() => this.changeView('inventory')}
													alt=''
												/>
											)}
										</div>
									</Row>
								)}

								{this.state.view.inventory && (
									<TaskInventories
										inventories={this.state.inventory}
										activeInventory={this.state.activeInventory}
										inventoryHasImages={this.state.inventoryHasImages}
										images={this.state.inventoryImages}
										changeActiveInventory={this.changeActiveInventory}
										client={this.props.client}
										timezone={this.props.taskData.startLocation.timeZone}
										taskData={this.props.taskData}
										taskAgent={this.props.taskAgent}
										reloadData={this.props.reloadData}
										idAccountTask={this.props.idAccountTask}
									/>
								)}

								{this.state.isAllowedToViewInventory && <div className='TaskModules-div row mx-3 my-4 py-2' />}
							</React.Fragment>
						)}

						{this.state.forms && this.state.forms.length > 0 && this.state.isAllowedToViewForm && (
							<Row>
								<div className='col-auto'>
									<img src={FormIcon} className='TaskModules-img' alt='' />
								</div>
								<div className='col-auto d-flex align-items-end'>{this.props.lan.forms}</div>
								<div className='col d-flex flex-row-reverse'>
									{this.state.view.forms && (
										<img
											id='task-module-forms-dropdown'
											src={DropdownIcon}
											className='Fielder-icon-button'
											onClick={() => this.changeView('forms')}
											alt=''
										/>
									)}
									{!this.state.view.forms && (
										<img
											id='task-module-forms-dropup'
											src={DropRigth}
											className='Fielder-icon-button'
											onClick={() => this.changeView('forms')}
											alt=''
										/>
									)}
								</div>
							</Row>
						)}

						{this.state.view.forms && this.state.forms && this.state.forms.length > 0 && (
							<div style={this.props.client ? { height: '100%' } : { height: '60%' }} className='mt-4'>
								{RoleUtils.isUserManager(this.props.session.user) && (
									<Row>
										<Col>
											<Col className='d-flex justify-content-end pb-1'>
												<Button
													variant='outlined'
													size='small'
													className='m-1'
													onClick={() => this.setViewImport(true)}
													startIcon={<img src={uploadButton} alt='' />}
													sx={{ color: DeepSpaceGrey, border: `1px solid ${DeepSpaceGrey}` }}
												>
													{this.props.lan.updateTemplateForm}
												</Button>
											</Col>
										</Col>
									</Row>
								)}
								<Row style={{ height: '70%', minHeight: '300px' }}>
									<Col sm={4}>
										<Scrollbars renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}>
											<div className='mt-1 taskModules-label'>
												<span className='mb-1'>{this.props.lan.formList}</span>
											</div>
											<this.FormTitles />
										</Scrollbars>
									</Col>
									
									<Col>
										<Scrollbars renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}>
											<this.FormHeader />
											<this.createFieldsForm />
										</Scrollbars>
									</Col>
								</Row>
								<div>
									{this.state.forms && !this.props.client && (
										<div className='row mt-4'>
											<Col sm={4}>
												{this.props.hasFormsFunctionality && !RoleUtils.isUserManager(this.props.session.user) && (
													<button className='Fielder-button-clear' onClick={this.viewForm}>
														{this.props.lan.viewForm}
													</button>
												)}
											</Col>
											{((this.props.forms && this.props.forms[this.state.activeForm] &&  this.props.forms[this.state.activeForm].status == 'FINISHED') || this.props.allowGenerateFormsIfTaskStatusWasModify) && ( 
											<Col>
												<Row className='justify-content-end'>
													
														{((this.state.formsFunctionalities.excel && this.state.isAllowedToDownloadForms) ||
															(this.state.formsFunctionalities.excel && RoleUtils.isUserManager(this.props.session.user)
																&& this.state.allowManagerToDownloadFormExcelTaskDetail)) &&
																this.isNotPartner()
															&& (
															<Col sm={3}>
																<FielderButton id='generate-pdf-xslx-form-module' onClick={() => this.generateXlsx(false)} outlined>
																	{this.props.lan.generateXlsxModule}
																</FielderButton>
														</Col>
													)}
													{this.state.formsFunctionalities.excel &&
														(this.state.isAllowedToDownloadForms || this.validateManagerGeneratePdf()) &&
															this.state.showPdfConverter &&
															this.validateShowDowloadPDFConverter() && (
															<Col sm={3}>
																<FielderButton id='generate-pdf-from-xlsx-form-module' onClick={() => this.generateXlsx(true)} disabled={this.state.disablePdfTemplateButton} outlined>
																	{this.state.disablePdfTemplateButton
																		? this.props.lan.generatedPDFfromXlsx
																		: this.props.lan.generatePDFfromXlsx}
																</FielderButton>
																
															</Col>
														)}

													{this.state.formsFunctionalities.excel &&
														this.state.isAllowedToDownloadForms &&
														!this.state.showPdfConverter || (this.state.urlFromPdf && this.isPartner()) &&  (
															<Col sm={3}>
																<FielderButton id='generate-pdf-form-module' style={{ fontSize:'8px' }}  onClick={this.redirectToMarketPlace} outlined>
																	{this.props.lan.buyGeneratePDFfromXlsx}
																</FielderButton>
															</Col>
															)}
														{this.state.urlFromPdf && this.isPartner() &&  (	
															<Col sm={3}>
																<Row>
																	<div style={{ fontWeight: 'bold' }}>{this.props.lan.download}</div>
																					
																	<img
																		className='Fielder-icon-button sizeIconDocumentDownload buttonSizePage'
																		src={ExportIcon}
																		alt=''
																		onClick={() => {
																			window.open(this.state.urlFromPdf, '_blank').focus();
																		}}
																		/>
																</Row>
														</Col>
														)}

														{/*Aqui*/}
													{(this.state.isAllowedToDownloadForms || RoleUtils.isUserManager(this.props.session.user))&& this.isNotPartner() && (
															<Col sm={3}>
																<FielderButton id='generate-pdf-form-module' onClick={this.generatePDF} outlined>
																	{this.props.lan.generatePDFModule}
																</FielderButton>
											
														</Col>
													)}

													{this.state.formHasImages && (
															<Col sm={3}>
																<FielderButton id='download-form-images' onClick={this.handlerDownloadImg} outlined>
																	{this.props.lan.downloadImages}
																</FielderButton>
															
														</Col>
													)}
												</Row>

												{this.state.formsFunctionalities.excel &&
													(this.state.isAllowedToDownloadForms || this.validateManagerGeneratePdf()) &&
														this.state.showPdfConverter &&		
														this.state.showUpdateComponentPdf
															&& this.isNotPartner() && (
														<>
															
																<div className='row mt-2 d-flex justify-content-end'>
																
																	<div className='col-auto'>
																		<UpdateFileMenu
																			key={'row-docUpdate'}
																			idAccountFile={this.props.idAccountTask}
																			idFile={this.state.idFilePdf}
																			fileUrl={this.state.urlFromPdf}
																			originType={0}
																			updateFileHandler={this.updateFileHandler}
																			dontShowLink={true}
																			acceptFiles={ACCEPT_FILES}
																		/>
																		</div>
																	
																	{this.isNotPartner() && (<Col sm={3}></Col>)}
																	{this.state.formHasImages && <Col sm={3}></Col>}
																</div>
															
															<div className='row d-flex justify-content-end'>
																<div className='col-auto mt-2'>
																	<div style={{ fontWeight: 'bold' }}>{this.props.lan.download}</div>
																</div>
																<div className='col-auto mt-1 pl-0'>
																	<img
																		className='Fielder-icon-button sizeIconDocumentDownload buttonSizePage'
																		src={ExportIcon}
																		alt=''
																		onClick={() => {
																			window.open(this.state.urlFromPdf, '_blank').focus();
																		}}
																	/>
																</div>
																{this.isNotPartner() && (<Col sm={3}></Col>)}
																{this.state.formHasImages && <Col sm={3}></Col>}
															</div>
														</>
													)}
											</Col>
											)}  
										</div>
									)}
								</div>
							</div>
						)}

						{((this.state.forms && this.state.forms.length > 0 && this.hasFinishForms()) || this.state.inventory || this.state.assets) &&
							!this.props.client && (
								<Row
									className='pt-5 justify-content-end'
									style={{ position: 'sticky', bottom: '0', backgroundColor: 'white' }}
								>
									<Col sm={4}>
										<Button
											className='fielder-button ml-auto'
											size='small'
											startIcon={<SaveAltIcon />}
											onClick={this.generateModulesZip}
										>
											{this.props.lan.generateZip}
										</Button>
									</Col>
								</Row>
							)}
					</div>
				</Scrollbars>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(withRouter(TaskModules));
