import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';
import { REQUEST_TYPES } from '@core/constants/branch/request.constants';


export const getBranchTableRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCHES_TABLE,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const searchBranchesByFilter = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCHES_BY_FILTER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getBranchesFromCompany = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCHES_BY_ID_COMPANY,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getBranchesByIdCompanyAndFilter = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCHES_BY_ID_COMPANY_AND_FILTER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getLastIdsCustomRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_LAST_IDS_CUSTOM_BRANCH_USED,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const createBranchRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	body.branch.idAccount = user.idAccount

	if (body.branch.location) {
		body.branch.location.accountId = user.idAccount;
	}

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CREATE_BRANCH,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getBranchInfoRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCH_INFO,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const changeBranchStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CHANGE_BRANCH_STATUS,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getBranchPendingToUpdateListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCHES_PENDING_TO_UPDATE_VIEW,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getBranchByIdRequest = async (body, onSuccess, onError, loadingConfig) => {

	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCH_BY_ID,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};



/** UPDATE BRANCH * */
export const updateBranchRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	body.branch.idAccount = user.idAccount;
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_BRANCH,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateBranchLocation = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_LOCATION_UPDATE,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


/** GET BRANCH LOGS * */
export const getBranchLogsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCH_LOGS,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


/** DELETE BRANCH * */
export const deleteBranchRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_DELETE_BRANCH,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};